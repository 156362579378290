import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import * as actions from './redux/actions';
import {
  KuposButton,
  KuposDatePicker,
  KuposErrorSuccessModal,
  KuposInput,
  KuposTimePicker,
  MaterialInputField,
} from '../common';
import DateService from '../../services/dateService';
import CommonService from '../../services/commonService';
import ApiUrls from '../../services/apiUrls';
import { apiCall } from './redux/apiCall';
import Loader from 'react-loader-spinner';

const initialState = {
  serviceName: '',
  amenitiesName: '',
  seats: '',
  luggageInfo: '',
  policyInfo: '',
  additionalInfoName: '',
  startDate: DateService.getTodayString(),
  endDate: DateService.getTodayString(),
  bookingThreshold: '00:00',
  remarks: '',
};

const modalInitialState = {
  show: false,
  success: false,
  bodyText: '',
};

const errorInitialState = {};

const AddVehicle = ({ match, history }) => {
  const dispatch = useDispatch();
  const [values, setValues] = useState(initialState);
  const [modal, setModal] = useState(modalInitialState);
  const [errors, setErrors] = useState(errorInitialState);
  const [vehiclesList, setVehiclesList] = useState([]);
  const [vehicleToEdit, setVehicleToEdit] = useState(null);
  const [loader, setLoader] = useState(false);

  const id = match.params ? parseInt(match.params.id) : null;


  useEffect(() => {
    dispatch(
      actions.apiCall({
        url: ApiUrls.getServices(),
        callback: res => {
          if (res.data && res.data.data) {
            setVehiclesList(res.data.data);
          }
          if (id) {
            res.data.data.forEach(element => {
              if (element.id == id) {
                setVehicleToEdit(element);
                setValues({
                  serviceName: element.name,
                  luggageInfo: element.restriction_info,
                  policyInfo: element.cancellation_info,
                  amenitiesName: element.amenities,
                  seats: element.no_of_seats,
                  additionalInfoName: element.additional_info,
                  bookingThreshold: DateService.secondsToHms(element.booking_time_threshold),
                  remarks: element.remark,
                  startDate: DateService.changeDateFormat(
                    element.availability_start_date,
                    'yyyy-mm-dd',
                    'dd-mm-yyyy',
                  ),
                  endDate: DateService.changeDateFormat(
                    element.availability_end_date,
                    'yyyy-mm-dd',
                    'dd-mm-yyyy',
                  ),
                });
              }
            });
          }
        },
      }),
    );
  }, [dispatch, id]);



  const onSelectOptionChange = val => { };

  const onBlur = (val, type) => { };

  const onChange = (val, type) => {
    console.log('+++selected data is', val);
    switch (type) {
      case 'serviceName':
        setValues({ ...values, serviceName: val });
        break;
      case 'amenitiesName':
        setValues({ ...values, amenitiesName: val });
        break;
      case 'seats':
        setValues({ ...values, seats: val });
        break;
      case 'luggageInfo':
        setValues({ ...values, luggageInfo: val });
        break;
      case 'policyInfo':
        setValues({ ...values, policyInfo: val });
        break;
      case 'additionalInfoName':
        setValues({ ...values, additionalInfoName: val });
        break;
      case 'bookingThreshold':
        setValues({ ...values, bookingThreshold: val });
        break;
      case 'remarks':
        setValues({ ...values, remarks: val });
        break;
      case 'startDate':
        setValues({ ...values, startDate: val });
        break;
      case 'endDate':
        setValues({ ...values, endDate: val });
        break;
    }
  };

  const validate = () => {
    let error = 0;
    setErrors(initialState);

    if (!values.serviceName) {
      setErrors({ ...errors, serviceNameError: 'Ingrese el nombre del servicio' });
      error++;
      return false;
    } else if (!values.seats) {
      setErrors({ ...errors, seatsError: 'Ingrese el número de asientos' });
      error++;
      return false;
    } else if (!values.amenitiesName) {
      setErrors({ ...errors, amenitiesNameError: 'Ingrese Servicios de servicio' });
      error++;
      return false;
    } else if (!values.luggageInfo) {
      setErrors({ ...errors, luggageInfoError: 'Ingrese el Restriccions de equipaje' });
      error++;
      return false;
    } else if (!values.policyInfo) {
      setErrors({ ...errors, policyInfoError: 'Ingrese el Politicas de cancelacion' });
      error++;
      return false;
    } else if (!values.additionalInfoName) {
      setErrors({ ...errors, additionalInfoNameError: ' Ingrese el Información adicional' });
      error++;
      return false;
    } else if (!values.bookingThreshold) {
      setErrors({ ...errors, bookingThresholdError: 'Ingrese el Límites de reserva' });
      error++;
      return false;
    } else if (!values.startDate) {
      setErrors({ ...errors, startDateError: 'Introduzca la fecha de inicio' });
      error++;
      return false;
    } else if (!values.endDate) {
      setErrors({ ...errors, endDateError: 'Ingrese la fecha de finalización' });
      error++;
      return false;
    } else if (!values.remarks) {
      setErrors({ ...errors, remarksError: 'Por favor ingrese comentario de servicio' });
      error++;
      return false;
    }
    if (error > 0) {
      return false;
    } else {
      return true;
    }
  };

  const createOrUpdate = () => {

    if (!validate()) {
      return;
    }
    setLoader(true)
    let updateData, data;


    id
      ? (updateData = {
        id: id ? +id : undefined,
        client_code: vehicleToEdit.client_code,
        name: vehicleToEdit.name,
        amenities: values.amenitiesName,
        restriction_info: values.luggageInfo,
        cancellation_info: values.policyInfo,
        additional_info: values.additionalInfoName,
        status: vehicleToEdit.status,
        no_of_seats: Number(values.seats),
        booking_time_threshold: CommonService.getSecondsFromTime(values.bookingThreshold),
        image: '',
        created_by_user: vehicleToEdit.created_by_user,
        remark: values.remarks,
        created_on_utc: vehicleToEdit.created_on_utc,
        created_date_time_stg: vehicleToEdit.deleted_date_time_stg,
        last_updated_user: ' ',
        last_updated_utc: DateService.getCurrentTimeUTC(),
        last_updated_date_time_stg: DateService.getCurrentStgDate(),
        deleted_user: '',
        deleted_utc: 0,
        deleted_date_time_stg: '',
        availability_start_date: DateService.changeDateFormat(
          values.startDate,
          'dd-mm-yyyy',
          'yyyy-mm-dd',
        ),
        availability_end_date: DateService.changeDateFormat(
          values.endDate,
          'dd-mm-yyyy',
          'yyyy-mm-dd',
        ),
        availability_start_date_utc: CommonService.getTimeInSeconds(
          DateService.changeDateFormat(values.startDate, 'dd-mm-yyyy', 'yyyy-mm-dd') +
          'T00:00:00',
        ),
        availability_end_date_utc: CommonService.getTimeInSeconds(
          DateService.changeDateFormat(values.endDate, 'dd-mm-yyyy', 'yyyy-mm-dd') + 'T23:59:59',
        ),
        image1: vehicleToEdit.image1,
        image2: vehicleToEdit.image2,
        image3: vehicleToEdit.image3,
      })
      : (data = {
        name: values.serviceName,
        amenities: values.amenitiesName,
        no_of_seats: Number(values.seats),
        booking_time_threshold: CommonService.getSecondsFromTime(values.bookingThreshold),
        restriction_info: values.luggageInfo,
        cancellation_info: values.policyInfo,
        additional_info: values.additionalInfoName,
        remark: values.remarks,
        availability_start_date: DateService.changeDateFormat(
          values.startDate,
          'dd-mm-yyyy',
          'yyyy-mm-dd',
        ),
        availability_end_date: DateService.changeDateFormat(
          values.endDate,
          'dd-mm-yyyy',
          'yyyy-mm-dd',
        ),
        availability_start_date_utc: CommonService.getTimeInSeconds(
          DateService.changeDateFormat(values.startDate, 'dd-mm-yyyy', 'yyyy-mm-dd') +
          'T00:00:00',
        ),
        availability_end_date_utc: CommonService.getTimeInSeconds(
          DateService.changeDateFormat(values.endDate, 'dd-mm-yyyy', 'yyyy-mm-dd') + 'T23:59:59',
        ),
        created_on_utc: DateService.getCurrentTimeUTC(),
        created_date_time_stg: DateService.getCurrentStgDate(),
      });

    dispatch(
      apiCall({
        url: ApiUrls.addService(),
        method: id ? 'PUT' : 'POST',
        data: id ? updateData : data,
        callback: res => onAddServiceResponse(res),
      }),
    );
  };

  const onAddServiceResponse = res => {
    if (res.data && res.data.success && res.data.data) {
      setLoader(false)
      setModal({
        show: true,
        success: true,
        redirect: true,
        bodyText: 'Éxito' || (id ? 'Éxito' : 'Éxito'),
      });
    } else if (!res.data.success && res.data.message) {
      setModal({ show: true, success: false, bodyText: res.data.message });
    } else {
      setModal({ show: true, success: false, bodyText: 'Fail' });
    }
  };

  const onFocusChange = () => { };

  return (
    <div className="home-add-vehicle">
      <div className="common-kupos-input-box" style={{ marginTop: 10 }}>
        <KuposInput
          type="text"
          className="kupos-border"
          placeholder={'Nombre del Servicio'}
          disabled={id ? true : false}
          value={values.serviceName}
          error={errors.serviceNameError ? true : false}
          errorMessage={errors.serviceNameError ? errors.serviceNameError : ''}
          onChange={text => onChange(text, 'serviceName')}
          onBlur={text => onBlur(text, 'serviceName')}
        />
      </div>

      <div className="common-kupos-input-box" style={{ marginTop: 10 }}>
        <KuposInput
          type="text"
          className="kupos-border"
          placeholder={'Numero de asientos'}
          value={values.seats}
          error={errors.seatsError ? true : false}
          errorMessage={errors.seatsError ? errors.seatsError : ''}
          onChange={text => onChange(text, 'seats')}
          onBlur={text => onBlur(text, 'seats')}
        />
      </div>

      <div className="common-kupos-input-box" style={{ marginTop: 10 }}>
        <KuposInput
          type="text"
          className="kupos-border"
          placeholder={'Comodidades'}
          value={values.amenitiesName}
          error={errors.amenitiesNameError ? true : false}
          errorMessage={errors.amenitiesNameError ? errors.amenitiesNameError : ''}
          onChange={text => onChange(text, 'amenitiesName')}
          onBlur={text => onBlur(text, 'amenitiesName')}
        />
      </div>

      <div className="common-kupos-input-box">
        <MaterialInputField
          // label={'Restriccions de equipaje'}
          placeholder={'Restriccions de equipaje'}
          value={values.luggageInfo}
          multiline={true}
          rows={2}
          onChange={text => onChange(text, 'luggageInfo')}
          onBlur={() => onBlur()}
          error={errors.luggageInfoError}
          errorMessage={errors.luggageInfoError ? errors.luggageInfoError : ''}
        />
      </div>

      <div className="common-kupos-input-box">
        <MaterialInputField
          // label={'Politicas de cancelacion'}
          placeholder={'Politicas de cancelacion'}
          value={values.policyInfo}
          multiline={true}
          rows={2}
          onChange={text => onChange(text, 'policyInfo')}
          onBlur={() => onBlur()}
          error={errors.policyInfoError}
          errorMessage={errors.policyInfoError ? errors.policyInfoError : ''}
        />
      </div>

      <div className="common-kupos-input-box">
        <MaterialInputField
          // label={'Información adicional'}
          placeholder={'Información adicional'}
          value={values.additionalInfoName}
          multiline={true}
          rows={2}
          onChange={text => onChange(text, 'additionalInfoName')}
          onBlur={() => onBlur()}
          error={errors.additionalInfoNameError}
          errorMessage={errors.additionalInfoNameError ? errors.additionalInfoNameError : ''}
        />
      </div>
      <div className="home-selection-input-box font10 datebox" style={{ marginTop: 10 }}>
        <span className="label font11">{'Límites de reserva (hh:mm)'}</span>
        <KuposTimePicker
          value={values.bookingThreshold}
          onChange={val => onChange(val, 'bookingThreshold')}
          error={errors.bookingThresholdError ? true : false}
          errorMessage={errors.bookingThresholdError}
          placeholder={'00:00'}
          hideAmPm
          not24Hours
          leftPadding={35}
        />
      </div>

      <div className="home-selection-input-box datebox font10" style={{ marginTop: 10 , position:'relative'}}>
        <span className="label font10">{'Fecha de inicio'}</span>
        <KuposDatePicker
          minDate={DateService.getTodayString()}
          selectedDate={values.startDate}
          onDateChange={date => onChange(date, 'startDate')}
          onFocus={() => onFocusChange('startDate')}
          placeholder={'Fecha de inicio'}
        />
      </div>

      <div className="home-selection-input-box datebox font10" style={{ marginTop: 10 ,position:'relative'}}>
        <span className="label font10">{'Fecha de finalización'}</span>
        <KuposDatePicker
          minDate={DateService.getTodayString()}
          selectedDate={values.endDate}
          onDateChange={date => onChange(date, 'endDate')}
          onFocus={() => onFocusChange('endDate')}
          placeholder={'Fecha de finalización'}
        />
      </div>

      <div className="common-kupos-input-box" style={{ marginTop: 10 }}>
        <KuposInput
          type="text"
          className="kupos-border"
          placeholder={'Observaciones'}
          value={values.remarks}
          error={errors.remarksError ? true : false}
          errorMessage={errors.remarksError ? errors.remarksError.message : ''}
          onChange={text => onChange(text, 'remarks')}
          onBlur={text => onBlur(text, 'remarks')}
        />
      </div>

      <div className="button-container" style={{ marginTop: 20 }}>
        <KuposButton onClick={createOrUpdate}>
          <span className="font11">{id ? 'Servicio de actualización' : 'Crear servicio'}</span>
        </KuposButton>
      </div>

      <KuposErrorSuccessModal
        success={modal.success}
        showModal={modal.show}
        onButtonClick={() => {
          setModal(modalInitialState);
          if (modal.redirect) {
            history.goBack();
          }
        }}
        bodyText={modal.bodyText}
      />

      {loader ? (
        <div className="loader-container">
          <Loader visible={loader} type="Bars" color="#637290" height={80} width={80} />
        </div>
      ) : null}
    </div>
  );
};



export default AddVehicle;
