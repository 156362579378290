import React, { Component } from 'react';
import PropTypes from 'prop-types';
import CommonService from '../../services/commonService';
import i18next from 'i18next';

export default class ServiceFilter extends Component {
  static propTypes = {
    filtersArray: PropTypes.array.isRequired,
    onFilterSelected: PropTypes.func.isRequired,
    onFilterClear: PropTypes.func,
    t:PropTypes.func
  };

  renderFilterItem = (option, onClick) => {


    return (
      <div className={'filter-category-box ' + option.align}>
        {(option.type === 'time' || !CommonService.isTrain()) ? 
        <div className="filter-category-title bold-text">
          {this.props.t('RESULTS_PAGE.' + option.title)}
        </div> : null}
        {(option.type == "time" || !CommonService.isTrain()) ? option.options.map((val, key) => {
          return (
            <div
              key={key}
              className={
                'filter-item pointer ' +
                (val.active ? 'primary-text active bold-text ' : '') +
                i18next.language
              }
              onClick={() => onClick(key)}
            >
              {val.icon ? (
                <img
                  className="icon-size"
                  src={`/images/icons/icon-${val.icon + (val.active ? '-orange' : '')}.png`}
                />
              ) : null}
              {val.trText ? this.props.t('RESULTS_PAGE.' + val.trText) : val.label}
            </div>
          );
        })
        : null
      } 
     </div>
    );
  };

  showClearFilter = () => {
    for (let f of this.props.filtersArray) {
      for (let o of f.options) {
        if (o.active) {
          return true;
        }
      }
    }
    return false;
  };

  clearFilter = () => {
    let filters = CommonService.copyObject(this.props.filtersArray);
    for (let f of filters) {
      for (let o of f.options) {
        o.active = false
      }
    }
    this.props.onFilterClear(filters)
  }

  render() {
    let {t} = this.props
    return (
      <div className="common-service-filter filter-container pb-service-list-service-filter">
        <div className=" kupos-card">
          <div className="filter-main-title font13">{t('RESULTS_PAGE.FILTERS')}</div>
          <div className="filter-box font10">
            {this.props.filtersArray.map((val, key) => {
              return this.renderFilterItem(val, i => this.props.onFilterSelected(val, key, i));
            })}
          </div>
        </div>
        {this.showClearFilter() ? (
          <div className="remove-filter-box pointer font10" onClick={this.clearFilter}>
            <img src="/images/icons/close-window.svg" />
            {t('RESULTS_PAGE.REMOVE_FILTERS')}
          </div>
        ) : null}
      </div>
    );
  }
}
