import initialState from './initialState';
import { reducer as loginReducer } from './login';
import { reducer as setLoginDataReducer } from './setLoginData';
import { reducer as getBookingsForAgentReducer } from './getBookingsForAgent';
import { reducer as getDriversReducer } from './getDrivers';
import { reducer as getVehiclesReducer } from './getVehicles';
import { reducer as getLocationsReducer } from './getLocations';
import { reducer as addLocationAreaReducer } from './addLocationArea';
import { reducer as getLocationAreasReducer } from './getLocationAreas';
import { reducer as getServicesReducer } from './getServices';
import { reducer as addServicePricingReducer } from './addServicePricing';
import { reducer as apiCallReducer } from './apiCall';
import { reducer as getLocationAreasFromLocationReducer } from './getLocationAreasFromLocation';
import { reducer as setSearchedTicketDetailsReducer } from './setSearchedTicketDetails';
import { reducer as setCounterInitilalDataReducer } from './setCounterInitilalData';
import { reducer as setCancelTicketDetailsReducer } from './setCancelTicketDetails';

const reducers = [
  loginReducer,
  setLoginDataReducer,
  getBookingsForAgentReducer,
  getDriversReducer,
  getVehiclesReducer,
  getLocationsReducer,
  addLocationAreaReducer,
  getLocationAreasReducer,
  getServicesReducer,
  addServicePricingReducer,
  apiCallReducer,
  getLocationAreasFromLocationReducer,
  setSearchedTicketDetailsReducer,
  setCounterInitilalDataReducer,
  setCancelTicketDetailsReducer,
];

export default function reducer(state = initialState, action) {
  let newState;
  // console.log("Action in reducer", action)
  switch (action.type) {
    // Handle cross-topic actions here
    default:
      newState = state;
      break;
  }
  /* istanbul ignore next */
  return reducers.reduce((s, r) => r(s, action), newState);
}
