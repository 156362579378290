import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actions from './redux/actions';
import {
  DropdownComponent,
  KuposButton,
  KuposDatePicker,
  KuposErrorSuccessModal,
  KuposInput,
  KuposModal,
  KuposTimePicker,
  MaterialInputField,
  TicketConfirmModal,
} from '../common';
import DateService from '../../services/dateService';
import AppData from '../../services/appData';
import CommonService from '../../services/commonService';
import PrintTicket from './printTicket';

export class DetailsPage extends Component {
  static propTypes = {
    home: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      apiResponseForPrinting: { data: [], pnr_no: '' },
      ticketConfirmModalShow: false,
      paymentOptions: [
        { label: 'Efectivo', active: true, value: 'CASH' },
        { label: 'Tarjeta Débito', active: false, value: 'DEBIT_CARD' },
        { label: 'Tarjeta Crédito', active: false, value: 'CREDIT_CARD' },
        { label: 'Trans bancaria', active: false, value: 'BANK_TRANSFER' },
        { label: 'Factura de Crédito', active: false, value: 'CREDIT_INVOICE' },
      ],
      changeTicketDetailsShow: false,
      updateTicketTime: null,
      updateTicketDate: DateService.getTodayString('dd-mm-yyyy'),
    };
  }

  componentDidMount() {
    console.log('All props in details page-----', this.props);

    this.setState({
      ticketDetails:
        this.props.home &&
        this.props.home.searchedTicketDetails &&
        this.props.home.searchedTicketDetails.ticket_details,
      ticketPdf:
        this.props.home &&
        this.props.home.searchedTicketDetails &&
        this.props.home.searchedTicketDetails.pdf_url,
    });
  }

  renderNameAndImage = (image, label, value) => {
    return (
      <div className="three-items font10">
        <div>
          <img src={image} />
        </div>
        <div>
          <span className="bold-text">{label}</span>
        </div>
        <div>
          <span> &nbsp;{value}</span>
        </div>
      </div>
    );
  };

  renderTwoItems = (label, value) => {
    return (
      <div className="two-items font10">
        <span className="bold-text label">{label}</span>
        <span>{value}</span>
      </div>
    );
  };

  onCancelButtonPress = () => {
    let currentTime = DateService.getCurrentTimeUTC();

    if (this.state.ticketDetails) {
      this.props.actions
        .apiCall({
          url:
            AppData.BASE_URL +
            `agent/cancelSearch/${this.state.ticketDetails.customer_email}/${this.state.ticketDetails.trip_ref_date}/${this.state.ticketDetails.pnr_id}/${currentTime}`,
        })
        .then(
          res => {
            this.onCancelTicketDetailsFetch(res);
          },
          err => {
            this.onCancelTicketDetailsFetch(err);
          },
        );
    }

    // cancelSearch/:email/:refDate/:pnrId/:cancelTime
  };

  onCancelTicketDetailsFetch = res => {
    console.log('onCancelTicketDetailsFetch---------', res);

    if (res && res.data && res.data.success && res.data.data) {
      this.props.actions.setCancelTicketDetails(res.data.data);

      this.props.history.push('/cancellation-details');

    }  else if(res && res.data && res.data.app_code == "311"){
      this.setState({
        showSuccessModal: true,
        successModalStatus: false,
        successModalMessage: res.data.message,
      });
    }else  {
      this.setState({
        showSuccessModal: true,
        successModalStatus: false,
        successModalMessage: 'Este ticket ya fue cancelado',
      });
    }

    // else if (
    //   res &&
    //   res.data &&
    //   !res.data.success &&
    //   res.data.data &&
    //   res.data.data.can_be_cancelled == false
    // ) {
    //   this.setState({
    //     showSuccessModal: true,
    //     successModalStatus: false,
    //     successModalMessage: 'Este ticket ya fue cancelado',
    //   });
    // }
  };

  onTicketSearch = () => {};

  onPnrChange = value => {
    this.setState({ pnrEntered: value, pnrEnteredError: null });
  };

  onBlur = (value, type) => {
    if (!value) {
      if (type === 'pnr') {
        this.setState({ pnrError: { message: 'Enter pnr' } });
      }
    }
  };

  getTicketDetails = () => {
    if (!this.state.pnrEntered) {
      this.setState({
        pnrEnteredError: { message: 'Please enter PNR' },
        showSnackbar: true,
        alertMessage: 'Please enter PNR',
      });
      return;
    }
    this.setState({ searchScreen: false });
    // let data = {
    //   name: 'raqib',
    //   class: 'jksdgjfh',
    // };
    // this.props.actions.setDataDummy({ data: data });

    this.props.actions
      .apiCall({
        url: AppData.BASE_URL + `ticketDetails/${this.state.pnrEntered}`,
      })
      .then(
        res => {
          this.onTicketDetailsFetch(res);
        },
        err => {
          this.onTicketDetailsFetch(err);
        },
      );

    // console.log('data is', data);

    // this.props.history.push('/details-page');
  };

  onTicketDetailsFetch = res => {
    console.log('data from search -----', res);

    if (res && res.data && res.data.success && res.data.data) {
      // this.props.actions.setSearchedTicketDetails(res.data.data);
      // this.props.history.push('/details-page');
      this.setState({
        ticketDetails: res.data.data.ticket_details,
        updatedTicketDetailStatus: false,
        total_fare:
          Number(res.data.data.ticket_details.net_amount) +
          Number(
            res.data.data.ticket_details.additional_fare
              ? res.data.data.ticket_details.additional_fare
              : 0,
          ),
      });
    }
    console.log('ticket details are -----', this.state.ticketDetails);
    // console.log('onTicketDetailsFetch--------', res);
  };

  getPaymentType = () => {
    let paymentType = this.state.ticketDetails && this.state.ticketDetails.payment_type;
    let status = this.state.ticketDetails && this.state.ticketDetails.status;

    let actualPaymentMethod = '';

    if (status == 'PENDING') {
      actualPaymentMethod = 'Pendiente';
    } else {
      if (paymentType === 'CASH') {
        actualPaymentMethod = 'Efectivo';
      } else if (paymentType === 'DEBIT_CARD') {
        actualPaymentMethod = 'Tarjeta Débito';
      } else if (paymentType === 'CREDIT_CARD') {
        actualPaymentMethod = 'Tarjeta Crédito';
      } else if (paymentType === 'BANK_TRANSFER') {
        actualPaymentMethod = 'Trans bancaria';
      } else if (paymentType === 'CREDIT_INVOICE') {
        actualPaymentMethod = 'Factura de Crédito';
      } else {
        actualPaymentMethod = paymentType;
      }
    }

    return actualPaymentMethod;
  };

  translatedStatus = status => {
    let translatedStatus = '';
    if (status == 'CANCELLED') {
      translatedStatus = 'Cancelado';
    } else if (status == 'CONFIRMED') {
      translatedStatus = 'Confirmado';
    } else if (status == 'PENDING') {
      translatedStatus = 'Reservado';
    } else {
      translatedStatus = status;
    }
    return translatedStatus;
  };

  onTransferChange = (val, type) => {
    this.setState({
      [type]: val,
      [type + 'Error']: null,
    });
  };

  confirmReserveTicket = () => {
    if (!this.validateReservePaymentFields()) {
      alert('Fill all details');
      return;
    }

    let data = {
      client_code: this.state.ticketDetails && this.state.ticketDetails.client_code,
      counter_txn_id: this.state.ticketDetails && this.state.ticketDetails.counter_txn_id,
      user_id: this.state.ticketDetails && this.state.ticketDetails.user_id,
      user_name: this.state.ticketDetails && this.state.ticketDetails.user_name,
      pnr_id: this.state.ticketDetails && this.state.ticketDetails.pnr_id,
      // confirm_time_utc: CommonService.getTimeInSeconds(
      //   DateService.getTodayString('yyyy-mm-dd') +
      //     'T' +
      //     DateService.getCurrentHour() +
      //     ':' +
      //     DateService.getCurrentMinute(),
      // ), 
      confirm_time_utc: DateService.getCurrentTimeUTCNew(),
      confirm_time_stg: `${DateService.getCurrentHour()}:${DateService.getCurrentMinute()} `,
      payment_type: this.state.reservePaymentType ? this.state.reservePaymentType.value : '',
      payment_amount: this.state.ticketDetails && this.state.ticketDetails.total_amount,
      payment_ref_number: this.state.reservePaymentComment ? this.state.reservePaymentComment : '',
    };

    this.props.actions
      .apiCall({
        url: AppData.BASE_URL_V1 + 'agent/confirmReservation',
        data: data,
        method: 'POST',
      })
      .then(
        res => {
          if (res && res.data && res.data.success) {
            var printData = { ...this.state.apiResponseForPrinting };
            printData.data = this.state.ticketDetails;

            this.setState({
              reservePaymentModal: false,
              reservePaymentType: '',
              reservePaymentComment: '',
              apiResponseForPrinting: printData,
              showConfirmTicketSuccessModal: true,
              successModalStatus: true,
              successModalMessage: 'El boleto ha sido confirmado',
            });
            this.forceUpdate();
          }
        },
        err => {
          console.log(err);
        },
      );
  };

  handleDisable = () => {
   
    const ticketDate = Date.parse(
      this.state.ticketDetails && this.state.ticketDetails.trip_ref_date,
    );
    const currentDate = Date.parse(DateService.getTodayString('yyyy-mm-dd'));
    const disable = currentDate > ticketDate ? true : false;
    return disable;
  };

  handleCancelButtonStatus = () => {
    let status = this.state.ticketDetails && this.state.ticketDetails.status == "CANCELLED" ? true : false;
    if (!status) {
      const ticketDate = Date.parse(
        this.state.ticketDetails && this.state.ticketDetails.trip_ref_date,
      );
      const currentDate = Date.parse(DateService.getTodayString('yyyy-mm-dd'));
      const disable = currentDate > ticketDate ? true : false;
      return disable;
    }
    return status;
  }

  handleConfirmButtonStatus = () => {
    let status =
      this.state.ticketDetails && this.state.ticketDetails.status == 'PENDING'? false : true;
    if (!status) {
      const ticketDate = Date.parse(
        this.state.ticketDetails && this.state.ticketDetails.trip_ref_date,
      );
      const currentDate = Date.parse(DateService.getTodayString('yyyy-mm-dd'));
      const disable = currentDate > ticketDate ? true : false;
      return disable;
    }
    return status;
  };
  // ---------- Handle Change Ticket Details-------------------
  handleUpdateTicketDetails = () => {
    const data = {
      pnr_id: this.state.ticketDetails.pnr_id,
      customer_email: this.state.ticketDetails.customer_email,
      customer_name: this.state.ticketDetails.customer_name,
      trip_ref_date: DateService.changeDateFormat(
        this.state.updateTicketDate,
        'dd-mm-yyyy',
        'yyyy-mm-dd',
      ),
      trip_ref_time: this.state.updateTicketTime,
      // trip_ref_date_time: DateService.getTimeInSeconds(new Date()),

      trip_ref_date_time: this.state.ticketDetails && this.state.ticketDetails.trip_ref_date_time,
      upd_date_time: CommonService.getTimeInSeconds(
        DateService.changeDateFormat(this.state.updateTicketDate, 'dd-mm-yyyy', 'yyyy-mm-dd') +
          'T' +
          this.state.updateTicketTime,
      ),

      // upd_date_time: DateService.getTimeInSeconds(new Date()),
    };

  
    this.props.actions
      .apiCall({
        url: AppData.BASE_URL_V1 + `agent/updateTripDateTime`,
        data,
        method: 'POST',
      })
      .then(
        res => {
          this.onUpdateTicketDetailsResponse(res);
        },
        err => {
          this.onUpdateTicketDetailsResponse(err);
        },
      );
  };

  onUpdateTicketDetailsResponse = res => {
    console.log({ res });

    if (res && res.data && res.data.success) {
      this.setState({
        showSuccessModal: true,
        successModalStatus: true,
        successModalMessage: true,
        successModalMessage: 'El servicio fue actualizado',
        changeTicketDetailsShow: false,
        updatedTicketDetailStatus: true,
      });
    }

    if (res && res.data && !res.data.success) {
      this.setState({
        showSuccessModal: true,
        successModalStatus: true,
        successModalMessage: true,
        successModalMessage: res.data.message,
      });
    }
  };

  // ---------- Handle Resend Email -------------------
  handleResendTicket = () => {
    if (this.state.ticketDetails) {
      this.props.actions
        .apiCall({
          url:
            AppData.BASE_URL_V1 +
            `agent/reSend/${this.state.ticketDetails.pnr_id}/${DateService.getTimeInSeconds(
              DateService.getTodayString('yyyy-mm-dd') + 'T00:00:00',
            )}`,
        })
        .then(
          res => {
            this.onResendTicketDetailsFetch(res);
          },
          err => {
            this.onResendTicketDetailsFetch(err);
          },
        );
    } else {
      this.setState({ pnrEnteredError: { message: 'Please enter PNR' } });
    }
  };

  validateReservePaymentFields = () => {
    let errors = 0;
    if (!this.state.reservePaymentType) {
      this.setState({
        reservePaymentTypeError: { message: 'Select a payment type' },
      });
      errors++;
    }

    if (!this.state.reservePaymentComment) {
      this.setState({
        reservePaymentCommentError: { message: 'Payment comment is required' },
      });
      errors++;
      console.log('6');
    }

    if (errors > 0) {
      return false;
    } else {
      return true;
    }
  };
  onResendTicketDetailsFetch = res => {
    console.log({ res });
    if (res && res.data && res.data.success) {
      this.setState({
        showSuccessModal: true,
        successModalStatus: true,
        successModalMessage: true,
        successModalMessage: 'El ticket fue reenviado exitosamente',
      });
    }

    if (res && res.data && !res.data.success) {
      this.setState({
        showSuccessModal: true,
        successModalStatus: false,
        successModalMessage: false,
        successModalMessage: 'Falla en el reenvío del boleto',
      });
    }
  };

  render() {
    console.log('Ticket details in state-----', this.state.ticketDetails);

    return (
      <div className="home-details-page">
        <div className="search-customer-section">
          <div
            className="vendor-pasaje light-vendor-pasaje"
            onClick={() => this.props.history.push('/sales-page')}
          >
            <span className="vendor-icon">
              <img src="/images/my-trips.png" />
            </span>
            <span className="vendor-text">VENDER PASAJE</span>
          </div>

          <div className="common-kupos-input-box document-search" style={{ marginLeft: 10 }}>
            <KuposInput
              type="email"
              className="kupos-border"
              placeholder={'RUT / Nº reserva / Correo'}
              //  value={}
              error={this.state.pnrEnteredError ? true : false}
              errorMessage={this.state.pnrEnteredError ? this.state.pnrEnteredError.message : ''}
              onChange={val => this.onPnrChange(val)}
              onBlur={text => this.onBlur(text, 'pnr')}
            />
          </div>

          <div className="doc-search-button font12">
            <KuposButton onClick={this.getTicketDetails} className="doc-search-button-inner">
              <span className="vendor-icon">
                <img src="/images/ticket-search.png" />
              </span>
            </KuposButton>
          </div>
        </div>

        <div className="details-page-body">
          <div>
            <div>
              {this.renderNameAndImage(
                '/images/my-trips.svg',
                'PNR: ',
                this.state.ticketDetails && this.state.ticketDetails.pnr_id,
              )}
            </div>
            <div>
              {this.renderNameAndImage(
                '/images/source.png',
                'Origen: ',
                this.state.ticketDetails && this.state.ticketDetails.pickup_address,
              )}
            </div>
            <div>
              {this.renderNameAndImage(
                '/images/destination.png',
                'Destino: ',
                this.state.ticketDetails && this.state.ticketDetails.drop_address,
              )}
            </div>
            <div>
              {this.renderNameAndImage(
                '/images/calender.png',
                'Fecha de viaje: ',
                DateService.changeDateFormat(
                  this.state.ticketDetails && this.state.ticketDetails.trip_ref_date,
                  'yyyy-mm-dd',
                  'dd/mm/yyyy',
                ),
              )}
            </div>
            <div>
              {this.renderNameAndImage(
                '/images/clock.png',
                'Hora de recogida: ',
                DateService.ampm(
                  this.state.ticketDetails && this.state.ticketDetails.trip_ref_time,
                ),
              )}
            </div>
            <div>
              {this.renderNameAndImage(
                '/images/car.png',
                'Servicio: ',
                this.state.ticketDetails && this.state.ticketDetails.service_type,
              )}
            </div>
            <div>
              {this.renderNameAndImage(
                '/images/seat.png',
                'Cupos: ',
                this.state.ticketDetails && this.state.ticketDetails.no_of_seats + ' Pasajeros',
              )}
            </div>
            <div>
              {this.renderNameAndImage(
                '/images/calender.png',
                'Fecha de venta: ',
                this.state.ticketDetails && this.state.ticketDetails.booking_date,
              )}
            </div>
            <div>
              {this.renderNameAndImage(
                '/images/my-trips.svg',
                'Vendido por: ',
                this.state.ticketDetails && this.state.ticketDetails.user_name,
              )}
            </div>
            <div>
              {this.renderNameAndImage(
                '/images/info.svg',
                'Estatus: ',
                this.state.ticketDetails && this.translatedStatus(this.state.ticketDetails.status),
              )}
            </div>
            <div>
              <KuposInput
                value={
                  this.state.ticketDetails && this.state.ticketDetails.additional_fare_comments
                }
                placeholder="Comentario"
                disabled
              />
            </div>
          </div>
          <div className="line-container">
            <div className="seperator"></div>
          </div>

          {this.state.ticketDetails ? (
            <div className="final-details-section">
              <div className="two-items-container">
                <div>
                  {' '}
                  {this.renderTwoItems(
                    'Nombre: ',
                    this.state.ticketDetails && this.state.ticketDetails.customer_name,
                  )}
                </div>
                <div>
                  {' '}
                  {this.renderTwoItems(
                    'RUT/ Documento: ',
                    this.state.ticketDetails && this.state.ticketDetails.identity_value,
                  )}
                </div>
                <div>
                  {' '}
                  {this.renderTwoItems(
                    'Teléfono: ',
                    this.state.ticketDetails && this.state.ticketDetails.customer_phone,
                  )}
                </div>
                <div>
                  {' '}
                  {this.renderTwoItems(
                    'Correo eléctronico: ',
                    this.state.ticketDetails && this.state.ticketDetails.customer_email,
                  )}
                </div>
              </div>
              <div className="seperator-bottom-container">
                <div className="seperator-bottom"></div>
              </div>
              <div className="two-items-container">
                <div> {this.renderTwoItems('Medio de pago: ', this.getPaymentType())}</div>

                <div>
                  {this.renderTwoItems(
                    'Monto total: ',
                    this.state.ticketDetails &&
                      'CLP' + CommonService.currency(this.state.ticketDetails.total_amount),
                  )}
                </div>

                <div>
                  {this.renderTwoItems(
                    'Descuento total: ',
                    (this.state.ticketDetails &&
                      CommonService.currency(this.state.ticketDetails.discount_amount)) ||
                      'CLP0',
                  )}
                </div>

                <div>
                  {' '}
                  {this.renderTwoItems(
                    'Tarifa adicional: ',
                    this.state.ticketDetails &&
                      'CLP' + CommonService.currency(this.state.ticketDetails.additional_fare),
                  )}
                </div>

                <div>
                  {this.renderTwoItems(
                    'Monto neto: ',
                    this.state.ticketDetails &&
                      'CLP' +
                        CommonService.currency(
                          this.state.ticketDetails.total_amount +
                            this.state.ticketDetails.additional_fare -
                            this.state.ticketDetails.discount_amount,
                        ),
                    // 'CLP' + this.state.ticketDetails.price_type === 'PER_PERSON'
                    // ? CommonService.currency(
                    //     this.state.ticketDetails.price * this.state.ticketDetails.no_of_seats -
                    //       this.state.ticketDetails.discount_amount,
                    //   )
                    // : CommonService.currency(
                    //     this.state.ticketDetails.price - this.state.ticketDetails.discount_amount,
                    //   ),
                  )}
                </div>

                <div>
                  {this.renderTwoItems(
                    'Cargos de cancelación: ',
                    this.state.ticketDetails &&
                      'CLP' + CommonService.currency(this.state.ticketDetails.cancellation_charge),
                  )}
                </div>

                <div>
                  {this.renderTwoItems(
                    'Monto de reembolso: ',
                    this.state.ticketDetails &&
                      'CLP' + CommonService.currency(this.state.ticketDetails.refund_amount),
                  )}
                </div>
              </div>
            </div>
          ) : null}
        </div>

        <div className="bottom-button-conatiner pb-3">
          <KuposButton
            onClick={() => {
              this.setState({
                changeTicketDetailsShow: true,
                updateTicketTime:
                  this.state.ticketDetails && this.state.ticketDetails.trip_ref_time,
                updateTicketDate:
                  this.state.ticketDetails &&
                  DateService.changeDateFormat(
                    this.state.ticketDetails.trip_ref_date,
                    'yyyy-mm-dd',
                    'dd-mm-yyyy',
                  ),
              });
            }}
            disabled={this.handleCancelButtonStatus()}
            // disabled={this.handleDisable()}
          >
            <span className="vendor-icon font10">CAMBIAR PASAJE</span>
          </KuposButton>

          <KuposButton
            onClick={() => this.setState({ showConfirmModal: true })}
            disabled={this.handleConfirmButtonStatus()}
          >
            <span className="vendor-icon font10">CONFIRMAR PASAJE</span>
          </KuposButton>

          <KuposButton onClick={this.onCancelButtonPress} disabled={this.handleCancelButtonStatus()}>
            <span className="vendor-icon font10">CANCELAR PASAJE</span>
          </KuposButton>
          <KuposButton onClick={this.handleResendTicket} disabled={this.handleCancelButtonStatus()}>
            <span className="vendor-icon font10">REENVIAR PASAJE</span>
          </KuposButton>
          <KuposButton
            // onClick={this.onBookButtonPress}
            disabled={true}
          >
            <span className="vendor-icon font10">VER HISTORIAL DEL PASAJE</span>
          </KuposButton>
        </div>

        <KuposModal
          showModal={this.state.changeTicketDetailsShow}
          onHide={() => this.setState({ changeTicketDetailsShow: false })}
        >
          <div className="p-4">
            <div className="bold-text">
              Cambios de fecha o hora de boleto :{' '}
              {this.state.ticketDetails && this.state.ticketDetails.pnr_id}
            </div>
            <div className="bold-text">
              Fecha actual:{' '}
              {this.state.ticketDetails &&
                DateService.changeDateFormat(
                  this.state.ticketDetails.trip_ref_date,
                  'yyyy-mm-dd',
                  'dd-mm-yyyy',
                )}
            </div>
            <div className="bold-text">
              Hora actual: {this.state.ticketDetails && this.state.ticketDetails.trip_ref_time}{' '}
              horas
            </div>
            <div className="d-flex align-items-center my-2">
              <div className="bold-text mr-8">Seleccionar fecha: </div>
              <KuposDatePicker
                // minDate={this.state.updateTicketDate}
                minDate={DateService.getTodayString()}
                selectedDate={this.state.updateTicketDate}
                onDateChange={data => {
                  this.setState({
                    updateTicketDate: data,
                    updateTicketDateError: null,
                  });
                }}
                placeholder={'Date'}
                newStyle="border-less-date-input"
                style={{ border: 'lightgray solid 1px', borderRadius: '10px' }}
              />
            </div>

            <div className="d-flex align-items-center">
              <div className="bold-text mr-10 ">Seleccionar hora: </div>
              <KuposTimePicker
                value={this.state.updateTicketTime}
                onChange={val => this.setState({ updateTicketTime: val })}
                placeholder={this.props.optional ? '' : '00:00'}
                hideIcon={true}
                showIconsOnAmPm={true}
                showAmPm={true}
                optional={this.props.optional}
                showPicker={true}
                style={{ width: '150px' }}
              />
            </div>

            <div className="d-flex mt-3">
              <KuposButton onClick={this.handleUpdateTicketDetails} className="mr-5">
                Actualizar fecha y hora del boleto
              </KuposButton>

              <KuposButton onClick={() => this.setState({ changeTicketDetailsShow: false })}>
                Cancelar
              </KuposButton>
            </div>
          </div>
        </KuposModal>

        <TicketConfirmModal
          showModal={this.state.ticketConfirmModalShow}
          onHide={() => this.setState({ ticketConfirmModalShow: false })}
          image={'/images/destination.png'}
          // t={this.props.t}
        />

        <KuposErrorSuccessModal
          success={this.state.showConfirmModal}
          showModal={this.state.showConfirmModal}
          showButton1={true}
          showButton2={true}
          buttonText1={'OK'}
          buttonText2={'Cancel'}
          onHide={() => this.setState({ showConfirmModal: false })}
          onButtonClick1={() =>
            this.setState({ reservePaymentModal: true, showConfirmModal: false })
          }
          onButtonClick2={() => this.setState({ showConfirmModal: false })}
          bodyText={'¿Usted recolectó el pago de este boleto? Aceptar'}
        />

        <KuposModal
          showModal={this.state.reservePaymentModal}
          onHide={() => this.setState({ reservePaymentModal: false })}
        >
          <div className="common-kupos-error-success-modal">
            <div>CONFIRMAR PASAJE PARA EL PNR:</div>
            <div>{this.state.ticketDetails && this.state.ticketDetails.pnr_id}</div>
            <div className="at-horizonatal-line"></div>

            <div className="home-selection-input-box font10 select-box" style={{ marginTop: 30 }}>
              <div>
                <span className="label font11">{'Tipo'}</span>
              </div>

              <DropdownComponent
                onChange={val => this.onTransferChange(val, 'reservePaymentType')}
                placeholder={'Moneda'}
                options={this.state.paymentOptions}
                value={this.state.reservePaymentType}
                error={this.state.reservePaymentTypeError ? true : false}
                errorMessage={
                  this.state.reservePaymentTypeError
                    ? this.state.reservePaymentTypeError.message
                    : ''
                }
              />
            </div>

            <div className="common-kupos-input-box" style={{ marginTop: 20 }}>
              <MaterialInputField
                // label={'Información adicional'}
                placeholder={'Comentario'}
                value={this.state.reservePaymentComment}
                multiline={true}
                rows={2}
                onChange={text => this.onTransferChange(text, 'reservePaymentComment')}
                onBlur={() => this.onBlur()}
                error={this.state.reservePaymentCommentError}
                errorMessage={
                  this.state.reservePaymentCommentError
                    ? this.state.reservePaymentCommentError.message
                    : ''
                }
              />
            </div>
            <div className="button-container">
              <div style={{ marginRight: 10 }}>
                <KuposButton onClick={() => this.confirmReserveTicket()}>
                  <span>Confirmar Pasaje</span>
                </KuposButton>
              </div>

              <div>
                <KuposButton onClick={() => this.setState({ reservePaymentModal: false })}>
                  <span>Cancelar</span>
                </KuposButton>
              </div>
            </div>
          </div>
        </KuposModal>

        <div style={{ visibility: 'hidden' }}>
          <PrintTicket printData={this.state.apiResponseForPrinting}></PrintTicket>
        </div>

        <KuposErrorSuccessModal
          success={this.state.successModalStatus}
          showModal={this.state.showSuccessModal}
          onButtonClick={() => {
            this.setState({
              showSuccessModal: false,
              successModalStatus: false,
              successModalMessage: false,
            });
            if (this.state.updatedTicketDetailStatus) {
              this.props.actions
                .apiCall({
                  url: AppData.BASE_URL + `ticketDetails/${this.state.pnrEntered}`,
                })
                .then(
                  res => {
                    this.onTicketDetailsFetch(res);
                  },
                  err => {
                    this.onTicketDetailsFetch(err);
                  },
                );
            }
          }}
          bodyText={this.state.successModalMessage}
        />

        <KuposErrorSuccessModal
          success={this.state.successModalStatus}
          showModal={this.state.showConfirmTicketSuccessModal}
          onButtonClick={() => {
            this.setState({
              showConfirmTicketSuccessModal: false,
              successModalStatus: false,
              successModalMessage: false,
            });

            this.state.successModalStatus &&
              CommonService.printCancelTicket(
                document.getElementById('confirm-reserve-ticket').innerHTML,
              );
            this.state.showConfirmTicketSuccessModal && this.props.history.push('/sales-page');
          }}
          bodyText={this.state.successModalMessage}
        />

        <div
          id="confirm-reserve-ticket"
          style={{ visibility: 'hidden' }}
          className="section-to-print"
        >
          <img
            src="/images/delfos_print_white.png"
            style={{ marginBottom: '20px', width: '50%' }}
            width={'200'}
            className="logo-img"
          />

          <div>
            <span>Detalles del pasejero y de la reserva</span>
          </div>
          <table style={{ borderColor: '#000' }} className="ticket_table">
            <tbody>
              <tr>
                <td> Origen :</td>
                <td>
                  {(this.state.ticketDetails && this.state.ticketDetails.pickup_address) || ''}
                </td>
              </tr>
              <tr>
                <td> Destino : </td>
                <td>{(this.state.ticketDetails && this.state.ticketDetails.drop_address) || ''}</td>
              </tr>

              <tr>
                <td>Tipo de servicio : </td>
                <td>{this.state.ticketDetails && this.state.ticketDetails.service_type}</td>
              </tr>

              <tr>
                <td> Cupos :</td>
                <td> {(this.state.ticketDetails && this.state.ticketDetails.no_of_seats) || ''}</td>
              </tr>

              <tr>
                <td> PNR : </td>
                <td>
                  {(this.state.ticketDetails && this.state.ticketDetails.pnr_id) || 'AT000000'}
                </td>
              </tr>

              <tr>
                <td> Normbre del Pasajero : </td>
                <td>
                  {(this.state.ticketDetails && this.state.ticketDetails.customer_name) || ' '}
                </td>
              </tr>

              <tr>
                <td>
                  {(this.state.ticketDetails && this.state.ticketDetails.identity_type) || 'RUT'}
                </td>
                <td>{this.state.ticketDetails && this.state.ticketDetails.identity_value}</td>
              </tr>

              <tr>
                <td>Nacionalidad : </td>
                <td>{(this.state.ticketDetails && this.state.ticketDetails.nationality) || ''}</td>
              </tr>

              <tr>
                <td> Fecha de Viaje : </td>
                <td>
                  {DateService.changeDateFormat(
                    this.state.ticketDetails && this.state.ticketDetails.trip_ref_date,
                    'yyyy-mm-dd',
                    'dd/mm/yyyy',
                  )}
                </td>
              </tr>

              <tr>
                <td>Hora de recogida :</td>
                {/* <td> {this.props.printData.time}</td> */}
                <td>
                  {' '}
                  {DateService.ampm(
                    this.state.ticketDetails && this.state.ticketDetails.trip_ref_time,
                  )}
                </td>
              </tr>

              <tr>
                <td> Precio de Pasaje : </td>
                <td>
                  <span style={{ color: '#1dade4' }}>
                    {CommonService.currency(
                      this.state.ticketDetails && this.state.ticketDetails.price,
                    )}
                  </span>{' '}
                </td>
              </tr>

              <tr>
                <td> Descuento :</td>
                <td>
                  {' '}
                  {CommonService.currency(
                    this.state.ticketDetails && this.state.ticketDetails.discount_amount,
                  ) || 0}
                </td>
              </tr>

              <tr>
                <td> Tarifa adicional :</td>
                <td>
                  {' '}
                  {CommonService.currency(
                    this.state.ticketDetails && this.state.ticketDetails.additional_fare,
                  ) || 0}
                </td>
              </tr>

              <tr>
                <td>Monto neto: </td>
                <td>
                  {' '}
                  {this.state.ticketDetails && this.state.ticketDetails.price_type === 'PER_PERSON'
                    ? CommonService.currency(
                        this.state.ticketDetails.price * this.state.ticketDetails.no_of_seats +
                          this.state.ticketDetails.additional_fare -
                          this.state.ticketDetails.discount_amount || 0,
                      )
                    : CommonService.currency(
                        (this.state.ticketDetails &&
                          this.state.ticketDetails.price +
                            this.state.ticketDetails.additional_fare -
                          this.state.ticketDetails.discount_amount) ||
                          0,
                      )}
                </td>
              </tr>

              <tr>
                <td>Reservado por : </td>
                <td>{this.state.ticketDetails && this.state.ticketDetails.user_name} </td>
              </tr>

              <tr>
                <td>Impreso por : </td>
                <td>{this.state.ticketDetails && this.state.ticketDetails.user_name}</td>
              </tr>
            </tbody>
          </table>

          <div className="bottom-line">
            <span>Impreso por: </span>
            <span className="bold-text">{CommonService.currentUser.user_name}</span>{' '}
            <span style={{ marginLeft: 20 }}>On: </span>
            <span className="bold-text">{`${DateService.getTodayString()} - ${DateService.ampm(
              DateService.getCurrentHour() + ':' + DateService.getCurrentMinute(),
            )}`}</span>
          </div>

          <span>
            {/* <td style={center}> */}
            <b>Terminos y Condiciones</b>
            {/* </td> */}
          </span>
          <span>
            {/* <td> */}
            <span className="tAndCPrint">
              Nota: este boleto es válido para la fecha y hora señalada. La devolución y/o cambio se
              aceptará hasta 4 horas antes del inicio del servicio. Si canceló en efectivo se
              devolverá: el 85% (Art. 67 d.s. 212/92 MT). Si el valor del equipaje excede de las 5
              UTM, es su obligación declarar previamente en la oficina de origen (Art. 76 d.s.
              212/92 MT). Los boletos no confirmados tendrán una vigencia de 6 meses, desde la fecha
              de venta. No se aceptan boletos rotos o enmendados. La empresa no se hace responsable
              por perdidas al interior del bus. La empresa se reserva el derecho a cambiar el tipo
              de bus por otro de similares características.
            </span>
            {/* </td> */}
          </span>

          <span>
            {/* <td style={center}> */}
            <b>D.S. 212 Artículo N# 68</b>
            {/* </td> */}
          </span>
          <span>
            {/* <td> */}
            <span className="tAndCPrint">
              Usted tiene derecho a llevar hasta 30 kilos de equipaje sin costo, siempre que el
              volumen no exceda 180 decímetros cúbicos, si peso y decímetros cúbicos del equipaje
              son mayores al establecido, se deberá cancelar el costo adicional. Compra tus boletos
              en
            </span>
            {/* </td> */}
          </span>
          <span>
            <td>
              <hr className="horizontalRuler" />
            </td>
          </span>

          <span>
            <td className="centerPrintTicket">www.transferdelfos.com</td>
          </span>
        </div>
      </div>
    );
  }
}

/* istanbul ignore next */
function mapStateToProps(state) {
  console.log('State in details page-----', state);
  return {
    home: state.home,
  };
}

/* istanbul ignore next */
function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...actions }, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(DetailsPage);
