import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actions from './redux/actions';
import KuposCheckbox from './KuposCheckbox';
import DateService from '../../services/dateService';
import KuposModal from './KuposModal';
import CommonService from '../../services/commonService';
import KuposErrorSuccessModal from './KuposErrorSuccessModal';
import ApiUrls from '../../services/apiUrls';
import SvgHome from './SvgHome';

export class KuposPoolModal extends Component {
         static propTypes = {
           common: PropTypes.object.isRequired,
           actions: PropTypes.object.isRequired,
           image: PropTypes.object.isRequired,
           googlePlay: PropTypes.string,
           appStore: PropTypes.string,
           t: PropTypes.func,
           metaData: PropTypes.object,
         };

         renderCancellationCard = image => {
           return (
             //  <div className="cancel-container">
             //    <div className={'print-cancel-section-modal cancel-modal'}>
             <div className="kupos-pool-popup-image-back">
               <img src={image} />

               <div className="kupos-pool-modal-text-container">
                 <span className="download-app-overlay-text-heading bold-text font20 kupos-pool-modal-text-heading">
                   {this.props.t('HOME.KUPOS_POOL_TITLE')}
                 </span>
                 <div className="kupos-pool-modal-image">
                   <img className="pool-image" src="/images/kupos_pool.svg" alt="" />
                 </div>

                 <span className="kupos-pool-modal-text-sub-heading font12">
                   <span className="bold-text">KuposPool</span>&nbsp;
                   {this.props.t('HOME.KUPOS_POOL_AVAILABLE')}
                 </span>
                 <span className="download-app-overlay-text-heading bold-text kupos-pool-download-app font15">
                   {this.props.t('HOME.KUPOS_APP_DOWNLOAD')}
                 </span>

                 <div className="download-app-kupos-pay-image kupos-pool-modal-image-stores">
                   {this.props.metaData ? (
                     <a href={this.props.metaData.social_media_urls.androidUrl}>
                       <img src={this.props.googlePlay} alt="" />
                     </a>
                   ) : null}
                   {this.props.metaData ? (
                     <a href={this.props.metaData.social_media_urls.iosUrl}>
                       <img src={this.props.appStore} alt="" />
                     </a>
                   ) : null}
                 </div>
               </div>
             </div>
           );
         };

         render() {
           return (
             <div className="common-login-modal">
               <KuposModal
                 // showModal={true}
                 showModal={this.props.showModal}
                 onHide={this.props.onHide}
                 size="md"
                 ariaLabel="login-modal"
               >
                 <div className="kupos-pool-modal-container">
                   <div className="close-button pointer" onClick={this.props.onHide}>
                     <SvgHome name="close" />
                   </div>
                   {this.renderCancellationCard(this.props.image)}
                 </div>
               </KuposModal>
             </div>
           );
         }
       }

/* istanbul ignore next */
function mapStateToProps(state) {
  return {
    common: state.common,
  };
}

/* istanbul ignore next */
function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...actions }, dispatch)
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(KuposPoolModal);
