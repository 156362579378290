import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actions from './redux/actions';

export class DefaultPage extends Component {
  static propTypes = {
    sales: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired,
  };

  render() {
    return <div className="sales-default-page">Page Content: sales/DefaultPage</div>;
  }
}

/* istanbul ignore next */
function mapStateToProps(state) {
  return {
    sales: state.sales,
  };
}

/* istanbul ignore next */
function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...actions }, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(DefaultPage);
