import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import {
  HOME_SET_CANCEL_TICKET_DETAILS,
} from './constants';

export function setCancelTicketDetails(data) {
  return {
    type: HOME_SET_CANCEL_TICKET_DETAILS,
    data: data
  };
}

export function useSetCancelTicketDetails() {
  const dispatch = useDispatch();
  const boundAction = useCallback((...params) => dispatch(setCancelTicketDetails(...params)), [dispatch]);
  return { setCancelTicketDetails: boundAction };
}

export function reducer(state, action) {
  switch (action.type) {
    case HOME_SET_CANCEL_TICKET_DETAILS:
      return {
        ...state,
        cancelTicketDetails :action.data
      };

    default:
      return state;
  }
}
