import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux';
import DateService from '../../services/dateService';
import {
  DropdownComponent, KuposButton, KuposDatePicker, KuposErrorSuccessModal, KuposInput,
} from '../common';
import { apiCall } from './redux/apiCall';
import ApiUrls from '../../services/apiUrls';
import CommonService from '../../services/commonService';
import { Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import Loader from 'react-loader-spinner';


const initialState = {
  entryDate: DateService.getTodayString(),
  reference_name: '',
  brand: '',
  patent: '',
  owner: '',
  no_of_seats: '',
  status: '',
}

const statusTypes = [
  { value: 'ACTIVE', label: 'Activo' },
  { value: 'INACTIVE', label: 'Inactivo' },
]

const AddCvehiclePage = ({ history, match }) => {
  const dispatch = useDispatch();
  const [modal, setModal] = useState({});
  const [values, setValues] = useState(initialState);
  const [loader, setLoader] = useState(true)
  const [snackbar, setSnackbar] = useState({});
  const [showForm, setShowForm] = useState(true)
  const [count, setCount] = useState('')



  const id = match.params ? Number(match.params.id) : null;
  console.log({ values })

  // Counter
  useEffect(() => {
    let interval
    if (count) {
      interval = setInterval(() => {
        setCount((currentCount) => --currentCount)
      }, 1000);
    }
    // Redirect once the count is === 0
    count === 0 && history.push('/c-vehicles');
    // CleanUp
    return () => clearInterval(interval)
  }, [count, history])

  useEffect(() => {
    if (id) {
      getList();
    } else {
      setLoader(false)
    }
    // eslint-disable-next-line
  }, [id]);

  const getList = () => {
    dispatch(
      apiCall({
        url: ApiUrls.getVehicles(), callback: res => {
          setLoader(false)
          if (res && res.data && res.data.success) {
            const found = res.data.data.find(f => f.id === id);
            if (found) {
              console.log({ found });
              getParticularCvehicle(found);
            } else {
              setSnackbar({
                showSnackbar: true,
                persist: true,
                alertMessage: 'Record not found'
              });
              setShowForm(false)
              setCount(5)
            }
          }
        }
      })
    )
  };
  const closeSnackbar = () => {
    setSnackbar({ showSnackbar: false });
  };

  const getParticularCvehicle = (cVehicles) => {
    const status = cVehicles.status === "ACTIVE" ? statusTypes[0] : statusTypes[1]
    let data = cVehicles
    data.statusType = status
    data.no_of_seats = cVehicles.no_of_seats.toString()
    data.entryDate = DateService.changeDateFormat(cVehicles.vehicle_entry_datetime_str, 'yyyy-mm-dd', 'dd-mm-yyyy',);
    setValues(data)
  }


  const onBlur = () => { }
  const onChange = (val, type) => {
    setValues({ ...values, [type]: val, [type + 'Error']: null });
  }

  const validate = () => {
    if (!values.reference_name) {
      setValues({ ...values, reference_nameError: 'Ingrese el nombre del servicio' });
      return false;
    } else if (!values.brand) {
      setValues({ ...values, brandError: 'Ingrese Servicios de servicio' });
      return false;
    } else if (!values.patent) {
      setValues({ ...values, patentError: 'Ingrese el número de asientos' });
      return false;
    } else if (!values.owner) {
      setValues({ ...values, ownerError: 'Introduzca la fecha de inicio' });
      return false;
    } else if (!values.no_of_seats) {
      setValues({ ...values, no_of_seatsError: 'Ingrese la fecha de finalización' });
      return false;
    } else if (!values.statusType) {
      setValues({ ...values, statusTypeError: 'Required' });
      return false;
    }
    return true;
  };

  const createOrUpdate = () => {
    if (!validate()) {
      return;
    }

    let data = {}

    if (id) {
      data = {
        "id": values.id,
        "reference_name": values.reference_name,
        "brand": values.brand,
        "patent": values.patent,
        "owner": values.owner,
        "no_of_seats": Number(values.no_of_seats),
        "status": values.statusType.value,
        //"created_datetime_str": values.cVehicleToEdit.created_datetime_str,
        // "created_by": values.cVehicleToEdit.created_by,
        "updation_datetime_str": DateService.changeDateFormat(values.entryDate, 'dd-mm-yyyy', 'yyyy-mm-dd',),
        "updation_datetime": CommonService.getTimeInSeconds(values.entryDate),
        "updated_by": values.updated_by
      }
    } else {
      data = {
        "reference_name": values.reference_name,
        "brand": values.brand,
        "patent": values.patent,
        "owner": values.owner,
        "no_of_seats": Number(values.no_of_seats),
        "status": values.statusType.value,
        "vehicle_entry_datetime_str": DateService.changeDateFormat(values.entryDate, 'dd-mm-yyyy', 'yyyy-mm-dd',),
        "vehicle_entry_datetime": CommonService.getTimeInSeconds(values.entryDate),
      };
    }

    // console.log(data)
    dispatch(
      apiCall(
        {
          url: ApiUrls.getVehicles(),
          method: (id ? 'PUT' : 'POST'),
          data, callback: res => onUpdateResponse(res)
        })
    )


  }

  const onUpdateResponse = (res) => {
    if (res.data && res.data.success && res.data.data) {
      console.log('Update vehicle data resonse : ');
      console.log(res.data.data);
      setModal({
        show: true,
        success: true,
        bodyText: "Éxito" || (id ? 'Éxito' : 'Éxito')

      })
    } else if (!res.data.success && res.data.message) {
      setModal({
        show: true,
        success: false,
        bodyText: res.data.message
      })
    } else {
      setModal({
        show: true,
        success: false,
        bodyText: 'Fallar'
      })
    }
  }


  if (loader) {
    return (
      <div className="loader-container">
        <Loader visible={loader} type="Bars" color="#637290" height={80} width={80} />
      </div>
    )
  }


  return showForm ? <div className="home-add-cvehicle-page pr-3 pl-3">
    <h2 className="mt-4 mb-4 font-bold">
      {id ? 'Editar Vehículo' : 'Crear nuevo Vehículo'}
    </h2>
    <div className="common-kupos-input-box" style={{ marginTop: 10 }}>
      <KuposInput
        type="text"
        className="kupos-border"
        placeholder={'Nombre'}
        label={'Nombre'}
        disabled={id ? true : false}
        value={values.reference_name}
        error={values.reference_nameError ? true : false}
        errorMessage={
          values.reference_nameError ? values.reference_nameError : ''
        }
        onChange={text => onChange(text, 'reference_name')}
        onBlur={text => onBlur(text, 'reference_name')}
      />
    </div>
    <div className="common-kupos-input-box" style={{ marginTop: 10 }}>
      <KuposInput
        type="text"
        className="kupos-border"
        placeholder={'Marca de Vehículo'}
        label={'Marca de Vehículo'}
        value={values.brand}
        error={values.brandError ? true : false}
        errorMessage={values.brandError ? values.brandError : ''}
        onChange={val => onChange(val, 'brand')}
        onBlur={text => onBlur(text, 'brand')}
      />
    </div>
    <div className="common-kupos-input-box" style={{ marginTop: 10 }}>
      <KuposInput
        type="text"
        className="kupos-border"
        placeholder={'Patente'}
        label={'Patente'}
        disabled={id ? true : false}
        value={values.patent}
        error={values.patentError ? true : false}
        errorMessage={values.patentError ? values.patentError : ''}
        onChange={val => onChange(val, 'patent')}
        onBlur={text => onBlur(text, 'patent')}
      />
    </div>
    <div className="common-kupos-input-box" style={{ marginTop: 10 }}>
      <KuposInput
        type="text"
        className="kupos-border"
        placeholder={'Propietario'}
        label={'Propietario'}
        disabled={id ? true : false}
        value={values.owner}
        error={values.ownerError ? true : false}
        errorMessage={values.ownerError ? values.ownerError : ''}
        onChange={val => onChange(val, 'owner')}
        onBlur={text => onBlur(text, 'owner')}
      />
    </div>
    <div className="common-kupos-input-box" style={{ marginTop: 10 }}>
      <KuposInput
        type="number"
        className="kupos-border"
        placeholder={'Numero de asientos'}
        label={'Numero de asientos'}
        value={values.no_of_seats}
        error={values.no_of_seatsError ? true : false}
        errorMessage={values.no_of_seatsError ? values.no_of_seatsError : ''}
        onChange={text => onChange(text, 'no_of_seats')}
        onBlur={text => onBlur(text, 'no_of_seats')}
      />
    </div>
    <div className="home-selection-input-box datebox" style={{ marginTop: 10 }}>
      <div><span className='input-label'>Fecha de ingreso</span></div>
      <KuposDatePicker
        minDate={DateService.getTodayString()}
        selectedDate={values.entryDate}
        onDateChange={date => onChange(date, 'entryDate')}
        placeholder={'Fecha de ingreso'}
      />
    </div>
    <div style={{ marginTop: 10 }}>
      <div><span className='input-label'>Estatus</span></div>

      <DropdownComponent
        onChange={val => onChange(val, 'statusType')}
        placeholder={'Ubicación'}
        options={statusTypes}
        error={values.statusTypeError ? true : false}
        errorMessage={values.statusTypeError ? values.statusTypeError : ''}
        // value={id ? values.statusType == 'ACTIVE' ? statusTypes[0] : statusTypes[1] : statusTypes && statusTypes.value}
        value={values.statusType}
      />
    </div>
    <div className="button-container" style={{ marginTop: 20, marginBottom: 40 }}>
      <KuposButton onClick={createOrUpdate}>
        <span className="font11">
          {id ? 'Actualizar Vehículo' : 'Crear Vehículo'}
        </span>
      </KuposButton>
    </div>

    <KuposErrorSuccessModal
      success={modal.success}
      showModal={modal.show}
      onButtonClick={() => {
        if (modal.success) history.push('/c-vehicles');
        setModal({});
      }}
      bodyText={modal.bodyText}
    />

  </div> : <Snackbar
    open={snackbar.showSnackbar}
    autoHideDuration={snackbar.persist ? 10000000000 : 5000}
    anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
    onClose={closeSnackbar}
  >
    <Alert onClose={closeSnackbar} severity="error">
      {snackbar.alertMessage} we are redirecting you back in {count} seconds

    </Alert>
  </Snackbar>
}

export default AddCvehiclePage