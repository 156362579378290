import {
  COMMON_SET_CLIENT_INFO,
} from './constants';

export function setClientInfo(data) {
  return {
    type: COMMON_SET_CLIENT_INFO,
    data:data
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case COMMON_SET_CLIENT_INFO:
      return {
        ...state,
        clientInfo:action.data
      };

    default:
      return state;
  }
}
