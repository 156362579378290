import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actions from './redux/actions';
import * as commonActions from '../common/redux/actions';
import { KuposButton, KuposErrorSuccessModal, KuposInput } from '../common';
import ApiUrls from '../../services/apiUrls';
import CommonService from '../../services/commonService';
import DateService from '../../services/dateService';

var global = window;

export class LoginPage extends Component {
  static propTypes = {
    home: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.selectServiceOptions = [
      { value: 1, label: 'Hacia aeropuerto' },
      { value: 2, label: 'Desde aeropuerto' },
    ];

    this.state = {
      emailError: false,
      passwordError: false,
      identityVal: null,
    };
  }

  onBlur = (value, text) => {};

  onChange = (value, type) => {
    this.setState({
      [type]: value,
      [type + 'Error']: null,
    });
  };

  goToDashboard = () => {
    if (!this.state.email) {
      this.setState({ emailError: 'please enter email' });
      return;
    } else if (!this.state.password) {
      this.setState({ passwordError: 'please enter password' });
      return;
    }

    // let data = {
    //   email: this.state.email,
    //   password: this.state.password,
    // };

    // let login_unix_time = DateService.getCurrentTimeUTC();

    let data = {
      login_id: this.state.email,
      password: this.state.password,
      login_unix_time: DateService.getCurrentTimeUTC(),
      login_date_stg: DateService.getTodayString(),
      login_time_stg: DateService.getCurrentHour() + ':' + DateService.getCurrentMinute(),
      login_day_begin_unix_time: CommonService.getTimeInSeconds(
        DateService.changeDateFormat(DateService.getTodayString(), 'dd-mm-yyyy', 'yyyy-mm-dd') +
          'T00:00:00',
      ),
      login_day_end_unix_time: CommonService.getTimeInSeconds(
        DateService.changeDateFormat(DateService.getTodayString(), 'dd-mm-yyyy', 'yyyy-mm-dd') +
          'T23:59:59',
      ),
    };

    console.log(this.props);

    this.props.actions.login({ url: ApiUrls.login(), data: data, method: 'POST' }).then(
      res => {
        this.onResponse(res);
      },
      err => {
        this.onResponse(err);
      },
    );

    // if (this.validate()){

    // }
    // console.log('Email and possword is', this.state.email, this.state.password);
  };

  onResponse = result => {
    console.log('login response from api--------', result);
    if (
      result.data &&
      result.data.success &&
      result.data.data &&
      result.data.data.authentication_details &&
      result.data.data.authentication_details.access_token
    ) {
      global.token = result.data.data.authentication_details.access_token;
      CommonService.storeLoginDetails(result.data.data, this.props.actions.setLoginData);
      localStorage.setItem(
        'loginTime',
        `${DateService.getTodayString()}T${DateService.getCurrentTime()}`,
      );

      if (result.data.data.is_counter_applicable) {
        //case if counter is already assigned and not closed earlier
        if (result.data.data.is_counter_in_open_status) {
          CommonService.storeCounterDetails(result.data.data.open_counter_shift_if_any);
          this.props.history.push('/counter-dashboard');
        } else {
          //if counter is not assigned ealrier may be fresh log in
          this.props.history.push('/choose-counter');
        }
      } else {
        //if counter is not applicable for user (case when user does not have sales role)
        this.props.history.push('/');
      }

      // this.props.history.push('/counter-selection');
    } else {
      this.setState({
        showSuccessModal: true,
        successModalStatus: true,
        successModalMessage:

          // result.data.data && result.data.app_code === '116'
          //   ? 'El usuario ya tiene una caja abierta ' +
          //     result.data.data +
          //     '. Por favor, abra sesión de nuevo para continuar'
          //   : '¡El nombre de usuario o la contraseña son incorrectos!',

          result.data.data && result.data.app_code === '116'
            ? 'El usuario ya tiene una caja abierta ' +
              result.data.data +
              '. Por favor, abra sesión de nuevo para continuar'
            : result.data.data && result.data.app_code === '1033'
            ? 'La sucursal del usuario no tiene cajas asignadas'
            : '¡El nombre de usuario o la contraseña son incorrectos!',
      });
    }
  };

  render() {
    console.log('Props are ', this.props);
    return (
      <div className="home-login-page">
        <div className="login-form-container">
          <div className="kupos-card">
            <div className="login-logo-container">
              <img
                style={{ maxHeight: 45, width: 'auto' }}
                src={
                  (this.props.common.clientInfo && this.props.common.clientInfo.logo_path) ||
                  '/images/kupos-logo2.png'
                }
              />
            </div>

            <div className="login-title-container">
              <span className="bold-text font13">Inicio de sesión</span>
            </div>

            <div className="input-label">
              <span className="bold-text font10">Nombre de usuario</span>
            </div>
            <div className="common-kupos-input-box document-search">
              <KuposInput
                type="email"
                className="kupos-border"
                placeholder="Correo electrónico"
                value={this.state.email}
                error={this.state.emailError ? true : false}
                errorMessage={this.state.emailError ? this.state.emailError.message : ''}
                onChange={val => this.onChange(val, 'email')}
                onBlur={text => this.onBlur(text, 'email')}
              />
            </div>

            <div className="input-label" style={{ marginTop: 10 }}>
              <span className="bold-text font10">Contraseña</span>
            </div>
            <div className="common-kupos-input-box document-search">
              <KuposInput
                type="password"
                className="kupos-border"
                placeholder={'Contraseña'}
                value={this.state.password}
                error={this.state.passwordError ? true : false}
                errorMessage={this.state.passwordError ? this.state.passwordError.message : ''}
                onChange={val => this.onChange(val, 'password')}
                onBlur={text => this.onBlur(text, 'password')}
              />
            </div>

            <div className="button-container" style={{ marginTop: 20 }}>
              <KuposButton onClick={this.goToDashboard}>
                <span className="font11">Ingresar</span>
              </KuposButton>
            </div>
          </div>

          <div className="login-bottom-notice light-text">
            <span>
              Solución de gestión desarrollada y mantenida por
              <span className="bold-text primary-text-color"> kupos.com</span>
            </span>
            <div>
              <span>Todos los derechos reservados.</span>
            </div>
          </div>
        </div>
        <KuposErrorSuccessModal
          success={this.state.successModalStatus}
          showModal={this.state.showSuccessModal}
          onButtonClick={() => {
            this.setState({
              showSuccessModal: false,
              successModalStatus: false,
              successModalMessage: false,
            });
          }}
          bodyText={this.state.successModalMessage}
        />
      </div>
    );
  }
}

/* istanbul ignore next */
function mapStateToProps(state) {
  return {
    home: state.home,
    common: state.common,
  };
}

/* istanbul ignore next */
function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...actions, ...commonActions }, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);
