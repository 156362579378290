import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actions from './redux/actions';
import { KuposCounter } from '.';

export class KuposAtCounter extends Component {
  static propTypes = {
    // common: PropTypes.object.isRequired,
    // actions: PropTypes.object.isRequired,
  };

  render() {
    return (
      <div className="common-kupos-at-counter">
        <div className="at-counter-row">
          <div className="at-icon-text">
            <div>
              <img
                className="at-count-user-type-icon"
                src={
                  this.props.kuposType === 'kupos_adult'
                    ? '/images/kupos-AirportTransfer-Icon-Adult.svg'
                    : this.props.kuposType === 'kupos_kid'
                    ? '/images/kupos-AirportTransfer-Icon-Kids.svg'
                    : this.props.kuposType === 'kupos_infant'
                    ? '/images/kupos-AirportTransfer-Icon-Infants.svg'
                    : null
                }
              />
            </div>
            <div>
              <div className="at-kupos-info">
                <span>
                  {this.props.kuposType === 'kupos_adult'
                    ? 'Adultos'
                    : this.props.kuposType === 'kupos_kid'
                    ? 'Ninos'
                    : this.props.kuposType === 'kupos_infant'
                    ? 'Bebes (sin costo)'
                    : null}
                </span>
              </div>
              <div>
                <span className="at-count-age-text">
                  {this.props.kuposType === 'kupos_adult'
                    ? '+ 13 anos'
                    : this.props.kuposType === 'kupos_kid'
                    ? '2 + 13 anos'
                    : this.props.kuposType === 'kupos_infant'
                    ? 'Manores de 2 anos'
                    : null}
                </span>
              </div>
            </div>
          </div>
          <KuposCounter
            value={this.props.selectedKupos}
            // error={this.state.transferKuposError && this.state.transferKuposError ? true : false}
            // errorMessage={this.state.transferKuposError}
            onChange={val => this.props.onTransferChange(val, 'kupos_adult')}
            noBorder
          />
        </div>
        {this.props.borderBottom ? <div className="at-horizonatal-line"></div> : null}
      </div>
    );
  }
}

/* istanbul ignore next */
function mapStateToProps(state) {
  return {
    common: state.common,
  };
}

/* istanbul ignore next */
function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...actions }, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(KuposAtCounter);
