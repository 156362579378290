import React, { useEffect, useRef, useState } from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { useDispatch } from 'react-redux';
import { apiCall } from './redux/apiCall';
import Select from 'react-select';
import { DropdownComponent, KuposButton, KuposErrorSuccessModal, KuposInput } from '../common';
import ApiUrls from '../../services/apiUrls';
import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from '@material-ui/core';
import DateService from '../../services/dateService';

const initialState = {
  id: '',
  name: '',
  address: '',
  contact_name: '',
  contact_email: '',
  contact_phone: '',
  status: '',
  logo_path: '',
  clientBanner: '',
  subdomain: '',
  email_bcc: '',
  terms: '',
  topupAmount: '',
  notificationThresholdAmount: '',
  firstName: '',
  lastName: '',
  loginEmail: '',
  loginPassword: '',
  confirmLoginPassword: '',
  role: '',
};

const modalInitialState = {
  show: false,
  success: false,
  bodyText: '',
};

const errorState = {};

const AddClientScreen = ({ history, match }) => {
  const dispatch = useDispatch();
  const onResponse = useRef(() => {});
  const [values, setValues] = useState(initialState);
  const [errors, setErrors] = useState(errorState);
  const [modal, setModal] = useState(modalInitialState);
  const [terms, setTerms] = useState('');
  const [buttonLoader, setButtonLoader] = useState(false);
  const [status, setStatus] = useState('');
  const [counterAssigned, setCounterAssigned] = useState('no');
  const [roles, setRoles] = useState([]);
  const [role, setRole] = useState([]);
  const [statuses, setStatuses] = useState([]);

  console.log({ values, terms, status, errors, counterAssigned, roles, statuses, role });

  const id = match.params ? parseInt(match.params.id) : null;

  useEffect(() => {
    if (id) {
      dispatch(
        apiCall({
          url: ApiUrls.clients(id),
          callback: res => onResponse.current(res),
        }),
      );
    }
  }, [dispatch, id]);

  onResponse.current = res => {
    console.log('+++response is', res);
    if (res && res.data && res.data.success) {
      const data = res.data.data;
      setValues(data);
      setTerms(data.terms);
      setStatus([{ value: data.status, label: data.status }]);
    }
  };

  useEffect(() => {
    dispatch(
      apiCall({
        url: ApiUrls.getMasterTypeData(),
        callback: res => onMasterDataResponse(res),
      }),
    );
  }, [dispatch]);

  const onMasterDataResponse = res => {
    if (res && res.data && res.data.success) {
      const data = res.data.data;
      let types = data.map(item => ({ value: item.value, label: item.value }));
      setStatuses(types);
    }
  };

  useEffect(() => {
    dispatch(
      apiCall({
        url: ApiUrls.getPlatformRolesList(),
        callback: res => onRoleResponse(res),
      }),
    );
  }, [dispatch]);

  const onRoleResponse = res => {
    if (res && res.data && res.data.success) {
      const data = res.data.data;
      let roles = data.map(role => ({ value: role.id, label: role.value }));
      setRoles(roles);
    }
  };

  const onChange = (val, type) => {
    setValues({ ...values, [type]: val });
  };

  const onBlur = () => {};

  const validate = () => {
    let errors = 0;
    setErrors(errorState);
    if (!values.name) {
      setErrors({ ...errors, nameError: 'Requerido' });
      errors++;
      return;
    }

    if (!values.address) {
      setErrors({ ...errors, addressError: 'Requerido' });
      errors++;
      return;
    }

    if (!values.status) {
      setErrors({ ...errors, statusError: 'Requerido' });
      errors++;
      return;
    }

    if (!values.contact_name) {
      setErrors({ ...errors, contact_nameError: 'Requerido' });
      errors++;
      return;
    }

    if (!values.contact_email) {
      setErrors({ ...errors, contact_emailError: 'Requerido' });
      errors++;
      return;
    }

    if (!values.contact_phone) {
      setErrors({ ...errors, contact_phoneError: 'Requerido' });
      errors++;
      return;
    }

    if (!values.logo_path) {
      setErrors({ ...errors, logo_pathError: 'Requerido' });
      errors++;
      return;
    }

    if (!values.clientBanner) {
      setErrors({ ...errors, clientBannerError: 'Requerido' });
      errors++;
      return;
    }

    if (!values.topupAmount) {
      setErrors({ ...errors, topupAmountError: 'Requerido' });
      errors++;
      return;
    }

    if (!values.notificationThresholdAmount) {
      setErrors({ ...errors, notificationThresholdAmountError: 'Requerido' });
      errors++;
      return;
    }

    if (!values.subdomain) {
      setErrors({ ...errors, subDomainError: 'Requerido' });
      errors++;
      return;
    }

    if (!values.email_bcc) {
      setErrors({ ...errors, EmailbccError: 'Requerido' });
      errors++;
      return;
    }

    if (!terms) {
      setErrors({ ...errors, termsError: 'Requerido' });
      errors++;
      return;
    }

    if (!values.firstName) {
      setErrors({ ...errors, firstNameError: 'Requerido' });
      errors++;
      return;
    }

    if (!values.lastName) {
      setErrors({ ...errors, lastNameError: 'Requerido' });
      errors++;
      return;
    }

    if (!values.loginEmail) {
      setErrors({ ...errors, loginEmailError: 'Requerido' });
      errors++;
      return;
    }

    if (!values.loginPassword) {
      setErrors({ ...errors, loginPasswordError: 'Requerido' });
      errors++;
      return;
    }

    if (!values.confirmLoginPassword) {
      setErrors({ ...errors, confirmLoginPasswordError: 'Requerido' });
      errors++;
      return;
    }

    if (values.loginPassword != values.confirmLoginPassword) {
      setErrors({
        ...errors,
        confirmLoginPasswordError: 'Password and confirm password does not match',
      });
      errors++;
      return;
    }

    if (role.length == 0) {
      setErrors({ ...errors, roleError: 'Requerido' });
      errors++;
      return;
    }

    if (errors > 0) {
      return false;
    } else {
      return true;
    }
  };

  const createOrUpdate = () => {
    if (!validate()) {
      return;
    }

    let rolesString = '';
    role &&
      role.map(val => {
        rolesString = rolesString + (rolesString ? ',' : '') + val.label;
      });

    let data = {
      basic_info: {
        type: values.status,
        name: values.name,
        address: values.address,
        contact_name: values.contact_name,
        contact_email: values.contact_email,
        contact_phone: values.contact_phone,
        password: values.loginPassword,
        created_on: `${DateService.getTodayString(
          'yyyy-mm-dd',
        )} ${DateService.getCurrentHour()}:${DateService.getCurrentMinute()} `, // YYYY-MM-DD HH:MM
      },

      CustomizationInfo: {
        logo_path: values.logo_path,
        banner_path: values.clientBanner,
        subdomain: values.subdomain,
        email_bcc: values.email_bcc,
        terms: terms,
      },

      AuthInfo: {
        is_counter_available: counterAssigned == 'no' ? 0 : 1, // 0 or 1
        topup_amount: values.topupAmount,
        threshold_balance: values.notificationThresholdAmount,
        notify_threshold_amount: values.notificationThresholdAmount,
      },

      super_user_info: {
        first_name: values.firstName,
        last_name: values.lastName,
        login_email: values.loginEmail,
        login_password: values.loginPassword,
        confirm_password: values.confirmLoginPassword,
        // client_roles: values.role,
        client_roles: rolesString,
      },
    };

    // console.log("++++data to send ",data)
    // return

    setButtonLoader(true);
    dispatch(
      apiCall({
        url: ApiUrls.clients(),
        data,
        method: 'POST',
        callback: res => onAddResponse(res),
      }),
    );
  };

  const onAddResponse = res => {
    // console.log({ res });
    setButtonLoader(false);
    if (res && res.data && res.data.success) {
      setModal({
        show: true,
        success: true,
        bodyText: 'Éxito',
        redirect: true,
      });
    } else {
      setModal({
        show: true,
        success: false,
        bodyText: res.data.message ? res.data.message : 'Error',
      });
    }
  };

  return (
    <div className="pr-3 pl-3">
      <h3 className="mt-4 mb-4 font-bold">
        {match.params && match.params.id ? 'Update Client' : 'Agregar cliente'}
      </h3>
      <h2 className="mt-2 font-bold"> Información básica</h2>
      <div className="client-row">
        <div className="create-user-input pb-2 mt-2">
          <KuposInput
            type="text"
            className="kupos-border"
            placeholder={'Primer nombre'}
            label={'Primer nombre'}
            value={values.name}
            error={errors.nameError ? true : false}
            errorMessage={errors.nameError ? errors.nameError : ''}
            onChange={val => onChange(val, 'name')}
            onBlur={text => onBlur(text)}
          />
        </div>

        <div className="create-user-input pb-2 mt-2">
          <KuposInput
            type="text"
            className="kupos-border"
            placeholder={'Cliente Dirección'}
            label={'Cliente Dirección'}
            value={values.address}
            error={errors.addressError ? true : false}
            errorMessage={errors.addressError ? errors.addressError : ''}
            onChange={val => onChange(val, 'address')}
            onBlur={text => onBlur(text)}
          />
        </div>

        <div className="create-user-input pb-2 mt-2">
          <DropdownComponent
            onChange={val => {
              setStatus(val);
              setValues({ ...values, status: val.value });
            }}
            placeholder={'Tipo'}
            label={'Tipo'}
            value={status}
            options={statuses}
            error={errors.statusError ? true : false}
            errorMessage={errors.statusError ? errors.statusError : ''}
          />
        </div>
      </div>
      <div className="client-row">
        <div className="create-user-input pb-2 mt-2">
          <KuposInput
            type="text"
            className="kupos-border"
            placeholder={'Nombre de contacto'}
            label={'Nombre de contacto'}
            value={values.contact_name}
            error={errors.contact_nameError ? true : false}
            errorMessage={errors.contact_nameError ? errors.contact_nameError : ''}
            onChange={val => onChange(val, 'contact_name')}
            onBlur={text => onBlur(text)}
          />
        </div>

        <div className="create-user-input pb-2 mt-2">
          <KuposInput
            type="text"
            className="kupos-border"
            placeholder={'Email de contacto'}
            label={'Email de contacto'}
            value={values.contact_email}
            error={errors.contact_emailError ? true : false}
            errorMessage={errors.contact_emailError ? errors.contact_emailError : ''}
            onChange={val => onChange(val, 'contact_email')}
            onBlur={text => onBlur(text)}
          />
        </div>

        <div className="create-user-input pb-2 mt-2">
          <KuposInput
            type="number"
            className="kupos-border"
            placeholder={'Teléfono de contacto'}
            label={'Teléfono de contacto'}
            value={values.contact_phone}
            error={errors.contact_phoneError ? true : false}
            errorMessage={errors.contact_phoneError ? errors.contact_phoneError : ''}
            onChange={val => onChange(val, 'contact_phone')}
            onBlur={text => onBlur(text)}
          />
        </div>
      </div>

      <h2 className="mt-2 font-bold">Información</h2>
      <div className="client-row">
        <div className="create-user-input pb-2 mt-2">
          <KuposInput
            type="text"
            className="kupos-border"
            placeholder={'Logo del cliente'}
            label={'Logo del cliente'}
            value={values.logo_path}
            error={errors.logo_pathError ? true : false}
            errorMessage={errors.logo_pathError ? errors.logo_pathError : ''}
            onChange={val => onChange(val, 'logo_path')}
            onBlur={text => onBlur(text)}
          />
        </div>

        <div className="create-user-input pb-2 mt-2">
          <KuposInput
            type="text"
            className="kupos-border"
            placeholder={'Cliente Banner URL'}
            label={'Cliente Banner URL'}
            value={values.clientBanner}
            error={errors.clientBannerError ? true : false}
            errorMessage={errors.clientBannerError ? errors.clientBannerError : ''}
            onChange={val => onChange(val, 'clientBanner')}
            onBlur={text => onBlur(text)}
          />
        </div>

        <div className="create-user-input pb-2 mt-2">
          <KuposInput
            type="text"
            className="kupos-border"
            placeholder={'Subdominio'}
            label={'Subdominio'}
            value={values.subdomain}
            error={errors.subDomainError ? true : false}
            errorMessage={errors.subDomainError ? errors.subDomainError : ''}
            onChange={val => onChange(val, 'subdomain')}
            onBlur={text => onBlur(text)}
          />
        </div>
      </div>

      <div className="create-user-input pb-2 mt-2">
        <KuposInput
          type="text"
          className="kupos-border"
          placeholder={'Email de notificación'}
          label={'Email de notificación'}
          value={values.email_bcc}
          error={errors.EmailbccError ? true : false}
          errorMessage={errors.EmailbccError ? errors.EmailbccError : ''}
          onChange={val => onChange(val, 'email_bcc')}
          onBlur={text => onBlur(text)}
        />
      </div>
      <div className="create-user-input pb-2 mt-2">
        <h3>Términos y condiciones</h3>
        <div
          style={
            errors.termsError
              ? { border: 'red 1px solid', borderRadius: '10px', overflow: 'hidden' }
              : { border: 'lightgray 1px solid', borderRadius: '10px' }
          }
        >
          <CKEditor
            editor={ClassicEditor}
            data={terms}
            onReady={editor => {
              // You can store the "editor" and use when it is needed.
              // console.log(
              //      'Editor is ready to use!',
              //      editor
              // );
            }}
            onChange={(___, editor) => {
              // console.log(editor);
              let data = editor.getData();
              setTerms(data);
            }}
            // onBlur={(event, editor) => {
            //  console.log('Blur.', editor);
            // }}
            // onFocus={(event, editor) => {
            //  console.log('Focus.', editor);
            // }}
          />
        </div>

        {errors.termsError && <span className="text-danger font8">{errors.termsError}</span>}
      </div>

      <FormControl component="fieldset">
        <FormLabel component="legend">Esta asignada la caja?</FormLabel>
        <RadioGroup
          row
          aria-label="counetr"
          name="counter"
          value={counterAssigned}
          onChange={e => {
            setCounterAssigned(e.target.value);
          }}
        >
          <FormControlLabel value="yes" control={<Radio />} label="Sí" />
          <FormControlLabel value="no" control={<Radio />} label="No" />
        </RadioGroup>
      </FormControl>

      <div className="client-row-2">
        <div className="create-user-input pb-2 mt-2">
          <KuposInput
            type="number"
            className="kupos-border"
            placeholder={'Monto de recaga'}
            label={'Monto de recaga'}
            value={values.topupAmount}
            error={errors.topupAmountError ? true : false}
            errorMessage={errors.topupAmountError ? errors.topupAmountError : ''}
            onChange={val => onChange(val, 'topupAmount')}
            onBlur={text => onBlur(text)}
          />
        </div>

        <div className="create-user-input pb-2 mt-2">
          <KuposInput
            type="number"
            className="kupos-border"
            placeholder={'Notificación de saldo en'}
            label={'Notificación de saldo en'}
            value={values.notificationThresholdAmount}
            error={errors.notificationThresholdAmountError ? true : false}
            errorMessage={
              errors.notificationThresholdAmountError ? errors.notificationThresholdAmountError : ''
            }
            onChange={val => onChange(val, 'notificationThresholdAmount')}
            onBlur={text => onBlur(text)}
          />
        </div>
      </div>

      <h2 className="mt-2 font-bold">Información de encargado</h2>

      <div className="client-row-2">
        <div className="create-user-input pb-2 mt-2">
          <KuposInput
            type="text"
            className="kupos-border"
            placeholder={'Nombres'}
            label={'Nombres'}
            value={values.firstName}
            error={errors.firstNameError ? true : false}
            errorMessage={errors.firstNameError ? errors.firstNameError : ''}
            onChange={val => onChange(val, 'firstName')}
            onBlur={text => onBlur(text)}
          />
        </div>

        <div className="create-user-input pb-2 mt-2">
          <KuposInput
            type="text"
            className="kupos-border"
            placeholder={'Apellido'}
            label={'Apellido'}
            value={values.lastName}
            error={errors.lastNameError ? true : false}
            errorMessage={errors.lastNameError ? errors.lastNameError : ''}
            onChange={val => onChange(val, 'lastName')}
            onBlur={text => onBlur(text)}
          />
        </div>
      </div>

      <div className="client-row-2">
        <div className="create-user-input pb-2 mt-2">
          <KuposInput
            type="text"
            className="kupos-border"
            placeholder={'Usuario '}
            label={'Usuario '}
            value={values.loginEmail}
            error={errors.loginEmailError ? true : false}
            errorMessage={errors.loginEmailError ? errors.loginEmailError : ''}
            onChange={val => onChange(val, 'loginEmail')}
            onBlur={text => onBlur(text)}
          />
        </div>

        <div className="create-user-input pb-2 mt-2">
          <KuposInput
            type="password"
            className="kupos-border"
            placeholder={'Contraseña '}
            label={'Contraseña '}
            value={values.loginPassword}
            error={errors.loginPasswordError ? true : false}
            errorMessage={errors.loginPasswordError ? errors.loginPasswordError : ''}
            onChange={val => onChange(val, 'loginPassword')}
            onBlur={text => onBlur(text)}
          />
        </div>
      </div>

      <div className="client-row-2">
        <div className="create-user-input pb-2 mt-2">
          <KuposInput
            type="password"
            className="kupos-border"
            placeholder={'Confirme la contraseña'}
            label={'Confirme la contraseña'}
            value={values.confirmLoginPassword}
            error={errors.confirmLoginPasswordError ? true : false}
            errorMessage={errors.confirmLoginPasswordError ? errors.confirmLoginPasswordError : ''}
            onChange={val => onChange(val, 'confirmLoginPassword')}
            onBlur={text => onBlur(text)}
          />
        </div>

        <div className="create-user-input pb-2 mt-2">
          <span className="input-label">Rol</span>
          <Select
            onChange={val => {
              setRole(val);
              setValues({ ...values, role: val.label });
            }}
            placeholder={'Rol'}
            options={roles}
            value={role}
            isMulti={true}
            error={errors.roleError ? true : false}
            errorMessage={errors.roleError ? errors.roleError : ''}
          />
        </div>
      </div>

      <div className="button-container" style={{ marginTop: 20, marginBottom: 20 }}>
        <KuposButton onClick={createOrUpdate}>
          <span className="font11">
            {buttonLoader ? (
              <div className="loader-cricle"></div>
            ) : match.params && match.params.id ? (
              'Update Client'
            ) : (
              'Agregar cliente'
            )}
          </span>
        </KuposButton>
      </div>

      <KuposErrorSuccessModal
        showModal={modal.show}
        success={modal.success}
        smallIcon={true}
        bodyText={modal.bodyText}
        onButtonClick={() => {
          setModal({ ...modal, show: false });
          if (modal.redirect) {
            history.goBack();
          }
        }}
      />
    </div>
  );
};

export default AddClientScreen;
