// @flow

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actions from './redux/actions';
import { KuposButton, KuposCheckbox, KuposDatePicker, KuposSelectBox, KuposSteps } from '../common';
import DateService from '../../services/dateService';
import ApiUrls from '../../services/apiUrls';
// import Menu from 'react-select/src/components/Menu';
import CommonService from '../../services/commonService';

var global = window;

export class Reports extends Component {
         static propTypes = {
           home: PropTypes.object.isRequired,
           actions: PropTypes.object.isRequired,
         };

         constructor(props) {
           super(props);

           var fromParts = DateService.getTodayString().split('-');
           var d1 = new Date(fromParts[2], fromParts[1] - 1, fromParts[0] - 10);

           this.selectToFrom = [
             { value: 'ALL', label: 'All' },
             { value: 'TO_AIRPORT', label: 'Hacia Aeropuerto' },
             { value: 'FROM_AIRPORT', label: 'Desde Aeropuerto' },
           ];

           this.selectServiceOptions = [
             { value: 'TRAVEL_DATE', label: 'Travel date' },
             { value: 'BOOKING_DATE', label: 'Booking date' },
           ];

           this.state = {
             menuButtons: [
               { label: 'Todos los servicios', active: true, key: 0 },
               { label: 'Al aeropuerto', active: false, key: 1 },
               { label: 'Desde aeropuerto', active: false, key: 2 },
             ],
             transferServiceError: true,
             identityVal: null,
             bookingRequests: null,
             drivers: null,
             vehicles: null,
             tripStep: 1,
             filteredBookings: null,
             fromDate: DateService.getFormattedDateHome(d1, 'dd-mm-yyyy'),
             toDate: DateService.getTodayString(),
             typeChange: 'BOOKING_DATE',
             directionChange: 'ALL',
           };

           this.getBookings();
           //  this.getDrivers();
           //  this.getVehicles();
         }

         componentDidMount() {
           if (!CommonService.authToken) {
             this.props.history.push('/login');
           }
         }

         getBookings = () => {
           let fromUnixTime = CommonService.getTimeInSeconds(this.state.fromDate, "start");
           let toUnixTime = CommonService.getTimeInSeconds(this.state.toDate, "end");

           this.props.actions
             .apiCall({
               url: `${ApiUrls.getTransactionSummary()}/${this.state.directionChange}/${
                 this.state.typeChange
               }/${fromUnixTime}/${toUnixTime}`,
             })
             .then(
               res => {
                 console.log('Data is', res);
                 this.onBookingResponse(res);
               },
               err => {
                 this.onBookingResponse(err);
                 console.log('Failure Data is', err);
               },
             );
         };

         onBookingResponse = result => {
           console.log('Booking Result from api after search', result);
           //  console.log('Props after booking result', this.props);

           if (result.data && result.data.success && result.data.data) {
             this.setState({
               bookingRequests: result.data.data,
               filteredBookings: result.data.data,
             });
           }else{
             this.setState({
               bookingRequests: [],
               filteredBookings: [],
             });
           }
         };

         renderServiceRequest = (request, key) => {
           //  console.log('Request item is', request);
           return (
             <div className="request-item" key={key}>
               <div className="item">{request.id}</div>
               <div className="item">{request.operator_name}</div>
               <div className="item">{request.service_name}</div>
               <div className="item">{request.no_of_seats}</div>
               {/* <div className="item">{request.booking_time.split('T')[1].slice(0, -1)}</div> */}
               <div className="item">{request.issue_date}</div>
               <div className="item">{request.issue_date}</div>
               <div className="item">{request.travel_date}</div>
               <div className="item">{request.customer_name}</div>
               <div className="item">{request.document_type}</div>
               <div className="item">{request.cancellation_time}</div>
               <div className="item">{request.from_address}</div>
               <div className="item">{request.to_address}</div>
               <div className="item">{request.pnr_id}</div>
               <div className="item">{request.ticket_price}</div>
               <div className="item">{request.cancellation_charges}</div>
               <div className="item">{request.refund_amount}</div>
               <div className="item">{request.net_amount}</div>
               <div className="big-entry item">{request.source_of_booking}</div>
               <div className="item">{request.PgType}</div>
             </div>
           );
         };

         onStepClick = menuItem => {
           //  console.warn('Menu clicked ', menuItem);

           // Highlight the selected Menu Item

           let elementsIndex = this.state.menuButtons.findIndex(
             element => element.key == menuItem - 1,
           );

           let newMenus = [...this.state.menuButtons];

           newMenus.forEach((item, key) => {
             newMenus[key] = {
               ...newMenus[key],
               active: false,
             };
           });

           newMenus[elementsIndex] = {
             ...newMenus[elementsIndex],
             active: !newMenus[elementsIndex].active,
           };

           this.setState({ menuButtons: newMenus });

           //  Filter the arrays as per selection

           let fromAT = [];
           let toAT = [];

           for (let i = 0; i < this.state.bookingRequests.length; i++) {
             if (menuItem == 2) {
               if (this.state.bookingRequests[i].txn_subtype == 'FROM_AIRPORT') {
                 fromAT.push(this.state.bookingRequests[i]);
               }
             } else if (menuItem == 3) {
               if (this.state.bookingRequests[i].txn_subtype == 'TO_AIRPORT') {
                 toAT.push(this.state.bookingRequests[i]);
               }
             }
           }

           menuItem == 1
             ? this.setState({ filteredBookings: this.state.bookingRequests })
             : menuItem == 2
             ? this.setState({ filteredBookings: fromAT })
             : this.setState({ filteredBookings: toAT });
         };

         onBookingSelectionChange = value => {
           this.setState({ typeChange: value.value });
         };

         onDirectionChange = value => {
           this.setState({ directionChange: value.value });
         };

         onDateChange = (value, type) => {
           if (type == 'going') {
             this.setState({ fromDate: value });
           } else {
             this.setState({ toDate: value });
           }
         };

         searchResults = () => {
           let fromUnixTime = CommonService.getTimeInSeconds(this.state.fromDate, "start");
           let toUnixTime = CommonService.getTimeInSeconds(this.state.toDate, "end");

           this.props.actions
             .apiCall({
               url: `${ApiUrls.getTransactionSummary()}/${this.state.directionChange}/${
                 this.state.typeChange
               }/${fromUnixTime}/${toUnixTime}`,
             })
             .then(
               res => {
                 this.onBookingResponse(res);
               },
               err => {
                 this.onBookingResponse(err);
               },
             );
         };

         downloadFile = (fileName, urlData) => {
           // var aLink = document.createElement('a');
           // var evt = document.createEvent("HTMLEvents");
           // evt.initEvent("click");
           // aLink.download = fileName;
           // aLink.href = urlData;
           // aLink.dispatchEvent(evt);

           // this.props.history.push(fileName)
           window.open(urlData);
         };

         onBookButtonPress = () => {
           let csvContent =
             'Id, Operador, Estatus , Nombre del Servicio, Tipo de vehiculo, Asientos, Fecha de asunto, Hora, Fecha de viaje, Nombre del cliente, Tipo de Documento, Número del Documento, Tiempo de cancelación, De la Dirección, Dirigirse, Número de PNR, Nueva cantidad, Cargos por cancelación \r\n';

           this.state.filteredBookings.forEach(rowArray => {
             let row = '';

             Object.values(rowArray).forEach(innerRow => {
               row +=
                 innerRow
                   .toString()
                   .split(',')
                   .join('') + ',';
             });

             // let row = innerRowMain;
             csvContent += row + '\r\n';
           });

           this.downloadFile(
             'data.csv',
             'data:text/csv;charset=UTF-8,' + encodeURIComponent(csvContent),
           );
         };

         render() {
           //  console.log(
           //    'Filtered Bookings are ',
           //    this.state.filteredBookings ? this.state.filteredBookings : 'no filtered bookings',
           //  );
           return (
             <div className="home-operations home-reports">
               <div className="operations-root-container">
                 <div className="reports-top-strip">
                   <div className="home-selection-input-box font10">
                     <span className="label font11">{'Direction'}</span>
                     <KuposSelectBox
                       readonly={true}
                       showAll={true}
                       options={this.selectToFrom}
                       selectedOption={this.state.directionChange}
                       onChange={val => this.onDirectionChange(val)}
                       onInputChange={this.onInputChange}
                       error={this.state.transferServiceError ? true : false}
                       errorMessage={this.state.transferServiceError}
                       placeholder={'All'}
                       height={40}
                     />
                   </div>
                   <div className="home-selection-input-box font10">
                     <span className="label font11">{'Ordenar por'}</span>
                     <KuposSelectBox
                       readonly={true}
                       showAll={true}
                       options={this.selectServiceOptions}
                       selectedOption={this.state.typeChange}
                       onChange={val => this.onBookingSelectionChange(val)}
                       onInputChange={this.onInputChange}
                       //  icon="/svgs/airport.svg"
                       error={this.state.transferServiceError ? true : false}
                       errorMessage={this.state.transferServiceError}
                       placeholder={'Travel date'}
                       height={40}
                     />
                   </div>
                   <div className="home-selection-input-box font10 datebox">
                     <span className="label font11">{'Desde'}</span>
                     <KuposDatePicker
                       //  selectedDate={this.props.transferData.selectedDate}
                       selectedDate={this.state.fromDate}
                       onDateChange={val => this.onDateChange(val, 'going')}
                       //  minDate={"01-01-2020"}
                       error={this.state.transferDateError ? true : false}
                       errorMessage={this.state.transferDateError}
                       placeholder={'Going Date'}
                     />
                   </div>
                   <div className="home-selection-input-box font10 datebox">
                     <span className="label font11">{'Hasta'}</span>
                     <KuposDatePicker
                       //  selectedDate={this.props.transferData.selectedDate}
                       selectedDate={this.state.toDate}
                       onDateChange={val => this.onDateChange(val, 'return')}
                       //  minDate={DateService.getTodayString()}
                       error={this.state.transferDateError ? true : false}
                       errorMessage={this.state.transferDateError}
                       placeholder={'Return Date'}
                     />
                   </div>

                   <div className="home-selection-input-box font12 datebox">
                     <button
                       className="kupos-button home-selection-input-button "
                       onClick={this.searchResults}
                       style={{ marginTop: 24 }}
                     >
                       <img
                         className="icon-size"
                         src="/images/icons/home/icon_search-white.png"
                         alt={'TRANSLATIONS.ICON_SEARCH_WHITE'}
                       />{' '}
                     </button>
                   </div>

                   <div className="doc-search-button font12 operations-button-container reports-button-container">
                     <KuposButton
                       onClick={this.onBookButtonPress}
                       className="operations-download-button-inner"
                     >
                       <span className="vendor-icon operations-download-button">
                         <img src="/images/ticket-search.png" />
                         <span className="font10">Descargar reporte</span>
                       </span>
                     </KuposButton>
                   </div>
                 </div>
                 <div className="reports-main-body-container operations-main-body-container">
                   <div className="operations-main-body">
                     <div className="operations-body-top-strip font10 reports-body-top-strip">
                       <span className="bold-text">S#</span>
                       <span className="bold-text">Operador</span>
                       <span className="bold-text">Tipo de vehiculo</span>
                       <span className="bold-text">Asientos</span>
                       <span className="bold-text">Hora de presentación</span>
                       <span className="bold-text">Fecha de asunto</span>
                       <span className="bold-text">Fecha de viaje</span>
                       <span className="bold-text">Nombre del cliente</span>
                       <span className="bold-text">Documento #</span>
                       <span className="bold-text">Cancelado el (si corresponde)</span>
                       <span className="bold-text">De la Dirección</span>
                       <span className="bold-text">Dirigirse</span>
                       <span className="bold-text">PNR</span>
                       <span className="bold-text">Precio de la entrada</span>
                       <span className="bold-text">Cargos por cancelación</span>
                       <span className="bold-text">cantidad devuelta</span>
                       <span className="bold-text">Importe neto</span>
                       <span className="big-entry bold-text">Fuente de reserva</span>
                       <span className="bold-text">Tipo PG</span>
                     </div>
                     <div className="operations-rest-body reports-rest-body font10">
                       {this.state.bookingRequests &&
                         this.state.filteredBookings.map((val, key) =>
                           this.renderServiceRequest(val, key),
                         )}
                     </div>
                   </div>
                 </div>
               </div>
             </div>
           );
         }
       }

       /* istanbul ignore next */
function mapStateToProps(state) {
  return {
    home: state.home,
  };
}

/* istanbul ignore next */
function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...actions }, dispatch)
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Reports);