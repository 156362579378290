import React, { useEffect } from 'react';
import { KuposButton } from '../common';
import CommonService from '../../services/commonService';

const AdminHome = ({ history }) => {
  useEffect(() => {
    if (!CommonService.authToken) {
      history.push('/login');
    }
  }, [history])

  const addLocationArea = () => {
    history.push('/location-areas/create');
  };

  const addServicePricing = () => {
    history.push('/services/create');
  };

  return (
    <div className="home-admin-home">
      <KuposButton onClick={addLocationArea}>
        <span className="font12">Add Location Area</span>
      </KuposButton>
      <KuposButton onClick={addServicePricing}>
        <span className="font12">Add Service Pricing</span>
      </KuposButton>
    </div>
  );
}

export default AdminHome;
