export const COMMON_LOAD_TICKET_DETAILS_BEGIN = 'COMMON_LOAD_TICKET_DETAILS_BEGIN';
export const COMMON_LOAD_TICKET_DETAILS_SUCCESS = 'COMMON_LOAD_TICKET_DETAILS_SUCCESS';
export const COMMON_LOAD_TICKET_DETAILS_FAILURE = 'COMMON_LOAD_TICKET_DETAILS_FAILURE';
export const COMMON_LOAD_TICKET_DETAILS_DISMISS_ERROR = 'COMMON_LOAD_TICKET_DETAILS_DISMISS_ERROR';
export const COMMON_SET_TICKET_DETAILS = 'COMMON_SET_TICKET_DETAILS';
export const COMMON_LOGIN_BEGIN = 'COMMON_LOGIN_BEGIN';
export const COMMON_LOGIN_SUCCESS = 'COMMON_LOGIN_SUCCESS';
export const COMMON_LOGIN_FAILURE = 'COMMON_LOGIN_FAILURE';
export const COMMON_LOGIN_DISMISS_ERROR = 'COMMON_LOGIN_DISMISS_ERROR';
export const COMMON_SIGNUP_BEGIN = 'COMMON_SIGNUP_BEGIN';
export const COMMON_SIGNUP_SUCCESS = 'COMMON_SIGNUP_SUCCESS';
export const COMMON_SIGNUP_FAILURE = 'COMMON_SIGNUP_FAILURE';
export const COMMON_SIGNUP_DISMISS_ERROR = 'COMMON_SIGNUP_DISMISS_ERROR';
export const COMMON_SET_LOGIN_DATA = 'COMMON_SET_LOGIN_DATA';
export const COMMON_UPDATE_PROFILE_BEGIN = 'COMMON_UPDATE_PROFILE_BEGIN';
export const COMMON_UPDATE_PROFILE_SUCCESS = 'COMMON_UPDATE_PROFILE_SUCCESS';
export const COMMON_UPDATE_PROFILE_FAILURE = 'COMMON_UPDATE_PROFILE_FAILURE';
export const COMMON_UPDATE_PROFILE_DISMISS_ERROR = 'COMMON_UPDATE_PROFILE_DISMISS_ERROR';
export const COMMON_SET_STEP_MENU_STEP = 'COMMON_SET_STEP_MENU_STEP';
export const COMMON_RESET_PASSWORD_BEGIN = 'COMMON_RESET_PASSWORD_BEGIN';
export const COMMON_RESET_PASSWORD_SUCCESS = 'COMMON_RESET_PASSWORD_SUCCESS';
export const COMMON_RESET_PASSWORD_FAILURE = 'COMMON_RESET_PASSWORD_FAILURE';
export const COMMON_RESET_PASSWORD_DISMISS_ERROR = 'COMMON_RESET_PASSWORD_DISMISS_ERROR';
export const COMMON_OPEN_CLOSE_LOGIN_MODAL = 'COMMON_OPEN_CLOSE_LOGIN_MODAL';
export const COMMON_GET_PLACES_BEGIN = 'COMMON_GET_PLACES_BEGIN';
export const COMMON_GET_PLACES_SUCCESS = 'COMMON_GET_PLACES_SUCCESS';
export const COMMON_GET_PLACES_FAILURE = 'COMMON_GET_PLACES_FAILURE';
export const COMMON_GET_PLACES_DISMISS_ERROR = 'COMMON_GET_PLACES_DISMISS_ERROR';
export const COMMON_GENERATE_OTP_BEGIN = 'COMMON_GENERATE_OTP_BEGIN';
export const COMMON_GENERATE_OTP_SUCCESS = 'COMMON_GENERATE_OTP_SUCCESS';
export const COMMON_GENERATE_OTP_FAILURE = 'COMMON_GENERATE_OTP_FAILURE';
export const COMMON_GENERATE_OTP_DISMISS_ERROR = 'COMMON_GENERATE_OTP_DISMISS_ERROR';
export const COMMON_VALIDATE_OTP_BEGIN = 'COMMON_VALIDATE_OTP_BEGIN';
export const COMMON_VALIDATE_OTP_SUCCESS = 'COMMON_VALIDATE_OTP_SUCCESS';
export const COMMON_VALIDATE_OTP_FAILURE = 'COMMON_VALIDATE_OTP_FAILURE';
export const COMMON_VALIDATE_OTP_DISMISS_ERROR = 'COMMON_VALIDATE_OTP_DISMISS_ERROR';
export const COMMON_LOAD_KUPOS_TICKET_DETAILS_BEGIN = 'COMMON_LOAD_KUPOS_TICKET_DETAILS_BEGIN';
export const COMMON_LOAD_KUPOS_TICKET_DETAILS_SUCCESS = 'COMMON_LOAD_KUPOS_TICKET_DETAILS_SUCCESS';
export const COMMON_LOAD_KUPOS_TICKET_DETAILS_FAILURE = 'COMMON_LOAD_KUPOS_TICKET_DETAILS_FAILURE';
export const COMMON_LOAD_KUPOS_TICKET_DETAILS_DISMISS_ERROR = 'COMMON_LOAD_KUPOS_TICKET_DETAILS_DISMISS_ERROR';
export const COMMON_GOOGLE_LOGOUT_BEGIN = 'COMMON_GOOGLE_LOGOUT_BEGIN';
export const COMMON_GOOGLE_LOGOUT_SUCCESS = 'COMMON_GOOGLE_LOGOUT_SUCCESS';
export const COMMON_GOOGLE_LOGOUT_FAILURE = 'COMMON_GOOGLE_LOGOUT_FAILURE';
export const COMMON_GOOGLE_LOGOUT_DISMISS_ERROR = 'COMMON_GOOGLE_LOGOUT_DISMISS_ERROR';
export const COMMON_SUBSCRIBE_BEGIN = 'COMMON_SUBSCRIBE_BEGIN';
export const COMMON_SUBSCRIBE_SUCCESS = 'COMMON_SUBSCRIBE_SUCCESS';
export const COMMON_SUBSCRIBE_FAILURE = 'COMMON_SUBSCRIBE_FAILURE';
export const COMMON_SUBSCRIBE_DISMISS_ERROR = 'COMMON_SUBSCRIBE_DISMISS_ERROR';
export const COMMON_SET_CLIENT_INFO = 'COMMON_SET_CLIENT_INFO';
