import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actions from './redux/actions';
import { KuposButton, KuposModalWithButtons } from '../common';
import ApiUrls from '../../services/apiUrls';
import AppData from '../../services/appData';
import CommonService from '../../services/commonService';
import { Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';

export class IncomeExpensesTransactionList extends Component {
  static propTypes = {
    home: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      selectedTab: 1,
    };
  }

  componentDidMount() {
    let counterDetails = CommonService.getLocal('counter');

    if (!counterDetails) {
      this.setState({
        noCounter: true,
        showSnackbar: true,
        alertMessage: "Usted no tiene caja abierta",
      });
      return;
    }

    this.getIncomeExpenseList();
  }

  ///agent/userTxnList/:counterId/:counterTxnId

  getIncomeExpenseList = () => {
    let counter = CommonService.getLocal('counter');

    console.log('counter details are----', counter);
    this.props.actions
      .apiCall({
        url: AppData.BASE_URL + `agent/userTxnList/${counter.counter_id}/${counter.counter_txn_id}`,
      })
      .then(
        res => {
          console.log('Expemnses list fetched ----------', res);
          if (res.data && res.data.success && res.data.data) {
            this.setState({ incomeexpenseList: res.data.data });
          }
        },
        err => {
          console.log('Result is', err);
        },
      );
  };

  onTabChange = tab => {
    this.setState({ selectedTab: tab });
  };

  renderTabs = () => {
    return (
      <div className="recharge-tabs-container">
        <div className="map-container-selector font10">
          <div
            className={'selector-item ' + (this.state.selectedTab == 1 ? 'active' : '')}
            onClick={() => this.onTabChange(1)}
          >
            {'Gastos'}
          </div>
          <div
            className={'selector-item ' + (this.state.selectedTab == 2 ? 'active' : '')}
            onClick={() => this.onTabChange(2)}
          >
            {'Ingresos'}
          </div>
        </div>
      </div>
    );
  };

  renderItem = (val, i) => {
    console.log('value is--------', val);
    return (
      <div key={i} className="flex item-row">
        <div className="sm:w-full md:w-3/6 text-center">
          {val.txn_type === 'INCOME' ? val.counter_party_name : CommonService.currentUser.user_name}
        </div>
        <div className="sm:w-full md:w-3/6 text-center">{val.payment_type}</div>
        <div className="sm:w-full md:w-3/6 text-center">{val.amount}</div>
        <div className="sm:w-full md:w-3/6 text-center">{val.receipt_number}</div>
        <div className="sm:w-full md:w-3/6 text-center">
          {CommonService.convertUTCDateToLocalDate(val.txn_date_time)}
        </div>
        <div className="sm:w-full md:w-3/6 text-center">{val.remark}</div>
        <div className="sm:w-full md:w-3/6 text-center flex" style={{ justifyContent: 'center' }}>
          <KuposButton onClick={() => this.setState({ selectedViewItem: val })}>
            <span>Ver</span>
          </KuposButton>
          {/* <KuposButton
            onClick={() => this.props.history.push('/income-expense/' + val.id + '/edit')}
          >
            <span>Edit</span>
          </KuposButton>
          <KuposButton
            onClick={() =>
              this.setState({
                showDeleteModal: true,
                selectedItemName: val.name,
                selectedItemId: val.id,
              })
            }
          >
            <span>Delete</span>
          </KuposButton> */}
        </div>
      </div>
    );
  };

  renderViewModal = () => {
    return (
      <KuposModalWithButtons
        size="lg"
        // buttonText1="O"
        // onButtonClick1={this.addSkillPoint}
        showButton1={true}
        showModal={this.state.selectedViewItem}
        onHide={() => this.setState({ selectedViewItem: null })}
      >
        {this.state.selectedViewItem && this.state.selectedViewItem.id ? (
          <div className="view-item-container">
            <h3 className="text-center font-bold">Transacción</h3>
            <div className="flex view-item-row">
              <div className="sm:w-full md:w-2/6 label font-bold">Transacción para:</div>
              <div className="sm:w-full md:w-full">{this.state.selectedViewItem.txn_name}</div>
            </div>
            <div className="flex view-item-row">
              <div className="sm:w-full md:w-2/6 label font-bold">Tipo de transacción :</div>
              <div className="sm:w-full md:w-full">{this.state.selectedViewItem.txn_type}</div>
            </div>
            <div className="flex view-item-row">
              <div className="sm:w-full md:w-2/6 label font-bold">Tipo de pagos :</div>
              <div className="sm:w-full md:w-full">{this.state.selectedViewItem.payment_type}</div>
            </div>
            <div className="flex view-item-row">
              <div className="sm:w-full md:w-2/6 label font-bold">Person in charge:</div>
              <div className="sm:w-full md:w-full">
                {this.state.selectedViewItem.counter_party_name}
              </div>
            </div>
            <div className="flex view-item-row">
              <div className="sm:w-full md:w-2/6 label font-bold">Comentarios:</div>
              <div className="sm:w-full md:w-full">{this.state.selectedViewItem.remark}</div>
            </div>
          </div>
        ) : null}
      </KuposModalWithButtons>
    );
  };

  render() {
    console.log('this.state.incomeexpenseList', this.state.incomeexpenseList);
    return !this.state.noCounter ? (
      <div className="home-all-income-expense">
        <hr className="pb-3" />

        <div className="add-item-container flex align-center justify-between">
          <div className="pl-3 font-bold">Gastos - Ingresos</div>
          {this.renderTabs()}

          <KuposButton
            className="add-button"
            onClick={() => this.props.history.push('income-expense-txn/create')}
          >
            <span>
              <i class="fas fa-plus"></i>Agregar transacción
            </span>
          </KuposButton>
        </div>
        <div className="flex table-heading">
          <div className="sm:w-full md:w-3/6 text-center">
            {this.state.selectedTab == 1 ? 'Realizado por' : 'Ingresos de'}
          </div>
          <div className="sm:w-full md:w-3/6 text-center">Tipo de pago</div>
          <div className="sm:w-full md:w-3/6 text-center">Monto</div>
          {/* <div className="sm:w-full md:w-3/6 text-center">Pagado a</div>
          <div className="sm:w-full md:w-3/6 text-center">Proveedores</div> */}
          <div className="sm:w-full md:w-3/6 text-center">Número de recibo</div>
          <div className="sm:w-full md:w-3/6 text-center">Datos</div>
          <div className="sm:w-full md:w-3/6 text-center">Comentario</div>
          <div className="sm:w-full md:w-3/6 text-center">Acciones</div>
        </div>
        {(
          (this.state.selectedTab == 1 &&
            this.state.incomeexpenseList &&
            this.state.incomeexpenseList) ||
          []
        ).length > 0
          ? this.state.incomeexpenseList
              .filter(it => it.txn_type === 'EXPENSE')
              .map((val, i) => this.renderItem(val, i))
          : null}

        {(
          (this.state.selectedTab == 2 &&
            this.state.incomeexpenseList &&
            this.state.incomeexpenseList) ||
          []
        ).length > 0
          ? this.state.incomeexpenseList
              .filter(it => it.txn_type === 'INCOME')
              .map((val, i) => this.renderItem(val, i))
          : null}

        {this.renderViewModal()}
        {/*
        {this.renderDeleteModal()} */}

        <Snackbar
          open={this.state.showSnackbar}
          autoHideDuration={5000}
          anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
          onClose={this.closeSnackbar}
          //   message={this.state.alertMessage}
        >
          <Alert onClose={() => this.setState({ showSnackbar: false })} severity="error">
            {this.state.alertMessage}
          </Alert>
        </Snackbar>
      </div>
    ) : (
      <Snackbar
        open={this.state.showSnackbar}
        autoHideDuration={5000}
        anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
        onClose={this.closeSnackbar}
        //   message={this.state.alertMessage}
      >
        <Alert onClose={() => this.setState({ showSnackbar: false })} severity="error">
          {this.state.alertMessage}
        </Alert>
      </Snackbar>
    );
  }
}

/* istanbul ignore next */
function mapStateToProps(state) {
  return {
    home: state.home,
  };
}

/* istanbul ignore next */
function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...actions }, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(IncomeExpensesTransactionList);
