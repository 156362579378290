import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actions from './redux/actions';
import { KuposDatePicker, KuposTimePicker } from '.';
import DateService from '../../services/dateService';

export class KuposDateTimePicker extends Component {
  static propTypes = {
    common: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {};
  }

  onDateChangeInternal = val => {
    this.setState({ showOnSelect: true });
    this.props.onDateChange(val, 'date');
    this.forceUpdate();
  };

  onFocus = () => {
    this.setState({ showOnSelect: false });
  };

  render() {
    // console.log('+++props in date time picker', this.props);
    return (
      <div className="common-kupos-date-time-picker">
        <div
          className="date-time-container"
          style={this.props.optional ? { backgroundColor: '#ccc' } : {}}
        >
          <KuposDatePicker
            selectedDate={
              this.props.optional && !this.props.selectedDate ? 'Optional' : this.props.selectedDate
            }
            onDateChange={val => this.onDateChangeInternal(val)}
            minDate={DateService.getTodayString()}
            // error={this.state.transferDateError ? true : false}
            // errorMessage={this.state.transferDateError}
            placeholder={this.props.datePlaceholder}
            placeholder={'Date'}
            newStyle="border-less-date-input"
            disabled={this.props.disabled}
            optional={this.props.optional}
            onFocus={this.onFocus}
          />

          {this.state.showOnSelect && (
            <KuposTimePicker
              value={this.props.optional ? '' : this.props.selectedTime}
              // value={this.props.optional && !this.props.selectedTime ? ' ' : this.props.selectedTime}
              // selectedTime={this.props.selectedTime}
              onChange={val => this.props.onTimeChange(val, 'time')}
              // error={this.state.transferTimeError ? true : false}
              // errorMessage={t(this.state.transferTimeError)}
              placeholder={this.props.optional ? '' : '00:00'}
              newStyle="border-less-time-input"
              hideIcon={true}
              fullWidthPicker={true}
              showIconsOnAmPm={true}
              showAmPm={this.props.optional ? false : true}
              disabled={this.props.disabled}
              optional={this.props.optional}
              showPicker={this.props.disabled ? false : true}
              showOnSelect={this.state.showOnSelect}
            />
          )}
        </div>
        {this.props.error ? (
          <span className="error-message font9">{this.props.errorMessage}</span>
        ) : // <span className="error font9">{this.props.t(this.props.errorMessage)}</span>
        null}
      </div>
    );
  }
}

/* istanbul ignore next */
function mapStateToProps(state) {
  return {
    common: state.common,
  };
}

/* istanbul ignore next */
function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...actions }, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(KuposDateTimePicker);
