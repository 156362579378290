// @flow

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actions from './redux/actions';
import {
  KuposButton,
  KuposCheckbox,
  KuposDatePicker,
  KuposSelectBox,
  KuposSteps,
  KuposErrorSuccessModal,
  KuposModalWithButtons,
  KuposRadio,
} from '../common';
import DateService from '../../services/dateService';
import { DropdownComponent } from '../common/DropdownComponent';
import ApiUrls from '../../services/apiUrls';
// import Menu from 'react-select/src/components/Menu';
import CommonService from '../../services/commonService';
import Table from 'react-bootstrap/Table';
import Loader from 'react-loader-spinner';
import { copySync } from 'fs-extra';

var global = window;

export class Operations extends Component {
  static propTypes = {
    home: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);

    this.selectServiceOptions = [
      { value: 'TRAVEL_DATE', label: 'Fecha de viaje' },
      { value: 'BOOKING_DATE', label: 'Fecha de emisión' },
    ];

    this.state = {
      menuButtons: [
        { label: 'Todos los servicios', active: true, key: 0 },
        { label: 'Al aeropuerto', active: false, key: 1 },
        { label: 'Desde aeropuerto', active: false, key: 2 },
      ],
      boarded: true,
      not_boarded: false,
      transferServiceError: true,
      identityVal: null,
      bookingRequests: null,
      drivers: null,
      vehicles: null,
      tripStep: 1,
      filteredBookings: null,
      fromDate: DateService.getTodayString(),
      toDate: DateService.getTodayString(),
      //  toDate: DateService.getFormattedDateHome(new Date().getTime() + 1000 * 60 * 60 * 24 * 7, 'dd-mm-yyyy'),
      selectedService: 'TRAVEL_DATE',
      showLoader: false,
      showHeader: false,
      selectedFilter: 'ALL',
      disableDownloadButton: true,
    };

    // console.log('From date constructed is--------------------', DateService.getTodayString());

    //  this.getBookings();
    this.getDrivers();
    this.getVehicles();
  }

  componentDidMount() {
    if (!CommonService.authToken) {
      this.props.history.push('/login');
    }
  }

  getVehicles = () => {
    this.props.actions.apiCall({ url: ApiUrls.getVehicles() }).then(
      res => {
        this.onVehicleApiResponse(res);
      },
      err => {
        this.onVehicleApiResponse(err);
      },
    );
  };

  getDrivers = () => {
    this.props.actions.apiCall({ url: ApiUrls.getDrivers() }).then(
      res => {
        this.onDriverApiResponse(res);
      },
      err => {
        this.onDriverApiResponse(err);
      },
    );
  };

  getBookings = () => {
    //  this.setState({ showHeader:false})
    let fromUnixTime = CommonService.getTimeInSeconds(this.state.fromDate + 'T00:00:00');
    let toUnixTime = CommonService.getTimeInSeconds(this.state.toDate + 'T23:59:59');

    this.props.actions
      .apiCall({
        url: `${ApiUrls.getBookingsForAgent()}/DELFOS/${
          this.state.selectedService
        }/${fromUnixTime}/${toUnixTime}`,
      })
      .then(
        res => {
          this.onBookingResponse(res);
        },
        err => {
          this.onBookingResponse(err);
        },
      );
  };

  onBookingResponse = result => {
    this.setState({ showLoader: false, showHeader: true });

    let filteredBookings = [];
    if (result.data && result.data.success && result.data.data) {

      result.data.data.map((val, key)=>{

        let item ={};

        item.id = val.id
        item.txn_subtype = val.txn_subtype
        item.pnr_id = val.pnr_id
        item.pickup_address = val.pickup_address
        item.drop_address = val.drop_address
        item.customer_name = val.customer_name
        item.customer_email = val.customer_email
        item.customer_phone = val.customer_phone
        item.booking_date = val.booking_date
        item.trip_ref_date = val.trip_ref_date
        item.trip_ref_time = val.trip_ref_time
        item.trip_ref_time_type = val.trip_ref_time_type
        item.customer_address = val.customer_address
        item.service_type = val.service_type
        item.no_of_seats = val.no_of_seats
        item.net_amount = val.net_amount
        item.sold_by = val.sold_by
        item.return_date = val.return_date
        item.comments = val.comments
        item.match = val.match
        item.vehicle_reference_name = val.vehicle_reference_name
        item.driver_name = val.driver_name
        item.trip_ref_date_time = val.trip_ref_date_time
        item.communa = val.communa
        

        filteredBookings.push(item);

      })
      console.log("filteredBookings----------", filteredBookings)
      this.setState({
        bookingRequests: result.data.data,
        filteredBookings: filteredBookings,
        disableDownloadButton: false,
      });
    } else {
      this.setState({
        bookingRequests: [],
        filteredBookings: [],
        disableDownloadButton: true,
      });
    }
  };

  onDriverApiResponse = result => {
    console.log('Driver api response is', result.data ? result : 'no data');
    console.log('Props after Driver api response', this.props);

    let data = [];

    if (result.data && result.data.success && result.data.data) {
      result.data.data.map(val => {
        if (val.status === 'ACTIVE') {
          data.push({ value: val.id, label: val.first_name + ' ' + val.last_name });
        }
      });
    }
    this.setState({ drivers: data });
  };

  onVehicleApiResponse = result => {
    //  console.log('Vehicle api response is', result.data ? result : 'no data');
    //  console.log('Props after Vehicle api response', this.props);

    let data = [];

    if (result.data && result.data.success && result.data.data) {
      result.data.data.map(val => {
        if (val.status === 'ACTIVE') {
          data.push({ value: val.id, label: val.reference_name });
        }
      });
    }
    this.setState({ vehicles: data });
  };

  renderServiceRequest = (request, key) => {
    console.log('+++Request item is', request);
    return (
      <tr className="request-item" key={key}>
        <td className="item">
          <KuposCheckbox onChange={this.onChange} checked={request.checked} />
        </td>
        <td className="item">{Number(request.id)}</td>
        <td className="item capitalize-text">
          {request.txn_subtype === 'FROM_AIRPORT'
            ? 'Desde Aeropuerto'
            : request.txn_subtype === 'TO_AIRPORT'
            ? 'Hacia Aeropuerto'
            : '-'}
        </td>
        <td className="item">{request.pnr_id}</td>
        <td className="item">{request.pickup_address}</td>
        <td className="item">{request.drop_address}</td>
        <td className="big-entry item">{request.customer_name}</td>
        <td className="big-entry item">{request.customer_email}</td>
        <td className="big-entry item">{request.customer_phone}</td>
        <td className="item">
          {DateService.changeDateFormat(request.booking_date, 'yyyy-mm-dd', 'dd-mm-yyyy')}
        </td>

        <td className="item">
          {DateService.changeDateFormat(request.trip_ref_date, 'yyyy-mm-dd', 'dd-mm-yyyy')}
        </td>
        <td className="item">
          {request.trip_ref_time_type == 1 ? DateService.ampm(request.trip_ref_time) : ''}
        </td>
        <td className="item">
          {request.trip_ref_time_type == 0 ? DateService.ampm(request.trip_ref_time) : ''}
        </td>
        <td className="big-entry item">{request.customer_address}</td>
        <td className="item">{request.service_type}</td>
        <td className="item">{request.no_of_seats}</td>
        <td className="item">{CommonService.currency(request.net_amount)}</td>
        <td className="item">{request.sold_by}</td>
        <td className="item">{request.return_date}</td>
        {/* <td className="item">{request.comuna}</td> */}
        <td className="item">{request.comments}</td>
        <td className="item capitalize-text">
          {request.match === 'PASSENGER_BOARDED' ? (
            'Pasajero Embarcó'
          ) : request.match === 'PASSENGER_NOT_BOARDED' ? (
            'Pasajero No embarco'
          ) : (
            <KuposButton
              onClick={() => this.onDispatchButtonPress(request)}
              className="operations-download-button-inner"
              // disabled={
              //   request.vehicle_reference_name == '' || request.driver_name == ''
              //     ? true
              //     : Date.parse(request.trip_ref_date) < Date.parse(DateService.getToday())
              //     // : request.trip_ref_date != DateService.getToday() 
              //     ? false
              //     : true
              // }

              disabled={
                request.vehicle_reference_name == '' || request.driver_name == ''
                  ? true
                  : Date.parse(request.trip_ref_date) < Date.parse(DateService.getToday())
                  ? false
                  :  request.trip_ref_date == DateService.getToday() ? false : true
              }
            >
              <div
                className="vendor-icon operations-download-button"
                style={{ flexDirection: 'column', alignItems: 'center' }}
              >
                <img src="/images/dispatch.png" style={{ width: 20 }} />
                <div className="font10">Despachar</div>
              </div>
            </KuposButton>
          )}
        </td>

        {/* <td className="item">{request.booking_time.split('T')[1].slice(0, -1)}</td> */}
        {/* <td className="item">{request.txn_subtype.replace('_', ' ')}</td> */}

        <td className="item">
          <KuposSelectBox
            readonly={
              request.match === 'PASSENGER_NOT_BOARDED' || request.match === 'PASSENGER_BOARDED'
                ? true
                : false
            }
            showAll={true}
            options={this.state.vehicles}
            selectedOption={
              request.vehicle_reference_name != ''
                ? { value: 0, label: request.vehicle_reference_name }
                : ''
            }
            onChange={val => this.onServiceDriverChange(val, 'vehicle')}
            onInputChange={this.onInputChange}
            error={this.state.transferServiceError ? true : false}
            errorMessage={this.state.transferServiceError}
            placeholder={'xxxxx'}
            height={40}
          />
        </td>
        <td className="item">
          <KuposSelectBox
            readonly={
              request.match === 'PASSENGER_NOT_BOARDED' || request.match === 'PASSENGER_BOARDED'
                ? true
                : false
            }
            showAll={true}
            options={this.state.drivers}
            selectedOption={
              request.driver_name != '' ? { value: 0, label: request.driver_name } : ''
            }
            onChange={val => this.onServiceDriverChange(val, 'driver')}
            onInputChange={this.onInputChange}
            error={this.state.transferServiceError ? true : false}
            errorMessage={this.state.transferServiceError}
            placeholder={'xxxxx'}
            height={40}
          />
        </td>
        <td className="item">
          {request.driver_name == '' ? (
            <KuposButton
              onClick={() => this.onAssignDriver(request)}
              className="operations-download-button-inner"
            >
              <span className="vendor-icon operations-download-button">
                <span className="font10">Registrar</span>
              </span>
            </KuposButton>
          ) : (
            <KuposButton
              onClick={() => this.onUpdateDriver(request)}
              className="operations-download-button-inner VD-button"
              disabled={
                request.match === 'PASSENGER_NOT_BOARDED' || request.match === 'PASSENGER_BOARDED'
                  ? true
                  : false
              }
            >
              <span className="vendor-icon operations-download-button">
                <span className="font10">Editar</span>
              </span>
            </KuposButton>
          )}
        </td>
      </tr>
    );
  };

  onDispatchButtonPress = val => {
    this.setState({ selectedViewItem: true, serviceToDispatch: val });
  };

  onDispatchPassenger = () => {
    let data = {
      txn_id: this.state.serviceToDispatch.id,
      pnr_id: this.state.serviceToDispatch.pnr_id,
      dispatch_status: this.state.boarded ? 'PASSENGER_BOARDED' : 'PASSENGER_NOT_BOARDED',
    };
    this.props.actions
      .apiCall({ method: 'PUT', url: ApiUrls.dispatchStatus(), data: data })
      .then(res => {
        if (res.data && res.data.success && res.data.http_code == 201) {
          this.setState({
            selectedViewItem: false,
            showSuccessModal: true,
            successModalStatus: true,
            successModalMessage: "¡Listo!",
          });
          this.searchResults();
        } else {
          this.setState({
            selectedViewItem: false,
            showSuccessModal: true,
            successModalStatus: false,
            successModalMessage: 'Fail',
          });
        }
      });
  };

  onDispatchChange = type => {
    switch (type) {
      case 'boarded':
        this.setState({ boarded: !this.state.boarded, not_boarded: false });
        break;
      case 'not_boarded':
        this.setState({ not_boarded: !this.state.not_boarded, boarded: false });
        break;
    }
  };

  onServiceDriverChange = (val, type) => {
    console.log('Val received inside driver change', val);
    switch (type) {
      case 'vehicle':
        this.setState({ vehicleAssigned: val });
        break;
      case 'driver':
        this.setState({ driverAssigned: val });
        break;
    }
  };

  validate = () => {
    let errorCount = 0;
    if (!this.state.vehicleAssigned) {
      errorCount++;
    }
    if (!this.state.driverAssigned) {
      errorCount++;
    }
    if (errorCount > 0) {
      return false;
    } else {
      return true;
    }
  };

  onAssignDriver = request => {
    console.log('selected request is', request);
    this.setState({ selectedRequest: request });
    if (!this.validate()) {
      this.setState({
        showSuccessModal: true,
        successModalStatus: false,
        successModalMessage: 'Por favor seleccione conductor y vehículo',
      });
      return;
    }

    console.log('All fine and props are', this.props);

    let data = {
      txn_id: request.id,
      pnr_id: request.pnr_id,
      driver_id: this.state.driverAssigned.value,
      driver_name: this.state.driverAssigned.label,
      vehicle_id: this.state.vehicleAssigned.value,
      vehicle_name: this.state.vehicleAssigned.label,
      assignment_time_stg: DateService.getTodayString('yyyy-mm-dd'),
      assigned_by:
        this.props.home.loginData && this.props.home.loginData.data.user_details.first_name,
      // assignment_time: DateService.getCurrentTimeUTC(),
      assignment_time: DateService.getCurrentTimeUTCNew(),
    };

    this.props.actions
      .apiCall({ method: 'POST', url: ApiUrls.assignDriverAndVehicle(), data: data })
      .then(
        res => {
          this.onAssignDriveAndVehicle(res);
        },
        err => {
          this.onAssignDriveAndVehicle(err);
        },
      );
  };

  onUpdateDriver = request => {
    console.log('updated request is', request);
    // this.setState({updatedRequest: request})
    if (!this.validate()) {
      return;
    }

    let updateData = {
      txn_id: request.id,
      pnr_id: request.pnr_id,
      driver_id: this.state.driverAssigned.value,
      driver_name: this.state.driverAssigned.label,
      vehicle_id: this.state.vehicleAssigned.value,
      vehicle_name: this.state.vehicleAssigned.label,
      assigned_by:
        this.props.home.loginData && this.props.home.loginData.data.user_details.first_name,
      assignment_time: DateService.getCurrentTimeUTC(),
    };

    console.log('updated data to pass', updateData);

    this.props.actions
      .apiCall({ method: 'PUT', url: ApiUrls.updateVehicleAndDriver(), data: updateData })
      .then(
        res => {
          this.onAssignDriveAndVehicle(res);
        },
        err => {
          this.onAssignDriveAndVehicle(err);
        },
      );
  };

  onAssignDriveAndVehicle = result => {
    console.log('Result from DriverVehicle add', result);
    if (result.data && result.data.success && result.data.http_code == 201) {
      this.setState({
        showSuccessModal: true,
        successModalStatus: true,
        // successModalMessage: result.data.message,
        successModalMessage: "¡Listo!",
        vehicleAssigned:null,
        driverAssigned:null,
      });
      this.searchResults();
    } else {
      this.setState({
        showSuccessModal: true,
        successModalStatus: false,
        successModalMessage: result.data.data || 'Fail',
      });
    }
  };

  onStepClick = menuItem => {
    console.warn('Menu clicked ', menuItem);

    switch (menuItem) {
      case 1:
        this.setState({ selectedFilter: 'ALL' });
        break;
      case 2:
        this.setState({ selectedFilter: 'TO_AIRPORT' });
        break;
      case 3:
        this.setState({ selectedFilter: 'FROM_AIRPORT' });
        break;
      default:
        this.setState({ selectedFilter: 'ALL' });
        break;
    }

    // Highlight the selected Menu Item

    let elementsIndex = this.state.menuButtons.findIndex(element => element.key == menuItem - 1);

    let newMenus = [...this.state.menuButtons];

    newMenus.forEach((item, key) => {
      newMenus[key] = {
        ...newMenus[key],
        active: false,
      };
    });

    newMenus[elementsIndex] = {
      ...newMenus[elementsIndex],
      active: !newMenus[elementsIndex].active,
    };

    this.setState({ menuButtons: newMenus });

    //  Filter the arrays as per selection

    let fromAT = [];
    let toAT = [];

    if (!this.state.bookingRequests) {
      return;
    }
    for (let i = 0; i < this.state.bookingRequests.length; i++) {
      if (menuItem == 2) {
        if (this.state.bookingRequests[i].txn_subtype == 'TO_AIRPORT') {
          fromAT.push(this.state.bookingRequests[i]);
        }
      } else if (menuItem == 3) {
        if (this.state.bookingRequests[i].txn_subtype == 'FROM_AIRPORT') {
          toAT.push(this.state.bookingRequests[i]);
        }
      }
    }

    menuItem == 1
      ? this.setState({ filteredBookings: this.state.bookingRequests })
      : menuItem == 2
      ? this.setState({ filteredBookings: fromAT })
      : this.setState({ filteredBookings: toAT });
  };

  onTransferChange = (value, type) => {
    console.log('data is', value);
    if (type == 'going') {
      this.setState({ fromDate: value });
    } else {
      this.setState({ toDate: value });
    }
  };

  onServiceTypeChnage = value => {
    console.log(value);
    this.setState({ selectedService: value.value });
  };

  searchResults = () => {
    this.setState({ showLoader: true });
    this.props.actions
      .apiCall({
        url: `${ApiUrls.getBookingsForAgent()}/DELFOS/${
          this.state.selectedService
        }/${CommonService.getTimeInSeconds(
          DateService.changeDateFormat(this.state.fromDate, 'dd-mm-yyyy', 'yyyy-mm-dd') + 'T00:00',
        )}/${CommonService.getTimeInSeconds(
          DateService.changeDateFormat(this.state.toDate, 'dd-mm-yyyy', 'yyyy-mm-dd') + 'T23:59',
        )}/${this.state.selectedFilter}`,
      })
      .then(
        res => {
          this.onBookingResponse(res);
        },
        err => {
          this.onBookingResponse(err);
        },
      );

    // let searchedBookings = [];

    // var fromParts = this.state.fromDate.split('-');
    // var d1 = new Date(fromParts[2], fromParts[1], fromParts[0]);

    // var toParts = this.state.toDate.split('-');
    // var d2 = new Date(toParts[2], toParts[1], toParts[0]);

    // for (let i = 0; i < this.state.bookingRequests.length; i++) {

    //   let actualDateParts = this.state.bookingRequests[i].booking_date_utc.split("T")[0].split("-");
    //   let d3 = new Date(actualDateParts[0], actualDateParts[1], actualDateParts[2]);

    //   console.log("Actual date parts", actualDateParts)
    //   console.log('D3 is', d3);

    //   if (d3 >= d1 && d3<= d2) {

    //     console.log("Success inside if")
    //     searchedBookings.push(this.state.bookingRequests[i]);
    //   }
    // }

    // this.setState({filteredBookings : searchedBookings})
  };

  downloadFile = (fileName, urlData) => {
    // var aLink = document.createElement('a');
    // var evt = document.createEvent("HTMLEvents");
    // evt.initEvent("click");
    // aLink.download = fileName;
    // aLink.href = urlData;
    // aLink.dispatchEvent(evt);

    // this.props.history.push(fileName)
    window.open(urlData);
  };

  onBookButtonPress = () => {
    let csvContent =
      // 'Id, Desde/Hacia, N° Reserva , Nombre del cliente , Correo electrónico, Teléfono, Fecha de compra, Fecha de viaje, Hora de vuelo,Hora de recogida, Dirección, Tipo de servicio, Asientos, Monto Neto,Fecha de regreso, Partido,Conductor,Vehículo, Vendido por,Origen,Destino, Comentario \r\n';
      'Id, Desde/Hacia, N° Reserva , Origen,Destino, Nombre del cliente , Correo electrónico, Teléfono, Fecha de compra, Fecha de viaje, Hora de vuelo,Hora de recogida, Dirección, Tipo de servicio, Asientos, Monto Neto,Vendido por,Fecha de regreso,Comentario, Despachado,Vehículo,Conductor \r\n';

    this.state.filteredBookings &&
      this.state.filteredBookings.forEach(rowArray => {
        let row = '';
        Object.keys(rowArray).map(key => {
          console.log('+++Keys are ', key);

          if (key == 'trip_ref_date_time' || key == 'communa') {
            //do nothing
          } else {
            //internal conditions where we have to change format of the data
            if (key == 'id') {
              row += Number(rowArray[key]) + ',';
            } else if (key == 'booking_date' || key == 'trip_ref_date') {
              console.log('Inside date ');
              row += DateService.changeDateFormat(rowArray[key], 'yyyy-mm-dd', 'dd-mm-yyyy') + ',';
            } else if (key == 'trip_ref_time') {
              let data = rowArray['trip_ref_time_type'] == 1 ? DateService.ampm(rowArray[key]) : '-';
              row += data + ',';
            } else if (key == 'trip_ref_time_type') {
              let data = rowArray['trip_ref_time_type'] == 0 ? DateService.ampm(rowArray['trip_ref_time']) : '-';
              row += data + ',';
            }else if (key == 'match') {
              if (rowArray[key] == 'PASSENGER_BOARDED') {
                row += 'Pasajero Abordó' + ' ,';
              } else if (rowArray[key] == 'PASSENGER_NOT_BOARDED') {
                row += 'Pasajero No Abordó' + ' ,';
              } else {
                row += 'Estatus de despacho no fue procesado' + ' ,';
              }
            } else if(key == 'pickup_address') {
              let sanitizedData = rowArray[key].replace(/#|-|,|_/g,'')
              row += sanitizedData + ' ,';
            }else if(key == 'drop_address'){
              let sanitizedData = rowArray[key].replace(/#|-|,|_/g,'')
              row += sanitizedData + ' ,';
            }else if (key == 'comments') {
              let sanitizedData = rowArray[key].replace(/[^a-zA-Z ]/g, " ")
              row += sanitizedData + ' ,';
            } else if (key == 'net_amount') {
              row += CommonService.currency(rowArray[key]) + ' ,';
            } else if (key == 'return_date') {
              row += rowArray[key] + '. ,';
            } else if (key == 'txn_subtype') {
              if (rowArray[key] == 'FROM_AIRPORT') {
                row += 'Desde Airport' + ' ,';
              } else if (rowArray[key] == 'TO_AIRPORT') {
                row += 'Hacia Airport' + ' ,';
              } else {
                row += '-' + ' ,';
              }
            } else if (key == 'driver_name') {
              row += rowArray[key] ? rowArray[key] + ' ,' : ' - ,';
            } else if (key == 'vehicle_reference_name') {
              row += rowArray[key] ? rowArray[key] + ' ,' : ' - ,';
            } else {
              row +=
                rowArray[key]
                  .toString()
                  .split(',')
                  .join('') + ',';
            }
          }
        });

        // Object.values(rowArray).forEach(innerRow => {
        //   row +=
        //     innerRow
        //       .toString()
        //       .split(',')
        //       .join('') + ',';
        // });

        // let row = innerRowMain;
        csvContent += row + '\r\n';
      });

    var hiddenElement = document.createElement('a');
    hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(csvContent);
    hiddenElement.target = '_blank';
    hiddenElement.download = 'Report.csv';
    hiddenElement.click();

    // this.downloadFile('data.csv', 'data:text/csv;charset=UTF-8,' + encodeURIComponent(csvContent));
  };

  render() {
    console.log('+++today date is', Date.parse(DateService.getToday()));
    return (
      <div className="home-operations">
        <div className="operations-root-container">
          <div className="opeartions-top-strip">
            <KuposSteps
              menuButtons={this.state.menuButtons}
              onStepClick={val => this.onStepClick(val)}
            />
            <div className="home-selection-input-box font10">
              <span className="label font11">{'Ordenar por'}</span>
              <KuposSelectBox
                // readonly={true}
                showAll={true}
                options={this.selectServiceOptions}
                selectedOption={this.state.selectedService}
                onChange={val => this.onServiceTypeChnage(val)}
                onInputChange={this.onInputChange}
                placeholder={'Fecha de viaje'}
                height={40}
              />
            </div>
            <div className="home-selection-input-box font10 datebox">
              <span className="label font11">{'Desde'}</span>
              <KuposDatePicker
                //  selectedDate={this.props.transferData.selectedDate}
                selectedDate={this.state.fromDate}
                onDateChange={val => this.onTransferChange(val, 'going')}
                //  minDate={"01-01-2020"}
                error={this.state.transferDateError ? true : false}
                errorMessage={this.state.transferDateError}
                deactivateFutureDate={this.state.selectedService == 'BOOKING_DATE' ? true : false}
                placeholder={'Going Date'}
              />
            </div>
            <div className="home-selection-input-box font10 datebox">
              <span className="label font11">{'Hasta'}</span>
              <KuposDatePicker
                //  selectedDate={this.props.transferData.selectedDate}
                selectedDate={this.state.toDate}
                onDateChange={val => this.onTransferChange(val, 'return')}
                deactivateFutureDate={this.state.selectedService == 'BOOKING_DATE' ? true : false}
                minDate={this.state.fromDate}
                error={this.state.transferDateError ? true : false}
                errorMessage={this.state.transferDateError}
                placeholder={'Return Date'}
              />
            </div>

            <div className="home-selection-input-box font12 datebox">
              <button
                className="kupos-button home-selection-input-button "
                onClick={this.searchResults}
                style={{ marginTop: 24 }}
              >
                <img
                  className="icon-size"
                  src="/images/icons/home/icon_search-white.png"
                  alt={'TRANSLATIONS.ICON_SEARCH_WHITE'}
                />{' '}
              </button>
            </div>

            <div className="doc-search-button font12 operations-button-container">
              <KuposButton
                onClick={this.onBookButtonPress}
                disabled={this.state.disableDownloadButton}
                className="operations-download-button-inner"
              >
                <span className="vendor-icon operations-download-button">
                  <img src="/images/ticket-search.png" />
                  <span className="font10">Descargar reporte</span>
                </span>
              </KuposButton>
            </div>
          </div>
          <div className="operations-main-body-container">
            <div className="operations-main-body">
              <Table responsive="sm" striped bordered>
                {this.state.showHeader &&
                this.state.filteredBookings &&
                this.state.filteredBookings.length > 0 ? (
                  <thead>
                    <tr className="operations-top-strip">
                      <th></th>
                      <th className="bold-text">ID</th>
                      <th className="bold-text">Desde/Hacia</th>
                      <th className="bold-text">N° Reserva</th>
                      <th className="bold-text">Origen</th>
                      <th className="bold-text">Destino</th>
                      <th className="big-entry  bold-text">Nombre del cliente</th>
                      <th className="big-entry  bold-text">Correo electrónico</th>
                      <th className="big-entry bold-text">Teléfono</th>
                      <th className="bold-text">Fecha de compra</th>
                      <th className="bold-text">Fecha de viaje</th>
                      <th className="bold-text">Hora de vuelo</th>
                      <th className="bold-text">Hora de recogida</th>
                      <th className="big-entry bold-text">Dirección</th>
                      <th className="bold-text">Tipo de servicio</th>
                      <th className="bold-text">Asientos</th>
                      <th className="bold-text">Monto Neto</th>
                      <th className="bold-text">Vendido por</th>
                      <th className="bold-text">Fecha de regreso</th>
                      {/* <th className="bold-text">Comuna</th> */}
                      <th className="bold-text">Comentario</th>
                      <th className="bold-text">Despachado</th>
                      <th className="bold-text minwidth150">Vehículo</th>
                      <th className="bold-text minwidth150">Conductor</th>
                      <th className="bold-text">Vehículo</th>
                      {/* <th className="bold-text">Conductor</th>
                      <th className="bold-text">Acciones</th> */}
                    </tr>
                  </thead>
                ) : null}
                <tbody>
                  {this.state.bookingRequests &&
                    this.state.filteredBookings.map((val, key) =>
                      this.renderServiceRequest(val, key),
                    )}
                </tbody>
              </Table>
            </div>
            {/* <div className="operations-rest-body font10">
                       {this.state.bookingRequests &&
                         this.state.filteredBookings.map((val, key) =>
                           this.renderServiceRequest(val, key),
                         )}
                     </div> */}
          </div>
          {/* </div> */}
        </div>

        {this.state.showLoader ? (
          <div className="loader-container">
            <Loader
              visible={this.state.showLoader}
              type="Bars"
              color="#637290"
              height={80}
              width={80}
            />
          </div>
        ) : null}
        <KuposModalWithButtons
          size="md"
          buttonText1="Registrar"
          onButtonClick1={this.onDispatchPassenger}
          showButton1={true}
          showModal={this.state.selectedViewItem}
          onHide={() => this.setState({ selectedViewItem: null })}
        >
          <div>
            <KuposRadio
              onChange={() => this.onDispatchChange('boarded')}
              checked={this.state.boarded}
              label={'Pasajero embarco'}
            />
            <KuposRadio
              onChange={() => this.onDispatchChange('not_boarded')}
              checked={this.state.not_boarded}
              label={'Pasajero no embarco'}
            />
          </div>
        </KuposModalWithButtons>
        <KuposErrorSuccessModal
          success={this.state.successModalStatus}
          showModal={this.state.showSuccessModal}
          onButtonClick={() => {
            this.setState({
              showSuccessModal: false,
              successModalStatus: false,
              successModalMessage: false,
            });
          }}
          bodyText={this.state.successModalMessage}
        />
      </div>
    );
  }
}

/* istanbul ignore next */
function mapStateToProps(state) {
  return {
    home: state.home,
  };
}

/* istanbul ignore next */
function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...actions }, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Operations);
