import React from 'react';
import CommonService from '../../services/commonService';
import './printTicket.css';

class PrintTicket extends React.Component {
  state = this.props;

  render() {
    
    let clientInfo = CommonService.getLocal('clientInfo')
    console.log('client info in print ticket-------', clientInfo && clientInfo);
    var styles1 = {
      // color: '#717b84',
      fontSize: '12px',
      lineHeight: '15px',
      display: 'block',
      fontWeight: 'normal',
    };

    var center = {
      textAlign: 'center',

      fontSize: '12px',
      fontWeight: 'bold',
    };

    var font12PX = {
      textAlign: 'center',
      fontSize: '14px',
      fontWeight: 'bold',
    };
    var ticketCost = {
      color: '#1dade4',
    };
    var imageSize = {
      maxWidth: '200px',
      width: '200px',
    };

    var horizontalRuler = {
      border: '1px solid #fee3a8',
    };

    var minWidth = {
      minWidth: '200px',
    };

    return (
      // <div>
      //   <div>Some data to print</div>
      // </div>
      <div id="print-ticket" class="section-to-print">
        <table className="ticket_table">
          <tr>
            {/* <td> */}
            <img
              // style={imageSize}
              // src="/images/delfos_print_white.png"
              src={(clientInfo && clientInfo.logo_path) ||
                '/images/delfos_print_white.png'
              }
              style={{ imageSize, marginBottom: '20px' }}
              width={'200'}
              className="logo-img"
            />
            {/* </td> */}
          </tr>
          {/* <tr>
            <td style={font12PX}>

              {this.props.printData.data.txn_subtype === 'FROM_AIRPORT'
                ? this.props.printData.data.customer_address
                : 'Airport'}
            </td>
          </tr> */}
          <tr>
            <td style={{ color: 'red' }}>
              {this.props.printData.data.confirm_deadline_utc &&
              this.props.printData.data.confirm_deadline_utc != 0
                ? `BOLETO PENDIENTE. Valido hasta ${this.props.printData.data.confirm_deadline_stg} Asegúrese de recolectar el dinero del boleto antes de confirmarlo.`
                : null}
            </td>
          </tr>

          <tr>
            <td> Origen :</td>
            <td>{this.props.printData.data.pickup_address || this.props.printData.origin}</td>
            {/* <td>{ this.props.printData.origin}</td> */}
          </tr>
          <tr>
            <td> Destino : </td>
            <td>{this.props.printData.data.drop_address || this.props.printData.destination}</td>
            {/* <td>{ this.props.printData.destination}</td> */}
          </tr>

          <tr>
            <td style={minWidth}>Tipo de servicio : </td>
            <td> {this.props.printData.data.service_type}</td>
          </tr>

          <tr>
            <td> Cupos :</td>
            <td> {this.props.printData.data.no_of_seats}</td>
          </tr>

          <tr>
            <td> PNR : </td>
            <td>
              {this.props.printData.data.pnr_no || this.props.printData.pnr_no || 'AT0000000'}
            </td>
          </tr>

          <tr>
            <td> Normbre del Pasajero : </td>
            <td>{this.props.printData.data.customer_name || ''}</td>
          </tr>

          <tr>
            <td> {CommonService.capitalize(this.props.printData.data.identity_type)} : </td>
            <td>{this.props.printData.data.identity_value || ''}</td>
          </tr>

          <tr>
            <td>Nacionalidad : </td>
            <td>{this.props.printData.data.nationality || ''}</td>
          </tr>

          {/* <tr>
            <td style={{ height: '20px' }}>
              <span style={styles1}>
                Normbre del Pasajero : {this.props.printData.data.customer_name || ''}
              </span>
              <span style={styles1}>
                {CommonService.capitalize(this.props.printData.data.identity_type) +
                  '  ' +
                  this.props.printData.data.identity_value}{' '}
              </span>
              <span style={styles1}>
                Nacionalidad : {this.props.printData.data.nationality || ''}
              </span>
            </td>
          </tr> */}

          <tr>
            <td> Fecha de Viaje : </td>
            <td>{this.props.printData.date || this.props.printData.data.trip_ref_date}</td>
          </tr>

          <tr>
            <td style={minWidth}>Hora de recogida :</td>
            <td> {this.props.printData.time || this.props.printData.data.trip_ref_time}</td>
          </tr>

          {/* <tr>
            <td> Precio de Pasaje : </td>
            <td>
              <span style={ticketCost}>
                {CommonService.currency(this.props.printData.data.total_amount)}
              </span>{' '}
            </td>
          </tr> */}

          <tr>
            <td> Monto total : </td>
            <td>
                {CommonService.currency(this.props.printData.data.total_amount)}
            </td>
          </tr>

          <tr>
            <td> Descuento total:</td>
            <td> {CommonService.currency(this.props.printData.data.discount_amount) || 0}</td>
          </tr>

          <tr>
            <td> Tarifa adicional :</td>
            <td> {CommonService.currency(this.props.printData.data.additional_fare) || 0}</td>
          </tr>

          <tr>
            <td>Monto neto : </td>
            <td>
              {' '}
              {this.props.printData.data.price_type === 'PER_PERSON'
                ? CommonService.currency(
                    this.props.printData.data.price * this.props.printData.data.no_of_seats +
                      this.props.printData.data.additional_fare -
                      this.props.printData.data.discount_amount || 0,
                  )
                : CommonService.currency(
                    this.props.printData.data.price +
                      this.props.printData.data.additional_fare -
                      this.props.printData.data.discount_amount || 0,
                  )}
            </td>
          </tr>

          <tr>
            <td>Reservado por : </td>
            <td>{this.props.printData.data.user_name} </td>
          </tr>

          <tr>
            <td>Impreso por : </td>
            <td>{this.props.printData.data.user_name}</td>
          </tr>
        </table>

        <span>
          {/* <td style={center}> */}
          <b>Terminos y Condiciones</b>
          {/* </td> */}
        </span>
        <span>
          {/* <td> */}
          <span style={styles1}>
            Nota: este boleto es válido para la fecha y hora señalada. La devolución y/o cambio se
            aceptará hasta 4 horas antes del inicio del servicio. Si canceló en efectivo se
            devolverá: el 85% (Art. 67 d.s. 212/92 MT). Si el valor del equipaje excede de las 5
            UTM, es su obligación declarar previamente en la oficina de origen (Art. 76 d.s. 212/92
            MT). Los boletos no confirmados tendrán una vigencia de 6 meses, desde la fecha de
            venta. No se aceptan boletos rotos o enmendados. La empresa no se hace responsable por
            perdidas al interior del bus. La empresa se reserva el derecho a cambiar el tipo de bus
            por otro de similares características.
          </span>
          {/* </td> */}
        </span>

        <span>
          {/* <td style={center}> */}
          <b>D.S. 212 Artículo N# 68</b>
          {/* </td> */}
        </span>
        <span>
          {/* <td> */}
          <span style={styles1}>
            Usted tiene derecho a llevar hasta 30 kilos de equipaje sin costo, siempre que el
            volumen no exceda 180 decímetros cúbicos, si peso y decímetros cúbicos del equipaje son
            mayores al establecido, se deberá cancelar el costo adicional. Compra tus boletos en
          </span>
          {/* </td> */}
        </span>
        <span>
          <td>
            <hr style={horizontalRuler} />
          </td>
        </span>

        <span>
          <td style={center}>www.transferdelfos.com</td>
        </span>
      </div>
    );
  }
}

export default PrintTicket;
