import React, { useEffect, useRef, useState } from 'react';
import { KuposButton, KuposInput, KuposErrorSuccessModal } from '../../common';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { useDispatch } from 'react-redux';
import ApiUrls from '../../../services/apiUrls';
import { apiCall } from '../redux/apiCall';
import { DropdownComponent } from '../../common/DropdownComponent';

const initialState = {
  id: '',
  name: '',
  address: '',
  contact_name: '',
  contact_email: '',
  contact_phone: '',
  status: '',
  primary_color: '',
  secondary_color: '',
  primary_text_color: '',
  secondary_text_color: '',
  subdomain: '',
  main_domain: '',
  email_cc: '',
  email_bcc: '',
  terms: '',
};

const modalInitialState = {
  show: false,
  success: false,
  bodyText: '',
};

const errorState = {};

const statuses = [
  {
    id: 0,
    value: 'ACTIVE',
    label: 'ACTIVE',
  },
  {
    id: 1,
    value: 'INACTIVE',
    label: 'INACTIVE',
  },
];

const EditClientScreen = ({ history, match }) => {
  const dispatch = useDispatch();
  const onResponse = useRef(() => {});
  const [values, setValues] = useState(initialState);
  const [errors, setErrors] = useState(errorState);
  const [modal, setModal] = useState(modalInitialState);
  const [terms, setTerms] = useState('');
  const [buttonLoader, setButtonLoader] = useState(false);
  const [status, setStatus] = useState('');
  // const [opStatus, setOpStatus] = useState('');

  console.log({ values, terms, status });

  const id = match.params ? parseInt(match.params.id) : null;

  useEffect(() => {
    if (id) {
      dispatch(
        apiCall({
          url: ApiUrls.clients(id),
          callback: res => onResponse.current(res),
        }),
      );
    }
  }, [dispatch, id]);

  onResponse.current = res => {
    // console.log({ res });
    if (res && res.data && res.data.success) {
      const data = res.data.data;
      setValues(data);
      setTerms(data.terms);
      setStatus([{ value: data.status, label: data.status }]);
      // console.log('-----------------what', found);
    }
  };

  const onChange = (val, type) => {
    setValues({ ...values, [type]: val });
  };

  const onBlur = () => {};

  const validate = () => {
    let errors = 0;
    setErrors(errorState);
    if (!values.name) {
      setErrors({ ...errors, nameError: 'Required' });
      errors++;
      return;
    }

    if (!values.address) {
      setErrors({ ...errors, addressError: 'Required' });
      errors++;
      return;
    }

    if (!values.contact_name) {
      setErrors({ ...errors, contactNameError: 'Required' });
      errors++;
      return;
    }

    if (!values.contact_email) {
      setErrors({ ...errors, contactEmailError: 'Required' });
      errors++;
      return;
    }

    // if (!values.contact_phone) {
    //   setErrors({ ...errors, contactPhoneError: 'Required' });
    //   errors++;
    //   return;
    // }

    if (!values.status) {
      setErrors({ ...errors, statusError: 'Required' });
      errors++;
      return;
    }

    // if (!values.primary_color) {
    //   setErrors({ ...errors, primaryColorError: 'Required' });
    //   errors++;
    //   return;
    // }

    // if (!values.secondary_color) {
    //   setErrors({ ...errors, secondaryColorError: 'Required' });
    //   errors++;
    //   return;
    // }

    // if (!values.primary_text_color) {
    //   setErrors({ ...errors, primaryTextColorError: 'Required' });
    //   errors++;
    //   return;
    // }

    // if (!values.secondary_text_color) {
    //   setErrors({ ...errors, secondaryColorError: 'Required' });
    //   errors++;
    //   return;
    // }

    if (!values.subdomain) {
      setErrors({ ...errors, subDomainError: 'Required' });
      errors++;
      return;
    }

    // if (!values.main_domain) {
    //   setErrors({ ...errors, mainDomainError: 'Required' });
    //   errors++;
    //   return;
    // }

    // if (!values.email_cc) {
    //   setErrors({ ...errors, EmailccError: 'Required' });
    //   errors++;
    //   return;
    // }

    if (!values.email_bcc) {
      setErrors({ ...errors, EmailbccError: 'Required' });
      errors++;
      return;
    }

    if (!terms) {
      setErrors({ ...errors, termsError: 'Required' });
      errors++;
      return;
    }

    if (errors > 0) {
      return false;
    } else {
      return true;
    }
  };

  const createOrUpdate = () => {
    if (!validate()) {
      return;
    }
    let data = values;
    data.terms = terms;

    setButtonLoader(true);
    dispatch(
      apiCall({
        url: ApiUrls.clients(),
        data,
        method: 'PUT',
        callback: res => onUpdateResponse(res),
      }),
    );
  };

  const onUpdateResponse = res => {
    // console.log({ res });
    setButtonLoader(false);
    if (res && res.data && res.data.success) {
      setModal({
        show: true,
        success: true,
        bodyText: res.data.message ? res.data.message : 'Update Success',
        redirect: true,
      });
    } else {
      setModal({
        show: true,
        success: false,
        bodyText: res.data.message ? res.data.message : 'Error',
      });
    }
  };

  return (
    <div className="pr-3 pl-3">
      <h2 className="mt-4 mb-4 font-bold">Update Client</h2>
      <div className="create-user-input pb-2 mt-2">
        <KuposInput
          type="text"
          className="kupos-border"
          placeholder={'Primer nombre'}
          label={'Primer nombre'}
          value={values.name}
          error={errors.nameError ? true : false}
          errorMessage={errors.nameError ? errors.nameError : ''}
          onChange={val => onChange(val, 'name')}
          onBlur={text => onBlur(text)}
        />
      </div>

      <div className="create-user-input pb-2 mt-2">
        <KuposInput
          type="text"
          className="kupos-border"
          placeholder={'Address'}
          label={'Address'}
          value={values.address}
          error={errors.addressError ? true : false}
          errorMessage={errors.addressError ? errors.addressError : ''}
          onChange={val => onChange(val, 'address')}
          onBlur={text => onBlur(text)}
        />
      </div>
      <div className="create-user-input pb-2 mt-2">
        <KuposInput
          type="email"
          className="kupos-border"
          placeholder={'Contact Name'}
          label={'Contact Nmae'}
          value={values.contact_name}
          error={errors.contactNameError ? true : false}
          errorMessage={errors.contactNameError ? errors.contactNameError : ''}
          onChange={val => onChange(val, 'contact_name')}
          onBlur={text => onBlur(text)}
        />
      </div>

      <div className="create-user-input pb-2 mt-2">
        <KuposInput
          type="text"
          className="kupos-border"
          placeholder={'Contact Email'}
          label={'Contact Email'}
          value={values.contact_email}
          error={errors.contactEmailError ? true : false}
          errorMessage={errors.contactEmailError ? errors.contactEmailError : ''}
          onChange={val => onChange(val, 'contact_email')}
          onBlur={text => onBlur(text)}
        />
      </div>

      <div className="create-user-input pb-2 mt-2">
        <KuposInput
          type="text"
          className="kupos-border"
          placeholder={'Contact Phone'}
          label={'Contact Phone'}
          value={values.contact_phone}
          error={errors.contactPhoneError ? true : false}
          errorMessage={errors.contactPhoneError ? errors.contactPhoneError : ''}
          onChange={val => onChange(val, 'contact_phone')}
          onBlur={text => onBlur(text)}
        />
      </div>
      <div className="create-user-input pb-2 mt-2">
        <DropdownComponent
          onChange={val => {
            setStatus(val);
            setValues({ ...values, status: val.value });
          }}
          placeholder={'Status'}
          value={status}
          options={statuses}
          error={errors.statusError ? true : false}
          errorMessage={errors.statusError ? errors.statusError : ''}
        />
        {/* <KuposInput
          type="email"
          className="kupos-border"
          placeholder={'Status'}
          label={'Status'}
          value={values.status}
          error={errors.statusError ? true : false}
          errorMessage={errors.statusError ? errors.statusError : ''}
          onChange={val => onChange(val, 'status')}
          onBlur={text => onBlur(text)}
        /> */}
      </div>

      <div className="create-user-input pb-2 mt-2">
        <KuposInput
          type="text"
          className="kupos-border"
          placeholder={'Primary Color'}
          label={'Primary Color'}
          value={values.primary_color}
          error={errors.primaryColorError ? true : false}
          errorMessage={errors.primaryColorError ? errors.primaryColorError : ''}
          onChange={val => onChange(val, 'primary_color')}
          onBlur={text => onBlur(text)}
        />
      </div>

      <div className="create-user-input pb-2 mt-2">
        <KuposInput
          type="text"
          className="kupos-border"
          placeholder={'Secondary Color'}
          label={'Secondary Color'}
          value={values.secondary_color}
          error={errors.secondaryColorError ? true : false}
          errorMessage={errors.secondaryColorError ? errors.secondaryColorError : ''}
          onChange={val => onChange(val, 'secondary_color')}
          onBlur={text => onBlur(text)}
        />
      </div>
      <div className="create-user-input pb-2 mt-2">
        <KuposInput
          type="email"
          className="kupos-border"
          placeholder={'Primary Text Color'}
          label={'Primary Text Color'}
          value={values.primary_text_color}
          error={errors.primaryTextColorError ? true : false}
          errorMessage={errors.primaryTextColorError ? errors.primaryTextColorError : ''}
          onChange={val => onChange(val, 'primary_text_color')}
          onBlur={text => onBlur(text)}
        />
      </div>
      <div className="create-user-input pb-2 mt-2">
        <KuposInput
          type="text"
          className="kupos-border"
          placeholder={'Secondary Text Color'}
          label={'Secondary Text Color'}
          value={values.secondary_text_color}
          error={errors.secondaryTextColorError ? true : false}
          errorMessage={errors.secondaryTextColorError ? errors.secondaryTextColorError : ''}
          onChange={val => onChange(val, 'secondary_text_color')}
          onBlur={text => onBlur(text)}
        />
      </div>

      <div className="create-user-input pb-2 mt-2">
        <KuposInput
          type="text"
          className="kupos-border"
          placeholder={'Subdomain'}
          label={'Subdomain'}
          value={values.subdomain}
          error={errors.subDomainError ? true : false}
          errorMessage={errors.subDomainError ? errors.subDomainError : ''}
          onChange={val => onChange(val, 'subdomain')}
          onBlur={text => onBlur(text)}
        />
      </div>
      <div className="create-user-input pb-2 mt-2">
        <KuposInput
          type="email"
          className="kupos-border"
          placeholder={'Main Domain'}
          label={'Main Domain'}
          value={values.main_domain}
          error={errors.mainDomainError ? true : false}
          errorMessage={errors.mainDomainError ? errors.mainDomainError : ''}
          onChange={val => onChange(val, 'main_domain')}
          onBlur={text => onBlur(text)}
        />
      </div>
      <div className="create-user-input pb-2 mt-2">
        <KuposInput
          type="text"
          className="kupos-border"
          placeholder={'Email cc'}
          label={'Email cc'}
          value={values.email_cc}
          error={errors.EmailccError ? true : false}
          errorMessage={errors.EmailccError ? errors.EmailccError : ''}
          onChange={val => onChange(val, 'email_cc')}
          onBlur={text => onBlur(text)}
        />
      </div>

      <div className="create-user-input pb-2 mt-2">
        <KuposInput
          type="text"
          className="kupos-border"
          placeholder={'Email bcc'}
          label={'Email bcc'}
          value={values.email_bcc}
          error={errors.EmailbccError ? true : false}
          errorMessage={errors.EmailbccError ? errors.EmailbccError : ''}
          onChange={val => onChange(val, 'email_bcc')}
          onBlur={text => onBlur(text)}
        />
      </div>
      <div className="create-user-input pb-2 mt-2">
        <div
          style={
            errors.termsError
              ? { border: 'red 1px solid', borderRadius: '10px', overflow: 'hidden' }
              : { border: 'lightgray 1px solid', borderRadius: '10px' }
          }
        >
          <CKEditor
            editor={ClassicEditor}
            data={terms}
            onReady={editor => {
              // You can store the "editor" and use when it is needed.
              // console.log(
              //      'Editor is ready to use!',
              //      editor
              // );
            }}
            onChange={(___, editor) => {
              // console.log(editor);
              let data = editor.getData();
              setTerms(data);
            }}
            // onBlur={(event, editor) => {
            //  console.log('Blur.', editor);
            // }}
            // onFocus={(event, editor) => {
            //  console.log('Focus.', editor);
            // }}
          />
        </div>

        {errors.termsError && <span className="text-danger font8">{errors.termsError}</span>}
      </div>

      <div className="button-container" style={{ marginTop: 20, marginBottom: 20 }}>
        <KuposButton onClick={createOrUpdate}>
          <span className="font11">
            {buttonLoader ? <div className="loader-cricle"></div> : 'Update'}
          </span>
        </KuposButton>
      </div>

      <KuposErrorSuccessModal
        showModal={modal.show}
        success={modal.success}
        smallIcon={true}
        bodyText={modal.bodyText}
        onButtonClick={() => {
          setModal({ ...modal, show: false });
          if (modal.redirect) {
            history.goBack();
          }
        }}
      />
    </div>
  );
};

export default EditClientScreen;
