import React, { useEffect } from 'react';
import { apiCall } from './redux/apiCall';
import ApiUrls from '../../services/apiUrls';
import { useDispatch } from 'react-redux';
import CommonService from '../../services/commonService';

const HomePage = (props) => {
  const dispatch = useDispatch()

  return (
    <div className="home-home-page">
      <div className="hero-image-container">
        <img src={(props.common && props.common.clientInfo && props.common.clientInfo.banner_path) || "/homepage/hero-image.png"} />
      </div>
    </div>
  );
}



export default HomePage;
