import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  DropdownComponent,
  KuposButton,
  KuposErrorSuccessModal,
  KuposInput,
  KuposTimePicker,
} from '../common';
import ApiUrls from '../../services/apiUrls';
import CommonService from '../../services/commonService';
import Loader from 'react-loader-spinner';
import { Snackbar } from '@material-ui/core';
import { apiCall } from './redux/apiCall';
import { Alert } from '@material-ui/lab';


const modalInitialState = {
  show: false,
  success: false,
  bodyText: null,
};
const initialState = {
  services: [],
  bookingThreshold: '00:00',
  adminCost: '0',
  policyName: '',
  percentage: '',
  remarks: '',
}
const AddCancellationPolicy = ({ history, match }) => {
  const dispatch = useDispatch();
  const [values, setValues] = useState(initialState);
  const [modal, setModal] = useState(modalInitialState);
  const [loader, setLoader] = useState(true);
  const [snackbar, setSnackbar] = useState({});
  const [count, setCount] = useState('')

  const id = match.params ? Number(match.params.id) : null;
  console.log(values)
  // redirecting back if data not found using timer
  useEffect(() => {
    let interval
    if (count) {
      interval = setInterval(() => {
        setCount((currentCount) => --currentCount)
      }, 1000);
    }
    // Redirect once the count is === 1
    count === 1 && history.push('/cancellation-policies');
    // CleanUp
    return () => clearInterval(interval)
  }, [count, history])

  useEffect(() => {
    if (id) {
      getPolicyList();
    } else {
      setLoader(false)
    }
    getServiceList();
    // eslint-disable-next-line
  }, [id])

  const getServiceList = () => dispatch(apiCall({ url: ApiUrls.getServices(), callback: res => getServiceListResponse(res) }))
  const getServiceListResponse = (res) => {
    if (res && res.data && res.data.success) {
      let services = [];
      res.data.data.map(service => {
        services.push({ value: service.id, label: service.name });
      });
      setValues({ ...values, services })
    } else if (res && res.data && !res.data.success) {
      setSnackbar({
        showSnackbar: true,
        persist: true,
        alertMessage: res.data.message ? res.data.message : 'Failure'
      });
    } else {
      setSnackbar({
        showSnackbar: true,
        persist: true,
        alertMessage: 'Check Your Internet Connection'
      });
    }
  };

  const getPolicyList = () => dispatch(apiCall({ url: ApiUrls.addCancellationPolicy(), callback: res => getPolicyListResponse(res) }));
  const getPolicyListResponse = (res) => {
    setLoader(false)
    // console.log('+++ Result from all countries api', res);
    if (res && res.data && res.data.success) {
      const found = res.data.data.find(f => f.id === id);
      if (found) {
        console.log({ found });
        getParticularPolicy(found);
      } else {
        setSnackbar({
          showSnackbar: true,
          persist: true,
          alertMessage: 'Record not found'
        });
        setCount(5)
      }
    } else if (res && res.data && !res.data.success) {
      setSnackbar({
        showSnackbar: true,
        persist: true,
        alertMessage: res.data.message ? res.data.message : 'Failure'
      });
    } else {
      setSnackbar({
        showSnackbar: true,
        persist: true,
        alertMessage: 'Check Your Internet Connection'
      });
    }
  };


  const getParticularPolicy = (element) => {
    console.log(' particuar service item is', element);
    setValues({
      ...values,
      policyToEdit: element,
      policyName: element.policy_name,
      selectedService: [{ value: element.service_type, label: element.service_type }],
      percentage: element.percentage,
      bookingThreshold: element.hours_before + ':' + element.minutes_before,
      adminCost: element.admin_cost,
      remarks: element.remark,
    });
  };

  const onChange = (val, type) => {
    switch (type) {
      case 'policy_name':
        setValues({ ...values, policyName: val, policyNameError: null });
        break;
      case 'bookingThreshold':
        setValues({ ...values, bookingThreshold: val, bookingThresholdError: null });
        break;
      case 'percentage':
        setValues({ ...values, percentage: val, percentageError: null });
        break;
      case 'adminCost':
        setValues({ ...values, adminCost: val, adminCostError: null });
        break;
      case 'remark':
        setValues({ ...values, remarks: val, remarksError: null });
        break;
    }
  };

  const onServiceChange = val => {
    console.log('selected service is', val);
    setValues({ ...values, selectedService: val, selectedServiceError: null });
  };

  const onBlur = () => { };

  const validate = () => {
    if (!values.policyName) {
      setValues({
        ...values,
        policyNameError: 'La referencia de la política es requerido',
      });
      return false;
    }

    if (!values.selectedService) {
      setValues({
        ...values, selectedServiceError: 'Seleccione un servicio',
      });
      return false;
    }

    if (!values.bookingThreshold) {
      setValues({
        ...values,
        bookingThresholdError: 'Hours before is required',
      });
      return false;
    }

    if (!values.percentage) {
      setValues({
        ...values,
        percentageError: 'El porcentaje es requerido',
      });
      return false;
    }
    if (!values.adminCost) {
      setValues({
        ...values,
        adminCostError: 'admin cost is required',

      });
      return false;
    }
    if (!values.remarks) {
      setValues({
        ...values,
        remarksError
          : 'Ingrese un comentarios',

      });
      return false;
    }

    return true;
  };

  const createOrUpdate = () => {
    if (!validate()) {
      return;
    }

    let data, method = 'POST';

    if (id) {
      method = 'PUT'
      data = {
        id: values.policyToEdit.id,
        admin_cost: parseFloat(values.adminCost),
        calculation_type: values.policyToEdit.calculation_type,
        client_code: values.policyToEdit.client_code,
        created_by: values.policyToEdit.created_by,
        created_on: values.policyToEdit.created_on,
        days_before: values.policyToEdit.days_before,
        fixed_rate: values.policyToEdit.fixed_rate,
        hours_before: Number(values.bookingThreshold.split(':')[0]),
        interval_in_ticks: CommonService.getSecondsFromTime(values.bookingThreshold),
        last_updated_by: values.policyToEdit.last_updated_by,
        last_updated_on: values.policyToEdit.last_updated_on,
        max_amount: values.policyToEdit.max_amount,
        min_amount: values.policyToEdit.min_amount,
        minutes_before: Number(values.bookingThreshold.split(':')[1]),
        percentage: parseFloat(values.percentage),
        policy_name: values.policyToEdit.policy_name,
        remark: values.remarks,
        service_type: values.policyToEdit.service_type,
        status: values.policyToEdit.status,
      }
    } else {
      data = {
        policy_name: values.policyName,
        service_type: values.selectedService.label,
        hours_before: Number(values.bookingThreshold.split(':')[0]),
        minutes_before: Number(values.bookingThreshold.split(':')[1]),
        interval_in_ticks: CommonService.getSecondsFromTime(values.bookingThreshold),
        percentage: parseFloat(values.percentage),
        admin_cost: parseFloat(values.adminCost),
        remark: values.remarks,
      }
    }


    setLoader(true)
    dispatch(
      apiCall({
        url: ApiUrls.addCancellationPolicy(),
        method,
        data,
        callback: res => onAddPolicyResponse(res),
      }),
    );

  };

  const onAddPolicyResponse = res => {
    setLoader(false)
    if (res.data && res.data.success && res.data.data) {
      setModal({
        show: true,
        success: true,
        redirect: true,
        bodyText: 'Éxito' || (id ? 'Éxito' : 'Éxito'),
      });
    } else if (!res.data.success && res.data.message) {
      setModal({ show: true, success: false, bodyText: res.data.message });
    } else {
      setModal({ show: true, success: false, bodyText: 'Fail' });
    }
  };

  return (
    <div className="home-add-location">
      <div className="common-kupos-input-box" style={{ marginTop: 10 }}>
        <KuposInput
          type="text"
          label="Referencia de la política"
          className="kupos-border"
          placeholder={'Referencia de la política'}
          value={values.policyName}
          error={values.policyNameError ? true : false}
          disabled={id ? true : false}
          errorMessage={values.policyNameError ? values.policyNameError : ''}
          onChange={text => onChange(text, 'policy_name')}
          onBlur={text => onBlur(text, 'policy_name')}
        />
      </div>

      <div className="home-selection-input-box font10 select-box mb-3">
        <DropdownComponent
          onChange={val => onServiceChange(val)}
          placeholder={'Nombre del servicio'}
          label="Nombre del servicio"
          disabled={id ? true : false}
          options={values.services}
          value={values.selectedService}
          error={values.selectedServiceError ? true : false}
          errorMessage={
            values.selectedServiceError ? values.selectedServiceError : null
          }
        />
      </div>

      <div className="home-selection-input-box font10 datebox" style={{ marginTop: 10 }}>
        <span className="label font11">{'Tiempo límite de cancelación (HH:MM)'}</span>
        <KuposTimePicker
          value={values.bookingThreshold}
          onChange={val => onChange(val, 'bookingThreshold')}
          error={values.bookingThresholdError ? true : false}
          errorMessage={values.bookingThresholdError}
          placeholder={'00:00'}
          hideAmPm
          not24Hours
          leftPadding={35}
        />
      </div>

      <div className="common-kupos-input-box" style={{ marginTop: 10 }}>
        <KuposInput
          type="number"
          min={0}
          className="kupos-border"
          placeholder={'Porcentaje'}
          label="Porcentaje"
          value={values.percentage}
          error={values.percentageError ? true : false}
          errorMessage={values.percentageError ? values.percentageError : ''}
          onChange={text => onChange(text, 'percentage')}
          onBlur={text => onBlur(text, 'percentage')}
        />
      </div>

      <div className="common-kupos-input-box" style={{ marginTop: 10 }}>
        <KuposInput
          type="number"
          min={0}
          className="kupos-border"
          placeholder={'Costo administrativo'}
          label="Costo administrativo"
          value={values.adminCost}
          error={values.adminCostError ? true : false}
          errorMessage={values.adminCostError ? values.adminCostError : ''}
          onChange={text => onChange(text, 'adminCost')}
          onBlur={text => onBlur(text, 'adminCost')}
        />
      </div>

      <div className="common-kupos-input-box" style={{ marginTop: 10 }}>
        <KuposInput
          type="text"
          className="kupos-border"
          placeholder={'Comentarios'}
          label="Comentarios"
          value={values.remarks}
          error={values.remarksError ? true : false}
          errorMessage={values.remarksError ? values.remarksError : ''}
          onChange={text => onChange(text, 'remark')}
          onBlur={text => onBlur(text, 'remark')}
        />
      </div>

      <div className="button-container" style={{ marginTop: 20 }}>
        <KuposButton onClick={createOrUpdate}>
          <span className="font11">{id ? 'Actualizar' : 'Crear'}</span>
        </KuposButton>
      </div>

      <KuposErrorSuccessModal
        success={modal.success}
        showModal={modal.show}
        onButtonClick={() => {
          setModal(modalInitialState);
          if (modal.redirect) {
            history.goBack();
          }
        }}
        bodyText={modal.bodyText}
      />

      <Snackbar
        open={snackbar.showSnackbar}
        autoHideDuration={snackbar.persist ? 10000000000 : 5000}
        anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
        onClose={() => setSnackbar({ showSnackbar: false })}
      >
        <Alert onClose={() => setSnackbar({ showSnackbar: false })} severity="error">
          <span>{snackbar.alertMessage} </span>
          {count
            &&
            `we are redirecting you back in ${count} seconds`
          }
        </Alert>
      </Snackbar>
      {loader ?
        <div className="loader-container">
          <Loader visible={loader} type="Bars" color="#637290" height={80} width={80} />
        </div> : null
      }
    </div>
  );
}

export default AddCancellationPolicy;
