import React, { useEffect, useRef, useState } from 'react';
import ApiUrls from '../../services/apiUrls';
import { ActionButtons, AddButton, KuposErrorSuccessModal, KuposListLoader, KuposModalWithButtons } from '../common';
import { withScriptjs, withGoogleMap, GoogleMap, Marker, Polygon } from 'react-google-maps';
import AppData from '../../services/appData';
import DateService from '../../services/dateService';
import { useDispatch } from 'react-redux';
import { apiCall } from './redux/apiCall';
import { Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';

const modalInitialState = {
  show: false,
  success: false,
  bodyText: null,
};
const AllServiceAreas = () => {
  const dispatch = useDispatch();
  const getListResponse = useRef(() => { });
  const polygonRef = useRef();
  const [fetch, setFetch] = useState(false);
  const [list, setList] = useState([]);
  const [modal, setModal] = useState(modalInitialState);
  const [selectedViewItem, setSelectedViewItem] = useState('');
  const [loader, setLoader] = useState(true);
  const [snackbar, setSnackbar] = useState({});
  const [selectedItemDetails, setSelectedItemDetails] = useState();


  useEffect(() => {
    dispatch(apiCall({ url: ApiUrls.addServicePricing(), callback: res => getListResponse.current(res) }))
  }, [dispatch, fetch]);

  getListResponse.current = (res) => {
    setLoader(false);
    if (res && res.data && res.data.success) {
      setList(res.data.data);
    } else if (res && res.data && !res.data.success) {
      setSnackbar({
        showSnackbar: true,
        persist: true,
        alertMessage: res.data.message ? res.data.message : 'Failure'
      });
    } else {
      setSnackbar({
        showSnackbar: true,
        persist: true,
        alertMessage: 'Check Your Internet Connection'
      });
    }
  };

  const getDetails = id => {
    dispatch(apiCall({ url: ApiUrls.addServicePricing(id), callback: res => getDetailsResponse(res) }));
  };

  const getDetailsResponse = (res) => {
    if (res && res.data && res.data.success) {
      setSelectedItemDetails(res.data.data);
    } else if (res && res.data && !res.data.success) {
      // setSnackbar({
      //   showSnackbar: true,
      //   persist: true,
      //   alertMessage: res.data.message ? res.data.message : 'Failure'
      // });
    } else {
      setSnackbar({
        showSnackbar: true,
        persist: true,
        alertMessage: 'Check Your Internet Connection'
      });
    }
  }

  const renderItem = (val, i) => {
    return (
      <div key={i} className="flex item-row">
        <div className="sm:w-full md:w-1/6 text-center">{val.id}</div>
        <div className="sm:w-full md:w-1/6 text-center">{val.client_code}</div>
        <div className="sm:w-full md:w-full text-center">{val.service_price_name}</div>
        <ActionButtons
          val={val}
          view={() => {
            getDetails(val.id);
            setSelectedViewItem(val)
          }}
          url={('/services/' + val.id + '/edit')}
          handleDelete={() =>
            setModal({
              showDeleteModal: true,
              selectedItemName: val.service_price_name,
              selectedItemId: val.id,
              itemToDelete: val,
            })
          }
        />

      </div>
    );
  };

  const renderDeleteModal = () => {
    return (
      <KuposModalWithButtons
        size="md"
        buttonText1="No"
        buttonText2="Si"
        onButtonClick1={() => setModal(modalInitialState)}
        onButtonClick2={deleteItem}
        showButton1={true}
        showButton2={true}
        showModal={modal.showDeleteModal}
        onHide={() => setModal(modalInitialState)}
      >
        <div className="create-staff">
          <h4 className="text-center">¿Quieres eliminar "{modal.selectedItemName}"?</h4>
        </div>
      </KuposModalWithButtons>
    );
  };

  const deleteItem = () => {
    let data = {
      id: Number(modal.itemToDelete.id),
      client_code: modal.itemToDelete.client_code,
      service_id: Number(modal.itemToDelete.service_id),
      service_name: modal.itemToDelete.service_name,
      pickup_location_id: Number(modal.itemToDelete.pickup_location_id),
      pickup_location_name: modal.itemToDelete.pickup_location_name,
      pickup_location_area_id: Number(modal.itemToDelete.pickup_location_area_id),
      pickup_location_area_name: modal.itemToDelete.pickup_location_area_name,
      drop_location_id: Number(modal.itemToDelete.drop_location_id),
      drop_location_name: modal.itemToDelete.drop_location_name,
      drop_location_area_id: Number(modal.itemToDelete.drop_location_area_id),
      drop_location_area_name: modal.itemToDelete.drop_location_area_name,
      price_type: modal.itemToDelete.price_type,
      price: Number(modal.itemToDelete.price),
      service_price_name: modal.itemToDelete.service_price_name,
      status: 'ACTIVE',
      created_on: modal.itemToDelete.created_on,
      created_by: 0,
      last_updated_on: modal.itemToDelete.last_updated_on,
      last_updated_by: 0,
      created_user: modal.itemToDelete.created_user,
      created_utc: Number(modal.itemToDelete.created_utc),
      created_date_time_stg: modal.itemToDelete.created_date_time_stg,
      last_updated_user: modal.itemToDelete.last_updated_user,
      last_updated_utc: Number(modal.itemToDelete.last_updated_utc),
      last_updated_date_time_stg: modal.itemToDelete.last_updated_date_time_stg,
      deleted_utc: DateService.getCurrentTimeUTC(),
      deleted_date_time_stg: DateService.getCurrentStgDate(),
    };

    dispatch(
      apiCall({
        url: ApiUrls.addServicePrice(),
        method: 'DELETE',
        data,
        callback: res => onDeleteResponse(res),
      }),
    );

  };


  const onDeleteResponse = res => {
    if (res.data && res.data.success) {
      setModal({ show: true, success: true, bodyText: 'Eliminar éxito' });
      setFetch(!fetch);
    } else {
      setModal({ show: true, success: false, bodyText: res.data.message });
    }
  };

  const renderViewModal = () => {
    return (
      <KuposModalWithButtons
        size="lg"
        // buttonText1="O"
        // onButtonClick1={this.addSkillPoint}
        showButton1={true}
        showModal={selectedViewItem ? true : false}
        onHide={() => setSelectedViewItem(null)}
      >
        {selectedViewItem && selectedViewItem.id ? (
          <div className="view-item-container">
            <h3 className="text-center font-bold">Servicio</h3>
            <div className="flex view-item-row">
              <div className="sm:w-full md:w-2/6 label font-bold">Nombre:</div>
              <div className="sm:w-full md:w-full">
                {selectedViewItem.service_price_name}
              </div>
            </div>
            <div className="flex view-item-row">
              <div className="sm:w-full md:w-2/6 label font-bold">Localización de embarque :</div>
              <div className="sm:w-full md:w-full">
                {selectedViewItem.pickup_location_name}
              </div>
            </div>
            <div className="flex view-item-row">
              <div className="sm:w-full md:w-2/6 label font-bold">
                Área de localización de embarque :
              </div>
              <div className="sm:w-full md:w-full">
                {selectedViewItem.pickup_location_area_name}
              </div>
            </div>
            <div className="flex view-item-row">
              <div className="sm:w-full md:w-2/6 label font-bold">Localización de la bajada:</div>
              <div className="sm:w-full md:w-full">
                {selectedViewItem.drop_location_name}
              </div>
            </div>
            <div className="flex view-item-row">
              <div className="sm:w-full md:w-2/6 label font-bold">
                Área de la localización de la bajada :
              </div>
              <div className="sm:w-full md:w-full">
                {selectedViewItem.drop_location_area_name}
              </div>
            </div>
            <div className="flex view-item-row">
              <div className="sm:w-full md:w-2/6 label font-bold">Tipo de precio:</div>
              <div className="sm:w-full md:w-full">{selectedViewItem.price_type}</div>
            </div>
            <div className="flex view-item-row">
              <div className="sm:w-full md:w-2/6 label font-bold">Precio:</div>
              <div className="sm:w-full md:w-full">{selectedViewItem.price}</div>
            </div>
            <div className="flex view-item-row">
              <div className="sm:w-full md:w-2/6 label font-bold">Estatus:</div>
              <div className="sm:w-full md:w-full">{selectedViewItem.status}</div>
            </div>
            {selectedItemDetails && selectedItemDetails.location_areas ? (
              <div className="service-pricing-map-section">
                <MapWithAMarker
                  key={selectedViewItem.id}
                  googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${AppData.MAP_API_KEY}&v=3.exp&libraries=geometry,drawing,places`}
                  loadingElement={<div style={{ height: `100%` }} />}
                  containerElement={<div style={{ height: `450px` }} />}
                  mapElement={<div style={{ height: `100%`, borderRadius: 8 }} />}
                  selectedLatLong={{ lat: -33.4436021, lng: -70.6606066 }}
                >
                  <Polygon
                    ref={polygonRef}
                    path={JSON.parse(
                      selectedItemDetails.location_areas.polygon,
                    ).features[0].geometry.coordinates[0].map(val => ({
                      lat: val[0],
                      lng: val[1],
                    }))}
                  />
                </MapWithAMarker>
              </div>
            ) : null}
          </div>
        ) : null}
      </KuposModalWithButtons>
    );
  };

  return (
    <div className="services">
      <hr className="pb-3" />
      <AddButton
        title='Servicios'
        url='/services/create'
        addTitle='Agregar nuevo precio de servicio'
      />

      <div className="flex table-heading">
        <div className="sm:w-full md:w-1/6 text-center">ID</div>
        <div className="sm:w-full md:w-1/6 text-center">Operador</div>
        <div className="sm:w-full md:w-full text-center">Nombre</div>
        <div className="sm:w-full md:w-3/6 text-center">Acción</div>
      </div>
      {loader ? <KuposListLoader /> : (list || []).length > 0
        ? list
          .sort((x, y) => {
            return y.id - x.id;
          })
          .map((val, i) => renderItem(val, i))
        : null}
      {renderViewModal()}
      {renderDeleteModal()}

      <KuposErrorSuccessModal
        showModal={modal.show}
        success={modal.success}
        smallIcon={true}
        bodyText={modal.bodyText}
        onButtonClick={() => {
          setModal(modalInitialState);
        }}
      />
      <Snackbar
        open={snackbar.showSnackbar}
        autoHideDuration={snackbar.persist ? 10000000000 : 5000}
        anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
        onClose={() => setSnackbar({ showSnackbar: false })}
      >
        <Alert onClose={() => setSnackbar({ showSnackbar: false })} severity="error">
          <span>{snackbar.alertMessage}</span>
        </Alert>
      </Snackbar>
    </div>
  );
}



const MapWithAMarker = withScriptjs(
  withGoogleMap(props => (
    <GoogleMap
      defaultZoom={12}
      defaultCenter={props.selectedLatLong}
      defaultOptions={{
        scrollwheel: false,
      }}
      // defaultCenter={{ lat: -33.4436021, lng: -70.6606066 }}
      onClick={props.onMapClick}
    >
      {props.children}
    </GoogleMap>
  )),
);


export default AllServiceAreas
