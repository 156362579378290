import React, { Component } from 'react';
import Modal from 'react-bootstrap/Modal';
import PropTypes from 'prop-types';

export default class KuposModal extends Component {
  static propTypes = {
    showModal:PropTypes.bool,
    onHide:PropTypes.func,
    size:PropTypes.string,
    ariaLabel:PropTypes.string
  };

  render() {
    return (
      <div className="common-kupos-modal">
        <Modal
            backdrop={this.props.backdrop}
            show={this.props.showModal}
            onHide={this.props.onHide}
              size={this.props.size || 'md'}
              aria-labelledby={"contained-modal-title-vcenter "+(this.props.ariaLabel)}
              centered
            >
              <Modal.Body>
                {this.props.children}
              </Modal.Body>
            </Modal>
      </div>
    );
  }
}
