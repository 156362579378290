import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { SvgHome } from '.';

export default class KuposCapsuleMenu extends Component {
  static propTypes = {
    menuButtons: PropTypes.array,
    tab: PropTypes.bool,
    activeTab: PropTypes.number,
    onTabChange: PropTypes.func,
    type: PropTypes.string,
    t:PropTypes.func,
    showSortIcon: PropTypes.bool,
    sortType: PropTypes.string
  };

  render() {
    const { t }  = this.props;
    return (
      <div className={'common-kupos-capsule-menu capsule-menu capsule-menu' + this.props.type + ' font10'}>
        {this.props.menuButtons.map((value, key) => {
          return (
            <div
              className={
                'capsule-menu-item pointer ' +
                (this.props.activeTab == key ? 'active bold-text' : ' ') + 
                (!value.iconActive ? ' no-active-icon' : '')+ 
                (key == 2 ? ' mid' : '')
              }
              key={key}
              onClick={this.props.onTabChange ? () => this.props.onTabChange(key) : null}
            >
              <div className="capsule-menu-icon">
                {!value.svgIcon
                  ? <img src={(this.props.activeTab == key && value.iconActive) ? value.iconActive : value.icon} />
                  : (value.svgPage == 'home' ? <SvgHome name={value.svgIcon} /> : null)
                }
              </div>
              <span className="capsule-menu-text">
                {t("RESULTS_PAGE."+value.label).split('\n').map((item, i) => {
                  return <p key={i}>{item}</p>;
                })}
              </span>
              {/* {this.props.showSortIcon && this.props.activeTab == key ?  <img className={"sort-icon " + ((this.props.sortType == 'DESC' && this.props.activeTab == 2) || !(this.props.sortType == 'DESC' || this.props.activeTab == 2)  ? 'desc' : '')} src="/images/icons/sort-arrow.png" /> : null} */}
              {this.props.showSortIcon && this.props.activeTab == key ?  <img className={"sort-icon " + (this.props.sortType == 'DESC' ? 'desc' : '')} src="/images/icons/sort-arrow.png" /> : null}
            </div>
          );
        })}
      </div>
    );
  }
}
