import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { ActionButtons, AddButton, KuposErrorSuccessModal, KuposListLoader, KuposModalWithButtons } from '../common';
import ApiUrls from '../../services/apiUrls';
import { apiCall } from './redux/apiCall';
import { Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';

const modalInitialState = {
  show: false,
  success: false,
  bodyText: null,
};

const AllCancellationPolicies = () => {
  const dispatch = useDispatch();
  const onResponse = useRef(() => { });
  const [fetch, setFetch] = useState(false);
  const [list, setList] = useState([]);
  const [modal, setModal] = useState(modalInitialState);
  const [selectedViewItem, setSelectedViewItem] = useState('');
  const [loader, setLoader] = useState(true);
  const [snackbar, setSnackbar] = useState({});

  useEffect(() => {
    dispatch(apiCall({ url: ApiUrls.addCancellationPolicy(), callback: res => onResponse.current(res) }));
  }, [dispatch, fetch]);

  onResponse.current = res => {
    setLoader(false);
    if (res && res.data && res.data.success) {
      setList(res.data.data);
    } else if (res && res.data && !res.data.success) {
      setSnackbar({
        showSnackbar: true,
        persist: true,
        alertMessage: res.data.message ? res.data.message : 'Failure'
      });
    } else {
      setSnackbar({
        showSnackbar: true,
        persist: true,
        alertMessage: 'Check Your Internet Connection'
      });
    }
  };

  const renderItem = (val, i) => {
    return (
      <div key={i} className="display-grid item-row">
      {/* <div key={i} className="flex item-row"> */}
        <div className="sm:w-full md:w-1/6 ">{val.id}</div>
        <div className="sm:w-full md:w-3/6 ">{val.client_code}</div>
        <div className="sm:w-full md:w-3/6 ">{val.service_type}</div>
        <div className="sm:w-full md:w-3/6 ">{val.policy_name}</div>
        {/* <div className="sm:w-full md:w-3/6 ">{val.hours_before}</div> */}
        <div className="sm:w-full md:w-3/6  ">{val.percentage}</div>
        <div className="sm:w-full md:w-3/6 text-center">{val.status}</div>
        {/* <div className="sm:w-full md:w-full ">{val.remark}</div> */}
        <ActionButtons
          val={val}
          view={setSelectedViewItem}
          url={('/cancellation-policies/' + val.id + '/edit')}
          handleDelete={() =>
            setModal({
              showDeleteModal: true,
              selectedItemName: val.name,
              selectedItemId: val.id,
              itemToDelete: val,
            })
          }
        />

      </div>
    );
  };

  const renderViewModal = () => {
    return (
      <KuposModalWithButtons
        size="lg"
        // buttonText1="O"
        // onButtonClick1={this.addSkillPoint}
        showButton1={true}
        showModal={selectedViewItem ? true : false}
        onHide={() => setSelectedViewItem(null)}
      >
        {selectedViewItem && selectedViewItem.id ? (
          <div className="view-item-container">
            <h3 className="text-center font-bold">Politicas de cancelación</h3>
            <div className="flex view-item-row">
              <div className="sm:w-full md:w-2/6 label font-bold">Operador:</div>
              <div className="sm:w-full md:w-full">{selectedViewItem.client_code}</div>
            </div>
            <div className="flex view-item-row">
              <div className="sm:w-full md:w-2/6 label font-bold">Referencia de la política:</div>
              <div className="sm:w-full md:w-full">{selectedViewItem.policy_name}</div>
            </div>
            <div className="flex view-item-row">
              <div className="sm:w-full md:w-2/6 label font-bold">Seleccione un servicio:</div>
              <div className="sm:w-full md:w-full">{selectedViewItem.service_type}</div>
            </div>
            <div className="flex view-item-row">
              <div className="sm:w-full md:w-2/6 label font-bold">Porcentaje:</div>
              <div className="sm:w-full md:w-full">{selectedViewItem.percentage}</div>
            </div>
            <div className="flex view-item-row">
              <div className="sm:w-full md:w-2/6 label font-bold">Horas límite de cancelación:</div>
              <div className="sm:w-full md:w-full">{selectedViewItem.hours_before}</div>
            </div>
            <div className="flex view-item-row">
              <div className="sm:w-full md:w-2/6 label font-bold">Minutos del plazo de cancelación:</div>
              <div className="sm:w-full md:w-full">
                {selectedViewItem.minutes_before}
              </div>
            </div>
            <div className="flex view-item-row">
              <div className="sm:w-full md:w-2/6 label font-bold">Costo administrativo:</div>
              <div className="sm:w-full md:w-full">{selectedViewItem.admin_cost}</div>
            </div>
            <div className="flex view-item-row">
              <div className="sm:w-full md:w-2/6 label font-bold">Comentario:</div>
              <div className="sm:w-full md:w-full">{selectedViewItem.remark}</div>
            </div>
            <div className="flex view-item-row">
              <div className="sm:w-full md:w-2/6 label font-bold">Estado:</div>
              <div className="sm:w-full md:w-full">{selectedViewItem.status}</div>
            </div>
          </div>
        ) : null}
      </KuposModalWithButtons>
    );
  };

  const renderDeleteModal = () => {
    return (
      <KuposModalWithButtons
        size="md"
        buttonText1="No"
        buttonText2="Sí"
        onButtonClick1={() => setModal(modalInitialState)}
        onButtonClick2={deleteItem}
        showButton1={true}
        showButton2={true}
        showModal={modal.showDeleteModal}
        onHide={() => setModal(modalInitialState)}
      >
        <div className="create-staff">
          <h4 className="text-center">
            ¿Esta seguro de que desea eliminar {modal.selectedItemName}"?
          </h4>
        </div>
      </KuposModalWithButtons>
    );
  };

  const deleteItem = () => {
    let data = {
      id: modal.itemToDelete.id,
      admin_cost: parseFloat(modal.itemToDelete.admin_cost),
      calculation_type: modal.itemToDelete.calculation_type,
      client_code: modal.itemToDelete.client_code,
      created_by: modal.itemToDelete.created_by,
      created_on: modal.itemToDelete.created_on,
      days_before: modal.itemToDelete.days_before,
      fixed_rate: modal.itemToDelete.fixed_rate,
      hours_before: Number(modal.itemToDelete.hours_before),
      interval_in_ticks: modal.itemToDelete.interval_in_ticks,
      last_updated_by: modal.itemToDelete.last_updated_by,
      last_updated_on: modal.itemToDelete.last_updated_on,
      max_amount: modal.itemToDelete.max_amount,
      min_amount: modal.itemToDelete.min_amount,
      minutes_before: Number(modal.itemToDelete.minutes_before),
      percentage: parseFloat(modal.itemToDelete.percentage),
      policy_name: modal.itemToDelete.policy_name,
      remark: modal.itemToDelete.remark,
      service_type: modal.itemToDelete.service_type,
      status: modal.itemToDelete.status,
    };

    dispatch(
      apiCall({
        url: ApiUrls.addCancellationPolicy(),
        method: 'DELETE',
        data,
        callback: res => onDeleteResponse(res),
      }),
    );

  };

  const onDeleteResponse = res => {
    if (res.data && res.data.success) {
      setModal({ show: true, success: true, bodyText: 'Eliminación exitosa' });
      setFetch(!fetch);
    } else {
      setModal({ show: true, success: false, bodyText: res.data.message });
    }
  };

  return (
    <div className="home-all-locations">
      <AddButton
        title='Politicas de cancelación'
        url='/cancellation-policies/create'
        addTitle='Agregar política de cancelación'
      />

      <div className="flex table-heading">
        <div className="sm:w-full md:w-1/6 " style={{paddingLeft:20}}>ID</div>
        <div className="sm:w-full md:w-3/6 " style={{width:"30vw"}}>Operador</div>
        <div className="sm:w-full md:w-3/6 ">Nombre del servicio</div>
        <div className="sm:w-full md:w-3/6 " style={{marginLeft:10}} >Referencia de la política</div>
        {/* <div className="sm:w-full md:w-3/6">Hours Before</div> */}
        <div className="sm:w-full md:w-3/6 text-right">Porcentaje</div>
        <div className="sm:w-full md:w-3/6 text-center" style={{marginLeft:-40}}>Estado</div>
        <div className="sm:w-full md:w-3/6">Acciones</div>
      </div>

      {loader ? <KuposListLoader /> : (list || []).length > 0
        ? list
          .sort((x, y) => {
            return y.id - x.id;
          })
          .map((val, i) => renderItem(val, i))
        : null}

      {renderViewModal()}

      {renderDeleteModal()}

      <KuposErrorSuccessModal
        showModal={modal.show}
        success={modal.success}
        smallIcon={true}
        bodyText={modal.bodyText}
        onButtonClick={() => {
          setModal(modalInitialState);
        }}
      />
      <Snackbar
        open={snackbar.showSnackbar}
        autoHideDuration={snackbar.persist ? 10000000000 : 5000}
        anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
        onClose={() => setSnackbar({ showSnackbar: false })}
      >
        <Alert onClose={() => setSnackbar({ showSnackbar: false })} severity="error">
          <span>{snackbar.alertMessage}</span>
        </Alert>
      </Snackbar>
    </div>
  );
}


export default AllCancellationPolicies;
