import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import AppData from '../../../services/appData';
import CommonService from '../../../services/commonService';
import DateService from '../../../services/dateService';
import { KuposButton, KuposErrorSuccessModal, KuposInput, KuposRadio } from '../../common';
import { KuposDateTimePicker } from '../../common/KuposDateTimePicker';
import { BookingPaymentModel } from '../BookingPaymentModel';
import PrintTicket from '../printTicket';
import { apiCall } from '../redux/apiCall';

const CustomerDetails = ({ values, setValues, setSnackbar, selectedService, metaData }) => {
  const dispatch = useDispatch();
  const [modal, setModal] = useState({});


  const onBookingDetailsChange = (value, type) => {
    console.log(type, value);
    if (type === 'identity_value') {
      const type = 'RUT';
      setValues({
        ...values,
        identity_value: type === 'RUT' ? CommonService.formatRut(value) : value,
        identity_valueError: null,
      });
    } else if (type === 'phone') {
      setValues({ ...values, phone: CommonService.onlyNumbers(value), phoneError: null });
    } else {
      setValues({ ...values, [type]: value, [type + 'Error']: null });
    }
  };

  const onBookingDetailsBlur = (value, type) => {
    switch (type) {
      case 'identity_value':
        if (!CommonService.isRutValid(value)) {
          setValues({ ...values, identity_valueError: 'Ingrese un RUT' });
        }
        // make api call to get passenger information and autofill the fields
        dispatch(
          apiCall({
            url:
              AppData.BASE_URL_V1 + `agent/customerInfo/${values.docType}/${values.identity_value}`,
            callback: res => onPassengerInformationResponse(res),
          }),
        );

        break;
      case 'phone':
        if (value.length > 12) {
          setValues({ ...values, phoneError: 'Ingrese un número de teléfono válido' });
        }
        break;

      case 'email':
        if (!CommonService.isEMailValid(value)) {
          setValues({ ...values, emailError: 'Ingrese un correo electrónico válido' });
        }
        break;
    }
  };

  const onPassengerInformationResponse = res => {
    console.log({ res });
    if (res && res.data && res.data.success) {
      setValues({
        ...values,
        identity_value: res.data.data.identity_value,
        name: res.data.data.name,
        phone: res.data.data.phone,
        email: res.data.data.email,
        docType: res.data.data.identity_type,
        user_boading_address: res.data.data.address,
        phoneError: '',
        emailError: '',
      });
    }

    if (res && res.data && !res.data.success) {
      setValues({ ...values, name: '', phone: '', email: '', user_boading_address: '' });
    }
  };

  const getTotalPrice = (serviceType, priceType, price, serviceSeats) => {
    return priceType === 'PER_VEHICLE' ? price : price * values.ChoosenKupos;
  };

  const getBookingPrice = (serviceType, priceType, price, serviceSeats) => {
    // console.log('Price Type is', priceType);
    // return priceType === 'PER_VEHICLE' ? price * serviceSeats : price;
    return priceType === 'PER_VEHICLE' ? price : price;
  };

  const calculateCurrencyAmount = () => {
    return (
      Number(
        selectedService.price_type === 'PER_VEHICLE'
          ? selectedService.price
          : // ? selectedService.price * selectedService.seat_capacity
          selectedService.price * values.ChoosenKupos,
      ) *
      Number(
        (values.selectedCurrency && values.selectedCurrency.rate && values.selectedCurrency.rate) ||
        1,
      )
    );
  };

  const validatePassengerDetails = () => {
    let errors = 0;

    // if (!values.identity_value && !values.phone && !values.email) {
    //   setValues({
    //     ...values,
    //     identity_valueError: 'Ingrese un número de documento válido',
    //     phoneError: 'Ingrese un número de teléfono válido',
    //     emailError: 'Ingrese un correo electrónico válido',
    //   });
    //   errors++;
    //   return;
    // }

    // if (values.docType === 'RUT') {
    //   if (!CommonService.isRutValid(values.identity_value)) {
    //     setValues({ ...values, identity_valueError: 'Ingrese un número de RUT válido' });
    //     errors++;
    //     return;
    //   }
    //   if (!values.identity_value) {
    //     setValues({ ...values, identity_valueError: 'Ingrese un número de documento válido' });
    //     errors++;
    //     return;
    //   }
    // }

    // if (values.additionalFareSelected && !values.additionalFareComment) {
    //   setValues({ ...values, additionalFareCommentError: 'Required' });
    //   errors++;
    //   console.log('5');
    // }

    // // if (!values.name) {
    // //   setValues({ ...values, nameError: 'Ingrese un nombre' });
    // //   errors++;
    // //   console.log('3');
    // // }
    // if (!values.phone || (values.phone && values.phone.length > 12)) {
    //   setValues({ ...values, phoneError: 'Ingrese un número de teléfono válido' });
    //   errors++;
    //   return;

    //   console.log('4');
    // }

    // if (!values.email || !(values.email && CommonService.isEMailValid(values.email))) {
    //   setValues({ ...values, emailError: 'Ingrese un correo electrónico válido' });
    //   errors++;
    //   return;

    //   console.log('5');
    // }

    if ( values.discountAmount >= selectedService.price) {
      setValues({ ...values, kuposGoNameError: 'discount can not be more than ticket amount' });
      errors++;
      return false
    }

    // if (errors > 0) {
    //   return false;
    // } else {
    // }
    return true;
  };

  const validatePassengerDetailsForReserve = () => {
    setValues({ ...values, isReservedTicket: true });
    let errors = 0;

    if (values.additionalFareSelected && !values.additionalFareComment) {
      setValues({ ...values, additionalFareCommentError: 'Required' });
      errors++;
      console.log('5');
    }

    if (!values.reserveDate) {
      setValues({
        ...values,
        reserveDateError: { message: 'Se requiere fecha de reserva' },
      });
      errors++;
      console.log('7');
    }

    if (!values.reserveTime) {
      setValues({
        ...values,
        reserveTimeError: { message: 'Se requiere tiempo de reserva' },
      });
      errors++;
      console.log('8');
    }
    // if (!values.user_boading_address) {
    //   setValues({...values,
    //     user_boading_addressError: { message: 'Ingrese un correo dirección válido' },
    //   });
    //   errors++;
    //   console.log('6');
    // }

    if (errors > 0) {
      return false;
    } else {
      return true;
    }
  };
  const setReserveFlags = () => {
    if (!validatePassengerDetailsForReserve()) {
      alert('Please select the reserve date and time ');
      return;
    }

    if (!values.reserveDate) {
      setValues({ ...values, selectedReserveDateTimeError: 'Reserve date  is required' });
      return;
    }

    if (!values.reserveTime) {
      setValues({ ...values, selectedReserveDateTimeError: 'Reserve time is required' });
      return;
    }

    // console.log('+++ set reserve flags', values);
    decideBookingFlow();
  };

  const decideBookingFlow = () => {
    let method;
    values.stepsMenu.forEach(element => {
      if (element.active) {
        method = element;
      }
    });
    console.log('Method selected -------', method);
    if (method.value === 'CASH') {
      bookTicket();
    } else {
      setValues({ ...values, showPaymentModal: true });
    }
  };

  const bookTicket = () => {
    // setValues({ ...values, showPaymentModal: false })
    
    if (!validatePassengerDetails()) {
      alert('Please correct the entered data');
      return;
    }

    let counterDetails = CommonService.getLocal('counter');

    if (counterDetails === undefined) {
      setSnackbar({
        showSnackbar: true,
        persist: true,
        alertMessage:
          'No hay ninguna caja asignada, por favor primero elija una caja para continuar',
      });
      return;
    }

    if (
      !selectedService ||
      (selectedService && selectedService[0] && !selectedService[0].selected)
    ) {
      setSnackbar({
        showSnackbar: true,
        alertMessage:
          'Estimado usuario, usted no ha seleccionado ningún servicio, por favor elija un servicio para continuar',
      });
      return;
    }

    let currentTime = DateService.getCurrentTimeUTC();

    let total_amount = getTotalPrice(
      selectedService.service_type,
      selectedService.price_type,
      selectedService.price,
      selectedService.seat_capacity,
      values.ChoosenKupos,
    );

    let price = getBookingPrice(
      selectedService.service_type,
      selectedService.price_type,
      selectedService.price,
      selectedService.seat_capacity,
    );

    let currencyAmount = calculateCurrencyAmount();

    console.log({ selectedService });

    let data = {
      correlation_id: `${selectedService.client_code.substr(0, 3)}_${values.user.substr(
        0,
        3
      )}_${currentTime}`,
      user_id: CommonService.currentUser.user_id,
      user_name: CommonService.currentUser.user_name,
      branch_id: counterDetails.branch_id,
      counter_id: counterDetails.counter_id,
      counter_txn_id: counterDetails.counter_txn_id, // Returned from CounterOpen
      shift_id: counterDetails.shift_id,
      customer_name: values.name,
      customer_email: values.email,
      customer_phone: values.phone,
      customer_address: values.user_boading_address,
      pickup_loc_id: selectedService.pickup_location_id,
      pickup_latitude: values.origin.location && values.origin.location.lat ? values.origin.location.lat : values.origin.value,
      pickup_longitude: values.origin.location && values.origin.location.lon ? values.origin.location.lon : values.origin.value,
      pickup_address: values.origin.address,
      drop_loc_id: selectedService.drop_location_id,
      drop_latitude: values.destination.location && values.destination.location.lat ? values.destination.location.lat : values.destination.value,
      drop_longitude: values.destination.location && values.destination.location.lon ? values.destination.location.lon : values.destination.value,
      drop_address: values.destination.address,
      is_round_trip: 0, // 1 : If Yes, 0 : If No.
      booking_date_time_stg: `${DateService.getTodayString(
        'yyyy-mm-dd',
      )} ${DateService.getCurrentHour()}:${DateService.getCurrentMinute()} `, // YYYY-MM-DD HH:MM
      booking_date_time: CommonService.getTimeInSeconds(
        DateService.getTodayString('yyyy-mm-dd') +
        'T' +
        DateService.getCurrentHour() +
        ':' +
        DateService.getCurrentMinute(),
      ), // UTC time in Unix Milli Seconds.
      onward_trip_date_stg: DateService.changeDateFormat(
        values.selectedDate,
        'dd-mm-yyyy',
        'yyyy-mm-dd',
      ), // Date of the trip, YYYY-MM-DD
      onward_trip_time_stg: values.selectedTime, // Time of the trip, HH:MM
      onward_trip_ref_date_time: CommonService.getTimeInSeconds(
        DateService.changeDateFormat(values.selectedDate, 'dd-mm-yyyy', 'yyyy-mm-dd') +
        'T' +
        values.selectedTime,
      ), // UTC time in Unix Milli Seconds.
      no_of_seats: values.selectedKupos,
      price_type: selectedService.price_type,
      price: price,
      identity_type: values.docType,
      identity_value: values.identity_value,
      onward_service_pricing_id: selectedService.id,
      payment_type: values.selectedPaymentType.value, // CASH / CREDIT_CARD / DEBIT_CARD / BANK_TRANSFER / CREDIT_INVOICE
      currency: (values.selectedCurrency && values.selectedCurrency.to) || 'CLP',
      payment_ref_no: values.card_number || '',
      total_amount: total_amount,
      currency_amount: currencyAmount || 0,
      discount_code: values.discountCode ? values.discountCode : ' ',
      discount_amount: Number(values.discountAmount),
      additional_fare_type: values.additionalFareSelected
        ? values.additionalFareSelected.value
        : '',
      additional_fare: values.additionalFareAmount ? Number(values.additionalFareAmount) : 0,
      additional_fare_remark: values.additionalFareComment ? values.additionalFareComment : '',
      seat_meta_data: metaData,
      service_type: selectedService.service_type,
      confirm_deadline_stg:
        values.reserveDate && values.reserveTime
          ? DateService.changeDateFormat(values.reserveDate, 'dd-mm-yyyy', 'yyyy-mm-dd') +
          ' ' +
          values.reserveTime
          : '', // YYYY-MM-DD HH:MM
      confirm_deadline_utc:
        values.reserveDate && values.reserveTime
          ? CommonService.getTimeInSeconds(
            DateService.changeDateFormat(values.reserveDate, 'dd-mm-yyyy', 'yyyy-mm-dd') +
            'T' +
            values.reserveTime,
          )
          : 0, // UTC time in Unix Milli Seconds.
    };

    console.log('+++Data to fly-----', data);

    // return;

    dispatch(
      apiCall({
        url: AppData.BASE_URL_V2 + 'agent/booking',
        data: data,
        method: 'POST',
        callback: res => onBookingResponse(res, data),
      }),
    );
  };

  const onBookingResponse = (res, data) => {
    console.log('res from final booking--------', res);
    if (res.data && res.data.success) {
      let newState = { ...values };
      var printData = { ...values.apiResponseForPrinting };
      printData.data = data;
      printData.pnr_no = res.data.data.onward_pnr_id;

      printData.fare = res.data.data.fare;
      printData.discount = res.data.data.discount;
      printData.totalAmount = res.data.data.totalAmount;
      printData.totalAmount = res.data.data.bookedBy;
      printData.totalAmount = res.data.data.printedBy;
      printData.totalAmount = res.data.data.printedBy;

      printData.origin = newState.atOriginText;
      printData.destination = newState.atDestinationText;
      printData.date = newState.selectedDate;
      printData.time = newState.selectedTime;
      setValues({ ...values, apiResponseForPrinting: printData ,showPaymentModal: false });

      console.log('newState data is---', newState);
      console.log(printData);
      setModal({
        show: true,
        bodyText: res.message,
        success: true,
      });
      // this.restoreState();
      // this.reloadPage();
    } else {
      setModal({
        show: true,
        bodyText: res.message,
        success: false,
      });
    }
  };

  return (
    <div className="details-sidebar">
      <div className="input-containers-bulk">
        <div className="doc-selection font11">
          <KuposRadio
            onChange={() => setValues({ ...values, docType: 'RUT' })}
            checked={values.docType === 'RUT'}
            label="RUT"
          />
          <KuposRadio
            onChange={() => setValues({ ...values, docType: 'OTHER' })}
            checked={values.docType === 'OTHER'}
            label="Otro"
          />
        </div>
        <div className="name-input">
          <KuposInput
            className="kupos-border"
            type="text"
            //  placeholder="Nombre"
            value={values.identity_value}
            onChange={val => onBookingDetailsChange(val, 'identity_value')}
            onBlur={val => {
              onBookingDetailsBlur(val, 'identity_value');
            }}
            error={values.identity_valueError && values.docType === 'RUT' ? true : false}
            errorMessage={values.identity_valueError ? values.identity_valueError : null}
            // style={{ height: 40 }}
            inputOuterStyle={{ height: 40 }}
          />
        </div>

        <div className="name-input">
          <div>
            <span className="font11">Nombre completo</span>
            {/* <span className="font11">{'HOME.NAME'}</span> */}
          </div>
          <KuposInput
            className="kupos-border"
            type="text"
            placeholder="Nombre completo"
            value={values.name}
            onChange={val => onBookingDetailsChange(val, 'name')}
            onBlur={text => { }}
            // error={this.state.nameError ? true : false}
            // errorMessage={this.state.nameError ? this.state.nameError.message : ''}
            // style={{ height: 40 }}
            inputOuterStyle={{ height: 40 }}
          />
        </div>

        <div className="name-input">
          <div>
            <span className="font11">Teléfono</span>
          </div>
          <KuposInput
            className="kupos-border"
            type="text"
            placeholder="9XXXXXXXX"
            value={values.phone}
            onChange={val => onBookingDetailsChange(val, 'phone')}
            onBlur={val => onBookingDetailsBlur(val, 'phone')}
            error={values.phoneError ? true : false}
            errorMessage={values.phoneError ? values.phoneError : null}
            // style={{ height: 40 }}
            inputOuterStyle={{ height: 40 }}
          />
        </div>

        <div className="name-input">
          <div>
            <span className="font11">Correo electrónico</span>
          </div>
          <KuposInput
            className="kupos-border"
            type="text"
            placeholder="Ingresa su correo electrónico"
            value={values.email}
            onChange={val => onBookingDetailsChange(val, 'email')}
            onBlur={val => onBookingDetailsBlur(val, 'email')}
            error={values.emailError ? true : false}
            errorMessage={values.emailError ? values.emailError : null}
            // style={{ height: 40 }}
            inputOuterStyle={{ height: 40 }}
          />
        </div>

        <div className="name-input">
          <div>
            <span className="font11">Punto de embarque</span>
          </div>
          <KuposInput
            className="kupos-border"
            type="text"
            placeholder="Punto de embarque"
            value={values.user_boading_address}
            onChange={val => onBookingDetailsChange(val, 'user_boading_address')}
            onBlur={text => { }}
            // error={values.user_boading_addressError ? true : false}
            // errorMessage={
            //   values.user_boading_addressError
            //     ? values.user_boading_addressError.message
            //     : ''
            // }
            // style={{ height: 40 }}
            inputOuterStyle={{ height: 40 }}
          />
        </div>

        <div className="home-selection-input-box font10 datebox">
          {/* <span className="label font11">{'Reservation of service without payment'}</span> */}
          <span className="label font11">{'Reserva'}</span>
          <KuposDateTimePicker
            selectedDate={values.reserveDate}
            selectedTime={values.reserveTime}
            onDateChange={val => onBookingDetailsChange(val, 'reserveDate')}
            onTimeChange={val => onBookingDetailsChange(val, 'reserveTime')}
            minDate={DateService.getTodayString()}
            error={values.reserveDateError || values.reserveTimeError ? true : false}
            errorMessage={values.selectedReserveDateTimeError}
          // placeholder={DateService.getTodayString()}
          // showOnSelect={this.state.showOnSelect}
          />
        </div>

        <div className="button-container">
          {/* <KuposButton onClick={this.bookTicket}> */}
          {/* <KuposButton onClick={() => setValues({...values, showPaymentModal: true })}> */}
          <div style={{ marginRight: 10 }}>
            <KuposButton onClick={setReserveFlags}>
              <span>RESERVE VENTA</span>
            </KuposButton>
          </div>

          <div>
            <KuposButton onClick={() => decideBookingFlow()}>
              <span>CONFIRMAR VENTA</span>
            </KuposButton>
          </div>
        </div>
      </div>

      {values.showPaymentModal && (
        <BookingPaymentModel
          showModal={values.showPaymentModal}
          onHide={() => setValues({ ...values, showPaymentModal: false })}
          onInputChange={val => onBookingDetailsChange(val, 'card_number')}
          button1Press={() => bookTicket()}
          selectedPaymentMethod={values.stepsMenu}
        />
      )}
      <div style={{ visibility: 'hidden' }}>
        <PrintTicket printData={values.apiResponseForPrinting}></PrintTicket>
      </div>

      <KuposErrorSuccessModal
        success={modal.success}
        showModal={modal.show}
        onButtonClick={() => {
          modal.success &&
            setTimeout(() => {
              CommonService.printCancelTicket(document.getElementById('print-ticket').innerHTML);
              setModal({});
            }, 1000);

          modal.success &&
            setTimeout(() => {
              window.location.reload();
            }, 3000);

          if (!modal.success) {
            setModal({});
          }
        }}
        bodyText={modal.bodyText}
      />
    </div>
  );
};

export default CustomerDetails;
