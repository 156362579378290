/* This is the Root component mainly initializes Redux and React Router. */

import React from 'react';
import { Provider } from 'react-redux';
import { Switch, Route, Redirect } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';
import { hot, setConfig } from 'react-hot-loader';
import store from './common/store';
import routeConfig from './common/routeConfig';
import history from './common/history';
import CommonService from './services/commonService';

const isLoggedIn = () => {
  let authtoken = CommonService.authToken;
  if (!authtoken) {
    let currentUser = localStorage.getItem('currentUser');
    if (currentUser) {
      let currentUserJson = JSON.parse(currentUser);
      if (currentUserJson.access_token) {
        CommonService.authToken = currentUserJson.access_token;
        CommonService.currentUser = currentUserJson;
        authtoken = CommonService.authToken
      }
    }
  }
  return authtoken;
}


const AuthRoute = ({ component: Component, render, ...rest }) => {
  let authtoken = isLoggedIn()
  return (
    <Route
      {...rest}
      render={(props) =>
        authtoken ? (
          <Component {...props} />
        ) : (
            <Redirect to={`/login`} />
          )
      }
    />
  );
};

setConfig({
  logLevel: 'debug',
});

function renderRouteConfigV3(routes, contextPath) {
  // Resolve route config object in React Router v3.
  const children = []; // children component list

  const renderRoute = (item, routeContextPath) => {
    let authtoken = isLoggedIn()
    let newContextPath;
    if (/^\//.test(item.path)) {
      newContextPath = item.path;
    } else {
      newContextPath = `${routeContextPath}/${item.path}`;
    }
    newContextPath = newContextPath.replace(/\/+/g, '/');
    if (item.component && item.childRoutes) {
      const childRoutes = renderRouteConfigV3(item.childRoutes, newContextPath);
      children.push(
        item.auth ?
          <AuthRoute
            key={newContextPath}
            render={props => <item.component {...props}>{childRoutes}</item.component>}
            path={newContextPath}
          />
          : <Route
            key={newContextPath}
            render={props =>  (authtoken && window.location.href.indexOf('login') > -1) ? <Redirect to={`/`} /> : <item.component {...props}>{childRoutes}</item.component>}
            path={newContextPath}
          />,
      );
    } else if (item.component) {
      children.push(
        item.auth ?
          <AuthRoute
            key={newContextPath} component={item.component} path={newContextPath} exact
          />
          : <Route key={newContextPath} component={item.component} path={newContextPath} exact />,
      );
    } else if (item.childRoutes) {
      item.childRoutes.forEach(r => renderRoute(r, newContextPath));
    }
  };

  routes.forEach(item => renderRoute(item, contextPath));

  // Use Switch so that only the first matched route is rendered.
  return <Switch>{children}</Switch>;
}

function Root() {
  const children = renderRouteConfigV3(routeConfig, '/');
  return (
    <Provider store={store}>
      <ConnectedRouter history={history}>{children}</ConnectedRouter>
    </Provider>
  );
}

export default hot(module)(Root);
