import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { KuposButton, KuposErrorSuccessModal, KuposInput } from '../common';
import ApiUrls from '../../services/apiUrls';
import { apiCall } from './redux/apiCall';
import Loader from 'react-loader-spinner';
import { Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';

const modalInitialState = {
  show: false,
  success: false,
  bodyText: null,
};
const initialState = {
  name: '',
  std_code: '',
  currency: '',
  remark: '',
};
const AddCountry = ({ history, match }) => {
  const dispatch = useDispatch();
  const [values, setValues] = useState(initialState);
  const [modalValues, setModalValues] = useState(modalInitialState);
  const [loader, setLoader] = useState(true);
  const [snackbar, setSnackbar] = useState({});
  const [showForm, setShowForm] = useState(true)
  const [count, setCount] = useState('')

  const id = match.params ? Number(match.params.id) : null;

  useEffect(() => {
    if (id) {
      getList();
    } else {
      setLoader(false)
    }
    // eslint-disable-next-line
  }, [id])


  // redirecting back if data not found using timer
  useEffect(() => {
    let interval
    if (count) {
      interval = setInterval(() => {
        setCount((currentCount) => --currentCount)
      }, 1000);
    }
    // Redirect once the count is === 1
    count === 1 && history.push('/countries');
    // CleanUp
    return () => clearInterval(interval)
  }, [count, history])

  // console.log({ values }, id)

  const getList = () => {
    dispatch(apiCall({ url: ApiUrls.addCountry(), callback: res => onListResponse(res) }))
  };

  const onListResponse = (res) => {
    setLoader(false)

    if (res && res.data && res.data.success) {
      let found = res.data.data.find(
        item => item.id === id,
      );
      // set values to readonly inputs
      // console.log('----data--------', found)
      if (found) {
        getParticularCountry(found)
      } else {
        // Show SnackBar Msge
        setSnackbar({
          showSnackbar: true,
          persist: true,
          alertMessage: 'Data not found'
        });
        setShowForm(false)
        setCount(5)
      }

    } else {
      // if there is no REsponse 
      setSnackbar({
        showSnackbar: true,
        persist: true,
        alertMessage: 'Check Your Internet Connection'
      });
      setShowForm(false)
    }
  }

  const getParticularCountry = (element) => {
    setValues(element)
  };

  const onChange = (val, type) => {
    setValues({ ...values, [type]: val, [type + 'Error']: null })
  };

  const validate = () => {
    if (!values.name) {
      setValues({
        ...values,
        nameError: 'Ingrese un Nombre'
      });
      return false;
    }
    if (!values.std_code) {
      setValues({
        ...values,
        std_codeError: 'Ingrese un Prefijo telefónico',
      });
      return false;
    }
    if (!values.currency) {
      setValues({
        ...values,
        currencyError:
          'Ingrese un Moneda',
      });
      return false;
    }
    if (!values.remark) {
      setValues({
        ...values,
        remarkError:
          'Ingrese un Comentarios',
      });
      return false;
    }
    return true;
  };

  const createOrUpdate = () => {
    if (!validate()) {
      return;
    }

    let data, method = "POST";

    if (id) {
      method = 'PUT'
      data = {
        id: values.id,
        name: values.name,
        std_code: values.std_code,
        currency: values.currency,
        status: values.status,
        remark: values.remark,
        created_on: values.created_on,
        created_by: values.created_by,
        last_updated_on: values.last_updated_on,
        last_updated_by: values.last_updated_by,
      }
    } else {
      data = {
        name: values.name,
        std_code: values.std_code,
        currency: values.currency,
        remark: values.remark,
      }
    }

    // console.log('Data is', data);

    dispatch(
      apiCall({ method, url: ApiUrls.addCountry(), data, callback: res => onAddCountryResponse(res) })
    )

  };

  const onAddCountryResponse = res => {
    if (res && res.data && res.data.success && res.data.data) {
      setModalValues({
        show: true,
        success: true,
        bodyText: res.data.message,
      });
    }
    // console.log('res from country save', res.data);
  };

  const onBlur = () => { };

  const hideModal = () => {
    setModalValues({ ...modalValues, show: false, bodyText: null });
    if (modalValues.success) {
      history.goBack();
    }
  };

  if (loader) {
    return (
      <div className="loader-container">
        <Loader visible={loader} type="Bars" color="#637290" height={80} width={80} />
      </div>
    )
  }

  return showForm ?
    <div className="home-add-location">
      <div className="common-kupos-input-box" style={{ marginTop: 10 }}>
        <KuposInput
          type="text"
          className="kupos-border"
          placeholder={'Nombre'}
          disabled={id ? true : false}
          value={values.name}
          error={values.nameError ? true : false}
          errorMessage={values.nameError ? values.nameError : ''}
          onChange={text => onChange(text, 'name')}
          onBlur={text => onBlur(text, 'name')}
        />
      </div>

      <div className="common-kupos-input-box" style={{ marginTop: 10 }}>
        <KuposInput
          type="text"
          className="kupos-border"
          placeholder={'Prefijo telefónico'}
          value={values.std_code}
          error={values.std_codeError ? true : false}
          errorMessage={values.std_codeError ? values.std_codeError : ''}
          onChange={text => onChange(text, 'std_code')}
          onBlur={text => onBlur(text, 'std_code')}
        />
      </div>

      <div className="common-kupos-input-box" style={{ marginTop: 10 }}>
        <KuposInput
          type="text"
          className="kupos-border"
          placeholder={'Moneda'}
          value={values.currency}
          error={values.currencyError ? true : false}
          errorMessage={values.currencyError ? values.currencyError : ''}
          onChange={text => onChange(text, 'currency')}
          onBlur={text => onBlur(text, 'currency')}
        />
      </div>

      <div className="common-kupos-input-box" style={{ marginTop: 10 }}>
        <KuposInput
          type="text"
          className="kupos-border"
          placeholder={'Comentarios'}
          value={values.remark}
          error={values.remarkError ? true : false}
          errorMessage={values.remarkError ? values.remarkError : ''}
          onChange={text => onChange(text, 'remark')}
          onBlur={text => onBlur(text, 'remark')}
        />
      </div>

      <div className="button-container" style={{ marginTop: 20 }}>
        <KuposButton onClick={createOrUpdate}>
          <span className="font11">
            {id ? ' Actualizar país' : 'Añadir país'}
          </span>
        </KuposButton>
      </div>

      <KuposErrorSuccessModal
        showModal={modalValues.show}
        success={modalValues.success}
        smallIcon={true}
        bodyText={modalValues.bodyText}
        onButtonClick={() => hideModal()}
      />

    </div>
    :
    <Snackbar
      open={snackbar.showSnackbar}
      autoHideDuration={snackbar.persist ? 10000000000 : 5000}
      anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
      onClose={() => setSnackbar({ showSnackbar: false })}
    >
      <Alert onClose={() => setSnackbar({ showSnackbar: false })} severity="error">
        <span>{snackbar.alertMessage} </span>
        {count
          &&
          `we are redirecting you back in ${count} seconds`
        }
      </Alert>
    </Snackbar>
}



export default AddCountry