import Axios from 'axios';
import CommonService from '../../../services/commonService';
import {
  HOME_ADD_SERVICE_PRICING_BEGIN,
  HOME_ADD_SERVICE_PRICING_SUCCESS,
  HOME_ADD_SERVICE_PRICING_FAILURE,
  HOME_ADD_SERVICE_PRICING_DISMISS_ERROR,
} from './constants';

export function addServicePricing(args = {}) {
  return (dispatch) => { // optionally you can have getState as the second argument
    dispatch({
      type: HOME_ADD_SERVICE_PRICING_BEGIN,
    });

    const promise = new Promise((resolve, reject) => {
      const doRequest = Axios.post(args.url, args.data, {
        headers: CommonService.getHeaders(),
      });
      doRequest.then(
        (res) => {
          dispatch({
            type: HOME_ADD_SERVICE_PRICING_SUCCESS,
            data: res.data,
          });
          resolve(res);
        },
        // Use rejectHandler as the second argument so that render errors won't be caught.
        (err) => {
          dispatch({
            type: HOME_ADD_SERVICE_PRICING_FAILURE,
            data: { error: err },
          });
          reject(err);
        },
      );
    });

    return promise;
  };
}

export function dismissAddServicePricingError() {
  return {
    type: HOME_ADD_SERVICE_PRICING_DISMISS_ERROR,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case HOME_ADD_SERVICE_PRICING_BEGIN:
      // Just after a request is sent
      return {
        ...state,
        addServicePricingPending: true,
        addServicePricingError: null,
      };

    case HOME_ADD_SERVICE_PRICING_SUCCESS:
      // The request is success
      return {
        ...state,
        addServicePricingPending: false,
        addServicePricingError: null,
        addServiceArea: action.data
      };

    case HOME_ADD_SERVICE_PRICING_FAILURE:
      // The request is failed
      return {
        ...state,
        addServicePricingPending: false,
        addServicePricingError: action.data.error,
      };

    case HOME_ADD_SERVICE_PRICING_DISMISS_ERROR:
      // Dismiss the request failure error
      return {
        ...state,
        addServicePricingError: null,
      };

    default:
      return state;
  }
}
