import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class KuposButton extends Component {
  static propTypes = {
    disabled: PropTypes.bool,
    onClick: PropTypes.func,
    className: PropTypes.string,
  };

  render() {
    return (
      <button
        className={'kupos-button ' + this.props.className}
        disabled={this.props.disabled}
        onClick={this.props.onClick}
        style={{ height: this.props.height || 40 }}
      >
        {this.props.loading ? (
          <div className="button-loader-container">
            <div className="loader-cricle" style={{ marginRight: 10, marginTop: -2 }}></div>
            <span style={this.props.style}>{this.props.children}</span>
          </div>
        ) : (
          <span style={this.props.style}>{this.props.children}</span>
        )}
      </button>
    );
  }
}
