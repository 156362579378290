import React from 'react';
import PropTypes from 'prop-types';
import KuposPlacesInput from './KuposPlacesInput';

import { withTranslation } from 'react-i18next';

class KuposInput extends React.Component {
  static propTypes = {
    placeholder: PropTypes.string,
    label: PropTypes.string,
    value: PropTypes.string,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    error: PropTypes.bool,
    errorMessage: PropTypes.string,
    className: PropTypes.string,
    iconRight: PropTypes.string,
    icon: PropTypes.string,
    iconRightClick: PropTypes.func,
    onSideClick: PropTypes.func,
    disabled: PropTypes.bool,
    type: PropTypes.string,
    style: PropTypes.object,
    inputWidth: PropTypes.object,
    focused: PropTypes.object,
    showPasswordIcon: PropTypes.bool,
    password: PropTypes.bool,
    fontSize: PropTypes.object,
  };

  constructor(props) {
    super(props);

    this.state = {
      focused: this.props.focused,
      isPassowrd: this.props.password,
    };
  }

  componentDidMount() {}

  onFocusChange = () => {
    // this.props.focused ? this.setState({ focused: false }) : null;
  };

  render() {
    // console.log("FontSize", this.props.fontSize)
    return (
      <div className="common-kupos-input" style={this.props.style}>
        {this.props.label ? <span className="input-label">{this.props.label}</span> : null}
        <div
          className={
            'flex-row input-outer ' + this.props.className + (this.props.error ? ' error' : '')
          }
          style={this.props.inputOuterStyle}
        >
          {this.props.icon ? <img className="icon-size icon-left " src={this.props.icon} /> : null}
          {this.props.codeandphone ? (
            <div className="country-code-container">
              <input
                disabled={this.props.disabled}
                value={this.props.countryCode}
                className={this.props.fontSize ? this.props.fontSize : 'font11'}
                // disable={true}
                type="tel"
                autoCorrect={false}
                style={this.props.inputOuterStyle}
                onChange={el => this.props.onCountryCodeChange(el.target.value)}
              />
            </div>
          ) : null}
          {this.props.type == 'places' ? (
            <KuposPlacesInput
              placeholder={this.props.placeholder}
              onFocus={() => this.onFocusChange()}
              value={this.props.value}
              style={this.props.inputWidth}
              onChange={this.props.onChange}
              onBlur={this.props.onBlur}
              disabled={this.props.disabled}
              fontSize={this.props.fontSize}
              onTextChange={this.props.onTextChange}
            />
          ) : (
            <input
              // type={
              //   !this.props.type || this.props.type == 'places' || this.props.type == 'text'
              //     ? 'text'
              //     : this.props.type
              // }
              type={
                (!this.props.type && !this.props.password) ||
                this.props.type == 'places' ||
                this.props.type == 'text'
                  ? 'text'
                  : this.state.isPassowrd
                  ? 'password'
                  : this.props.type
              }
              className={this.props.fontSize ? this.props.fontSize : 'font11'}
              placeholder={this.props.placeholder}
              onFocus={() => this.onFocusChange()}
              value={this.props.value}
              style={([this.props.inputWidth], this.props.disabled ? { color: '#919191' } : {})}
              onChange={
                this.props.onChange && this.props.type != 'places'
                  ? event => this.props.onChange(event.target.value)
                  : null
              }
              onBlur={this.props.onBlur ? event => this.props.onBlur(event.target.value) : null}
              disabled={this.props.disabled}
              min={this.props.min}
              maxlength={this.props.max}
            />
          )}
          {this.props.iconRight ? (
            <img
              onClick={this.props.iconRightClick}
              className="icon-size icon-right "
              src={this.props.iconRight}
            />
          ) : null}

          {this.props.showPasswordIcon && this.props.password ? (
            <div>
              <div onClick={() => this.setState({ isPassowrd: !this.state.isPassowrd })}>
                <div className="show-password-container">
                  {this.state.isPassowrd ? (
                    <img
                      className={'password-show'}
                      src="/images/icons/home/password_off.svg"
                      size={20}
                    />
                  ) : (
                    <img
                      className={'password-hide'}
                      src="/images/icons/home/password_on.svg"
                      size={20}
                    />
                  )}
                </div>
              </div>
            </div>
          ) : null}
        </div>
        {this.props.error ? (
          <span className="error font8">{this.props.t(this.props.errorMessage)}</span>
        ) : null}
      </div>
    );
  }
}

export default withTranslation('common')(KuposInput);
