import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class DataNotFound extends Component {
  static propTypes = {
    icon: PropTypes.string,
    title: PropTypes.string,
    subTitle: PropTypes.string,
    infoTexts: PropTypes.string,
    onInfoClick: PropTypes.func,
  };

  render() {
    return (
      <div className="common-kupos-data-not-found font11 center-text">
        <div className="icon">
          <img src={this.props.icon} />
        </div>
        <div className="title bold-text">{this.props.title}</div>
        <div className="sub-title">{this.props.subTitle}</div>
        <div className={'info-text-container font10 ' + (this.props.onInfoClick ? 'pointer' : '')}>
          <div
            className="info-text"
            onClick={this.props.onInfoClick ? this.props.onInfoClick : null}
          >
            {this.props.infoText}
          </div>
        </div>
      </div>
    );
  }
}
