import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import AppData from '../../../services/appData';
import CommonService from '../../../services/commonService';
import DateService from '../../../services/dateService';
import {
  KuposButton,
  KuposDatePicker,
  KuposErrorSuccessModal,
  KuposModal,
  KuposTimePicker,
  MaterialInputField,
} from '../../common';
import { DropdownComponent } from '../../common/DropdownComponent';
import PrintTicket from '../printTicket';
import { apiCall } from '../redux/apiCall';
import { setCancelTicketDetails } from '../redux/setCancelTicketDetails';

const paymentOptions = [
  { label: 'Efectivo', active: true, value: 'CASH' },
  { label: 'Tarjeta Débito', active: false, value: 'DEBIT_CARD' },
  { label: 'Tarjeta Crédito', active: false, value: 'CREDIT_CARD' },
  { label: 'Trans bancaria', active: false, value: 'BANK_TRANSFER' },
  { label: 'Factura de Crédito', active: false, value: 'CREDIT_INVOICE' },
];

const initailState = {
  apiResponseForPrinting: { data: [], pnr_no: '' },
  reservePaymentType: '',
  reservePaymentComment: '',
};

const Buttons = ({ ticketDetails, setSnackbar, history, setModal, optional }) => {
  const dispatch = useDispatch();
  const [changeTicketDetailsModal, setChangeTicketDetailsModal] = useState(false);
  const [updateTicketDate, setUpdateTicketDate] = useState('');
  const [updateTicketTime, setUpdateTicketTime] = useState('');
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [reservePaymentModal, setReservePaymentModal] = useState(false);
  const [values, setValues] = useState(initailState);

  const handleDisable = () => {
    const ticketDate = Date.parse(ticketDetails && ticketDetails.trip_ref_date);
    const currentDate = Date.parse(DateService.getTodayString('yyyy-mm-dd'));
    const disable = ticketDate > currentDate ? false : true;

    return disable;
  };

  const handleConfirmButtonDisable = () => {
    let status = ticketDetails && ticketDetails.status == 'PENDING' ? false : true;
    if (!status) {
      const ticketDate = Date.parse(ticketDetails && ticketDetails.trip_ref_date);
      const currentDate = Date.parse(DateService.getTodayString('yyyy-mm-dd'));
      const disable = currentDate > ticketDate ? true : false;
      return disable;
    }
    return status;
  };

 const handleCancelButtonDisable = () => {
    let status = ticketDetails && ticketDetails.status == "CANCELLED" ? true : false;
    if (!status) {
      const ticketDate = Date.parse(
        ticketDetails && ticketDetails.trip_ref_date,
      );
      const currentDate = Date.parse(DateService.getTodayString('yyyy-mm-dd'));
      const disable = currentDate > ticketDate ? true : false;
      return disable;
    }
    return status;
  }

  //   Update Ticket details Start
  const handleShowChangeTicket = () => {
    setChangeTicketDetailsModal(true);
    setUpdateTicketDate(
      DateService.changeDateFormat(ticketDetails.trip_ref_date, 'yyyy-mm-dd', 'dd-mm-yyyy'),
    );
    setUpdateTicketTime(ticketDetails.trip_ref_time);
  };

  const handleUpdateTicketDetails = () => {
    const data = {
      pnr_id: ticketDetails.pnr_id,
      customer_email: ticketDetails.customer_email,
      customer_name: ticketDetails.customer_name,
      trip_ref_date: DateService.changeDateFormat(updateTicketDate, 'dd-mm-yyyy', 'yyyy-mm-dd'),
      trip_ref_time: updateTicketTime,
      trip_ref_date_time: ticketDetails && ticketDetails.trip_ref_date_time,
      upd_date_time: CommonService.getTimeInSeconds(
        DateService.changeDateFormat(updateTicketDate, 'dd-mm-yyyy', 'yyyy-mm-dd') +
          'T' +
          updateTicketTime,
      ),
    };

    dispatch(
      apiCall({
        url: AppData.BASE_URL_V1 + `agent/updateTripDateTime`,
        data,
        method: 'POST',
        callback: res => onUpdateTicketDetailsResponse(res),
      }),
    );
  };

  const onUpdateTicketDetailsResponse = res => {
    // console.log({ res });
    if (res && res.data && res.data.success) {
      setModal({ show: true, success: true, bodyText: 'El servicio fue actualizado' });
      setChangeTicketDetailsModal(false);
    }
    if (res && res.data && !res.data.success) {
      setModal({ show: true, success: false, bodyText: res.data.message });
    }
  };

  // handle Cancel Ticket
  const onCancelButtonPress = () => {
    let currentTime = DateService.getCurrentTimeUTC();

    if (ticketDetails) {
      dispatch(
        apiCall({
          url:
            AppData.BASE_URL +
            `agent/cancelSearch/${ticketDetails.customer_email}/${ticketDetails.trip_ref_date}/${ticketDetails.pnr_id}/${currentTime}`,
          callback: res => onCancelTicketDetailsFetch(res),
        }),
      );
    }
  };

  const onCancelTicketDetailsFetch = res => {
    console.log('onCancelTicketDetailsFetch---------', res);

    if (res && res.data && res.data.success && res.data.data) {
      dispatch(setCancelTicketDetails(res.data.data));
      history.push('/cancellation-details');
    } else if (
      res &&
      res.data &&
      !res.data.success &&
      res.data.data &&
      res.data.data.can_be_cancelled == false
    ) {
      setModal({ show: true, success: false, bodyText: 'Este ticket ya fue cancelado' });
    }
  };

  // Resendt Ticket
  const handleResendTicket = () => {
    if (ticketDetails) {
      dispatch(
        apiCall({
          url:
            AppData.BASE_URL_V1 +
            `agent/reSend/${ticketDetails.pnr_id}/${DateService.getTimeInSeconds(
              DateService.getTodayString('yyyy-mm-dd') + 'T00:00:00',
            )}`,
          callback: res => onResendTicketDetailsFetch(res),
        }),
      );
    } else {
      setSnackbar({ showSnackbar: true, alertMessage: 'Ingrese un PNR' });
    }
  };

  const onResendTicketDetailsFetch = res => {
    // console.log({ res });
    if (res && res.data && res.data.success) {
      setModal({ show: true, success: true, bodyText: 'El ticket fue reenviado exitosamente' });
    }
    if (res && res.data && !res.data.success) {
      setModal({ show: true, success: false, bodyText: 'Falla en el reenvío del boleto' });
    }
  };

  const onTransferChange = (val, type) => {
    setValues({ ...values, [type]: val, [type + 'Error']: null });
  };

  const onBlur = () => {};

  const validateReservePaymentFields = () => {
    let errors = 0;
    if (!values.reservePaymentType) {
      setValues({ ...values, reservePaymentTypeError: 'Select a payment type' });
      errors++;
    }

    if (!values.reservePaymentComment) {
      setValues({ ...values, reservePaymentCommentError: 'Payment comment is required' });
      errors++;
      //   console.log('6');
    }

    if (errors > 0) {
      return false;
    } else {
      return true;
    }
  };
  const confirmReserveTicket = () => {
    if (!validateReservePaymentFields()) {
      alert('Fill all details');
      return;
    }

    let data = {
      client_code: ticketDetails && ticketDetails.client_code,
      counter_txn_id: ticketDetails && ticketDetails.counter_txn_id,
      user_id: ticketDetails && ticketDetails.user_id,
      user_name: ticketDetails && ticketDetails.user_name,
      pnr_id: ticketDetails && ticketDetails.pnr_id,
      confirm_time_utc: CommonService.getTimeInSeconds(
        DateService.getTodayString('yyyy-mm-dd') +
          'T' +
          DateService.getCurrentHour() +
          ':' +
          DateService.getCurrentMinute(),
      ), // UTC time in Unix Milli Seconds.,
      confirm_time_stg: `${DateService.getCurrentHour()}:${DateService.getCurrentMinute()} `,
      payment_type: values.reservePaymentType ? values.reservePaymentType.value : '',
      payment_amount: ticketDetails && ticketDetails.total_amount,
      payment_ref_number: values.reservePaymentComment ? values.reservePaymentComment : '',
    };

    dispatch(
      apiCall({
        url: AppData.BASE_URL_V1 + 'agent/confirmReservation',
        data: data,
        method: 'POST',
        callback: res => onConfirmPaymentFetch(res),
      }),
    );
  };

  const onConfirmPaymentFetch = res => {
    if (res && res.data && res.data.success) {
      console.log("response from confirm ticket ",res)
      var printData = { ...values.apiResponseForPrinting };
      printData.data = ticketDetails;

      setValues({ ...values, apiResponseForPrinting: printData });
      setReservePaymentModal(false);
      setModal({
        show: true,
        success: true,
        bodyText: 'El boleto ha sido confirmado',
        printTicket: true,
      });
    }
  };

  const handleCloseReserverModal = () => {
    setReservePaymentModal(false);
    setValues(initailState);
  };

  return (
    <>
      <div className="bottom-button-conatiner pb-3">
        <KuposButton onClick={handleShowChangeTicket} disabled={handleCancelButtonDisable()}>
          <span className="vendor-icon font10">CAMBIAR PASAJE</span>
        </KuposButton>

        <KuposButton
          onClick={() => setShowConfirmModal(true)}
          disabled={handleConfirmButtonDisable()}
        >
          <span className="vendor-icon font10">CONFIRMAR PASAJE</span>
        </KuposButton>

        <KuposButton onClick={onCancelButtonPress} disabled={handleCancelButtonDisable()}>
          <span className="vendor-icon font10">CANCELAR PASAJE</span>
        </KuposButton>
        <KuposButton onClick={handleResendTicket} disabled={handleCancelButtonDisable()}>
          <span className="vendor-icon font10">REENVIAR PASAJE</span>
        </KuposButton>
        <KuposButton
          // onClick={onBookButtonPress}
          disabled={true}
        >
          <span className="vendor-icon font10">VER HISTORIAL DEL PASAJE</span>
        </KuposButton>
      </div>
      <KuposModal
        showModal={changeTicketDetailsModal}
        onHide={() => setChangeTicketDetailsModal(false)}
      >
        <div className="p-4">
          <div className="bold-text">
            Cambios de fecha o hora de boleto : {ticketDetails && ticketDetails.pnr_id}
          </div>
          <div className="bold-text">
            Fecha actual:{' '}
            {ticketDetails &&
              DateService.changeDateFormat(ticketDetails.trip_ref_date, 'yyyy-mm-dd', 'dd-mm-yyyy')}
          </div>
          <div className="bold-text">
            Hora actual: {ticketDetails && ticketDetails.trip_ref_time} horas
          </div>
          <div className="d-flex align-items-center my-2">
            <div className="bold-text mr-8">Seleccionar fecha: </div>
            <KuposDatePicker
              minDate={DateService.getTodayString('dd-mm-yyyy')}
              selectedDate={updateTicketDate}
              onDateChange={data => {
                setUpdateTicketDate(data);
                //setState({
                //   updateTicketDate: data,
                //   updateTicketDateError: null,
                // });
              }}
              placeholder={'Date'}
              newStyle="border-less-date-input"
              style={{ border: 'lightgray solid 1px', borderRadius: '10px' }}
            />
          </div>

          <div className="d-flex align-items-center">
            <div className="bold-text mr-10 ">Seleccionar hora: </div>
            <KuposTimePicker
              value={updateTicketTime}
              onChange={val => setUpdateTicketTime(val)}
              placeholder={optional ? '' : '00:00'}
              hideIcon={true}
              showIconsOnAmPm={true}
              showAmPm={true}
              optional={optional}
              showPicker={true}
              style={{ width: '150px' }}
            />
          </div>

          <div className="d-flex mt-3">
            <KuposButton onClick={handleUpdateTicketDetails} className="mr-5">
              Actualizar fecha y hora del boleto
            </KuposButton>

            <KuposButton onClick={() => setChangeTicketDetailsModal(false)}>Cancelar</KuposButton>
          </div>
        </div>
      </KuposModal>

      <KuposErrorSuccessModal
        success={showConfirmModal}
        showModal={showConfirmModal}
        showButton1={true}
        showButton2={true}
        buttonText1={'OK'}
        buttonText2={'Cancel'}
        onHide={handleCloseReserverModal}
        onButtonClick1={() => {
          setShowConfirmModal(false);
          setReservePaymentModal(true);
        }}
        onButtonClick2={handleCloseReserverModal}
        bodyText={'¿Usted recolectó el pago de este boleto? Aceptar'}
      />

      <KuposModal showModal={reservePaymentModal} onHide={handleCloseReserverModal}>
        <div className="common-kupos-error-success-modal">
          <div>CONFIRMAR PASAJE PARA EL PNR:</div>
          <div>{ticketDetails && ticketDetails.pnr_id}</div>
          <div className="at-horizonatal-line"></div>

          <div className="home-selection-input-box font10 select-box" style={{ marginTop: 30 }}>
            <div>
              <span className="label font11">{'Tipo'}</span>
            </div>

            <DropdownComponent
              onChange={val => onTransferChange(val, 'reservePaymentType')}
              placeholder={'Moneda'}
              options={paymentOptions}
              value={values.reservePaymentType}
              error={values.reservePaymentTypeError ? true : false}
              errorMessage={values.reservePaymentTypeError ? values.reservePaymentTypeError : ''}
            />
          </div>

          <div className="common-kupos-input-box" style={{ marginTop: 20 }}>
            <MaterialInputField
              // label={'Información adicional'}
              placeholder={'Comentario'}
              value={values.reservePaymentComment}
              multiline={true}
              rows={2}
              onChange={text => onTransferChange(text, 'reservePaymentComment')}
              onBlur={() => onBlur()}
              error={values.reservePaymentCommentError}
              errorMessage={
                values.reservePaymentCommentError ? values.reservePaymentCommentError : ''
              }
            />
          </div>
          <div className="button-container">
            <div style={{ marginRight: 10 }}>
              <KuposButton onClick={() => confirmReserveTicket()}>
                <span>Confirmar</span>
              </KuposButton>
            </div>

            <div>
              <KuposButton onClick={handleCloseReserverModal}>
                <span>Cerrar</span>
              </KuposButton>
            </div>
          </div>
        </div>
      </KuposModal>
      <div style={{ visibility: 'hidden' }}>
        <PrintTicket printData={values.apiResponseForPrinting}></PrintTicket>
      </div>
    </>
  );
};

export default Buttons;
