import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actions from './redux/actions';
import KuposModal from './KuposModal';
import SvgHome from './SvgHome';

export class NewsLetterSuccessModal extends Component {
  static propTypes = {
    common: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired,
  };

  render() {
    return (
      <div className="common-login-modal">
        <KuposModal
          showModal={this.props.showModal}
          onHide={this.props.onHide}
          size="md"
          ariaLabel="login-modal"
        >
          <div className="login-signup-block">
            <div className="close-button pointer" onClick={this.props.onHide}>
              <SvgHome name="close" />
            </div>
            <div className="kupos-card log-in-card ">
              <div className="user-icon kupos-go-modal-image">
                <img src="../images/booking-success-circle.png" />
              </div>
              <div className="bold-text center-text font16">{this.props.t('HOME.THANK_YOU')}</div>

              <div className="center-text font11 light-text" style={{ margin: 20 }}>
                {this.props.t('HOME.NEWSLETTER_1')}
              </div>
              <div className="center-text font11 light-text">
                {this.props.t('HOME.NEWSLETTER_2')}
              </div>

              <div className="login-signup-button font12">
                <button className="kupos-button" onClick={this.props.onHide}>
                  <span>{this.props.t('HOME.GO_BACK_BUTTON')}</span>
                </button>
              </div>
            </div>
          </div>
        </KuposModal>
      </div>
    );
  }
}

/* istanbul ignore next */
function mapStateToProps(state) {
  return {
    common: state.common,
  };
}

/* istanbul ignore next */
function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...actions }, dispatch)
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NewsLetterSuccessModal);
