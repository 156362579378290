import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actions from './redux/actions';
import { KuposButton, KuposInput } from '../common';
import { DropdownComponent } from '../common/DropdownComponent';

export class ManageVehicleType extends Component {
  static propTypes = {
    home: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired,
  };

    render() {
           return (
             <div className="home-add-vehicle">
               <div className="common-kupos-input-box" style={{ marginTop: 10 }}>
                 <KuposInput
                   type="text"
                   className="kupos-border"
                   placeholder={'Select Application'}
                   value={this.state.application}
                   error={this.state.applicationError ? true : false}
                   errorMessage={
                     this.state.applicationError ? this.state.applicationError.message : ''
                   }
                   onChange={text => this.onChange(text, 'application')}
                   onBlur={text => this.onBlur(text, 'application')}
                 />
               </div>

               <div className="common-kupos-input-box" style={{ marginTop: 10 }}>
                 <KuposInput
                   type="text"
                   className="kupos-border"
                   placeholder={'Vehicle Name'}
                   value={this.state.vehicleName}
                   error={this.state.vehicleNameError ? true : false}
                   errorMessage={
                     this.state.vehicleNameError ? this.state.vehicleNameError.message : ''
                   }
                   onChange={text => this.onChange(text, 'vehicleName')}
                   onBlur={text => this.onBlur(text, 'vehicleName')}
                 />
               </div>

               <div style={{ marginTop: 10, marginBottom: 10 }}>
                 <DropdownComponent
                   onChange={val => this.onSelectOptionChange(val)}
                   placeholder={'Vehicle Status'}
                   options={this.state.status}
                   value={this.state.vehicleStatus}
                   error={this.state.vehicleStatusError ? true : false}
                   errorMessage={
                     this.state.vehicleStatusError ? this.state.vehicleStatusError.message : ''
                   }
                 />
               </div>

               <div className="common-kupos-input-box" style={{ marginTop: 10 }}>
                 <KuposInput
                   type="text"
                   className="kupos-border"
                   placeholder={'Location Name'}
                   value={this.state.locationName}
                   error={this.state.locationNameError ? true : false}
                   errorMessage={
                     this.state.locationNameError ? this.state.locationNameError.message : ''
                   }
                   onChange={text => this.onChange(text, 'locationName')}
                   onBlur={text => this.onBlur(text, 'locationName')}
                 />
               </div>

               <div className="common-kupos-input-box" style={{ marginTop: 10 }}>
                 <KuposInput
                   type="text"
                   className="kupos-border"
                   placeholder={'Max Seats'}
                   value={this.state.maxSeats}
                   error={this.state.maxSeatsError ? true : false}
                   errorMessage={this.state.maxSeatsError ? this.state.maxSeatsError.message : ''}
                   onChange={text => this.onChange(text, 'maxSeats')}
                   onBlur={text => this.onBlur(text, 'maxSeats')}
                 />
               </div>

               <div className="common-kupos-input-box" style={{ marginTop: 10 }}>
                 <KuposInput
                   type="text"
                   className="kupos-border"
                   placeholder={'Minimum Balance'}
                   value={this.state.minBalance}
                   error={this.state.minBalanceError ? true : false}
                   errorMessage={
                     this.state.minBalanceError ? this.state.minBalanceError.message : ''
                   }
                   onChange={text => this.onChange(text, 'minBalance')}
                   onBlur={text => this.onBlur(text, 'minBalance')}
                 />
               </div>

               <div style={{ marginTop: 10, marginBottom: 10 }}>
                 <DropdownComponent
                   onChange={val => this.onSelectOptionChange(val)}
                   placeholder={'Commission Type'}
                   options={this.state.commissionTypes}
                   value={this.state.commissionType}
                   error={this.state.commissionTypeError ? true : false}
                   errorMessage={
                     this.state.commissionTypeError ? this.state.commissionTypeError.message : ''
                   }
                 />
               </div>

               <div className="common-kupos-input-box" style={{ marginTop: 10 }}>
                 <KuposInput
                   type="text"
                   className="kupos-border"
                   placeholder={'Commission'}
                   value={this.state.commission}
                   error={this.state.commissionError ? true : false}
                   errorMessage={
                     this.state.commissionError ? this.state.commissionError.message : ''
                   }
                   onChange={text => this.onChange(text, 'commission')}
                   onBlur={text => this.onBlur(text, 'commission')}
                 />
               </div>

               <div className="button-container" style={{ marginTop: 20 }}>
                 <KuposButton onClick={this.createOrUpdate}>
                   <span className="font11">
                     {this.props.match.params.id ? 'Update Vehicle' : 'Create Vehicle'}
                   </span>
                 </KuposButton>
               </div>
             </div>
           );
         }
  }

/* istanbul ignore next */
function mapStateToProps(state) {
  return {
    home: state.home,
  };
}

/* istanbul ignore next */
function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...actions }, dispatch)
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ManageVehicleType);
