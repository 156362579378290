import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { ActionButtons, AddButton, KuposErrorSuccessModal, KuposListLoader, KuposModalWithButtons } from '../common';
import ApiUrls from '../../services/apiUrls';
import DateService from '../../services/dateService';
import { apiCall } from './redux/apiCall';

const modalInitialState = {
  show: false,
  success: false,
  bodyText: null,
};
const AllPlatformLocations = () => {
  const dispatch = useDispatch();
  const onResponse = useRef(() => { });
  const [fetch, setFetch] = useState(false);
  const [locationList, setLocationList] = useState([]);
  const [modal, setModal] = useState(modalInitialState);
  const [selectedViewItem, setSelectedViewItem] = useState('');
  const [loader, setLoader] = useState(true);

  useEffect(() => {
    dispatch(apiCall({ url: ApiUrls.getLocationList(), callback: res => onResponse.current(res) }));
  }, [dispatch, fetch]);

  onResponse.current = res => {
    setLoader(false);
    if (res && res.data && res.data.success) {
      setLocationList(res.data.data);
    }
  };

  const renderItem = (val, i) => {
    return (
      <div key={i} className="flex item-row">
        <div className="sm:w-full md:w-1/6 text-center">{val.id}</div>
        <div className="sm:w-full md:w-1/6 text-center">{val.client_code}</div>
        <div className="sm:w-full md:w-1/6 text-center">{val.type}</div>
        <div className="sm:w-full md:w-3/6 text-center">{val.name}</div>
        <div className="sm:w-full md:w-1/6 text-center">{val.status}</div>
        <div className="sm:w-full md:w-3/6 text-center">{val.remark}</div>
        <ActionButtons
          val={val}
          view={setSelectedViewItem}
          url={('/platform-location/' + val.id + '/edit')}
          handleDelete={() =>
            setModal({
              showDeleteModal: true,
              selectedItemName: val.name,
              selectedItemId: val.id,
              itemToDelete: val,
            })
          }
        />

      </div>
    );
  };

  const renderViewModal = () => {
    return (
      <KuposModalWithButtons
        size="lg"
        // buttonText1="O"
        // onButtonClick1={this.addSkillPoint}
        showButton1={true}
        showModal={selectedViewItem ? true : false}
        onHide={() => setSelectedViewItem(null)}
      >
        {selectedViewItem && selectedViewItem.id ? (
          <div className="view-item-container">
            <h3 className="text-center font-bold">Servicio</h3>
            <div className="flex view-item-row">
              <div className="sm:w-full md:w-2/6 label font-bold">Tipo:</div>
              <div className="sm:w-full md:w-full">{selectedViewItem.type}</div>
            </div>
            <div className="flex view-item-row">
              <div className="sm:w-full md:w-2/6 label font-bold">Nombre:</div>
              <div className="sm:w-full md:w-full">{selectedViewItem.name}</div>
            </div>
            <div className="flex view-item-row">
              <div className="sm:w-full md:w-2/6 label font-bold">País:</div>
              <div className="sm:w-full md:w-full">{selectedViewItem.country_name}</div>
            </div>
            <div className="flex view-item-row">
              <div className="sm:w-full md:w-2/6 label font-bold">Región:</div>
              <div className="sm:w-full md:w-full">{selectedViewItem.Sate_name}</div>
            </div>
            <div className="flex view-item-row">
              <div className="sm:w-full md:w-2/6 label font-bold">Ciudad:</div>
              <div className="sm:w-full md:w-full">{selectedViewItem.city_name}</div>
            </div>
            <div className="flex view-item-row">
              <div className="sm:w-full md:w-2/6 label font-bold">Dirección :</div>
              <div className="sm:w-full md:w-full">{selectedViewItem.address}</div>
            </div>
            <div className="flex view-item-row">
              <div className="sm:w-full md:w-2/6 label font-bold">Estatus:</div>
              <div className="sm:w-full md:w-full">{selectedViewItem.status}</div>
            </div>
            <div className="flex view-item-row">
              <div className="sm:w-full md:w-2/6 label font-bold">Observación:</div>
              <div className="sm:w-full md:w-full">{selectedViewItem.remark}</div>
            </div>
          </div>
        ) : null}
      </KuposModalWithButtons>
    );
  };

  const deleteItem = () => {
    let data = {
      id: modal.itemToDelete.id,
      client_code: modal.itemToDelete.client_code,
      type: modal.itemToDelete.type,
      name: modal.itemToDelete.name,
      city_id: modal.itemToDelete.city_id,
      city_name: modal.itemToDelete.city_name,
      state_id: modal.itemToDelete.state_id,
      Sate_name: modal.itemToDelete.Sate_name,
      country_id: modal.itemToDelete.country_id,
      country_name: modal.itemToDelete.country_name,
      address: modal.itemToDelete.address,
      remark: modal.itemToDelete.remark,
      status: 'ACTIVE',
      created_by_user: ' ',
      created_on_utc: modal.itemToDelete.created_on_utc,
      created_on_date_time_stg: modal.itemToDelete.created_on_date_time_stg,
      last_updated_by_user: ' ',
      last_updated_on_utc: modal.itemToDelete.last_updated_on_utc,
      last_updated_on_date_time_stg: modal.itemToDelete.last_updated_on_date_time_stg,
      deleted_by: '',
      deleted_on_utc: DateService.getCurrentTimeUTC(),
      deleted_on_date_time_stg: DateService.getCurrentStgDate(),
    };
    dispatch(
      apiCall({
        url: ApiUrls.addLocation(),
        method: 'DELETE',
        data,
        callback: res => onDeleteResponse(res),
      }),
    );

  };

  const onDeleteResponse = res => {
    if (res.data && res.data.success) {
      setModal({ show: true, success: true, bodyText: 'Eliminar éxito' });
      setFetch(!fetch);
    } else {
      setModal({ show: true, success: false, bodyText: res.data.message });
    }
  };

  const renderDeleteModal = () => {
    return (
      <KuposModalWithButtons
        size="md"
        buttonText1="No"
        buttonText2="Si"
        onButtonClick1={() => setModal(modalInitialState)}
        onButtonClick2={deleteItem}
        showButton1={true}
        showButton2={true}
        showModal={modal.showDeleteModal}
        onHide={() => setModal(modalInitialState)}
      >
        <div className="create-staff">
          <h4 className="text-center">¿Quieres eliminar "{modal.selectedItemName}"?</h4>
        </div>
      </KuposModalWithButtons>
    );
  };

  return (
    <div className="home-all-locations">
      <AddButton
        title='Platfrom Locations'
        url='/platform-location/create'
        addTitle='Add Platfrom Location'
      />
      <div className="flex table-heading">
        <div className="sm:w-full md:w-1/6 text-center">ID</div>
        <div className="sm:w-full md:w-1/6 text-center">Operador</div>
        <div className="sm:w-full md:w-1/6 text-center">Tipo de servicio</div>
        <div className="sm:w-full md:w-3/6 text-center">Ubicación</div>
        <div className="sm:w-full md:w-1/6 text-center">Estatus</div>
        <div className="sm:w-full md:w-3/6 text-center">Observación de ubicación</div>
        <div className="sm:w-full md:w-3/6 text-center">Acción</div>
      </div>

      {loader ? <KuposListLoader /> : (locationList || []).length > 0
        ? locationList
          .sort((x, y) => {
            return y.id - x.id;
          })
          .map((val, i) => renderItem(val, i))
        : null}

      {renderViewModal()}

      {renderDeleteModal()}

      <KuposErrorSuccessModal
        showModal={modal.show}
        success={modal.success}
        smallIcon={true}
        bodyText={modal.bodyText}
        onButtonClick={() => {
          setModal(modalInitialState);
        }}
      />
    </div>
  );
}

export default AllPlatformLocations;
