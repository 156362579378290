import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actions from './redux/actions';
import { DropdownComponent } from '../common/DropdownComponent';
import { KuposButton } from '../common';
import ApiUrls from '../../services/apiUrls';
import CommonService from '../../services/commonService';
import DateService from '../../services/dateService';

export class ChooseShiftAndCounter extends Component {
  static propTypes = {
    home: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    let currentUser = CommonService.currentUser;

    console.log('current user is-----', CommonService.currentUser);

    currentUser &&
      currentUser.user_details &&
      this.setState({
        myBranch: {
          value: currentUser.user_details.branch_id,
          label: currentUser.user_details.branch_name,
        },
      });

    // this.getBranchesList();
    this.getCountersList();
    //  this.getShiftList();
  }

  // getBranchesList = () => {
  //   this.props.actions.apiCall({ url: ApiUrls.addBranch() }).then(
  //     res => {
  //       console.log('Result from all branches api', res);
  //       if (res.data && res.data.success) {
  //         let branches = [];
  //         res.data.data.map(branch => {
  //           branches.push({ value: branch.id, label: branch.name });
  //         });
  //         this.setState({ branches: branches });
  //       }
  //     },
  //     err => {
  //       console.log('Err from all branches is', err);
  //     },
  //   );
  // };

  getCountersList = () => {
    let currentUserFull = CommonService.getLocal('currentUserFull');

    let counters = [];

    currentUserFull.available_counter_shifts &&
      currentUserFull.available_counter_shifts.map((val, key) => {
        console.log('Counter shift map is----', val);
        counters.push({ value: val.counter_id, label: val.counter_name });
      });
    this.setState({ countersList: counters });
    // let counters = [];
    // this.props.actions.apiCall({ url: ApiUrls.allCounters() }).then(
    //   res => {
    //     if (res.data && res.data.success) {
    //       console.log('All counters are--------', res.data.data);

    //       res.data.data.map(val => {
    //         counters.push({ value: val.id, label: val.name });
    //       });

    //       this.setState({ countersList: counters });
    //     }
    //   },
    //   err => {
    //     console.log('Result is', err);
    //   },
    // );
  };

  getShiftList = counter => {
    console.log('Selected counter is----', counter);
    // return;

    let currentUserFull = CommonService.getLocal('currentUserFull');

    let shifts = [];

    currentUserFull.available_counter_shifts &&
      currentUserFull.available_counter_shifts.map((val, key) => {
        console.log('Counter shift map is----', val);

        if (val.counter_id === counter.value) {
          val.shift_id.map(shift => {
            shifts.push({ value: shift.shift_id, label: shift.shift_name });
          });
        }
      });
    this.setState({ shiftList: shifts });
    // this.props.actions.apiCall({ url: ApiUrls.addShifts() }).then(
    //   res => {
    //     console.log('Shift list is------', res);
    //     let shifts = [];
    //     if (res.data && res.data.success) {
    //       res.data.data.map(val => {
    //         shifts.push({ value: val.id, label: val.name });
    //       });
    //       this.setState({ shiftList: shifts });
    //     }
    //   },
    //   err => {
    //     console.log('Result is', err);
    //   },
    // );
  };

  onChnage = (val, type) => {
    switch (type) {
      case 'branch':
        this.setState({ selectedBranch: val, selectedBranchError: null });
        break;
      case 'counter':
        this.setState({ selectedCounter: val, selectedCounterError: null });
        this.getShiftList(val);
        break;
      case 'shift':
        this.setState({ selectedShift: val, selectedShiftError: null });
        break;
    }
  };

  validate = () => {
    let errors = 0;

    if (!this.state.myBranch) {
      this.setState({ selectedBranchError: { message: 'Seleccione sucursal' } });
      errors++;
    }

    if (!this.state.selectedShift) {
      this.setState({ selectedShiftError: { message: 'Seleccione turno' } });
      errors++;
    }

    if (!this.state.selectedCounter) {
      this.setState({ selectedCounterError: { message: 'Seleccione caja' } });
      errors++;
    }

    if (errors > 0) {
      return false;
    } else {
      return true;
    }
  };

  createOrUpdate = () => {
    console.log('Props are----', this.props);

    if (!this.validate()) {
      return;
    }

    let data = {
      branch: this.state.myBranch,
      shift: this.state.selectedShift,
      counter: this.state.selectedCounter,
    };
    this.props.actions.setCounterInitilalData(data);
    this.props.history.push('/cash-in-hand');
  };

  logoutUser = () => {
    let currentUser = CommonService.getLocal('currentUser');
    let currentUserFull = CommonService.getLocal('currentUserFull');
    let counter = CommonService.getLocal('counter');
    let currentTime = DateService.getCurrentTimeUTC();

    console.log('Current user----', currentUser);
    console.log('counter is---', counter);
    let data = {
      login_id: currentUser.user_details.email, // Id used for login ( email)
      login_info_ref_id: currentUserFull.login_info_ref_id, // Will get this in login API response ( login_info_ref_id )
      counter_txn_id: counter != undefined ? counter.counter_txn_id : 0, // For Counter Users, client will get this while counter_open, Other Users send 0
      logout_unix_time: currentTime,
      logout_date_stg: DateService.getTodayString('yyyy-mm-dd'),
      logout_time_stg: `${DateService.getCurrentHour()}:${DateService.getCurrentMinute()}`,
    };

    console.log('Data to fly ----', data);

    this.props.actions.apiCall({ url: ApiUrls.logout(), data: data, method: 'POST' }).then(
      res => {
        this.onLogoutResponse(res);
      },
      err => {
        this.onLogoutResponse(err);
      },
    );
  };

  onLogoutResponse = result => {
    console.log('Result from logout is----', result);

    if (result && result.data && result.data.success) {
      CommonService.logout();
      window.location.href = '/';
    }
  };

  render() {
    console.log('myBranch', this.state.myBranch);
    return (
      <div className="home-choose-shift-and-counter">
        <div className="shift-main-container">
          <div className="text-align-center ">
            <span className="bold-text ">Bienvenido {CommonService.currentUser.user_name}</span>
          </div>
          <div className="header-conatiner text-align-center ">
            <span>Seleccione turno y caja para iniciar la venta</span>
          </div>

          <DropdownComponent
            onChange={val => this.onChnage(val, 'branch')}
            disabled={true}
            placeholder={'Seleccione sucursal'}
            options={this.state.branches}
            value={this.state.myBranch}
            error={this.state.selectedBranchError ? true : false}
            errorMessage={
              this.state.selectedBranchError ? this.state.selectedBranchError.message : ''
            }
          />

          <div className="dropdown-container">
            <DropdownComponent
              onChange={val => this.onChnage(val, 'counter')}
              placeholder={'Seleccione Caja'}
              options={this.state.countersList}
              value={this.state.selectedCounter}
              error={this.state.selectedCounterError ? true : false}
              errorMessage={
                this.state.selectedCounterError ? this.state.selectedCounterError.message : ''
              }
            />
            <DropdownComponent
              onChange={val => this.onChnage(val, 'shift')}
              placeholder={'Turno mañana'}
              options={this.state.shiftList}
              value={this.state.selectedShift}
              error={this.state.selectedShiftError ? true : false}
              errorMessage={
                this.state.selectedShiftError ? this.state.selectedShiftError.message : ''
              }
            />
          </div>

          <div className="button-container">
            <div style={{ marginRight: 10 }}>
              <KuposButton onClick={this.createOrUpdate} style={{ minWidth: 150 }}>
                <span className="font11">Continuar</span>
              </KuposButton>
            </div>

            <div style={{ marginLeft: 10 }}>
              <KuposButton onClick={() => this.logoutUser()} style={{ minWidth: 150 }}>
                <span className="font11">Salir</span>
              </KuposButton>
            </div>

            <div style={{ marginLeft: 10 }}>
              <KuposButton onClick={() => this.props.history.push('/')} style={{ minWidth: 150 }}>
                <span className="font11">Saltar</span>
              </KuposButton>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

/* istanbul ignore next */
function mapStateToProps(state) {
  return {
    home: state.home,
  };
}

/* istanbul ignore next */
function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...actions }, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ChooseShiftAndCounter);
