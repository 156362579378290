import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { ActionButtons, AddButton, KuposErrorSuccessModal, KuposListLoader, KuposModalWithButtons } from '../common';
import ApiUrls from '../../services/apiUrls';
import { apiCall } from './redux/apiCall';
import CommonService from '../../services/commonService';
import DateService from '../../services/dateService';
import { Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';

const modalInitialState = {
  show: false,
  success: false,
  bodyText: null,
};

const AllExchangeRates = () => {
  const dispatch = useDispatch();
  const onResponse = useRef(() => { });
  const [fetch, setFetch] = useState(false);
  const [list, setList] = useState([]);
  const [modal, setModal] = useState(modalInitialState);
  const [selectedViewItem, setSelectedViewItem] = useState('');
  const [loader, setLoader] = useState(true);
  const [snackbar, setSnackbar] = useState({});


  useEffect(() => {
    dispatch(apiCall({ url: ApiUrls.addCurrencyExchange(), callback: res => onResponse.current(res) }));
  }, [dispatch, fetch]);

  onResponse.current = res => {
    setLoader(false);
    if (res && res.data && res.data.success) {
      setList(res.data.data);
    } else if (res && res.data && !res.data.success) {
      setSnackbar({
        showSnackbar: true,
        persist: true,
        alertMessage: res.data.message ? res.data.message : 'Failure'
      });
    } else {
      setSnackbar({
        showSnackbar: true,
        persist: true,
        alertMessage: 'Check Your Internet Connection'
      });
    }
  };



  const renderItem = (val, i) => {
    return (
      <div key={i} className="flex item-row">
        <div className="sm:w-full md:w-1/6 text-center">{val.id}</div>
        <div className="sm:w-full md:w-2/6 text-center">{val.client_code}</div>
        <div className="sm:w-full md:w-3/6 text-center">{val.exchange_name}</div>
        <div className="sm:w-full md:w-2/6 text-center">{val.exchange_rate}</div>
        <div className="sm:w-full md:w-2/6 text-center">{val.status}</div>
        <div className="sm:w-full md:w-2/6 text-center">
          {val.creation_date_stg + '\n' + val.creation_time_stg}
        </div>
        <div className="sm:w-full md:w-2/6 text-center">
          {' '}
          {val.updation_date_stg + '\n' + val.updation_time_stg}
        </div>

        <ActionButtons
          val={val}
          view={setSelectedViewItem}
          url={('/exchange-rates/' + val.id + '/edit')}
          handleDelete={() =>
            setModal({
              showDeleteModal: true,
              selectedItemDelete: val,
            })
          }
        />

      </div>
    );
  };

  const renderViewModal = () => {
    return (
      <KuposModalWithButtons
        size="lg"
        // buttonText1="O"
        // onButtonClick1={this.addSkillPoint}
        showButton1={true}
        showModal={selectedViewItem ? true : false}
        onHide={() => setSelectedViewItem(null)}
      >
        {selectedViewItem && selectedViewItem.id ? (
          <div className="view-item-container">
            <h3 className="text-center font-bold">Tasa de conversión</h3>
            <div className="flex view-item-row">
              <div className="sm:w-full md:w-2/6 label font-bold">Conversión Nombre:</div>
              <div className="sm:w-full md:w-full">{selectedViewItem.exchange_name}</div>
            </div>
            <div className="flex view-item-row">
              <div className="sm:w-full md:w-2/6 label font-bold">Pais Tasa:</div>
              <div className="sm:w-full md:w-full">{selectedViewItem.exchange_rate}</div>
            </div>
            <div className="flex view-item-row">
              <div className="sm:w-full md:w-2/6 label font-bold">Addeed on:</div>
              <div className="sm:w-full md:w-full">
                {selectedViewItem.creation_date_stg +
                  ', ' +
                  selectedViewItem.creation_time_stg}
              </div>
            </div>
            <div className="flex view-item-row">
              <div className="sm:w-full md:w-2/6 label font-bold">Estatus:</div>
              <div className="sm:w-full md:w-full">{selectedViewItem.status}</div>
            </div>
          </div>
        ) : null}
      </KuposModalWithButtons>
    );
  };

  const renderDeleteModal = () => {
    return (
      <KuposModalWithButtons
        size="md"
        buttonText1="No"
        buttonText2="Yes"
        onButtonClick1={() => setModal(modalInitialState)}
        onButtonClick2={deleteItem}
        showButton1={true}
        showButton2={true}
        showModal={modal.showDeleteModal}
        onHide={() => setModal(modalInitialState)}
      >
        <div className="create-staff">
          <h4 className="text-center">
            ¿Esta seguro de que desea eliminar "
            {modal.selectedItemDelete && modal.selectedItemDelete.exchange_name}"?
          </h4>
        </div>
      </KuposModalWithButtons>
    );
  };

  const deleteItem = () => {
    let data = {
      id: modal.selectedItemDelete.id,
      client_code: modal.selectedItemDelete.client_code,
      user_id: modal.selectedItemDelete.user_id,
      user_name: modal.selectedItemDelete.user_name,
      exchange_name: modal.selectedItemDelete.exchange_name,
      status: modal.selectedItemDelete.status,
      from_currency_id: modal.selectedItemDelete.from_currency_id,
      from_currency_code: modal.selectedItemDelete.from_currency_code,
      to_currency_id: modal.selectedItemDelete.to_currency_id,
      to_currency_code: modal.selectedItemDelete.to_currency_code,
      exchange_rate: modal.selectedItemDelete.exchange_rate,
      creation_date_stg: modal.selectedItemDelete.creation_date_stg,
      creation_time_stg: modal.selectedItemDelete.creation_time_stg,
      updation_user_id: 0,
      updation_user_name: '',
      updation_date_stg: '',
      updation_time_stg: '',
      deletion_user_id: CommonService.currentUser.user_id,
      deletion_user_name: `${CommonService.currentUser.user_details.first_name} ${CommonService.currentUser.user_details.last_name}`,
      deletion_date_stg: DateService.getTodayString('yyyy-mm-dd'),
      deletion_time_stg: `${DateService.getCurrentHour()}:${DateService.getCurrentMinute()}`,
    };

    dispatch(
      apiCall({
        url: ApiUrls.addCurrencyExchange(),
        method: 'DELETE',
        data,
        callback: res => onDeleteResponse(res),
      }),
    );

  };

  const onDeleteResponse = res => {
    if (res.data && res.data.success) {
      setModal({ show: true, success: true, bodyText: 'Eliminar éxito' });
      setFetch(!fetch);
    } else {
      setModal({ show: true, success: false, bodyText: res.data.message });
    }
  };

  return (
    <div className="home-all-counters">
      <hr className="pb-3" />
      <AddButton
        title='Tasa de conversión'
        url='/exchange-rates/create'
        addTitle='Añadir Tasa de conversión'
      />

      <div className="flex table-heading">
        <div className="sm:w-full md:w-1/6 text-center">ID</div>
        <div className="sm:w-full md:w-2/6 text-center">Cliente</div>
        <div className="sm:w-full md:w-3/6 text-center">Nombre de conversión</div>
        <div className="sm:w-full md:w-2/6 text-center">Tasa de conversión</div>
        <div className="sm:w-full md:w-2/6 text-center">Estatus</div>
        <div className="sm:w-full md:w-2/6 text-center">Creado el</div>
        <div className="sm:w-full md:w-2/6 text-center">Actualizado el</div>
        <div className="sm:w-full md:w-3/6 text-center">Acciones</div>
      </div>
      {loader ? <KuposListLoader /> : (list || []).length > 0
        ? list
          .sort((x, y) => {
            return y.id - x.id;
          })
          .map((val, i) => renderItem(val, i))
        : null}
      {renderViewModal()}
      {renderDeleteModal()}

      <KuposErrorSuccessModal
        showModal={modal.show}
        success={modal.success}
        smallIcon={true}
        bodyText={modal.bodyText}
        onButtonClick={() => {
          setModal(modalInitialState);
        }}
      />
      <Snackbar
        open={snackbar.showSnackbar}
        autoHideDuration={snackbar.persist ? 10000000000 : 5000}
        anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
        onClose={() => setSnackbar({ showSnackbar: false })}
      >
        <Alert onClose={() => setSnackbar({ showSnackbar: false })} severity="error">
          <span>{snackbar.alertMessage}</span>
        </Alert>
      </Snackbar>
    </div>
  );
}

export default AllExchangeRates;
