import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class KuposRadio extends Component {
  static propTypes = {
    checked: PropTypes.bool,
    onChange: PropTypes.func,
    label: PropTypes.string,
  };

  render() {
    return (
      <div className="common-kupos-radio">
        {this.props.disabled ? (
          <span>
            <div className="kupos-radio">
              <span className={this.props.checked ? 'active' : ''}></span>
              {this.props.checked ? (
                <div className="icon-selection-circle-selected"></div>
              ) : (
                <div className="icon-selection-circle-not-selected"></div>
              )}
            </div>
            <span>{this.props.label}</span>
          </span>
        ) : (
          <label>
            <div className="kupos-radio">
              <input
                type="radio"
                checked={this.props.checked}
                onChange={this.props.onChange ? this.props.onChange : () => {}}
              />
              <span className={this.props.checked ? 'active' : ''}></span>
              {this.props.checked ? (
                <div className="icon-selection-circle-selected"></div>
              ) : (
                <div className="icon-selection-circle-not-selected"></div>
              )}
            </div>
            <span>{this.props.label}</span>
          </label>
        )}
      </div>
    );
  }
}
