
import React, { useState, useEffect, useRef } from 'react';
import { KuposErrorSuccessModal, KuposListLoader, KuposModalWithButtons } from '../common';
import ApiUrls from '../../services/apiUrls';
import DateService from '../../services/dateService';
import AddButton from '../common/AddButton';
import ActionButtons from '../common/ActionButtons';
import { useDispatch } from 'react-redux';
import { apiCall } from './redux/apiCall';
import { Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';

const modalInitialState = {
  show: false,
  success: false,
  bodyText: null,
};
const AllVehicles = () => {
  const dispatch = useDispatch();
  const onResponse = useRef(() => { });
  const [fetch, setFetch] = useState(false);
  const [vehiclesList, setVehiclesList] = useState([]);
  const [modal, setModal] = useState(modalInitialState);
  const [selectedViewItem, setSelectedViewItem] = useState('');
  const [loader, setLoader] = useState(true);
  const [snackbar, setSnackbar] = useState({});


  console.log(modal)
  useEffect(() => {
    dispatch(apiCall({ url: ApiUrls.getServices(), callback: res => onResponse.current(res) }));
  }, [dispatch, fetch]);

  onResponse.current = res => {
    setLoader(false);
    if (res && res.data && res.data.success) {
      setVehiclesList(res.data.data);
    } else if (res && res.data && !res.data.success) {
      setSnackbar({
        showSnackbar: true,
        persist: true,
        alertMessage: res.data.message ? res.data.message : 'Failure'
      });
    } else {
      setSnackbar({
        showSnackbar: true,
        persist: true,
        alertMessage: 'Check Your Internet Connection'
      });
    }
  };

  const renderItem = (val, i) => {
    // console.log('value is ', val);
    return (
      <div key={i} className="flex item-row">
        <div className="sm:w-full md:w-1/6 text-center">{val.id}</div>
        <div className="sm:w-full md:w-2/6 text-center">{val.client_code}</div>
        <div className="sm:w-full md:w-3/6 text-center">{val.name}</div>
        <div className="sm:w-full md:w-1/6 text-center">{val.no_of_seats}</div>
        <div className="sm:w-full md:w-2/6 text-center">{val.status}</div>

        <ActionButtons
          val={val}
          view={setSelectedViewItem}
          url={('/vehicles/' + val.id + '/edit')}
          imageUrl={('/service-images/' + val.id)}
          handleDelete={() =>
            setModal({
              showDeleteModal: true,
              selectedItemName: val.name,
              selectedItemId: val.id,
              itemToDelete: val,
            })
          }
        />
      </div>
    );
  };
  const renderViewModal = () => {
    return (
      <KuposModalWithButtons
        size="lg"
        // buttonText1="O"
        // onButtonClick1={this.addSkillPoint}
        showButton1={true}
        showModal={selectedViewItem ? true : false}
        onHide={() => setSelectedViewItem(null)}
      >
        {selectedViewItem && selectedViewItem.id ? (
          <div className="view-item-container">
            <h3 className="text-center font-bold">Servicio</h3>
            <div className="flex view-item-row">
              <div className="sm:w-full md:w-2/6 label font-bold">Nombre:</div>
              <div className="sm:w-full md:w-full">{selectedViewItem.name}</div>
            </div>
            <div className="flex view-item-row">
              <div className="sm:w-full md:w-2/6 label font-bold">Asientos:</div>
              <div className="sm:w-full md:w-full">{selectedViewItem.no_of_seats}</div>
            </div>
            <div className="flex view-item-row">
              <div className="sm:w-full md:w-2/6 label font-bold">Límites de reserva:</div>
              <div className="sm:w-full md:w-full">{DateService.secondsToHms(selectedViewItem.booking_time_threshold)}</div>
            </div>
            <div className="flex view-item-row">
              <div className="sm:w-full md:w-2/6 label font-bold">Amenities:</div>
              <div className="sm:w-full md:w-full">{selectedViewItem.amenities}</div>
            </div>
            <div className="flex view-item-row">
              <div className="sm:w-full md:w-2/6 label font-bold">Restriccions de equipaje :</div>
              <div className="sm:w-full md:w-full">{selectedViewItem.restriction_info}</div>
            </div>
            <div className="flex view-item-row">
              <div className="sm:w-full md:w-2/6 label font-bold">Politicas de cancelacion :</div>
              <div className="sm:w-full md:w-full">{selectedViewItem.cancellation_info}</div>
            </div>
            <div className="flex view-item-row">
              <div className="sm:w-full md:w-2/6 label font-bold">Información adicional :</div>
              <div className="sm:w-full md:w-full">{selectedViewItem.additional_info}</div>
            </div>
            <div className="flex view-item-row">
              <div className="sm:w-full md:w-2/6 label font-bold">Comentarios:</div>
              <div className="sm:w-full md:w-full">{selectedViewItem.remark}</div>
            </div>
            <div className="flex view-item-row">
              <div className="sm:w-full md:w-2/6 label font-bold">Estatus:</div>
              <div className="sm:w-full md:w-full">{selectedViewItem.status}</div>
            </div>
          </div>
        ) : null}
      </KuposModalWithButtons>
    );
  };

  const renderDeleteModal = () => {
    return (
      <KuposModalWithButtons
        size="md"
        buttonText1="No"
        buttonText2="Si"
        onButtonClick1={() => setModal(modalInitialState)}
        onButtonClick2={deleteItem}
        showButton1={true}
        showButton2={true}
        showModal={modal.showDeleteModal}
        onHide={() => setModal(modalInitialState)}
      >
        <div className="create-staff">
          <h4 className="text-center">¿Quieres eliminar "{modal.selectedItemName}"?</h4>
        </div>
      </KuposModalWithButtons>
    );
  };

  const deleteItem = () => {
    let data = {
      id: modal.itemToDelete.id,
      client_code: modal.itemToDelete.client_code,
      name: modal.itemToDelete.name,
      amenities: modal.itemToDelete.amenities,
      restriction_info: modal.itemToDelete.restriction_info,
      cancellation_info: modal.itemToDelete.cancellation_info,
      additional_info: modal.itemToDelete.additional_info,
      status: "ACTIVE",
      no_of_seats: Number(modal.itemToDelete.no_of_seats),
      booking_time_threshold: Number(modal.itemToDelete.booking_time_threshold),
      image: "",
      remark: modal.itemToDelete.remark,
      created_by_user: modal.itemToDelete.created_by_user,
      created_on_utc: modal.itemToDelete.created_on_utc,
      created_date_time_stg: modal.itemToDelete.created_date_time_stg,
      last_updated_user: modal.itemToDelete.last_updated_user,
      last_updated_utc: modal.itemToDelete.last_updated_utc,
      last_updated_date_time_stg: modal.itemToDelete.last_updated_date_time_stg,
      deleted_user: "",
      deleted_utc: DateService.getCurrentTimeUTC(),
      deleted_date_time_stg: DateService.getCurrentStgDate(),
    }
    dispatch(
      apiCall({
        url: ApiUrls.addService(),
        method: 'DELETE',
        data,
        callback: res => onDeleteResponse(res),
      }),
    );
  };

  const onDeleteResponse = res => {
    if (res.data && res.data.success) {
      setModal({ show: true, success: true, bodyText: 'Eliminar éxito' });
      setFetch(!fetch);
    } else {
      setModal({ show: true, success: false, bodyText: res.data.message });
    }

  };

  return (
    <div className="home-all-vehicles">
      <AddButton
        title='Servicios'
        url='/vehicles/create'
        addTitle='Agregar servicio'
      />

      <div className="flex table-heading">
        <div className="sm:w-full md:w-1/6 text-center">ID</div>
        <div className="sm:w-full md:w-2/6 text-center">Operador</div>
        <div className="sm:w-full md:w-3/6 text-center">Servicio</div>
        <div className="sm:w-full md:w-1/6 text-center">Asientos</div>
        <div className="sm:w-full md:w-2/6 text-center">Estatus</div>
        <div className="sm:w-full md:w-3/6 text-center">Acción</div>
      </div>

      {loader ? <KuposListLoader /> : (vehiclesList || []).length > 0
        ? vehiclesList.sort((x, y) => {
          return y.id - x.id;
        }).map((val, i) => renderItem(val, i))
        : null}

      {renderViewModal()}

      {renderDeleteModal()}

      <KuposErrorSuccessModal
        showModal={modal.show}
        success={modal.success}
        smallIcon={true}
        bodyText={modal.bodyText}
        onButtonClick={() => {
          setModal(modalInitialState);
        }}
      />
      <Snackbar
        open={snackbar.showSnackbar}
        autoHideDuration={snackbar.persist ? 10000000000 : 5000}
        anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
        onClose={() => setSnackbar({ showSnackbar: false })}
      >
        <Alert onClose={() => setSnackbar({ showSnackbar: false })} severity="error">
          <span>{snackbar.alertMessage}</span>
        </Alert>
      </Snackbar>
    </div>
  );
}


export default AllVehicles;
