import React, { Component, useEffect, useState, useRef } from 'react';
import { useDispatch } from 'react-redux';
import * as actions from './redux/actions';
import { DropdownComponent } from '../common/DropdownComponent';
import { KuposButton, KuposErrorSuccessModal, KuposInput } from '../common';
import ApiUrls from '../../services/apiUrls';
import CommonService from '../../services/commonService';
import DateService from '../../services/dateService';
import { apiCall } from './redux/apiCall';
import Loader from 'react-loader-spinner';
import { Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';

const initialState = {
  countryError: null,
  locationName: null,
  locationTypes: [
    { value: 'GENERIC_LOCATION', label: 'Generic Location' },
    { value: 'AIRPORT_TERMINAL', label: 'Airport Terminal' },
    { value: 'BUS_TERMINAL', label: 'Bus Terminal' },
  ],
  selectedCountry: '',
};

const modalInitialState = {
  show: false,
  success: false,
  bodyText: '',
};

const errorInitialState = {};

const AddLocation = ({ match, history }) => {
  const dispatch = useDispatch();
  const [values, setValues] = useState(initialState);
  const [modal, setModal] = useState(modalInitialState);
  const [errors, setErrors] = useState(errorInitialState);
  const [locationToEdit, setLocationToEdit] = useState(null);
  const [selectedAtTerminals, setSelectedAtTerminals] = useState([]);
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [loader, setLoader] = useState(true);
  const [snackbar, setSnackbar] = useState({});
  const [count, setCount] = useState('')


  const id = match.params ? Number(match.params.id) : null;

  console.log({ errors, values });

  // redirecting back if data not found using timer
  useEffect(() => {
    let interval
    if (count) {
      interval = setInterval(() => {
        setCount((currentCount) => --currentCount)
      }, 1000);
    }
    // Redirect once the count is === 1
    count === 1 && history.push('/locations');
    // CleanUp
    return () => clearInterval(interval)
  }, [count, history])

  useEffect(() => {
    if (id) {
      getList();
    } else {
      setLoader(false)
    }
    // eslint-disable-next-line
  }, [id]);

  const getList = () => {
    dispatch(
      apiCall({
        url: ApiUrls.getLocationList(), callback: res => {
          setLoader(false)
          if (res && res.data && res.data.success) {
            const found = res.data.data.find(f => f.id === id);
            if (found) {
              console.log({ found });
              getParticular(found);
            } else {
              setSnackbar({
                showSnackbar: true,
                persist: true,
                alertMessage: 'Data not found'
              });
              setCount(5)
            }
          } else if (res && res.data && !res.data.success) {
            setSnackbar({
              showSnackbar: true,
              persist: true,
              alertMessage: res.data.message ? res.data.message : 'Failure'
            });
          } else {
            setSnackbar({
              showSnackbar: true,
              persist: true,
              alertMessage: 'Check Your Internet Connection'
            });
          }
        }
      })
    )
  };

  const getParticular = (element) => {
    setLocationToEdit(element);
    setValues({
      locationName: element.name,
      selectedLocationType: { label: element.type, value: element.type },
      selectedCountry: { value: element.country_id, label: element.country_name },
      selectedState: { value: element.state_id, label: element.Sate_name },
      selectedCity: { value: element.city_id, label: element.city_name },
      address: element.name,
      locationRemark: element.remark,
      // selectedLocationType: {value:element.type,lable:element.type}
    });
  }


  useEffect(() => {
    dispatch(
      apiCall({
        url: ApiUrls.getAtTerminalList(),
        callback: res => {
          if (res.data && res.data.success && res.data.data) {
            let atTerminals = [];
            res.data.data.map(atTerminal => {
              atTerminals.push({
                value: atTerminal.airport_address,
                label: atTerminal.airport_name,
              });
            });
            setSelectedAtTerminals(atTerminals);
          }
        },
      }),
    );

    // eslint-disable-next-line
  }, [dispatch]);

  const onSelectChange = (val, type) => {

    console.log("onchange",val, type)
    switch (type) {
      case 'countryName':
        setValues({ ...values, selectedCountry: val });
        getStatesByCountryID(val.value);
        break;
      case 'stateName':
        setValues({ ...values, selectedState: val });
        getCitiesByStateID(val.value);
        break;
      case 'cityName':
        setValues({ ...values, selectedCity: val });
        break;
      case 'locationType':
        // setValues({ ...values, selectedLocationType: val });
        // break;
        val.value == 'AIRPORT_TERMINAL'
          ? setValues({ ...values, selectedLocationType: val })
          : setValues({ ...values, selectedLocationType: val, locationName: null, address: '' });
        break;
    }
  };

  const onChange = (val, type) => {
    console.log("onchange---",val, type)
    switch (type) {
      case 'locationName':
        values.selectedLocationType && values.selectedLocationType.value == 'AIRPORT_TERMINAL'
          ? setValues({ ...values, locationName: val, address: val.value })
          : setValues({ ...values, locationName: val });
        break;
      case 'address':
        setValues({ ...values, address: val });
        break;
      case 'locationRemark':
        setValues({ ...values, locationRemark: val });
        break;
    }
  };

  const onBlur = (val, type) => { };

  const validate = () => {
    let error = 0;
    setErrors(initialState);

    if (!values.selectedLocationType) {
      setErrors({ ...errors, selectedLocationTypeError: 'select a location', addressError: null });
      error++;
      return false;
    } else if (!values.locationName) {
      setErrors({ ...errors, locationNameError: 'Ingrese el nombre de la ubicación' });
      error++;
      return false;
    } else if (!values.selectedCountry) {
      setErrors({ ...errors, selectedCityError: 'select a country' });
      error++;
      return false;
    } else if (!values.selectedState) {
      setErrors({ ...errors, selectedStateError: 'select a state' });
      error++;
      return false;
    } else if (!values.selectedCity) {
      setErrors({ ...errors, selectedCityError: 'select a city' });
      error++;
      return false;
    } else if (!values.locationRemark) {
      setErrors({ ...errors, locationRemarkError: 'Ingrese el comentario de ubicación' });
      error++;
      return false;
    } else if (!values.address) {
      if (
        values.selectedLocationType.value == 'AIRPORT_TERMINAL' ||
        values.selectedLocationType.value == 'BUS_TERMINAL'
      ) {
        setErrors({ ...errors, addressError: 'Address can not be empty' });
        error++;
        return false;
      } else if (values.selectedLocationType.value == 'GENERIC_LOCATION') {
        setErrors({ ...errors, addressError: null });
      }
    }
    if (error > 0) {
      return false;
    } else {
      return true;
    }
  };

  const createOrUpdate = () => {
    if (!validate()) {
      return;
    }
    setLoader(true);
    let updateData, data;

    id
      ? (updateData = {
        id: locationToEdit.id,
        client_code: locationToEdit.client_code,
        type: locationToEdit.type,
        name: locationToEdit.name,
        city_id: locationToEdit.city_id,
        city_name: locationToEdit.city_name,
        state_id: locationToEdit.state_id,
        Sate_name: locationToEdit.Sate_name,
        country_id: locationToEdit.country_id,
        country_name: locationToEdit.country_name,
        address: values.address,
        status: locationToEdit.status,
        remark: values.locationRemark,
        created_on_utc: locationToEdit.created_on,
        created_by_user: locationToEdit.created_by,
        created_on_date_time_stg: locationToEdit.created_on_date_time_stg,
        last_updated_by_user: '',
        last_updated_on_utc: DateService.getCurrentTimeUTC(),
        last_updated_on_date_time_stg: DateService.getCurrentStgDate(),
        deleted_by: '',
        deleted_on_utc: 0,
        deleted_on_date_time_stg: '',
      })
      : (data = {
        type: values.selectedLocationType.label,
        name:
          values.selectedLocationType && values.selectedLocationType.value == 'AIRPORT_TERMINAL'
            ? values.locationName.label
            : values.locationName,
        city_id: values.selectedCity.value,
        city_name: values.selectedCity.label,
        state_id: values.selectedState.value,
        Sate_name: values.selectedState.label,
        country_id: values.selectedCountry.value,
        country_name: values.selectedCountry.label,
        address:
          values.selectedLocationType && values.selectedLocationType.value == 'AIRPORT_TERMINAL'
            ? values.locationName.value
            : values.address,
        remark: values.locationRemark,
        created_on_utc: DateService.getCurrentTimeUTC(),
        created_on_date_time_stg: DateService.getCurrentStgDate(),
        //  client_code:"DELFOS"
      });


    dispatch(
      apiCall({
        url: ApiUrls.addLocation(),
        method: id ? 'PUT' : 'POST',
        data: id ? updateData : data,
        callback: res => onAddLocationResponse(res),
      }),
    );
  };

  const onAddLocationResponse = res => {
    setLoader(false);
    if (res.data && res.data.success && res.data.data) {
      setModal({
        show: true,
        success: true,
        redirect: true,
        bodyText: 'Éxito' || (id ? 'Éxito' : 'Éxito'),
      });
    } else if (!res.data.success && res.data.message) {
      setModal({ show: true, success: false, bodyText: res.data.message });
    } else {
      setModal({ show: true, success: false, bodyText: 'Fail' });
    }
  };

  useEffect(() => {
    dispatch(
      apiCall({
        url: ApiUrls.addCountry(),
        callback: res => {
          if (res.data && res.data.success && res.data.data) {
            let countries = [];
            res.data.data.map(country => {
              countries.push({ value: country.id, label: country.name });
            });
            setCountries(countries);
          }
        },
      }),
    );

    // eslint-disable-next-line
  }, [dispatch]);


  useEffect(() => {
    dispatch(
      apiCall({
        url: ApiUrls.addState(),
        callback: res => {
          if (res.data && res.data.success && res.data.data) {
            let states = [];
            res.data.data.map(state => {
              states.push({ value: state.id, label: state.name });
            });
            setStates(states);
          }
        },
      }),
    );

    // eslint-disable-next-line
  }, [dispatch]);

  // useEffect(() => {
  //   dispatch(
  //     apiCall({
  //       url: ApiUrls.addState(),
  //       callback: res => {
  //         if (res.data && res.data.success && res.data.data) {
  //           let cities = [];
  //           res.data.data.map(city => {
  //             cities.push({ value: city.id, label: city.name });
  //           });
  //           setCities(cities);
  //         }
  //       },
  //     }),
  //   );

  //   // eslint-disable-next-line
  // }, [dispatch]);

  const getStatesByCountryID = id => {
    dispatch(
      apiCall({
        url: ApiUrls.addState(id),
        callback: res => {
          if (res.data && res.data.success && res.data.data) {
            let states = [];
            res.data.data.map(state => {
              states.push({ value: state.id, label: state.name });
            });
            setStates(states);
          }
        },
      }),
    );
  };

  const getCitiesByStateID = id => {
    // this.props.actions.apiCall({ url: ApiUrls.addCity(id) }).then(res => {
    //   console.log('+++cities id is', res.data.data);
    //   if (res.data && res.data.success) {
    //     let cities = [];
    //     res.data.data.map(city => {
    //       cities.push({ value: city.id, label: city.name });
    //     });
    //     this.setState({ cities: cities });
    //   }
    // });

    // useEffect(() => {
  //   dispatch(
  //     apiCall({
  //       url: ApiUrls.addState(),
  //       callback: res => {
  //         if (res.data && res.data.success && res.data.data) {
  //           let cities = [];
  //           res.data.data.map(city => {
  //             cities.push({ value: city.id, label: city.name });
  //           });
  //           setCities(cities);
  //         }
  //       },
  //     }),
  //   );

  //   // eslint-disable-next-line
  // }, [dispatch]);


    dispatch(
      apiCall({
        url: ApiUrls.addCity(id),
        callback: res => {
          if (res.data && res.data.success && res.data.data) {
            let cities = [];
            res.data.data.map(city => {
              cities.push({ value: city.id, label: city.name });
            });
            setCities(cities);
          }
        },
      }),
    );
  };

  return (
    <div className="home-add-location">
      <div style={{ marginTop: 10 }}>
        <DropdownComponent
          onChange={val => onSelectChange(val, 'locationType')}
          placeholder={'Tipo de locación '}
          options={initialState.locationTypes}
          value={values.selectedLocationType}
          disabled={id ? true : false}
          error={errors.selectedLocationTypeError ? true : false}
          errorMessage={errors.selectedLocationTypeError ? errors.selectedLocationTypeError : ''}
        />
      </div>

      {values.selectedLocationType && values.selectedLocationType.value == 'AIRPORT_TERMINAL' ? (
        <div style={{ marginTop: 10 }}>
          <DropdownComponent
            onChange={val => onChange(val, 'locationName')}
            placeholder={'Nombre del lugar'}
            options={selectedAtTerminals}
            value={id ? selectedAtTerminals : values.locationName}
            disabled={id ? true : false}
            error={errors.locationNameError ? true : false}
            errorMessage={errors.locationNameError ? errors.locationNameError : ''}
          />
        </div>
      ) : (
        <div className="common-kupos-input-box" style={{ marginTop: 10 }}>
          <KuposInput
            type="text"
            className="kupos-border"
            placeholder={'Nombre del lugar'}
            value={values.locationName}
            disabled={id ? true : false}
            error={errors.locationNameError ? true : false}
            errorMessage={errors.locationNameError ? errors.locationNameError : ''}
            onChange={text => onChange(text, 'locationName')}
            onBlur={text => onBlur(text, 'locationName')}
          />
        </div>
      )}

      <div
        className="home-selection-input-box font10 select-box"
        style={{ marginTop: 10, marginBottom: 10 }}
      >
        <DropdownComponent
          onChange={val => onSelectChange(val, 'countryName')}
          placeholder={'Seleccione el país'}
          disabled={id ? true : false}
          options={countries}
          value={values.selectedCountry}
          error={errors.selectedCountryError ? true : false}
          errorMessage={errors.selectedCountryError ? errors.selectedCountryError : null}
        />
      </div>
      <div className="home-selection-input-box font10 select-box" style={{ marginTop: 10 }}>
        <DropdownComponent
          onChange={val => onSelectChange(val, 'stateName')}
          placeholder={'Seleccione la región'}
          options={states}
          value={values.selectedState}
          disabled={id ? true : false}
          error={errors.selectedStateError ? true : false}
          errorMessage={errors.selectedStateError ? errors.selectedStateError : ''}
        />
      </div>
      <div
        className="home-selection-input-box font10 select-box"
        style={{ marginTop: 10, marginBottom: 10 }}
      >
        <DropdownComponent
          onChange={val => onSelectChange(val, 'cityName')}
          placeholder={'Seleccione la ciudad'}
          options={cities}
          value={values.selectedCity}
          disabled={id ? true : false}
          error={errors.selectedCityError ? true : false}
          errorMessage={errors.selectedCityError ? errors.selectedCityError.message : ''}
        />
      </div>

      <div className="common-kupos-input-box" style={{ marginTop: 10 }}>
        <KuposInput
          type="text"
          className="kupos-border"
          placeholder={'Dirección'}
          // value={this.state.address}
          value={
            values.selectedLocationType &&
              values.selectedLocationType.value == 'AIRPORT_TERMINAL' &&
              values.locationName
              ? values.locationName.value
              : values.address
          }
          error={errors.addressError ? true : false}
          errorMessage={errors.addressError ? errors.addressError : ''}
          onChange={text => onChange(text, 'address')}
          onBlur={text => onBlur(text, 'address')}
        />
      </div>

      <div className="common-kupos-input-box" style={{ marginTop: 10 }}>
        <KuposInput
          type="text"
          className="kupos-border"
          placeholder={'Observación de ubicación'}
          value={values.locationRemark}
          error={errors.locationRemarkError ? true : false}
          errorMessage={errors.locationRemarkError ? errors.locationRemarkError : ''}
          onChange={text => onChange(text, 'locationRemark')}
          onBlur={text => onBlur(text, 'locationRemark')}
        />
      </div>

      <div className="button-container" style={{ marginTop: 20 }}>
        <KuposButton onClick={createOrUpdate}>
          <span className="font11">{id ? 'Actualizar ubicación' : 'Crear ubicación'}</span>
        </KuposButton>
      </div>

      <KuposErrorSuccessModal
        success={modal.success}
        showModal={modal.show}
        onButtonClick={() => {
          setModal(modalInitialState);
          if (modal.redirect) {
            history.goBack();
          }
        }}
        bodyText={modal.bodyText}
      />

      {loader ? (
        <div className="loader-container">
          <Loader visible={loader} type="Bars" color="#637290" height={80} width={80} />
        </div>
      ) : null}
      <Snackbar
        open={snackbar.showSnackbar}
        autoHideDuration={snackbar.persist ? 10000000000 : 5000}
        anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
        onClose={() => setSnackbar({ showSnackbar: false })}
      >
        <Alert onClose={() => setSnackbar({ showSnackbar: false })} severity="error">
          <span>{snackbar.alertMessage} </span>
          {count
            &&
            `we are redirecting you back in ${count} seconds`
          }
        </Alert>
      </Snackbar>
    </div>
  );
};

export default AddLocation
