import Axios from 'axios';
import CommonService from '../../../services/commonService';
import {
  HOME_GET_LOCATION_AREAS_FROM_LOCATION_BEGIN,
  HOME_GET_LOCATION_AREAS_FROM_LOCATION_SUCCESS,
  HOME_GET_LOCATION_AREAS_FROM_LOCATION_FAILURE,
  HOME_GET_LOCATION_AREAS_FROM_LOCATION_DISMISS_ERROR,
} from './constants';

export function getLocationAreasFromLocation(args = {}) {
  return (dispatch) => { // optionally you can have getState as the second argument
    dispatch({
      type: HOME_GET_LOCATION_AREAS_FROM_LOCATION_BEGIN,
    });

    console.log("Args passed to api are", args)

    const promise = new Promise((resolve, reject) => {
      const doRequest = Axios.get(`${args.url}/${args.id}`,{ headers: CommonService.getHeaders()});
      doRequest.then(
        (res) => {
          dispatch({
            type: HOME_GET_LOCATION_AREAS_FROM_LOCATION_SUCCESS,
            data: res.data,
          });
          resolve(res);
        },
        // Use rejectHandler as the second argument so that render errors won't be caught.
        (err) => {
          dispatch({
            type: HOME_GET_LOCATION_AREAS_FROM_LOCATION_FAILURE,
            data: { error: err },
          });
          reject(err);
        },
      );
    });

    return promise;
  };
}

export function dismissGetLocationAreasFromLocationError() {
  return {
    type: HOME_GET_LOCATION_AREAS_FROM_LOCATION_DISMISS_ERROR,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case HOME_GET_LOCATION_AREAS_FROM_LOCATION_BEGIN:
      // Just after a request is sent
      return {
        ...state,
        getLocationAreasFromLocationPending: true,
        getLocationAreasFromLocationError: null,
      };

    case HOME_GET_LOCATION_AREAS_FROM_LOCATION_SUCCESS:
      // The request is success
      return {
        ...state,
        getLocationAreasFromLocationPending: false,
        getLocationAreasFromLocationError: null,
        locationAreasFromLocation: action.data
      };

    case HOME_GET_LOCATION_AREAS_FROM_LOCATION_FAILURE:
      // The request is failed
      return {
        ...state,
        getLocationAreasFromLocationPending: false,
        getLocationAreasFromLocationError: action.data.error,
      };

    case HOME_GET_LOCATION_AREAS_FROM_LOCATION_DISMISS_ERROR:
      // Dismiss the request failure error
      return {
        ...state,
        getLocationAreasFromLocationError: null,
      };

    default:
      return state;
  }
}
