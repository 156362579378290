import React from 'react';

const TransactionsPage = () => {

  const renderTwoItems = (label, value) => {
    return (
      <div className="two-items font10">
        <span className="bold-text label">{label}</span>
        <span>{value}</span>
      </div>
    );
  };

  return (
    <div className="home-transactions-page">
      <div>
        <span className="bold-text font12">Historial de pasaje</span>
      </div>
      {/* 21481 Boleto N° 1: Impreso por Owner Dirección IP: 64.252.175. */}
      <div className="transaction-body">
        <div className="basic-ticket-details">
          <div className="font10">
            <span className="bold-box">21481</span>
            <span>Boleto N° 1: Impreso por Owner</span>
          </div>
          <div className="small-items">
            <div>{renderTwoItems('Dirección IP: ', '64.252.175.69')}</div>
            <div>{renderTwoItems('Nombre de sucursal:', 'Venta Psb')}</div>
            <div>{renderTwoItems('Compra realizada por: ', 'Owner')}</div>
            <div>{renderTwoItems('Fecha - Hora: ', '19/10/2020 - 17:11 PM')}</div>
          </div>
        </div>
        <div className="seperator-bottom-container">
          <div className="seperator-bottom"></div>
        </div>
        <div className="rest-ticket-details">
          <div className="font10">
            <span className="bold-box">21481</span>
            <span>
              Pasaje comprado <span>{'>'}</span> Asiento N° 1<span>{'>'}</span> En servicio
              Santiago - Aeropuerto (Santiago - Aeropuerto) <span>{'>'}</span> 19/10/2020'
            </span>
          </div>
          <div className="small-items">
            <div>
              {renderTwoItems(
                'Detalles del pasajero: ',
                'Marcela Guzmán - 9 5248 7893 - N° 1 (Nombre: Ms Marcela Guzmán, Edad: 0, Tipo: RUT, N°: 23.877.346-6 Etapa ',
              )}
            </div>
            <div>
              {renderTwoItems('del embaque: ', 'Santiago - 23:00 PM (Día 1)')}
            </div>
            <div>
              {renderTwoItems(
                'Dirección IP: ',
                '64.252.175.69, 186.10.37.41, 64.252.175.69',
              )}
            </div>
            <div>{renderTwoItems('Nombre de sucursal: ', 'Venta Psb')}</div>
            <div>{renderTwoItems('Booking source: ', 'Branch booking')}</div>
            <div>{renderTwoItems('Compra realizada por: ', 'Owner')}</div>
            <div>{renderTwoItems('Fecha - Hora: ', '19/10/2020 - 17:11 PM')}</div>
          </div>
        </div>

        <div className="searches-found-section">
          <span className="font10">2 encontrados</span>
        </div>
      </div>
    </div>
  );
}


export default TransactionsPage;