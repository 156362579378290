import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import CommonService from '../../services/commonService';
import { Aside, KuposButton, KuposModalWithButtons } from '../common';
import DateService from '../../services/dateService';
import ApiUrls from '../../services/apiUrls';
import { setLoginData } from '../common/redux/setLoginData'
import { setClientInfo } from '../common/redux/setClientInfo'
import { apiCall } from './redux/apiCall';

const App = (props) => {
  const dispatch = useDispatch()
  const [aside, setAside] = useState({ toggled: false, collapsed: true })
  const [loginTime, setLoginTime] = useState()
  const [showLogoutModal, setShowLogoutModal] = useState(false)

  useEffect(() => {
    let currentUser = localStorage.getItem('currentUser');
    setLoginTime(localStorage.getItem('loginTime'))
    if (currentUser) {
      let currentUserJson = JSON.parse(currentUser);
      if (currentUserJson.access_token) {
        CommonService.authToken = currentUserJson.access_token;
        dispatch(setLoginData(currentUserJson));
        // if (window.location.href.indexOf('login') > -1) {
        //   this.props.history.push('/');
        // }
      }
    }
    getClientInfo();
    //eslint-disable-next-line
  }, [])


  const getClientInfo = () => {
    dispatch(apiCall({ url: ApiUrls.getClientInfo(window.location.host), callback: res => onClientResponse(res) }))
  };

  const onClientResponse = (res) => {
    console.log('client res api', res);
    if (res && res.data && res.data.success) {
      dispatch(setClientInfo(res.data.data));
      // CommonService.setLocal('clientInfo',res.data.data)
    }
  }

  const renderLogoutModal = () => {
    return (
      <KuposModalWithButtons
        size="md"
        type="alert"
        title="¿Esta seguro de que desea cerrar sesión?"
        showModal={showLogoutModal}
        onHide={() => setShowLogoutModal(false)}
        buttonText1="No"
        onButtonClick1={() => setShowLogoutModal(false)}
        showButton1={true}
        buttonText2="Si"
        onButtonClick2={logoutUser}
        showButton2={true}
      ></KuposModalWithButtons>
    );
  };

  const logoutUser = () => {
    let currentUser = CommonService.getLocal('currentUser');
    let currentUserFull = CommonService.getLocal('currentUserFull');
    let counter = CommonService.getLocal('counter');
    let currentTime = DateService.getCurrentTimeUTC();

    console.log('Current user----', currentUser);
    console.log('counter is---', counter);
    let data = {
      login_id: currentUser.user_details.email, // Id used for login ( email ) 
      login_info_ref_id: currentUserFull.login_info_ref_id, // Will get this in login API response ( login_info_ref_id )
      counter_txn_id: counter != undefined ? counter.counter_txn_id : 0, // For Counter Users, client will get this while counter_open, Other Users send 0
      logout_unix_time: currentTime,
      logout_date_stg: DateService.getTodayString('yyyy-mm-dd'),
      logout_time_stg: `${DateService.getCurrentHour()}:${DateService.getCurrentMinute()}`,
    };

    console.log('Data to fly ----', data);

    dispatch(apiCall({ url: ApiUrls.logout(), data: data, method: 'POST', callback: res => onLogoutResponse(res) }))

  };

  const onLogoutResponse = res => {
    console.log('res from logout is----', res);
    if (res && res.data && res.data.success) {
      CommonService.logout();
      window.location.href = '/';
    }
  };

  const handleToggleSidebar = () => setAside({ toggled: !aside.toggled, collapsed: !aside.collapsed })


  console.log('props in app js ______________', props);
  // var isActive = this.context.router.route.location.pathname === this.props.to;
  var pathName = props.location.pathname;
  // var className = isActive ? 'active-link' : '';
  return (
    <div className="home-app">
      <div className="flex"
        style={{ display: 'flex' }}
      // flex-col md:flex-row"
      >
        <Aside
          image
          collapsed={aside.collapsed}
          toggled={aside.toggled}
          handleToggleSidebar={handleToggleSidebar}
        />


        <div className="main-content flex-1 px-4 ">
          <div className="page-container">
            {CommonService.authToken ? (
              <div className="flex-row space-between align-center nav-bar nav-bar-style pt-3">
                <div>
                  <img
                    style={{ maxHeight: 40, width: 'auto' }}
                    // src={(props.common && props.common.clientInfo && props.common.clientInfo.logo_path) ||
                    //   '/images/kupos-logo2.png'}
                    src={'/images/kupos-logo2.png'}
                  />
                </div>
                <div className="name-and-logout">
                  {props.location &&
                    props.location.pathname.includes('/counter-dashboard') ? (
                    <div className="mr-4">
                      <KuposButton
                        onClick={() => props.history.push('/shift-summary-report')}
                      >
                        Cerrar caja
                      </KuposButton>
                    </div>
                  ) : null}
                  <div>
                    <div className="font11 bold">Bienvenido {CommonService.userName()}</div>
                    <div className="font10">
                      {DateService.changeDateFormat(
                        CommonService.loginTime().split('T')[0],
                        'dd-mm-yyyy',
                        'dd/mm/yyyy',
                      )}{' '}
                      -{' '}
                      {DateService.convertTime12to24New(CommonService.loginTime().split('T')[1]) +
                        DateService.ampmOnly(CommonService.loginTime().split('T')[1])}
                    </div>
                  </div>
                  <div
                    className="logout-button pointer"
                    onClick={() => setShowLogoutModal(true)}
                  >
                    <img src="/images/logout.png" />
                  </div>
                </div>
              </div>
            ) : null}
            {props.children}
          </div>
        </div>
      </div>
      {renderLogoutModal()}
    </div>
  );
}

export default App;
