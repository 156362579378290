import { HOME_SET_SEARCHED_TICKET_DETAILS } from './constants';

export function setSearchedTicketDetails(data) {
  return {
    type: HOME_SET_SEARCHED_TICKET_DETAILS,
    data: data,
  };
}
export function reducer(state, action) {
  // console.log("Dummy data action", action)
  // console.log('Dummy data state', state);
  switch (action.type) {
    case HOME_SET_SEARCHED_TICKET_DETAILS:
      return {
        ...state,
        searchedTicketDetails: action.data,
      };

    default:
      return state;
  }
}
