import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { DropdownComponent, KuposButton, KuposErrorSuccessModal, KuposInput } from '../common';
import ApiUrls from '../../services/apiUrls';
import { apiCall } from './redux/apiCall';
import Loader from 'react-loader-spinner';

const modalInitialState = {
  show: false,
  success: false,
  bodyText: null,
};
const initialState = {
  cityName: '',
  remark: '',
};

const errorInitialState = {};

const AddCity = ({ history, match }) => {

  const dispatch = useDispatch();
  const [values, setValues] = useState(initialState);
  const [modal, setModal] = useState(modalInitialState);
  const [states, setStates] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [cityToEdit, setCityToEdit] = useState(null);
  const [loader, setLoader] = useState(false);
  const [selectedState, setSelectedState] = useState('');
  const [errors, setErrors] = useState(errorInitialState);
  const [fetch, setFetch] = useState('');

  const id = match.params ? Number(match.params.id) : null;

  console.log("+++selected state is ", selectedState)
  useEffect(() => {
    if (id) {
      getCityList();
    } else {
      setLoader(false)
    }
    // eslint-disable-next-line
  }, [id, fetch])


  useEffect(() => {
    dispatch(apiCall({
      url: ApiUrls.addState(),
      callback: res => {
        if (res.data && res.data.success) {

          let states = [];
          res.data.data.map(state => {
            states.push({ value: state.id, label: state.name });
          });

          setStates(states)
        }
      }
    }))
  }, [dispatch, fetch])


  const getCityList = () => {
    dispatch(apiCall({
      url: ApiUrls.addCity(),
      callback: res => {
        if (res.data && res.data.success) {
          setCityList(res.data.data)

          if (id)
            setTimeout(() => {
              getParticularCity(id, res.data.data);
            }, 1000);
        }
      }
    }))
  }

  const getParticularCity = (id, cities) => {
    cities.forEach(element => {
      if (element.id == id) {

        setCityToEdit(element);
        setValues({
          cityName: element.name,
          remark: element.remark,
        })

        states &&
          states.map(val => {
            if (val.value === element.state_id) {
              setSelectedState(val)
            }
          });
      }
    });
  };

  const onChange = (val, type) => {
    switch (type) {
      case 'city_name':
        setValues({ ...values, cityName: val })
        break;
      case 'remark':
        setValues({ ...values, remark: val })
        break;
    }
  };


  const onStateChange = val => {
    setSelectedState(val)
  };

  const onBlur = () => { };

  const validate = () => {

    let error = 0;
    setErrors(initialState);

    if (!selectedState) {
      setErrors({ ...errors, selectedStateError: 'Seleccione la país' });
      error++;
      return false;
    }
    if (!values.cityName) {
      setErrors({ ...errors, cityNameError: 'Ingrese un Nombre de la ciudad' });
      error++;
      return false;
    }
    if (!values.remark) {
      setErrors({ ...errors, cityRemarkError: 'Ingrese un Comentarios' });
      error++;
      return false;
    }

    if (error > 0) {
      return false;
    } else {
      return true;
    }

  };

  const createOrUpdate = () => {
    if (!validate()) {
      return;
    }
    setLoader(true)
    let updateData, data;

    id
      ? (updateData = {
        id: cityToEdit.id,
        state_id: cityToEdit.state_id,
        name: values.cityName,
        status: cityToEdit.status,
        remark: values.remark,
        created_on: cityToEdit.created_on,
        created_by: cityToEdit.created_by,
        last_updated_on: cityToEdit.last_updated_on,
        last_updated_by: cityToEdit.last_updated_by,
      })
      : (data = {
        name: values.cityName,
        state_id: selectedState.value,
        remark: values.remark,
      });

    dispatch(
      apiCall({
        url: ApiUrls.addCity(),
        method: id ? 'PUT' : 'POST',
        data: id ? updateData : data,
        callback: res => onAddCityResponse(res),
      }),
    );
  };

  const onAddCityResponse = res => {
    if (res.data && res.data.success && res.data.data) {
      setLoader(false)
      setModal({
        show: true,
        success: true,
        redirect: true,
        bodyText: 'Éxito' || (id ? 'Éxito' : 'Éxito'),
      });
    } else if (!res.data.success && res.data.message) {
      setLoader(false)
      setModal({ show: true, success: false, bodyText: res.data.message });
    } else {
      setLoader(false)
      setModal({ show: true, success: false, bodyText: 'Fail' });
    }
  };

  return (
    <div className="home-add-location">

      <div className="home-selection-input-box font10 select-box" style={{ marginTop: 10 }}>
        <DropdownComponent
          onChange={val => onStateChange(val)}
          placeholder={'Seleccione la región'}
          options={states}
          value={selectedState}
          error={errors.selectedStateError ? true : false}
          errorMessage={
            errors.selectedStateError ? errors.selectedStateError : null
          }
        />
      </div>

      <div className="common-kupos-input-box" style={{ marginTop: 10 }}>
        <KuposInput
          type="text"
          className="kupos-border"
          placeholder={'Nombre de la ciudad'}
          value={values.cityName}
          error={errors.cityNameError ? true : false}
          errorMessage={errors.cityNameError ? errors.cityNameError : ''}
          onChange={text => onChange(text, 'city_name')}
          onBlur={text => onBlur(text, 'city_name')}
        />
      </div>

      <div className="common-kupos-input-box" style={{ marginTop: 10 }}>
        <KuposInput
          type="text"
          className="kupos-border"
          placeholder={'Comentarios'}
          value={values.remark}
          error={errors.cityRemarkError ? true : false}
          errorMessage={errors.cityRemarkError ? errors.cityRemarkError.message : ''}
          onChange={text => onChange(text, 'remark')}
          onBlur={text => onBlur(text, 'remark')}
        />
      </div>

      <div className="button-container" style={{ marginTop: 20 }}>
        <KuposButton onClick={createOrUpdate}>
          <span className="font11">
            {id ? 'Actualizar ciudad' : 'Crear ciudad'}
          </span>
        </KuposButton>
      </div>

      <KuposErrorSuccessModal
        success={modal.success}
        showModal={modal.show}
        onButtonClick={() => {
          setModal(modalInitialState);
          if (modal.redirect) {
            history.goBack();
          }
        }}
        bodyText={modal.bodyText}
      />

      {loader ? (
        <div className="loader-container">
          <Loader visible={loader} type="Bars" color="#637290" height={80} width={80} />
        </div>
      ) : null}
    </div>
  );
}


export default AddCity;
