import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import ApiUrls from '../../../services/apiUrls';
import CommonService from '../../../services/commonService';
import { KuposButton, KuposInput, KuposSteps } from '../../common';
import { DropdownComponent } from '../../common/DropdownComponent';
import { StripDateFilter } from '../../common/StripDateFilter';
import { apiCall } from '../redux/apiCall';
import CustomerDetails from './CustomerDetails';

const RenderResult = ({
  // Props start
  values,
  setValues,
  ticketData,
  selectedService,
  setSelectedService,
  setCallApiAgain,
  setSnackbar,
  metaData,

  // Props end
}) => {
  const dispatch = useDispatch();
  const [selectedServiceId, setSelectedServiceId] = useState(null);
  const [additionalFares, setAdditionalFares] = useState(null);

  useEffect(() => {
    getAdditionalFareList();
    getExchangeList();
    // eslint-disable-next-line
  }, []);

  console.log('additional fares ', additionalFares);

  const getAdditionalFareList = () => {
    dispatch(
      apiCall({
        url: ApiUrls.getAddtionalFareList(),
        callback: res => {
          console.log('Additional fare', res);
          if (res.data && res.data.success) {
            const data = res.data.data.map(val => {
              return { value: val.value, label: val.value };
            });
            setAdditionalFares(data);
          }
        },
      }),
    );
  };

  const getExchangeList = () => {
    dispatch(
      apiCall({
        url: ApiUrls.addCurrencyExchange(),
        callback: res => {
          console.log('Currency Exchange', res);

          if (res && res.data && res.data.success) {
            let exchanges = [];
            exchanges.push({
              value: 1,
              label: 'CLP',
              rate: 1,
              from: 'CLP',
              to: 'CLP',
            });
            res.data.data.map(val => {
              exchanges.push({
                value: val.id,
                label: val.exchange_name.split('_TO_')[1],
                rate: val.exchange_rate,
                from: val.from_currency_code,
                to: val.to_currency_code,
              });
            });

            setValues({
              ...values,
              exchangeListData: res.data.data,
              exchanges,
              selectedCurrency: {
                value: 1,
                label: 'CLP',
                rate: 1,
                from: 'CLP',
                to: 'CLP',
              },
            });
          }
        },
      }),
    );
  };

  const getTotalPrice = (serviceType, priceType, price, serviceSeats) => {

    return priceType === 'PER_VEHICLE' ? price : price*values.ChoosenKupos;

  };

  const getBookingPrice = (serviceType, priceType, price, serviceSeats) => {
    // console.log('Price Type is', priceType);
    // return priceType === 'PER_VEHICLE' ? price * serviceSeats : price;
    return priceType === 'PER_VEHICLE' ? price : price;
  };

  const selectSeat = item => {
    console.log("onselectservice-------",item)
    setSelectedService(item);
    setSelectedServiceId(item.id);
  };

  const onStripDateClick = date => {
    setValues({ ...values, selectedDate: date });
    setTimeout(() => {
      setCallApiAgain(true);
    }, 1000);
  };

  const renderService = (service, key) => {
    // console.log('Service is-----------------', service);
    return (
      <div className="service-row font10">
        <div className="service-icon-container">
          {/* {this.getVehicleImage(service.service_type)} */}

          {service.service_type === 'Pav Van Compartida' ? (
            <img src="/images/cars/van_shared.png" />
          ) : (
            <img src="/images/cars/taxi_exclusive.png" />
          )}
        </div>
        <div>
          <div className="bold-text">
            {service.service_type && CommonService.capitalize(service.service_type)}
          </div>
          <div className="second-line">
            Pasajeros: <span>1 - {service.seat_capacity}</span>
          </div>
        </div>

        <div>
          <div>{service.price_type === 'PER_VEHICLE' ? 'PRECIO' : 'PRECIO POR CUPO'} </div>
          <div className="bold-text second-line">
            CPL${' '}
            <span>
              {CommonService.currency(
                getBookingPrice(
                  service.service_type,
                  service.price_type,
                  service.price,
                  service.seat_capacity,
                ),
              )}
            </span>
          </div>
        </div>

        <div>
          <div>CUPOS</div>
          <div className="bold-text second-line">
            <span>{values.ChoosenKupos}</span> Pasajeros
          </div>
        </div>

        <div>
          <div>PRECIO TOTAL </div>
          <div className="bold-text second-line">
            CLP${' '}
            <span>
              {CommonService.currency(
                getTotalPrice(
                  service.service_type,
                  service.price_type,
                  service.price,
                  service.seat_capacity,
                  service
                ),
                
              )}
              {/* {service.price_type === 'PER_VEHICLE' ? service.price : service.price * values.ChoosenKupos} */}
            </span>
          </div>
        </div>

        <div className="search-button-container">
          <KuposButton
            className={
              selectedService && selectedServiceId === service.id
                ? 'search-button-selected'
                : 'search-button'
            }
            style={
              selectedService && selectedServiceId === service.id
                ? { backgroundColor: '#637290' }
                : { backgroundColor: '#ccc' }
            }
            onClick={() => selectSeat(service)}
          >
            <span className="upper-case-text">comprar</span>
          </KuposButton>
        </div>
      </div>
    );
  };

  // ----------
  const onStepClick = menuItem => {
    console.log("+++menu item is",values.stepsMenu[menuItem - 1])
    // setValues({ ...values, selectedPaymentType: values.stepsMenu[menuItem - 1] });

    let elementsIndex = menuItem - 1;

    let newMenus = [...values.stepsMenu];

    newMenus.forEach((item, key) => {
      newMenus[key] = {
        ...newMenus[key],
        active: false,
      };
    });

    newMenus[elementsIndex] = {
      ...newMenus[elementsIndex],
      active: !newMenus[elementsIndex].active,
    };

    // console.log({ newMenus });
    setValues({ ...values, stepsMenu: newMenus , selectedPaymentType: values.stepsMenu[menuItem - 1] });
  };

  const onTransferChange = (val, type) => {
    if (type === 'discountAmount') {
      setValues({ ...values, [type]: val, discountCode: 'coupen' });
    } else {
      setValues({ ...values, [type]: val });
    }
  };

  return (
    <div className="services-and-details">
      <div className="available-services">
        <StripDateFilter
          onStripDateClick={val => onStripDateClick(val)}
          selectedDate={values.selectedDate}
        />

        <div className="service-container">
          {ticketData.onwardServices &&
            ticketData.onwardServices.map((val, key) => renderService(val, key))}
        </div>

        <div className="details-of-transaction font10">
          <div className="main-details">
            <div>Detalles de la transacción</div>
            <div className="payment-type">
              <span>Tipo de pago</span>
              <KuposSteps
                menuButtons={values.stepsMenu}
                //  t={this.props.t}
                onStepClick={val => onStepClick(val)}
              />
            </div>

            <div
              className="aggrement-discount"
              style={{ marginTop: 50, marginBottom: 10, alignItems: 'end' }}
            >
              <div className="home-selection-input-box font10 select-box">
                <div>
                  <span className="label font11">{'Descuento por convenio'}</span>
                </div>

                <DropdownComponent
                  placeholder={'Descuento por convenio'}
                  disabled={true}
                  options={values.selectedDiscounts}
                  value={values.selectedDiscount}
                  onChange={val => onTransferChange(val, 'corporate_agency')}
                  error={values.selectedCurrencyError ? true : false}
                  errorMessage={values.selectedCurrencyError ? values.selectedCurrencyError : ''}
                />
              </div>

              <div className="home-selection-input-box font10 select-box">
                <div>
                  <span className="font11">{'Descuento monto'}</span>
                </div>
                <KuposInput
                  className="kupos-border"
                  type="text"
                  placeholder="Descuento"
                  //  value={this.props.kuposGoData.name}
                  onChange={val => onTransferChange(val, 'discountAmount')}
                  onBlur={text => {}}
                  error={values.kuposGoNameError ? true : false}
                  errorMessage={values.kuposGoNameError}
                  style={{ height: 40 }}
                  inputOuterStyle={{ height: 40 }}
                />
              </div>

              <div className="home-selection-input-box font10 select-box">
                <div>
                  <span className="label font11">{'Moneda'}</span>
                </div>
                <DropdownComponent
                  onChange={val => onTransferChange(val, 'selectedCurrency')}
                  placeholder={'Moneda'}
                  options={values.exchanges}
                  value={values.selectedCurrency}
                  error={values.selectedCurrencyError ? true : false}
                  errorMessage={values.selectedCurrencyError ? values.selectedCurrencyError : ''}
                />
              </div>
            </div>

            {/* ADDITIONAL FARE UI */}
            <div
              className="aggrement-discount"
              style={{ marginTop: 20, marginBottom: 10, alignItems: 'flex-start' }}
            >
              <div className="home-selection-input-box font10 select-box">
                <DropdownComponent
                  placeholder={'Tarifa adicional'}
                  // disabled={true}
                  options={additionalFares}
                  value={values.additionalFareSelected}
                  onChange={val => onTransferChange(val, 'additionalFareSelected')}
                  error={values.selectedCurrencyError ? true : false}
                  errorMessage={values.selectedCurrencyError ? values.selectedCurrencyError : ''}
                />
              </div>

              <div className="home-selection-input-box font10 select-box">
                <KuposInput
                  className="kupos-border"
                  type="number"
                  placeholder="Monto"
                  value={values.additionalFareAmount}
                  onChange={val => {
                    if (val < 0) {
                      setValues({ ...values, additionalFareAmount: 0 });
                    } else {
                      setValues({ ...values, additionalFareAmount: val });
                    }
                  }}
                  onBlur={text => {}}
                  // error={this.state.kuposGoNameError ? true : false}
                  // errorMessage={this.state.kuposGoNameError}
                  style={{ height: 40 }}
                  inputOuterStyle={{ height: 40 }}
                />
              </div>

              <div className="home-selection-input-box font10 select-box">
                <textarea
                  className="kupos-border"
                  type="text"
                  placeholder="Comentario"
                  value={values.additionalFareComment}
                  onChange={val => {
                    if (values.additionalFareComment) {
                      setValues({
                        ...values,
                        additionalFareComment: val.target.value,
                        additionalFareCommentError: null,
                      });
                    } else {
                      setValues({ ...values, additionalFareComment: val.target.value });
                    }
                  }}
                  onBlur={text => {}}
                  error={values.additionalFareCommentError ? true : false}
                  errorMessage={values.additionalFareCommentError}
                  rows={5}
                  cols={15}
                  style={
                    values.additionalFareCommentError
                      ? { border: 'red 1px solid', borderRadius: 10, padding: 6 }
                      : { borderRadius: 10, padding: 6 }
                  }
                  // inputOuterStyle={{ height: 40 }}
                />
                {values.additionalFareCommentError && (
                  <span style={{ color: 'red' }}>{values.additionalFareCommentError}</span>
                )}
              </div>
            </div>
          </div>

          <div className="discount-details">
            <div className="two-items-in-line">
              <span>Descuento total</span>
              <span>{CommonService.currency(values.discountAmount || 0)}</span>
            </div>
            <div className="two-items-in-line">
              <span>Monto total</span>
              <span>
                CLP
                {CommonService.currency(
                  selectedService && selectedService.price_type === 'PER_VEHICLE'
                    ? selectedService.price
                    : selectedService.price * values.ChoosenKupos,
                )}
              </span>
            </div>

            <div className="two-items-in-line">
              <span>Tarifa adicional</span>
              <span>$ {values.additionalFareAmount ? values.additionalFareAmount : 0}</span>
            </div>
            <div className="two-items-in-line">
              <span>Monto neto</span>
              <span>
                CLP
                {CommonService.currency(
                  selectedService && selectedService.price_type === 'PER_VEHICLE'
                    ? selectedService.price +
                        Number(values.additionalFareAmount) -
                        values.discountAmount
                    : selectedService.price * values.ChoosenKupos -
                        values.discountAmount +
                        Number(values.additionalFareAmount),
                )}
              </span>
            </div>

            <div className="two-items-in-line">
              <span>
                {values.selectedCurrency
                  ? `Monto neto ${values.selectedCurrency.to}`
                  : 'Choose currency'}
              </span>
              <span>
                {values.selectedCurrency && values.selectedCurrency.to}
                {CommonService.currency(
                  selectedService &&
                    values.selectedCurrency &&
                    Number(
                      selectedService.price_type === 'PER_VEHICLE'
                        ? selectedService.price +
                            Number(values.additionalFareAmount) -
                            values.discountAmount
                        : selectedService.price * values.ChoosenKupos -
                            values.discountAmount +
                            Number(values.additionalFareAmount),
                    ) *
                      Number(
                        (values.selectedCurrency &&
                          values.selectedCurrency.rate &&
                          values.selectedCurrency.rate) ||
                          1,
                      ),
                )}
              </span>
            </div>
          </div>
        </div>
      </div>

      <CustomerDetails
        values={values}
        setValues={setValues}
        setSnackbar={setSnackbar}
        selectedService={selectedService}
        metaData={metaData}
      />
    </div>
  );
};

export default RenderResult;
