import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actions from './redux/actions';
import { KuposButton, KuposErrorSuccessModal, KuposInput, KuposSelectBox } from '../common';

export class CounterSelection extends Component {
  static propTypes = {
    home: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.selectServiceOptions = [
      { value: 1, label: 'Hacia aeropuerto' },
      { value: 2, label: 'Desde aeropuerto' },
    ];

    this.state = {
      emailError: false,
      passwordError: false,
      identityVal: null,
      turns: [
        { value: 'TURN_1', label: 'Turn 1' },
        { value: 'TURN_2', label: 'Turn 2' },
        { value: 'TURN_3', label: 'Turn 3' },
        { value: 'TURN_4', label: 'Turn 4' },
      ],
      counters: [
        { value: 'COUNTER_1', label: 'Counter 1' },
        { value: 'COUNTER_2', label: 'Counter 2' },
        { value: 'COUNTER_3', label: 'Counter 3' },
        { value: 'COUNTER_4', label: 'Counter 4' },
      ],
    };
  }
  onTurnChange = val => {
    console.log('Turn selewcted is', val);
    this.setState({ turn: val.value, turnError: null });
  };

  onCounterChange = val => {
    console.log('Counter selected is', val);
    this.setState({ counter: val.value, counterError: null });
  };

  validate = () => {
    if (!this.state.turn) {
      this.setState({
        turnError: {
          message: 'Select your turn',
        },
      });
      return false;
    }

    if (!this.state.counter) {
      this.setState({
        counterError: {
          message: 'Select your counter',
        },
      });
      return false;
    }

    return true;
  };

  goToCashCounter = () => {
    if (!this.validate()) {
      return;
    }
    this.props.history.push('/counter-cash');
  };

  render() {
    return (
      <div className="home-login-page home-counter-selection">
        <div className="login-form-container">
          <div className="kupos-card counter-card">
            <div
              className="back-button-container"
              onClick={() => this.props.history.push('/login')}
            >
              <img src="/images/back_arrow_bip.png" />
            </div>
            <div className="login-logo-container">
              {/* <img style={{maxHeight: 45, width: 'auto'}} src={(this.props.common.clientInfo && this.props.common.clientInfo.logo_path) ||  "/images/kupos-logo2.png"} /> */}
              <img style={{maxHeight: 45, width: 'auto'}} src={ "/images/kupos-logo2.png"} />
            </div>

            <div className="login-title-container-counter">
              <span className="bold-text font14">Bienvenido Carlos</span>
            </div>
            <div className="login-desc-container-counter">
              <span className="font12 center">
                Selecciona tu turno u caja para ingresar<br></br> al sistems de ventas
              </span>
            </div>

            <div className="home-selection-input-box font10 counter-item">
              <span className="bold-text font10">Selecciona tu turno</span>
              <KuposSelectBox
                readonly={true}
                showAll={true}
                options={this.state.turns}
                selectedOption={this.state.turn}
                onChange={val => this.onTurnChange(val)}
                // onInputChange={this.onInputChange}
                placeholder={'Turno mañana'}
                height={40}
                // error={this.state.transferServiceError ? true : false}
                // errorMessage={this.state.transferServiceError}
                error={this.state.turnError ? true : false}
                errorMessage={this.state.turnError ? this.state.turnError.message : null}
              />
            </div>

            <div className="home-selection-input-box font10 counter-item">
              <span className="bold-text font10">Selecciona tu caja</span>
              <KuposSelectBox
                readonly={true}
                showAll={true}
                options={this.state.counters}
                selectedOption={this.state.counter}
                onChange={val => this.onCounterChange(val)}
                // onInputChange={this.onInputChange}
                placeholder={'Seleccione caja'}
                height={40}
                error={this.state.counterError ? true : false}
                errorMessage={this.state.counterError ? this.state.counterError.message : null}
              />
            </div>

            <div className="button-container" style={{ marginTop: 20 }}>
              <KuposButton onClick={this.goToCashCounter}>
                <span className="font11">CONTINUAR</span>
              </KuposButton>
            </div>
          </div>

          <div className="login-bottom-notice light-text">
            <span>
              Solución de gestión desarrollada y mantenida por
              <span className="bold-text primary-text-color"> kupos.com</span>
            </span>
            <div>
              <span>Todos los derechos reservados.</span>
            </div>
          </div>
        </div>
        <KuposErrorSuccessModal
          success={this.state.successModalStatus}
          showModal={this.state.showSuccessModal}
          onButtonClick={() => {
            this.setState({
              showSuccessModal: false,
              successModalStatus: false,
              successModalMessage: false,
            });
          }}
          bodyText={this.state.successModalMessage}
        />
      </div>
    );
  }
}

/* istanbul ignore next */
function mapStateToProps(state) {
  return {
    home: state.home,
    common: state.common
  };
}

/* istanbul ignore next */
function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...actions }, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CounterSelection);
