import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { HOME_SET_COUNTER_INITILAL_DATA } from './constants';

export function setCounterInitilalData(data) {
  return {
    type: HOME_SET_COUNTER_INITILAL_DATA,
    data: data,
  };
}

export function useSetCounterInitilalData() {
  const dispatch = useDispatch();
  const boundAction = useCallback((...params) => dispatch(setCounterInitilalData(...params)), [
    dispatch,
  ]);
  return { setCounterInitilalData: boundAction };
}

export function reducer(state, action) {
  switch (action.type) {
    case HOME_SET_COUNTER_INITILAL_DATA:
      return {
        ...state,
        counterInitialData: action.data,
      };

    default:
      return state;
  }
}
