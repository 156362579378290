import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actions from './redux/actions';
import KuposModal from './KuposModal';
import SvgHome from './SvgHome';
import KuposInput from './KuposInput';
import KuposButton from './KuposButton';

export class AddNewBipCard extends Component {
  static propTypes = {
    common: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired,
    t:PropTypes.func
  };

  render() {
    return (
      <div className="common-add-new-bip-card">
        <KuposModal
          showModal={this.props.showModal}
          onHide={this.props.onHide}
          size="md"
          ariaLabel="login-modal"
        >
          <div className="login-signup-block delete-bip-card add-bip-card">
            <div className="close-button pointer" onClick={this.props.onHide}>
              <SvgHome name="close" />
            </div>
            <div className="main-bip-add-parent-container">
              <div className="bold-text font12 bip-pop-up-title">
                {this.props.t('PROFILE.ADD_BIP_HEADING')}
              </div>
              <div className="add-bip-card-main-image">
                <img src="/images/example_bip_card.png" />
              </div>
              <div className="add-bip-card-input-container">
                <div className="add-bip-card-input-container-inner">
                  <div className="bip-input-add-container">
                    <div className="recarge-input-label font10 bold-text">
                      {this.props.t('PROFILE.NAME')}
                    </div>

                    <KuposInput
                      placeholder={this.props.t('PROFILE.NAME_ENTER_PLACEHOLDER')}
                      className="kupos-border font10 bip-recharge-input add-bip-input"
                      // value={this.state.rechargeAmount}
                      // onBlur={() => {}}
                      // onChange={val => this.setState({ rechargeAmount: val })}
                    />
                  </div>

                  <div className="bip-input-add-container">
                    <div className="recarge-input-label font10 bold-text">
                      {this.props.t('PROFILE.CARD_NUMBER')}
                    </div>

                    <KuposInput
                      placeholder="XXXXXXXX"
                      className="kupos-border font10 bip-recharge-input add-bip-input"
                      // value={this.state.rechargeAmount}
                      // onBlur={() => {}}
                      // onChange={val => this.setState({ rechargeAmount: val })}
                    />
                  </div>
                </div>
              </div>

              <div className="bip-popup-button-container">
                <KuposButton onClick={this.props.onAddCard} className="bip-popup-button1 font12">
                  {this.props.t('PROFILE.ADD_BIP_CARD_BUTTON')}
                </KuposButton>
              </div>
            </div>
          </div>
        </KuposModal>
      </div>
    );
  }
}

/* istanbul ignore next */
function mapStateToProps(state) {
  return {
    common: state.common,
  };
}

/* istanbul ignore next */
function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...actions }, dispatch)
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddNewBipCard);
