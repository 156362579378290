import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import AppData from '../../../services/appData';
import DateService from '../../../services/dateService';
import { KuposHybridInput, KuposInput } from '../../common';
import { KuposAtCounter } from '../../common/KuposAtCounter';
import { KuposDateTimePicker } from '../../common/KuposDateTimePicker';
import { apiCall } from '../redux/apiCall';
import Loader from 'react-loader-spinner';
import { useEffect } from 'react';
import ApiUrls from '../../../services/apiUrls';


const SearchInput = ({
  values,
  setValues,
  setTicketData,
  setSelectedService,
  setSnackbar,
  callApiAgain,
  setCallApiAgain,
}) => {
  const dispatch = useDispatch();
  const [showLoader, setShowLoader] = useState(false);
  const [selectedAtTerminals, setSelectedAtTerminals] = useState(false);
  const [showAtCounter, setShowAtCounter] = useState(false);
  console.log({ selectedAtTerminals });

  useEffect(() => {
    if (callApiAgain) {
      searchTransfer();
    }
    // eslint-disable-next-line
  }, [callApiAgain]);

  useEffect(() => {
    getAtTerminalList();
    // eslint-disable-next-line
  }, []);

  const getAtTerminalList = () => dispatch(apiCall({ url: ApiUrls.getAtTerminalList(), callback: res => getAtTerminalListResponse(res) }))


  const getAtTerminalListResponse = (res) => {

    console.log('+++response from atTerminals api is', res);
    if (res && res.data && res.data.success && res.data.data) {
      let atTerminals = [];
      res.data.data.map(atTerminal => {
        atTerminals.push({ value: atTerminal.airport_loc_id, label: atTerminal.airport_name });
      });
      setSelectedAtTerminals(atTerminals)
    }

  }



  const onTransferChange = (value, type) => {
    console.log('Value is ', type, value);
    const adult = values.selectedAdultKupos;
    const kid = values.selectedKidKupos;
    if (type === 'selectedServiceDirection') {
      setValues({ ...values, selectedServiceDirection: value });
    }
    else if (type === 'origin') {

      setValues({ ...values, origin: value, transferSourceCityError: null });
    }

    else if (type === 'destination') {

      setValues({ ...values, destination: value, transferDestinationCityError: null });
    }

    else if (type === 'kupos_adult') {
      setValues({
        ...values,
        selectedAdultKupos: value < 1 ? 1 : value,
        selectedKupos: value < 1 ? 1 : value + kid,
      });
    } else if (type === 'kupos_kid') {
      setValues({
        ...values,
        selectedKidKupos: value < 1 ? 0 : value,
        selectedKupos: adult + value,
      });
    } else if (type === 'kupos_infant') {
      setValues({
        ...values,
        selectedInfantKupos: value < 1 ? 0 : value,
      });
    } else {
      setValues({ ...values, [type]: value, [type + 'Error']: null });
    }
  };

  const validate = () => {
    if (!values.origin) {
      setValues({ ...values, transferSourceCityError: 'Seleccione la origen' });
      return false;
    }
    if (!values.destination) {
      setValues({ ...values, transferDestinationCityError: 'Seleccione la destino' });
      return false;
    }
    if (!values.selectedDate) {
      setValues({ ...values, selectedDateTimeError: 'select both time and date' });
      return false;
    }
    if (!values.selectedTime) {
      setValues({ ...values, selectedDateTimeError: 'select both time and date' });
      return false;
    }
    return true;
  };

  const searchTransfer = () => {
    if (!validate()) {
      console.log('Validation failed ');
      return;
    }

    let trfrType = 0;
    if (values.origin.value) {
      trfrType = 1;
    } else if (values.destination.value) {
      trfrType = 2;
    }

    const date = DateService.getTimeInSeconds(
      DateService.changeDateFormat(values.selectedDate, 'dd-mm-yyyy', 'yyyy-mm-dd') +
      'T' +
      values.selectedTime,
    );
    let currentUTC = DateService.getCurrentTimeUTC();
    let seats = Number(values.selectedAdultKupos) + Number(values.selectedKidKupos);


    const originLat = values.origin.location && values.origin.location ? values.origin.location.lat : values.origin.value;
    const originLon = values.origin.location && values.origin.location.lon ? values.origin.location.lon : values.origin.value;
    const destinationLat = values.destination.location && values.destination.location.lat ? values.destination.location.lat : values.destination.value;
    const destinationLon = values.destination.location && values.destination.location.lon ? values.destination.location.lon : values.destination.value;
    let originLocId = values.origin.value ? values.origin.value : 0;
    let destLocId = values.destination.value ? values.destination.value : 0;

    setValues({ ...values, ChoosenKupos: values.selectedKupos });
    setShowLoader(true);

    dispatch(
      apiCall({
        url:
          AppData.BASE_URL_V3 +
          `service_inventory/${originLat}/${originLon}/${originLocId}/${date}/${destinationLat}/${destinationLon}/${destLocId}/0/${seats}/${currentUTC}/${trfrType}`,
        callback: res => onServicePriceAPIResponse(res),
      }),
    );
  };

  const onServicePriceAPIResponse = result => {
    let noOfServices = [];
    setShowLoader(false);
    setCallApiAgain(false);
    console.log('Result from search api is-----', result);

    if (
      result &&
      result.data &&
      result.data.success &&
      result.data.data &&
      (result.data.data.onward_service_list || result.data.data.return_service_list)
    ) {
      result.data.data &&
        result.data.data.onward_service_list &&
        Object.keys(result.data.data.onward_service_list).forEach(element => {
          noOfServices.push({
            selected: false,
          });
          console.log('Elemet to push', element);
        });

      setTicketData({
        onwardServices: result.data.data.onward_service_list,
        returnServices: result.data.data.return_service_list,
      });
      setSelectedService(noOfServices);

      console.log('No of services', noOfServices);
    } else {
      setSelectedService(null);
      setSnackbar({
        showSnackbar: true,
        alertMessage:
          '¡Lo sentimos!\n\n Este servicio no está disponible en la fecha que seleccionada. Por favor intenta con otra fecha.',
        noResults:
          '¡Lo sentimos!Este servicio no está disponible en la fecha que seleccionada. Por favor intenta con otra fecha',
      });
    }
  };

  const onATInputBlur = (val, type) => {
    // setTimeout(() => {
    //   if (type === 'origin') {
    //     setValues({
    //       ...values,
    //       showOriginTerminalSuggestions: false,
    //       // showDestinationTerminalSuggestions: false,
    //     });
    //   } else {
    //     setValues({
    //       ...values,
    //       showDestinationTerminalSuggestions: false,
    //       // showOriginTerminalSuggestions: false,
    //     });
    //   }
    // }, 300);
  };


  const onATInputFocus = (type, event) => {
    const setPickupTerminal = values.setPickupTerminal
    const setDestinationTerminal = values.setDestinationTerminal

    if (type === 'origin')
      setValues({
        ...values,
        showOriginTerminalSuggestions: true,
        showDestinationTerminalSuggestions: false,

        originType: setPickupTerminal ? 'text' : 'places',
      });
    else if (type === 'destination')
      setValues({
        ...values,
        showDestinationTerminalSuggestions: true,
        showOriginTerminalSuggestions: false,
        destinationType: setDestinationTerminal ? 'text' : 'places',
        // destinationReadonly: this.state.setPickupTerminal ? false : true,
      });
  };

  const eraseSelectedInput = type => {
    let val = '';
    const setDestinationTerminal = values.setDestinationTerminal
    const setPickupTerminal = values.setPickupTerminal

    switch (type) {
      case 'origin':
        setValues({
          ...values,
          atOriginText: val,
          origin: val,
          setPickupTerminal: null,
          airportChoosen: false,
          // airportChoosen: setDestinationTerminal ? true : false,
          originType: 'places',
        });
        // this.props.onTransferChange(val, 'origin');
        break;
      case 'destination':
        setValues({
          ...values,
          atDestinationText: val,
          destination: val,
          airportChoosen: false,
          // airportChoosen: setPickupTerminal ? true : false,
          destinationType: 'places',
          // destinationReadonly: false,
          setDestinationTerminal: null,
        });
        // this.props.onTransferChange(val, 'destination');
        break;
    }
  };

  const renderATTerminals = (val, key, type) => {
    console.log('+++ value , key and type is', val, key, type);
    return (
      <div onClick={() => onSelect(val, type)}>
        <a
          // className={}
          key={key}
          className="at-terminal-item"
        >
          {/* <img src="/images/icons/home/airport-icon.png" /> */}
          {val.label}
        </a>
      </div>
    );
  };
  const onSelect = (val, type) => {
    if (type == 'origin') {
      setValues({
        ...values,
        showOriginTerminalSuggestions: false,
        setPickupTerminal: val,
        atOriginText: val.label,
        originType: 'text',
        origin: val,
        airportChoosen: true,
        transferSourceCityError: null
      });

      // this.props.onTransferChange(val, 'origin');
    } else {
      setValues({
        ...values,
        showDestinationTerminalSuggestions: false,
        setDestinationTerminal: val,
        atDestinationText: val.label,
        destinationType: 'text',
        destination: val,
        airportChoosen: true,
        transferDestinationCityError: null
      });
      // this.props.onTransferChange(val, 'destination');
    }
  };
  return (
    <>
      <hr className="mb-0 mt-3" />
      <div className="search-box-container mt-3">
        <div
          className="home-selection-input-boxes"
        //  key={this.props.selectedSearchApp}
        >

          <div className="home-selection-input-box font10" style={{ position: 'relative' }}>
            <span className="label font11">{'Origen'}</span>

            <KuposHybridInput
              type={values.originType}
              value={values.atOriginText}
              onTextChange={val => setValues({ ...values, atOriginText: val, showOriginTerminalSuggestions: val ? false : true })}
              onBlur={val => onATInputBlur(val, 'origin')}
              onChange={val => onTransferChange(val, 'origin')}
              placeholder={'Origen'}
              icon={'/svgs/origin.svg'}
              error={values.transferSourceCityError ? true : false}
              errorMessage={values.transferSourceCityError ? values.transferSourceCityError : ''}
              height={40}
              fontSize="font11"
              erasable={true}
              onFocus={event => onATInputFocus('origin', event)}
              eraseSelectedInput={() => eraseSelectedInput('origin')}
            // ref={'atOriginRef'}
            />
            {selectedAtTerminals &&
              values.showOriginTerminalSuggestions &&
              !values.airportChoosen && (
                <div className="at-termninal-suggestions auto-suggestions font10 scroll-style">
                  {selectedAtTerminals &&
                    selectedAtTerminals
                      .sort((a, b) => b.label.localeCompare(a.label))
                      .map((val, key) => {
                        return renderATTerminals(val, key, 'origin');
                      })}
                </div>
              )}
          </div>

          <div className="home-selection-input-box font10" style={{ position: 'relative' }}>
            <span className="label font11">{'Destino'}</span>
            <KuposHybridInput
              type={values.destinationType}
              value={values.atDestinationText}
              onTextChange={val => setValues({ ...values, atDestinationText: val, showDestinationTerminalSuggestions: val ? false : true, })}
              onBlur={val => onATInputBlur(val, 'destination')}
              onChange={val => onTransferChange(val, 'destination')}
              placeholder={'Destino'}
              icon={'/svgs/deestination.svg'}
              error={values.transferDestinationCityError ? true : false}
              errorMessage={values.transferDestinationCityError ? values.transferDestinationCityError : ''}
              height={40}
              fontSize="font11"
              erasable={true}
              onFocus={event => onATInputFocus('destination', event)}
              eraseSelectedInput={() => eraseSelectedInput('destination')}
            />

            {values.origin &&
              values.showDestinationTerminalSuggestions &&
              !values.airportChoosen && (
                <div className="at-termninal-suggestions auto-suggestions font10 scroll-style">
                  {selectedAtTerminals &&
                    selectedAtTerminals
                      .sort((a, b) => b.label.localeCompare(a.label))
                      .map((val, key) => {
                        return renderATTerminals(val, key, 'destination');
                      })}
                </div>
              )}
          </div>

          <div className="home-selection-input-box font10 datebox">
            <span className="label font11">{'Ida'}</span>
            <KuposDateTimePicker
              selectedDate={values.selectedDate}
              selectedTime={values.selectedTime}
              onDateChange={val => onTransferChange(val, 'selectedDate')}
              onTimeChange={val => onTransferChange(val, 'selectedTime')}
              minDate={DateService.getTodayString()}
              //   error={this.state.selectedDateError || this.state.selectedTimeError ? true : false}
              //   errorMessage={this.state.selectedDateTimeError}
              //   errorMessage={this.state.selectedDateTimeError}
              placeholder={DateService.getTodayString()}
              showOnSelect={values.showOnSelect}
            />
          </div>

          <div className="home-selection-input-box font10 datebox">
            <span className="label font11">{'Vuelta(Opcional)'}</span>
            <KuposDateTimePicker
              selectedDate={DateService.getTodayString()}
              selectedTime={'00:00'}
              onDateChange={val => onTransferChange(val, 'returnDate')}
              onTimeChange={val => onTransferChange(val, 'returnTime')}
              minDate={DateService.getTodayString()}
              optional={true}
              disabled={true}
            //   // error={this.state.selectedDateError || this.state.selectedTimeError ? true : false}
            //   // errorMessage={this.state.selectedDateTimeError}
            />
          </div>

          <div className="home-selection-input-box font10 counter">
            <span className="label font11">{'Cupos'}</span>
            <div className="show-count" onClick={() => setShowAtCounter(!showAtCounter)}>
              <div>
                <img
                  className="at-count-user-icon"
                  src="/images/kupos-AirportTransfer-Icon-Userkupos.svg"
                />
              </div>
              <div>
                <span>
                  {values.selectedKupos}
                  &nbsp;Pasajero
                </span>
              </div>
              <div>
                <img
                  className="at-count-down-icon"
                  src="/images/kupos-AirportTransfer-Icon-Arrow.svg"
                />
              </div>
            </div>
            {showAtCounter ? (
              <div className="at-counter-popoup" onMouseLeave={() => setShowAtCounter(false)}>
                <KuposAtCounter
                  selectedKupos={values.selectedAdultKupos}
                  onTransferChange={val => onTransferChange(val, 'kupos_adult')}
                  kuposType={'kupos_adult'}
                  borderBottom
                />

                <KuposAtCounter
                  selectedKupos={values.selectedKidKupos}
                  onTransferChange={val => onTransferChange(val, 'kupos_kid')}
                  kuposType={'kupos_kid'}
                  borderBottom
                />

                <KuposAtCounter
                  selectedKupos={values.selectedInfantKupos}
                  onTransferChange={val => onTransferChange(val, 'kupos_infant')}
                  kuposType={'kupos_infant'}
                />
              </div>
            ) : null}
          </div>

          <div className="home-selection-input-box font12">
            <button className="kupos-button home-selection-input-button " onClick={searchTransfer}>
              <img
                className="icon-size"
                src="/images/icons/home/icon_search-white.png"
                alt={'TRANSLATIONS.ICON_SEARCH_WHITE'}
              />
              {/* <span>{'HOME.SEARCH'}</span> */}
            </button>
          </div>
        </div>
      </div>

      {showLoader ? (
        <div className="loader-container">
          <Loader visible={showLoader} type="Bars" color="#637290" height={80} width={80} />
        </div>
      ) : null}
    </>
  );
};

export default SearchInput;
