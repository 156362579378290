import React, { Component } from 'react';
import PropTypes from 'prop-types';
import KuposModal from './KuposModal';
import KuposInput from './KuposInput';
import KuposCheckbox from './KuposCheckbox';
import CommonService from '../../services/commonService';
import ApiUrls from '../../services/apiUrls';
import KuposRadio from './KuposRadio';
import SvgCircularIcons from './SvgCircularIcons';
import SvgHome from './SvgHome';
import { KuposSelectBox, KuposModalWithButtons } from '.';
import { NewsLetterSuccessModal } from './NewsLetterSuccessModal';
import { SignUpSuccessModal } from './SignUpSuccessModal';

// import FacebookLogin from 'react-facebook-login';
// import { GoogleLogin } from 'react-google-login';
import AppData from '../../services/appData';
import KuposErrorSuccessModal from './KuposErrorSuccessModal';
// import TermsAndConditions from '../pages/TermsAndConditions';

var global = window;

export class LoginModal extends Component {
  static propTypes = {
    common: PropTypes.object.isRequired,
    pbPaymentDetails: PropTypes.object,
    actions: PropTypes.object.isRequired,
    onHide: PropTypes.func,
    showModal: PropTypes.bool,
    onLoginSuccess: PropTypes.func,
    t: PropTypes.func,
    history: PropTypes.object,
  };

  idCardTypes = [];
  backView = [];

  constructor(props) {
    super(props);
    for (let c in this.props.metaData.id_card_types) {
      this.idCardTypes.push({
        value: this.props.metaData.id_card_types[c],
        id: this.props.metaData.id_card_types[c],
        label: c,
        name: c,
        selected: this.props.metaData.id_card_types[c] == 7,
      });
    }
    this.idCardTypesSelect = [
      { value: 7, label: 'RUT' },
      { value: 3, label: 'DNI/Pasaporte' },
    ];
    this.state = {
      signupCardType: this.idCardTypesSelect[0],
      modalType: 'LOGIN-OPTIONS',
      recieveOffer: true,
      signupMobile: '',
      signupCallingCode: '+56',
      successfullRegistration: false,
      selectedForgotTab: 1,
    };
  }

  componentDidMount() {
    console.log('componentMounted');
    this.resetData();
  }

  resetData = () => {
    this.oAuthName = null;
    this.oauth_account = null;
    this.oauth_token = null;
    this.oAuthEmail = null;
    this.oAuthLastName = null;
    this.setState({
      email: null,
      emailError: null,
      password: null,
      passwordError: null,
      loginErrorMessage: null,
      signupCallingCode: '+56',
      signupCardNumber: null,
      signupCardNumberError: null,
      signupCardType: this.idCardTypesSelect[0],
      signupName: null,
      signupNameError: null,
      signupLastName: null,
      signupLastNameError: null,
      signupMobile: null,
      signupMobileError: null,
      signupPassword: null,
      signupPasswordError: null,
      signupConfirmPassword: null,
      signupConfirmPasswordError: null,
    });
  };

  onLoginInputChange = (text, inp) => {
    if (inp == 'email') {
      this.setState({
        emailError: null,
        email: this.state.loginType === 'email' ? text : CommonService.phoneNumberFormat(CommonService.onlyNumbers(text)),
      });
    } else if (inp == 'password') {
      this.setState({
        passwordError: null,
        password: text,
      });
    }
  };

  onLoginInputBlur = (text, inp) => {
    if (!text) {
      if (inp == 'email') {
        this.state.loginType == 'mobile'
          ? this.setState({
              emailError: {
                message: 'VALIDATIONS.VALID_MOBILE',
              },
            })
          : this.setState({
              emailError: {
                message: 'VALIDATIONS.VALID_NO_EMAIL',
              },
            });
      } else if (inp == 'password') {
        this.setState({
          passwordError: {
            message: 'VALIDATIONS.VALID_PASSWORD',
          },
        });
      }
    } else if (this.state.loginType === 'email') {
      if (inp == 'email') {
        if (!CommonService.isEMailValid(text)) {
          this.setState({
            emailError: {
              message: 'VALIDATIONS.VALID_EMAIL',
            },
          });
        }
      }
    }
  };

  onLogin = () => {
    console.log(this, 'props');
    let errorCount = 0;
    if (!this.state.email) {
      this.state.loginType == 'mobile'
        ? this.setState({
            emailError: {
              message: 'VALIDATIONS.VALID_MOBILE',
            },
          })
        : this.setState({
            emailError: {
              message: 'VALIDATIONS.VALID_NO_EMAIL',
            },
          });
      errorCount++;
    }
    if (this.state.loginType === 'email') {
      if (!CommonService.isEMailValid(this.state.email)) {
        this.setState({
          emailError: {
            message: 'VALIDATIONS.VALID_EMAIL',
          },
        });
        errorCount++;
      }
    }
    if (!this.state.password) {
      this.setState({
        passwordError: {
          message: 'VALIDATIONS.VALID_PASSWORD',
        },
      });
      errorCount++;
    }
    if (errorCount > 0) {
      return;
    }

    this.setState({ loginErrorMessage: null });
    // let data = {
    //   email: this.state.email,
    //   password: this.state.password,
    //   // "device_details": this.props.data.deviceInfo
    // };

    let data = {
      password: this.state.password,
    };
    this.state.loginType == 'email'
      ? (data.email = this.state.email)
      : (data.phone_no = this.state.email);
    this.state.loginType == 'mobile' ? (data.is_phone = true) : (data.is_phone = false);

    // this.props.actions.login({ url: ApiUrls.login(), data: data }).then(
    //   res => {
    //     this.onResponse(res);
    //     // this.props.history.push("/my-account")
    //   },
    //   err => {
    //     this.onResponse(err);
    //   },
    // );
  };

  onResponse = result => {
    if (result.data && result.data.error) {
      if (this.state.modalType == 'LOGIN-OPTIONS' && this.oauth_token) {
        this.backView.push(this.state.modalType);
        this.setState({
          modalType: 'REGISTER',
          signupName: this.oAuthName,
          signupNameError: null,
          signupLastName: this.oAuthLastName,
          signupLastNameError: null,
          signupEmail: this.oAuthEmail,
          signupEmailError: null,
          signupMobile:null,
          signupMobileError:null,
          loginType: 'oauth',
        });
      } else {
        this.setState({
          loginErrorMessage: result.data.error,
        });
        return;
      }
    } else if (result.data.auth_token) {
      // GlobalService.setGlobal('token',result.data.auth_token);
      global.token = result.data.auth_token;
      CommonService.storeLoginDetails(result.data, this.props.actions.setLoginData);
      setTimeout(() => {
        // CommonService.toast.show("¡Sesión iniciada éxitosamente!");
        if (!CommonService.isBookingScreen() && !CommonService.isATBookingScreen())
          this.props.history.push('/my-account');
        this.onHide();
        this.props.onLoginSuccess();
        if (CommonService.isBookingScreen()) {
          this.fillPassengerAutoData();
        }
        if (CommonService.isATBookingScreen()) {
          // this.props.fillAtData()
        }
      }, 300);
    } else {
      if (this.state.modalType == 'LOGIN-OPTIONS' && this.oauth_token) {
        this.backView.push(this.state.modalType);
        this.setState({
          modalType: 'REGISTER',
          signupName: this.oAuthName,
          signupNameError: null,
          signupLastName: this.oAuthLastName,
          signupLastNameError: null,
          signupEmail: this.oAuthEmail,
          signupEmailError: null,
          signupMobile:null,
          signupMobileError:null,
        });
      }
    }
  };

  renderLoginOptions = () => {
    return (
      <div className="kupos-card log-in-card ">
        <div className="user-icon">
          <SvgCircularIcons name="user-orange" />
        </div>
        <div className="bold-text center-text font16">{this.props.t('PROFILE.LOG_IN_HEADING')}</div>

        <div className="center-text font11 light-text" style={{ marginTop: 10 }}>
          {this.props.t('PROFILE.LOGIN_MESSAGE1')}
          <span className="bold-text pointer" onClick={() => this.setState({ showTNCModel: true })}>
            {' '}
            {this.props.t('PROFILE.LOGIN_MESSAGE2')}
          </span>
          {this.props.t('PROFILE.LOGIN_MESSAGE3')}
          <span className="bold-text pointer" onClick={() => this.setState({ showTNCModel: true })}>
            {this.props.t('PROFILE.LOGIN_MESSAGE4')}
          </span>
        </div>

        <div className="login-signup-inputs">
          <div className="login-signup-button option-buttons font12">
            <button
              disabled={this.props.common.loginPending}
              className="kupos-button login_option-button"
              onClick={() => {
                this.resetData();
                this.backView.push(this.state.modalType);
                this.setState({ modalType: 'LOGIN', loginType: 'mobile' });
              }}
            >
              <div className="inner-button-login-options">
                <div
                  style={{
                    width: 30,
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <img src="/images/phone-text.png" />
                </div>
                <span>{this.props.t('PROFILE.PHONE_LOGIN')}</span>
              </div>
            </button>
          </div>

          <div className="login-signup-button option-buttons font12">
            <button
              style={{ backgroundColor: '#F6E8E0' }}
              className="kupos-button login_option-button"
              onClick={() => {
                this.resetData();
                this.backView.push(this.state.modalType);
                this.setState({ modalType: 'LOGIN', loginType: 'email' });
              }}
            >
              <div className="inner-button-login-options">
                <div
                  style={{
                    width: 30,
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <img src="/images/email.png" />
                </div>
                <span className="primary-text">{this.props.t('PROFILE.EMAIL_LOGIN')}</span>
              </div>
            </button>
          </div>

          <div
            className="login-signup-button option-buttons font12 "
            style={{ position: 'relative' }}
          >
            <button
              disabled={this.props.common.loginPending}
              className="kupos-button login_option-button facebook"
            >
              <div className="inner-button-login-options">
                <div
                  style={{
                    width: 30,
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <img src="/images/fb.png" />
                </div>
                <span>{this.props.t('PROFILE.FACEBOOK_LOGIN')}</span>
              </div>
            </button>
            {/* <span className="hidden-fb-login-button">
              <FacebookLogin
                appId={AppData.FB_APP_ID} //APP ID NOT CREATED YET
                fields="name,email,picture.height(400),first_name,middle_name,last_name"
                autoLoad={false}
                callback={this.loginResponseFacebook}
              />
            </span> */}
          </div>

          <div className="login-signup-button option-buttons font12 relative ">
            <button
              disabled={this.props.common.loginPending}
              className="kupos-button login_option-button google"
            >
              <div className="inner-button-login-options">
                <div
                  style={{
                    width: 30,
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <img src="/images/google_reg.png" />
                </div>
                <span>{this.props.t('PROFILE.GOOGLE_LOGIN')}</span>
              </div>
            </button>
            {/* <span className="hidden-fb-login-button">
              <GoogleLogin
                clientId="369861062906-8s5lt8tvto7tls969nivt5bcjkpa706s.apps.googleusercontent.com"
                autoLoad={false}
                onSuccess={this.responseGoogle}
                onFailure={this.responseGoogle}
                cookiePolicy={'single_host_origin'}
                redirectUri="postmessage"
              />
            </span> */}
          </div>
        </div>
      </div>
    );
  };

  renderLoginCard = () => {
    return (
      <div className="kupos-card log-in-card ">
        <div
          className="back-arrow"
          onClick={() => {
            this.setState({
              modalType: this.backView.pop(),
              email: null,
              password: null,
              emailError: null,
              passwordError: null,
            });
          }}
        >
          <SvgHome name="back-arrow" />
        </div>
        <div className="user-icon">
          <SvgCircularIcons name="user-orange" />
        </div>
        <div className="bold-text center-text font16">{this.props.t('PROFILE.LOG_IN_HEADING')}</div>

        {/* <div className="center-text social-links">
          <span className="social-link" style={{ position: 'relative' }}>
            <img src="/images/icons/home/icon-facebook.png" alt="" />
            <span className="hidden-fb-login-button">
              <FacebookLogin
                appId={AppData.FB_APP_ID} //APP ID NOT CREATED YET
                fields="name,email,picture"
                callback={this.loginResponseFacebook}
              />
            </span>
          </span>
          <span className="social-link">
            <img src="/images/icons/home/icon-googleplus.png" alt="" />
          </span>
        </div> */}

        <div style={{ marginTop: 10 }} className="center-text font11 light-text">
          {this.state.loginType == 'email'
            ? this.props.t('PROFILE.LOGIN_DETAILS')
            : this.props.t('PROFILE.LOGIN_WITH_PHONE')}
        </div>

        <div className="login-signup-inputs">
          {this.state.loginType == 'email' ? (
            <div className="login-signup-input font11">
              <KuposInput
                className="kupos-border light-placeholder"
                placeholder={this.props.t('PROFILE.ENTER_EMAIL')}
                t={this.props.t}
                onChange={text => this.onLoginInputChange(text, 'email')}
                onBlur={text => this.onLoginInputBlur(text, 'email')}
                value={this.state.email}
                error={this.state.emailError ? true : false}
                errorMessage={
                  this.state.emailError ? this.props.t(this.state.emailError.message) : ''
                }
              />
            </div>
          ) : (
            <div className="login-signup-inputs row light-placeholder">
              <div
                className="login-signup-input  col-md-12 no-pad font11 mobile-signup-input"
                style={{ marginBottom: 0 }}
              >
                <div className="input-flag-img">
                  <img src={'/images/chile-flag.png'} />
                </div>
                <KuposInput
                  placeholder={this.props.t('PROFILE.ENTER_PHONE')}
                  t={this.props.t}
                  onChange={text => this.onLoginInputChange(text, 'email')}
                  onBlur={text => this.onLoginInputBlur(text, 'email')}
                  focused={true}
                  value={this.state.email}
                />
              </div>
              <div className="errorMessageInput font8">
                {this.state.emailError ? this.props.t(this.state.emailError.message) : ''}
              </div>
            </div>
          )}
          <div className="login-signup-input font11">
            <KuposInput
              className="kupos-border light-placeholder"
              placeholder={this.props.t('PROFILE.ENTER_PASSWORD')}
              // type="password"
              t={this.props.t}
              onChange={text => this.onLoginInputChange(text, 'password')}
              onBlur={text => this.onLoginInputBlur(text, 'password')}
              value={this.state.password}
              error={this.state.passwordError ? true : false}
              showPasswordIcon={true}
              password={true}
              errorMessage={
                this.state.passwordError ? this.props.t(this.state.passwordError.message) : ''
              }
            />
          </div>
          {this.state.loginErrorMessage ? (
            <div className="login-server-error font10 secondary-text">
              {this.state.loginErrorMessage}
            </div>
          ) : null}
        </div>

        <div className="login-terms flex-row font10">
          <span>
            <KuposCheckbox
              t={this.props.t}
              label="REMEMBER_ME"
              checked={this.state.rememberMe}
              onChange={() => this.setState({ rememberMe: !this.state.rememberMe })}
            />
          </span>
          <a
            onClick={() => {
              this.backView.push(this.state.modalType);
              this.setState({
                modalType: 'FORGOT',
                email: null,
                password: null,
                emailError: null,
                passwordError: null,
              });
            }}
            className="primary-text"
          >
            {this.props.t('PROFILE.FORGET_PASSWORD')}
          </a>
        </div>

        <div className="login-signup-button font12">
          <button
            disabled={this.props.common.loginPending}
            className="kupos-button"
            onClick={this.onLogin}
          >
            {this.props.common.loginPending ? (
              <div className="loader-cricle"></div>
            ) : (
              <span>{this.props.t('PROFILE.LOG_IN_HEADING')}</span>
            )}
          </button>
        </div>

        <div className="register-link font10">
          <span>{this.props.t('PROFILE.DONT_HAVE_ACCOUNT')}</span>&nbsp;
          <a
            onClick={() => {
              this.backView.push(this.state.modalType);
              this.setState({
                modalType: this.state.loginType == 'email' ? 'REGISTER' : 'MOBILE-REGISTER',
                email: null,
                password: null,
                emailError: null,
                passwordError: null,
              });
            }}
          >
            {this.props.t('PROFILE.REGISTER')}
          </a>
        </div>
      </div>
    );
  };

  fillPassengerAutoData = () => {
    setTimeout(() => {
      let passengerDetailsOnward = CommonService.copyObject(
        this.props.pbPaymentDetails.passengerDetailsOnward,
      );
      passengerDetailsOnward[0].name = this.props.common.loginData
        ? this.props.common.loginData.name
        : null;
      passengerDetailsOnward[0].last_name = this.props.common.loginData
        ? this.props.common.loginData.last_name
        : null;
      // (passengerDetailsOnward[0].idCardType =
      //   this.props.common.loginData && Object.keys(this.props.common.loginData).length
      //     ? this.idCardTypes.find(it => it.value == this.props.common.loginData.identity_type)
      //     : this.idCardTypes.find(it => it.value == 7)),
      //   (passengerDetailsOnward[0].idCardNumber =
      //     this.props.common.loginData && Object.keys(this.props.common.loginData).length
      //       ? this.props.common.loginData.identity_val
      //       : null);
      this.props.actions.setPassengerDetailsOnward(passengerDetailsOnward);

      let contactDetails = {
        countryCode: this.countrieCodes && this.countrieCodes[0] ? this.countrieCodes[0].name : '',
        mobile: this.props.common.loginData ? this.props.common.loginData.phone : null,
        email: this.props.common.loginData ? this.props.common.loginData.email : null,
        confirmEmail: this.props.common.loginData ? this.props.common.loginData.email : null,
      };
      this.props.actions.setContactDetails(contactDetails);
      CommonService.loginCallbackFunction = null;
    }, 300);
  };

  // signup code ----------------------------
  onSignupInputChange = (text, inp) => {
    if (inp == 'name') {
      this.setState({
        signupNameError: null,
        signupName: text,
      });
    } else if (inp == 'lastname') {
      this.setState({
        signupLastNameError: null,
        signupLastName: text,
      });
    } else if (inp == 'email') {
      this.setState({
        signupEmailError: null,
        signupEmail: text,
      });
    } else if (inp == 'mobile') {
      if (text) {
        text = CommonService.phoneNumberFormat(text);
      }
      this.setState({
        signupMobileError: null,
        signupMobile: text,
      });
    } else if (inp == 'cardNumber') {
      this.setState({
        signupCardNumberError: null,
      });
      if (text) {
        // let cardNumber = CommonService.isRut(this.state.signupCardType,this.idCardTypes) ? CommonService.formatRut(text) : text;
        this.setState({
          signupCardNumber: text,
        });
      } else {
        this.setState({
          signupCardNumber: text,
        });
      }
    } else if (inp == 'cardType') {
      for (let item of this.idCardTypes) {
        item.selected = false;
      }
      text.selected = true;
      this.setState({
        signupCardNumberError: null,
        signupCardNumber: null,
        signupCardType: text.value,
      });
    } else if (inp == 'password') {
      this.setState({
        signupPasswordError: null,
        signupPassword: text,
      });
    } else if (inp == 'confirmPassword') {
      this.setState({
        signupConfirmPasswordError: null,
        signupConfirmPassword: text,
      });
    }
  };

  onSignupInputBlur = (text, inp) => {
    if (!text) {
      if (inp == 'name') {
        this.setState({
          signupNameError: {
            message: 'VALIDATIONS.VALID_NAME',
          },
        });
      } else if (inp == 'lastname') {
        this.setState({
          signupLastNameError: {
            message: 'VALIDATIONS.VALID_SURNAME',
          },
        });
      } else if (inp == 'email') {
        this.setState({
          signupEmailError: {
            message: 'VALIDATIONS.VALID_NO_EMAIL',
          },
        });
      } else if (inp == 'password') {
        this.setState({
          signupPasswordError: {
            message: 'VALIDATIONS.VALID_PASSWORD',
          },
        });
      } else if (inp == 'confirmPassword') {
        this.setState({
          signupConfirmPasswordError: {
            message: 'VALIDATIONS.VALID_CONFIRM_PASSWORD',
          },
        });
      } else if (inp == 'mobile') {
        this.setState({
          signupMobileError: {
            message: 'VALIDATIONS.VALID_MOBILE',
          },
        });
      } else if (inp == 'cardNumber') {
        this.setState({
          signupCardNumberError: {
            message: 'VALIDATIONS.VALID_DOCUMENT_NUMBER',
          },
        });
      }
      // } else if (inp == 'name') {
      //   if (text.length < 4) {
      //     this.setState({
      //       signupNameError: {
      //         message: 'VALIDATIONS.VALID_NAME_LENGTH',
      //       },
      //     });
      //   }
    } else if (inp == 'email') {
      if (!CommonService.isEMailValid(text)) {
        this.setState({
          signupEmailError: {
            message: 'VALIDATIONS.VALID_EMAIL_VALIDATION',
          },
        });
      }
    } else if (inp == 'password') {
      if (this.state.signupPassword.length < 4) {
        this.setState({
          signupPasswordError: {
            message: 'VALIDATIONS.VALID_PASSWORD_LENGTH',
          },
        });
      }
    } else if (inp == 'confirmPassword') {
      if (this.state.signupPassword != this.state.signupConfirmPassword) {
        this.setState({
          signupConfirmPasswordError: {
            message: 'VALIDATIONS.VALID_CONFIRM_PASSWORD_VALID',
          },
        });
      }
    } else if (inp == 'mobile') {
      if (this.state.signupMobile.toString().length != 9) {
        this.setState({
          signupMobileError: {
            message: 'VALIDATIONS.VALID_MOBILE_LENGTH_9',
          },
        });
      }
    } else if (inp == 'cardNumber') {
      let cardNumber = this.state.signupCardType.value == 7 ? CommonService.formatRut(text) : text;
      // let cardNumber = CommonService.formatRut(text);
      this.setState({
        signupCardNumber: cardNumber,
      });
      if (
        !CommonService.isRutValid(this.state.signupCardNumber) &&
        this.state.signupCardType.value == 7
      ) {
        this.setState({
          signupCardNumberError: {
            message: 'VALIDATIONS.VALID_RUT',
          },
        });
      }
    }
  };

  isSignupValid = () => {
    let errorCount = 0;

    if (!this.state.signupName) {
      this.setState({
        signupNameError: {
          message: 'VALIDATIONS.VALID_NAME',
        },
      });
      errorCount++;
      // } else if (this.state.signupName.length < 4) {
      //   this.setState({
      //     signupNameError: {
      //       message: 'VALIDATIONS.VALID_NAME_LENGTH',
      //     },
      //   });
      //   errorCount++;
    }
    if (!this.state.signupEmail) {
      this.setState({
        signupEmailError: {
          message: 'VALIDATIONS.VALID_EMAIL',
        },
      });
      errorCount++;
    } else if (!CommonService.isEMailValid(this.state.signupEmail)) {
      this.setState({
        signupEmailError: {
          message: 'VALIDATIONS.VALID_EMAIL_VALIDATION',
        },
      });
      errorCount++;
    }
    if (!this.state.signupPassword) {
      this.setState({
        signupPasswordError: {
          message: 'VALIDATIONS.VALID_PASSWORD',
        },
      });
      errorCount++;
    } else if (this.state.signupPassword.length < 4) {
      this.setState({
        signupPasswordError: {
          message: 'VALIDATIONS.VALID_PASSWORD_LENGTH',
        },
      });
      errorCount++;
    }
    if (this.state.signupPassword != this.state.signupConfirmPassword) {
      this.setState({
        signupConfirmPasswordError: {
          message: 'VALIDATIONS.VALID_CONFIRM_PASSWORD',
        },
      });
      errorCount++;
    }
    if (!this.state.signupMobile) {
      this.setState({
        signupMobileError: {
          message: 'VALIDATIONS.VALID_MOBILE',
        },
      });
      errorCount++;
    } else if (this.state.signupMobile.toString().length != 9) {
      this.setState({
        signupMobileError: {
          message: 'VALIDATIONS.VALID_MOBILE_LENGTH_9',
        },
      });
      errorCount++;
    }
    // if(!this.state.gender){
    //     this.setState({
    //         genderError:{
    //             message:'Seleccionar Género'
    //         }
    //     })
    //     errorCount++;
    // }
    if (!this.state.signupCardNumber) {
      this.setState({
        signupCardNumberError: {
          message: 'VALIDATIONS.VALID_DOCUMENT_NUMBER',
        },
      });
      errorCount++;
    } else if (
      !CommonService.isRutValid(this.state.signupCardNumber) &&
      this.state.signupCardType == 7
    ) {
      this.setState({
        signupCardNumberError: {
          message: 'VALIDATIONS.VALID_RUT',
        },
      });
      errorCount++;
    }
    if (!this.state.recieveOffer) {
      this.setState({
        termsAndCondionsError: {
          message: 'VALIDATIONS.SELECT_TNC',
        },
      });
      errorCount++;
    }
    if (errorCount <= 0) {
      return true;
    }
    return false;
  };

  signup = () => {
    if (!this.isSignupValid()) {
      return;
    }

    this.setState({ signupErrorMessage: null });

    let data = {
      name: this.state.signupName,
      last_name: this.state.signupLastName,
      email: this.state.signupEmail,
      password: this.state.signupPassword,
      phone: this.state.signupMobile,
      identity_type: this.state.signupCardType.value,
      identity_val: this.state.signupCardNumber,
      referrer_code: this.state.referCode ? this.state.referCode : '',
      gender: this.state.gender,
      // gender: this.state.gender || 'M',

      // device_details: this.props.data.deviceInfo
    };

    if (this.oauth_account && this.oAuthType) {
      data.oauth_account = this.oauth_account;
      data.oauth_token = this.oauth_token;
      data.oauth_type = this.oAuthType;
      data.image_url = this.oAuthPic;
      data.auth_info = { email: this.oAuthEmail };
    }

    this.props.actions.signup({ url: ApiUrls.register(), data: data }).then(
      res => {
        this.onSignupResponse(res);
      },
      err => {
        this.onSignupResponse(err);
      },
    );
  };

  onSignupResponse = result => {
    if (result.data && result.data.error) {
      this.setState({
        signupErrorMessage: result.data.error,
      });
      // this.onHide();
      return;
    } else if (result.data && result.data.id) {
      // GlobalService.setGlobal('token',result.data.auth_token);
      // global.token = result.data.id;
      // CommonService.storeLoginDetails(result.data, this.props.actions.setLoginData);
      if (result.data.auth_token) {
        global.token = result.data.auth_token;
        CommonService.storeLoginDetails(result.data, this.props.actions.setLoginData);
        setTimeout(() => {
          // CommonService.toast.show("¡Sesión iniciada éxitosamente!");
          if (!CommonService.isBookingScreen() && !CommonService.isATBookingScreen())
            this.props.history.push('/my-account');
          this.setState({
            email: null,
            password: null,
          });
          if (CommonService.isBookingScreen()) {
            this.fillPassengerAutoData();
          }
          if (CommonService.isATBookingScreen()) {
            // this.props.fillAtData()
          }
        }, 300);
      }
      setTimeout(() => {
        // CommonService.toast.show("¡Sesión iniciada éxitosamente!");
        this.setState({
          signupName: null,
          signupEmail: null,
          signupMobile: null,
          signupPassword: null,
          signupCardNumber: null,
          successfullRegistration: true,
        });
        this.resetData();
        this.props.onSignupSuccess();
      }, 300);
    }
  };

  responseFacebook = res => {
    console.log('responseFacebook', res);
    if (res.name) {
      this.setState({
        signupName: res.name.split(' ')[0],
        signupNameError: null,
      });
      if (res.name.split(' ')[1]) {
        this.setState({
          signupLastName: res.name.split(' ')[1],
          signupLastNameError: null,
        });
      }
    }
    if (res.email) {
      this.setState({
        signupEmail: res.email,
        signupEmailError: null,
        disableEmail: true,
      });
    }
    this.oauth_account = res.userID;
    this.oauth_token = res.accessToken;
    this.facebookData = res;
  };

  loginResponseFacebook = res => {
    this.oauth_account = null;
    this.oauth_token = null;
    this.oAuthLogin = false;
    if (res.email) {
      this.setState({
        email: res.email,
        emailError: null,
        disableEmail: true,
      });
    }
    this.oAuthLogin = true;
    this.oAuthType = 'facebook';
    this.oauth_account = res.userID;
    this.oauth_token = res.accessToken;
    this.facebookData = res;
    if (res.first_name) this.oAuthName = res.first_name.trim();
    if (res.last_name || res.middle_name)
      this.oAuthLastName = (res.middle_name || '').trim() + ' ' + (res.last_name || '').trim();
    this.oAuthEmail = res.email;
    if (res.picture && res.picture.data) {
      this.oAuthPic = res.picture.data.url;
    }

    if (this.oauth_account) this.loginApiCall();

    console.log('Data received from FB', res);
  };

  responseGoogle = res => {
    console.log('Data received from google', res);
    this.oauth_account = null;
    this.oauth_token = null;
    this.oAuthLogin = false;
    if (res.profileObj) {
      this.oauth_account = res.googleId;
      this.oauth_token = res.tokenId;
      // this.facebookData = res;
      this.oAuthLogin = true;
      this.oAuthType = 'google';
      this.oAuthName = res.profileObj.givenName;
      this.oAuthLastName = res.profileObj.familyName;
      this.oAuthEmail = res.profileObj.email;
      this.oAuthPic = res.profileObj.imageUrl;
      localStorage.setItem('googleAccessToken', res.accessToken);
    }
    // alert(JSON.stringify(res));
    if (this.oauth_account) this.loginApiCall();
  };

  loginApiCall = () => {
    let data = {
      email: this.state.email,
      password: this.state.password,
    };
    // if (this.oAuthLogin) {
    data.oauth_account = this.oauth_account;
    data.oauth_token = this.oauth_token;
    data.oauth_type = this.oAuthType;
    data.auth_info = { email: this.oAuthEmail };
    // }

    this.props.actions.login({ url: ApiUrls.login(), data: data }).then(
      res => {
        this.onResponse(res);
      },
      err => {
        this.onResponse(err);
      },
    );
  };

  generateOtp = ({ countryCode, mobile, type }) => {
    if (!this.state.signupMobile) {
      this.setState({
        otpMobileError: {
          message: 'VALIDATIONS.VALID_MOBILE',
        },
      });
      return;
    } else if (this.state.signupMobile.toString().length != 9) {
      this.setState({
        otpMobileError: {
          message: 'VALIDATIONS.VALID_MOBILE_LENGTH_9',
        },
      });
      return;
    }
    this.props.actions
      .generateOtp({
        url: ApiUrls.generateOtp(),
        data: {
          country_code: countryCode,
          mobile_number: mobile.toString(),
          cat_type: type,
        },
      })
      .then(
        res => {
          if (res.data && res.data.status === 200) {
            this.backView.push(this.state.modalType);
            this.setState({
              modalType: 'OTP-REGISTER',
            });
          } else if (res.data) {
            this.setState({
              signupMobileError: {
                message: res.data.message,
              },
            });
            // this.setState({showSuccessFailureModal:true,showSuccessFailureModalStatus:false,showSuccessFailureModalBodyText:res.data.message})
          }
        },
        err => {
          // this.onResponse(err);
        },
      );
  };

  renderSignupMobileCard = () => {
    return (
      <div className="kupos-card log-in-card signup">
        <div
          className="back-arrow"
          onClick={() => {
            this.setState({ modalType: this.backView.pop() });
            this.resetData();
          }}
        >
          <SvgHome name="back-arrow" />
        </div>

        <div className="user-icon">
          <SvgCircularIcons name="user-orange" />
        </div>
        <div className="bold-text center-text font16">{this.props.t('PROFILE.SIGN_UP')}</div>
        <div style={{ marginTop: 10 }} className="center-text font11 light-text">
          {this.props.t('PROFILE.CREATE_ACC_PHONE')}
        </div>
        <div className="login-signup-inputs row light-placeholder">
          <div className="login-signup-input  col-md-12 no-pad font11 mobile-signup-input">
            <div className="input-flag-img">
              <img src={'/images/chile-flag.png'} />
            </div>
            <KuposInput
              placeholder={this.props.t('PROFILE.ENTER_PHONE')}
              t={this.props.t}
              onChange={text => this.onSignupInputChange(text, 'mobile')}
              onBlur={text => this.onSignupInputBlur(text, 'mobile')}
              focused={true}
              value={this.state.signupMobile}
            />
          </div>
          <div className="errorMessageInput font8">
            {this.state.otpMobileError ? this.props.t(this.state.otpMobileError.message) : ''}
          </div>
        </div>
        <div className="login-signup-button font12">
          <button
            disabled={this.props.common.generateOtpPending}
            className="kupos-button"
            onClick={() =>
              this.generateOtp({
                countryCode: this.state.signupCallingCode,
                mobile: this.state.signupMobile,
                type: 'sign_up',
              })
            }
          >
            {this.props.common.generateOtpPending ? (
              <div className="loader-cricle"></div>
            ) : (
              <span>{this.props.t('PROFILE.SIGN_UP')}</span>
            )}
          </button>
        </div>
        <div className="register-link font10">
          <span>{this.props.t('PROFILE.ALREADY_ACCOUNT')}</span>&nbsp;
          <a
            onClick={() => {
              this.setState({ modalType: this.backView.pop() });
            }}
          >
            {this.props.t('PROFILE.LOG_IN')}
          </a>
        </div>
      </div>
    );
  };

  validateOtp = ({ countryCode, mobile, type }) => {
    if (!this.state.otp1 || !this.state.otp2 || !this.state.otp3 || !this.state.otp4) {
      return;
    }
    try {
      this.props.actions
        .validateOtp({
          url: ApiUrls.validateOtp(),
          data: {
            country_code: countryCode,
            mobile_number: mobile.toString(),
            cat_type: type,
            code:
              this.state.otp1 + '' + this.state.otp2 + '' + this.state.otp3 + '' + this.state.otp4,
          },
        })
        .then(
          res => {
            if (res.data && res.data.status === 200) {
              this.backView.push(this.state.modalType);
              this.setState({
                modalType: type == 'sign_up' ? 'REGISTER' : 'RESET-PASSWORD',
              });
            } else {
              console.log("Response is ", res)
              this.setState({
                // showSuccessFailureModal: true,
                // showSuccessFailureModalStatus: false,
                // showSuccessFailureModalBodyText: res.data.message,
                validateOtpError: {
                  message: res.data.message,
                }
              });
            }
          },
          err => {
            // this.onResponse(err);
          },
        );
    } catch (e) {
      console.log(e);
    }

    // this.props.forgotPassword({callback:(result) => this.onResponse(result),data:data})
  };

  renderOtpView = () => {
    return (
      <div className="login-signup-inputs row light-placeholder">
        <div className="login-signup-input  col-md-12 no-pad font11 mobile-signup-input no-border">
          <div className="flex-row otp-inps">
            <input
              value={this.state.otp1}
              ref="otp1"
              autoCorrect={false}
              type="password"
              onKeyUp={el => {
                let key = el.keyCode || el.charCode;
                if (key == 39) this.refs.otp2.focus();
              }}
              onChange={el => {
                this.setState({ otp1: CommonService.phoneNumberFormat(el.target.value, 1) });
                if (el.target.value) this.refs.otp2.focus();
                else this.refs.otp1.focus();
              }}
            />
            <input
              value={this.state.otp2}
              ref="otp2"
              autoCorrect={false}
              type="password"
              onKeyUp={el => {
                let key = el.keyCode || el.charCode;
                if (key == 8 || key == 46) this.refs.otp1.focus();
                if (key == 37) this.refs.otp1.focus();
                if (key == 39) this.refs.otp3.focus();
              }}
              onChange={el => {
                this.setState({ otp2: CommonService.phoneNumberFormat(el.target.value, 1) });
                if (el.target.value) this.refs.otp3.focus();
                // else this.refs.otp1.focus();
              }}
            />
            <input
              value={this.state.otp3}
              ref="otp3"
              autoCorrect={false}
              type="password"
              onKeyUp={el => {
                let key = el.keyCode || el.charCode;
                if (key == 8 || key == 46) this.refs.otp2.focus();
                if (key == 37) this.refs.otp2.focus();
                if (key == 39) this.refs.otp4.focus();
              }}
              onChange={el => {
                this.setState({ otp3: CommonService.phoneNumberFormat(el.target.value, 1) });
                if (el.target.value) this.refs.otp4.focus();
                // else this.refs.otp2.focus();
              }}
            />
            <input
              value={this.state.otp4}
              ref="otp4"
              autoCorrect={false}
              type="password"
              onKeyUp={el => {
                let key = el.keyCode || el.charCode;
                if (key == 8 || key == 46) this.refs.otp3.focus();
                if (key == 37) this.refs.otp3.focus();
              }}
              onChange={el => {
                this.setState({ otp4: CommonService.phoneNumberFormat(el.target.value, 1) });
                // this.refs.otp4.focus();
                if (el.target.value) this.refs.otp4.focus();
                // else this.refs.otp3.focus();
              }}
            />
          </div>
        </div>
        <div className="errorMessageInput font8 margin-auto">
          {this.state.validateOtpError ? this.props.t(this.state.validateOtpError.message) : ''}
        </div>
      </div>
    );
  };

  renderSignupOTPCard = () => {
    return (
      <div className="kupos-card log-in-card signup">
        <div
          className="back-arrow"
          onClick={() => {
            this.setState({ modalType: this.backView.pop() });
          }}
        >
          <SvgHome name="back-arrow" />
        </div>

        <div className="user-icon">
          <img src="/images/circular/forgot-circle.png" />
        </div>
        <div className="bold-text center-text font16">Verifica tu teléfono</div>
        <div style={{ marginTop: 10 }} className="center-text font11 light-text">
          Ingresa el código de 4 dígitos que enviamos al número registrado.
        </div>
        {this.renderOtpView()}
        <div className="login-signup-button font12">
          <button
            disabled={this.props.common.validateOtpPending}
            className="kupos-button"
            onClick={() =>
              this.validateOtp({
                countryCode: this.state.signupCallingCode,
                mobile: this.state.signupMobile,
                type: 'sign_up',
              })
            }
          >
            {this.props.common.validateOtpPending ? (
              <div className="loader-cricle"></div>
            ) : (
              <span>{this.props.t('RESULTS_PAGE.CONTINUE')}</span>
            )}
          </button>
        </div>
        <div className="register-link font10">
          <div>¿No recibiste el código? </div>
          <a
            onClick={() =>
              this.generateOtp({
                countryCode: this.state.signupCallingCode,
                mobile: this.state.signupMobile,
                type: 'sign_up',
              })
            }
          >
            Haz clic aquí para reenviar.
          </a>
        </div>
      </div>
    );
  };

  renderSignupCard = () => {
    return (
      <div className="kupos-card log-in-card signup">
        <div
          className="back-arrow"
          onClick={() => {
            this.setState({ modalType: this.backView.pop() });
            this.resetData();
          }}
        >
          <SvgHome name="back-arrow" />
        </div>

        <div className="bold-text center-text font16">{this.props.t('PROFILE.CREATE_ACCOUNT')}</div>
        <div className="login-signup-inputs row light-placeholder">
          <div className="login-signup-input col-md-6 no-pad font11">
            <KuposInput
              className="kupos-border"
              placeholder={this.props.t('PROFILE.ENTER_NAME')}
              t={this.props.t}
              onChange={text => this.onSignupInputChange(text, 'name')}
              onBlur={text => this.onSignupInputBlur(text, 'name')}
              value={this.state.signupName}
              error={this.state.signupNameError ? true : false}
              errorMessage={
                this.state.signupNameError ? this.props.t(this.state.signupNameError.message) : ''
              }
            />
          </div>

          <div className="login-signup-input col-md-6 no-pad font11">
            <KuposInput
              className="kupos-border"
              placeholder={this.props.t('PROFILE.ENTER_LAST_NAME')}
              t={this.props.t}
              onChange={text => this.onSignupInputChange(text, 'lastname')}
              onBlur={text => this.onSignupInputBlur(text, 'lastname')}
              value={this.state.signupLastName}
              error={this.state.signupLastNameError ? true : false}
              errorMessage={
                this.state.signupLastNameError
                  ? this.props.t(this.state.signupLastNameError.message)
                  : ''
              }
            />
          </div>
          <div className="login-signup-input col-md-6 no-pad font11">
            {/* {this.idCardTypes.map((item,key) => (
              <div className="radio-inp" key={key}>
                <KuposRadio
                  onChange={() => this.onSignupInputChange(item, 'cardType')}
                  checked={item.selected}
                  label={item.label}
                />
              </div>
            ))} */}
            <KuposSelectBox
              readonly={true}
              height={40}
              onChange={val => this.setState({ signupCardType: val })}
              options={this.idCardTypesSelect}
              selectedOption={this.state.signupCardType}
              t={this.props.t}
            />
          </div>
          <div className="login-signup-input col-md-6 no-pad font11">
            <KuposInput
              className="kupos-border "
              placeholder={this.props.t('PROFILE.DOCUMENT_NUMBER')}
              t={this.props.t}
              onChange={text => this.onSignupInputChange(text, 'cardNumber')}
              onBlur={text => this.onSignupInputBlur(text, 'cardNumber')}
              value={this.state.signupCardNumber}
              error={this.state.signupCardNumberError ? true : false}
              errorMessage={
                this.state.signupCardNumberError
                  ? this.props.t(this.state.signupCardNumberError.message)
                  : ''
              }
            />
          </div>

          <div className="login-signup-input col-md-12 no-pad font11">
            <KuposInput
              className="kupos-border"
              t={this.props.t}
              placeholder={this.props.t('PROFILE.ENTER_EMAIL')}
              onChange={text => this.onSignupInputChange(text, 'email')}
              onBlur={text => this.onSignupInputBlur(text, 'email')}
              value={this.state.signupEmail}
              error={this.state.signupEmailError ? true : false}
              errorMessage={
                this.state.signupEmailError ? this.props.t(this.state.signupEmailError.message) : ''
              }
              disabled={this.oAuthEmail && this.state.signupEmail}
            />
          </div>

          <div className="login-signup-input col-md-12 no-pad font11">
            {/* <div className="country-code-and-phone-container"> */}
            {/* <div className="input-country-code">
                <KuposInput
                  className="kupos-border"
                  placeholder={"+51"}
                  style={{width:60}}
                  inputWidth={{minWidth:60}}
                  // placeholder={this.props.t('PROFILE.PHONE_NUMBER')}
                  t={this.props.t}
                  // onChange={text => this.onSignupInputChange(text, 'mobile')}
                  // onBlur={text => this.onSignupInputBlur(text, 'mobile')}
                  // value={this.state.signupMobile}
                  // error={this.state.signupMobileError ? true : false}
                  // errorMessage={
                  //   this.state.signupMobileError
                  //     ? this.props.t(this.state.signupMobileError.message)
                  //     : ''
                  // }
                />
              </div> */}
            {/* <div className="input-phone-number"> */}
            <KuposInput
              className="kupos-border"
              placeholder={this.props.t('PROFILE.PHONE_NUMBER')}
              t={this.props.t}
              onChange={text => this.onSignupInputChange(text, 'mobile')}
              onBlur={text => this.onSignupInputBlur(text, 'mobile')}
              focused={true}
              value={this.state.signupMobile}
              error={this.state.signupMobileError ? true : false}
              errorMessage={
                this.state.signupMobileError
                  ? this.props.t(this.state.signupMobileError.message)
                  : ''
              }
              disabled={this.state.loginType == 'mobile' && this.state.signupMobile}
              codeandphone={true}
              countryCode={this.state.signupCallingCode}
              onCountryCodeChange={text => this.onSignupInputChange(text, 'country_code')}
            />
            {/* </div> */}
            {/* </div> */}
          </div>

          <div className="login-signup-input col-md-6 no-pad font11">
            <KuposInput
              className="kupos-border"
              type="password"
              t={this.props.t}
              placeholder={this.props.t('PROFILE.ENTER_PASSWORD')}
              onChange={text => this.onSignupInputChange(text, 'password')}
              onBlur={text => this.onSignupInputBlur(text, 'password')}
              value={this.state.signupPassword}
              error={this.state.signupPasswordError ? true : false}
              errorMessage={
                this.state.signupPasswordError
                  ? this.props.t(this.state.signupPasswordError.message)
                  : ''
              }
            />
          </div>
          <div className="login-signup-input col-md-6 no-pad font11">
            <KuposInput
              className="kupos-border"
              type="password"
              t={this.props.t}
              placeholder={this.props.t('PROFILE.CONFIRM_PASSWORD')}
              onChange={text => this.onSignupInputChange(text, 'confirmPassword')}
              onBlur={text => this.onSignupInputBlur(text, 'confirmPassword')}
              value={this.state.signupConfirmPassword}
              error={this.state.signupConfirmPasswordError ? true : false}
              errorMessage={
                this.state.signupConfirmPasswordError
                  ? this.props.t(this.state.signupConfirmPasswordError.message)
                  : ''
              }
            />
          </div>
        </div>

        <div className="login-terms flex-row font10">
          <span className="flex-row align-center">
            <KuposCheckbox
              t={this.props.t}
              checked={this.state.recieveOffer}
              onChange={() =>
                this.setState({
                  recieveOffer: !this.state.recieveOffer,
                  termsAndCondionsError: false,
                })
              }
            />
            <a onClick={() => this.setState({ showTNCModel: true })}>
              {this.props.t('PROFILE.TNC')}
            </a>
          </span>
          {this.state.termsAndCondionsError ? (
            <div className="errorMessageInput font8">
              {this.props.t(this.state.termsAndCondionsError.message)}
            </div>
          ) : null}
        </div>
        {this.state.signupErrorMessage ? (
          <div className="login-server-error font10 secondary-text">
            {this.state.signupErrorMessage}
          </div>
        ) : null}

        <div className="login-signup-button font12">
          <button
            disabled={this.props.common.signupPending}
            className="kupos-button"
            onClick={this.signup}
          >
            {this.props.common.signupPending ? (
              <div className="loader-cricle"></div>
            ) : (
              <span>{this.props.t('PROFILE.SIGN_UP')}</span>
            )}
          </button>
        </div>
      </div>
    );
  };

  onForgotInputChange = (text, inp) => {
    if (inp == 'email') {
      this.setState({
        forgotErrorMessage: null,
        forgotEmailError: null,
        forgotEmail: text,
      });
    } else if (inp == 'mobile') {
      this.setState({
        forgotErrorMessage: null,
        otpMobileError: null,
        forgotMobile: CommonService.phoneNumberFormat(text),
      });
    }
  };

  onForgotInputBlur = (text, inp) => {
    if (!text) {
      if (inp == 'email') {
        this.setState({
          forgotEmailError: {
            message: 'VALIDATIONS.VALID_EMAIL',
          },
        });
      }
    } else if (inp == 'email') {
      if (!CommonService.isEMailValid(text)) {
        this.setState({
          forgotEmailError: {
            message: 'VALIDATIONS.VALID_EMAIL_VALIDATION',
          },
        });
      }
    }
  };

  resetPassword = () => {
    let errorCount = 0;
    if (this.state.selectedForgotTab == 2) {
      if (!this.state.forgotEmail) {
        this.setState({
          forgotEmailError: {
            message: 'VALIDATIONS.VALID_EMAIL',
          },
        });
        errorCount++;
      } else if (!CommonService.isEMailValid(this.state.forgotEmail)) {
        this.setState({
          forgotEmailError: {
            message: 'VALIDATIONS.VALID_EMAIL',
          },
        });
        errorCount++;
      }
      if (errorCount > 0) {
        return;
      }
      let data = {
        user: { email: this.state.forgotEmail },
      };

      this.props.actions.resetPassword({ url: ApiUrls.resetPassword(), data: data }).then(
        res => {
          this.onForgotResponse(res);
        },
        err => {
          this.onForgotResponse(err);
        },
      );
    } else {
      let errorCount = 0;
      if (!this.state.forgotMobile) {
        this.setState({
          otpMobileError: {
            message: 'Ingresa tu número de teléfono',
          },
        });
        errorCount++;
      } else if (this.state.forgotMobile.toString().length != 9) {
        this.setState({
          otpMobileError: {
            message: 'El teléfono debe contener mínimo 9 dígitos',
          },
        });
        errorCount++;
      }
      if (errorCount > 0) {
        return;
      }

      this.props.actions
        .generateOtp({
          url: ApiUrls.generateOtp(),
          data: {
            country_code: this.state.signupCallingCode,
            mobile_number: this.state.forgotMobile.toString(),
            cat_type: 'change_pwd_valid',
          },
        })
        .then(
          res => {
            if (res.data && res.data.status === 200) {
              this.backView.push(this.state.modalType);
              this.setState({
                modalType: 'OTP-FORGOT',
              });
            } else {
              this.setState({
                otpMobileError: {
                  message: res.data.message,
                },
              });
              // this.setState({showSuccessFailureModal:true,showSuccessFailureModalStatus:false,showSuccessFailureModalBodyText:res.data.message})
            }
          },
          err => {
            // this.onResponse(err);
          },
        );
    }
  };

  onForgotResponse = result => {
    if (result.data && result.data.error) {
      this.setState({
        forgotEmailError: {
          message: result.data.error,
        },
      });
      return;
    } else if (result.data.message) {
      this.setState({
        forgotEmail: null,
        showForgotSuccess: true,
      });
    }
  };

  renderForgotPasswordCard = () => {
    return !this.state.showForgotSuccess ? (
      <div className="kupos-card log-in-card forgot">
        <div
          className="back-arrow"
          onClick={() => {
            this.setState({
              modalType: this.backView.pop(),
              selectedForgotTab: 1,
              forgotEmail: null,
              forgotEmailError: null,
              forgotMobile: null,
              otpMobileError: null,
              forgotErrorMessage: null,
            });
          }}
        >
          <SvgHome name="back-arrow" />
        </div>

        <div className="user-icon">
          <SvgCircularIcons name="forgot-password" />
        </div>
        <div className="bold-text center-text font16">
          {this.props.t('PROFILE.RESET_PASSWORD_HEADING')}
        </div>

        <br />
        {this.state.selectedForgotTab == 1 ? (
          <div className="center-text font11 light-text">
            {this.props.t('PROFILE.RESET_DETAILS_PHONE')}
          </div>
        ) : (
          <div className="center-text font11 light-text">
            {this.props.t('PROFILE.RESET_DETAILS')}
          </div>
        )}

        <div className="forgot-tabs-container">
          <div className="kupos-tabs-container font10">
            <div
              className={'kupos-tab-item ' + (this.state.selectedForgotTab == 1 ? 'active' : '')}
              onClick={() =>
                this.setState({
                  selectedForgotTab: 1,
                  forgotEmail: null,
                  forgotEmailError: null,
                  forgotMobile: null,
                  otpMobileError: null,
                  forgotErrorMessage: null,
                })
              }
            >
              {this.props.t('HOME.PHONE_NUMBER')}
            </div>
            <div
              className={'kupos-tab-item ' + (this.state.selectedForgotTab == 2 ? 'active' : '')}
              onClick={() =>
                this.setState({
                  selectedForgotTab: 2,
                  forgotEmail: null,
                  forgotEmailError: null,
                  forgotMobile: null,
                  otpMobileError: null,
                  forgotErrorMessage: null,
                })
              }
            >
              {this.props.t('HOME.EMAIL_ID')}
            </div>
          </div>
        </div>
        <div className="login-signup-inputs">
          {this.state.selectedForgotTab == 2 ? (
            <div className="login-signup-input font11">
              <KuposInput
                className="kupos-border light-placeholder"
                placeholder={this.props.t('PROFILE.ENTER_EMAIL_FULL')}
                t={this.props.t}
                onChange={text => this.onForgotInputChange(text, 'email')}
                onBlur={text => this.onForgotInputBlur(text, 'email')}
                value={this.state.forgotEmail}
                error={this.state.forgotEmailError ? true : false}
                errorMessage={
                  this.state.forgotEmailError
                    ? this.props.t(this.state.forgotEmailError.message)
                    : ''
                }
              />
            </div>
          ) : (
            <div className="login-signup-input light-placeholder">
              <div
                className="login-signup-input  col-md-12 no-pad font11 mobile-signup-input"
                style={{ marginBottom: 0 }}
              >
                <div className="input-flag-img">
                  <img src={'/images/chile-flag.png'} />
                </div>
                <KuposInput
                  placeholder={this.props.t('PROFILE.ENTER_PHONE')}
                  t={this.props.t}
                  onChange={text => this.onForgotInputChange(text, 'mobile')}
                  onBlur={text => this.onForgotInputBlur(text, 'mobile')}
                  focused={true}
                  value={this.state.forgotMobile}
                />
              </div>
              <div className="errorMessageInput font8">
                {this.state.otpMobileError ? this.props.t(this.state.otpMobileError.message) : ''}
              </div>
            </div>
          )}
          {this.state.forgotErrorMessage ? (
            <div className="login-server-error font10 secondary-text">
              {this.state.forgotErrorMessage}
            </div>
          ) : null}
        </div>

        <div className="login-signup-button font12">
          <button
            disabled={this.props.common.resetPasswordPending}
            className="kupos-button"
            onClick={this.resetPassword}
          >
            {this.props.common.resetPasswordPending ? (
              <div className="loader-cricle"></div>
            ) : (
              <span>{this.props.t('HOME.SEND')}</span>
            )}
          </button>
        </div>
      </div>
    ) : (
      <div className="kupos-card log-in-card forgot-success">
        <div className="user-icon">
          <img src="/images/icons/circular-icons/icon-success.png" />
        </div>
        <div className="bold-text center-text font16">{this.props.t('PROFILE.ALL_SET')}</div>
        <br />
        <div className="center-text font11 light-text">
          {this.props.t('PROFILE.RESET_SENT_EMAIL')}
        </div>
        <div className="login-signup-button font12">
          <button className="kupos-button" onClick={this.onHide}>
            <span>{this.props.t('PROFILE.OK_CONTINUE')}</span>
          </button>
        </div>
      </div>
    );
  };

  renderForgotOTPCard = () => {
    return (
      <div className="kupos-card log-in-card signup">
        <div
          className="back-arrow"
          onClick={() => {
            this.setState({ modalType: this.backView.pop() });
          }}
        >
          <SvgHome name="back-arrow" />
        </div>

        <div className="user-icon">
          <SvgCircularIcons name="forgot-password" />
        </div>
        {/* <div className="bold-text center-text font16">
          {this.props.t('PROFILE.RESET_PASSWORD')}
        </div> */}
        <div className="bold-text center-text font16">{this.props.t('PROFILE.VERIFY_PHONE')}</div>
        <div style={{ marginTop: 10 }} className="center-text font11 light-text">
          {this.props.t('PROFILE.VERIFY_PHONE_DESC')}
        </div>
        {this.renderOtpView()}
        <div className="login-signup-button font12">
          <button
            disabled={this.props.common.validateOtpPending}
            className="kupos-button"
            onClick={() =>
              this.validateOtp({
                countryCode: this.state.signupCallingCode,
                mobile: this.state.forgotMobile,
                type: 'change_pwd_valid',
              })
            }
          >
            {this.props.common.validateOtpPending ? (
              <div className="loader-cricle"></div>
            ) : (
              <span>{this.props.t('RESULTS_PAGE.CONTINUE')}</span>
            )}
          </button>
        </div>
        <div className="register-link font10">
          <div>{this.props.t('PROFILE.NOT_RECEIVED_CODE')}</div>
          <a onClick={() => this.resetPassword()}>{this.props.t('PROFILE.CLICK_TO_RESEND')}</a>
        </div>
      </div>
    );
  };

  onResetInputChange = (text, inp) => {
    if (inp == 'password') {
      this.setState({
        resetPasswordError: null,
        resetPassword: text,
      });
    } else if (inp == 'confirmPassword') {
      this.setState({
        resetConfirmPasswordError: null,
        resetConfirmPassword: text,
      });
    }
  };

  onResetInputBlur = (text, inp) => {
    if (!text) {
      if (inp == 'password') {
        this.setState({
          resetPasswordError: {
            message: 'VALIDATIONS.VALID_PASSWORD',
          },
        });
      } else if (inp == 'confirmPassword') {
        this.setState({
          resetConfirmPasswordError: {
            message: 'VALIDATIONS.VALID_CONFIRM_PASSWORD',
          },
        });
      }
    } else if (inp == 'password') {
      if (this.state.resetPassword.length < 4) {
        this.setState({
          resetPasswordError: {
            message: 'VALIDATIONS.VALID_PASSWORD_LENGTH',
          },
        });
      }
    } else if (inp == 'confirmPassword') {
      if (this.state.resetPassword != this.state.resetConfirmPassword) {
        this.setState({
          resetConfirmPasswordError: {
            message: 'VALIDATIONS.VALID_CONFIRM_PASSWORD_VALID',
          },
        });
      }
    }
  };

  changePassword = () => {
    try {
      let errorCount = 0;
      if (!this.state.resetPassword) {
        this.setState({
          resetPasswordError: {
            message: 'Ingresa tu contraseña',
          },
        });
        errorCount++;
      } else if (this.state.resetPassword.length < 4) {
        this.setState({
          resetPasswordError: {
            message: 'La contraseña debe contener mínimo 6 dígitos',
          },
        });
        errorCount++;
      }
      if (this.state.resetPassword != this.state.resetConfirmPassword) {
        this.setState({
          resetConfirmPasswordError: {
            message: 'Las contraseñas deben coincidir',
          },
        });
        errorCount++;
      }
      if (errorCount > 0) {
        return;
      }
      this.props.actions
        .validateOtp({
          url: ApiUrls.validateOtp(),
          data: {
            country_code: this.state.signupCallingCode,
            mobile_number: this.state.forgotMobile.toString(),
            cat_type: 'change_pwd',
            code:
              this.state.otp1 + '' + this.state.otp2 + '' + this.state.otp3 + '' + this.state.otp4,
            user: {
              password: this.state.resetPassword,
              password_confirmation: this.state.resetConfirmPassword,
            },
          },
        })
        .then(
          res => {
            if (res.data && res.data.status === 200) {
              this.backView = [];
              this.setState({
                showSuccessFailureModal: true,
                showSuccessFailureModalStatus: true,
                showSuccessFailureModalBodyText: res.data.message,
                modalType: 'LOGIN-OPTIONS',
              });
            } else {
              this.setState({
                showSuccessFailureModal: true,
                showSuccessFailureModalStatus: false,
                showSuccessFailureModalBodyText: res.data.message,
              });
            }
          },
          err => {
            // this.onResponse(err);
          },
        );
    } catch (e) {
      console.log(e);
    }
  };

  renderResetPasswordCard = () => {
    return (
      <div className="kupos-card log-in-card signup">
        <div
          className="back-arrow"
          onClick={() => {
            this.setState({ modalType: this.backView.pop() });
          }}
        >
          <SvgHome name="back-arrow" />
        </div>

        <div className="user-icon">
          <SvgCircularIcons name="forgot-password" />
        </div>
        <div className="bold-text center-text font16">{this.props.t('PROFILE.RESET_PASSWORD')}</div>
        <div style={{ marginTop: 10 }} className="center-text font11 light-text">
          {this.props.t('PROFILE.RESET_PASSWORD_DESC')}
        </div>
        <div className="login-signup-inputs">
          <div className="login-signup-input  col-md-12 no-pad font11">
            <KuposInput
              className="kupos-border"
              type="password"
              t={this.props.t}
              placeholder={this.props.t('PROFILE.ENTER_PASSWORD')}
              onChange={text => this.onResetInputChange(text, 'password')}
              onBlur={text => this.onResetInputBlur(text, 'password')}
              value={this.state.resetPassword}
              error={this.state.resetPasswordError ? true : false}
              errorMessage={
                this.state.resetPasswordError
                  ? this.props.t(this.state.resetPasswordError.message)
                  : ''
              }
            />
          </div>
          <div className="login-signup-input  col-md-12 no-pad font11">
            <KuposInput
              className="kupos-border"
              type="password"
              t={this.props.t}
              placeholder={this.props.t('PROFILE.CONFIRM_PASSWORD')}
              onChange={text => this.onResetInputChange(text, 'confirmPassword')}
              onBlur={text => this.onResetInputBlur(text, 'confirmPassword')}
              value={this.state.resetConfirmPassword}
              error={this.state.resetConfirmPasswordError ? true : false}
              errorMessage={
                this.state.resetConfirmPasswordError
                  ? this.props.t(this.state.resetConfirmPasswordError.message)
                  : ''
              }
            />
          </div>
        </div>
        <div className="login-signup-button font12">
          <button
            disabled={this.props.common.validateOtpPending}
            className="kupos-button"
            onClick={this.changePassword}
          >
            {this.props.common.validateOtpPending ? (
              <div className="loader-cricle"></div>
            ) : (
              <span>{this.props.t('RESULTS_PAGE.CONTINUE')}</span>
            )}
          </button>
        </div>
      </div>
    );
  };

  onHide = () => {
    setTimeout(() => {
      this.setState({
        modalType: 'LOGIN-OPTIONS',
        signupName: null,
        signupNameError: null,
        signupLastName: null,
        signupLastNameError: null,
        signupEmail: null,
        signupEmailError: null,
        signupMobile: null,
        signupMobileError: null,
        signupPassword: null,
        signupPasswordError: null,
        signupConfirmPassword: null,
        signupConfirmPasswordError: null,
        signupCardNumber: null,
        signupCardNumberError: null,
        emailError: null,
        passwordError: null,
        loginErrorMessage: null,
        forgotErrorMessage: null,
        signupErrorMessage: null,
        otp1: null,
        otp2: null,
        otp3: null,
        otp4: null,
        showForgotSuccess: false,
      });
    });
    this.props.onHide();
  };

  renderModalView = () => {
    switch (this.state.modalType) {
      case 'LOGIN-OPTIONS':
        return this.renderLoginOptions();
        break;
      case 'LOGIN':
        return this.renderLoginCard();
        // return this.renderResetPasswordCard();
        break;
      case 'REGISTER':
        return this.renderSignupCard();
        break;
      case 'MOBILE-REGISTER':
        return this.renderSignupMobileCard();
        break;
      case 'OTP-REGISTER':
        return this.renderSignupOTPCard();
        break;
      case 'OTP-FORGOT':
        return this.renderForgotOTPCard();
        break;
      case 'RESET-PASSWORD':
        return this.renderResetPasswordCard();
        break;
      case 'FORGOT':
        return this.renderForgotPasswordCard();
        break;
      default:
        return this.renderLoginOptions();
        break;
    }
  };

  render() {
    return (
      <div className="common-login-modal">
        <KuposModal
          showModal={this.props.showModal}
          onHide={this.onHide}
          size="md"
          ariaLabel="login-modal"
          backdrop={false}
        >
          <div className="login-signup-block">
            <div className="close-button pointer" onClick={this.onHide}>
              <SvgHome name="close" />
            </div>
            {this.renderModalView()}
          </div>
        </KuposModal>

        <SignUpSuccessModal
          showModal={this.state.successfullRegistration}
          onHide={() => {
            this.setState({ successfullRegistration: false });
            this.onHide();
          }}
          oAuthSignup={CommonService.isLoggedIn() ? true : false}
          t={this.props.t}
        />
        <KuposErrorSuccessModal
          showModal={this.state.showSuccessFailureModal}
          success={this.state.showSuccessFailureModalStatus}
          bodyText={this.state.showSuccessFailureModalBodyText}
          buttonText={'OK, CONTINUAR'}
          onButtonClick={() => {
            this.setState({
              showSuccessFailureModal: false,
              showSuccessFailureModalBodyText: null,
            });
          }}
        />
        <KuposModal
          ariaLabel="tnc-pnr-modal-outer"
          size={'xl'}
          onHide={() => this.setState({ showTNCModel: false })}
          showModal={this.state.showTNCModel}
        >
          {/* <div className="tnc-pnr-modal">
            <TermsAndConditions />
            <div
              className="close-button pointer"
              onClick={() => this.setState({ showTNCModel: false })}
            >
              <SvgHome name="close" />
            </div>
          </div> */}
        </KuposModal>
      </div>
    );
  }
}

export default LoginModal;
