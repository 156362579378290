import React, { Component } from 'react';
import { Map, InfoWindow, Marker, GoogleApiWrapper, Polygon, Polyline } from 'google-maps-react';
import PropTypes from 'prop-types';

import AppData from '../../services/appData';
import KuposInput from './KuposInput';
import ApiUrls from '../../services/apiUrls';
import KuposErrorSuccessModal from './KuposErrorSuccessModal';

var google;
export class KuposMap extends Component {
  static propTypes = {
    showPlaceSearch: PropTypes.bool,
    myLocation: PropTypes.object,
    markers: PropTypes.array,
    // getRechargeLocations: PropTypes.func,
    // t: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.state = {
      selectedPlace: {},
      showModal: false,
      mapCenter: {
        lat: 32.321,
        lng: -64.757,
      },
      myLocation: {
        lat: 32.321,
        lng: -64.757,
      },
    };
  }

  componentDidMount() {
    this.getLocation();
    // this.renderAutoComplete();
  }
  componentDidUpdate(prevProps) {
    // if (this.props !== prevProps.map) this.renderAutoComplete();
  }

  getLocation = () => {
    if (navigator.geolocation) {
      var options = { timeout: 60000 };
      navigator.geolocation.getCurrentPosition(this.showLocation, this.errorHandler, options);
    } else {
      console.log('Sorry, browser does not support geolocation!');
    }
  };

  getRechargeLocations = (lat, lng) => {
    this.props.getRechargeLocations({ url: ApiUrls.rechargeLocations(lat, lng) }).then(
      res => {
        if (res && res.length) {
          this.setState({ locations: res });
        } else {
          this.setState({ showModal: true });
        }
      },
      err => { },
    );
  };

  showLocation = position => {
    var latitude = position.coords.latitude;
    var longitude = position.coords.longitude;
    let loc = {
      lat: latitude,
      lng: longitude,
    };
    this.setState({ myLocation: loc, mapCenter: loc });
    console.log('Latitude : ' + latitude + ' Longitude: ' + longitude);
    // this.getRechargeLocations(latitude, longitude);
  };

  errorHandler(err) {
    if (err.code == 1) {
      console.log('Error: Access is denied!');
    } else if (err.code == 2) {
      console.log('Error: Position is unavailable!');
    }
  }

  fetchPlaces(mapProps, map) {
    const { google } = mapProps;
    const service = new google.maps.places.PlacesService(map);
    console.log(service);
    // ...
  }

  renderAutoComplete = (mapProps, map) => {
    const { google } = mapProps;

    if (!google || !map) return;

    const autocomplete = new google.maps.places.Autocomplete(this.autocomplete);
    autocomplete.bindTo('bounds', map);
    console.log('hshshsjk---------------------------------', autocomplete);

    autocomplete.addListener('place_changed', () => {
      const place = autocomplete.getPlace();

      if (!place.geometry) return;

      // if (place.geometry.viewport) map.fitBounds(place.geometry.viewport);
      // else {
      map.setCenter(place.geometry.location);
      map.setZoom(15);
      // }

      console.log('place.geometry.location.lat()', place.geometry.location.lat());
      this.setState({
        myLocation: {
          lat: place.geometry.location.lat(),
          lng: place.geometry.location.lng(),
        },
      });
      // this.getRechargeLocations(place.geometry.location.lat(), place.geometry.location.lng());
    });
  };

  renderInput = () => {
    return (
      <div className="autocomplete-places kupos-border font10">
        <img src="/images/icons/home/icon_search-white.png" />
        <input placeholder="Enter a location" ref={ref => (this.autocomplete = ref)} type="text" />
      </div>
    );
  };

  onMarkerClick = (props, marker, e, selectedMarekrData) => {
    this.setState({
      selectedPlace: selectedMarekrData,
      activeMarker: marker,
      showingInfoWindow: true,
    });
  };

  onMapClicked = (props, ev) => {
    if (this.state.showingInfoWindow) {
      this.setState({
        showingInfoWindow: false,
        activeMarker: null,
      });
    }
    this.props.onMapClicked(props, ev);
  };

  openDirections = () => {
    window.open(
      'https://www.google.com/maps/dir/' +
      this.state.myLocation.lat +
      ',' +
      this.state.myLocation.lng +
      '/' +
      this.state.selectedPlace.latitude +
      ',' +
      this.state.selectedPlace.longitude +
      '',
      '_system',
    );
  }

  render() {


    return (
      <div className="common-kupos-map">
        <Map
          google={this.props.google}
          zoom={14}
          initialCenter={this.state.mapCenter}
          center={this.state.mapCenter}
          onReady={this.renderAutoComplete}
          onClick={this.onMapClicked}
        >
          <Marker
            position={this.state.myLocation}
            icon={{
              url: '/svgs/my-location.svg',
              anchor: new this.props.google.maps.Point(32, 32),
              scaledSize: new this.props.google.maps.Size(30, 30),
            }}
          />

          {this.state.locations &&
            this.state.locations.map((val, i) => (
              <Marker
                key={i}
                onClick={(props, marker, e) => this.onMarkerClick(props, marker, e, val)}
                position={{ lat: val.latitude, lng: val.longitude }}
                icon={{
                  url: val.marker_icon,
                  anchor: new this.props.google.maps.Point(32, 32),
                  scaledSize: new this.props.google.maps.Size(30, 30),
                }}
              />
            ))}

          {this.props.polyline &&
            this.props.polyline.map((val, i) => (
              <Marker
                draggable={true} 
                onDragend={(mapProps, map) => this.props.onPolylineMarkerDragend(mapProps, map, i)}
                // onDragstart={this.props.onPolylineMarkerDragend}
                key={i}
                position={{ lat: val.lat, lng: val.lng }}
                icon={{
                  // url: val.marker_icon,
                  anchor: new this.props.google.maps.Point(32, 32),
                  scaledSize: new this.props.google.maps.Size(30, 30),
                }}
              />
            ))}


          <Polyline
            path={this.props.polyline}
            strokeColor="#0000FF"
            strokeOpacity={0.8}
            strokeWeight={2}
            tappable={true}
            geodesic={true}
            onPress={() => console.log('Tapped')}
          />

          {/* <InfoWindow marker={this.state.activeMarker} visible={this.state.showingInfoWindow}>
            {this.state.selectedPlace.bank
              ? console.log('Selected Place', this.state.selectedPlace.bank.name)
              : null}
            {this.state.selectedPlace ? (
              <div className="info-window font9">
                <div className="marker-icon-name primary-text bold-text">
                  <span className="marker-icon">
                    <img src={this.state.selectedPlace.marker_icon} />
                  </span>
                  <span className="marker-name">
                    {this.state.selectedPlace.bank ? this.state.selectedPlace.bank.name : ''}
                  </span>
                </div>
                <div className="marker-service">
                  <span className="bold-text">Servicios:</span>&nbsp;
                  <span>Recargas</span>
                </div>
                <div className="marker-service">
                  <span className="bold-text">Direccion:</span>&nbsp;
                  <span>
                    {this.state.selectedPlace.bank ? this.state.selectedPlace.bank.address : ''}
                  </span>
                </div>
                <hr />
                <div className="marker-service">
                  <span className="bold-text">PasajeBus.com</span>
                </div>
                <div className="marker-service">
                  <span className="bold-text">Codigo de comercio:</span>&nbsp;
                  <span>
                    {this.state.selectedPlace.bank
                      ? this.state.selectedPlace.bank.pb_commerce_code
                      : ''}
                  </span>
                </div>
                <button className="kupos-button" onClick={this.openDirections}>
                  ¿Cómo llegar?
                </button>
              </div>
            ) : (
              <div></div>
            )}
          </InfoWindow> */}
        </Map>
        {this.props.showPlaceSearch ? this.renderInput() : null}
        <KuposErrorSuccessModal
          success={false}
          showModal={this.state.showModal}
          onButtonClick={() => this.setState({ showModal: false })}
          bodyText={'PROFILE.NO_LOCATION_FOUND'}
        />
      </div>
    );
  }
}

export default GoogleApiWrapper({
  apiKey: AppData.MAP_API_KEY,
})(KuposMap);
