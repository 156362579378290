import React, { Component } from 'react';
import { TextField } from '@material-ui/core';

export default class MaterialInputField extends Component {
  static propTypes = {};

  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <div className="common-material-input-field">
        {this.props.select ? (
          <TextField
            id="filled-select-currency-native"
            select
            label={this.props.label}
            value={this.props.value}
            onChange={event => this.props.onChange(event.target.value)}
            SelectProps={{
              native: true,
            }}
            error={this.props.error}
            helperText={this.props.error ? this.props.errorMessage : null}
            variant="filled"
          >
            {this.props.options.map(option => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </TextField>
        ) : (
          <TextField
            id="standard-multiline-flexible"
            label={this.props.label}
            placeholder={this.props.placeholder}
            multiline={this.props.multiline}
            rows={this.props.rows}
            value={this.props.value}
            variant="outlined"
            fullWidth={true}
            type={this.props.type || 'text'}
            onBlur={this.props.blur}
            onChange={event => this.props.onChange(event.target.value)}
            helperText={this.props.error ? this.props.errorMessage : null}
            error={this.props.error}
            color="primary"
          />
        )}
      </div>
    );
  }
}
