import React, { Component } from 'react';
import PropTypes from 'prop-types';
import KuposSelectBox from './KuposSelectBox';
import KuposDatePicker from './KuposDatePicker';
import DateService from '../../services/dateService';
import CommonService from '../../services/commonService';

export default class NewSearchBox extends Component {
  static propTypes = {
    cities: PropTypes.array,
    openNewSearch: PropTypes.bool,
    pbSourceCity: PropTypes.object,
    sourceCityOnChange: PropTypes.func,
    destinationCityOnChange: PropTypes.func,
    onwardPBDate: PropTypes.string,
    onwardDateChange: PropTypes.func,
    returnPBDate: PropTypes.string,
    returnDateChange: PropTypes.func,
    swapCities: PropTypes.func,
    newSearch: PropTypes.func,
    t: PropTypes.func,
  };
  constructor(props) {
    super(props);
    this.state = {
      key: 1,
    };
  }

  swapCities = () => {
    this.props.swapCities();
    this.setState({ key: this.state.key + 1 });
  };

  render() {
    const { t } = this.props;
    return (
      <div className={'common-new-search-box ' + (this.props.openNewSearch ? 'open' : '')}>
        <div className={' content-container new-search-box home-selection-input-boxes '}>
          <div className="home-selection-input-box font10">
            <span className="label font10">{t('HOME.ORIGIN')}</span>
            <KuposSelectBox
              appType={CommonService.isTrain() ? 'train' : ''}
              selectType="origin"
              options={this.props.cities}
              selectedOption={this.props.pbSourceCity}
              onChange={this.props.sourceCityOnChange}
              placeholder={'Source'}
              hideArrow={true}
              icon="/images/icons/home/icon_source.png"
              height={40}
              key={this.state.key}
              t={t}
            />
            {/* <div className="home-selection-input kupos-border">
                <img className="icon-size" src="./images/icons/home/icon_source.png"/> <span>Source</span>
            </div> */}
          </div>
          <div className="home-selection-input-box swap-icon">
            <img src="/images/icons/icon-swap.png" onClick={this.swapCities} />
          </div>

          <div className="home-selection-input-box font10">
            <span className="label font10">{t('HOME.DESTINATION')}</span>
            <KuposSelectBox
              appType={CommonService.isTrain() ? 'train' : ''}
              selectType="destination"
              options={this.props.cities}
              selectedOption={this.props.pbDestinationCity}
              onChange={this.props.destinationCityOnChange}
              placeholder={'Destination'}
              hideArrow={true}
              icon="/images/icons/home/icon_source2.png"
              height={40}
              key={this.state.key}
              t={t}
            />
            {/* <sbdiv className="home-selection-input kupos-border">
                <img className="icon-size" src="./images/icons/home/icon_source2.png"/> <span>Source</span>
            </div> */}
          </div>

          <div className="home-selection-input-box datebox font10">
            <span className="label font10">{t('HOME.ONE_WAY')}</span>
            <KuposDatePicker
              minDate={DateService.getTodayString()}
              selectedDate={this.props.onwardPBDate}
              onDateChange={date => this.props.onwardDateChange(date)}
            />
            {/* <div className="home-selection-input kupos-border">
                  <img className="icon-size" src="./images/icons/home/icon_calendar.png"/> <span>Source</span>
              </div> */}
          </div>
          <div className="home-selection-input-box datebox font10">
            <span className="label font10">{t('HOME.ROUND_TRIP')}</span>
            <KuposDatePicker
              minDate={this.props.onwardPBDate}
              selectedDate={this.props.returnPBDate}
              onDateChange={date => this.props.returnDateChange(date)}
              placeholder={t('HOME.OPTIONAL_DATE')}
            />
            {/* <div className="home-selection-input kupos-border">
                    <img className="icon-size" src="./images/icons/home/icon_calendar.png"/> <span>Source</span>
                </div> */}
          </div>

          <div className="home-selection-input-box datebox font10">
            <button
              className="kupos-button home-selection-input-button "
              onClick={this.props.newSearch}
            >
              <img
                className="icon-size"
                src="/images/icons/home/icon_search-white.png"
                alt={this.props.t('TRANSLATIONS.ICON_SEARCH_WHITE')}
              />{' '}
              <span className="font11">{t('HOME.SEARCH')}</span>
            </button>
          </div>
        </div>
      </div>
    );
  }
}
