import React, { useEffect, useState } from 'react';
import {
  DropdownComponent,
  KuposButton,
  KuposErrorSuccessModal,
  KuposInput,
} from '../common';
import ApiUrls from '../../services/apiUrls';
import { useDispatch } from 'react-redux';
import { apiCall } from './redux/apiCall';

const initialState = {
  posName: '',
  posDescription: '',
  selectedBranch: null,
};

const AllPos = ({ match, history }) => {
  const dispatch = useDispatch();
  const [values, setValues] = useState(initialState);
  const [modal, setModal] = useState({});
  const [branches, setBranches] = useState([]);

  const id = match.params ? Number(match.params.id) : null;

  useEffect(() => {
    getBranchesList();
    if (id) {
      getList();
    }
    // eslint-disable-next-line
  }, [id]);

  const getBranchesList = () => {
    dispatch(
      apiCall({
        url: ApiUrls.addBranch(),
        callback: res => {
          if (res.data && res.data.success) {
            const brnch = res.data.data.map(d => {
              return { value: d.id, label: d.name };
            });
            setBranches(brnch);
          }
        },
      }),
    );
  };

  const getList = () => {
    dispatch(
      apiCall({
        url: ApiUrls.addPos(),
        callback: res => {
          if (res.data && res.data.success) {
            const found = res.data.data.find(p => p.id === id);
            if (found) {
              getParticularPos(found);
            }
          }
        },
      }),
    );
  };

  const getParticularPos = data => {
    setValues({
      posToEdit: data,
      posName: data.name,
      branchName: data.branch_name,
      posDescription: data.description,
      branchId: data.branch_id,
      selectedBranch: { value: Number(data.branch_id), label: data.branch_name },
    });
  };

  const onChange = (val, type) => {
    setValues({ ...values, [type]: val, [type + 'Error']: null });
  };

  const validate = () => {
    if (!values.posName) {
      setValues({ ...values, posNameError: 'Ingrese el nombre del pos' });
      return false;
    }
    if (!values.selectedBranch) {
      setValues({ ...values, selectedBranchError: 'Seleccionar rama' });
      return false;
    }
    if (!values.posDescription) {
      setValues({ ...values, posDescriptionError: 'Ingrese el comentario de la pos' });
      return false;
    }
    return true;
  };

  const createOrUpdate = () => {
    if (!validate()) {
      return;
    }

    let method = 'POST';
    let data = {};

    if (id) {
      method = 'PUT';
      data = {
        id: values.posToEdit.id,
        name: values.posToEdit.name,
        client_code: values.posToEdit.client_code,
        branch_id: values.selectedBranch.branch_id,
        branch_name: values.selectedBranch.label,
        description: values.posDescription,
        status: values.posToEdit.status,
        created_on: values.posToEdit.created_on,
        created_by: values.posToEdit.created_by,
        last_updated_on: values.posToEdit.last_updated_on,
        last_updated_by: values.posToEdit.last_updated_by,
      };
    } else {
      data = {
        name: values.posName,
        branch_id: values.selectedBranch.value,
        branch_name: values.selectedBranch.label,
        description: values.posDescription,
      };
    }

    dispatch(
      apiCall({
        url: ApiUrls.addPos(),
        method,
        data,
        callback: res => onResponse(res),
      }),
    );
  };

  const onResponse = res => {
    if (res.data && res.data.success && res.data.data) {
      setModal({
        show: true,
        success: true,
        bodyText: 'Éxito' || (id ? 'Éxito' : 'Éxito'),
      });
    } else if (!res.data.success && res.data.message) {
      setModal({
        show: true,
        success: false,
        bodyText: res.data.message,
      });
    } else {
      setModal({
        show: true,
        success: false,
        bodyText: 'Fallar',
      });
    }
  };

  return (
    <div className="home-add-pos pr-3 pl-3">
      <h2 className="mt-4 mb-4 font-bold">{id ? 'Editar POS' : 'Crear nuevo POS'}</h2>
      <div className="common-kupos-input-box" style={{ marginTop: 10 }}>
        <KuposInput
          type="text"
          className="kupos-border"
          placeholder={'Nombre'}
          label={'Nombre'}
          disabled={id ? true : false}
          value={values.posName}
          error={values.posNameError ? true : false}
          errorMessage={values.posNameError ? values.posNameError : ''}
          onChange={text => onChange(text, 'posName')}
        />
      </div>
      <div style={{ marginTop: 10 }}>
        <div>
          <span className="input-label">Sucursal</span>
        </div>
        <DropdownComponent
          onChange={val => onChange(val, 'selectedBranch')}
          placeholder={'Sucursal'}
          options={branches}
          value={values.selectedBranch}
          error={values.selectedBranchError ? true : false}
          errorMessage={values.selectedBranchError ? values.selectedBranchError : ''}
        />
      </div>
      <div className="common-kupos-input-box" style={{ marginTop: 20 }}>
        <KuposInput
          type="text"
          className="kupos-border"
          placeholder={'Observaciones'}
          label={'Observaciones'}
          value={values.posDescription}
          error={values.posDescriptionError ? true : false}
          errorMessage={values.posDescriptionError ? values.posDescriptionError : ''}
          onChange={text => onChange(text, 'posDescription')}
        />
      </div>
      <div className="button-container" style={{ marginTop: 20, marginBottom: 40 }}>
        <KuposButton onClick={createOrUpdate}>
          <span className="font11">{id ? 'Actualizar POS' : 'Crear POS'}</span>
        </KuposButton>
      </div>

      <KuposErrorSuccessModal
        success={modal.success}
        showModal={modal.show}
        onButtonClick={() => {
          if (modal.success) history.push('/pos');
          setModal({});
        }}
        bodyText={modal.bodyText}
      />
    </div>
  );
};

export default AllPos;
