import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { KuposButton, KuposErrorSuccessModal, MaterialInputField } from '../common';
import ApiUrls from '../../services/apiUrls';
import DateService from '../../services/dateService';
import CommonService from '../../services/commonService';
import { apiCall } from './redux/apiCall';
import Loader from 'react-loader-spinner';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actions from './redux/actions';


const initialState = {
  amount: '',
  comment: '',
  selectedBranch: null,
};

const modalInitialState = {
  show: false,
  success: false,
  bodyText: null,
};

const CashInHand = (props) => {
  const dispatch = useDispatch();
  const [modal, setModal] = useState(modalInitialState);
  const [loader, setLoader] = useState(false)
  const [values, setValues] = useState(initialState);

  const onChange = (val, type) => {
    // console.log('Value is-----', val);
    switch (type) {
      case 'amount':
        setValues({ ...values, amount: CommonService.onlyNumbers(val), amountError: null });
        break;
      case 'comment':
        setValues({ ...values, comment: val, commentError: null });
        break;
    }
  };

  const validate = () => {
    if (!values.amount) {
      setValues({ ...values, amountError: 'Ingrese un monto' });
      return false
    }

    if (!values.comment) {
      setValues({
        ...values,
        commentError:
          'Estimado usuario, debe ingresar algún comentario para continuar'
      });
      return false
    }

    return true;
  };

  const onBlur = () => { };

  const createOrUpdate = () => {
    if (!validate()) {
      return;
    }

    let currentUserFull = CommonService.getLocal('currentUserFull');

    let data = {
      login_info_ref_id: currentUserFull.login_info_ref_id,
      counter_id: props.home.counterInitialData.counter.value,
      counter_name: props.home.counterInitialData.counter.label,
      branch_id: props.home.counterInitialData.branch.value,
      branch_name: props.home.counterInitialData.branch.label,
      shift_id: props.home.counterInitialData.shift.value,
      shift_name: props.home.counterInitialData.shift.label,
      opening_balance: Number(values.amount),
      opening_remark: values.comment,
      ctr_open_date_stg: DateService.getTodayString(),
      ctr_open_time_stg: DateService.getCurrentHour() + ':' + DateService.getCurrentMinute(),
      ctr_open_date_time: DateService.getCurrentTimeUTC(),
    };

    console.log('data to fly----', data);

    setLoader(true)

    dispatch(
      apiCall({
        url: ApiUrls.openCounter(),
        method: 'POST',
        data,
        callback: res => onCounterOpenResponse(res),
      }),
    );
  };

  const onCounterOpenResponse = res => {
    setLoader(false)
    console.log('Data from counter assignement----', res);

    if (res.data && res.data.success && res.data.data) {
      setModal({
        show: true,
        success: true,
        // redirect: "/",
        bodyText: 'Éxito',
      });
      // props.history.push('/')
      CommonService.setLocal("counter",res.data.data)
    } else if (!res.data.success && res.data.message) {
      setModal({ show: true, success: false, bodyText: res.data.message });
      
    } else {
      setModal({ show: true, success: false, bodyText: 'Fail' });
    }
  };

  console.log({ props, values })
  return (
    <div className="home-cash-in-hand">
      <div className="cash-in-hand-container">
        <div className="text-align-center ">
          <span className="bold-text">
            Caja:{' '}
            {(props.home &&
              props.home.counterInitialData &&
              props.home.counterInitialData.branch.label) ||
              'Counter 1'}
          </span>
        </div>

        <div>
          <div className="header-conatiner text-align-center ">
            <span>Balance inicial para {CommonService.currentUser.user_name}</span>
          </div>

          <MaterialInputField
            label={'Balance inicial'}
            value={values.amount}
            onChange={text => onChange(text, 'amount')}
            onBlur={() => onBlur()}
            error={values.amountError}
            type={'number'}
            errorMessage={values.amountError ? values.amountError : ''}
          />

          <MaterialInputField
            label={'Comentario'}
            value={values.comment}
            multiline={true}
            rows={4}
            onChange={text => onChange(text, 'comment')}
            onBlur={() => onBlur()}
            error={values.commentError}
            errorMessage={values.commentError ? values.commentError : ''}
          />

          <div className="button-container" style={{ marginTop: 20 }}>
            <KuposButton onClick={createOrUpdate} >
              <span className="font11">{'Asignar caja'}</span>
            </KuposButton>
          </div>
        </div>
      </div>



      <KuposErrorSuccessModal
        showModal={modal.show}
        success={modal.success}
        smallIcon={true}
        bodyText={modal.bodyText}
        onButtonClick={() => {
          
          setModal(modalInitialState);
          // props.history.push(modal.redirect);
          if (modal.show) props.history.push('/counter-dashboard');
        }}
      />
      {loader ? (
        <div className="loader-container">
          <Loader visible={loader} type="Bars" color="#637290" height={80} width={80} />
        </div>
      ) : null}
    </div>
  );
}


// export default CashInHand;

/* istanbul ignore next */
function mapStateToProps(state) {
  return {
    home: state.home,
    common: state.common
  };
}

/* istanbul ignore next */
function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...actions }, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CashInHand);
