import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  DropdownComponent,
  KuposButton,
  KuposErrorSuccessModal,
  KuposInput,
} from '../common';
import ApiUrls from '../../services/apiUrls';
import DateService from '../../services/dateService';
import { apiCall } from './redux/apiCall';
import Loader from 'react-loader-spinner';

const modalInitialState = {
  show: false,
  success: false,
  bodyText: null,
};
const initialState = {
  stateName: '',
  startDate: '',
  endDate: '',
  remarks: '',
};

const errorInitialState = {};

const AddState = ({ history, match }) => {

  const dispatch = useDispatch();
  const [values, setValues] = useState(initialState);
  const [modal, setModal] = useState(modalInitialState);
  const [countries, setCountries] = useState([]);
  const [statesList, setStatesList] = useState([]);
  const [stateToEdit, setStateToEdit] = useState(null);
  const [loader, setLoader] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState('');
  const [errors, setErrors] = useState(errorInitialState);

  const id = match.params ? Number(match.params.id) : null;

  useEffect(() => {
    if (id) {
      getStatesList();
    } else {
      setLoader(false)
    }
    // eslint-disable-next-line
  }, [id])


  useEffect(() => {
    dispatch(apiCall({
      url: ApiUrls.addCountry(),
      callback: res => {
        if (res.data && res.data.success) {

          let countries = [];
          res.data.data.map(country => {
            countries.push({ value: country.id, label: country.name });
          });
          setCountries(countries)
        }
      }
    }))
  }, [dispatch, id])


  const getStatesList = () => {
    dispatch(apiCall({
      url: ApiUrls.addState(),
      callback: res => {
        if (res.data && res.data.success) {
          setStatesList(res.data.data)

          if (id)
            setTimeout(() => {
              getParticularState(id, res.data.data);
            }, 1000);
        }
      }
    }))
  }



  const getParticularState = (id, states) => {
    console.log('country list is', states);
    states.forEach(element => {
      if (element.id == id) {
        setStateToEdit(element);
        setValues({
          stateName: element.name,
          remarks: element.remark,
          startDate: DateService.changeDateFormat(
            element.availability_start_date,
            'yyyy-mm-dd',
            'dd-mm-yyyy',
          ),
          endDate: DateService.changeDateFormat(
            element.availability_end_date,
            'yyyy-mm-dd',
            'dd-mm-yyyy',
          ),
        })

        countries &&
          countries.map(val => {
            if (val.value === element.country_id) {
              setSelectedCountry(val);
            }
          });
      }
    });
  };

  const onChange = (val, type) => {
    switch (type) {
      case 'state_name':
        setValues({ ...values, stateName: val })
        break;
      case 'remark':
        setValues({ ...values, remarks: val })
        break;
    }
  };

  const onCountryChange = val => {
    setSelectedCountry(val)
  };

  const onBlur = () => { };

  const validate = () => {
    let error = 0;
    setErrors(initialState);

    if (!selectedCountry) {
      setErrors({ ...errors, selectedCountryError: 'Seleccione la país' });
      error++;
      return false;
    }
    if (!values.stateName) {
      setErrors({ ...errors, stateNameError: 'Ingrese un  región' });
      error++;
      return false;
    }
    if (!values.remarks) {
      setErrors({ ...errors, remarksError: 'Ingrese un comentarios' });
      error++;
      return false;
    }

    if (error > 0) {
      return false;
    } else {
      return true;
    }
  };

  const createOrUpdate = () => {
    if (!validate()) {
      return;
    }
    setLoader(true)

    let updateData, data;

    id
      ? (updateData = {
        id: stateToEdit.id,
        country_id: selectedCountry.value,
        name: values.stateName,
        status: stateToEdit.status,
        remark: values.remarks,
        created_on: stateToEdit.created_on,
        created_by: stateToEdit.created_by,
        last_updated_on: stateToEdit.last_updated_on,
        last_updated_by: stateToEdit.last_updated_by,
      })
      : (data = {
        name: values.stateName,
        country_id: selectedCountry.value,
        remark: values.remarks,
      });

    dispatch(
      apiCall({
        url: ApiUrls.addState(),
        method: id ? 'PUT' : 'POST',
        data: id ? updateData : data,
        callback: res => onAddStateResponse(res),
      }),
    );
  };

  const onAddStateResponse = res => {
    if (res.data && res.data.success && res.data.data) {
      setLoader(false)
      setModal({
        show: true,
        success: true,
        redirect: true,
        bodyText: 'Éxito' || (id ? 'Éxito' : 'Éxito'),
      });
    } else if (!res.data.success && res.data.message) {
      setModal({ show: true, success: false, bodyText: res.data.message });
    } else {
      setModal({ show: true, success: false, bodyText: 'Fail' });
    }
  };

  return (
    <div className="home-add-location">
      <div className="home-selection-input-box font10 select-box">
        <DropdownComponent
          onChange={val => onCountryChange(val)}
          placeholder={'Seleccione la país'}
          disabled={id ? true : false}
          options={countries}
          value={selectedCountry}
          error={errors.selectedCountryError ? true : false}
          errorMessage={
            errors.selectedCountryError ? errors.selectedCountryError : null
          }
        />
      </div>
      <div className="common-kupos-input-box" style={{ marginTop: 10 }}>
        <KuposInput
          type="text"
          className="kupos-border"
          placeholder={'Nombre de la región'}
          value={values.stateName}
          error={errors.stateNameError ? true : false}
          errorMessage={errors.stateNameError ? errors.stateNameError : ''}
          onChange={text => onChange(text, 'state_name')}
          onBlur={text => onBlur(text, 'state_name')}
        />
      </div>

      <div className="common-kupos-input-box" style={{ marginTop: 10 }}>
        <KuposInput
          type="text"
          className="kupos-border"
          placeholder={'Comentarios'}
          value={values.remarks}
          error={errors.remarksError ? true : false}
          errorMessage={errors.remarksError ? errors.remarksError : ''}
          onChange={text => onChange(text, 'remark')}
          onBlur={text => onBlur(text, 'remark')}
        />
      </div>

      <div className="button-container" style={{ marginTop: 20 }}>
        <KuposButton onClick={createOrUpdate}>
          <span className="font11">
            {id ? 'Actualizar región' : 'Crear región '}
          </span>
        </KuposButton>
      </div>

      <KuposErrorSuccessModal
        success={modal.success}
        showModal={modal.show}
        onButtonClick={() => {
          setModal(modalInitialState);
          if (modal.redirect) {
            history.goBack();
          }
        }}
        bodyText={modal.bodyText}
      />

      {loader ? (
        <div className="loader-container">
          <Loader visible={loader} type="Bars" color="#637290" height={80} width={80} />
        </div>
      ) : null}
    </div>
  );
}


export default AddState;
