// @flow

import React, { Component, PureComponent } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actions from './redux/actions';
import { KuposButton, KuposErrorSuccessModal, KuposInput, KuposMap } from '../common';
import Select from 'react-select';
import GoogleMapReact from 'google-map-react';
import AppData from '../../services/appData';
import CommonService from '../../services/commonService';
import ApiUrls from '../../services/apiUrls';
import { withScriptjs, withGoogleMap, GoogleMap, Marker, Polygon } from 'react-google-maps';
import DrawingManager from 'react-google-maps/lib/components/drawing/DrawingManager';
import { DropdownComponent } from '../common/DropdownComponent';
import DateService from '../../services/dateService';
import Loader from 'react-loader-spinner';

// const Marker = () => (
//   <div style={{
//     color: 'red',
//     backgroundColor: 'red',
//     with: '20px',
//     height: '20px',
//     display: 'block',
//     textAlign: 'center',
//     alignItems: 'center',
//     justifyContent: 'center',
//     borderRadius: '50%',
//     fontSize: '26px',
//   }}>
//     *
//   </div>
// );

var google;

export class AddLocationArea extends Component {
  static propTypes = {
    home: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      //  center: {
      //    lat: 33.429,
      //    lng: -70.62,
      //  },
      //  myLocation: {
      //    lat: 33.429,
      //    lng: -70.62,
      //  },
      selectedTab: 1,
      polygon: [],
      locations: [],
      selectedLocation: '',
      areaName: null,
      successModalMessage: null,
      successModalStatus: false,
      areadAddModal: false,
      selectedLatLong: { lat: -33.4436021, lng: -70.6606066 },
      locationAreaToEdit: null,
      locationError: null,
      loader:false,
    };
  }

  componentDidMount() {
    this.getLocations();
    this.getLocationList();
  }

  getLocationArea = (id, locations) => {
    console.log('+++id and loc', id, locations);
    // this.props.actions.apiCall({ url: ApiUrls.getLocationAreas(id) }).then(
    //   res => {
    //     if (res.data && res.data.success && res.data.data) {
    //       this.setState({
    //         locationAreaToEdit: res.data.data,
    //         polygon:
    //           res.data.data.polygon && JSON.parse(res.data.data.polygon).features
    //             ? JSON.parse(res.data.data.polygon).features[0].geometry.coordinates[0].map(
    //                 val => ({
    //                   lat: val[0],
    //                   lng: val[1],
    //                 }),
    //               )
    //             : [],
    //         selectedLocation: locations.find(loc => loc.value === res.data.data.location_id),
    //         areaName: res.data.data.area_name,
    //         remark:res.data.data.remark,
    //       });
    //     }
    //   },
    //   err => {},
    // );
    locations.forEach(element => {
      if (element.id == id) {
        console.log('+++element to edit', element);
        this.setState({
          locationAreaToEdit: element,
          areaName: element.area_name,
          remark: element.remark,
          selectedLocation: element.location_id,
          // polygon: element.polygon,
          polygon:
            element.polygon && JSON.parse(element.polygon).features
              ? JSON.parse(element.polygon).features[0].geometry.coordinates[0].map(val => ({
                  lat: val[0],
                  lng: val[1],
                }))
              : [],
        });
      }
    });
  };

  getLocationList = () => {
    this.props.actions.apiCall({ url: ApiUrls.getLocationList() }).then(
      res => {
        this.onLocationListResponse(res)
      },
      err =>{
        this.onLocationListResponse(err)
      }
    );
  }

  onLocationListResponse = (result) => {
    let data = [];

    if (result.data && result.data.success && result.data.data) {
      result.data.data.map(val => {
      data.push({ value: val.id, label: val.name });
      });
    }
    this.setState({ locationList: data });
  }

  getLocations = () => {
    //  this.props.actions.apiCall({ url: ApiUrls.getLocations() }).then(
    this.props.actions.apiCall({ url: ApiUrls.getLocationAreas() }).then(
      res => {
        this.onLocationApiResponse(res);
      },
      err => {
        this.onLocationApiResponse(err);
      },
    );
  };

  onLocationApiResponse = result => {
    console.log('+++Locations Result is', result.data ? result : 'no data');

    let data = [];

    if (result.data && result.data.success && result.data.data) {
      // result.data.data.map(val => {
      // data.push({ value: val.id, label: val.name });
      this.setState({ locations: result.data.data });
      // });
      if (this.props.match.params.id)
        this.getLocationArea(this.props.match.params.id, result.data.data);
    }

    // this.setState({ locations: data });
  };

  onTabChange = val => {
    this.setState({ selectedTab: val });
  };

  renderTabs = () => {
    return (
      <div className="recharge-tabs-container">
        <div className="map-container-selector font10">
          <div
            className={'selector-item ' + (this.state.selectedTab == 1 ? 'active' : '')}
            onClick={() => this.onTabChange(1)}
          >
            {'Setup'}
          </div>
          <div
            className={'selector-item ' + (this.state.selectedTab == 2 ? 'active' : '')}
            onClick={() => this.onTabChange(2)}
          >
            {'Map'}
          </div>
        </div>
      </div>
    );
  };

  validate = () => {
    if (!this.state.selectedLocation) {
      this.setState({ locationError: { message: 'Por favor seleccione Ubicación' } });
      return false;
    }
    if (!this.state.areaName > 0) {
      this.setState({ areaNameError: { message: 'Seleccione el nombre del área' } });
      return false;
    }
    if (!this.state.polygon.length > 0) {
      this.setState({ polygonError: { message: 'Seleccione el área del polígono' } });
      return false;
    }
    if (!this.state.remark) {
      this.setState({ remarkError: { message: 'Por favor, ingrese algún comentario para continuar' } });
      return false;
    }
    return true;
  };

  onSelectOptionChange = val => {
    console.log("+++in onSelectChange value is",val)
    this.props.actions
      .apiCall({
        url: `https://nominatim.openstreetmap.org/search?q=${val.label},santiago,chile&format=json&polygon=1&addressdetails=1`,
      })
      .then(
        res => {
          this.onGetLatLongResponse(res);
        },
        err => {
          this.onGetLatLongResponse(err);
        },
      );

    this.setState({ selectedLocation: val ,locationError:null});
  };

  createOrUpdate = () => {
    if (!this.validate()) {
      return;
    }
    this.setState({loader:true})

    // console.log('Service selected location ', this.state.selectedLocation.value);
    let polygon = this.props.match.params.id
      ? {
          type: 'FeatureCollection',
          features: [
            {
              type: 'Feature',
              geometry: {
                type: 'Polygon',
                coordinates: [this.getPolygonString()],
              },
              properties: { prop0: 'value0' },
            },
          ],
        }
      : this.getPolygonString();

    let data, updateData;

    this.props.match.params.id
      ? (updateData = {
          id: this.state.locationAreaToEdit.id,
          location_id: this.state.locationAreaToEdit.location_id,
          client_code: this.state.locationAreaToEdit.client_code,
          area_name: this.state.areaName,
          polygon: JSON.stringify(polygon),
          remark: this.state.remark,
          status: 'ACTIVE',
          created_by: this.state.locationAreaToEdit.created_by,
          created_on_utc: this.state.locationAreaToEdit.created_on_utc,
          created_date_time_stg: this.state.locationAreaToEdit.created_date_time_stg,
          last_updated_by: this.state.locationAreaToEdit.last_updated_by,
          last_updated_on_utc: DateService.getCurrentTimeUTC(),
          last_updated_date_time_stg: DateService.getCurrentStgDate(),
          deleted_by: ' ',
          deleted_on_utc: 0,
          deleted_date_time_stg: '',
        })
      : (data = {
          id: this.props.match.params.id ? +this.props.match.params.id : undefined,
          location_id: this.state.selectedLocation.value,
          location_name: this.state.selectedLocation.label,
          client_code: AppData.clientCode,
          area_name: this.state.areaName,
          polygon: JSON.stringify(polygon),
          remark: this.state.remark,
          created_on_utc: DateService.getCurrentTimeUTC(),
          created_date_time_stg: DateService.getCurrentStgDate(),
        });

    this.props.actions
      .apiCall({
        method: this.props.match.params.id ? 'PUT' : 'POST',
        url: ApiUrls.addLocationArea(),
        data: this.props.match.params.id ? updateData : data,
      })
      .then(
        res => {
          this.onLocationAddResponse(res);
        },
        err => {
          this.onLocationAddResponse(err);
        },
      );

    //  console.log('Service Add area called ', data);
  };

  onLocationAddResponse = result => {
    console.log('+++Result from add/update location area is', result.data);
    if (result.data && result.data.success && result.data.http_code == '200') {
      this.setState({loader:false})
      this.setState({
        showSuccessModal: true,
        successModalStatus: true,
        successModalMessage: 'Éxito',
      });
    } else {
      this.setState({
        showSuccessModal: true,
        successModalStatus: false,
        successModalMessage: 'Éxito',
      });
    }
  };

  onBlur = (val, inp) => {};

  onChange = (val, inp) => {
    this.setState({
      [inp]: val,
      [inp + 'Error']: null,
    });
  };

  onMapClicked = props => {
    let polygon = CommonService.copyObject(this.state.polygon);
    if (polygon.length < 3) {
      polygon.push({
        lat: props.latLng.lat(),
        lng: props.latLng.lng(),
      });
      this.setState({
        polygon: polygon,
      });
    }
  };

  onMarkerDrag = (index, props) => {
    // function is just a stub to test callbacks
    let polygon = CommonService.copyObject(this.state.polygon);
    polygon[index].lat = props.latLng.lat();
    polygon[index].lng = props.latLng.lng();
    this.setState({
      draggable: false,
      polygon: polygon,
    });

    console.log('onCircleInteraction called with', index, props);
  };

  onPolygonMouseOut = props => {
    // console.log(props)
    let polygon = [];
    console.log(props.getPath().length);
    props.getPath().forEach((latlng, index) => {
      polygon.push({ lat: latlng.lat(), lng: latlng.lng() });
    });
    this.setState({
      draggable: false,
      polygon: polygon,
    });
  };

  getPolygonString = () => {
    let polygon = [];
    this.state.polygon.forEach((latlng, index) => {
      polygon.push([latlng.lat, latlng.lng]);
    });

    return polygon;
  };

 

  onGetLatLongResponse = result => {
    if (result.status == 200 && result.data.length > 0) {
      this.setState({
        selectedLatLong: {
          lat: +result.data[0].lat,
          lng: +result.data[0].lon,
        },
      });
    }
  };

  hideAndRedirect = () => {
    this.setState({ showSuccessModal: false });
    this.props.history.push('/admin-home');
  };

  resetPolygon = () => {
    this.setState({ polygon: [] });
  };

  render() {
    console.log('+++state is----', this.state);

    return (
      <div className="home-add-location-area">
        {/* <Select options={options} /> */}

        <div>
          <div style={{ marginTop: 10, marginBottom: 10 }}>
            <DropdownComponent
              onChange={val => this.onSelectOptionChange(val)}
              // onChange={text => this.onChange(text, 'location')}
              placeholder={'Ubicación'}
              options={this.state.locationList}
              value={this.state.selectedLocation}
              error={this.state.locationError ? true : false}
              disabled={this.props.match.params.id ? true : false}
              errorMessage={this.state.locationError ? this.state.locationError.message : ''}
            />
          </div>

          <div className="common-kupos-input-box" style={{ marginTop: 10 }}>
            <KuposInput
              type="text"
              className="kupos-border"
              placeholder={'Nombre del área'}
              value={this.state.areaName}
              error={this.state.areaNameError ? true : false}
              errorMessage={this.state.areaNameError ? this.state.areaNameError.message : ''}
              onChange={text => this.onChange(text, 'areaName')}
              onBlur={text => this.onBlur(text, 'areaName')}
            />
          </div>

          <div>
            <div className="common-kupos-input-box" style={{ marginTop: 10 }}>
              <div className="polygon-input kupos-border">
                <span>{JSON.stringify(this.getPolygonString())}</span>
              </div>
            </div>

            {this.state.polygonError ? (
              <span className="error">{this.state.polygonError.message}</span>
            ) : null}
          </div>

          <div className="common-kupos-input-box" style={{ marginTop: 10 }}>
            <KuposInput
              type="text"
              className="kupos-border"
              placeholder={'Comentarios'}
              value={this.state.remark}
              error={this.state.remarkError ? true : false}
              errorMessage={this.state.remarkError ? this.state.remarkError.message : ''}
              onChange={text => this.onChange(text, 'remark')}
              onBlur={text => this.onBlur(text, 'remark')}
            />
          </div>

          <div className="button-container" style={{ marginTop: 20 }}>
            <KuposButton onClick={this.createOrUpdate}>
              <span className="font11">
                {this.props.match.params.id
                  ? 'Actualizar área de ubicación'
                  : 'Crear área de ubicación'}
              </span>
            </KuposButton>
          </div>
        </div>

        <div className="add-service-area-container">
          <div className="map-container">
            <div className="map-div">
              {/* <GoogleMapReact
                draggable={this.state.draggable}
                bootstrapURLKeys={{ key: AppData.MAP_API_KEY }}
                defaultCenter={this.state.myLocation}
                defaultZoom={12}
                onChildMouseDown={this.onMarkerMouseUpMove}
                onChildMouseUp={this.onMarkerMouseUp}
                onChildMouseMove={this.onMarkerMouseUpMove}
                onClick={this.onMapClicked}
                onGoogleApiLoaded={({ map, maps }) => { this.setState({ map: map, maps: maps, mapLoaded: true }) }}
              >
                {
                  this.state.polygon ? this.state.polygon.map((val, key) => (
                    <Marker
                      key={key}
                      lat={val.lat}
                      lng={val.lng}
                    />
                  )) : null
                }
              </GoogleMapReact> */}

              <MapWithAMarker
                key={this.state.selectedLatLong.lat}
                googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${AppData.MAP_API_KEY}&v=3.exp&libraries=geometry,drawing,places`}
                loadingElement={<div style={{ height: `100%` }} />}
                containerElement={<div style={{ height: `500px` }} />}
                mapElement={<div style={{ height: `100%` }} />}
                // polygon={this.state.polygon}
                onMapClick={this.onMapClicked}
                onMarkerDrag={this.onMarkerDrag}
                onPolygonMouseOut={this.onPolygonMouseOut}
                selectedLatLong={this.state.selectedLatLong}
              >
                <Polygon
                  ref={ref => {
                    this.polygonRef = ref;
                  }}
                  onDragEnd={() => console.log(this.polygonRef.getPaths())}
                  onMouseUp={() => this.onPolygonMouseOut(this.polygonRef)}
                  editable={true}
                  path={this.state.polygon}
                />
              </MapWithAMarker>
            </div>
          </div>

          {this.state.polygon.length > 0 && (
            <div className="button-container-absoulute">
              <KuposButton onClick={this.resetPolygon}>
                <span className="font11">Restablecer polígono</span>
              </KuposButton>
            </div>
          )}
        </div>

        <KuposErrorSuccessModal
          success={this.state.successModalStatus}
          showModal={this.state.showSuccessModal}
          onButtonClick={() => {
            this.setState({
              showSuccessModal: false,
              successModalStatus: false,
              successModalMessage: false,
            });
            if (this.state.successModalStatus) this.props.history.goBack();
          }}
          bodyText={this.state.successModalMessage}
        />

        
      {this.state.loader ? (
        <div className="loader-container">
          <Loader visible={this.state.loader} type="Bars" color="#637290" height={80} width={80} />
        </div>
      ) : null}
      </div>
    );
  }
}

const MapWithAMarker = withScriptjs(
  withGoogleMap(props => (
    <GoogleMap
      defaultZoom={13}
      defaultCenter={props.selectedLatLong}
      // defaultCenter={{ lat: -33.4436021, lng: -70.6606066 }}
      onClick={props.onMapClick}
    >
      
      {props.children}
    </GoogleMap>
  )),
);

/* istanbul ignore next */
function mapStateToProps(state) {
  return {
    home: state.home,
  };
}

/* istanbul ignore next */
function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...actions }, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AddLocationArea);
