export const HOME_LOGIN_BEGIN = 'HOME_LOGIN_BEGIN';
export const HOME_LOGIN_SUCCESS = 'HOME_LOGIN_SUCCESS';
export const HOME_LOGIN_FAILURE = 'HOME_LOGIN_FAILURE';
export const HOME_LOGIN_DISMISS_ERROR = 'HOME_LOGIN_DISMISS_ERROR';
export const HOME_SET_LOGIN_DATA = 'HOME_SET_LOGIN_DATA';
export const HOME_GET_BOOKINGS_FOR_AGENT_BEGIN = 'HOME_GET_BOOKINGS_FOR_AGENT_BEGIN';
export const HOME_GET_BOOKINGS_FOR_AGENT_SUCCESS = 'HOME_GET_BOOKINGS_FOR_AGENT_SUCCESS';
export const HOME_GET_BOOKINGS_FOR_AGENT_FAILURE = 'HOME_GET_BOOKINGS_FOR_AGENT_FAILURE';
export const HOME_GET_BOOKINGS_FOR_AGENT_DISMISS_ERROR = 'HOME_GET_BOOKINGS_FOR_AGENT_DISMISS_ERROR';
export const HOME_GET_DRIVERS_BEGIN = 'HOME_GET_DRIVERS_BEGIN';
export const HOME_GET_DRIVERS_SUCCESS = 'HOME_GET_DRIVERS_SUCCESS';
export const HOME_GET_DRIVERS_FAILURE = 'HOME_GET_DRIVERS_FAILURE';
export const HOME_GET_DRIVERS_DISMISS_ERROR = 'HOME_GET_DRIVERS_DISMISS_ERROR';
export const HOME_GET_VEHICLES_BEGIN = 'HOME_GET_VEHICLES_BEGIN';
export const HOME_GET_VEHICLES_SUCCESS = 'HOME_GET_VEHICLES_SUCCESS';
export const HOME_GET_VEHICLES_FAILURE = 'HOME_GET_VEHICLES_FAILURE';
export const HOME_GET_VEHICLES_DISMISS_ERROR = 'HOME_GET_VEHICLES_DISMISS_ERROR';
export const HOME_GET_LOCATIONS_BEGIN = 'HOME_GET_LOCATIONS_BEGIN';
export const HOME_GET_LOCATIONS_SUCCESS = 'HOME_GET_LOCATIONS_SUCCESS';
export const HOME_GET_LOCATIONS_FAILURE = 'HOME_GET_LOCATIONS_FAILURE';
export const HOME_GET_LOCATIONS_DISMISS_ERROR = 'HOME_GET_LOCATIONS_DISMISS_ERROR';
export const HOME_ADD_LOCATION_AREA_BEGIN = 'HOME_ADD_LOCATION_AREA_BEGIN';
export const HOME_ADD_LOCATION_AREA_SUCCESS = 'HOME_ADD_LOCATION_AREA_SUCCESS';
export const HOME_ADD_LOCATION_AREA_FAILURE = 'HOME_ADD_LOCATION_AREA_FAILURE';
export const HOME_ADD_LOCATION_AREA_DISMISS_ERROR = 'HOME_ADD_LOCATION_AREA_DISMISS_ERROR';
export const HOME_GET_LOCATION_AREAS_BEGIN = 'HOME_GET_LOCATION_AREAS_BEGIN';
export const HOME_GET_LOCATION_AREAS_SUCCESS = 'HOME_GET_LOCATION_AREAS_SUCCESS';
export const HOME_GET_LOCATION_AREAS_FAILURE = 'HOME_GET_LOCATION_AREAS_FAILURE';
export const HOME_GET_LOCATION_AREAS_DISMISS_ERROR = 'HOME_GET_LOCATION_AREAS_DISMISS_ERROR';
export const HOME_GET_SERVICES_BEGIN = 'HOME_GET_SERVICES_BEGIN';
export const HOME_GET_SERVICES_SUCCESS = 'HOME_GET_SERVICES_SUCCESS';
export const HOME_GET_SERVICES_FAILURE = 'HOME_GET_SERVICES_FAILURE';
export const HOME_GET_SERVICES_DISMISS_ERROR = 'HOME_GET_SERVICES_DISMISS_ERROR';
export const HOME_ADD_SERVICE_PRICING_BEGIN = 'HOME_ADD_SERVICE_PRICING_BEGIN';
export const HOME_ADD_SERVICE_PRICING_SUCCESS = 'HOME_ADD_SERVICE_PRICING_SUCCESS';
export const HOME_ADD_SERVICE_PRICING_FAILURE = 'HOME_ADD_SERVICE_PRICING_FAILURE';
export const HOME_ADD_SERVICE_PRICING_DISMISS_ERROR = 'HOME_ADD_SERVICE_PRICING_DISMISS_ERROR';
export const HOME_API_CALL_BEGIN = 'HOME_API_CALL_BEGIN';
export const HOME_API_CALL_SUCCESS = 'HOME_API_CALL_SUCCESS';
export const HOME_API_CALL_FAILURE = 'HOME_API_CALL_FAILURE';
export const HOME_API_CALL_DISMISS_ERROR = 'HOME_API_CALL_DISMISS_ERROR';
export const HOME_GET_LOCATION_AREAS_FROM_LOCATION_BEGIN = 'HOME_GET_LOCATION_AREAS_FROM_LOCATION_BEGIN';
export const HOME_GET_LOCATION_AREAS_FROM_LOCATION_SUCCESS = 'HOME_GET_LOCATION_AREAS_FROM_LOCATION_SUCCESS';
export const HOME_GET_LOCATION_AREAS_FROM_LOCATION_FAILURE = 'HOME_GET_LOCATION_AREAS_FROM_LOCATION_FAILURE';
export const HOME_GET_LOCATION_AREAS_FROM_LOCATION_DISMISS_ERROR = 'HOME_GET_LOCATION_AREAS_FROM_LOCATION_DISMISS_ERROR';
export const HOME_SET_SEARCHED_TICKET_DETAILS = 'HOME_SET_SEARCHED_TICKET_DETAILS';
export const HOME_SET_COUNTER_INITILAL_DATA = 'HOME_SET_COUNTER_INITILAL_DATA';
export const HOME_SET_CANCEL_TICKET_DETAILS = 'HOME_SET_CANCEL_TICKET_DETAILS';
