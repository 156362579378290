import React, { Component } from 'react';
import KuposRadio from '../common/KuposRadio';
import PropTypes from 'prop-types';
import KuposCheckbox from '../common/KuposCheckbox';
import KuposInput from '../common/KuposInput';
import { KuposErrorSuccessModal, KuposModal, SvgHome } from '../common';
import CommonService from '../../services/commonService';
// import { TermsAndConditions } from '../pages';

export default class PaymentOptions extends Component {
  static propTypes = {
    onPaymentSelect: PropTypes.func,
    selected: PropTypes.number,
    paymentGatewayList: PropTypes.array,
    isLoggedIn: PropTypes.bool,
    walletMoney: PropTypes.string,
    checkWallet: PropTypes.func,
    walletChecked: PropTypes.bool,
    onPay: PropTypes.func,
    bookTicketPending: PropTypes.bool,
    bookingError: PropTypes.string,
    onBookingErrorClick: PropTypes.func,
    onPromoCheckboxChange: PropTypes.func,
    promoCodeChecked: PropTypes.bool,
    promoCode: PropTypes.string,
    onPromoCodeChange: PropTypes.func,
    onPromoCodeBlur: PropTypes.func,
    onApplyPromoCode: PropTypes.func,
    applyPromoCodePending: PropTypes.bool,
    promoCodeError: PropTypes.string,
    tncChecked: PropTypes.bool,
    onTncChange: PropTypes.func,
    t: PropTypes.func,
    history: PropTypes.object,
    totalAmount: PropTypes.number,
  };
  constructor(props) {
    super(props);
    this.state = {
      tncError: false,
    };
  }

  tncChanged = () => {
    this.setState({ tncError: false });
    this.props.onTncChange();
  };

  onPay = () => {
    this.props.onPay();
    console.log(this.props.totalAmount);
    if (!this.props.selected && this.props.totalAmount > 0) {
      this.setState({ paymentError: true });
      return;
    } else {
      this.setState({ paymentError: false });
    }
    if (!this.props.tncChecked) {
      this.setState({ tncError: true });
    } else {
      this.setState({ tncError: false });
    }
  };

  render() {
    const { t } = this.props;
    return (
      <div className="common-payment-options">
        <div className="kupos-card payment-block">
          <div className="payment-block-heading font13">
            <div>
              {t('PASSENGER_DETAILS.PAYMENT_OPTIONS.NORMAL')}
              <span className="bold">{t('PASSENGER_DETAILS.PAYMENT_OPTIONS.BOLD')}</span>
            </div>
          </div>
          <div className="payment-block-promo flex-row font11">
            <KuposCheckbox
              label={t('PASSENGER_DETAILS.PROMO_CODE')}
              onChange={this.props.onPromoCheckboxChange}
              checked={this.props.promoCodeChecked}
            />
            {this.props.promoCodeChecked ? (
              <div className="promocode-box flex-row">
                <KuposInput
                  className="kupos-border"
                  type="text"
                  placeholder={t('PASSENGER_DETAILS.INSERT_PROMO')}
                  value={this.props.promoCode}
                  onChange={this.props.onPromoCodeChange}
                  onBlur={this.props.onPromoCodeBlur}
                  error={this.props.promoCodeError ? true : false}
                  errorMessage={this.props.promoCodeError}
                />
                <button
                  className="kupos-button font12"
                  disabled={this.props.applyPromoCodePending ? true : false}
                  onClick={this.props.onApplyPromoCode}
                >
                  {this.props.applyPromoCodePending ? (
                    <div className="loader-cricle"></div>
                  ) : (
                    <span>{t('PASSENGER_DETAILS.APPLY')}</span>
                  )}
                </button>
              </div>
            ) : null}
          </div>

          <div className="flex-row payment-methods">
            {console.log('Payment gateways', this.props.paymentGatewayList)}
            {this.props.paymentGatewayList &&
              this.props.paymentGatewayList.map((item, key) => (
                <div
                  className="webpay-payment-method"
                  key={key}
                  onClick={() => {
                    this.setState({ paymentError: null, tncError: null });
                    this.props.onPaymentSelect(item);
                  }}
                >
                  <KuposRadio
                    // onChange={() => this.props.onPaymentSelect(item)}
                    checked={item.code == this.props.selected}
                  />
                  <div className="payment-checkbox"></div>
                  {item.label
                    .toLowerCase()
                    .split(' ')
                    .join('-') == 'web-pay-plus' ? (
                    <img src="/images/webpay_logo.png" alt="" />
                  ) : null}
                  {item.label
                    .toLowerCase()
                    .split(' ')
                    .join('-') == 'paypal' ? (
                    <img src="/images/paypal_logo.png" alt="" />
                  ) : null}
                  {item.label
                    .toLowerCase()
                    .split(' ')
                    .join('-') == 'banco-estado' ? (
                    <img
                      // style={{width:"25vw"}}
                      src={'/images/banco-estado.png'}
                      alt=""
                    />
                  ) : null}
                  {item.label
                    .toLowerCase()
                    .split(' ')
                    .join('-') != 'paypal' &&
                  item.label
                    .toLowerCase()
                    .split(' ')
                    .join('-') != 'web-pay-plus' &&
                  item.label
                    .toLowerCase()
                    .split(' ')
                    .join('-') != 'banco-estado' ? (
                    // <span style={{fontWeight:"bold"}}>{item.label}</span>
                    <img
                      // style={{width:"25vw"}}
                      src={'/images/one-click.png'}
                      alt=""
                    />
                  ) : null}
                </div>
              ))}
            {this.props.isLoggedIn ? (
              <div className="kupos-payment-moneys kupos-pay-method">
                <div
                  className="kupos-payment-method kupos-pay bold-text font10"
                  onClick={this.props.checkWallet}
                >
                  <div className="flex-row kupos-pay-check">
                    <KuposRadio disabled={true} checked={this.props.walletChecked} />{' '}
                    <img
                      onClick={() => {
                        this.setState({ paymentError: null, tncError: null });
                        this.props.checkWallet();
                      }}
                      src="/images/kupos_pay_logo.png"
                      alt=""
                    />{' '}
                  </div>
                  <span className="kupos-amount">
                    {' '}
                    {this.props.isLoggedIn
                      ? CommonService.currency(this.props.walletMoney).split(',')[0]
                      : 'CLP $0'}
                  </span>
                </div>
                <div className="kupos-credit bold-text font10">
                  <KuposCheckbox
                    checked={this.props.virtualChecked}
                    onChange={() => {
                      this.setState({ paymentError: null, tncError: null });
                      this.props.checkVirtual();
                    }}
                    label={'KuposCréditos'}
                    labelStyle={{ fontSize: 12 }}
                    labelBold={true}
                  />
                  <span className="kupos-amount">
                    {' '}
                    {this.props.isLoggedIn
                      ? CommonService.currency(this.props.virtualMoney).split(',')[0]
                      : 'CLP $0'}
                  </span>
                </div>
              </div>
            ) : null}
            {/* <div className="kupos-payment-method">    
                   </div> */}
          </div>

          <div className="accept-tnc font10">
            <KuposCheckbox checked={this.props.tncChecked} onChange={this.tncChanged} />
            <a onClick={() => this.setState({ showTNCModel: true })}>
              {t('PASSENGER_DETAILS.I_ACCEPT_TNC')}
            </a>
          </div>

          {this.state.tncError ? (
            <div className="tnc-error secondary-text font10 center-text">
              {t('PASSENGER_DETAILS.PLEASE_ACCEPT_TNC')}
            </div>
          ) : null}
          {this.state.paymentError ? (
            <div className="tnc-error secondary-text font10 center-text">
              {t('PASSENGER_DETAILS.PLEASE_SELECT_PAYMENT')}
            </div>
          ) : null}

          <div className="pay-now-section font13">
            <button
              onClick={this.onPay}
              className="pay-now-button kupos-button"
              disabled={this.props.bookTicketPending ? true : false}
            >
              {this.props.bookTicketPending ? (
                <div className="loader-cricle"></div>
              ) : (
                <span>{t('PASSENGER_DETAILS.PAY')}</span>
              )}
            </button>
          </div>
          <KuposErrorSuccessModal
            showModal={this.props.bookingError ? true : false}
            success={false}
            onButtonClick={this.props.onBookingErrorClick}
            bodyText={this.props.bookingError}
          />
        </div>

        <KuposModal
          ariaLabel="tnc-pnr-modal-outer"
          size={'xl'}
          onHide={() => this.setState({ showTNCModel: false })}
          showModal={this.state.showTNCModel}
        >
          {/* <div className="tnc-pnr-modal">
            <TermsAndConditions />
            <div
              className="close-button pointer"
              onClick={() => this.setState({ showTNCModel: false })}
            >
              <SvgHome name="close" />
            </div>
          </div> */}
        </KuposModal>
      </div>
    );
  }
}
