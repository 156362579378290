import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { DropdownComponent } from '../common/DropdownComponent';
import {
  KuposButton,
  KuposDatePicker,
  KuposErrorSuccessModal,
  KuposInput,
  KuposTimePicker,
} from '../common';
import DateService from '../../services/dateService';
import CommonService from '../../services/commonService';
import ApiUrls from '../../services/apiUrls';
import { apiCall } from './redux/apiCall';

const modalInitialState = {
  show: false,
  success: false,
  bodyText: null,
};

const txnTypes = [
  { value: 'AT_BOOKING', label: 'Delfos website' },
  { value: 'COUNTER_BOOKING', label: 'Cajas' },
  { value: 'LOCAL_BOOKING', label: 'kupos' },
];

const initialState = {
  txn_code: '',
  start_time_stg: '00:00',
  end_time_stg: '00:00',
  start_date_stg: DateService.getTodayString(),
  end_date_stg: DateService.getTodayString(),
  remark: '',
};

const AddBlockUnblock = ({ history }) => {
  const dispatch = useDispatch();
  const [values, setValues] = useState(initialState);
  const [modalValues, setModalValues] = useState(modalInitialState);
  const [buttonLoader, setButtonLoader] = useState(false);
  const [selectedTxn, setSelectedTxn] = useState(null);

  const onInputChnage = (val, type) => {
    setValues({ ...values, [type]: val, [type + 'Error']: null });
  };

  // onBlur = () => { };
  // console.log({ values, modalValues, selectedTxn });

  const validate = () => {
    if (!selectedTxn) {
      setValues({ ...values, selectedTxnError: 'Seleccionar tipo de transacción' });
      return false
    }

    if (!values.start_date_stg) {
      setValues({ ...values, start_date_stgError: 'Seleccionar fecha de inicio' });
      return false
    }

    if (!values.start_time_stg) {
      setValues({ ...values, start_time_stgError: 'Seleccionar hora de inicio' });
      return false
    }

    if (!values.end_date_stg) {
      setValues({ ...values, end_date_stgError: 'Seleccionar fecha final' });
      return false
    }

    if (!values.end_time_stg) {
      setValues({ ...values, end_time_stgError: 'Seleccionar hora de finalización' });
      return false
    }

    if (!values.remark) {
      setValues({ ...values, remarkError: 'Obligatorio' });
      return false
    }
    return true;
  };

  const createOrUpdate = () => {
    if (!validate()) {
      return;
    }


    let start_date_time = CommonService.getTimeInSeconds(
      `${DateService.changeDateFormat(values.start_date_stg, 'dd-mm-yyyy', 'yyyy-mm-dd')}T${values.start_time_stg
      }`,
    );
    let end_date_time = CommonService.getTimeInSeconds(
      `${DateService.changeDateFormat(values.end_date_stg, 'dd-mm-yyyy', 'yyyy-mm-dd')}T${values.end_time_stg
      }`,
    );

    if (start_date_time > end_date_time) {
      setValues({ ...values, end_time_stgError: 'Please enter a future time!' });
      return;
    }

    console.log("startdatetime",end_date_time)

    // setValues({ ...values, start_date_time, end_date_time });

    let data = {
      txn_code: values.txn_code,
      start_date_time,
      start_date_stg: values.start_date_stg,
      start_time_stg: values.start_time_stg,
      end_date_time,
      end_date_stg: values.end_date_stg,
      end_time_stg: values.end_time_stg,
      remark: values.remark,
    }

    console.log('New data', { data });

    // return
    setButtonLoader(true);

    dispatch(
      apiCall({
        url: ApiUrls.blockUnblock(),
        method: 'POST',
        data: data,
        callback: res => onResponse(res),
      }),
    );
  };
  const onResponse = res => {
    console.log('Response from add block request-------', res);
    setButtonLoader(false);
    if (res && res.data && res.data.data && res.data.success) {
      setModalValues({
        show: true,
        success: true,
        bodyText: res.data.message || 'Record added successfully!',
      });
    } else {
      setModalValues({
        show: true,
        success: false,
        bodyText: res.data.message || 'Some issue in creating record, Please try again!',
      });
    }
  };

  const hideModal = () => {
    setModalValues({ ...modalValues, show: false, bodyText: null });
    if (modalValues.success) {
      history.goBack();
    }
  };
console.log("selected service is", values)
  return (
    <div className="home-add-block-unblock">
      <div style={{ marginTop: 10, marginBottom: 10 }}>
        <DropdownComponent
          onChange={val => {
            setValues({ ...values, txn_code: val.value, selectedTxnError: null });
            setSelectedTxn(val);
          }}
          placeholder={'Seleccionar tipo de transacción'}
          options={txnTypes}
          value={selectedTxn}
          error={values.selectedTxnError ? true : false}
          errorMessage={values.selectedTxnError}
        />
      </div>

      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'baseline',
          marginBottom: 15,
          marginTop: 15,
        }}
      >
        <div
          className="home-selection-input-box font10 datebox"
          style={{ flex: 1, marginRight: 10 }}
        >
          <span className="label font11">{'Fecha de inicio'}</span>
          <KuposDatePicker
            selectedDate={values.start_date_stg}
            onDateChange={val => onInputChnage(val, 'start_date_stg')}
            minDate={DateService.getTodayString()}
            error={values.start_date_stgError ? true : false}
            errorMessage={values.start_date_stgError}
            placeholder={DateService.getTodayString()}
          />
        </div>

        <div
          className="home-selection-input-box  font10 datebox"
          style={{ flex: 1, marginLeft: 10 }}
        >
          <span className="label font11">{'Hora de inicio'}</span>
          <KuposTimePicker
            value={values.start_time_stg}
            onChange={val => onInputChnage(val, 'start_time_stg')}
            error={values.start_time_stgError ? true : false}
            errorMessage={values.start_time_stgError}
            placeholder={'00:00'}
            leftPadding={35}
          />
        </div>
      </div>

      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'baseline',
          marginBottom: 15,
          marginTop: 15,
        }}
      >
        <div
          className="home-selection-input-box font10 datebox"
          style={{ flex: 1, marginRight: 10 }}
        >
          <span className="label font11">{'Fecha final'}</span>
          <KuposDatePicker
            selectedDate={values.end_date_stg}
            onDateChange={val => onInputChnage(val, 'end_date_stg')}
            minDate={DateService.getTodayString()}
            error={values.end_date_stgError ? true : false}
            errorMessage={values.end_date_stgError}
            placeholder={DateService.getTodayString()}
          />
        </div>

        <div
          className="home-selection-input-box font10 datebox"
          style={{ flex: 1, marginLeft: 10 }}
        >
          <span className="label font11">{'Hora de finalización'}</span>
          <KuposTimePicker
            value={values.end_time_stg}
            onChange={val => onInputChnage(val, 'end_time_stg')}
            error={values.end_time_stgError ? true : false}
            errorMessage={values.end_time_stgError}
            placeholder={'00:00'}
            leftPadding={35}
          />
        </div>
      </div>

      <div className="common-kupos-input-box" style={{ marginTop: 10 }}>
        <KuposInput
          type="text"
          className="kupos-border"
          placeholder={'Razones para bloquear'}
          value={values.remark}
          error={values.remarkError ? true : false}
          errorMessage={values.remarkError}
          onChange={text => onInputChnage(text, 'remark')}
        // onBlur={text => this.onBlur(text, 'remark')}
        />
      </div>

      <div className="button-container" style={{ marginTop: 20 }}>
        <KuposButton onClick={createOrUpdate}>
          <span className="font11">
            {buttonLoader ? <div className="loader-cricle"></div> : 'Agregar registro'}
          </span>
        </KuposButton>
      </div>

      <KuposErrorSuccessModal
        showModal={modalValues.show}
        success={modalValues.success}
        smallIcon={true}
        bodyText={modalValues.bodyText}
        onButtonClick={() => hideModal()}
      />
    </div>
  );
}



export default AddBlockUnblock
