import AppData from './appData';
import CommonService from './commonService';

const ApiUrls = {
  login: () => {
    return AppData.BASE_URL + 'login';
  },
  logout: () => {
    return AppData.BASE_URL + 'logout';
  },
  user: id => {
    return AppData.BASE_URL + 'user' + (id ? '/' + id : '');
  },
  getBookingsForAgent: () => {
    return AppData.BASE_URL + 'booking';
  },
  getTransactionSummary: () => {
    return AppData.BASE_URL + 'getTransactionSummary';
  },
  getMasterData: () => {
    return AppData.BASE_URL + 'master_data/SEAT/TYPE';
  },
  getMasterTypeData: () => {
    return AppData.BASE_URL + 'master_data/CLIENT/TYPE';
  },
  getDrivers: () => {
    return AppData.BASE_URL + 'driver';
  },
  getVehicles: id => {
    return AppData.BASE_URL + 'vehicle' + (id ? '/' + id : '');
  },
  getLocations: () => {
    return AppData.BASE_URL + 'location';
  },
  getLocationList: () => {
    return AppData.BASE_URL + 'locations';
  },
  getAtTerminalList: () => {
    return AppData.BASE_URL + 'airportLocations';
  },
  addLocationArea: () => {
    return AppData.BASE_URL + 'location_area';
  },
  getLocationAreas: id => {
    return AppData.BASE_URL + 'location_area' + (id ? '/' + id : '');
  },
  getServices: () => {
    return AppData.BASE_URL + 'service';
  },
  addServicePricing: id => {
    return AppData.BASE_URL + 'service_price' + (id ? '/' + id : '');
  },
  addServicePrice: id => {
    return AppData.BASE_URL_V2 + 'service_price' + (id ? '/' + id : '');
  },
  addOldServicePrice: id => {
    return AppData.BASE_URL + 'service_price' + (id ? '/' + id : '');
  },
  getLocationAreasFromLocation: () => {
    return AppData.BASE_URL + 'location_area_by_location_id';
  },
  role: () => {
    return AppData.BASE_URL + 'role';
  },
  addLocation: id => {
    return AppData.BASE_URL + 'location' + (id ? '/' + id : '');
  },
  addService: id => {
    return AppData.BASE_URL + 'service' + (id ? '/' + id : '');
  },
  addServiceImages: () => {
    return AppData.BASE_URL + 'serviceImage';
  },
  getServiceImages: id => {
    return AppData.BASE_URL + 'serviceImage' + '/' + id ;
  },
  deleteServiceImage: (serviceId,imageId) =>{
    return AppData.BASE_URL + 'serviceImage' + '/' + serviceId  + '/' + imageId;
  },
  addDriver: id => {
    return AppData.BASE_URL + 'driver' + (id ? '/' + id : '');
  },
  assignDriverAndVehicle: () => {
    return AppData.BASE_URL + 'assignVehicleAndDriver';
  },
  updateVehicleAndDriver: () => {
    return AppData.BASE_URL + 'updateVehicleAndDriver';
  },
  dispatchStatus: () => {
    return AppData.BASE_URL + 'setDispatchStatus';
  },
  blockUnblock: () => {
    return AppData.BASE_URL + 'blockTxn';
  },
  unblockTxn: () => {
    return AppData.BASE_URL + 'unblockTxn';
  },
  addCountry: id => {
    return AppData.BASE_URL + 'country' + (id ? '/' + id : '');
  },
  addState: id => {
    return AppData.BASE_URL + 'state' + (id ? '/' + id : '');
  },
  addCity: id => {
    return AppData.BASE_URL + 'city' + (id ? '/' + id : '');
  },
  addBranch: id => {
    return AppData.BASE_URL + 'branch' + (id ? '/' + id : '');
  },
  addShifts: id => {
    return AppData.BASE_URL + 'shifts' + (id ? '/' + id : '');
  },
  addCancellationPolicy: id => {
    return AppData.BASE_URL + 'cancellation_policy' + (id ? '/' + id : '');
  },
  allCounters: () => {
    return AppData.BASE_URL + 'counters';
  },
  addCounter: id => {
    return AppData.BASE_URL + 'counter' + (id ? '/' + id : '');
  },
  addPos: id => {
    return AppData.BASE_URL + 'pos' + (id ? '/' + id : '');
  },
  allIncomeExpense: () => {
    return AppData.BASE_URL + 'inc_exp_summary';
  },
  addIncomeExpense: id => {
    return AppData.BASE_URL + 'inc_exp' + (id ? '/' + id : '');
  },
  openCounter: () => {
    return AppData.BASE_URL + 'counter/open';
  },
  getCurrencies: () => {
    return AppData.BASE_URL + 'currency';
  },
  addCurrencyExchange: () => {
    return AppData.BASE_URL + 'currencyExchange ';
  },
  getClientInfo: subdomain => {
    return AppData.BASE_URL + `client/by-subdomain/${subdomain}`;
  },
  clients: id => {
    return AppData.BASE_URL_V1+ 'clients' + (id ? '/' + id : '');
  },
  getAddtionalFareList: () => {
    return AppData.BASE_URL_V1 + 'master_data/FARE/ADDITIONAL';
  },
  getPlatformRolesList: () => {
    return AppData.BASE_URL_V1 + 'master_data/ROLE/NAME';
  },
};
export default ApiUrls;
