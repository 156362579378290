import React, { Component } from 'react';
import PropTypes from 'prop-types';
import DateService from '../../services/dateService';
import CommonService from '../../services/commonService';
import { NewsLetterSuccessModal } from './NewsLetterSuccessModal';
import i18next from 'i18next';
import ApiUrls from '../../services/apiUrls';
import { KuposErrorSuccessModal } from '.';


export default class KuposFooter extends Component {
  static propTypes = {
    t: PropTypes.func,
    history: PropTypes.object,
    metaData: PropTypes.object,
    actions: PropTypes.object,
  };
  constructor(props) {
    super(props);
    this.state = {
      newsLetterModal: false
    };
  }

  gotoHome = () => {
    window.scrollTo(0, 0);
    if (CommonService.clearSearchFunction) {
      CommonService.clearSearchFunction();
    }
    this.props.actions.setOnwardPbDate(DateService.getTodayString());
    this.props.actions.setReturnPbDate(null);
    this.props.actions.pbSetSourceCity(null);
    this.props.actions.pbSetDestinationCity(null);
    this.props.actions.setServiceListOnward(null);
    this.props.actions.setServiceListReturn(null);
    if (this.props.history) this.props.history.push('/');
  };

  subscribe = () => {
    if (!this.state.email) {
      this.setState({
        emailError: 'VALIDATIONS.VALID_EMAIL',
      });
      return
    } else if (!CommonService.isEMailValid(this.state.email)) {
      this.setState({
        emailError: 'VALIDATIONS.VALID_EMAIL_VALIDATION',
      });
      return
    }
    this.props.actions.subscribe({ url: ApiUrls.subscribe(), data: {"email": this.state.email} }).then(
      res => {
        if (res.data && res.data.status === 200) {
          this.setState({ newsLetterModal: true })
        }else if(res.data){
          this.setState({ newsLetterFailureModal: true,  newsLetterFailureModalMessage: res.data.error });
        }
      },
      err => {
      },
    );
  }

  render() {
    const { t, history } = this.props;
    return (
      <div className="kupos-footer-main">
        <div className="kupos-footer">
          <div className=" content-container">
            <div className="footer-logo" onClick={this.gotoHome}>
              <img src="/svgs/kupos-logo2.svg" alt={this.props.t('TRANSLATIONS.KUPOS_LOGO2')} />
            </div>

            <div className="footer-details">
              <div className="individual-footer-details">
                <div className="individual-footer-item font10">
                  <span className="bold-footer-item bold-text font14">{t('HOME.OUR_COMPANY')}</span>
                  <div className="underline" onClick={() => history.push(`/${i18next.language}/pages/about`)}>
                    {t('HOME.ABOUT_KUPOS')}
                  </div>
                  <div
                    className="underline"
                    onClick={() =>
                      history.push(`/${i18next.language}/pages/partners`)
                    }
                  >
                    {t('HOME.OUR_PARTNERS')}
                  </div>
                  <div className="underline">{t('HOME.CARRERS')}</div>
                  <div className="underline" onClick={() => history.push(`/${i18next.language}/pages/press`)}>
                    {t('HOME.PRESS')}
                  </div>
                </div>

                <div className="individual-footer-item font10">
                  <span className="bold-footer-item bold-text font14">
                    {t('HOME.USEFUL_DETAILS')}
                  </span>
                  <div className="underline" onClick={() => history.push(`/${i18next.language}/pages/contact`)}>
                    {t('HOME.CONTACT_US')}{' '}
                  </div>
                  <div className="underline">{t('HOME.FAQ')}</div>
                  <div className="underline" onClick={() => history.push(`/${i18next.language}/pages/tnc`)}>
                    {t('HOME.TNC')}{' '}
                  </div>
                  <div className="underline" onClick={() => history.push(`/${i18next.language}/pages/privacy-policy`)}>
                    {t('HOME.PRIVACY_POLICY')}{' '}
                  </div>
                  <div className="underline" onClick={() => history.push(`/${i18next.language}/site-map`)}>
                    {t('HOME.SITEMAP')}
                  </div>
                </div>

                <div className="individual-footer-item subscribe-n-social">
                  <div className="subscribe-div">
                    <span className="bold-footer-item bold-text font14">
                      {t('HOME.NEWSLETTER')}
                    </span>
                    <div className="footer-news-letter-input flex-row font10">
                      <input
                        className="font10"
                        type="email"
                        placeholder={t('PASSENGER_DETAILS.EMAIL_ID')}
                        value={this.state.email}
                        onChange={event => this.setState({ email: event.target.value.trim(), emailError: null })}
                        onBlur={event => this.setState({ email: event.target.value })}
                      />
                      <button 
                        onClick={this.subscribe} 
                        className="kupos-button no-decoration" 
                        disabled={this.props.subscribePending ? true : false}>
                          {this.props.subscribePending ? (
                            <div className="loader-cricle"></div>
                          ) : (
                            <span>{t('HOME.SEND')}</span>
                          )}
                      </button>
                    </div>
                    {this.state.emailError ? <div style={{marginTop:-5}} className="errorMessageInput font8">{t(this.state.emailError)}</div> : null}
                  </div>

                  <div className="div-social-media-icons">
                    <div className="social-icons-footer">
                      <div className="footer-social-image">
                        {this.props.metaData ? (
                          <a
                            href={this.props.metaData.social_media_urls.facebookUrl}
                            target="_blank"
                          >
                            <img src="/images/icons/home/icon-facebook.png" alt={this.props.t('TRANSLATIONS.FB')} />
                          </a>
                        ) : null}
                      </div>
                      <div className="footer-social-image">
                        {this.props.metaData ? (
                          <a href={'https://www.instagram.com/pasajebus/'} target="-blank">
                            <img src="/images/icons/home/icon-instagram.png" alt={this.props.t('TRANSLATIONS.INSTA')} />
                          </a>
                        ) : null}
                      </div>
                      <div className="footer-social-image">
                        {this.props.metaData ? (
                          <a
                            href={this.props.metaData.social_media_urls.twitterUrl}
                            target="_blank"
                          >
                            <img src="/images/icons/home/icon-twitter.png" alt={this.props.t('TRANSLATIONS.TWITTER')} />
                          </a>
                        ) : null}
                      </div>
                      <div className="footer-social-image">
                        {this.props.metaData ? (
                          <a href={this.props.metaData.social_media_urls.blogUrl} target="_blank">
                            <img src="/images/icons/home/icon-blog.png" alt={this.props.t('TRANSLATIONS.BLOG')} />
                          </a>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="last-footer font10">
          <span className="copyright-message">{t('HOME.FOOTER_COPYRIGHT')}</span>
        </div>

        <NewsLetterSuccessModal
          showModal={this.state.newsLetterModal}
          onHide={() => this.setState({ newsLetterModal: false })}
          t={this.props.t}
        />
        <KuposErrorSuccessModal 
          onHide={() => this.setState({ newsLetterFailureModal: false })}
          showModal={this.state.newsLetterFailureModal}
          success={false}
          bodyText={this.state.newsLetterFailureModalMessage}
         />
      </div>
    );
  }
}
