import Axios from 'axios';
import ApiUrls from '../../../services/apiUrls';
import CommonService from '../../../services/commonService';
import {
  HOME_GET_BOOKINGS_FOR_AGENT_BEGIN,
  HOME_GET_BOOKINGS_FOR_AGENT_SUCCESS,
  HOME_GET_BOOKINGS_FOR_AGENT_FAILURE,
  HOME_GET_BOOKINGS_FOR_AGENT_DISMISS_ERROR,
} from './constants';

export function getBookingsForAgent(args = {}) {
  return (dispatch) => { // optionally you can have getState as the second argument
    dispatch({
      type: HOME_GET_BOOKINGS_FOR_AGENT_BEGIN,
    });

    // console.log("Args in")

    const promise = new Promise((resolve, reject) => {
      const doRequest = Axios.get(args.url,{
        headers: CommonService.getHeaders(),
      });
      doRequest.then(
        (res) => {
          dispatch({
            type: HOME_GET_BOOKINGS_FOR_AGENT_SUCCESS,
            data: res.data,
          });
          resolve(res);
        },
        // Use rejectHandler as the second argument so that render errors won't be caught.
        (err) => {
          dispatch({
            type: HOME_GET_BOOKINGS_FOR_AGENT_FAILURE,
            data: { error: err },
          });
          reject(err);
        },
      );
    });

    return promise;
  };
}

export function dismissGetBookingsForAgentError() {
  return {
    type: HOME_GET_BOOKINGS_FOR_AGENT_DISMISS_ERROR,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case HOME_GET_BOOKINGS_FOR_AGENT_BEGIN:
      // Just after a request is sent
      return {
        ...state,
        getBookingsForAgentPending: true,
        getBookingsForAgentError: null,
      };

    case HOME_GET_BOOKINGS_FOR_AGENT_SUCCESS:
      // The request is success
      return {
        ...state,
        getBookingsForAgentPending: false,
        getBookingsForAgentError: null,
        bookingsForAgent:action.data
      };

    case HOME_GET_BOOKINGS_FOR_AGENT_FAILURE:
      // The request is failed
      return {
        ...state,
        getBookingsForAgentPending: false,
        getBookingsForAgentError: action.data.error,
      };

    case HOME_GET_BOOKINGS_FOR_AGENT_DISMISS_ERROR:
      // Dismiss the request failure error
      return {
        ...state,
        getBookingsForAgentError: null,
      };

    default:
      return state;
  }
}
