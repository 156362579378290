import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actions from './redux/actions';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import CommonService from '../../services/commonService';
import AppData from '../../services/appData';
import { colors, Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';

const columns = [
  { id: 'name', label: 'Name', minWidth: 170 },
  { id: 'code', label: 'ISO\u00a0Code', minWidth: 100 },
  {
    id: 'population',
    label: 'Population',
    minWidth: 170,
    align: 'right',
    format: value => value.toLocaleString('en-US'),
  },
  {
    id: 'size',
    label: 'Size\u00a0(km\u00b2)',
    minWidth: 170,
    align: 'right',
    format: value => value.toLocaleString('en-US'),
  },
  {
    id: 'density',
    label: 'Density',
    minWidth: 170,
    align: 'right',
    format: value => value.toFixed(2),
  },
];

function createData(name, code, population, size) {
  const density = population / size;
  return { name, code, population, size, density };
}

const rows = [
  createData('India', 'IN', 876567, 3287263),
  createData('China', 'CN', 76567567, 9596961),
  createData('Italy', 'IT', 60483973, 301340),
  createData('United States', 'US', 327167434, 9833520),
  createData('Canada', 'CA', 37602103, 9984670),
  createData('Australia', 'AU', 25475400, 7692024),
  createData('Germany', 'DE', 83019200, 357578),
  createData('Ireland', 'IE', 4857000, 70273),
  createData('Mexico', 'MX', 126577691, 1972550),
  createData('Japan', 'JP', 126317000, 377973),
  createData('France', 'FR', 67022000, 640679),
  createData('United Kingdom', 'GB', 67545757, 242495),
  createData('Russia', 'RU', 146793744, 17098246),
  createData('Nigeria', 'NG', 200962417, 923768),
  createData('Brazil', 'BR', 210147125, 8515767),
];

export class CounterDashboard extends Component {
  static propTypes = {
    home: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      selectedTab: 1,
    };
  }

  componentDidMount() {
    let counterDetails = CommonService.getLocal('counter');

    console.log('Counter details are-------', counterDetails);

    if (!counterDetails) {
      this.setState({
        noCounter: true,
        showSnackbar: true,
        alertMessage: "Usted no tiene caja abierta",
      });
      return;
    }

    this.props.actions
      .apiCall({
        url:
          AppData.BASE_URL +
          // `agent/counterClosureSummary/8/164`,
          `agent/counterClosureSummary/${counterDetails.counter_id}/${counterDetails.counter_txn_id}`,
      })
      .then(
        res => {
          this.onShiftSummaryReportFetch(res);
        },
        err => {
          this.onShiftSummaryReportFetch(err);
        },
      );
  }

  onShiftSummaryReportFetch = res => {
    if (res && res.data && res.data.success && res.data.data) {
      console.log('+++tickets details are--__', res.data.data);
      this.setState({
        counterReport: res.data.data.counter_txn_summary,
        ticketDetails: res.data.data.counter_tickets_summary,
        selectedTrips:
          res.data.data.counter_tickets_summary &&
          res.data.data.counter_tickets_summary.cash_ticket_details,
      });
    }
  };

  onTabChange = tab => {
    this.setState({ selectedTab: tab });

    switch (tab) {
      case 1:
        this.setState({
          selectedTrips: this.state.ticketDetails && this.state.ticketDetails.cash_ticket_details,
        });
        break;
      case 2:
        this.setState({
          selectedTrips:
            this.state.ticketDetails && this.state.ticketDetails.credit_card_ticket_details,
        });
        break;
      case 3:
        this.setState({
          selectedTrips:
            this.state.ticketDetails && this.state.ticketDetails.debit_card_ticket_details,
        });
        break;
      case 4:
        this.setState({
          selectedTrips:
            this.state.ticketDetails && this.state.ticketDetails.bank_transfer_ticket_details,
        });
        break;
      case 5:
        this.setState({
          selectedTrips: this.state.ticketDetails && this.state.ticketDetails.cancel_ticket_details,
        });
        break;
      case 6:
        this.setState({
          selectedTrips:
            this.state.ticketDetails && this.state.ticketDetails.credit_invoice_ticket_details,
        });
        break;
    }
  };

  renderTabs = () => {
    return (
      <div className="recharge-tabs-container">
        <div className="map-container-selector font10" style={{ marginTop: 0 }}>
          <div
            className={'selector-item ' + (this.state.selectedTab == 1 ? 'active' : '')}
            onClick={() => this.onTabChange(1)}
          >
            {'Boletos en efectivo'}
          </div>
          <div
            className={'selector-item ' + (this.state.selectedTab == 2 ? 'active' : '')}
            onClick={() => this.onTabChange(2)}
          >
            {'Boletos en tarjeta de crédito'}
          </div>

          <div
            className={'selector-item ' + (this.state.selectedTab == 3 ? 'active' : '')}
            onClick={() => this.onTabChange(3)}
          >
            {'Boletos en tarjeta de débito'}
          </div>

          <div
            className={'selector-item ' + (this.state.selectedTab == 4 ? 'active' : '')}
            onClick={() => this.onTabChange(4)}
          >
            {'Boletos en transferencia bancaria'}
          </div>
          <div
            className={'selector-item ' + (this.state.selectedTab == 5 ? 'active' : '')}
            onClick={() => this.onTabChange(5)}
          >
            {'Cancelaciones'}
          </div>

          <div
            className={'selector-item ' + (this.state.selectedTab == 6 ? 'active' : '')}
            onClick={() => this.onTabChange(6)}
          >
            {' Factura de crédito'}
          </div>
        </div>
      </div>
    );
  };

  renderTwoItems = (label, value) => {
    return (
      <div className="font11" style={{ display: 'flex', alignItems: 'center' }}>
        <div>{label}</div>
        <span style={{ marginLeft: 10, marginRight: 10 }}>-</span>
        <div>{value}</div>
      </div>
    );
  };

  translatedShifts = shift => {
    let translatedShift = '';
    if (shift.includes('Morning')) {
      translatedShift = 'Mañana';
    } else if (shift.includes('Afternoon')) {
      translatedShift = 'Tarde';
    } else if (shift.includes('Evening')) {
      translatedShift = 'Noche';
    } else {
      translatedShift = shift;
    }
    return translatedShift;
  };

  renderTicketItem = ticket => {
    return (
      <div className="ticket-item-single">
        <span style={{width:"40%"}}>{ticket.pnr_data}</span>
        <span style={{width:"50%"}}>{ticket.date_time_data}</span>
        <span style={{width:"10%"}}>{CommonService.currency(ticket.Amount)}</span>
      </div>
    );
  };

  render() {
    console.log('counterReport-----------------------', this.state.selectedTrips);
    return !this.state.noCounter ? (
      <div className="home-counter-dashboard">
        <div className="counter-dashboard-container">
          <div className="step-1">
            <div className="quick-reports">
              <div className="bold-text font14" style={{ marginBottom: 10 }}>
                {/* Shift summary report */}
                Resumen transaccional de caja
              </div>
              {this.state.counterReport && this.state.counterReport.counter_customer_txn_summary && (
                <div>
                  {this.renderTwoItems(
                    'Nombre de sucursal ',

                    this.state.counterReport.counter_customer_txn_summary.branch_name,
                  )}
                  {this.renderTwoItems(
                    'Turno',
                    this.translatedShifts(
                      this.state.counterReport.counter_customer_txn_summary.shift_name,
                    ),
                  )}
                  {this.renderTwoItems(
                    'Balance inicial',
                    this.state.counterReport.counter_customer_txn_summary.opening_balance,
                  )}
                  {this.renderTwoItems(
                    'Balance de cierre ',
                    this.state.counterReport.counter_customer_txn_summary.closing_balance,
                  )}
                  {this.renderTwoItems(
                    'Monto de transferencias bancarias',
                    this.state.counterReport.counter_customer_txn_summary
                      .banktransfer_booking_amount,
                  )}
                  {this.renderTwoItems(
                    'Monto tarjeta de crédito ',
                    this.state.counterReport.counter_customer_txn_summary.credit_booking_amount,
                  )}
                  {this.renderTwoItems(
                    'Monto tarjeta de débito ',
                    this.state.counterReport.counter_customer_txn_summary.debit_booking_amount,
                  )}
                  {this.renderTwoItems(
                    'Monto en efectivo',
                    this.state.counterReport.counter_customer_txn_summary.cash_booking_amount,
                  )}

                  {this.renderTwoItems(
                    'Factura de crédito',
                    this.state.counterReport.counter_customer_txn_summary.credit_invoice_booking_amount || 0,
                  )}

                  {this.renderTwoItems(
                    'Monto total',
                    this.state.counterReport.counter_customer_txn_summary.total_booking_amount,
                  )}

                  {this.renderTwoItems(
                    'Monto Descuento',
                    this.state.counterReport.counter_customer_txn_summary.total_discount_amount,
                  )}
                  {this.renderTwoItems(
                    'Monto total de cancelaciones',
                    this.state.counterReport.counter_customer_txn_summary.total_cancellation_amount,
                  )}
                </div>
              )}
            </div>
            <div className="booking-summary">
              <div className="header-conatiner">Tu resumen de ventas</div>
              {this.renderTabs()}

              {this.state.selectedTrips && this.state.selectedTrips.ticket_list ? (
                this.state.selectedTrips.ticket_list.map(val => {
                  return this.renderTicketItem(val);
                })
              ) : (
                <div style={{ display: 'flex', justifyContent: 'center', marginTop: 50 }}>
                  Sin transacciones registradas
                </div>
              )}

              {/* <div className="table-root">
                <TableContainer className="table-height-30vh" style={{ minHeight: '45vh' }}>
                  <Table stickyHeader size="small" aria-label="a dense table">
                    <TableHead>
                      <TableRow>
                        {columns.map(column => (
                          <TableCell
                            key={column.id}
                            align={column.align}
                            style={{ minWidth: column.minWidth }}
                          >
                            {column.label}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {rows.slice(0 * 10, 0 * 10 + 10).map(row => {
                        return (
                          <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                            {columns.map(column => {
                              const value = row[column.id];
                              return (
                                <TableCell key={column.id} align={column.align}>
                                  {column.format && typeof value === 'number'
                                    ? column.format(value)
                                    : value}
                                </TableCell>
                              );
                            })}
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  rowsPerPageOptions={[10, 25, 100]}
                  component="div"
                  count={rows.length}
                  rowsPerPage={10}
                  page={0}
                  // onChangePage={handleChangePage}
                  // onChangeRowsPerPage={handleChangeRowsPerPage}
                />
              </div> */}
            </div>
          </div>
          {/* <div className="step-2">
            <div className="quick-links">
              <div>some link 1</div>
              <div>some link 1</div>
              <div>some link 1</div>
              <div>some link 1</div>
            </div>
            <div className="counter-summary">
              <div>some link 1</div>
              <div>some link 1</div>
              <div>some link 1</div>
              <div>some link 1</div>
            </div>
          </div> */}
        </div>

        <Snackbar
          open={this.state.showSnackbar}
          autoHideDuration={5000}
          anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
          onClose={this.closeSnackbar}
          //   message={this.state.alertMessage}
        >
          <Alert onClose={() => this.setState({ showSnackbar: false })} severity="error">
            {this.state.alertMessage}
          </Alert>
        </Snackbar>
      </div>
    ) : (
      <Snackbar
        open={this.state.showSnackbar}
        autoHideDuration={5000}
        anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
        onClose={this.closeSnackbar}
        //   message={this.state.alertMessage}
      >
        <Alert onClose={() => this.setState({ showSnackbar: false })} severity="error">
          {this.state.alertMessage}
        </Alert>
      </Snackbar>
    );
  }
}

/* istanbul ignore next */
function mapStateToProps(state) {
  return {
    home: state.home,
  };
}

/* istanbul ignore next */
function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...actions }, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CounterDashboard);
