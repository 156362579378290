import React, { useEffect, useRef, useState } from 'react';
import { KuposButton, KuposErrorSuccessModal, KuposListLoader, KuposModalWithButtons } from '../common';
import ApiUrls from '../../services/apiUrls';
import AddButton from '../common/AddButton';
import { useDispatch } from 'react-redux';
import { apiCall } from './redux/apiCall';
import { Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';


const modalInitialState = {
  show: false,
  success: false,
  bodyText: null,
};
const AllBlocksUnblocks = () => {
  const dispatch = useDispatch();
  const onResponse = useRef(() => { });
  const [blockUnblockList, setBlockUnblockList] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedTxn, setSelectedTxn] = useState(null);
  const [modalValues, setModalValues] = useState(modalInitialState);
  const [fetch, setFetch] = useState(false);
  const [spinner, setSpinner] = useState(true);
  const [snackbar, setSnackbar] = useState({});


  useEffect(() => {
    dispatch(
      apiCall({
        url: ApiUrls.blockUnblock(),
        callback: res => onResponse.current(res),
      }),
    );
  }, [dispatch, fetch]);

  onResponse.current = res => {
    setSpinner(false);
    // console.log('------------------Blocks Unblocks-----------', res);
    if (res && res.data && res.data.data) {
      setBlockUnblockList(res.data.data);
    }
    else if (res && res.data && !res.data.success) {
      setSnackbar({
        showSnackbar: true,
        persist: true,
        alertMessage: res.data.message ? res.data.message : 'Failure'
      });
    } else {
      setSnackbar({
        showSnackbar: true,
        persist: true,
        alertMessage: 'Check Your Internet Connection'
      });
    }
  };

  const renderItem = (val, key) => {
    // console.log('Particular item is -------', val);
    return (
      <div key={key} className="flex item-row">
        <div className="sm:w-full md:w-1/6 text-center">{val.id}</div>
        <div className="sm:w-full md:w-1/6 text-center">{val.client_code}</div>
        <div className="sm:w-full md:w-4/6 text-center">{val.txn_code}</div>
        <div className="sm:w-full md:w-4/6 text-center">
          {val.start_date_stg}
          <br></br>
          {val.start_time_stg}
        </div>
        <div className="sm:w-full md:w-4/6 text-center">
          {val.end_date_stg}
          <br></br>
          {val.end_time_stg}
        </div>
        <div className="sm:w-full md:w-4/6 text-center">{val.remark}</div>
        <div className="sm:w-full md:w-3/6 text-center">{val.status}</div>
        <div
          className="sm:w-full md:w-3/6 text-center"
          style={{ display: 'flex', justifyContent: 'center' }}
        >
          <KuposButton
            className='bg-red-500'
            onClick={() => {
              setShowDeleteModal(true);
              setSelectedTxn(val);
            }}
          >
            <span>Expirar</span>
          </KuposButton>
        </div>
      </div>
    );
  };

  const renderDeleteModal = () => {
    return (
      <KuposModalWithButtons
        size="md"
        buttonText1="No"
        buttonText2="Si"
        onButtonClick1={() => setShowDeleteModal(false)}
        onButtonClick2={deleteItem}
        showButton1={true}
        showButton2={true}
        showModal={showDeleteModal}
        onHide={() => setShowDeleteModal(false)}
      >
        <div className="create-staff">
          <h4 className="text-center">
            ¿Esta seguro de que desea expirar el registro programado "
            {selectedTxn && selectedTxn.txn_code}"?
          </h4>
        </div>
      </KuposModalWithButtons>
    );
  };

  const deleteItem = () => {
    let data = {
      id: selectedTxn.id,
      client_code: selectedTxn.client_code,
      txn_code: selectedTxn.txn_code,
      status: selectedTxn.status,
      start_date_time: selectedTxn.start_date_time,
      start_date_stg: selectedTxn.start_date_stg,
      start_time_stg: selectedTxn.start_time_stg,
      end_date_time: selectedTxn.end_date_time,
      end_date_stg: selectedTxn.end_date_stg,
      end_time_stg: selectedTxn.end_time_stg,
      remark: selectedTxn.remark,
      unblock_date_time: 0,
      unblock_date_stg: '',
      unblock_time_stg: '',
      created_on: selectedTxn.created_on,
      created_by: selectedTxn.created_by,
      last_updated_on: selectedTxn.last_updated_on,
      last_updated_by: selectedTxn.last_updated_by,
    };

    // console.log('Old data ------', selectedTxn);
    // console.log('New data ------', data);

    dispatch(
      apiCall({
        url: ApiUrls.unblockTxn(),
        method: 'POST',
        data: data,
        callback: res => onDeleteResponse(res),
      }),
    );
  };

  const onDeleteResponse = res => {
    // console.log('Delete response -----------', res);
    if (res.data && res.data.success) {
      setModalValues({ show: true, success: true, bodyText: res.data.message || 'Eliminar éxito' });
      setShowDeleteModal(false);
      setFetch(!fetch);
    } else {
      setModalValues({ show: true, success: false, bodyText: res.data.message });
    }
  };

  return (
    <div className="home-users">
      <hr className="pb-3" />
      <AddButton
        title='Bloqueo/desbloqueo'
        url='/blocks-unblocks/create'
        addTitle='Crear nuevo bloqueo'
      />
      <div className="flex table-heading">
        <div className="sm:w-full md:w-1/6 text-center">id</div>
        <div className="sm:w-full md:w-1/6 text-center">Client</div>
        <div className="sm:w-full md:w-4/6 text-center">Transacción</div>
        <div className="sm:w-full md:w-4/6 text-center">Fecha y hora de inicio</div>
        <div className="sm:w-full md:w-4/6 text-center">Fecha y hora de finalización</div>
        <div className="sm:w-full md:w-4/6 text-center">Razones</div>
        <div className="sm:w-full md:w-3/6 text-center">Estado</div>
        <div className="sm:w-full md:w-3/6 text-center">Comportamiento</div>
      </div>

      {/* show spinner on load */}
      {spinner ? (
        <KuposListLoader />
      ) : (blockUnblockList || []).length > 0 ? (
        blockUnblockList.sort((a, b) => b.id - a.id).map((val, i) => renderItem(val, i))
      ) : null}
      {renderDeleteModal()}

      <KuposErrorSuccessModal
        showModal={modalValues.show}
        success={modalValues.success}
        smallIcon={true}
        bodyText={modalValues.bodyText}
        onButtonClick={() => {
          setModalValues(modalInitialState);
        }}
      />
      <Snackbar
        open={snackbar.showSnackbar}
        autoHideDuration={snackbar.persist ? 10000000000 : 5000}
        anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
        onClose={() => setSnackbar({ showSnackbar: false })}
      >
        <Alert onClose={() => setSnackbar({ showSnackbar: false })} severity="error">
          <span>{snackbar.alertMessage}</span>
        </Alert>
      </Snackbar>
    </div>
  );
}



export default AllBlocksUnblocks;
