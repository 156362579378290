import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { KuposCheckbox, SvgHome } from '../common';
import CommonService from '../../services/commonService';
import DateService from '../../services/dateService';

export default class PassengerDetailsBlock extends Component {
  static propTypes = {
    ticketDetails: PropTypes.object,
    selectedSeats: PropTypes.object,
    onSeatCheck: PropTypes.func,
    selectAll: PropTypes.func,
    allSelected: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.state = {
      tncChecked: false,
    };
  }
  componentDidMount() {
    // console.log('Props are-----', this.props);
  }

  showCheckAll = () => {
    if (this.props.ticketDetails) {
    } else {
      return true;
    }
    return false;
  };

  renderPassengerBlock = () => {
    const ticketDetails = this.props.ticketDetails;
    console.log('Ticket details in renderPassengerBlock', ticketDetails);
    return (
      <div className="passenger-seat-rows-block car">
        <div className="at-right-card-sections bold-text font10">
          <span>
            {/* <KuposCheckbox 
              onChange={() => this.props.selectAll()}
              checked={this.props.allSelected}
            />  */}
            Número de asientos
          </span>
          <span>Pasajero</span>
          {/* <span>{'CANCEL_TICKET.RUT_PASSPORT_DNI')}</span> */}
          <span style={{ display: 'flex', justifyContent: 'flex-end' }}>Precio</span>
        </div>
        <div>
          <div className="at-right-card-sections font10">
            <span>
              {/* <KuposCheckbox
                onChange={() => this.props.onExclusiveCheck()}
                checked={this.props.exclusiveChecked}
              /> */}

              {ticketDetails.no_of_seats}
            </span>
            <span>{ticketDetails.customer_name}</span>
            <span style={{ display: 'flex', justifyContent: 'flex-end' }}>
              {ticketDetails.booking_amount
                ? CommonService.currency(ticketDetails.booking_amount.toFixed(0))
                : null}
            </span>
          </div>
        </div>
      </div>
    );
  };

  renderPassengerSeats = () => {
    const ticketDetails = this.props.ticketDetails;
    console.log('Ticket details in renderPassengerSeats', ticketDetails);
    return (
      <div className="passenger-seat-rows-block">
        <div className="at-right-card-sections bold-text font10">
          <span>
            <KuposCheckbox
              onChange={() => this.props.selectAll()}
              checked={this.props.allSelected}
            />
          </span>
          <span>{'CANCEL_TICKET.SEAT_NUMBER_SMALL'}</span>
          <span style={{ justifyContent: 'start' }}>{'CANCEL_TICKET.PASSENGER'}</span>
          {/* <span>{'CANCEL_TICKET.RUT_PASSPORT_DNI')}</span> */}
          <span style={{ display: 'flex', justifyContent: 'flex-end' }}>
            {'CANCEL_TICKET.PRICE'}
          </span>
        </div>

        {ticketDetails.cancelled_seats_count
          ? Array.range(1, ticketDetails.cancelled_seats_count).map((val, key) =>
              this.renderPassengerSeatRow(val, key, true),
            )
          : null}
        {ticketDetails.no_of_seats - ticketDetails.cancelled_seats_count > 0
          ? Array.range(
              1,
              ticketDetails.no_of_seats - ticketDetails.cancelled_seats_count,
            ).map((val, key) => this.renderPassengerSeatRow(val, key))
          : null}
      </div>
    );
  };

  renderPassengerSeatRow = (val, key, disabled) => {
    console.log('Passenger key', key);
    return (
      <div>
        <div className="at-right-card-sections font10" key={key}>
          <span>
            {!disabled ? (
              <KuposCheckbox
                onChange={() => this.props.onSeatCheck(key)}
                checked={this.props.selectedSeats[key]}
              />
            ) : null}
          </span>
          <span>{val + (!disabled ? this.props.ticketDetails.cancelled_seats_count : 0)}</span>
          <span style={{ justifyContent: 'start' }}>
            {'Pasajero #' +
              (val + (!disabled ? this.props.ticketDetails.cancelled_seats_count : 0))}
          </span>
          {/* <span>{seat.id_card_number}</span> */}
          <span style={{ display: 'flex', justifyContent: 'flex-end' }}>
            {'$'}
            {(this.props.ticketDetails.total_amount / this.props.ticketDetails.no_of_seats).toFixed(
              0,
            )}
          </span>
        </div>
      </div>
    );
  };

  render() {
    const ticketDetails = this.props.ticketDetails;
    // const { ticketDetails } = this.props;
    console.log('Props in passenger details screen are  ', this.props);
    return ticketDetails ? (
      <div className="cancellation-passenger-details-block">
        <div className="passenger-details flex-row">
          <div className="cancel-bank-total-section">
            <div className="kupos-card bank-deposit-left-card font10">
              <div className="bank-deposit-heading font13">
                Datos
                <span className="bold-text">&nbsp;de la reserva</span>
              </div>
              <div className=" flex-row pnr-number">
                <span className="bold-text">N° de reserva</span>
                <div className="kupos-button-secondary">{ticketDetails.pnr_id}</div>
              </div>

              <div className="city-name-box">
                <div className="city-name">
                  {/* <img src="/images/icons/home/icon_source2.png" alt="" />{' '} */}
                  {/* <SvgHome name="pin" type="origin" /> */}
                  <img
                    alt="Choose your origin city"
                    class="icon-size icon-left "
                    src="/images/icons/home/icon_source.png"
                  />
                  <span className="bold-text  font11">{ticketDetails.pickup_address}</span>
                </div>

                <div className="city-name">
                  <SvgHome name="pin" type="destination" />
                  <span className="bold-text  font11">{ticketDetails.drop_address}</span>
                </div>
              </div>

              <div className="datetime">
                <span className="bold-text ">Fecha de viaje:&nbsp; </span>
                {ticketDetails.trip_ref_date
                  ? DateService.changeDateFormat(
                      ticketDetails.trip_ref_date,
                      'yyyy-mm-dd',
                      'dd/mm/yyyy',
                    )
                  : ''}
              </div>

              <div className="datetime">
                <span className="bold-text "> Hora de vuelo:&nbsp;</span>
                {DateService.ampm(ticketDetails.trip_ref_time)}
              </div>
              <div className="datetime">
                <span className="bold-text "> Tipo de servicio:&nbsp;</span>
                {ticketDetails.service_type}
              </div>
            </div>

            <div className="kupos-button-secondary total-amount bold-text flex-row font12">
              <span>Tarifa total</span>{' '}
              <span className="text-right">
                CLP {CommonService.currency(+ticketDetails.booking_amount)}
              </span>
            </div>
          </div>

          <div className="kupos-card at-passenger-seat-details center-text">
            <div style={{ marginLeft: 15 }} className="bank-deposit-heading font13">
              ¿Cuáles asientos
              <span className="bold-text"> quieres cancelar?</span>
            </div>
            {/* {ticketDetails.category == 'seat' */}

            {/* for now as we are doing full cancellation senario only */}
            {this.renderPassengerBlock()}

            {/* {ticketDetails.service_type == 'Pav Exclusivo'
              ? this.renderPassengerBlock()
              : this.renderPassengerSeats()} */}
            {/* <div className="accept-terms flex-row font10">
              <KuposCheckbox onChange={this.props.onTncChange} checked={this.props.tncChecked} />{' '}
              <span>Acepto términos, condiciones y políticas de cancelación</span>
            </div> */}
          </div>
        </div>
      </div>
    ) : null;
  }
}
