import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import ApiUrls from '../../services/apiUrls';
import CommonService from '../../services/commonService';
import { DropdownComponent, KuposButton, KuposDatePicker, KuposErrorSuccessModal, KuposInput } from '../common';
import { apiCall } from './redux/apiCall';
import DateService from '../../services/dateService';
import { Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import Loader from 'react-loader-spinner';

const employmentTypes = [
  { value: 'INDEPENDENT', label: 'Independiente' },
  { value: 'CONTRACTOR', label: 'Contrato' },
];
const statusTypes = [
  { value: 'ACTIVE', label: 'Activo' },
  { value: 'INACTIVE', label: 'Inactivo' },
];
const genderTypes = [
  { value: 'MALE', label: 'Masculino' },
  { value: 'FEMALE', label: 'Mujer' },
];
const initialState = {
  expiryDate: DateService.getTodayString(),
};

const AddDriverPage = ({ match, history }) => {
  const dispatch = useDispatch();
  const [values, setValues] = useState(initialState);
  const [modal, setModal] = useState({});
  const [errors, setErrors] = useState({});
  const [employmentType, setEmploymentType] = useState('');
  const [genderType, setGenderType] = useState('');
  const [statusType, setStatusType] = useState('');
  const onResponse = useRef(() => { });
  const [snackbar, setSnackbar] = useState({});
  const [count, setCount] = useState('')
  const [loader, setLoader] = useState(false)


  const id = match.params ? parseInt(match.params.id) : null;

  console.log({ values, errors, id, employmentType, statusType, genderType });

  useEffect(() => {
    if (id) {
      setLoader(true)
      dispatch(
        apiCall({
          url: ApiUrls.getDrivers(),
          callback: res => onResponse.current(res),
        }),
      );
    }
  }, [dispatch, id]);

  // Counter
  useEffect(() => {
    let interval
    if (count) {
      interval = setInterval(() => {
        setCount((currentCount) => --currentCount)
      }, 1000);
    }
    // Redirect once the count is === 0
    count === 0 && history.push('/drivers');
    // CleanUp
    return () => clearInterval(interval)
  }, [count, history])

  onResponse.current = res => {
    console.log('Result from all drivers api', res);
    setLoader(false)

    if (res.data && res.data.success && res.data.data) {
      const found = res.data.data.find(d => d.id === id);
      console.log({ found });
      if (found) {
        setValues({
          ...found,
          expiryDate: DateService.changeDateFormat(
            found.license_expiration_date,
            'yyyy-mm-dd',
            'dd-mm-yyyy',
          ),
        });

        const employ =
          found.employment_type === 'INDEPENDENT' ? employmentTypes[0] : employmentTypes[1];
        setEmploymentType(employ);

        const status = found.status === 'ACTIVE' ? statusTypes[0] : statusTypes[1];
        setStatusType(status);

        const gender = found.gender === 'MALE' ? genderTypes[0] : genderTypes[1];
        setGenderType(gender);
      } else {
        setSnackbar({
          showSnackbar: true,
          persist: true,
          alertMessage: 'Record not found'
        });
        setCount(5)
      }
    } else if (res && res.data && !res.data.success) {
      setSnackbar({
        showSnackbar: true,
        persist: true,
        alertMessage: res.data.message ? res.data.message : 'Failure'
      });
    } else {
      setSnackbar({
        showSnackbar: true,
        persist: true,
        alertMessage: 'Check Your Internet Connection'
      });
    }
  };

  const closeSnackbar = () => {
    setSnackbar({ showSnackbar: false });
  };

  const handleChange = (val, type) => {
    if (type === 'rut') {
      setValues({ ...values, [type]: CommonService.formatRut(val) });
      setErrors({ ...errors, [type + 'Error']: '' });
    } else if (type !== 'rut') {
      setValues({ ...values, [type]: val });
      setErrors({ ...errors, [type + 'Error']: '' });
    }
  };
  const onBlur = (val, inp) => { };

  const validate = () => {
    setErrors({});
    if (!values.first_name) {
      setErrors({ first_nameError: 'Ingrese el nombre del servicio' });
      return false;
    } else if (!values.last_name) {
      setErrors({ last_nameError: 'Ingrese Servicios de servicio' });
      return false;
    } else if (!values.primary_email) {
      setErrors({ primary_emailError: 'Ingrese el número de asientos' });
      return false;
    } else if (!employmentType) {
      setErrors({ employment_typeError: 'Introduzca la fecha de inicio' });
      return false;
    } else if (!values.rut) {
      setErrors({ rutError: 'Ingrese la fecha de finalización' });
      return false;
    } else if(values.rut.length < 12){
      setErrors({ rutError: 'RUT can not be less than 9 digits' });
      return false;
    } else if (!values.license_number) {
      setErrors({ license_numberError: 'Por favor ingrese comentario de servicio' });
      return false;
    } else if (!values.expiryDate) {
      setErrors({ expiryDateError: 'Por favor ingrese comentario de servicio' });
      return false;
    } else if (!genderType) {
      setErrors({ genderError: 'Por favor ingrese comentario de servicio' });
      return false;
    } else if (!values.home_address) {
      setErrors({ home_addressError: 'Por favor ingrese comentario de servicio' });
      return false;
    } else if (!statusType) {
      setErrors({ statusError: 'Por favor ingrese comentario de servicio' });
      return false;
    } else if (!values.commission_a) {
      setErrors({ commission_aError: 'Por favor ingrese comentario de servicio' });
      return false;
    } else if (!values.commission_b) {
      setErrors({ commission_bError: 'Por favor ingrese comentario de servicio' });
      return false;
    }
    return true;
  };

  const createOrUpdate = () => {
    if (!validate()) {
      return;
    }

    let method = 'POST';
    let data = {};

    if (id) {
      method = 'PUT';
      data = {
        id: values.id,
        client_code: values.client_code,
        first_name: values.first_name,
        last_name: values.last_name,
        status: statusType.value,
        primary_email: values.primary_email,
        last_updated_on: values.last_updated_on,
        last_updated_by: values.last_updated_by,
        home_address: values.home_address,
        commission_a: Number(values.commission_a),
        commission_b: Number(values.commission_b),
      };
    } else {
      data = values;
      data.employment_type = employmentType.value;
      data.license_expiration_date = DateService.changeDateFormat(
        values.expiryDate,
        'dd-mm-yyyy',
        'yyyy-mm-dd',
      );
      data.gender = genderType.value;
      data.status = statusType.value;
      data.license_expiration_date_unix = CommonService.getTimeInSeconds(values.expiryDate);
      data.commission_a = Number(values.commission_a);
      data.commission_b = Number(values.commission_b);
    }

    console.log({ data });
    setLoader(true)

    dispatch(
      apiCall({
        url: ApiUrls.addDriver(),
        method,
        data,
        callback: res => onUpdateResponse(res),
      }),
    );
  };

  const onUpdateResponse = res => {
    setLoader(false)

    if (res && res.data && res.data.success && res.data.http_code == 201) {
      setModal({ show: true, success: true, bodyText: 'Éxito', redirect: true });
    } else if (res && res.data && !res.data.success) {
      setSnackbar({
        showSnackbar: true,
        persist: true,
        alertMessage: res.data.message ? res.data.message : 'Failure'
      });
    } else {
      setSnackbar({
        showSnackbar: true,
        persist: true,
        alertMessage: 'Check Your Internet Connection'
      });
    }
  };

  return (
    <div className="home-add-driver-page pr-3 pl-3">
      <div className="common-kupos-input-box" style={{ marginTop: 10 }}>
        <KuposInput
          type="text"
          className="kupos-border"
          placeholder={'Nombre'}
          label={'Nombre'}
          disabled={id ? true : false}
          value={values.first_name}
          error={errors.first_nameError ? true : false}
          errorMessage={errors.first_nameError ? errors.first_nameError : ''}
          onChange={text => handleChange(text, 'first_name')}
          onBlur={text => onBlur(text, 'first_name')}
        />
      </div>
      <div className="common-kupos-input-box" style={{ marginTop: 10 }}>
        <KuposInput
          type="text"
          className="kupos-border"
          placeholder={'Apellido'}
          label={'Apellido'}
          disabled={id ? true : false}
          value={values.last_name}
          error={errors.last_nameError ? true : false}
          errorMessage={errors.last_nameError ? errors.last_nameError : ''}
          onChange={text => handleChange(text, 'last_name')}
          onBlur={text => onBlur(text, 'last_name')}
        />
      </div>
      <div className="common-kupos-input-box" style={{ marginTop: 10 }}>
        <KuposInput
          type="email"
          className="kupos-border"
          placeholder={'Correo electronico'}
          label={'Correo electronico'}
          disabled={id ? true : false}
          value={values.primary_email}
          error={errors.primary_emailError ? true : false}
          errorMessage={errors.primary_emailError ? errors.primary_emailError : ''}
          onChange={text => handleChange(text, 'primary_email')}
          onBlur={text => onBlur(text, 'primary_email')}
        />
      </div>
      <div style={{ marginTop: 10 }}>
        <div>
          <span className="input-label">Tipo de empleado</span>
        </div>
        <DropdownComponent
          onChange={val => {
            setEmploymentType(val);
            setErrors({ employment_typeError: '' });
          }}
          placeholder={'Ubicación'}
          options={employmentTypes}
          error={errors.employment_typeError ? true : false}
          errorMessage={errors.employment_typeError ? errors.employment_typeError : ''}
          value={employmentType}
        />
      </div>
      <div className="home-selection-input-box datebox" style={{ marginTop: 10 }}>
        <div>
          <span className="input-label">Fecha de expedicion</span>
        </div>
        <KuposDatePicker
          minDate={DateService.getTodayString()}
          selectedDate={values.expiryDate}
          onDateChange={date => handleChange(date, 'expiryDate')}
          placeholder={'Expiry Date'}
        />
      </div>
      <div className="common-kupos-input-box" style={{ marginTop: 10 }}>
        <KuposInput
          type="text"
          className="kupos-border"
          placeholder={'RUT'}
          label={'RUT'}
          disabled={id ? true : false}
          value={values.rut}
          error={errors.rutError ? true : false}
          errorMessage={errors.rutError ? errors.rutError : ''}
          onChange={text => handleChange(text, 'rut')}
          onBlur={text => onBlur(text, 'rut')}
          max={12}
        />
      </div>
      <div className="common-kupos-input-box" style={{ marginTop: 10 }}>
        <KuposInput
          type="text"
          className="kupos-border"
          placeholder={'No licencia conducir'}
          label={'No licencia conducir'}
          disabled={id ? true : false}
          value={values.license_number}
          error={errors.license_numberError ? true : false}
          errorMessage={errors.license_numberError ? errors.license_numberError : ''}
          onChange={text => handleChange(text, 'license_number')}
          onBlur={text => onBlur(text, 'license_number')}
        />
      </div>
      <div style={{ marginTop: 10 }}>
        <div>
          <span className="input-label">Sexo</span>
        </div>
        <DropdownComponent
          onChange={val => {
            setGenderType(val);
            setErrors({ genderError: '' });
          }}
          placeholder={'Ubicación'}
          options={genderTypes}
          error={errors.genderError ? true : false}
          errorMessage={errors.genderError ? errors.genderError : ''}
          value={genderType}
        />
      </div>
      <div className="common-kupos-input-box" style={{ marginTop: 10 }}>
        <KuposInput
          type="text"
          className="kupos-border"
          placeholder={'Dirección'}
          label={'Dirección'}
          value={values.home_address}
          error={errors.home_addressError ? true : false}
          errorMessage={errors.home_addressError ? errors.home_addressError : ''}
          onChange={text => handleChange(text, 'home_address')}
          onBlur={text => onBlur(text, 'home_address')}
        />
      </div>
      <div style={{ marginTop: 20 }}>
        <div>
          <span className="input-label">Estatus</span>
        </div>
        <DropdownComponent
          onChange={val => {
            setStatusType(val);
            setErrors({ statusError: '' });
          }}
          placeholder={'Ubicación'}
          options={statusTypes}
          error={errors.statusError ? true : false}
          errorMessage={errors.statusError ? errors.statusError : ''}
          value={statusType}
        />
      </div>
      <div className="common-kupos-input-box" style={{ marginTop: 20 }}>
        <KuposInput
          type="number"
          className="kupos-border"
          placeholder={'Comisión A'}
          label={'Comisión A'}
          value={values.commission_a}
          error={errors.commission_aError ? true : false}
          errorMessage={errors.commission_aError ? errors.commission_aError : ''}
          onChange={text => handleChange(text, 'commission_a')}
          onBlur={text => onBlur(text, 'commission_a')}
        />
      </div>
      <div className="common-kupos-input-box" style={{ marginTop: 10 }}>
        <KuposInput
          type="number"
          className="kupos-border"
          placeholder={'Comisión B'}
          label={'Comisión B'}
          value={values.commission_b}
          error={errors.commission_bError ? true : false}
          errorMessage={errors.commission_bError ? errors.commission_bError : ''}
          onChange={text => handleChange(text, 'commission_b')}
          onBlur={text => onBlur(text, 'commission_b')}
        />
      </div>
      <div className="button-container" style={{ marginTop: 20, marginBottom: 40 }}>
        <KuposButton onClick={createOrUpdate}>
          <span className="font11">{id ? 'Conductor de actualización' : 'Crear Conductor'}</span>
        </KuposButton>
      </div>
      <KuposErrorSuccessModal
        success={modal.success}
        showModal={modal.show}
        onButtonClick={() => {
          setModal({});
          if (modal.redirect) {
            history.goBack();
          }
        }}
        bodyText={modal.bodyText}
      />

      <Snackbar
        open={snackbar.showSnackbar}
        autoHideDuration={snackbar.persist ? 10000000000 : 5000}
        anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
        onClose={closeSnackbar}
      >
        <Alert onClose={closeSnackbar} severity="error">
          {snackbar.alertMessage} we are redirecting you back in {count} seconds

        </Alert>
      </Snackbar>

      {loader ?
        <div className="loader-container">
          <Loader visible={loader} type="Bars" color="#637290" height={80} width={80} />
        </div> : null
      }
    </div>
  );
};

export default AddDriverPage;
