import React from 'react'
import { useHistory } from 'react-router-dom'
import KuposButton from './KuposButton'

const ActionButtons = ({ view, url, handleDelete, imageUrl, val }) => {
    const history = useHistory()
    return <div className="sm:w-full md:w-3/6 flex-center">
        {view &&
            <KuposButton
                className={'bg-blue-500'}
                onClick={() => view(val)}>
                <span>Ver</span>
            </KuposButton>
        }

        {url &&
            <KuposButton
                className={'bg-yellow-500'}
                onClick={() => history.push(url)}>
                <span>Editar</span>
            </KuposButton>
        }

        {handleDelete &&
            <KuposButton
                className={'bg-red-500'}
                onClick={handleDelete}
            >
                <span>Eliminar</span>
            </KuposButton>
        }

        {imageUrl &&
            <KuposButton
                className={'bg-orange-500'}
                onClick={() => history.push(imageUrl)}>
                <span>Images</span>
            </KuposButton>
        }
    </div>
}

export default ActionButtons