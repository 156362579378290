import React, { useState, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { KuposErrorSuccessModal, KuposListLoader, KuposModalWithButtons } from '../common';
import ApiUrls from '../../services/apiUrls';
import { apiCall } from './redux/apiCall';
import ActionButtons from '../common/ActionButtons';
import AddButton from '../common/AddButton';
import { Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';

const modalSuccessFailureInitialState = {
  showSuccessFailureModal: false,
  showSuccessFailureModalStatus: false,
  showSuccessFailureModalBodyText: null,
};

const Countries = () => {
  const dispatch = useDispatch();
  const onResponse = useRef(() => { });
  const [modalValues, setModalValues] = useState(modalSuccessFailureInitialState);
  const [countriesList, setCountriesList] = useState([]);
  const [selectedViewItem, setSelectedViewItem] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedItemName, setSelectedItemName] = useState(null);
  const [selectedItemId, setSelectedItemId] = useState(null);
  const [fetch, setFetch] = useState(false);
  const [spinner, setSpinner] = useState(true);
  const [snackbar, setSnackbar] = useState({});


  useEffect(() => {
    dispatch(
      apiCall({
        url: ApiUrls.addCountry(),
        callback: res => onResponse.current(res),
      }),
    );
  }, [dispatch, fetch]);

  onResponse.current = res => {
    setSpinner(false);
    if (res && res.data && res.data.data) {
      setCountriesList(res.data.data);
    } else if (res && res.data && !res.data.success) {
      setSnackbar({
        showSnackbar: true,
        persist: true,
        alertMessage: res.data.message ? res.data.message : 'Failure'
      });
    } else {
      setSnackbar({
        showSnackbar: true,
        persist: true,
        alertMessage: 'Check Your Internet Connection'
      });
    }

  };

  const renderItem = (val, i) => {
    console.log('value is ', val);
    return (
      <div key={i} className="flex item-row">
        <div className="sm:w-full md:w-1/6 text-center">{val.id}</div>
        <div className="sm:w-full md:w-3/6 ">{val.name}</div>
        <div className="sm:w-full md:w-3/6 ">{val.std_code}</div>
        <div className="sm:w-full md:w-3/6 ">{val.currency}</div>
        <div className="sm:w-full md:w-3/6 ">{val.status}</div>
        <div className="sm:w-full md:w-full ">{val.remark}</div>
        <ActionButtons
          val={val}
          view={setSelectedViewItem}
          url={('/countries/' + val.id + '/edit')}
          handleDelete={() =>
            setOnClickDataForDelete(val)


          }
        />

      </div>
    );
  };

  const setOnClickDataForDelete = val => {
    setShowDeleteModal(true);
    setSelectedItemName(val.name);
    setSelectedItemId(val.id);
  };

  const renderViewModal = () => {
    return (
      <KuposModalWithButtons
        size="lg"
        // buttonText1="O"
        // onButtonClick1={this.addSkillPoint}
        showButton1={true}
        showModal={selectedViewItem}
        onHide={() => setSelectedViewItem(null)}
      >
        {selectedViewItem && selectedViewItem.id ? (
          <div className="view-item-container">
            <h3 className="text-center font-bold">País</h3>
            <div className="flex view-item-row">
              <div className="sm:w-full md:w-2/6 label font-bold">Nombre:</div>
              <div className="sm:w-full md:w-full">{selectedViewItem.name}</div>
            </div>
            <div className="flex view-item-row">
              <div className="sm:w-full md:w-2/6 label font-bold">Moneda:</div>
              <div className="sm:w-full md:w-full">{selectedViewItem.currency}</div>
            </div>
            <div className="flex view-item-row">
              <div className="sm:w-full md:w-2/6 label font-bold">Prefijo telefónico:</div>
              <div className="sm:w-full md:w-full">{selectedViewItem.std_code}</div>
            </div>
            <div className="flex view-item-row">
              <div className="sm:w-full md:w-2/6 label font-bold">Estatus:</div>
              <div className="sm:w-full md:w-full">{selectedViewItem.status}</div>
            </div>
          </div>
        ) : null}
      </KuposModalWithButtons>
    );
  };

  const renderDeleteModal = () => {
    return (
      <KuposModalWithButtons
        size="md"
        buttonText1="No"
        buttonText2="Yes"
        onButtonClick1={() => setShowDeleteModal(false)}
        onButtonClick2={deleteItem}
        showButton1={true}
        showButton2={true}
        showModal={showDeleteModal}
        onHide={() => setShowDeleteModal(false)}
      >
        <div className="create-staff">
          <h4 className="text-center">¿Esta seguro de que desea eliminar {selectedItemName}"?</h4>
        </div>
      </KuposModalWithButtons>
    );
  };


  const deleteItem = () => {
    dispatch(
      apiCall({
        url: ApiUrls.addCountry(selectedItemId),
        method: 'DELETE',
        callback: res => onDeleteResponse(res),
      }),
    );
  };

  const onDeleteResponse = res => {
    if (res.data && res.data.success) {
      setSelectedItemName(null);
      setSelectedItemId(null);
      setShowDeleteModal(false);
      setModalValues({
        showSuccessFailureModal: true,
        showSuccessFailureModalStatus: true,
        showSuccessFailureModalBodyText: 'Delete Success',
      })
      setFetch(!fetch)
      // getCountiesList();
    } else {

      setModalValues({
        showSuccessFailureModal: true,
        showSuccessFailureModalStatus: false,
        showSuccessFailureModalBodyText: res.data.message ? res.data.message : 'Error in Deleting',
      })
      setSelectedItemName(null);
      setSelectedItemId(null);
    }
  };

  console.log('Countries list is', countriesList);
  return (
    <div className="home-all-locations">
      <AddButton
        title='Países'
        url='/countries/create'
        addTitle='Añadir país'
      />
      <div className="flex table-heading">
        <div className="sm:w-full md:w-1/6 text-center">ID</div>
        <div className="sm:w-full md:w-3/6">País</div>
        <div className="sm:w-full md:w-3/6">Prefijo telefónico</div>
        <div className="sm:w-full md:w-3/6">Moneda</div>
        <div className="sm:w-full md:w-3/6">Estatus</div>
        <div className="sm:w-full md:w-full">Comentarios</div>
        <div className="sm:w-full md:w-3/6">Acciones</div>
      </div>

      {spinner ? <KuposListLoader /> : (countriesList || []).length > 0
        ? countriesList
          .sort((x, y) => {
            return y.id - x.id;
          })
          .map((val, i) => renderItem(val, i))
        : null}

      {renderViewModal()}

      {renderDeleteModal()}

      <KuposErrorSuccessModal
        showModal={modalValues.showSuccessFailureModal}
        success={modalValues.showSuccessFailureModalStatus}
        smallIcon={true}
        bodyText={modalValues.showSuccessFailureModalBodyText}
        onButtonClick={() => {
          setModalValues({
            showSuccessFailureModal: false,
            showSuccessFailureModalBodyText: null,
          })
        }}
      />
      <Snackbar
        open={snackbar.showSnackbar}
        autoHideDuration={snackbar.persist ? 10000000000 : 5000}
        anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
        onClose={() => setSnackbar({ showSnackbar: false })}
      >
        <Alert onClose={() => setSnackbar({ showSnackbar: false })} severity="error">
          <span>{snackbar.alertMessage}</span>
        </Alert>
      </Snackbar>
    </div>
  );
};

export default Countries;
