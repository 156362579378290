import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import ApiUrls from '../../services/apiUrls';
import { DropdownComponent, KuposButton, KuposErrorSuccessModal, KuposInput } from '../common';
import { apiCall } from './redux/apiCall';

const types = [
  { value: 'EXPENSE', label: 'Expense' },
  { value: 'INCOME', label: 'Income' },
];
const initialState = {
  name: '',
  description: '',
};

const modalInitialState = {
  show: false,
  success: false,
  bodyText: '',
};

const errorInitialState = {
  nameError: '',
  descriptionError: '',
  typeError: '',
};

const AddIncomeExpense = ({ history, match }) => {
  const dispatch = useDispatch();
  const [values, setValues] = useState(initialState);
  const [modal, setModal] = useState(modalInitialState);
  const [errors, setErrors] = useState(errorInitialState);
  const [txnType, setTxnType] = useState('');

  const onResponse = useRef(() => { });

  const id = match.params ? parseInt(match.params.id) : null;

  // console.log({ match, history, values, id, errors });

  const handleOnChange = (val, type) => {
    setValues({ ...values, [type]: val });
  };

  useEffect(() => {
    if (id) {
      dispatch(
        apiCall({ url: ApiUrls.allIncomeExpense(), callback: res => onResponse.current(res) }),
      );
    }
  }, [dispatch, id]);

  onResponse.current = res => {
    // console.log({ res });
    if (res.data && res.data.success) {
      if (id) {
        getParticular(id, res.data.data);
      }
    }
  };

  const getParticular = (id, res) => {
    // console.log('Particular dat', { id, res });

    const income = res.income_item_list.find(el => el.id === id);
    const expense = res.expense_item_list.find(el => el.id === id);

    // console.log({ income, expense });
    if (income) {
      setValues({
        type: income.type,
        name: income.name,
        description: income.description,
        newData: income,
      });
      setTxnType({ value: income.type, label: income.type });
    } else {
      setValues({
        type: expense.type,
        name: expense.name,
        description: expense.description,
        newData: expense,
      });
      setTxnType({ value: expense.type, label: expense.type });
    }
  };

  const onBlur = () => { };

  const validate = () => {
    let errors = 0;
    setErrors(errorInitialState);

    if (!txnType) {
      setErrors({ ...errors, typeError: 'Seleccione opciones' });
      errors++;
      return;
    }
    if (!values.name) {
      setErrors({ ...errors, nameError: 'Ingrese un nombre' });
      errors++;
      return;
    }
    if (!values.description) {
      setErrors({ ...errors, descriptionError: 'descripción' });
      errors++;
      return;
    }

    if (errors > 0) return false;
    else return true;
  };

  const createOrUpdate = () => {
    if (!validate()) {
      return;
    }

    let method = 'POST';
    let data = values;

    if (id) {
      method = 'PUT';
      data = values.newData;
      data.description = values.description;
    }

    // console.log('Updated  data is', data, { method });

    dispatch(
      apiCall({
        method,
        url: ApiUrls.addIncomeExpense(),
        data,
        callback: res => onUpdateResponse(res),
      }),
    );
  };

  const onUpdateResponse = res => {
    // console.log('res from add income expense is --------', res);
    if (res && res.data && res.data.success) {
      setModal({ ...modal, show: true, success: true, bodyText: res.data.message });
    } else {
      setModal({ ...modal, show: true, success: false, bodyText: res.data.message });
    }
  };

  return (
    <div className="home-add-location">
      <div className="home-selection-input-box font10 select-box">
        <DropdownComponent
          onChange={val => {
            setTxnType(val);
            setValues({ ...values, type: val.value });
          }}
          placeholder={'Seleccione tipo'}
          disabled={match.params.id ? true : false}
          options={types}
          value={txnType}
          error={errors.typeError ? true : false}
          errorMessage={errors.typeError ? errors.typeError : null}
        />
      </div>
      <div className="common-kupos-input-box" style={{ marginTop: 10 }}>
        <KuposInput
          type="text"
          className="kupos-border"
          placeholder={'Nombre'}
          disabled={match.params.id ? true : false}
          value={values.name}
          error={errors.nameError ? true : false}
          errorMessage={errors.nameError ? errors.nameError : ''}
          onChange={text => handleOnChange(text, 'name')}
          onBlur={text => onBlur(text, 'name')}
        />
      </div>

      <div className="common-kupos-input-box" style={{ marginTop: 10 }}>
        <KuposInput
          type="text"
          className="kupos-border"
          placeholder={'Descripción'}
          value={values.description}
          error={errors.descriptionError ? true : false}
          errorMessage={errors.descriptionError ? errors.descriptionError : ''}
          onChange={text => handleOnChange(text, 'description')}
          onBlur={text => onBlur(text, 'description')}
        />
      </div>

      <div className="button-container" style={{ marginTop: 20 }}>
        <KuposButton onClick={createOrUpdate}>
          <span className="font11">
            {match.params.id ? 'Actualizar tipo de transacción' : 'Crear tipo de transacción'}
          </span>
        </KuposButton>
      </div>

      <KuposErrorSuccessModal
        showModal={modal.show}
        success={modal.success}
        onButtonClick={() => {
          setModal({ ...modal, show: false });
          if (modal.success) history.goBack();
        }}
        bodyText={modal.bodyText}
      />
    </div>
  );
};

export default AddIncomeExpense;
