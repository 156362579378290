import {
  AddLocationArea,
  AllServiceAreas,
  DefaultPage,
  DetailsPage,
  HomePage,
  AddService,
  LoginPage,
  Operations,
  TransactionsPage,
  WelcomePage,
  AdminHome,
  AllLocationAreas,
  UserPage,
  AddUserPage,
  AllLocations,
  AddLocation,
  AddVehicle,
  AllVehicles,
  Reports,
  ReportsNew,
  DriverPage,
  AddDriverPage,
  CvehiclePage,
  AddCvehiclePage,
  CounterCashInit,
  CounterSelection,
  Countries,
  AddCity,
  AddCountry,
  AddState,
  AllCities,
  AllStates,
  AddBranch,
  AllBranches,
  AddCounter,
  AllCounters,
  AddPos,
  AllPos,
  AddIncomeExpense,
  AllIncomeExpense,
  AddIncomeExpensesTransaction,
  IncomeExpensesTransactionList,
  ChooseShiftAndCounter,
  CashInHand,
  CounterDashboard,
  AllShifts,
  CancellationDetailsPage,
  ShiftSummaryReport,
  AllExchangeRates,
  AddExchangeRate,
  AllBlocksUnblocks,
  AddBlockUnblock,
  ServiceImages,
  AllOldService,
  AddOldService,
  AllPlatformLocations,
  AddPlatformLocation,
} from './';
import AddCancellationPolicy from './AddCancellationPolicy';
import AddClientScreen from './AddClientScreen';
import AllCancellationPolicies from './AllCancellationPolicies';
import ClientsScreen from './ClientsScreen/ClientsScreen';
import EditClientScreen from './ClientsScreen/EditClientScreen';
import SalesScreen from './Sales/SalesScreen';
import DetailsScreen from './Details/DetailsScreen';

export default {
  path: '',
  childRoutes: [
    // { path: 'sales-page', component: DefaultPage, auth: true },
    { path: 'sales-page', component: SalesScreen, auth: true },
    { path: '', component: HomePage, isIndex: true, auth: true },
    { path: 'welcome-page', component: WelcomePage, auth: true },
    { path: 'details-page', component: DetailsScreen, auth: true },
    { path: 'login', component: LoginPage },
    { path: 'counter-selection', component: CounterSelection, auth: true },
    { path: 'counter-cash', component: CounterCashInit, auth: true },
    { path: 'transactions-page', component: TransactionsPage, auth: true },
    { path: 'operations', component: Operations, auth: true },
    { path: 'admin-home', component: AdminHome, auth: true },
    { path: 'location-areas', component: AllLocationAreas, auth: true },
    { path: 'locations', component: AllLocations, auth: true },
    { path: 'vehicles', component: AllVehicles, auth: true },
    { path: 'platform-location', component: AllPlatformLocations, auth: true },
    { path: 'platform-location/create', component: AddPlatformLocation, auth: true },
    { path: 'platform-location/:id/edit', component: AddPlatformLocation, auth: true },
    { path: 'service-images/:id', component: ServiceImages, auth: true },
    { path: 'locations/create', component: AddLocation, auth: true },
    { path: 'locations/:id/edit', component: AddLocation, auth: true },
    { path: 'vehicles/:id/edit', component: AddVehicle, auth: true },
    { path: 'vehicles/create', component: AddVehicle, auth: true },
    { path: 'location-areas/create', component: AddLocationArea, auth: true },
    { path: 'location-areas/:id/edit', component: AddLocationArea, auth: true },
    { path: 'services', component: AllServiceAreas, auth: true },
    { path: 'services/create', component: AddService, auth: true },
    { path: 'services/:id/edit', component: AddService, auth: true },
    { path: 'old-services', component: AllOldService, auth: true },
    { path: 'old-services/create', component: AddOldService, auth: true },
    { path: 'old-services/:id/edit', component: AddOldService, auth: true },
    { path: 'users', component: UserPage, auth: true },
    { path: 'users/create', component: AddUserPage, auth: true },
    { path: 'users/:id/edit', component: AddUserPage, auth: true },
    { path: 'reports', component: ReportsNew, auth: true },
    { path: 'drivers', component: DriverPage, auth: true },
    { path: 'drivers/create', component: AddDriverPage, auth: true },
    { path: 'drivers/:id/edit', component: AddDriverPage, auth: true },
    { path: 'c-vehicles', component: CvehiclePage, auth: true },
    { path: 'c-vehicles/create', component: AddCvehiclePage, auth: true },
    { path: 'c-vehicles/:id/edit', component: AddCvehiclePage, auth: true },
    { path: 'countries', component: Countries, auth: true },
    { path: 'countries/create', component: AddCountry, auth: true },
    { path: 'countries/:id/edit', component: AddCountry, auth: true },
    { path: 'states', component: AllStates, auth: true },
    { path: 'states/create', component: AddState, auth: true },
    { path: 'states/:id/edit', component: AddState, auth: true },
    { path: 'cities', component: AllCities, auth: true },
    { path: 'cities/create', component: AddCity, auth: true },
    { path: 'cities/:id/edit', component: AddCity, auth: true },
    { path: 'branches', component: AllBranches, auth: true },
    { path: 'branches/create', component: AddBranch, auth: true },
    { path: 'branches/:id/edit', component: AddBranch, auth: true },
    { path: 'counters', component: AllCounters, auth: true },
    { path: 'counters/create', component: AddCounter, auth: true },
    { path: 'counters/:id/edit', component: AddCounter, auth: true },
    { path: 'pos', component: AllPos, auth: true },
    { path: 'pos/create', component: AddPos, auth: true },
    { path: 'pos/:id/edit', component: AddPos, auth: true },
    { path: 'income-expense', component: AllIncomeExpense, auth: true },
    { path: 'income-expense/create', component: AddIncomeExpense, auth: true },
    { path: 'income-expense/:id/edit', component: AddIncomeExpense, auth: true },
    { path: 'income-expense-txn', component: IncomeExpensesTransactionList, auth: true },
    { path: 'income-expense-txn/create', component: AddIncomeExpensesTransaction, auth: true },
    { path: 'choose-counter', component: ChooseShiftAndCounter, auth: true },
    { path: 'cash-in-hand', component: CashInHand, auth: true },
    { path: 'counter-dashboard', component: CounterDashboard, auth: true },
    { path: 'shifts', component: AllShifts, auth: true },
    { path: 'cancellation-details', component: CancellationDetailsPage, auth: true },
    { path: 'shift-summary-report', component: ShiftSummaryReport, auth: true },
    { path: 'exchange-rates', component: AllExchangeRates, auth: true },
    { path: 'exchange-rates/create', component: AddExchangeRate, auth: true },
    { path: 'exchange-rates/:id/edit', component: AddExchangeRate, auth: true },
    { path: 'blocks-unblocks', component: AllBlocksUnblocks, auth: true },
    { path: 'blocks-unblocks/create', component: AddBlockUnblock, auth: true },
    { path: 'clients', component: ClientsScreen, auth: true },
    { path: 'clients/create', component: AddClientScreen, auth: true },
    // { path: 'clients/:id/edit', component: AddClientScreen, auth: true },
    { path: 'clients/:id/edit', component: EditClientScreen, auth: true },
    { path: 'cancellation-policies', component: AllCancellationPolicies, auth: true },
    { path: 'cancellation-policies/create', component: AddCancellationPolicy, auth: true },
    { path: 'cancellation-policies/:id/edit', component: AddCancellationPolicy, auth: true },
  ],
};
