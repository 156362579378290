import { Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Select from 'react-select';
import ApiUrls from '../../services/apiUrls';
import { KuposButton, KuposErrorSuccessModal, KuposInput, DropdownComponent } from '../common';
import { apiCall } from './redux/apiCall';
import Loader from 'react-loader-spinner';

const initialState = {
  email: '',
  password: '',
  first_name: '',
  last_name: '',
};

const modalInitialState = {
  show: false,
  success: false,
  bodyText: '',
};

const errorInitialState = {};

const AddUserScreen = ({ match, history }) => {
  const dispatch = useDispatch();
  const [values, setValues] = useState(initialState);
  const [modal, setModal] = useState(modalInitialState);
  const [errors, setErrors] = useState(errorInitialState);
  const [country, setCountry] = useState('');
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [state, setState] = useState('');
  const [cities, setCities] = useState([]);
  const [city, setCity] = useState('');
  const [branches, setBranches] = useState([]);
  const [branch, setBranch] = useState('');
  const [roles, setRoles] = useState([]);
  const [loader, setLoader] = useState(false);
  const [role, setRole] = useState('');
  const [snackbar, setSnackbar] = useState({});
  const [count, setCount] = useState('')



  // redirecting back if data not found using timer
  useEffect(() => {
    let interval
    if (count) {
      interval = setInterval(() => {
        setCount((currentCount) => --currentCount)
      }, 1000);
    }
    // Redirect once the count is === 1
    count === 1 && history.push('/users');
    // CleanUp
    return () => clearInterval(interval)
  }, [count, history])


  const id = match.params ? parseInt(match.params.id) : null;

  // console.log({ role, values, country, state, city, branch });
  useEffect(() => {
    getCountries();
    getRoles();

    id && setLoader(true)
    // eslint-disable-next-line
  }, [id]);

  const handleChange = (val, type) => {
    if (type === 'country') {
      setCountry(val);
      setState(null);
      setCity(null);
      setBranch(null);
      getStates(val.value);
      setErrors({ ...errors, [type + 'Error']: '' });
      setLoader(true)
    }

    if (type === 'state') {
      setState(val);
      setCity(null);
      setBranch(null);
      getCities(val.value);
      setErrors({ ...errors, [type + 'Error']: '' });
      setLoader(true)

    }

    if (type === 'city') {
      setCity(val);
      setBranch(null);
      getBranches(val.value);
      setErrors({ ...errors, [type + 'Error']: '' });
      setLoader(true)

    }

    if (type === 'branch') {
      setBranch(val);
      setErrors({ ...errors, [type + 'Error']: '' });
    }

    if (type === 'role') {
      setRole(val);
      setErrors({ ...errors, [type + 'Error']: '' });
    } else {
      setValues({ ...values, [type]: val });
      setErrors({ ...errors, [type + 'Error']: '' });
    }
  };
  const onBlur = (val, inp) => {

  };

  // get countries
  const getCountries = () => {
    dispatch(
      apiCall({
        url: ApiUrls.addCountry(),
        callback: res => countriesResponse(res),
      }),
    );
  };

  const countriesResponse = res => {
    // setLoader(false)
    if (res && res.data && res.data.success) {
      setCountries(
        res.data.data.map(country => {
          return { value: country.id, label: country.name };
        }),
      );
    } else if (res && res.data && !res.data.success) {
      setSnackbar({
        showSnackbar: true,
        persist: true,
        alertMessage: res.data.message ? res.data.message : 'Failure'
      });
    } else {
      setSnackbar({
        showSnackbar: true,
        persist: true,
        alertMessage: 'Check Your Internet Connection'
      });
    }
  };

  const getStates = id => {
    dispatch(
      apiCall({
        url: ApiUrls.addState(id),
        callback: res => statesResponse(res),
      }),
    );
  };
  const statesResponse = res => {
    setLoader(false)
    console.log('object', { res });
    if (res && res.data && res.data.success) {
      setStates(
        res.data.data.map(state => {
          return { value: state.id, label: state.name };
        }),
      );
    } else if (res && res.data && !res.data.success) {
      setSnackbar({
        showSnackbar: true,
        persist: true,
        alertMessage: res.data.message ? res.data.message : 'Failure'
      });
    } else {
      setSnackbar({
        showSnackbar: true,
        persist: true,
        alertMessage: 'Check Your Internet Connection'
      });
    }
  };

  const getCities = id => {
    dispatch(
      apiCall({
        url: ApiUrls.addCity(id),
        callback: res => citiesResponse(res),
      }),
    );
  };
  const citiesResponse = res => {
    setLoader(false)

    console.log('city', { res });
    if (res && res.data && res.data.success) {
      setCities(
        res.data.data.map(city => {
          return { value: city.id, label: city.name };
        }),
      );
    } else if (res && res.data && !res.data.success) {
      setSnackbar({
        showSnackbar: true,
        persist: true,
        alertMessage: res.data.message ? res.data.message : 'Failure'
      });
    } else {
      setSnackbar({
        showSnackbar: true,
        persist: true,
        alertMessage: 'Check Your Internet Connection'
      });
    }
  };

  const getBranches = id => {
    dispatch(
      apiCall({
        url: id ? ApiUrls.addBranch(id) : ApiUrls.addBranch(),
        callback: res => branchesResponse(res),
      }),
    );
  };
  const branchesResponse = res => {
    setLoader(false)
    console.log('branch', { res });
    if (res && res.data && res.data.success) {
      setBranches(
        res.data.data.map(branch => {
          return { value: branch.id, label: branch.name };
        }),
      );
    } else if (res && res.data && !res.data.success) {
      setSnackbar({
        showSnackbar: true,
        persist: true,
        alertMessage: res.data.message ? res.data.message : 'Failure'
      });
    } else {
      setSnackbar({
        showSnackbar: true,
        persist: true,
        alertMessage: 'Check Your Internet Connection'
      });
    }
  };

  // get Roles
  const getRoles = () => {
    dispatch(
      apiCall({
        url: ApiUrls.role(),
        callback: res => rolesResponse(res),
      }),
    );
  };

  const rolesResponse = res => {
    // setLoader(false)
    console.log('roles response ', { res });
    if (res && res.data && res.data.success && res.data.data) {
      const rolesArr = res.data.data.map(role => ({ value: role.id, label: role.role_name }));
      setRoles(rolesArr);
      if (id) {
        getUser(id, rolesArr);
      }
    } else if (res && res.data && !res.data.success) {
      setSnackbar({
        showSnackbar: true,
        persist: true,
        alertMessage: res.data.message ? res.data.message : 'Failure'
      });
    } else {
      setSnackbar({
        showSnackbar: true,
        persist: true,
        alertMessage: 'Check Your Internet Connection'
      });
    }
  };

  const getUser = (id, roles) => {
    dispatch(
      apiCall({
        url: ApiUrls.user(id),
        callback: res => userResponse(res),
      }),
    );
  };

  const userResponse = res => {
    setLoader(false)
    console.log('user Response ', { res });
    if (res && res.data && res.data.success && res.data.data) {
      setValues({
        ...res.data.data,
        password: res.data.data.password.indexOf('****') >= 0 ? '' : res.data.data.password,
      });
      setCountry({ value: res.data.data.country_id, label: res.data.data.country_name });
      setState({ value: res.data.data.state_id, label: res.data.data.state_name });
      setCity({ value: res.data.data.city_id, label: res.data.data.city_name });
      setBranch({ value: res.data.data.branch_id, label: res.data.data.branch_name });
      let oldRoles = [];

      res.data.data.user_roles_list &&
        res.data.data.user_roles_list.map(val => {
          oldRoles.push({ value: val.role_id, label: val.role_name });
        });
      setRole(oldRoles);
    } else if (res && res.data && !res.data.success) {
      // Show Snack BarMsge
      setSnackbar({
        showSnackbar: true,
        persist: true,
        alertMessage: 'Record not found'
      });
      setCount(5)
    } else {
      // if there is no REsponse 
      setSnackbar({
        showSnackbar: true,
        persist: true,
        alertMessage: 'Check Your Internet Connection'
      });
    }
  };

  const validate = () => {
    let error = 0;
    setErrors(initialState);

    if (!values.first_name) {
      setErrors({ ...errors, first_nameError: 'Ingrese nombre' });
      error++;
      return;
    }

    if (!values.last_name) {
      setErrors({ ...errors, last_nameError: 'Ingrese apellido' });
      error++;
      return;
    }

    if (!values.email) {
      setErrors({ emailError: 'Ingrese email del usuario' });
      error++;
      return;
    }

    if (!id && !values.password) {
      setErrors({ passwordError: 'Ingrese clave del usuario' });
      error++;
      return;
    }

    if (!country) {
      setErrors({ countryError: 'Elija el país' });
      error++;
      return;
    }

    if (!state) {
      setErrors({ stateError: 'Elija la región ' });
      error++;
      return;
    }

    if (!city) {
      setErrors({ cityError: 'Elija la ciudad' });
      error++;
      return;
    }

    if (!branch) {
      setErrors({ branchError: 'Elija la sucursal' });
      error++;
      return;
    }

    if (!role) {
      setErrors({ roleError: 'Elija la rols' });
      error++;
      return;
    }

    if (error > 0) {
      return false;
    } else {
      return true;
    }
  };

  const createOrUpdate = () => {
    if (!validate()) {
      return;
    }

    let user_roles =
      role &&
      role.map(r => {
        return {
          role_id: r.value,
        };
      });

    let data = {
      id: id ? +id : undefined,
      email: values.email,
      password: values.password,
      first_name: values.first_name,
      last_name: values.last_name,
      country_id: country.value,
      country_name: country.label,
      state_id: state.value,
      state_name: state.label,
      city_id: city.value,
      city_name: city.label,
      branch_id: branch.value,
      branch_name: branch.label,
      user_roles,
    };
    console.log({ data });

    setLoader(true)

    dispatch(
      apiCall({
        url: ApiUrls.user(),
        method: id ? 'PUT' : 'POST',
        data: data,
        callback: res => onResponse(res),
      }),
    );
  };

  const onResponse = res => {
    setLoader(false)
    if (res.data && res.data.success && res.data.data) {
      setModal({
        show: true,
        success: true,
        redirect: true,
        bodyText: 'Éxito' || (id ? 'Éxito' : 'Éxito'),
      });
    } else if (!res.data.success && res.data.message) {
      setModal({ show: true, success: false, bodyText: res.data.message });
    } else {
      setModal({ show: true, success: false, bodyText: 'Fail' });
    }
  };

  return (
    <div className="home-add-user-page pr-3 pl-3">
      <h2 className="mt-4 mb-4 font-bold">{id ? 'Editar usuario' : 'Crear nuevo usuario'}</h2>
      <div className="create-user-input pb-2 mt-2">
        <KuposInput
          type="text"
          className="kupos-border"
          placeholder={'Primer nombre'}
          label={'Primer nombre'}
          value={values.first_name}
          error={errors.first_nameError ? true : false}
          errorMessage={errors.first_nameError ? errors.first_nameError : ''}
          onChange={val => handleChange(val, 'first_name')}
          onBlur={text => onBlur(text)}
        />
      </div>
      <div className="create-user-input pb-2 mt-2">
        <KuposInput
          type="text"
          className="kupos-border"
          placeholder={'Apellido'}
          label={'Apellido'}
          value={values.last_name}
          error={errors.last_nameError ? true : false}
          errorMessage={errors.last_nameError ? errors.last_nameError : ''}
          onChange={val => handleChange(val, 'last_name')}
          onBlur={text => onBlur(text)}
        />
      </div>
      <div className="create-user-input pb-2 mt-2">
        <KuposInput
          type="email"
          className="kupos-border"
          placeholder={'Correo electrónico'}
          label={'Correo electrónico'}
          disabled={id ? true : false}
          value={values.email}
          error={errors.emailError ? true : false}
          errorMessage={errors.emailError ? errors.emailError.message : ''}
          onChange={val => handleChange(val, 'email')}
          onBlur={text => onBlur(text)}
        />
      </div>

      {/*don't show password on Update  */}
      {id ? null : (
        <div className="create-user-input pb-2 mt-2">
          <KuposInput
            type="password"
            className="kupos-border"
            placeholder={'Contraseña'}
            label={'Contraseña'}
            value={values.password}
            error={errors.passwordError ? true : false}
            errorMessage={errors.passwordError ? errors.passwordError : ''}
            onChange={val => handleChange(val, 'password')}
            onBlur={text => onBlur(text)}
          />
        </div>
      )}

      <div>
        <div style={{ marginTop: 20 }}>
          <span className="input-label">País</span>
        </div>
        <DropdownComponent
          onChange={val => handleChange(val, 'country')}
          placeholder={'País'}
          value={country}
          options={countries}
          error={errors.countryError ? true : false}
          errorMessage={errors.countryError ? errors.countryError : ''}
        />
      </div>
      <div>
        <div style={{ marginTop: 20 }}>
          <span className="input-label">Región</span>
        </div>
        <DropdownComponent
          onChange={val => handleChange(val, 'state')}
          placeholder={'Región'}
          value={state}
          options={states}
          error={errors.stateError ? true : false}
          errorMessage={errors.stateError ? errors.stateError : ''}
        />
      </div>
      <div>
        <div style={{ marginTop: 20 }}>
          <span className="input-label">Ciudad</span>
        </div>
        <DropdownComponent
          onChange={val => handleChange(val, 'city')}
          placeholder={'Ciudad'}
          value={city}
          options={cities}
          error={errors.cityError ? true : false}
          errorMessage={errors.cityError ? errors.cityError : ''}
        />
      </div>

      <div>
        <div style={{ marginTop: 20 }}>
          <span className="input-label">Sucursal</span>
        </div>
        <DropdownComponent
          onChange={val => handleChange(val, 'branch')}
          placeholder={'Sucursal'}
          value={branch}
          options={branches}
          error={errors.branchError ? true : false}
          errorMessage={errors.branchError ? errors.branchError : ''}
        />
      </div>

      <div className="create-user-input pb-2" style={{ marginTop: 20 }}>
        <span className="input-label">Rol</span>
        <Select
          onChange={val => handleChange(val, 'role')}
          placeholder={'Rol'}
          options={roles}
          value={role}
          // isDisabled={this.props.match.params.id ? true : false}
          isMulti={true}
        />
        <span className="text-danger">{errors.roleError && errors.roleError}</span>
      </div>

      <div className="button-container" style={{ marginTop: 20, marginBottom: 20 }}>
        <KuposButton onClick={createOrUpdate}>
          <span className="font11">{id ? 'Actualizar usuario' : 'Crear usuario'}</span>
        </KuposButton>
      </div>

      <KuposErrorSuccessModal
        success={modal.success}
        showModal={modal.show}
        onButtonClick={() => {
          setModal(modalInitialState);
          if (modal.redirect) {
            history.goBack();
          }
        }}
        bodyText={modal.bodyText}
      />

      <Snackbar
        open={snackbar.showSnackbar}
        autoHideDuration={snackbar.persist ? 10000000000 : 5000}
        anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
        onClose={() => setSnackbar({ showSnackbar: false })}
      >
        <Alert onClose={() => setSnackbar({ showSnackbar: false })} severity="error">
          <span>{snackbar.alertMessage}</span>
          {count
            &&
            `we are redirecting you back in ${count} seconds`
          }
        </Alert>
      </Snackbar>
      {loader ?
        <div className="loader-container">
          <Loader visible={loader} type="Bars" color="#637290" height={80} width={80} />
        </div> : null
      }
    </div>
  );
};

export default AddUserScreen;