import { HOME_SET_LOGIN_DATA } from './constants';

export function setLoginData() {
  return {
    type: HOME_SET_LOGIN_DATA,
  };
}

export function reducer(state, action) {
  // console.log("set login data",  state);
  switch (action.type) {
    case HOME_SET_LOGIN_DATA:
      return {
        ...state,
        loginData: state.loginData.data,
      };

    default:
      return state;
  }
}
