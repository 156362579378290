import React from 'react'
import { useHistory } from 'react-router-dom'
import KuposButton from './KuposButton'

const AddButton = ({ title, addTitle, url }) => {
    const history = useHistory()
    return <div className="add-item-container flex align-center justify-between">
        <div className="pl-3 font-bold">{title}</div>
        <KuposButton className="add-button bg-green-500" onClick={() => url && history.push(url)}>
            <span>
                <i className="fas fa-plus"></i>{addTitle}
            </span>
        </KuposButton>
    </div>
}

export default AddButton