import React, { useEffect, useRef, useState } from 'react';
import { ActionButtons, AddButton, KuposErrorSuccessModal, KuposListLoader, KuposModalWithButtons } from '../common';
import ApiUrls from '../../services/apiUrls';
import { apiCall } from './redux/apiCall';
import { useDispatch } from 'react-redux';
import { Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';


const modalInitialState = {
  show: false,
  success: false,
  bodyText: null,
};
const AllIncomeExpense = () => {
  const dispatch = useDispatch();
  const onResponse = useRef(() => { });
  const [spinner, setSpinner] = useState(true);
  const [modal, setModal] = useState(modalInitialState);
  const [incomeExpenseList, setIncomeExpenseList] = useState([]);
  const [selectedItem, setSelectedItem] = useState('');
  const [fetch, setFetch] = useState(false);
  const [snackbar, setSnackbar] = useState({});


  useEffect(() => {
    dispatch(
      apiCall({ url: ApiUrls.allIncomeExpense(), callback: res => onResponse.current(res) }),
    );
  }, [dispatch, fetch]);

  onResponse.current = res => {
    // console.log('------------------Blocks Unblocks-----------', res);
    setSpinner(false);

    if (res && res.data && res.data.success) {
      setIncomeExpenseList(res.data.data);
    } else if (res && res.data && !res.data.success) {
      setSnackbar({
        showSnackbar: true,
        persist: true,
        alertMessage: res.data.message ? res.data.message : 'Failure'
      });
    } else {
      setSnackbar({
        showSnackbar: true,
        persist: true,
        alertMessage: 'Check Your Internet Connection'
      });
    }
  };

  const renderItem = (val, i) => {
    // console.log('value is--------', val);
    return (
      <div key={i} className="flex item-row">
        <div className="sm:w-full md:w-1/6 text-center">{val.id}</div>
        <div className="sm:w-full md:w-3/6 text-center">{val.type}</div>
        <div className="sm:w-full md:w-3/6 text-center">{val.name}</div>
        <div className="sm:w-full md:w-3/6 text-center">{val.status}</div>
        <ActionButtons
          val={val}
          view={setSelectedItem}
          url={('/income-expense/' + val.id + '/edit')}
          handleDelete={() =>
            setModal({
              ...modal,
              showDeleteModal: true,
              selectedItemName: val.name,
              selectedItemId: val.id,
            })
          }
        />
      </div>
    );
  };

  const renderViewModal = () => {
    return (
      <KuposModalWithButtons
        size="lg"
        // buttonText1="O"
        // onButtonClick1={this.addSkillPoint}
        showButton1={true}
        showModal={selectedItem ? true : false}
        onHide={() => setSelectedItem(null)}
      >
        {selectedItem && selectedItem.id ? (
          <div className="view-item-container">
            <h3 className="text-center font-bold">Gastos - Ingresos</h3>
            <div className="flex view-item-row">
              <div className="sm:w-full md:w-2/6 label font-bold">Tipo:</div>
              <div className="sm:w-full md:w-full">{selectedItem.type}</div>
            </div>
            <div className="flex view-item-row">
              <div className="sm:w-full md:w-2/6 label font-bold">Nombre:</div>
              <div className="sm:w-full md:w-full">{selectedItem.name}</div>
            </div>
            <div className="flex view-item-row">
              <div className="sm:w-full md:w-2/6 label font-bold">Descripción:</div>
              <div className="sm:w-full md:w-full">{selectedItem.description}</div>
            </div>
          </div>
        ) : null}
      </KuposModalWithButtons>
    );
  };

  const renderDeleteModal = () => {
    return (
      <KuposModalWithButtons
        size="md"
        buttonText1="No"
        buttonText2="Yes"
        onButtonClick1={() => setModal(modalInitialState)}
        onButtonClick2={deleteItem}
        showButton1={true}
        showButton2={true}
        showModal={modal.showDeleteModal}
        onHide={() => setModal(modalInitialState)}
      >
        <div className="create-staff">
          <h4 className="text-center">
            ¿Esta seguro de que desea eliminar "{modal.selectedItemName}"?
          </h4>
        </div>
      </KuposModalWithButtons>
    );
  };

  const deleteItem = () => {
    dispatch(
      apiCall({
        url: ApiUrls.addIncomeExpense(modal.selectedItemId),
        method: 'DELETE',
        callback: res => onDeleteResponse(res),
      }),
    );
  };

  const onDeleteResponse = res => {
    if (res.data && res.data.success) {
      setModal({ show: true, success: true, bodyText: 'Delete Success' });
      setFetch(!fetch);
    } else {
      setModal({ show: true, success: false, bodyText: res.data.message });
    }
  };
  return (
    <div className="home-all-income-expense">
      <hr className="pb-3" />
      <AddButton
        title='Gastos - Ingresos'
        url='/income-expense/create'
        addTitle='Añadir Gastos - Ingresos'
      />

      <div className="flex table-heading">
        <div className="sm:w-full md:w-1/6 text-center">ID</div>
        <div className="sm:w-full md:w-3/6 text-center">Tipo</div>
        <div className="sm:w-full md:w-3/6 text-center">Nombre</div>
        <div className="sm:w-full md:w-3/6 text-center">Estatus</div>
        <div className="sm:w-full md:w-3/6 text-center">Acciones</div>
      </div>
      {spinner ? (
        <KuposListLoader />
      ) : (
        <>
          {((incomeExpenseList && incomeExpenseList.expense_item_list) || []).length > 0
            ? incomeExpenseList.expense_item_list
              .sort((x, y) => {
                return y.id - x.id;
              })
              .map((val, i) => renderItem(val, i))
            : null}

          {((incomeExpenseList && incomeExpenseList.income_item_list) || []).length > 0
            ? incomeExpenseList.income_item_list
              .sort((x, y) => {
                return y.id - x.id;
              })
              .map((val, i) => renderItem(val, i))
            : null}
        </>
      )}


      {renderViewModal()}

      {renderDeleteModal()}

      <KuposErrorSuccessModal
        showModal={modal.show}
        success={modal.success}
        smallIcon={true}
        bodyText={modal.bodyText}
        onButtonClick={() => {
          setModal(modalInitialState);
        }}
      />
      <Snackbar
        open={snackbar.showSnackbar}
        autoHideDuration={snackbar.persist ? 10000000000 : 5000}
        anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
        onClose={() => setSnackbar({ showSnackbar: false })}
      >
        <Alert onClose={() => setSnackbar({ showSnackbar: false })} severity="error">
          <span>{snackbar.alertMessage}</span>
        </Alert>
      </Snackbar>
    </div>
  );
}



export default AllIncomeExpense;
