import React, { Component } from 'react';
import SvgHome from './SvgHome';
import CommonService from '../../services/commonService';
import DateService from '../../services/dateService';
import PropTypes from 'prop-types';
// import moment from 'moment';
import ReactDOM from 'react-dom';
import KuposInput from './KuposInput';

export default class KuposTimePicker extends Component {
  static propTypes = {
    value: PropTypes.string,
    error: PropTypes.bool,
    errorMessage: PropTypes.string,
    onChange: PropTypes.func,
    placeholder: PropTypes.string,
  };

  constructor(props) {
    super(props);
    this.state = {
      showPicker: this.props.showOnSelect ? true : false ,
    };
  }

  componentDidMount() {
    document.addEventListener('click', this.handleClickOutside, true);
  }

  scrollIfNotInView = () => {
    if (this.el) {
      if (CommonService.isElementInView(this.el)) {
        console.log('In the viewport!');
      } else {
        this.el.scrollIntoView(false);
      }
    }
  };

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClickOutside, true);
  }

  handleClickOutside = event => {
    const domNode = ReactDOM.findDOMNode(this);

    if (!domNode || !domNode.contains(event.target)) {
      // console.log('clicked outside',event)
      this.setState({ showPicker: false });
    }
  };

  onFocus = () => {
    this.setState({ showPicker: true });
    setTimeout(() => {
      this.scrollIfNotInView();
    }, 250);
  };

  onBlur = () => {};

  onChange = (counterType, arrowType) => {
    let time = this.props.value;
    let hours = time ? +time.split(':')[0] : 0;
    let minutes = time ? +time.split(':')[1] : 0;
    if (counterType == 'hours') {
      if (arrowType == 'up') {
        if(this.props.not24Hours){
          hours = hours + 1 >= 119 ? 119 : hours + 1;
        }else{
          hours = hours + 1 >= 23 ? 23 : hours + 1;
        }
        
      } else {
        hours = hours - 1 <= 0 ? 0 : hours - 1;
      }
    } else if (counterType == 'minutes') {
      if (arrowType == 'up') {
        minutes = minutes + 5 >= 55 ? 55 : minutes + 5;
      } else {
        minutes = minutes - 5 <= 0 ? 0 : minutes - 5;
      }
    }
    this.props.onChange(DateService.preZero(hours) + ':' + DateService.preZero(minutes));
  };

  onChangeInput = (val, counterType) => {
    let time = this.props.value;
    let hours = time ? +time.split(':')[0] : '00';
    let minutes = time ? +time.split(':')[1] : '00';
    let lastChar = val.substr(val.length - 1);
    let matches = val.match(/(\d+)/);
    let valNew = matches ? matches[0] : 0;
    if (counterType == 'hours') {
      if(this.props.not24Hours){
        valNew = +valNew >= 119 ? 119 : +valNew;
        hours = +valNew <= 0 ? '00' : +valNew;
      }else{
        valNew = +valNew >= 23 ? 23 : +valNew;
        hours = +valNew <= 0 ? '00' : +valNew;
      }
      
    } else if (counterType == 'minutes') {
      valNew = +valNew >= 55 ? 55 : +valNew;
      minutes = +valNew <= 0 ? '00' : +valNew;
    }
    this.props.onChange(CommonService.preZero(+hours) + ':' + CommonService.preZero(+minutes));
  };
  onBlurInput = (val, counterType) => {
    let time = this.props.value;
    let hours = time ? +time.split(':')[0] : 0;
    let minutes = time ? +time.split(':')[1] : 0;
    this.props.onChange(DateService.preZero(hours) + ':' + DateService.preZero(minutes));
  };

  renderTimePicker = () => {
    return (
      <div
        className="time-picker"
        ref={el => {
          this.el = el;
        }}
      >
        <div className="time-picker-grid">
          <div className="time-counter hours">
            <div className="up arrow" onClick={() => this.onChange('hours', 'up')}>
              <img src="/svgs/up.svg" />
            </div>
            <div className="value">
              {/* {this.props.value ? this.props.value.split(':')[0] : '00'} */}
              <input
                type="text"
                value={this.props.value ? this.props.value.split(':')[0] : '00'}
                onChange={ev => this.onChangeInput(ev.target.value, 'hours')}
                onBlur={ev => this.onBlurInput(ev.target.value, 'hours')}
              />
            </div>
            <div className="down arrow" onClick={() => this.onChange('hours', 'down')}>
              <img src="/svgs/down.svg" />
            </div>
          </div>
          <div className="time-counter minutes">
            <div className="up arrow" onClick={() => this.onChange('minutes', 'up')}>
              <img src="/svgs/up.svg" />
            </div>
            <div className="value">
              {/* {this.props.value ? this.props.value.split(':')[1] : '00'} */}
              <input
                type="text"
                value={this.props.value ? this.props.value.split(':')[1] : '00'}
                onChange={ev => this.onChangeInput(ev.target.value, 'minutes')}
                onBlur={ev => this.onBlurInput(ev.target.value, 'minutes')}
              />
            </div>
            <div className="down arrow" onClick={() => this.onChange('minutes', 'down')}>
              <img src="/svgs/down.svg" />
            </div>
          </div>
          {!this.props.hideAmPm ? 
          <div className="time-counter ampm">
            <div className="up arrow">
              <img src="/images/icons/icon-arrow.png" />
            </div>
            <div className="value">
              {+(this.props.value ? this.props.value.split(':')[0] : '00') >= 12 ? 'PM' : 'AM'}
            </div>
            <div className="down arrow">
              <img src="/images/icons/icon-arrow.png" />
            </div>
          </div>
           : null }
        </div>
      </div>
    );
  };

  render() {
    // const selected = moment('2019-12-27').toDate()
    return (
      <div
        className={
          'common-kupos-time-picker ' +
          (this.props.error ? ' error' : '') +
          (this.props.newStyle ? this.props.newStyle : '')
        }
        // style={this.props.optional ? { backgroundColor: '#ccc' } : {}}
        // style={this.props.width?{width:this.props.width}:{width:"100%"}}
        style={this.props.style}
      >
        <div className="date-inner">
          {/* <img src="/images/icons/home/icon_calendar.png" /> */}
          {!this.props.hideIcon ? <SvgHome name="time" /> : null}
          <input
            type="text"
            className={"time-picker-input " + (this.props.leftPadding ? 'leftPadding' : '')}
            onFocus={this.onFocus}
            onBlur={this.onBlur}
            value={
              this.props.value +
              ' ' +
              (!this.props.hideAmPm && this.props.value ? (+this.props.value.split(':')[0] < 12 ? 'AM' : 'PM') : ' ')
            }
            readOnly
            disabled={this.props.disabled}
            placeholder={this.props.placeholder}
            style={this.props.optional ? { backgroundColor: '#ccc' } : {}}
          />
        </div>
        {this.state.showPicker ? this.renderTimePicker() : null}
        {this.props.error ? <span className="error font9">{this.props.errorMessage}</span> : null}
      </div>
    );
  }
}
