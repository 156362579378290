const initialState = {
  loginData: null,
  loginPending: false,
  loginError: null,
  getBookingsForAgentPending: false,
  getBookingsForAgentError: null,
  bookingsForAgent:null,
  getDriversPending: false,
  getDriversError: null,
  drivers:null,
  getVehiclesPending: false,
  getVehiclesError: null,
  getLocationsPending: false,
  getLocationsError: null,
  addLocationAreaPending: false,
  addLocationAreaError: null,
  getLocationAreasPending: false,
  getLocationAreasError: null,
  getServicesPending: false,
  getServicesError: null,
  addServicePricingPending: false,
  addServicePricingError: null,
  apiCallPending: false,
  apiCallError: null,
  getLocationAreasFromLocationPending: false,
  getLocationAreasFromLocationError: null
};

export default initialState;
