import React, { useState, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { apiCall } from './redux/apiCall';
import { AddButton, KuposErrorSuccessModal, KuposModalWithButtons, KuposListLoader, ActionButtons } from '../common';
import ApiUrls from '../../services/apiUrls';
import { Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';

const AllShifts = ({ history }) => {
  const dispatch = useDispatch();
  const onResponse = useRef(() => { });
  const [fetch, setFetch] = useState(false);
  const [shiftList, setShiftList] = useState([]);
  const [spinner, setSpinner] = useState(true);
  const [modal, setModal] = useState({});
  const [selectedViewItem, setSelectedViewItem] = useState('');
  const [snackbar, setSnackbar] = useState({});


  useEffect(() => {
    dispatch(apiCall({ url: ApiUrls.addShifts(), callback: res => onResponse.current(res) }));
  }, [dispatch, fetch]);

  onResponse.current = res => {
    setSpinner(false);
    if (res && res.data && res.data.success) {
      setShiftList(res.data.data);
    }
    else if (res && res.data && !res.data.success) {
      setSnackbar({
        showSnackbar: true,
        persist: true,
        alertMessage: res.data.message ? res.data.message : 'Failure'
      });
    } else {
      setSnackbar({
        showSnackbar: true,
        persist: true,
        alertMessage: 'Check Your Internet Connection'
      });
    }
  };

  const translatedShifts = shift => {
    // console.log("+++shift description is",shift)
    let translatedShift = '';
    if (shift.includes('Morning')) {
      translatedShift = 'Turno de mañana';
    } else if (shift.includes('Afternoon')) {
      translatedShift = 'Turno de tarde';
    } else if (shift.includes('Evening')) {
      translatedShift = 'Turno de noche';
    } else {
      translatedShift = shift;
    }
    return translatedShift;
  };

  const renderItem = (val, i) => {
    console.log('value is ', val);
    return (
      <div key={i} className="flex item-row">
        <div className="sm:w-full md:w-1/6 text-center">{val.id}</div>
        <div className="sm:w-full md:w-3/6 ">{val.client_code}</div>
        <div className="sm:w-full md:w-3/6 ">{val.name}</div>
        <div className="sm:w-full md:w-3/6 ">{val.status}</div>
        <div className="sm:w-full md:w-full ">{translatedShifts(val.description)}</div>
        <ActionButtons
          val={val}
          view={setSelectedViewItem}
          // url={('/shifts/' + val.id + '/edit')}
          url={('/shifts')}
          handleDelete={() =>
            setModal({
              showDeleteModal: true,
              selectedItemName: val.name,
              selectedItemId: val.id,
            })
          }
        />

      </div>
    );
  };

  const renderViewModal = () => {
    return (
      <KuposModalWithButtons
        size="lg"
        // buttonText1="O"
        // onButtonClick1={this.addSkillPoint}
        showButton1={true}
        showModal={selectedViewItem ? true : false}
        onHide={() => setSelectedViewItem(null)}
      >
        {selectedViewItem && selectedViewItem.id ? (
          <div className="view-item-container">
            <h3 className="text-center font-bold">Turno</h3>
            <div className="flex view-item-row">
              <div className="sm:w-full md:w-2/6 label font-bold">Nombre:</div>
              <div className="sm:w-full md:w-full">{selectedViewItem.name}</div>
            </div>
            <div className="flex view-item-row">
              <div className="sm:w-full md:w-2/6 label font-bold">Client code:</div>
              <div className="sm:w-full md:w-full">{selectedViewItem.client_code}</div>
            </div>
            <div className="flex view-item-row">
              <div className="sm:w-full md:w-2/6 label font-bold">Estatus:</div>
              <div className="sm:w-full md:w-full">{selectedViewItem.status}</div>
            </div>
            <div className="flex view-item-row">
              <div className="sm:w-full md:w-2/6 label font-bold">Comentarios:</div>
              <div className="sm:w-full md:w-full">
                {translatedShifts(selectedViewItem.description)}
              </div>
            </div>
          </div>
        ) : null}
      </KuposModalWithButtons>
    );
  };


  return (
    <div className="home-all-locations">
      <AddButton
        title='Turnos'
        addTitle='Añadir Turno'
      />
      <div className="flex table-heading">
        <div className="sm:w-full md:w-1/6 text-center">ID</div>
        <div className="sm:w-full md:w-3/6">Cliente</div>
        <div className="sm:w-full md:w-3/6">Turno</div>
        <div className="sm:w-full md:w-3/6">Estatus</div>
        <div className="sm:w-full md:w-full">Comentarios</div>
        <div className="sm:w-full md:w-3/6">Acciones</div>
      </div>

      {spinner ? (
        <KuposListLoader />
      ) : (shiftList || []).length > 0 ? (
        shiftList
          .sort((x, y) => {
            return y.id - x.id;
          })
          .map((val, i) => renderItem(val, i))
      ) : null}

      {renderViewModal()}
      {/* {renderDeleteModal()} */}

      <KuposErrorSuccessModal
        showModal={modal.show}
        success={modal.success}
        smallIcon={true}
        bodyText={modal.bodyText}
        onButtonClick={() => {
          setModal({});
        }}
      />
      <Snackbar
        open={snackbar.showSnackbar}
        autoHideDuration={snackbar.persist ? 10000000000 : 5000}
        anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
        onClose={() => setSnackbar({ showSnackbar: false })}
      >
        <Alert onClose={() => setSnackbar({ showSnackbar: false })} severity="error">
          <span>{snackbar.alertMessage}</span>
        </Alert>
      </Snackbar>
    </div>
  );
}
export default AllShifts;

