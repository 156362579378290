import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { AddButton, ActionButtons, KuposErrorSuccessModal, KuposListLoader, KuposModalWithButtons } from '../common';
import ApiUrls from '../../services/apiUrls';
import { apiCall } from './redux/apiCall';
import { Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';

const modalInitialState = {
  show: false,
  success: false,
  bodyText: null,
};

const CvehiclePage = () => {
  const dispatch = useDispatch();
  const onResponse = useRef(() => { });
  const [fetch, setFetch] = useState(false);
  const [itemList, setItemList] = useState([]);
  const [modal, setModal] = useState(modalInitialState);
  const [selectedViewItem, setSelectedViewItem] = useState('');
  const [loader, setLoader] = useState(true);
  const [snackbar, setSnackbar] = useState({});


  useEffect(() => {
    dispatch(apiCall({ url: ApiUrls.getVehicles(), callback: res => onResponse.current(res) }));
  }, [dispatch, fetch]);

  onResponse.current = res => {
    setLoader(false);
    if (res && res.data && res.data.success) {
      setItemList(res.data.data);
    } else if (res && res.data && !res.data.success) {
      setSnackbar({
        showSnackbar: true,
        persist: true,
        alertMessage: res.data.message ? res.data.message : 'Failure'
      });
    } else {
      setSnackbar({
        showSnackbar: true,
        persist: true,
        alertMessage: 'Check Your Internet Connection'
      });
    }
  };


  const renderItem = (val, i) => {
    return (
      <div key={i} className="flex item-row">
        <div className="sm:w-full md:w-1/6 text-center">{val.id}</div>
        <div className="sm:w-full md:w-3/6 text-center capitalize-text">{val.reference_name}</div>
        <div className="sm:w-full md:w-3/6 text-center capitalize-text">{val.brand}</div>
        <div className="sm:w-full md:w-2/6 text-center capitalize-text">{val.patent}</div>
        <div className="sm:w-full md:w-2/6 text-center capitalize-text">{val.owner}</div>
        <div className="sm:w-full md:w-2/6 text-center capitalize-text">{val.status.toLocaleLowerCase()}</div>
        <ActionButtons
          val={val}
          view={setSelectedViewItem}
          url={('/c-vehicles/' + val.id + '/edit')}
          handleDelete={() =>
            setModal({
              showDeleteModal: true,
              selectedItemName: val.reference_name,
              selectedItemId: val.id,
            })
          }
        />


      </div>
    );
  }

  const renderDeleteModal = () => {
    return (

      <KuposModalWithButtons
        size="md"
        buttonText1="No"
        buttonText2="Si"
        onButtonClick1={() => setModal(modalInitialState)}
        onButtonClick2={deleteItem}
        showButton1={true}
        showButton2={true}
        showModal={modal.showDeleteModal}
        onHide={() => setModal(modalInitialState)}
      >
        <div className="create-staff">
          <h4 className="text-center">¿Quieres eliminar "{modal.selectedItemName}"?</h4>
        </div>
      </KuposModalWithButtons>
    )
  }

  const deleteItem = () => {
    dispatch(
      apiCall({
        url: ApiUrls.getVehicles(modal.selectedItemId),
        method: 'DELETE',
        callback: res => onDeleteResponse(res),
      }),
    );
  }

  const onDeleteResponse = res => {
    if (res.data && res.data.success) {
      setModal({ show: true, success: true, bodyText: 'Eliminar éxito' });
      setFetch(!fetch);
    } else {
      setModal({ show: true, success: false, bodyText: res.data.message });
    }
  };


  const renderViewModal = () => {
    return (
      <KuposModalWithButtons
        size="lg"
        // buttonText1="O"
        // onButtonClick1={this.addSkillPoint}
        showButton1={true}
        showModal={selectedViewItem ? true : false}
        onHide={() => setSelectedViewItem(null)}
      >
        {selectedViewItem ? <div className="view-item-container">
          <h3 className="text-center font-bold" style={{ marginBottom: 20 }}>Vehículo</h3>
          <div className="flex view-item-row">
            <div className="sm:w-full md:w-3/6 label font-bold">Nombre:</div>
            <div className="sm:w-full md:w-full capitalize-text">{selectedViewItem.reference_name}</div>
          </div>
          <div className="flex view-item-row">
            <div className="sm:w-full md:w-3/6 label font-bold">Marca de Vehículo:</div>
            <div className="sm:w-full md:w-full capitalize-text">{selectedViewItem.brand}</div>
          </div>
          <div className="flex view-item-row">
            <div className="sm:w-full md:w-3/6 label font-bold">Patente:</div>
            <div className="sm:w-full md:w-full capitalize-text">{selectedViewItem.patent}</div>
          </div>
          <div className="flex view-item-row">
            <div className="sm:w-full md:w-3/6 label font-bold">Propietario:</div>
            <div className="sm:w-full md:w-full capitalize-text">{selectedViewItem.owner}</div>
          </div>
          <div className="flex view-item-row">
            <div className="sm:w-full md:w-3/6 label font-bold">Numero de asientos:</div>
            <div className="sm:w-full md:w-full">{selectedViewItem.no_of_seats}</div>
          </div>
          <div className="flex view-item-row">
            <div className="sm:w-full md:w-3/6 label font-bold">Fecha de ingreso:</div>
            <div className="sm:w-full md:w-full">{selectedViewItem.vehicle_entry_datetime_str}</div>
          </div>
          <div className="flex view-item-row">
            <div className="sm:w-full md:w-3/6 label font-bold">Estatus:</div>
            <div className="sm:w-full md:w-full capitalize-text">{selectedViewItem.status.toLocaleLowerCase()}</div>
          </div>
        </div> : null}
      </KuposModalWithButtons>
    )
  }

  return (
    <div className="home-cvehicle-page">
      <hr className="pb-3" />
      <AddButton
        title='Vehículos'
        url='/c-vehicles/create'
        addTitle='Añadir Vehículo'
      />

      <div className="flex table-heading">
        <div className="sm:w-full md:w-1/6 text-center">id</div>
        <div className="sm:w-full md:w-3/6 text-center">Nombre</div>
        <div className="sm:w-full md:w-3/6 text-center">Marca de vehículo</div>
        <div className="sm:w-full md:w-2/6 text-center">Patente</div>
        <div className="sm:w-full md:w-2/6 text-center">Propietario</div>
        <div className="sm:w-full md:w-2/6 text-center">Estatus</div>
        <div className="sm:w-full md:w-3/6 text-center">Acciones</div>
      </div>
      {loader ? <KuposListLoader /> :
        (itemList || []).length > 0
          ? itemList.sort((x, y) => {
            return y.id - x.id;
          }).map((val, i) => renderItem(val, i))
          : null}

      {renderViewModal()}
      {renderDeleteModal()}

      <KuposErrorSuccessModal
        showModal={modal.show}
        success={modal.success}
        smallIcon={true}
        bodyText={modal.bodyText}
        onButtonClick={() => {
          setModal(modalInitialState);
        }}
      />
      <Snackbar
        open={snackbar.showSnackbar}
        autoHideDuration={snackbar.persist ? 10000000000 : 5000}
        anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
        onClose={() => setSnackbar({ showSnackbar: false })}
      >
        <Alert onClose={() => setSnackbar({ showSnackbar: false })} severity="error">
          <span>{snackbar.alertMessage}</span>
        </Alert>
      </Snackbar>
    </div>
  );
}


export default CvehiclePage;
