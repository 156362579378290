import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';

export default class KuposSuccessFailure extends Component {
  static propTypes = {
    success:PropTypes.bool,
    title:PropTypes.string,
    subtitle:PropTypes.string,
    infoText:PropTypes.string,
    icon:PropTypes.string,
    t: PropTypes.func,
  };

  render() {
    return (
      <div className="common-kupos-success-failure">
          
          <div className="content-container cancel-done-section">

            <div className="cancel-ticket-done">
              {this.props.icon ? <img src={this.props.icon} alt="" /> :
                <img src={this.props.success ? "/images/icons/circular-icons/icon-success.png" : "/images/icons/circular-icons/icon-failure.png"} alt="" />}
            </div>

            <div className="mt-y-3 cancel-ticket-done">
              <span className="bold-text font20">{this.props.title ? this.props.title : (this.props.success ? '¡Listo!' : '¡Lo sentimos!') }</span>
            </div>

            <div className="mt-y-3 cancel-ticket-done cancel-ticket-done-desc font12">
              <span style={{textAlign:"center"}}>{this.props.subtitle}</span>
            </div>

          {
            this.props.infoText 
            ? <div className="cancel-success-info-container"> 
                <div className="mt-y-3 cancel-ticket-done cancel-success-info-div font12">
                  <span className="primary-triangle"></span>
                  <span>{this.props.infoText} </span>
                </div> 
              </div>
            : null
          }
            
          </div>

          {this.props.children ? <div className="content-container success-failure-content">
            {this.props.children}   
          </div> : null}

          <div className="content-container download-our-app-section-container">
            <div className="download-our-app-section">
              <img className="banner-back" src="/images/download-success-page-banner-2.png" />
              <div className="download-app-overlay-text-items">
                  <span className="download-app-overlay-text-heading bold-text font30">Download our app!</span>

                  <span className="download-app-overlay-text-details font12">Create your account and pay your tickets with</span>
                  <div className="download-app-kupos-pay-image">
                      <img src="/images/icons/home/kupos_pay.png" alt="" />
                  </div>
                  <div className="download-app-kupos-pay-image download-app-kupos-pay-store">
                      <a href="https://play.google.com/store/apps/details?id=com.bitla.mba.pasajebus" target="_blank">
                        <img src="/images/icons/home/google-play.png" alt={this.props.t('TRANSLATIONS.GPLAY')} />
                      </a>
                      <a href="https://itunes.apple.com/us/app/pasaje-bus/id973424151?ls=1&amp;mt=8" target="_blank">
                        <img src="/images/icons/home/app-store.png" alt={this.props.t('TRANSLATIONS.APPSTORE')} />
                      </a>
                      
                  </div>
              </div>
              
            </div>
          </div>
      </div>
    );
  }
}
