import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import ApiUrls from '../../services/apiUrls';
import { ActionButtons, KuposErrorSuccessModal, AddButton, KuposListLoader, KuposModalWithButtons } from '../common';
import { withScriptjs, withGoogleMap, GoogleMap, Polygon } from 'react-google-maps';
import AppData from '../../services/appData';
import DateService from '../../services/dateService';
import { apiCall } from './redux/apiCall';
import { Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';

const modalInitialState = {
  show: false,
  success: false,
  bodyText: null,
};

const AllLocationAreas = () => {
  const dispatch = useDispatch();
  const getLocationListResponse = useRef(() => { });
  const getListResponse = useRef(() => { });
  const polygonRef = useRef();
  const [fetch, setFetch] = useState(false);
  const [list, setList] = useState([]);
  const [locations, setLocations] = useState([]);
  const [modal, setModal] = useState(modalInitialState);
  const [selectedViewItem, setSelectedViewItem] = useState('');
  const [loader, setLoader] = useState(true);
  const [snackbar, setSnackbar] = useState({});


  useEffect(() => {
    dispatch(apiCall({ url: ApiUrls.getLocationList(), callback: res => getLocationListResponse.current(res) }))
    // eslint-disable-next-line
  }, []);


  useEffect(() => {
    dispatch(apiCall({ url: ApiUrls.getLocationAreas(), callback: res => getListResponse.current(res) }))
    // eslint-disable-next-line
  }, [fetch]);


  getLocationListResponse.current = (res) => {
    if (res && res.data && res.data.success) {
      let locations = [];
      res.data.data.map(location => {
        locations.push({ value: location.id, label: location.name });
      });
      setLocations(locations);
    } else if (res && res.data && !res.data.success) {
      setSnackbar({
        showSnackbar: true,
        persist: true,
        alertMessage: res.data.message ? res.data.message : 'Failure'
      });
    } else {
      setSnackbar({
        showSnackbar: true,
        persist: true,
        alertMessage: 'Check Your Internet Connection'
      });
    }
  };

  getListResponse.current = (res) => {
    console.log('Result from all location areas is', res);
    setLoader(false)
    if (res && res.data && res.data.success) {
      setList(res.data.data)
    } else if (res && res.data && !res.data.success) {
      setSnackbar({
        showSnackbar: true,
        persist: true,
        alertMessage: res.data.message ? res.data.message : 'Failure'
      });
    } else {
      setSnackbar({
        showSnackbar: true,
        persist: true,
        alertMessage: 'Check Your Internet Connection'
      });
    }
  }

  const getParticularLocationName = location_id => {
    let locationName;
    locations &&
      locations.map(val => {
        if (val.value === location_id) {
          locationName = val.label;
        }
      });

    return <span>{locationName}</span>;
  };

  const renderItem = (val, i) => {
    // console.log(val)
    return (
      <div key={i} className="flex item-row">
        <div className="sm:w-full md:w-1/6 text-center">{val.id}</div>
        <div className="sm:w-full md:w-3/6 text-center">{val.client_code}</div>
        <div className="sm:w-full md:w-full text-center">{val.area_name}</div>
        <div className="sm:w-full md:w-full text-center">
          {getParticularLocationName(val.location_id)}
        </div>
        <div className="sm:w-full md:w-full text-center">{val.remark}</div>
        <ActionButtons
          val={val}
          view={setSelectedViewItem}
          url={('/location-areas/' + val.id + '/edit')}
          handleDelete={() =>
            setModal({
              showDeleteModal: true,
              selectedItemName: val.area_name,
              selectedItemId: val.id,
              itemToDelete: val,
            })
          }
        />

      </div>
    );
  };

  const renderDeleteModal = () => {
    return (
      <KuposModalWithButtons
        size="md"
        buttonText1="No"
        buttonText2="Si"
        onButtonClick1={() => setModal(modalInitialState)}
        onButtonClick2={deleteItem}
        showButton1={true}
        showButton2={true}
        showModal={modal.showDeleteModal}
        onHide={() => setModal(modalInitialState)}
      >
        <div className="create-staff">
          <h4 className="text-center">¿Quieres eliminar "{modal.selectedItemName}"?</h4>
        </div>
      </KuposModalWithButtons>
    );
  };

  const deleteItem = () => {
    let data = {
      id: modal.itemToDelete.id,
      location_id: modal.itemToDelete.location_id,
      client_code: modal.itemToDelete.client_code,
      area_name: modal.itemToDelete.area_name,
      polygon: modal.itemToDelete.polygon,
      remark: modal.itemToDelete.remark,
      status: 'ACTIVE',
      created_by: modal.itemToDelete.created_by,
      created_on_utc: modal.itemToDelete.created_on_utc,
      created_date_time_stg: modal.itemToDelete.created_date_time_stg,
      last_updated_by: modal.itemToDelete.last_updated_by,
      last_updated_on_utc: modal.itemToDelete.last_updated_on_utc,
      last_updated_date_time_stg: modal.itemToDelete.last_updated_date_time_stg,
      deleted_by: ' ',
      deleted_on_utc: DateService.getCurrentTimeUTC(),
      deleted_date_time_stg: DateService.getCurrentStgDate(),
    };


    dispatch(
      apiCall({
        url: ApiUrls.getLocationAreas(),
        method: 'DELETE',
        data,
        callback: res => onDeleteResponse(res),
      }),
    );

  };

  const onDeleteResponse = res => {
    if (res.data && res.data.success) {
      setModal({ show: true, success: true, bodyText: 'Eliminar éxito' });
      setFetch(!fetch);
    } else {
      setModal({ show: true, success: false, bodyText: res.data.message });
    }
  };

  const renderViewModal = () => {
    return (
      <KuposModalWithButtons
        size="lg"
        // buttonText1="O"
        // onButtonClick1={this.addSkillPoint}
        showButton1={true}
        showModal={selectedViewItem ? true : false}
        onHide={() => setSelectedViewItem(null)}
      >
        {selectedViewItem ? (
          <div className="view-item-container">
            <h3 className="text-center font-bold">Área de ubicacion</h3>
            <div className="flex view-item-row">
              <div className="sm:w-full md:w-2/6 label font-bold">Nombre:</div>
              <div className="sm:w-full md:w-full">{selectedViewItem.area_name}</div>
            </div>
            <div className="flex view-item-row">
              <div className="sm:w-full md:w-2/6 label font-bold">Estatus:</div>
              <div className="sm:w-full md:w-full">{selectedViewItem.status}</div>
            </div>
            <div className="service-pricing-map-section">
              <MapWithAMarker
                key={selectedViewItem.id}
                googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${AppData.MAP_API_KEY}&v=3.exp&libraries=geometry,drawing,places`}
                loadingElement={<div style={{ height: `100%` }} />}
                containerElement={<div style={{ height: `450px` }} />}
                mapElement={<div style={{ height: `100%`, borderRadius: 8 }} />}
                //  onMapClick={this.onMapClicked}
                //  onMarkerDrag={this.onMarkerDrag}
                //  onPolygonMouseOut={this.onPolygonMouseOut}
                selectedLatLong={{ lat: -33.4436021, lng: -70.6606066 }}
              >
                <Polygon
                  ref={polygonRef}

                  //  onDragEnd={() => console.log(this.polygonRef.getPaths())}
                  //  onMouseUp={() => this.onPolygonMouseOut(this.polygonRef)}
                  //  editable={true}
                  path={JSON.parse(
                    selectedViewItem.polygon,
                  ).features[0].geometry.coordinates[0].map(val => ({ lat: val[0], lng: val[1] }))}
                />
              </MapWithAMarker>
            </div>
          </div>
        ) : null}
      </KuposModalWithButtons>
    );
  };

  return (
    <div className="home-all-location-areas">
      <hr className="pb-3" />
      <AddButton
        title='Áreas de ubicación'
        url='/location-areas/create'
        addTitle='Agregar área de ubicación'
      />

      <div className="flex table-heading">
        <div className="sm:w-full md:w-1/6 text-center">ID</div>
        <div className="sm:w-full md:w-3/6 text-center">Operador</div>
        <div className="sm:w-full md:w-full text-center">Área de ubicación</div>
        <div className="sm:w-full md:w-full text-center">Locación </div>
        <div className="sm:w-full md:w-full text-center">Observaciones</div>
        <div className="sm:w-full md:w-3/6 text-center">Acción</div>
      </div>
      {loader ? <KuposListLoader /> : (list || []).length > 0
        ? list
          .sort((x, y) => {
            return y.id - x.id;
          })
          .map((val, i) => renderItem(val, i))
        : null}

      {renderViewModal()}
      {renderDeleteModal()}

      <KuposErrorSuccessModal
        showModal={modal.show}
        success={modal.success}
        smallIcon={true}
        bodyText={modal.bodyText}
        onButtonClick={() => {
          setModal(modalInitialState);
        }}
      />
      <Snackbar
        open={snackbar.showSnackbar}
        autoHideDuration={snackbar.persist ? 10000000000 : 5000}
        anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
        onClose={() => setSnackbar({ showSnackbar: false })}
      >
        <Alert onClose={() => setSnackbar({ showSnackbar: false })} severity="error">
          <span>{snackbar.alertMessage}</span>
        </Alert>
      </Snackbar>
    </div>
  );
}


const MapWithAMarker = withScriptjs(
  withGoogleMap(props => (
    <GoogleMap
      defaultZoom={12}
      defaultCenter={props.selectedLatLong}
      defaultOptions={{
        scrollwheel: false,
      }}
      // defaultCenter={{ lat: -33.4436021, lng: -70.6606066 }}
      onClick={props.onMapClick}
    >
      {props.children}
    </GoogleMap>
  )),
);


export default AllLocationAreas;
