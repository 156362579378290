import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actions from './redux/actions';
import Select from 'react-select';

export class DropdownComponent extends Component {
  static propTypes = {
    common: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired,
  };

  render() {
    return (
      <div className="common-dropdown-component">
        <div>
          {this.props.label ? (
            <span className="input-label" style={{marginTop:10}}>{this.props.label}</span>
          ) : null}
        </div>
        <Select
          onChange={val => this.props.onChange(val)}
          placeholder={this.props.placeholder}
          options={this.props.options}
          value={this.props.value}
          isDisabled={this.props.disabled}
          isMulti={this.props.multiple || false}
          styles={{ height: 44 }}
        />
        <div style={{ marginBottom: 10 }}>
          {this.props.error ? (
            <span className="error-message">{this.props.errorMessage}</span>
          ) : null}
        </div>
      </div>
    );
  }
}

/* istanbul ignore next */
function mapStateToProps(state) {
  return {
    common: state.common,
  };
}

/* istanbul ignore next */
function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...actions }, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(DropdownComponent);
