import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actions from './redux/actions';
import SvgHome from './SvgHome';
import KuposModal from './KuposModal';
import KuposButton from './KuposButton';

export class DeleteCard extends Component {
  static propTypes = {
    common: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired,
    t:PropTypes.func,
    userCards: PropTypes.object.isRequired,
    selectedItemForDeletion: PropTypes.number.isRequired,
  };

  constructor(props){
    super(props);
    this.state = {
      name: null,
      cardNumber: null
    };
  }

  renderName = (val, key) => {
    return <span>{this.props.selectedItemForDeletion === key ? val.name : null}</span>;
    
  }
  renderCardNumber = (val, key) => {
    return <span>{this.props.selectedItemForDeletion === key ? val.cardNumber : null}</span>;
    
  }
  render() {

    return (
      <div className="common-delete-bip-card">
        <KuposModal
          showModal={this.props.showModal}
          onHide={this.props.onHide}
          size="md"
          ariaLabel="login-modal"
        >
          <div className="login-signup-block delete-bip-card">
            <div className="close-button pointer" onClick={this.props.onHide}>
              <SvgHome name="close" />
            </div>

            <div>
              <span className="bold-text font12 bip-pop-up-title">
                {this.props.t('PROFILE.REMOVE_CARD').split('\n')[0]}
                <br />
                {this.props.t('PROFILE.REMOVE_CARD').split('\n')[1]}
              </span>
              <div className="bip-popup-sub-body">
                <div>
                  <div className="display-flex font10 ">
                    <span className="bold-text">{this.props.t('PROFILE.NAME')}: </span>
                    &nbsp;
                    {this.props.userCards
                      ? this.props.userCards.map((val, key) => this.renderName(val, key))
                      : null}
                  </div>

                  <div className="display-flex font10">
                    <span className="bold-text">{this.props.t('PROFILE.CARD_NUMBER')}: </span>
                    &nbsp;
                    {this.props.userCards
                      ? this.props.userCards.map((val, key) => this.renderCardNumber(val, key))
                      : null}
                  </div>
                </div>
              </div>

              <div className="bip-popup-button-container">
                <KuposButton
                  // onClick={this.onBookButtonPress}
                  className="bip-popup-button1 font12"
                >
                  {this.props.t('PROFILE.REMOVE_BIP_BUTTON')}
                </KuposButton>

                <KuposButton onClick={this.props.onHide} className="bip-popup-button2 font12">
                  {this.props.t('PROFILE.KEEP_BIP_BUTTON')}
                </KuposButton>
              </div>
            </div>
          </div>
        </KuposModal>
      </div>
    );
  }
}

/* istanbul ignore next */
function mapStateToProps(state) {
  return {
    common: state.common,
  };
}

/* istanbul ignore next */
function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...actions }, dispatch)
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DeleteCard);
