import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class KuposSteps extends Component {
  static propTypes = {
    menuButtons:PropTypes.array,
    t:PropTypes.func,
    onStepClick:PropTypes.func
  };

  render() {
    
    // const {t} = this.props
    // console.log(t)
    return (
      <div className="common-kupos-steps kupos-steps">
      {
        this.props.menuButtons ? this.props.menuButtons.map((value,key) => {
           return <a className={"kupos-step-item font10 "+(value.active ? 'active' : '')} key={key} onClick={() => this.props.onStepClick ? this.props.onStepClick(key+1) : null}>
              {/* <span className="step-number">{key+1}</span> */}
              <span className={"step-label"}>{value.label}</span>
            </a>
          }) : null
      }
      </div>
    );
  }
}
