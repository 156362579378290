import React, { useEffect, useState } from 'react';
import { useRef } from 'react';
import { useDispatch } from 'react-redux';
import ApiUrls from '../../../services/apiUrls';
import { apiCall } from '../redux/apiCall';
import { KuposButton, KuposModalWithButtons } from '../../common';
import { DropdownComponent } from '../../common/DropdownComponent';

const ClientsScreen = ({ history }) => {
  const dispatch = useDispatch();
  const onResponse = useRef(() => {});
  const [clients, setClients] = useState([]);
  const [selectedClient, setSelectedClient] = useState('');

  useEffect(() => {
    dispatch(
      apiCall({
        url: ApiUrls.clients(),
        callback: res => onResponse.current(res),
      }),
    );
  }, [dispatch]);

  onResponse.current = res => {
    console.log({ res });
    if (res && res.data && res.data.success) {
      setClients(res.data.data);
      //   console.log('-------------------', res.data.data.reverse());
    }
  };

  const renderItem = (val, i) => {
    // console.log('value is--------', val);

    return (
      <div key={i} className="flex item-row">
        <div className="sm:w-full md:w-1/6 text-center">{val.id}</div>
        <div className="sm:w-full md:w-3/6 text-center">{val.name}</div>

        <div className="sm:w-full md:w-3/6 text-center">{val.status}</div>
        <div
          onClick={() => window.open(`http://${val.subdomain}`, '_blank')}
          className="sm:w-full md:w-3/6 text-center cursor-pointer text-info"
        >
          {val.subdomain}
        </div>
        <div className="sm:w-full md:w-3/6 text-center flex justify-content-center">
          <KuposButton onClick={() => setSelectedClient(val)}>
            <span>Ver </span>
          </KuposButton>
          <KuposButton onClick={() => history.push('/clients/' + val.id + '/edit')}>
            <span>Editar</span>
          </KuposButton>
        </div>
      </div>
    );
  };

  const renderViewModal = () => {
    return (
      <KuposModalWithButtons
        size="lg"
        // buttonText1="O"
        // onButtonClick1={this.addSkillPoint}
        showButton1={true}
        showModal={selectedClient ? true : false}
        onHide={() => setSelectedClient(null)}
      >
        {selectedClient && selectedClient.id ? (
          <div className="view-item-container">
            <h3 className="text-center font-bold">Cliente</h3>
            <div className="flex view-item-row">
              <div className="sm:w-full md:w-2/6 label font-bold">Nombre:</div>
              <div className="sm:w-full md:w-full">{selectedClient.name}</div>
            </div>

            <div className="flex view-item-row">
              <div className="sm:w-full md:w-2/6 label font-bold">Email:</div>
              <div className="sm:w-full md:w-full">{selectedClient.contact_email}</div>
            </div>

            <div className="flex view-item-row">
              <div className="sm:w-full md:w-2/6 label font-bold">Dirección:</div>
              <div className="sm:w-full md:w-full">{selectedClient.address}</div>
            </div>
            <div className="flex view-item-row">
              <div className="sm:w-full md:w-2/6 label font-bold">Estatus:</div>
              <div className="sm:w-full md:w-full">{selectedClient.status}</div>
            </div>
          </div>
        ) : null}
      </KuposModalWithButtons>
    );
  };

  return (
    <div className="">
      {/* <div className="add-item-container flex align-center justify-between">
          <div className=" pl-3 font-bold">Clients</div>
          <KuposButton
            className="add-button "
            onClick={() => this.props.history.push('/locations/create')}
          >
            <span>
              <i class="fas fa-plus"></i>Add Client
            </span>
          </KuposButton>
        </div> */}
      <hr className="pb-3" />

      <div className="add-item-container flex align-center justify-between">
        <div className="pl-3 font-bold">Clients</div>
        <KuposButton
            className="add-button "
            onClick={() => history.push('/clients/create')}
          >
            <span>
              <i class="fas fa-plus"></i>Agregar cliente
            </span>
          </KuposButton>
      </div>
      <div className="flex table-heading">
        <div className="sm:w-full md:w-1/6 text-center">ID</div>
        <div className="sm:w-full md:w-3/6 text-center">Nombre</div>
        <div className="sm:w-full md:w-3/6 text-center">Estatus</div>
        <div className="sm:w-full md:w-3/6 text-center">Subdominio</div>
        <div className="sm:w-full md:w-3/6 text-center">Acciones</div>
      </div>

      {((clients && clients) || []).length > 0
        ? clients
            .sort((x, y) => {
              return y.id - x.id;
            })
            .map((val, i) => renderItem(val, i))
        : null}

      {renderViewModal()}

      {/* {this.renderDeleteModal()} */}
    </div>
  );
};

export default ClientsScreen;
