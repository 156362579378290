import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actions from './redux/actions';
import { KuposInput, KuposModal, SvgHome } from '../common';

export class BookingPaymentModel extends Component {
  static propTypes = {
    home: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {
    this.props.selectedPaymentMethod.forEach(element => {
      if (element.active) {
        this.setState({ choosenPaymentMethod: element });
      }
    });
  }

  onChange = val => {
    this.setState({ transRefNo: val, transRefNoError: null });
    this.props.onInputChange(val);
  };

  onButtoPress = () => {
    if (!this.state.transRefNo) {
      this.setState({ transRefNoError: { message: 'Por favor ingrese los detalles de la transacción ' } });
      return;
    }

    if (
      this.state.choosenPaymentMethod.label != 'Trans bancaria' &&
      this.state.transRefNo &&
      this.state.transRefNo.length != 6
    ) {
      this.setState({
        transRefNoError: { message: 'Ingrese los últimos 6 dígitos del número de la tarjeta' },
      });

      return;
    }

    this.props.button1Press();
  };

  render() {
    console.log('selectedPaymentMethod', this.props.selectedPaymentMethod);

    return (
      <div className="common-bip-info-modal">
        <KuposModal
          showModal={this.props.showModal}
          onHide={this.props.onHide}
          size="md"
          ariaLabel="login-modal"
        >
          <div className="bip-info-modal-container">
            <div className="close-button pointer" onClick={this.props.onHide}>
              <SvgHome name="close" />
            </div>
            <div style={{ marginTop: 30 }}>
              <div
                className="bold-text"
                style={{ display: 'flex', justifyContent: 'center', marginBottom: 20 }}
              >
                Seleccione un método de pago:{' '}
                {this.state.choosenPaymentMethod && this.state.choosenPaymentMethod.label}
              </div>
              <div>
                <div className="name-input">
                  <div>
                    <span className="font11">
                      {this.state.choosenPaymentMethod &&
                      this.state.choosenPaymentMethod.label === 'Bank transfer'
                        ? 'Ingrese número de transacción'
                        : this.state.choosenPaymentMethod &&
                          this.state.choosenPaymentMethod.label === 'Efectivo'
                        ? 'Enter recipt number'
                        : ' Número de tarjeta'}
                    </span>
                  </div>
                  <KuposInput
                    className="kupos-border"
                    type="text"
                    placeholder="xxxxxxxxxxxx"
                    value={this.state.transRefNo}
                    onChange={val => this.onChange(val)}
                    onBlur={text => {}}
                    error={this.state.transRefNoError ? true : false}
                    errorMessage={
                      this.state.transRefNoError ? this.state.transRefNoError.message : null
                    }
                    style={{ height: 40 }}
                    inputOuterStyle={{ height: 40 }}
                  />
                </div>
              </div>
              <div className="kupos-modal-button-div font12" style={{ marginTop: 20 }}>
                <button
                  style={{ width: this.props.buttonWidth ? this.props.buttonWidth : '100%' }}
                  className="kupos-button"
                  onClick={this.onButtoPress}
                >
                  {'CONFIRMAR VENTA'}
                </button>
              </div>
            </div>
          </div>
        </KuposModal>
      </div>
    );
  }
}

/* istanbul ignore next */
function mapStateToProps(state) {
  return {
    home: state.home,
  };
}

/* istanbul ignore next */
function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...actions }, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(BookingPaymentModel);
