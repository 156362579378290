import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actions from './redux/actions';
import { KuposButton, KuposInput, KuposModalWithButtons } from '../common';
import ApiUrls from '../../services/apiUrls';
import AppData from '../../services/appData';
import axios from 'axios';
import Loader from 'react-loader-spinner';

export class ServiceImages extends Component {
  static propTypes = {
    home: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired,
  };
  constructor(props) {
    super(props);
    this.state = {
      imageList: [
        // { id: 1, imageSource: '/images/cars/taxi_exclusive.png' },
        // { id: 2, imageSource: 'https://gt-service-images.s3.amazonaws.com/1623439556-at-adult.png' },
        // { id: 3, imageSource: '/images/cars/van_shared.png' },
      ],
      serviceId: Number(this.props.match.params.id),
      imageId: 1,
    };
  }

  componentDidMount() {
    this.getList();
  }

  getList = () => {
    this.props.actions.apiCall({ url: ApiUrls.getServiceImages(this.state.serviceId) }).then(
      res => {
        this.responseImageList(res);
      },
      err => {
        this.responseImageList(err);
      },
    );
  };

  responseImageList = res => {
    console.log('+++Result from service images api', res);
    if (res.data && res.data.success) {
      this.setState({ imageList: res.data.data.image_list });
      this.setState({
        imageId: res.data.data.image_list != null ? Number(res.data.data.image_list.length) + 1 : 1,
      });
    }
  };

  renderItem = (val, i) => {
    console.log('+++value is', val);
    return (
      <div key={i} className="flex item-row">
        <div className="sm:w-full md:w-1/6 text-center">{val.image_id}</div>
        <div
          className="sm:w-full md:w-2/6 text-center capitalize-text"
          style={{ display: 'flex', justifyContent: 'center' }}
        >
          <img src={val.image_path} style={{ width: 'auto', height: 80 }} />
        </div>
        <div className="sm:w-full md:w-3/6 flex-center">
          <KuposButton
            onClick={() =>
              this.setState({
                showDeleteModal: true,
                selectedImageName: val.image_path,
                selectedImageId: val.image_id,
              })
            }
          >
            <span>Eliminar</span>
          </KuposButton>
        </div>
      </div>
    );
  };

  renderDeleteModal = () => {
    return (
      <KuposModalWithButtons
        size="md"
        buttonText1="No"
        buttonText2="Yes"
        onButtonClick1={() => this.setState({ showDeleteModal: false })}
        onButtonClick2={this.deleteItem}
        showButton1={true}
        showButton2={true}
        showModal={this.state.showDeleteModal}
        onHide={() => this.setState({ showDeleteModal: false })}
      >
        <div className="create-staff">
          <h4 className="text-center">¿Quieres eliminar?</h4>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <img src={this.state.selectedImageName} style={{ height: 50, width: 'auto' }} />
          </div>
        </div>
      </KuposModalWithButtons>
    );
  };

  deleteItem = () => {
    this.props.actions
      .apiCall({
        url: ApiUrls.deleteServiceImage(this.state.serviceId, this.state.selectedImageId),
        method: 'DELETE',
      })
      .then(
        res => {
          this.onDeleteResponse(res);
        },
        err => {
          this.onDeleteResponse(err);
        },
      );
  };

  onDeleteResponse = res => {
    if (res.data && res.data.success) {
      this.setState({
        showSuccessFailureModal: true,
        showSuccessFailureModalStatus: true,
        showSuccessFailureModalBodyText: 'Eliminar éxito',
        selectedImageName: null,
        selectedImageId: null,
        showDeleteModal: false,
      });
      this.getList();
    } else {
      this.setState({
        showSuccessFailureModal: true,
        showSuccessFailureModalStatus: false,
        showSuccessFailureModalBodyText: res.data.message,
        selectedImageName: null,
        selectedImageId: null,
      });
    }
  };

  onChange = (val, type) => {
    // this.setState({ serviceImage: val, serviceImageError: null });
  };

  validate = () => {
    if (!this.state.serviceImage) {
      this.setState({
        serviceImageError: {
          message: 'Por favor elija una imagen',
        },
      });
      return false;
    }
    return true;
  };

  addImage = () => {
    if (!this.validate()) {
      return;
    }
    let randomString = Math.random().toString(36);

    this.setState({
      theInputKey: randomString,
      serviceImage: null
    });
    this.setState({ showLoader: true });
    const data = new FormData();
    data.append('serviceImage', this.state.serviceImage, this.state.serviceImage.name);
    data.append('serviceId', this.state.serviceId);
    data.append('imageId', this.state.imageId);
    //  let data = {
    //     serviceImage: this.state.serviceImage,
    //     serviceId: this.state.serviceId,
    //     imageId: this.state.imageId,
    //   };

    // let url = AppData.BASE_URL + 'serviceImage';
    // axios.post(url, data).then(res => {
    //   console.log("+++response from api",res)
    // });

    this.props.actions
      .apiCall({ method: 'POST', url: ApiUrls.addServiceImages(), data: data })
      .then(
        res => {
          this.onAddServiceImageResponse(res);
        },
        err => {
          this.onAddServiceImageResponse(err);
        },
      );
  };

  onAddServiceImageResponse = result => {
    console.log('+++result from add image api is', result);
    if (result.data && result.data.success && result.data.data) {
      this.setState({ showLoader: false });

      this.getList();
    }
  };
  onFileChange = event => {
    this.setState({ serviceImage: event.target.files[0], serviceImageError: null });
  };

  render() {
    console.log('+++ state in service image ', this.state);
    return (
      <div className="home-service-images">
        <hr className="pb-3" />
        <div className="add-item-container flex align-center justify-between">
          <div className="pl-3 font-bold">Service Images</div>

          <div style={{ display: 'flex', alignItems: 'flex-end' }}>
            <div className="common-kupos-input-box mr-1 kupos-border p-1" style={{ marginTop: 10 }}>
              {/* <KuposInput
                type="file"
                className="kupos-border"
                placeholder={'Address'}
                label={'Choose an image'}
                value={this.state.serviceImage}
                error={this.state.serviceImageError ? true : false}
                errorMessage={
                  this.state.serviceImageError ? this.state.serviceImageError.message : ''
                }
                onChange={text => this.onChange(text, 'serviceImage')}
                inputOuterStyle={{ padding: 0 }}
              /> */}
              <input
                className="kupos-border"
                style={{ padding: 5, display: 'none' }}
                type="file"
                onChange={this.onFileChange}
                accept="image/png,image/jpeg"
                key={this.state.theInputKey || ''}
                id={'file'}
              />
                <label for="file" className="add-button kupos-border p-1 font12" style={{backgroundColor:'#367290',color:'#fff'}}>
                Seleccionar archivo
                </label>
                <span className="font11">
                  {this.state.serviceImage ? this.state.serviceImage.name : 'Ningún archivo elegido'}
                </span>
            
            </div>

            {this.state.serviceImage ? (
              <KuposButton className="add-button kupos-border" onClick={this.addImage}>
                <span>
                  <i class="fas fa-plus"></i>Upload Image
                </span>
              </KuposButton>
            ) : null}
          </div>
        </div>
        <div className="flex table-heading">
          <div className="sm:w-full md:w-1/6 text-center">Id</div>
          <div className="sm:w-full md:w-2/6 text-center">Image</div>
          <div className="sm:w-full md:w-3/6 text-center">Acciónes</div>
        </div>
        {this.state.imageList != null &&
          this.state.imageList.map((val, i) => this.renderItem(val, i))}

        {this.renderDeleteModal()}
        {this.state.showLoader ? (
          <div className="loader-container">
            <Loader
              visible={this.state.showLoader}
              type="Bars"
              color="#637290"
              height={80}
              width={80}
            />
          </div>
        ) : null}
      </div>
    );
  }
}

/* istanbul ignore next */
function mapStateToProps(state) {
  return {
    home: state.home,
  };
}

/* istanbul ignore next */
function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...actions }, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ServiceImages);
