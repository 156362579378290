import React, { useEffect, useState } from 'react';
import TicketSearch from './TicketSearch';
import { Alert } from '@material-ui/lab';
import { Snackbar } from '@material-ui/core';
import CommonService from '../../../services/commonService';
import { useDispatch } from 'react-redux';
import { apiCall } from '../redux/apiCall';
import ApiUrls from '../../../services/apiUrls';
import SearchInput from './SearchInput';
import { DataNotFound } from '../../common';
import RenderResult from './RenderResult';
import DateService from '../../../services/dateService';

const initialState = {
  atOriginText: '',
  origin: '',
  originType: 'places',
  showOriginTerminalSuggestions: false,
  setPickupTerminal: '',
  airportChoosen: false,
  atDestinationText: '',
  destination: '',
  showDestinationTerminalSuggestions: false,
  setDestinationTerminal: '',
  destinationType: 'places',
  selectedDate: DateService.getTodayString(),
  selectedTime: '00:00',
  // showOnSelect
  selectedKupos: 1,
  selectedAdultKupos: 1,
  selectedKidKupos: 0,
  selectedInfantKupos: 0,
  ChoosenKupos: null,
  // ----------------
  apiResponseForPrinting: { data: [], pnr_no: '' },

  stepsMenu: [
    { label: 'Efectivo', active: true, value: 'CASH' },
    { label: 'Tarjeta Débito', active: false, value: 'DEBIT_CARD' },
    { label: 'Tarjeta Crédito', active: false, value: 'CREDIT_CARD' },
    { label: 'Trans bancaria', active: false, value: 'BANK_TRANSFER' },
    { label: 'Factura de Crédito', active: false, value: 'CREDIT_INVOICE' },
  ],
  selectedDiscounts: [],
  selectedDiscount: '',
  discountAmount: 0,
  selectedCurrency: '',
  additionalFareAmount: null,
  additionalFareSelected: null,
  additionalFareComment: '',
  docType: 'RUT',
  reserveDate: DateService.getTodayString(),
  // reserveTime: '00:00',
  selectedPaymentType: { label: 'Efectivo', active: true, value: 'CASH' },
  user_boading_address: '',
  exchangeListData: [],
  user: "guest",

};

const SalesScreen = ({ history }) => {
  const dispatch = useDispatch();
  const [values, setValues] = useState(initialState);
  const [snackbar, setSnackbar] = useState({});
  const [ticketData, setTicketData] = useState({});
  const [selectedService, setSelectedService] = useState('');
  const [callApiAgain, setCallApiAgain] = useState(false);
  const [metaData, setMetaData] = useState([]);

  console.log('values from sales screen', values);

  useEffect(() => {
    let counterDetails = CommonService.getLocal('counter');

    if (counterDetails === undefined) {
      setSnackbar({
        showSnackbar: true,
        persist: true,
        alertMessage:
          'No hay ninguna caja asignada, por favor primero elija una caja para continuar',
      });
      return;
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    dispatch(
      apiCall({
        url: ApiUrls.getMasterData(),
        callback: res => {
          if (res && res.data && res.data.success) {
            setMetaData(res.data.data);
          }
        },
      }),
    );
    getClientInfo();
    // eslint-disable-next-line
  }, [dispatch]);

  const closeSnackbar = () => {
    setSnackbar({ showSnackbar: false });
  };

  const getClientInfo = () => {
    dispatch(apiCall({ url: ApiUrls.getClientInfo(window.location.host), callback: res => onClientResponse(res) }))
  };

  const onClientResponse = (res) => {
    console.log('client  api response in home Page is-----', res);
    if (res && res.data && res.data.success) {
      console.log('client api response in home Page is INSIDEEE-----', res.data.data);
      
        CommonService.setLocal('clientInfo',res.data.data)
     
    }
  }

  return (
    <div className="home-default-page">
      <TicketSearch setSnackbar={setSnackbar} history={history} reloadPage navigate />

      <SearchInput
        values={values}
        setValues={setValues}
        setTicketData={setTicketData}
        setSelectedService={setSelectedService}
        setSnackbar={setSnackbar}
        callApiAgain={callApiAgain}
        setCallApiAgain={setCallApiAgain}
      />

      {selectedService ? (
        <RenderResult
          values={values}
          setValues={setValues}
          setSnackbar={setSnackbar}
          ticketData={ticketData}
          selectedService={selectedService}
          setSelectedService={setSelectedService}
          setCallApiAgain={setCallApiAgain}
          metaData={metaData}
        />
      ) : (
        <DataNotFound
          icon="../../../images/our_mission.svg"
          title={'Sin resultados'}
          subTitle={'Buscar nuestros servicios '}
          infoText={
            'Este servicio no está disponible en la fecha que seleccionada. Por favor intenta con otra fecha.'
          }
        // onInfoClick={() => this.setState({ showAddBIPCard: true })}
        />
      )}

      <Snackbar
        open={snackbar.showSnackbar}
        autoHideDuration={snackbar.persist ? 10000000000 : 5000}
        anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
        onClose={closeSnackbar}
      >
        <Alert onClose={closeSnackbar} severity="error">
          {snackbar.alertMessage}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default SalesScreen;
