import axios from 'axios';
import { useEffect, useCallback } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import CommonService from '../../../services/commonService';
import {
  HOME_API_CALL_BEGIN,
  HOME_API_CALL_SUCCESS,
  HOME_API_CALL_FAILURE,
  HOME_API_CALL_DISMISS_ERROR,
} from './constants';

export function apiCall(args = {}) {
  return dispatch => {
    // optionally you can have getState as the second argument
    dispatch({
      type: HOME_API_CALL_BEGIN,
    });

    const promise = new Promise((resolve, reject) => {
      let doRequest;
      if (args.method === 'PUT') {
        doRequest = axios.put(args.url, args.data, {
          headers: CommonService.getHeaders(),
        });
      } else if (args.method === 'POST') {
        doRequest = axios.post(args.url, args.data, {
          headers: CommonService.getHeaders(),
        });
      } else if (args.method === 'DELETE') {
        doRequest = axios.delete(args.url, {
          headers: CommonService.getHeaders(),
          data: args.data ? args.data : '',
        });
      } else {
        doRequest = axios.get(args.url, {
          headers: CommonService.getHeaders(),
        });
      }
      doRequest.then(
        res => {
          dispatch({
            type: HOME_API_CALL_SUCCESS,
            data: res,
          });
          resolve(res);
          if (args.callback) {
            args.callback(res);
          }
        },
        // Use rejectHandler as the second argument so that render errors won't be caught.
        err => {
          dispatch({
            type: HOME_API_CALL_FAILURE,
            data: { error: err },
          });
          if (err.response && err.response.status === 401) {
            CommonService.logout();
            window.location.href = '/';
          }
          if (args.callback) {
            args.callback(err.response);
          }
          reject(err.response);
        },
      );
    });

    return promise;
  };
}

export function dismissApiCallError() {
  return {
    type: HOME_API_CALL_DISMISS_ERROR,
  };
}

export function useApiCall() {
  const dispatch = useDispatch();

  const { apiCallPending, apiCallError } = useSelector(
    state => ({
      apiCallPending: state.home.apiCallPending,
      apiCallError: state.home.apiCallError,
    }),
    shallowEqual,
  );

  const boundAction = useCallback(
    (...args) => {
      return dispatch(apiCall(...args));
    },
    [dispatch],
  );

  const boundDismissError = useCallback(() => {
    return dispatch(dismissApiCallError());
  }, [dispatch]);

  return {
    apiCall: boundAction,
    apiCallPending,
    apiCallError,
    dismissApiCallError: boundDismissError,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case HOME_API_CALL_BEGIN:
      // Just after a request is sent
      return {
        ...state,
        apiCallPending: true,
        apiCallError: null,
      };

    case HOME_API_CALL_SUCCESS:
      // The request is success
      return {
        ...state,
        apiCallPending: false,
        apiCallError: null,
      };

    case HOME_API_CALL_FAILURE:
      // The request is failed
      return {
        ...state,
        apiCallPending: false,
        apiCallError: action.data.error,
      };

    case HOME_API_CALL_DISMISS_ERROR:
      // Dismiss the request failure error
      return {
        ...state,
        apiCallError: null,
      };

    default:
      return state;
  }
}
