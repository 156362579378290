import React, { Component } from 'react';
import PropTypes from 'prop-types';
import KuposSelectBox from './KuposSelectBox';
import KuposDatePicker from './KuposDatePicker';
import DateService from '../../services/dateService';
import KuposCounter from './KuposCounter';
import KuposTimePicker from './KuposTimePicker';
import KuposInput from './KuposInput';

export default class NewSearchBoxTransfer extends Component {
  static propTypes = {
    cities: PropTypes.array,
    openNewSearch: PropTypes.bool,
    newSearchTransfer: PropTypes.object,
    newSearch: PropTypes.func,
    t: PropTypes.func
  };
  constructor(props) {
    super(props)
    this.selectServiceOptions = [
      { value: 1, label: 'HOME.TO_AT' },
      { value: 2, label: 'HOME.FROM_AT' },
    ];
    this.state = {
      key: 1,
      atOriginText:this.props.transferData.selectedOrigin ? this.props.transferData.selectedOrigin.address : ''
    }
    this.textInput = React.createRef();
  }

  swapCities = () => {
    this.props.swapCities()
    this.setState({ key: this.state.key + 1 })
  }

  onChange = (val, type) => {
    this.props.onChange(val,type)
  }

  render() {
    const { t } = this.props;
    return (
      <div className={'common-new-search-box ' + (this.props.openNewSearch ? 'open' : '')}>
        <div className={' content-container new-search-box home-selection-input-boxes '}>
          <div className="home-selection-input-box font10 flex4">
            <span className="label font10">{t('HOME.SERVICE')}</span>
            <KuposSelectBox
              readonly={true}
              showAll={true}
              options={this.selectServiceOptions}
              selectedOption={this.props.t(this.props.transferData.selectedService)}
              onChange={val => this.onChange(val, 'service')}
              onInputChange={this.onInputChange}
              icon="/svgs/airport.svg"
              error={this.state.transferServiceError ? true : false}
              errorMessage={this.props.t(this.state.transferServiceError)}
              placeholder={t('HOME.SERVICE')}
              height={40}
              t={t}
            />
            {/* <div className="home-selection-input kupos-border">
                <img className="icon-size" src="./images/icons/home/icon_source.png"/> <span>Source</span>
            </div> */}
          </div>

          <div className="home-selection-input-box font10 flex4">
            <span className="label font10">
              {this.props.transferData.selectedService &&
              this.props.transferData.selectedService.value == 2
                ? t('HOME.DESTINATION')
                : t('HOME.ORIGIN')}
            </span>
            <KuposInput
              type="places"
              value={this.state.atOriginText}
              onTextChange={val => this.setState({ atOriginText: val })}
              onChange={val => this.onChange(val, 'origin')}
              placeholder={
                this.props.transferData.selectedService &&
                this.props.transferData.selectedService.value == 2
                  ? t('HOME.DESTINATION')
                  : t('HOME.ORIGIN')
              }
              icon="/images/icons/home/icon_source2.png"
              height={40}
              // key={this.state.key}
            />
            {/* <div className="home-selection-input kupos-border">
                <img className="icon-size" src="./images/icons/home/icon_source2.png"/> <span>Source</span>
            </div> */}
          </div>

          <div className="home-selection-input-box datebox font10 flex2">
            <span className="label font10">{t('HOME.DATE')}</span>
            <KuposDatePicker
              minDate={DateService.getTodayString()}
              selectedDate={this.props.transferData.selectedDate}
              onDateChange={val => this.onChange(val, 'date')}
            />
            {/* <div className="home-selection-input kupos-border">
                  <img className="icon-size" src="./images/icons/home/icon_calendar.png"/> <span>Source</span>
              </div> */}
          </div>
          <div className="home-selection-input-box  font10 flex3">
            <span className="label font10">{t('HOME.AIRPORT_TIME')}</span>
            <KuposTimePicker
              value={this.props.transferData.selectedTime}
              onChange={val => this.onChange(val, 'time')}
              error={this.state.transferTimeError ? true : false}
              errorMessage={this.state.transferTimeError}
              placeholder={'00:00'}
            />
            {/* <div className="home-selection-input kupos-border">
                    <img className="icon-size" src="./images/icons/home/icon_calendar.png"/> <span>Source</span>
                </div> */}
          </div>
          <div className="home-selection-input-box  font10 flex2">
            <span className="label font10">{t('HOME.KUPOS')}</span>
            <KuposCounter
              value={this.props.transferData.selectedKupos}
              onChange={val => this.onChange(val, 'kupos')}
            />
            {/* <div className="home-selection-input kupos-border">
                    <img className="icon-size" src="./images/icons/home/icon_calendar.png"/> <span>Source</span>
                </div> */}
          </div>

          <div className="home-selection-input-box datebox font10 flex2">
            <button
              className="kupos-button home-selection-input-button "
              onClick={this.props.newSearch}
            >
              <img className="icon-size" src="/images/icons/home/icon_search-white.png" alt={this.props.t('TRANSLATIONS.ICON_SEARCH_WHITE')} />{' '}
              <span className="font11">{t('HOME.SEARCH')}</span>
            </button>
          </div>
        </div>
      </div>
    );
  }
}
