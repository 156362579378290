import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import ApiUrls from '../../services/apiUrls';
import DateService from '../../services/dateService';
import { DropdownComponent, KuposButton, KuposErrorSuccessModal, KuposInput } from '../common';
import { apiCall } from './redux/apiCall';


const initialState = {
  branchName: '',
  branchAddress: '',
  branchRemarks: '',
};

const AddBranch = ({ match, history }) => {
  const dispatch = useDispatch();
  const [values, setValues] = useState(initialState);
  const [modal, setModal] = useState({});
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);

  const id = match.params ? Number(match.params.id) : null;

  useEffect(() => {
    getCountriesList();
    getStatesList();
    getCitiesList();
    if (id) {
      getList();
    }
    // eslint-disable-next-line
  }, [id]);

  console.log('values from add branch ', values);

  const getCountriesList = () => {
    dispatch(
      apiCall({
        url: ApiUrls.addCountry(),
        callback: res => {
          console.log('Result from all countries api', res);
          if (res.data && res.data.success) {
            const countries = res.data.data.map(country => {
              return { value: country.id, label: country.name };
            });
            setCountries(countries);
          }
        },
      }),
    );
  };

  const getStatesList = () => {
    dispatch(
      apiCall({
        url: ApiUrls.addState(),
        callback: res => {
          console.log('Result from all state api', res);
          if (res.data && res.data.success) {
            const states = res.data.data.map(state => {
              return { value: state.id, label: state.name };
            });
            setStates(states);
          }
        },
      }),
    );
  };

  const getCitiesList = () => {
    dispatch(
      apiCall({
        url: ApiUrls.addCity(),
        callback: res => {
          if (res.data && res.data.success) {
            const cities = res.data.data.map(city => {
              return { value: city.id, label: city.name };
            });
            setCities(cities);
          }
        },
      }),
    );
  };

  const getList = () => {
    dispatch(
      apiCall({
        url: ApiUrls.addBranch(),
        callback: res => {
          console.log('Result from all state api', res);
          const found = res.data.data.find(f => f.id === id);
          if (found) {
            console.log({ found });
            getParticularBranch(found);
          }
        },
      }),
    );
  };

  const getParticularBranch = data => {
    setValues({
      ...values,
      branchToEdit: data,
      branchName: data.name,
      branchAddress: data.address,
      branchRemarks: data.remark,
      countryId: data.country_id,
      countryName: data.country_name,
      stateId: data.state_id,
      stateName: data.state_name,
      cityId: data.city_id,
      cityName: data.city_name,
      startDate: DateService.changeDateFormat(
        data.availability_start_date,
        'yyyy-mm-dd',
        'dd-mm-yyyy',
      ),
      endDate: DateService.changeDateFormat(data.availability_end_date, 'yyyy-mm-dd', 'dd-mm-yyyy'),
      selectedCountry: { value: Number(data.country_id), label: data.country_name },
      selectedState: { value: Number(data.state_id), label: data.state_name },
      selectedCity: { value: Number(data.city_id), label: data.city_name },
    });
  };

  const onChange = (val, type) => {
    setValues({ ...values, [type]: val, [type + 'Error']: null });
  };

  const validate = () => {
    if (!values.branchName) {
      setValues({ ...values, branchNameError: 'Ingrese el nombre de la sucursal' });
      return false;
    }

    if (!values.branchAddress) {
      setValues({ ...values, branchAddressError: 'Ingrese la dirección de la sucursal' });
      return false;
    }

    if (!values.selectedCountry) {
      setValues({
        ...values,
        selectedCountryError: 'Seleccione un país',
      });
      return false;
    }
    if (!values.selectedState) {
      setValues({
        ...values,
        selectedStateError: 'Seleccione estado',
      });
      return false;
    }
    if (!values.selectedCity) {
      setValues({
        ...values,
        selectedCityError: 'Ciudad selecta',
      });
      return false;
    }

    if (!values.branchRemarks) {
      setValues({
        ...values,
        branchRemarksError: 'Ingrese el comentario de la rama',
      });
      return false;
    }
    return true;
  };

  const createOrUpdate = () => {
    if (!validate()) {
      return;
    }
    let method = 'POST';
    let data = {};
    if (id) {
      method = 'PUT';
      data = {
        id,
        name: values.branchName,
        address: values.branchAddress,
        city_id: values.selectedCity.value,
        city_name: values.selectedCity.label,
        state_id: values.selectedState.value,
        state_name: values.selectedState.label,
        country_id: values.selectedCountry.value,
        country_name: values.selectedCountry.label,
        client_code: values.branchToEdit.client_code,
        status: values.branchToEdit.status,
        remark: values.branchRemarks,
        created_on: values.branchToEdit.created_on,
        created_by: values.branchToEdit.created_by,
        last_updated_on: values.branchToEdit.last_updated_on,
        last_updated_by: values.branchToEdit.last_updated_by,
      };
    } else {
      data = {
        name: values.branchName,
        address: values.branchAddress,
        city_id: values.selectedCity.value,
        city_name: values.selectedCity.label,
        state_id: values.selectedState.value,
        state_name: values.selectedState.label,
        country_id: values.selectedCountry.value,
        country_name: values.selectedCountry.label,
        remark: values.branchRemarks,
      };
    }

    console.log('api data', data);

    dispatch(
      apiCall({
        url: ApiUrls.addBranch(),
        method,
        data,
        callback: res => onResponse(res),
      }),
    );
  };

  const onResponse = res => {
    if (res.data && res.data.success && res.data.data) {
      setModal({ show: true, success: true, bodyText: 'Éxito' || (id ? 'Éxito' : 'Éxito') });
    } else if (!res.data.success && res.data.message) {
      setModal({ show: true, success: false, bodyText: res.data.message });
    } else {
      setModal({ show: true, success: false, bodyText: 'Fallar' });
    }
  };

  return (
    <div className="home-add-branch pr-3 pl-3">
      <h2 className="mt-4 mb-4 font-bold">{id ? 'Editar Sucursal' : 'Crear nuevo Sucursal'}</h2>
      <div className="common-kupos-input-box" style={{ marginTop: 10 }}>
        <KuposInput
          type="text"
          className="kupos-border"
          placeholder={'Nombre'}
          label={'Nombre'}
          disabled={id ? true : false}
          value={values.branchName}
          error={values.branchNameError ? true : false}
          errorMessage={values.branchNameError ? values.branchNameError : ''}
          onChange={text => onChange(text, 'branchName')}
        />
      </div>
      <div className="common-kupos-input-box" style={{ marginTop: 10 }}>
        <KuposInput
          type="text"
          className="kupos-border"
          placeholder={'Dirección'}
          label={'Dirección'}
          value={values.branchAddress}
          error={values.branchAddressError ? true : false}
          errorMessage={values.branchAddressError ? values.branchAddressError : ''}
          onChange={text => onChange(text, 'branchAddress')}
        />
      </div>
      <div style={{ marginTop: 10 }}>
        <div>
          <span className="input-label">País</span>
        </div>
        <DropdownComponent
          onChange={val => onChange(val, 'selectedCountry')}
          placeholder={'País'}
          options={countries}
          value={values.selectedCountry}
          error={values.selectedCountryError ? true : false}
          errorMessage={values.selectedCountryError ? values.selectedCountryError : ''}
        />
      </div>
      <div style={{ marginTop: 10 }}>
        <div>
          <span className="input-label">Región</span>
        </div>
        <DropdownComponent
          onChange={val => onChange(val, 'selectedState')}
          placeholder={'Región'}
          options={states}
          value={values.selectedState}
          error={values.selectedStateError ? true : false}
          errorMessage={values.selectedStateError ? values.selectedStateError : ''}
        />
      </div>
      <div style={{ marginTop: 10 }}>
        <div>
          <span className="input-label">Ciudad</span>
        </div>
        <DropdownComponent
          onChange={val => onChange(val, 'selectedCity')}
          placeholder={'Ciudad'}
          options={cities}
          value={values.selectedCity}
          error={values.selectedCityError ? true : false}
          errorMessage={values.selectedCityError ? values.selectedCityError : ''}
        />
      </div>
      <div className="common-kupos-input-box" style={{ marginTop: 10 }}>
        <KuposInput
          type="text"
          className="kupos-border"
          placeholder={'Observaciones'}
          label={'Observaciones'}
          value={values.branchRemarks}
          error={values.branchRemarksError ? true : false}
          errorMessage={values.branchRemarksError ? values.branchRemarksError : ''}
          onChange={text => onChange(text, 'branchRemarks')}
        />
      </div>
      <div className="button-container" style={{ marginTop: 20, marginBottom: 40 }}>
        <KuposButton onClick={createOrUpdate}>
          <span className="font11">{id ? 'Actualizar Sucursal' : 'Crear Sucursal'}</span>
        </KuposButton>
      </div>
      <KuposErrorSuccessModal
        success={modal.success}
        showModal={modal.show}
        onButtonClick={() => {
          if (modal.success) history.push('/branches');
          setModal({});
        }}
        bodyText={modal.bodyText}
      />
    </div>
  );
};

export default AddBranch;
