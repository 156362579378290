import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actions from './redux/actions';
import { DropdownComponent } from '../common/DropdownComponent';
import { KuposButton, KuposErrorSuccessModal, KuposInput } from '../common';
import ApiUrls from '../../services/apiUrls';
import AppData from '../../services/appData';
import CommonService from '../../services/commonService';
import DateService from '../../services/dateService';

export class AddIncomeExpensesTransaction extends Component {
  static propTypes = {
    home: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      types: [
        { value: 'EXPENSE', label: 'Gastos' },
        { value: 'INCOME', label: 'Ingresos' },
      ],
      paymentTypes: [
        { value: 'CREDIT_CARD', label: 'Tarjeta de crédito' },
        { value: 'DEBIT_CARD', label: 'Tarjeta de débito' },
        { value: 'CASH', label: 'Efectivo' },
        { value: 'CHEQUE', label: 'Cheques' },
      ],
    };
  }

  componentDidMount() {
    this.getIncomeExpenseList();
  }

  getIncomeExpenseList = () => {
    this.props.actions.apiCall({ url: ApiUrls.allIncomeExpense() }).then(
      res => {
        let incomeList = [];
        let expensesList = [];
        console.log('Expemnses list fetched ----------', res);
        if (res.data && res.data.success) {
          res.data.data.income_item_list.map(val => {
            incomeList.push({ value: val.id, label: val.name });
          });

          res.data.data.expense_item_list.map(val => {
            expensesList.push({ value: val.id, label: val.name });
          });

          this.setState({
            incomeexpenseList: res.data.data,
            incomeList: incomeList,
            expensesList: expensesList,
          });
        }
      },
      err => {
        console.log('Result is', err);
      },
    );
  };

  onBlur = () => {};
  onChange = (val, type) => {
    switch (type) {
      case 'txn_type':
        this.setState({ txnType: val, txnTypeError: null });
        break;
      case 'txn_for':
        this.setState({ transactionFor: val, transactionForError: null });
        break;
      case 'payment_type':
        this.setState({ paymentType: val, paymentTypeError: null });
        break;
      case 'amount':
        this.setState({ amount: val, amountError: null });
        break;
      case 'received_from':
        this.setState({ receivedFrom: val, receivedFromError: null });
        break;
      case 'comment':
        this.setState({ comment: val, commentError: null });
        break;
    }
  };

  validate = () => {
    let errors = 0;

    if (!this.state.txnType) {
      this.setState({ txnTypeError: { message: 'Elija el tipo' } });
      errors++;
    }
    if (!this.state.transactionFor) {
      this.setState({ transactionForError: { message: 'Elija la transacción para' } });
      errors++;
    }
    if (!this.state.paymentType) {
      this.setState({ paymentTypeError: { message: 'Elija tipo de pago' } });
      errors++;
    }
    if (!this.state.amount) {
      this.setState({ amountError: { message: 'Ingrese monto' } });
      errors++;
    }
    if (!this.state.receivedFrom) {
      this.setState({ receivedFromError: { message: 'Recibio por' } });
      errors++;
    }
    if (!this.state.comment) {
      this.setState({ commentError: { message: 'Ingrese comentario' } });
      errors++;
    }

    if (errors > 0) {
      return false;
    } else {
      return true;
    }
  };

  createOrUpdate = () => {
    if (!this.validate()) {
      return;
    }

    let counter = CommonService.getLocal('counter');

    console.log('Conter details are-----', counter);

    if (counter == undefined) {
      this.setState({
        showSuccessModal: true,
        successModalStatus: false,
        successModalMessage: 'no counter is assigned to you, Please choose the counter first!',
      });
      return;
    }

    let currentTime = DateService.getCurrentTimeUTC();

    let data = {
      branch_id: counter.branch_id,
      counter_id: counter.counter_id,
      counter_txn_id: counter.counter_txn_id,
      shift_id: counter.shift_id,
      txn_type: this.state.txnType.value, //binding:"required"` // INCOME | EXPENSE
      txn_name_id: this.state.transactionFor.value,
      txn_name: this.state.transactionFor.label, // Income or Expense Item List
      txn_date_stg: DateService.getTodayString('mm-dd-yyyy'), // MM-DD-YYYY
      txn_time_stg: `${DateService.getCurrentHour()}:${DateService.getCurrentMinute()}`, // MM-DD-YYYY
      txn_date_time: currentTime, // UTC time in Unix Milli Seconds with TZ consideration
      amount: Number(this.state.amount),
      currency: 'CLP', // CLP
      payment_type: this.state.paymentType.value, // CASH
      counter_party_name: this.state.receivedFrom, // Name of the person who receives the money
      remark: this.state.comment,
    };

    console.log('data to fly----', data);

    // return;

    this.props.actions
      .apiCall({ url: `${AppData.BASE_URL}agent/userTxn`, data: data, method: 'POST' })
      .then(
        res => {
          this.onTransactionAdd(res);
        },
        err => {
          this.onTransactionAdd(err);
        },
      );
  };

  onTransactionAdd = response => {
    console.log('Result from txn add----', response);

    if (response && response.data && response.data.success && response.data.data) {
      this.setState({
        showSuccessModal: true,
        successModalStatus: true,
        successModalMessage: response.data.message == "Success" ? "Éxito" : response.data.message,
      });
    } else {
      this.setState({
        showSuccessModal: true,
        successModalStatus: false,
        successModalMessage: response.data.message == "Success" ? "Éxito" : response.data.message,
      });
    }
  };

  render() {
    console.log('Txn for --------', this.state.transactionFor);
    return (
      <div className="home-add-income-expenses-transaction">
        <div className="home-selection-input-box font10 select-box">
          <DropdownComponent
            onChange={val => this.onChange(val, 'txn_type')}
            placeholder={'Seleccione transacción'}
            disabled={this.props.match.params.id ? true : false}
            options={this.state.types}
            value={this.state.txnType}
            error={this.state.txnTypeError ? true : false}
            errorMessage={this.state.txnTypeError ? this.state.txnTypeError.message : null}
          />
        </div>
        <div className="home-selection-input-box font10 select-box" style={{ marginTop: 10 }}>
          <DropdownComponent
            onChange={val => this.onChange(val, 'txn_for')}
            placeholder={'Transacción para'}
            options={
              this.state.txnType && this.state.txnType.value === 'INCOME'
                ? this.state.incomeList
                : this.state.expensesList
            }
            value={this.state.transactionFor}
            error={this.state.transactionForError ? true : false}
            errorMessage={
              this.state.transactionForError ? this.state.transactionForError.message : null
            }
          />
        </div>
        <div className="home-selection-input-box font10 select-box" style={{ marginTop: 10 }}>
          <DropdownComponent
            onChange={val => this.onChange(val, 'payment_type')}
            placeholder={'Tipo de pago'}
            options={this.state.paymentTypes}
            value={this.state.paymentType}
            error={this.state.paymentTypeError ? true : false}
            errorMessage={this.state.paymentTypeError ? this.state.paymentTypeError.message : null}
          />
        </div>
        <div className="common-kupos-input-box" style={{ marginTop: 10 }}>
          <KuposInput
            type="text"
            className="kupos-border"
            placeholder={'Monto'}
            value={this.state.amount}
            error={this.state.amountError ? true : false}
            errorMessage={this.state.amountError ? this.state.amountError.message : ''}
            onChange={text => this.onChange(text, 'amount')}
            onBlur={text => this.onBlur(text, 'amount')}
          />
        </div>
        <div className="common-kupos-input-box" style={{ marginTop: 10 }}>
          <KuposInput
            type="text"
            className="kupos-border"
            placeholder={
              this.state.txnType && this.state.txnType.value === 'INCOME'
                ? 'Recibido de'
                : 'Pagado a'
            }
            value={this.state.receivedFrom}
            error={this.state.receivedFromError ? true : false}
            errorMessage={this.state.receivedFromError ? this.state.receivedFromError.message : ''}
            onChange={text => this.onChange(text, 'received_from')}
            onBlur={text => this.onBlur(text, 'received_from')}
          />
        </div>
        <div className="common-kupos-input-box" style={{ marginTop: 10 }}>
          <KuposInput
            type="text"
            className="kupos-border"
            placeholder={'Ingrese comentario'}
            value={this.state.comment}
            error={this.state.commentError ? true : false}
            errorMessage={this.state.commentError ? this.state.commentError.message : ''}
            onChange={text => this.onChange(text, 'comment')}
            onBlur={text => this.onBlur(text, 'comment')}
          />
        </div>

        <div className="button-container" style={{ marginTop: 20 }}>
          <KuposButton onClick={this.createOrUpdate}>
            <span className="font11">
              {this.props.match.params.id ? 'Actualizar transacción' : 'Crear transacción'}
            </span>
          </KuposButton>
        </div>

        <KuposErrorSuccessModal
          success={this.state.successModalStatus}
          showModal={this.state.showSuccessModal}
          onButtonClick={() => {
            this.setState({
              showSuccessModal: false,
              successModalStatus: false,
              successModalMessage: false,
            });
            if (this.state.successModalStatus) this.props.history.goBack();
          }}
          bodyText={this.state.successModalMessage}
        />
      </div>
    );
  }
}

/* istanbul ignore next */
function mapStateToProps(state) {
  return {
    home: state.home,
  };
}

/* istanbul ignore next */
function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...actions }, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AddIncomeExpensesTransaction);
