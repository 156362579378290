import React, { useEffect, useRef, useState } from 'react';
import ApiUrls from '../../services/apiUrls';
import { AddButton, KuposErrorSuccessModal, ActionButtons, KuposListLoader, KuposModalWithButtons } from '../common';
import DateService from '../../services/dateService';
import { useDispatch } from 'react-redux';
import { apiCall } from './redux/apiCall';
import { Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';

const modalInitialState = {
  show: false,
  success: false,
  bodyText: null,
};
const UserPage = ({ history }) => {
  const dispatch = useDispatch();
  const onResponse = useRef(() => { });
  const [userList, setUserList] = useState([]);
  const [modal, setModal] = useState(modalInitialState);
  const [onLoadSpinner, setOnLoadSpinner] = useState(true);
  const [fetch, setFetch] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [snackbar, setSnackbar] = useState({});


  useEffect(() => {
    dispatch(apiCall({ url: ApiUrls.user(), callback: res => onResponse.current(res) }));
  }, [dispatch, fetch]);

  onResponse.current = res => {
    console.log({ res });
    setOnLoadSpinner(false);
    if (res && res.data && res.data.data && res.data.success) {
      setUserList(res.data.data);
    } else if (res && res.data && !res.data.success) {
      setSnackbar({
        showSnackbar: true,
        persist: true,
        alertMessage: res.data.message ? res.data.message : 'Failure'
      });
    } else {
      setSnackbar({
        showSnackbar: true,
        persist: true,
        alertMessage: 'Check Your Internet Connection'
      });
    }
  };

  const renderItem = (val, i) => {
    return (
      <div key={i} className="flex item-row">
        <div className="sm:w-full md:w-1/6 text-center">{val.id}</div>
        <div className="sm:w-full md:w-1/6 text-center">{val.client_code}</div>
        <div className="sm:w-full md:w-3/6 text-center">
          {val.first_name} {val.last_name}
        </div>
        <div className="sm:w-full md:w-4/6 text-center">{val.email}</div>
        <div className="sm:w-full md:w-2/6 text-center">
          {(val.user_roles_list || []).length ? val.user_roles_list[0].role_name : ''}
        </div>

        <ActionButtons
          val={val}
          view={setSelectedUser}
          url={('/users/' + val.id + '/edit')}
          handleDelete={() =>
            setModal({
              showDeleteModal: true,
              selectedItemName: val.first_name + ' ' + val.last_name + ', ' + val.email,
              selectedItemId: val.id,
            })
          }
        />
      </div>
    );
  };

  const getOldUserRoles = roles => {
    let oldRoles = '';
    roles &&
      roles.map(val => {
        oldRoles = oldRoles ? oldRoles + ', ' + val.role_name : oldRoles + val.role_name;
      });

    return oldRoles;
  };

  const renderViewModal = () => {
    return (
      <KuposModalWithButtons
        size="lg"
        // buttonText1="O"
        // onButtonClick1={this.addSkillPoint}
        showButton1={true}
        showModal={selectedUser ? true : false}
        onHide={() => setSelectedUser(null)}
      >
        {selectedUser ? (
          <div className="view-item-container">
            <h3 className="text-center font-bold">Usuario</h3>
            <div className="flex view-item-row">
              <div className="sm:w-full md:w-2/6 label font-bold">Primer nombre:</div>
              <div className="sm:w-full md:w-full">{selectedUser.first_name}</div>
            </div>
            <div className="flex view-item-row">
              <div className="sm:w-full md:w-2/6 label font-bold">Apellido:</div>
              <div className="sm:w-full md:w-full">{selectedUser.last_name}</div>
            </div>
            <div className="flex view-item-row">
              <div className="sm:w-full md:w-2/6 label font-bold">Correo electrónico:</div>
              <div className="sm:w-full md:w-full">{selectedUser.email}</div>
            </div>
            <div className="flex view-item-row">
              <div className="sm:w-full md:w-2/6 label font-bold">Papel:</div>
              <div className="sm:w-full md:w-full">
                {getOldUserRoles(selectedUser.user_roles_list)}
              </div>
            </div>
            <div className="flex view-item-row">
              <div className="sm:w-full md:w-2/6 label font-bold">Estatus:</div>
              <div className="sm:w-full md:w-full">{selectedUser.status}</div>
            </div>
            <div className="flex view-item-row">
              <div className="sm:w-full md:w-2/6 label font-bold">Creado en:</div>
              <div className="sm:w-full md:w-full">
                {DateService.changeDateFormat(
                  selectedUser.created_on.split('T')[0],
                  'yyyy-mm-dd',
                  'dd/mm/yyyy',
                )}
              </div>
            </div>
          </div>
        ) : null}
      </KuposModalWithButtons>
    );
  };

  const renderDeleteModal = () => {
    return (
      <KuposModalWithButtons
        size="md"
        buttonText1="No"
        buttonText2="Si"
        onButtonClick1={() => setModal(modalInitialState)}
        onButtonClick2={deleteItem}
        showButton1={true}
        showButton2={true}
        showModal={modal.showDeleteModal}
        onHide={() => setModal(modalInitialState)}
      >
        <div className="create-staff">
          <h4 className="text-center">¿Quieres eliminar "{modal.selectedItemName}"?</h4>
        </div>
      </KuposModalWithButtons>
    );
  };

  const deleteItem = () => {
    dispatch(
      apiCall({
        url: ApiUrls.user(modal.selectedItemId),
        method: 'DELETE',
        callback: res => onDeleteResponse(res),
      }),
    );
  };

  const onDeleteResponse = res => {
    if (res.data && res.data.success) {
      setModal({ show: true, success: true, bodyText: 'Eliminar éxito' });
      setFetch(!fetch);
    } else {
      setModal({ show: true, success: false, bodyText: res.data.message });
    }
  };
  return (
    <div className="home-users">
      <hr className="pb-3" />
      <AddButton
        title='Usuarios'
        url='/users/create'
        addTitle='Añadir usuario'
      />
      <div className="flex table-heading">
        <div className="sm:w-full md:w-1/6 text-center">ID</div>
        <div className="sm:w-full md:w-1/6 text-center">Operador</div>
        <div className="sm:w-full md:w-3/6 text-center">Nombre</div>
        <div className="sm:w-full md:w-4/6 text-center">Correo electrónico</div>
        <div className="sm:w-full md:w-2/6 text-center">Rol</div>
        <div className="sm:w-full md:w-3/6 text-center">Acciónes</div>
      </div>
      {onLoadSpinner ? (
        <KuposListLoader />
      ) : (userList || []).length > 0 ? (
        userList
          .sort((x, y) => {
            return y.id - x.id;
          })
          .map((val, i) => renderItem(val, i))
      ) : null}

      {renderViewModal()}

      {renderDeleteModal()}

      <KuposErrorSuccessModal
        showModal={modal.show}
        success={modal.success}
        smallIcon={true}
        bodyText={modal.bodyText}
        onButtonClick={() => {
          setModal(modalInitialState);
        }}
      />
      <Snackbar
        open={snackbar.showSnackbar}
        autoHideDuration={snackbar.persist ? 10000000000 : 5000}
        anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
        onClose={() => setSnackbar({ showSnackbar: false })}
      >
        <Alert onClose={() => setSnackbar({ showSnackbar: false })} severity="error">
          <span>{snackbar.alertMessage}</span>
        </Alert>
      </Snackbar>
    </div >
  )
}


export default UserPage;
