import React, { useState } from 'react';
import TicketSearch from '../Sales/TicketSearch';
import { Alert } from '@material-ui/lab';
import { Snackbar } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import DateService from '../../../services/dateService';
import CommonService from '../../../services/commonService';
import { KuposErrorSuccessModal, KuposInput } from '../../common';
import Buttons from './Buttons';

const DetailsScreen = ({ history }) => {
  const [snackbar, setSnackbar] = useState({});
  const state = useSelector(state => state.home.searchedTicketDetails);
  const [ticketDetails, setTicketDetails] = useState('');
  const [ticketPdf, setTicketPdf] = useState('');
  const [modal, setModal] = useState({});


  useEffect(() => {
    if (state) {
      setTicketPdf(state.pdf_url);
      setTicketDetails(state.ticket_details);
    }
  }, [state]);

  console.log({ ticketDetails });

  const closeSnackbar = () => {
    setSnackbar({ showSnackbar: false });
  };

  const renderNameAndImage = (image, label, value) => {
    return (
      <div className="three-items font10">
        <div>
          <img src={image} />
        </div>
        <div>
          <span className="bold-text">{label}</span>
        </div>
        <div>
          <span> &nbsp;{value}</span>
        </div>
      </div>
    );
  };

  const translatedStatus = status => {
    let translatedStatus = '';
    if (status == 'CANCELLED') {
      translatedStatus = 'Cancelado';
    } else if (status == 'CONFIRMED') {
      translatedStatus = 'Confirmado';
    } else if (status == 'PENDING') {
      translatedStatus = 'Reservado';
    } else {
      translatedStatus = status;
    }
    return translatedStatus;
  };

  const renderTwoItems = (label, value) => {
    return (
      <div className="two-items font10">
        <span className="bold-text label">{label}</span>
        <span>{value}</span>
      </div>
    );
  };

  const getPaymentType = () => {
    let paymentType = ticketDetails && ticketDetails.payment_type;
    let status = ticketDetails && ticketDetails.status;

    let actualPaymentMethod = '';

    if (status == 'PENDING') {
      actualPaymentMethod = 'Pendiente';
    } else {
      if (paymentType === 'CASH') {
        actualPaymentMethod = 'Efectivo';
      } else if (paymentType === 'DEBIT_CARD') {
        actualPaymentMethod = 'Tarjeta Débito';
      } else if (paymentType === 'CREDIT_CARD') {
        actualPaymentMethod = 'Tarjeta Crédito';
      } else if (paymentType === 'BANK_TRANSFER') {
        actualPaymentMethod = 'Trans bancaria';
      } else if (paymentType === 'CREDIT_INVOICE') {
        actualPaymentMethod = 'Factura de Crédito';
      } else {
        actualPaymentMethod = paymentType;
      }
    }

    return actualPaymentMethod;
  };
  return (
    <div className="home-details-page">
      <TicketSearch setSnackbar={setSnackbar} history={history} />

      <div className="details-page-body">
        <div>
          <div>
            {renderNameAndImage(
              '/images/my-trips.svg',
              'PNR: ',
              ticketDetails && ticketDetails.pnr_id,
            )}
          </div>
          <div>
            {renderNameAndImage(
              '/images/source.png',
              'Origen: ',
              ticketDetails && ticketDetails.pickup_address,
            )}
          </div>
          <div>
            {renderNameAndImage(
              '/images/destination.png',
              'Destino: ',
              ticketDetails && ticketDetails.drop_address,
            )}
          </div>
          <div>
            {renderNameAndImage(
              '/images/calender.png',
              'Fecha de viaje: ',
              DateService.changeDateFormat(
                ticketDetails && ticketDetails.trip_ref_date,
                'yyyy-mm-dd',
                'dd/mm/yyyy',
              ),
            )}
          </div>
          <div>
            {renderNameAndImage(
              '/images/clock.png',
              'Hora de recogida: ',
              DateService.ampm(ticketDetails && ticketDetails.trip_ref_time),
            )}
          </div>
          <div>
            {renderNameAndImage(
              '/images/car.png',
              'Servicio: ',
              ticketDetails && ticketDetails.service_type,
            )}
          </div>
          <div>
            {renderNameAndImage(
              '/images/seat.png',
              'Cupos: ',
              ticketDetails && ticketDetails.no_of_seats + ' Pasajeros',
            )}
          </div>
          <div>
            {renderNameAndImage(
              '/images/calender.png',
              'Fecha de venta: ',
              ticketDetails && ticketDetails.booking_date,
            )}
          </div>
          <div>
            {renderNameAndImage(
              '/images/my-trips.svg',
              'Vendido por: ',
              ticketDetails && ticketDetails.user_name,
            )}
          </div>
          <div>
            {renderNameAndImage(
              '/images/info.svg',
              'Estatus: ',
              ticketDetails && translatedStatus(ticketDetails.status),
            )}
          </div>
          <div>
            <KuposInput
              value={ticketDetails && ticketDetails.additional_fare_remark}
              placeholder="Commentario"
              disabled
            />
          </div>
        </div>
        <div className="line-container">
          <div className="seperator"></div>
        </div>

        {ticketDetails ? (
          <div className="final-details-section">
            <div className="two-items-container">
              <div> {renderTwoItems('Nombre: ', ticketDetails && ticketDetails.customer_name)}</div>
              <div>
                {' '}
                {renderTwoItems('RUT/ Documento: ', ticketDetails && ticketDetails.identity_value)}
              </div>
              <div>
                {' '}
                {renderTwoItems('Teléfono: ', ticketDetails && ticketDetails.customer_phone)}
              </div>
              <div>
                {' '}
                {renderTwoItems(
                  'Correo eléctronico: ',
                  ticketDetails && ticketDetails.customer_email,
                )}
              </div>
            </div>
            <div className="seperator-bottom-container">
              <div className="seperator-bottom"></div>
            </div>
            <div className="two-items-container">
              <div> {renderTwoItems('Medio de pago: ', getPaymentType())}</div>

              <div>
                {renderTwoItems(
                  'Monto total: ',
                  ticketDetails && 'CLP' + CommonService.currency(ticketDetails.total_amount),
                )}
              </div>

              <div>
                {' '}
                {renderTwoItems(
                  'Tarifa adicional: ',
                  ticketDetails && 'CLP' + CommonService.currency(ticketDetails.additional_fare),
                )}
              </div>

              <div>
                {renderTwoItems(
                  'Monto neto: ',
                  ticketDetails && 'CLP' + CommonService.currency(ticketDetails.net_amount),
                  // 'CLP' + ticketDetails.price_type === 'PER_PERSON'
                  // ? CommonService.currency(
                  //     ticketDetails.price * ticketDetails.no_of_seats -
                  //       ticketDetails.discount_amount,
                  //   )
                  // : CommonService.currency(
                  //     ticketDetails.price - ticketDetails.discount_amount,
                  //   ),
                )}
              </div>
              <div>
                {renderTwoItems(
                  'Descuento total: ',
                  (ticketDetails && CommonService.currency(ticketDetails.discount_amount)) ||
                    'CLP0',
                )}
              </div>

              <div>
                {renderTwoItems(
                  'Cargos de cancelación: ',
                  ticketDetails &&
                    'CLP' + CommonService.currency(ticketDetails.cancellation_charge),
                )}
              </div>

              <div>
                {renderTwoItems(
                  'Monto de reembolso: ',
                  ticketDetails && 'CLP' + CommonService.currency(ticketDetails.refund_amount),
                )}
              </div>
            </div>
          </div>
        ) : null}
      </div>

      <Buttons
        history={history}
        ticketDetails={ticketDetails}
        setModal={setModal}
        setSnackbar={setSnackbar}
      />

      <Snackbar
        open={snackbar.showSnackbar}
        autoHideDuration={snackbar.persist ? 10000000000 : 5000}
        anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
        onClose={closeSnackbar}
      >
        <Alert onClose={closeSnackbar} severity="error">
          {snackbar.alertMessage}
        </Alert>
      </Snackbar>

      <KuposErrorSuccessModal
        success={modal.success}
        showModal={modal.show}
        onButtonClick={() => {
          if (modal.printTicket) {
            modal.printTicket &&
              CommonService.printCancelTicket(document.getElementById('print-ticket').innerHTML);
          }
          setModal({});
          // modal.success && history.push('/sales-page');
        }}
        bodyText={modal.bodyText}
      />
    </div>
  );
};

export default DetailsScreen;
