import React, { Component } from 'react';
import PropTypes from 'prop-types';
// import moment from 'moment';
// import MonthPickerInput from 'react-month-picker-input';
import ReactDOM from 'react-dom';

import i18next from 'i18next';
import SvgHome from './SvgHome';
import DateService from '../../services/dateService';

const monthNameEn = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];
const monthNameSp = [
  'Ene',
  'Feb',
  'Mar',
  'Abr',
  'May',
  'Jun',
  'Jul',
  'Ago',
  'Sep',
  'Oct',
  'Nov',
  'Dic',
];

export default class MonthYearPicker extends Component {
  static propTypes = {
    selectedYear: PropTypes.number,
    selectedMonth: PropTypes.number,
    onMonthChange: PropTypes.func,
  };
  months = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];
  constructor(props) {
    super(props);
    this.state = {
      selectedYear: this.props.selectedYear || new Date().getFullYear(),
    };
  }

  componentDidMount() {
    this.setState({ selectedYear: this.props.selectedYear || new Date().getFullYear() });
    document.addEventListener('click', this.handleClickOutside, true);
  }
  componentWillUnmount() {
    document.removeEventListener('click', this.handleClickOutside, true);
  }

  handleClickOutside = event => {
    const domNode = ReactDOM.findDOMNode(this);

    if (!domNode || !domNode.contains(event.target)) {
      // console.log('clicked outside',event)
      this.setState({ showCalendar: false });
    }
  };

  getMonthName = i => {
    // return i18next.language == 'es' ? monthNameSp[i] : monthNameEn[i];
    return monthNameSp[i];
  };

  onMonthChange = selectedMonth => {
    let maskedValue = this.getMonthName(selectedMonth) + ' ' + this.state.selectedYear;
    // setTimeout(() => {
      this.props.onMonthChange(maskedValue, this.state.selectedYear, selectedMonth);
    // }, 200);
    this.setState({ showCalendar: false });
  };

  prevYear = () => {
    this.setState({
      selectedYear: +this.state.selectedYear - 1,
    });
  };
  nextYear = () => {
    this.setState({
      selectedYear: +this.state.selectedYear + 1,
    });
  };

  renderMonthCalendar = () => {
    return (
      <div className={'common-kupos-month-picker font9 ' + (this.props.error ? ' error' : '')}>
        {/* <MonthPickerInput
            year={this.props.selectedYear}
            month={this.props.selectedMonth}
            closeOnSelect={true}
            mode={"calendarOnly"}
            onChange={this.props.onMonthChange}
        /> */}
        <div className="month-calendar kupos-card">
          <div className="month-label font10">
            <div
              className={'unit prev ' + (this.state.hidePreviosMonth ? 'transparent' : '')}
              onClick={this.prevYear}
            >
              {/* <img src="/images/icons/icon-arrow-left.png" /> */}
              <SvgHome name="arrow-whight-left" />
            </div>
            <div className="monyear uppercase">{this.state.selectedYear}</div>
            <div className="unit next" onClick={this.nextYear}>
              <SvgHome name="arrow-whight-right" />
              {/* <img src="/images/icons/icon-arrow-right.png" /> */}
            </div>
          </div>
          <div className="calendar-container font10 light-text">
            {this.months.map((val, i) => (
              <div className={"col_mp span_1_of_3_mp " + (this.props.selectedMonth == i ? 'selected_cell' : '')} key={i} onClick={() => this.onMonthChange(i)}>
                {this.getMonthName(i)}
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  };

  render() {
    return (
      <div
        className="month-calendar-capsule  font10"
      >
        <div className="click-container"
        onClick={() => this.setState({showCalendar:!this.state.showCalendar})}>
        <span>
          {DateService.getMonthName(this.props.selectedMonth)}  {this.props.selectedYear}
        </span>
        <span className="flex-right dropdown-movement">
          <img src="/images/icons/home/icon_arrow_down_white.png" alt="" />
        </span>
        </div>
        {this.state.showCalendar ? this.renderMonthCalendar() : null}
      </div>
    );
  }
}
