import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import ApiUrls from '../../services/apiUrls';
import { DropdownComponent, KuposButton, KuposErrorSuccessModal, KuposInput } from '../common';
import { apiCall } from './redux/apiCall';

const AddCounter = ({ history, match }) => {
  const dispatch = useDispatch();
  const [modal, setModal] = useState({});
  const [branches, setBranches] = useState([]);
  const [errors, setErrors] = useState({});
  const [values, setValues] = useState({});
  const [branch, setBranch] = useState('');

  const id = match.params ? Number(match.params.id) : null;
  console.log({ values, id });

  useEffect(() => {
    getBranches();
    if (id) {
      getList();
    }
    // eslint-disable-next-line
  }, [id]);

  const getBranches = () => {
    dispatch(
      apiCall({
        url: ApiUrls.addBranch(),
        callback: res => {
          if (res.data && res.data.success) {
            setBranches(
              res.data.data.map(branch => {
                return { value: branch.id, label: branch.name };
              }),
            );
          }
        },
      }),
    );
  };

  const getList = () => {
    dispatch(
      apiCall({
        url: ApiUrls.allCounters(),
        callback: res => {
          console.log({ res });
          if (res.data && res.data.success) {
            const found = res.data.data.find(f => f.id === id);
            console.log({ found });
            if (found) {
              setValues({ ...found, Remark: found.remark });
              setBranch({ label: found.branch_name, value: found.branch_id });
            }
          }
        },
      }),
    );
  };

  const handleChange = (val, type) => {
    setValues({ ...values, [type]: val });
    setErrors({ ...values, [type + 'Error']: '' });
  };

  const validate = () => {
    if (!values.name) {
      setErrors({ nameError: 'Ingrese el nombre del caja' });
      return false;
    }
    if (!branch) {
      setErrors({ branchError: 'Seleccionar rama' });
      return false;
    }
    if (!values.Remark) {
      setErrors({ RemarkError: 'Ingrese el comentario del caja' });
      return false;
    }
    return true;
  };

  const createOrUpdate = () => {
    if (!validate()) {
      return;
    }

    let method = 'POST';
    let data = {};
    if (id) {
      method = 'PUT';
      data = values;
      data.branch_id = branch.value;
      data.branch_name = branch.label;
    } else {
      data = values;
      data.branch_id = branch.value;
      data.branch_name = branch.label;
    }

    console.log({ data });

    dispatch(
      apiCall({
        url: ApiUrls.addCounter(),
        method,
        data,
        callback: res => onUpdateResponse(res),
      }),
    );
  };

  const onUpdateResponse = res => {
    if (res.data && res.data.success && res.data.data) {
      setModal({
        show: true,
        success: true,
        redirect: true,
        bodyText: 'Éxito' || (id ? 'Éxito' : 'Éxito'),
      });
    } else if (!res.data.success && res.data.message) {
      setModal({ show: true, success: false, bodyText: res.data.message });
    } else {
      setModal({ show: true, success: false, bodyText: 'Fallar' });
    }
  };
  return (
    <div className="home-add-counter pr-3 pl-3">
      <h2 className="mt-4 mb-4 font-bold">{id ? 'Crear caja' : 'Crear nuevo caja'}</h2>
      <div className="common-kupos-input-box" style={{ marginTop: 10 }}>
        <KuposInput
          type="text"
          className="kupos-border"
          placeholder={'Nombre'}
          label={'Nombre'}
          disabled={id ? true : false}
          value={values.name}
          error={errors.nameError ? true : false}
          errorMessage={errors.nameError ? errors.nameError : ''}
          onChange={text => handleChange(text, 'name')}
        />
      </div>

      <div style={{ marginTop: 10 }}>
        <div>
          <span className="input-label">Sucrusal</span>
        </div>
        <DropdownComponent
          onChange={val => {
            setBranch(val);
            setErrors({});
          }}
          placeholder={'Sucrusal'}
          value={branch}
          options={branches}
          error={errors.branchError ? true : false}
          errorMessage={errors.branchError ? errors.branchError : ''}
        />
      </div>

      <div className="common-kupos-input-box" style={{ marginTop: 20 }}>
        <KuposInput
          type="text"
          className="kupos-border"
          placeholder={'Observaciones'}
          label={'Observaciones'}
          value={values.Remark}
          error={errors.RemarkError ? true : false}
          errorMessage={errors.RemarkError ? errors.RemarkError : ''}
          onChange={text => handleChange(text, 'Remark')}
        />
      </div>
      <div className="button-container" style={{ marginTop: 20, marginBottom: 40 }}>
        <KuposButton onClick={createOrUpdate}>
          <span className="font11">{id ? 'Actualizar contador' : 'Crear contador'}</span>
        </KuposButton>
      </div>
      <KuposErrorSuccessModal
        success={modal.success}
        showModal={modal.show}
        onButtonClick={() => {
          setModal({});
          if (modal.redirect) {
            history.goBack();
          }
        }}
        bodyText={modal.bodyText}
      />
    </div>
  );
};

export default AddCounter;
