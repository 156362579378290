import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import AppData from '../../../services/appData';
import { KuposButton, KuposInput } from '../../common';
import { apiCall } from '../redux/apiCall';
import { setSearchedTicketDetails } from '../redux/setSearchedTicketDetails';

const TicketSearch = ({ setSnackbar, history, reloadPage, navigate }) => {
  const [values, setValues] = useState({ pnr: '', pnrError: '' });
  const dispatch = useDispatch();

  const getTicketDetails = () => {
    setValues({ ...values, pnrError: '' });
    if (!values.pnr) {
      setValues({ pnrError: 'Ingrese un PNR' });
      setSnackbar({
        showSnackbar: true,
        alertMessage: 'Ingrese un PNR',
      });
      return;
    }

    // Check this later
    // this.setState({ searchScreen: false });

    dispatch(
      apiCall({
        url: AppData.BASE_URL + `ticketDetails/${values.pnr}`,
        callback: res => onTicketDetailsFetch(res),
      }),
    );
  };

  const onTicketDetailsFetch = res => {
    if (res && res.data && res.data.success && res.data.data) {
      dispatch(setSearchedTicketDetails(res.data.data));
      if (navigate) {
        history.push('/details-page');
      } else {
        //   do some when on Details page later
      }
    } else {
      setSnackbar({
        showSnackbar: true,
        alertMessage: 'PNR inválido, por favor verifique el PNR e intente nuevamente',
      });
      setValues({ ...values, pnrError: 'PNR inválido' });
    }
    console.log('onTicketDetailsFetch--------', res);
  };

  const clickOnVenderPasaje = () => {
    if (reloadPage) {
      window.location.reload();
    } else {
      history.push('/sales-page');
    }
  };

  const onPnrChange = val => {
    setValues({ pnr: val, pnrError: '' });
  };
  const onBlur = val => {};

  return (
    <div>
      <hr className="pb-3" />
      <div className="search-customer-section">
        <div
          className="vendor-pasaje"
          style={{ marginTop: 2 }}
          onClick={() => clickOnVenderPasaje()}
        >
          <span className="vendor-icon">
            <img src="/images/my-trips.png" />
          </span>
          <span className="vendor-text font12">VENDER PASAJE</span>
        </div>

        <div className="common-kupos-input-box document-search" style={{ marginLeft: 10 }}>
          <KuposInput
            type="email"
            className="kupos-border"
            placeholder={'RUT / Nº reserva / Correo'}
            value={values.pnr}
            error={values.pnrError ? true : false}
            errorMessage={values.pnrError ? values.pnrError : ''}
            onChange={val => onPnrChange(val)}
            onBlur={text => onBlur(text, 'pnr')}
          />
        </div>

        <div className="doc-search-button font12">
          <KuposButton onClick={getTicketDetails} className="doc-search-button-inner">
            <span className="vendor-icon">
              <img src="../../../images/ticket-search.png" />
            </span>
          </KuposButton>
        </div>
      </div>
    </div>
  );
};

export default TicketSearch;
