import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { DropdownComponent } from '../common/DropdownComponent';
import { KuposButton, KuposErrorSuccessModal, KuposInput } from '../common';
import ApiUrls from '../../services/apiUrls';
import DateService from '../../services/dateService';
import { apiCall } from './redux/apiCall';
import Loader from 'react-loader-spinner';
import { Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';



const modalInitialState = {
  show: false,
  success: false,
  bodyText: null,
};
const initialState = {
  locationName: '',
  selectedLocationType: { value: 'BUS_TERMINAL', label: 'Bus Terminal' },
}
const locationTypes = [
  { value: 'BUS_TERMINAL', label: 'Bus Terminal' },
  { value: 'AIRPORT_TERMINAL', label: 'Airport Terminal' },
  { value: 'GENERIC_LOCATION', label: 'Generic Location' },
];

const AddPlatformLocation = ({ match, history }) => {
  const dispatch = useDispatch();
  const [values, setValues] = useState(initialState);
  const [modal, setModal] = useState(modalInitialState);
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [terminals, setTerminals] = useState([]);
  const [loader, setLoader] = useState(true);
  const [snackbar, setSnackbar] = useState({});
  const [count, setCount] = useState('')
  const id = match.params ? Number(match.params.id) : null;


  console.log(countries, states, cities, terminals)
  console.log(values)

  useEffect(() => {
    if (id) {
      getList();
    } else {
      setLoader(false)
    }
    // eslint-disable-next-line
  }, [id])


  useEffect(() => {
    getCountriesList();
    getStatesList();
    getCitiesList();
    getAtTerminalList();
    // eslint-disable-next-line
  }, [])


  // redirecting back if data not found using timer
  useEffect(() => {
    let interval
    if (count) {
      interval = setInterval(() => {
        setCount((currentCount) => --currentCount)
      }, 1000);
    }
    // Redirect once the count is === 1
    count === 1 && history.push('/platform-location');
    // CleanUp
    return () => clearInterval(interval)
  }, [count, history])

  const getCountriesList = () => dispatch(apiCall({ url: ApiUrls.addCountry(), callback: res => getCountriesListResponse(res) }))
  const getCountriesListResponse = (res) => {
    // console.log('+++ Result from all countries api', res);
    if (res && res.data && res.data.success) {
      let countries = [];
      res.data.data.map(country => {
        countries.push({ value: country.id, label: country.name });
      });
      setCountries(countries)
    } else if (res && res.data && !res.data.success) {
      setSnackbar({
        showSnackbar: true,
        persist: true,
        alertMessage: res.data.message ? res.data.message : 'Failure'
      });
    } else {
      setSnackbar({
        showSnackbar: true,
        persist: true,
        alertMessage: 'Check Your Internet Connection'
      });
    }
  };



  const getStatesList = () => dispatch(apiCall({ url: ApiUrls.addState(), callback: res => getStatesListResponse(res) }));
  const getStatesListResponse = (res) => {
    // console.log('+++ Result from all countries api', res);
    if (res && res.data && res.data.success) {
      let states = [];
      res.data.data.map(state => {
        states.push({ value: state.id, label: state.name });
      });
      setStates(states)
    } else if (res && res.data && !res.data.success) {
      setSnackbar({
        showSnackbar: true,
        persist: true,
        alertMessage: res.data.message ? res.data.message : 'Failure'
      });
    } else {
      setSnackbar({
        showSnackbar: true,
        persist: true,
        alertMessage: 'Check Your Internet Connection'
      });
    }
  };

  const getCitiesList = () => dispatch(apiCall({ url: ApiUrls.addCity(), callback: res => getCitiesListResponse(res) }))
  const getCitiesListResponse = (res) => {
    // console.log('+++ Result from all countries api', res);
    if (res && res.data && res.data.success) {
      let cities = [];
      res.data.data.map(city => {
        cities.push({ value: city.id, label: city.name });
      });
      setCities(cities)
    } else if (res && res.data && !res.data.success) {
      setSnackbar({
        showSnackbar: true,
        persist: true,
        alertMessage: res.data.message ? res.data.message : 'Failure'
      });
    } else {
      setSnackbar({
        showSnackbar: true,
        persist: true,
        alertMessage: 'Check Your Internet Connection'
      });
    }
  };

  const getAtTerminalList = () => dispatch(apiCall({ url: ApiUrls.getAtTerminalList(), callback: res => getAtTerminalListResponse(res) }))
  const getAtTerminalListResponse = (res) => {
    // console.log('+++ Result from all countries api', res);
    if (res && res.data && res.data.success) {
      let atTerminals = [];
      res.data.data.map(atTerminal => {
        atTerminals.push({ value: atTerminal.airport_address, label: atTerminal.airport_name });
      });
      setTerminals(atTerminals)
    } else if (res && res.data && !res.data.success) {
      setSnackbar({
        showSnackbar: true,
        persist: true,
        alertMessage: res.data.message ? res.data.message : 'Failure'
      });
    } else {
      setSnackbar({
        showSnackbar: true,
        persist: true,
        alertMessage: 'Check Your Internet Connection'
      });
    }
  };



  const getList = () => dispatch(apiCall({ url: ApiUrls.getLocationList(), callback: res => getListResponse(res) }));
  const getListResponse = (res) => {
    setLoader(false)
    // console.log('+++ Result from all countries api', res);
    if (res && res.data && res.data.success) {
      const found = res.data.data.find(f => f.id === id);
      if (found) {
        console.log({ found });
        getParticularItem(found);
      } else {
        setSnackbar({
          showSnackbar: true,
          persist: true,
          alertMessage: 'Record not found'
        });
        setCount(5)
      }
    } else if (res && res.data && !res.data.success) {
      setSnackbar({
        showSnackbar: true,
        persist: true,
        alertMessage: res.data.message ? res.data.message : 'Failure'
      });
    } else {
      setSnackbar({
        showSnackbar: true,
        persist: true,
        alertMessage: 'Check Your Internet Connection'
      });
    }
  };

  const getParticularItem = (element) => {
    setValues({
      locationToEdit: element,
      locationName: element.name,
      selectedLocationType: { value: element.type, label: element.type },
      selectedCountry: { value: element.country_name, label: element.country_name },
      selectedState: { value: element.Sate_name, label: element.Sate_name },
      selectedCity: { value: element.city_name, label: element.city_name },
      address: element.address,
      locationRemark: element.remark,
    })
  };


  const onSelectChange = (val, type) => {
    switch (type) {
      case 'countryName':
        setValues({ ...values, selectedCountry: val, selectedCountryError: null });
        getStatesByCountryID(val.value);
        break;
      case 'stateName':
        setValues({ ...values, selectedState: val, selectedStateError: null });
        getCitiesByStateID(val.value);
        break;
      case 'cityName':
        setValues({ ...values, selectedCity: val, selectedCityError: null });
        break;
      case 'locationType':
        setValues({ ...values, selectedLocationType: val, selectedLocationTypeError: null });
        break;
    }
  };

  const getStatesByCountryID = id => {
    dispatch(apiCall({
      url: ApiUrls.addState(id), callback: res => {
        if (res && res.data && res.data.success) {
          let states = [];
          res.data.data.map(state => {
            states.push({ value: state.id, label: state.name });
          });
          setStates(states)
        } else if (res && res.data && !res.data.success) {
          setSnackbar({
            showSnackbar: true,
            persist: true,
            alertMessage: res.data.message ? res.data.message : 'Failure'
          });
        } else {
          setSnackbar({
            showSnackbar: true,
            persist: true,
            alertMessage: 'Check Your Internet Connection'
          });
        }
      }
    }))
  };


  const getCitiesByStateID = id => {
    dispatch(apiCall({
      url: ApiUrls.addCity(id), callback: res => {
        if (res && res.data && res.data.success) {
          let cities = [];
          res.data.data.map(city => {
            cities.push({ value: city.id, label: city.name });
          });
          setCities(cities)
        } else if (res && res.data && !res.data.success) {
          setSnackbar({
            showSnackbar: true,
            persist: true,
            alertMessage: res.data.message ? res.data.message : 'Failure'
          });
        } else {
          setSnackbar({
            showSnackbar: true,
            persist: true,
            alertMessage: 'Check Your Internet Connection'
          });
        }
      }
    }))

  };

  const onChange = (val, type) => {
    switch (type) {
      case 'locationName':
        values.selectedLocationType &&
          values.selectedLocationType.value == 'AIRPORT_TERMINAL'
          ? setValues({
            ...values,
            locationName: val,
            locationNameError: null,
            address: val.value,
            addressError: null,
          })
          : setValues({ ...values, locationName: val, locationNameError: null });
        break;
      case 'address':
        setValues({ ...values, address: val, addressError: null });
        break;
      case 'locationRemark':
        setValues({ ...values, locationRemark: val, locationRemarkError: null });
        break;
    }
  };

  const onBlur = (val, type) => { };

  const validate = () => {
    if (!values.selectedLocationType) {
      setValues({ ...values, selectedLocationTypeError: 'select a location type' });
      return false;
    } else if (!values.locationName) {
      setValues({ ...values, locationNameError: 'Ingrese el nombre de la ubicación' });
      return false;
    } else if (!values.selectedCountry) {
      setValues({ ...values, selectedCountryError: 'select a country' });
      return false;
    } else if (!values.selectedState) {
      setValues({ ...values, selectedStateError: 'select a state' });
      return false;
    } else if (!values.selectedCity) {
      setValues({ ...values, selectedCityError: 'select a city' });
      return false;
    } else if (!values.locationRemark) {
      setValues({ ...values, locationRemarkError: 'Ingrese el comentario de ubicación' });
      return false;
    } else if (!values.address) {
      if (
        values.selectedLocationType.value == 'AIRPORT_TERMINAL' ||
        values.selectedLocationType.value == 'BUS_TERMINAL'
      ) {
        setValues({ ...values, addressError: 'Address can not be empty' });
        return false;
      } else if (values.selectedLocationType.value == 'GENERIC_LOCATION') {
        setValues({ ...values, addressError: null });
      }
      //  this.setState({ addressError: 'Address can not be empty'  });
      // return false;
    }

    return true;
  };

  const createOrUpdate = () => {
    if (!validate()) {
      return;
    }

    let data, method = 'POST';

    if (id) {
      method = 'PUT';
      data = {
        id: values.locationToEdit.id,
        client_code: values.locationToEdit.client_code,
        type: values.locationToEdit.type,
        name: values.locationToEdit.name,
        city_id: values.locationToEdit.city_id,
        city_name: values.locationToEdit.city_name,
        state_id: values.locationToEdit.state_id,
        Sate_name: values.locationToEdit.Sate_name,
        country_id: values.locationToEdit.country_id,
        country_name: values.locationToEdit.country_name,
        address: values.address,
        status: values.locationToEdit.status,
        remark: values.locationRemark,
        created_on_utc: values.locationToEdit.created_on,
        created_by_user: values.locationToEdit.created_by,
        created_on_date_time_stg: values.locationToEdit.created_on_date_time_stg,
        last_updated_by_user: '',
        last_updated_on_utc: DateService.getCurrentTimeUTC(),
        last_updated_on_date_time_stg: DateService.getCurrentStgDate(),
        deleted_by: '',
        deleted_on_utc: 0,
        deleted_on_date_time_stg: '',
      }
    } else {
      data = {
        type: values.selectedLocationType.label,
        name: values.locationName,
        city_id: values.selectedCity.value,
        city_name: values.selectedCity.label,
        state_id: values.selectedState.value,
        Sate_name: values.selectedState.label,
        country_id: values.selectedCountry.value,
        country_name: values.selectedCountry.label,
        address: values.address,
        remark: values.locationRemark,
        created_on_utc: DateService.getCurrentTimeUTC(),
        created_on_date_time_stg: DateService.getCurrentStgDate(),
        client_code: "KUPOS"
      }
    }
    console.log('+++data to update', data);
    setLoader(true)
    dispatch(
      apiCall({
        url: ApiUrls.addLocation(),
        method,
        data,
        callback: res => onAddLocationResponse(res),
      }),
    );
  };


  const onAddLocationResponse = res => {
    setLoader(false)
    if (res.data && res.data.success && res.data.data) {
      setModal({
        show: true,
        success: true,
        redirect: true,
        bodyText: 'Éxito' || (id ? 'Éxito' : 'Éxito'),
      });
    } else if (!res.data.success && res.data.message) {
      setModal({ show: true, success: false, bodyText: res.data.message });
    } else {
      setModal({ show: true, success: false, bodyText: 'Fail' });
    }
  };

  return (
    <div className="home-add-location">
      <div style={{ marginTop: 10 }}>
        <DropdownComponent
          onChange={val => onSelectChange(val, 'locationType')}
          placeholder={'Tipo de locación '}
          options={locationTypes}
          value={values.selectedLocationType}
          disabled={id ? true : false}
          error={values.selectedLocationTypeError ? true : false}
          errorMessage={
            values.selectedLocationTypeError
              ? values.selectedLocationTypeError
              : ''
          }
        />
      </div>

      {/* {this.state.selectedLocationType &&
        this.state.selectedLocationType.value == 'AIRPORT_TERMINAL' ? (
          <div style={{ marginTop: 10 }}>
            <DropdownComponent
              onChange={val =>onChange(val, 'locationName')}
              placeholder={'Nombre del lugar 1'}
              options={this.state.selectedAtTerminals}
              value={this.state.locationName}
              disabled={id ? true : false}
              error={this.state.locationNameError ? true : false}
              errorMessage={
                this.state.locationNameError ? this.state.locationNameError : ''
              }
            />
          </div>
        ) : ( */}
      <div className="common-kupos-input-box" style={{ marginTop: 10 }}>
        <KuposInput
          type="text"
          className="kupos-border"
          placeholder={'Nombre del lugar'}
          value={values.locationName}
          disabled={id ? true : false}
          error={values.locationNameError ? true : false}
          errorMessage={
            values.locationNameError ? values.locationNameError : ''
          }
          onChange={text => onChange(text, 'locationName')}
          onBlur={text => onBlur(text, 'locationName')}
        />
      </div>
      {/* )} */}

      <div
        className="home-selection-input-box font10 select-box"
        style={{ marginTop: 10, marginBottom: 10 }}
      >
        <DropdownComponent
          onChange={val => onSelectChange(val, 'countryName')}
          placeholder={'Seleccione el país'}
          disabled={id ? true : false}
          options={countries}
          value={values.selectedCountry}
          error={values.selectedCountryError ? true : false}
          errorMessage={values.selectedCountryError ? values.selectedCountryError : null}
        />
      </div>
      <div className="home-selection-input-box font10 select-box" style={{ marginTop: 10 }}>
        <DropdownComponent
          onChange={val => onSelectChange(val, 'stateName')}
          placeholder={'Seleccione la región'}
          options={states}
          value={values.selectedState}
          disabled={id ? true : false}
          error={values.selectedStateError ? true : false}
          errorMessage={values.selectedStateError ? values.selectedStateError : ''}
        />
      </div>
      <div
        className="home-selection-input-box font10 select-box"
        style={{ marginTop: 10, marginBottom: 10 }}
      >
        <DropdownComponent
          onChange={val => onSelectChange(val, 'cityName')}
          placeholder={'Seleccione la ciudad'}
          options={cities}
          value={values.selectedCity}
          disabled={id ? true : false}
          error={values.selectedCityError ? true : false}
          errorMessage={values.selectedCityError ? values.selectedCityError : ''}
        />
      </div>

      <div className="common-kupos-input-box" style={{ marginTop: 10 }}>
        <KuposInput
          type="text"
          className="kupos-border"
          placeholder={'Dirección'}
          value={values.address}
          // value={
          //   this.state.selectedLocationType &&
          //     this.state.selectedLocationType.value == 'AIRPORT_TERMINAL' &&
          //     this.state.locationName
          //     ? this.state.locationName.value
          //     : this.state.address
          // }
          error={values.addressError ? true : false}
          errorMessage={values.addressError ? values.addressError : ''}
          onChange={text => onChange(text, 'address')}
          onBlur={text => onBlur(text, 'address')}
        />
      </div>

      <div className="common-kupos-input-box" style={{ marginTop: 10 }}>
        <KuposInput
          type="text"
          className="kupos-border"
          placeholder={'Observación de ubicación'}
          value={values.locationRemark}
          error={values.locationRemarkError ? true : false}
          errorMessage={values.locationRemarkError ? values.locationRemarkError : ''}
          onChange={text => onChange(text, 'locationRemark')}
          onBlur={text => onBlur(text, 'locationRemark')}
        />
      </div>

      <div className="button-container" style={{ marginTop: 20 }}>
        <KuposButton onClick={createOrUpdate}>
          <span className="font11">
            {id ? 'Actualizar ubicación' : 'Crear ubicación'}
          </span>
        </KuposButton>
      </div>

      <KuposErrorSuccessModal
        success={modal.success}
        showModal={modal.show}
        onButtonClick={() => {
          setModal(modalInitialState);
          if (modal.redirect) {
            history.goBack();
          }
        }}
        bodyText={modal.bodyText}
      />

      <Snackbar
        open={snackbar.showSnackbar}
        autoHideDuration={snackbar.persist ? 10000000000 : 5000}
        anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
        onClose={() => setSnackbar({ showSnackbar: false })}
      >
        <Alert onClose={() => setSnackbar({ showSnackbar: false })} severity="error">
          <span>{snackbar.alertMessage} </span>
          {count
            &&
            `we are redirecting you back in ${count} seconds`
          }
        </Alert>
      </Snackbar>
      {loader ?
        <div className="loader-container">
          <Loader visible={loader} type="Bars" color="#637290" height={80} width={80} />
        </div> : null
      }
    </div>
  );
}


export default AddPlatformLocation;
