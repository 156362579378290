import React, { Component } from 'react';
// import ModernDatepicker from 'react-modern-datepicker';
// import DatePicker from 'react-datepicker';
import PropTypes from 'prop-types';
// import moment from 'moment';
import ReactDOM from 'react-dom';

import KuposCalendar from './KuposCalendar';
import SvgHome from './SvgHome';
import CommonService from '../../services/commonService';
import DateService from '../../services/dateService';

export default class KuposDatePicker extends Component {
  static propTypes = {
    selectedDate: PropTypes.string,
    error: PropTypes.bool,
    errorMessage: PropTypes.string,
    minDate: PropTypes.string,
    onDateChange: PropTypes.func,
    placeholder: PropTypes.string,
    deactivatePastDate: PropTypes.bool,
    deactivateFutureDate: PropTypes.bool,
    canChangeYear: PropTypes.bool,
    style: PropTypes.object,
    canEraseDate: PropTypes.bool,
    t: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.state = {
      showCalendar: false,
    };
  }

  componentDidMount() {
    document.addEventListener('click', this.handleClickOutside, true);
  }

  scrollIfNotInView = () => {
    if (this.el) {
      if (CommonService.isElementInView(this.el)) {
        // console.log('In the viewport!');
      } else {
        this.el.scrollIntoView(false);
      }
    }
  };

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClickOutside, true);
  }

  handleClickOutside = event => {
    const domNode = ReactDOM.findDOMNode(this);

    if (!domNode || !domNode.contains(event.target)) {
      // console.log('clicked outside',event)
      this.setState({ showCalendar: false });
    }
  };

  onFocus = () => {
    !this.props.disabled && this.setState({ showCalendar: true });
    this.props.onFocus && this.props.onFocus();
    setTimeout(() => {
      this.scrollIfNotInView();
    }, 250);
  };

  onBlur = () => {};

  onDateChange = d => {
    // if (d) {
    this.props.onDateChange(d);
    this.setState({ showCalendar: false });
    // }
  };

  render() {
    // const selected = moment('2019-12-27').toDate()
    // console.log(this.props);
    return (
      <div
        className={
          'common-kupos-date-picker ' +
          (this.props.error ? ' error' : '') +
          (this.props.newStyle ? this.props.newStyle : '')
        }
        style={this.props.style}
      >
        <div className="date-inner">
          {/* <img src="/images/icons/home/icon_calendar.png" /> */}
          <SvgHome name="calendar" />
          <input
            type="text"
            onFocus={this.onFocus}
            onBlur={this.onBlur}
            value={this.props.optional ? 'Opcional' : this.props.selectedDate}
            readOnly
            className="date-picker-input"
            placeholder={this.props.optional ? 'Opcional' : this.props.placeholder}
            style={this.props.optional ? { backgroundColor: '#ccc' } : {}}
          />

          {/* <ModernDatepicker
            date={this.props.selectedDate} 
            format={'DD-MM-YYYY'} 
            showBorder        
            onChange={(date) => this.props.onDateChange(date)}
            placeholder={this.props.placeholder}
            minDate={this.props.minDate}
            lang="es"
          /> */}
          {/* <DatePicker
            selected={selected}
            onChange={date => this.props.onDateChange(date)}
            dateFormat="dd-mm-yyyy"
            locale="es-CL"
          /> */}
        </div>
        {this.state.showCalendar ? (
          <div
            className="date-picker-calendar"
            ref={el => {
              this.el = el;
            }}
          >
            <KuposCalendar
              firstDayOfWeek="mo"
              sunHighlight={true}
              minDateSelection={this.props.minDate}
              deactivateFutureDate={this.props.deactivateFutureDate}
              // deactivatePastDate={this.props.deactivatePastDate}
              canChangeYear={this.props.canChangeYear}
              dateSelected={this.props.selectedDate || DateService.getTodayString('dd-mm-yyyy')}
              onDateSelect={date => this.onDateChange(date)}
              onMonthChange={d => {
                // console.log(d);
              }}
              canEraseDate={this.props.canEraseDate}
            />
          </div>
        ) : null}
        {this.props.error ? (
          <span className="error-message font9">{this.props.errorMessage}</span>
        ) : // <span className="error font9">{this.props.t(this.props.errorMessage)}</span>
        null}
      </div>
    );
  }
}
