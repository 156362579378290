import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actions from './redux/actions';
import { KuposButton, KuposCheckbox, KuposDatePicker, KuposSelectBox, KuposSteps } from '../common';
import DateService from '../../services/dateService';
import ApiUrls from '../../services/apiUrls';
// import Menu from 'react-select/src/components/Menu';
import CommonService from '../../services/commonService';
import Table from 'react-bootstrap/Table';
import Loader from 'react-loader-spinner';
import { apiCall } from './redux/apiCall';

var global = window;

const selectToFrom = [
  { value: 'ALL', label: 'Todos' },
  { value: 'TO_AIRPORT', label: 'Hacia aeropuerto' },
  { value: 'FROM_AIRPORT', label: 'Desde aeropuerto' },
];

const selectServiceOptions = [
  { value: 'TRAVEL_DATE', label: 'Fecha de viaje' },
  { value: 'BOOKING_DATE', label: 'Fecha de emisión' },
];

const typeOfReport = [{ value: 'FULL_REPORT', label: 'Reporte de ventas' }];

const initialState = {
  menuButtons: [
    { label: 'Todos los servicios', active: true, key: 0 },
    { label: 'Al aeropuerto', active: false, key: 1 },
    { label: 'Desde aeropuerto', active: false, key: 2 },
  ],
  transferServiceError: true,
  identityVal: null,
  bookingRequests: null,
  drivers: null,
  vehicles: null,
  tripStep: 1,
  filteredBookings: null,
  fromDate: DateService.getTodayString(),
  toDate: DateService.getTodayString(),
  typeChange: 'TRAVEL_DATE',
  directionChange: 'ALL',
  dataToShow: [],
  showLoader: false,
  typeOfReport: 'Full detailed report',
  showHeader: false,
  disableDownloadButton: true,
};

const modalInitialState = {
  show: false,
  success: false,
  bodyText: null,
};

const errorInitialState = {};

const ReportsNew = ({ history, match }) => {
  const dispatch = useDispatch();
  const [values, setValues] = useState(initialState);
  const [modal, setModal] = useState(modalInitialState);
  const [bookingRequests, setBookingRequests] = useState([]);
  const [filteredBookings, setFilteredBookings] = useState([]);
  const [errors, setErrors] = useState(errorInitialState);
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    if (!CommonService.authToken) {
      history.push('/login');
    }
  });

  // const getBookings = () => {
  //   let fromUnixTime = CommonService.getTimeInSeconds(initialState.fromDate, 'start');
  //   let toUnixTime = CommonService.getTimeInSeconds(initialState.toDate, 'end');

  //   dispatch(apiCall({
  //     url:  `${ApiUrls.getTransactionSummary()}/${values.directionChange}/${ values.typeChange }/${fromUnixTime}/${toUnixTime}`,
  //     callback: res => { onBookingResponse(res)}
  //   }))
  // };

  const onBookingResponse = result => {
    setLoader(false);
    let fullBooking = [];

    if (result && result.data && result.data.success && result.data.data) {
      setBookingRequests(result.data.data);
      setFilteredBookings(result.data.data);
      setValues({ ...values, disableDownloadButton: false, showHeader: true });
    } else {
      setBookingRequests([]);
      setFilteredBookings([]);
    }
  };

  const renderServiceRequest = (request, key) => {
    console.log('Request item is--------------', request);
    return (
      <tr className="request-item" key={key}>
        <td className="item">{request.id + 1}</td>
        <td className="item">{request.operator_name}</td>
        <td className="item">{request.service_name}</td>
        <td className="item">{request.no_of_seats}</td>
        <td className="item">{request.status}</td>
        {/* <td className="item">{request.booking_time.split('T')[1].slice(0, -1)}</td> */}
        <td className="item">{DateService.ampm(request.presentation_time)}</td>
        <td className="item">
          {DateService.changeDateFormat(request.issue_date, 'yyyy-mm-dd', 'dd-mm-yyyy')}
        </td>
        <td className="item">
          {DateService.changeDateFormat(request.travel_date, 'yyyy-mm-dd', 'dd-mm-yyyy')}
        </td>
        <td className="item">{request.customer_name}</td>
        <td className="item">{request.customer_email}</td>
        <td className="item">{request.customer_phone}</td>
        <td className="item">{request.document_number}</td>
        <td className="item">
          {CommonService.convertUTCDateToLocalDate(request.cancellation_time)}
        </td>
        <td className="item">{request.cancelled_by}</td>
        <td className="item">{request.from_address}</td>
        <td className="item">{request.to_address}</td>
        <td className="item">{request.pnr_id}</td>
        <td className="item">{CommonService.currency(request.ticket_price)}</td>
        <td className="item">{CommonService.currency(request.discount_amount)}</td>
        <td className="item">{CommonService.currency(request.additional_fare)}</td>
        <td className="item">{CommonService.currency(request.cancellation_charges)}</td>
        <td className="item">{CommonService.currency(request.refund_amount)}</td>
        <td className="item">{CommonService.currency(request.net_amount)}</td>
        <td className="big-entry item capitalize-text">
          {request.source_of_booking.toLocaleLowerCase().replace(/[\W_]/g, ' ')}
        </td>
        <td className="item capitalize-text">
          {/* {request.PgType.toLocaleLowerCase().replace(/[\W_]/g, ' ')} */}
          {getPaymentGatewayType(request.PgType)}
        </td>
      </tr>
    );
  };

  const getPaymentGatewayType = type => {
    let actualPaymentMethod = '';

    if (type.includes('Cash')) {
      actualPaymentMethod = 'Efectivo';
    } else if (type.includes('Débito')) {
      actualPaymentMethod = 'Tarjeta de débito';
    } else if (type.includes('Crédito')) {
      actualPaymentMethod = 'Tarjeta de crédito ';
    } else if (type.includes('Bank')) {
      actualPaymentMethod = 'Transferencia bancaria ';
    } else if (type.includes('WEBPAY_REST')) {
      actualPaymentMethod = 'Web Pay';
    } else {
      actualPaymentMethod = type;
    }
    return actualPaymentMethod;
  };

  const onStepClick = menuItem => {
    // Highlight the selected Menu Item

    let elementsIndex = initialState.menuButtons.findIndex(element => element.key == menuItem - 1);

    let newMenus = [...initialState.menuButtons];

    newMenus.forEach((item, key) => {
      newMenus[key] = {
        ...newMenus[key],
        active: false,
      };
    });

    newMenus[elementsIndex] = {
      ...newMenus[elementsIndex],
      active: !newMenus[elementsIndex].active,
    };

    setValues({ ...values, menuButtons: newMenus });

    //  Filter the arrays as per selection

    let fromAT = [];
    let toAT = [];

    for (let i = 0; i < initialState.bookingRequests.length; i++) {
      if (menuItem == 2) {
        if (initialState.bookingRequests[i].txn_subtype == 'FROM_AIRPORT') {
          fromAT.push(initialState.bookingRequests[i]);
        }
      } else if (menuItem == 3) {
        if (initialState.bookingRequests[i].txn_subtype == 'TO_AIRPORT') {
          toAT.push(initialState.bookingRequests[i]);
        }
      }
    }

    menuItem == 1
      ? this.setState({ filteredBookings: initialState.bookingRequests })
      : menuItem == 2
      ? setFilteredBookings(fromAT)
      : setFilteredBookings(toAT);
  };

  const onReportTypeChnage = value => {
    // this.setState({ typeOfReport: value });
    setValues({ ...values, typeOfReport: value });
  };

  const onBookingSelectionChange = value => {
    // this.setState({ typeChange: value.value });
    setValues({ ...values, typeChange: value.value });
  };

  const onDirectionChange = value => {
    // this.setState({ directionChange: value.value });
    setValues({ ...values, directionChange: value.value });
  };

  const onDateChange = (value, type) => {
    if (type == 'going') {
      // this.setState({ fromDate: value });
      setValues({ ...values, fromDate: value });
    } else {
      // this.setState({ toDate: value });
      setValues({ ...values, toDate: value });
    }
  };

  const searchResults = () => {
    setLoader(true);
    let fromUnixTime = CommonService.getTimeInSeconds(
      `${DateService.changeDateFormat(values.fromDate, 'dd-mm-yyyy', 'yyyy-mm-dd')}T00:00`,
    );
    let toUnixTime = CommonService.getTimeInSeconds(
      `${DateService.changeDateFormat(values.toDate, 'dd-mm-yyyy', 'yyyy-mm-dd')}T23:59`,
    );

    dispatch(
      apiCall({
        url: `${ApiUrls.getTransactionSummary()}/${values.directionChange}/${
          values.typeChange
        }/${fromUnixTime}/${toUnixTime}`,
        callback: res => {
          onBookingResponse(res);
        },
      }),
    );
  };

  const downloadFile = (fileName, urlData) => {
    window.open(urlData);
  };

  const onBookButtonPress = () => {
    // let csvContent =
    //   'Id, Operador, Estatus, Tipo de servicio, Asientos, Fecha de asunto, Hora, Fecha de viaje, Nombre del cliente, Número del Documento, Tiempo de cancelación, De la Dirección, Cancelado por ,Dirigirse, Número de PNR, Precio de la entrada, Cargos por cancelación, Cantidad devuelta, Importe neto, Monto neto , Descuento, Fuente de reserva, Tipo PG \r\n';

    let csvContent =
      'Id, Operador, Tipo de servicio, Asientos,Estatus, Hora de recogida, Fecha de emisión,  Fecha de viaje, Nombre del pasajero,Correo electrónico, Teléfono, 	Documento, Fecha de cancelación, Cancelado por , Desde dirección , Hacía dirección,  PNR, Tarifa,Descuento, Tarifa adicional, Cargos de cancelación,Monto de reembolso, Monto neto , Origen de emisión, Tipo PG \r\n';

    filteredBookings &&
      filteredBookings.forEach(rowArray => {
        let row = '';

        Object.keys(rowArray).map(key => {
          console.log('Keys in reports are', key);

          if (
            key == 'vehicle_type' ||
            key == 'document_type' ||
            // key == 'customer_email' ||
            // key == 'to_address' ||
            key == 'total_amount'
          ) {
            //do nothing
          } else {
            //internal conditions where we have to change format of the data
            if (key == 'id') {
              row += Number(rowArray[key]) + 1 + ',';
            } else if (key == 'issue_date' || key == 'travel_date') {
              row += DateService.changeDateFormat(rowArray[key], 'yyyy-mm-dd', 'dd-mm-yyyy') + ',';
            } else if (key == 'cancellation_time') {
              row += CommonService.convertUTCDateToLocalDate(rowArray[key]) + ',';
            } else if (
              key == 'ticket_price' ||
              key == 'cancellation_charges' ||
              key == 'refund_amount' ||
              key == 'net_amount' ||
              key == 'discount_amount' ||
              key == 'additional_fare'
            ) {
              row += CommonService.currency(rowArray[key]) + ',';
            } else if (key == 'from_address') {
              let sanitizedData = rowArray[key].replace(/#|-|,|_/g, '');
              row += sanitizedData + ' ,';
            } else if (key == 'to_address') {
              let sanitizedData = rowArray[key].replace(/#|-|,|_/g, '');
              row += sanitizedData + ' ,';
            } else if (key == 'presentation_time') {
              row += DateService.ampm(rowArray[key]) + ',';
            } else {
              row +=
                rowArray[key]
                  .toString()
                  .split(',')
                  .join('') + ',';
            }
          }
        });

        console.log('+++Full row is', row);

        //  Object.values(rowArray).forEach(innerRow => {
        //   //  console.log("Inner row is", innerRow);
        //    row +=
        //      innerRow
        //        .toString()
        //        .split(',')
        //        .join('') + ',';
        //  });

        // let row = innerRowMain;
        csvContent += row + '\r\n';
      });

    var hiddenElement = document.createElement('a');
    hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(csvContent);
    hiddenElement.target = '_blank';
    hiddenElement.download = 'Report.csv';
    hiddenElement.click();
  };

  const onFocus = () => {};

  console.log('values are', values);
  return (
    <div className="home-operations home-reports">
      <div className="operations-root-container">
        <div className="reports-top-strip">
          <div className="home-selection-input-box font10">
            <span className="label font11">{'Reportes'}</span>
            <KuposSelectBox
              // readonly={true}
              showAll={true}
              options={typeOfReport}
              selectedOption={values.typeOfReport}
              onChange={val => onReportTypeChnage(val)}
              // onInputChange={onInputChange}
              placeholder={'Reporte de ventas'}
              height={40}
            />
          </div>
          <div className="home-selection-input-box font10">
            <span className="label font11">{'Dirección'}</span>
            <KuposSelectBox
              // readonly={true}
              showAll={true}
              options={selectToFrom}
              selectedOption={values.directionChange}
              onChange={val => onDirectionChange(val)}
              // onInputChange={onInputChange}
              error={errors.transferServiceError ? true : false}
              errorMessage={errors.transferServiceError}
              placeholder={'Todos'}
              height={40}
            />
          </div>
          <div className="home-selection-input-box font10">
            <span className="label font11">{'Ordenar por'}</span>
            <KuposSelectBox
              // readonly={true}
              showAll={true}
              options={selectServiceOptions}
              selectedOption={values.typeChange}
              onChange={val => onBookingSelectionChange(val)}
              // onInputChange={onInputChange}
              error={errors.transferServiceError ? true : false}
              errorMessage={errors.transferServiceError}
              placeholder={'Fecha de viaje'}
              height={40}
            />
          </div>
          <div className="home-selection-input-box font10 datebox">
            <span className="label font11">{'Desde'}</span>
            <KuposDatePicker
              selectedDate={values.fromDate}
              onDateChange={val => onDateChange(val, 'going')}
              deactivateFutureDate={values.typeChange == 'BOOKING_DATE' ? true : false}
              error={errors.transferDateError ? true : false}
              errorMessage={errors.transferDateError}
              placeholder={'Going Date'}
              onFocus={() => onFocus('going')}
            />
          </div>
          <div className="home-selection-input-box font10 datebox">
            <span className="label font11">{'Hasta'}</span>
            <KuposDatePicker
              selectedDate={values.toDate}
              onDateChange={val => onDateChange(val, 'return')}
              deactivateFutureDate={values.typeChange == 'BOOKING_DATE' ? true : false}
              minDate={values.fromDate}
              error={errors.transferDateError ? true : false}
              errorMessage={errors.transferDateError}
              placeholder={'Return Date'}
              onFocus={() => onFocus('return')}
            />
          </div>

          <div className="home-selection-input-box font12 datebox">
            <button
              className="kupos-button home-selection-input-button "
              onClick={searchResults}
              style={{ marginTop: 24 }}
            >
              <img
                className="icon-size"
                src="/images/icons/home/icon_search-white.png"
                alt={'TRANSLATIONS.ICON_SEARCH_WHITE'}
              />{' '}
            </button>
          </div>

          <div className="doc-search-button font12 operations-button-container reports-button-container">
            <KuposButton
              onClick={onBookButtonPress}
              disabled={values.disableDownloadButton}
              className="operations-download-button-inner"
            >
              <span className="vendor-icon operations-download-button">
                <img src="/images/ticket-search.png" />
                <span className="font10">Descargar reporte</span>
              </span>
            </KuposButton>
          </div>
        </div>
        <div className="reports-main-body-container operations-main-body-container">
          <div className="operations-main-body">
            <Table responsive="sm" striped bordered hover>
              {values.showHeader && filteredBookings && filteredBookings.length > 0 ? (
                <thead>
                  <tr>
                    <th className="bold-text">S#</th>
                    <th className="bold-text">Operador</th>
                    <th className="bold-text">Tipo de servicio</th>
                    <th className="bold-text">Asientos</th>
                    <th className="bold-text">Estatus</th>
                    <th className="bold-text" style={{ minWidth: 100 }}>
                      Hora de recogida
                    </th>
                    <th className="bold-text">Fecha de emisión</th>
                    <th className="bold-text">Fecha de viaje</th>
                    <th className="bold-text">Nombre del pasajero</th>
                    <th className="bold-text">Correo electrónico</th>
                    <th className="bold-text">Teléfono</th>
                    <th className="bold-text">Documento #</th>
                    <th className="bold-text">Fecha de cancelación</th>
                    <th className="bold-text">Cancelado por</th>
                    <th className="bold-text">Desde dirección</th>
                    <th className="bold-text">Hacía dirección</th>
                    <th className="bold-text">PNR</th>
                    <th className="bold-text">Tarifa</th>
                    <th className="bold-text">Descuento</th>
                    <th className="bold-text">Tarifa adicional</th>
                    <th className="bold-text">Cargos de cancelación</th>
                    <th className="bold-text">Monto de reembolso</th>
                    <th className="bold-text">Monto neto</th>
                    <th className="big-entry bold-text">Origen de emisión</th>
                    <th className="bold-text">Tipo PG</th>
                  </tr>
                </thead>
              ) : null}
              <tbody>
                {bookingRequests &&
                  filteredBookings.map((val, key) => renderServiceRequest(val, key))}
              </tbody>
            </Table>
          </div>
        </div>
      </div>

      {loader ? (
        <div className="loader-container">
          <Loader visible={setLoader} type="Bars" color="#637290" height={80} width={80} />
        </div>
      ) : null}
    </div>
  );
};

/* istanbul ignore next */
function mapStateToProps(state) {
  return {
    home: state.home,
  };
}

/* istanbul ignore next */
function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...actions }, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ReportsNew);
