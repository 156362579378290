import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actions from './redux/actions';
import Select from 'react-select';
import { KuposButton, KuposErrorSuccessModal, KuposInput } from '../common';
import ApiUrls from '../../services/apiUrls';
import { withScriptjs, withGoogleMap, GoogleMap, Marker, Polygon } from 'react-google-maps';
import AppData from '../../services/appData';
import { DropdownComponent } from '../common/DropdownComponent';
import DateService from '../../services/dateService';
import { apiCall } from './redux/apiCall';
import Loader from 'react-loader-spinner';

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    fontSize: 14,
  }),
};

const modalInitialState = {
  show: false,
  success: false,
  bodyText: null,
};
const initialState = {
  locations: [],
  locationAreas: [],
  priceTypes: [
    { value: 'PER_VEHICLE', label: 'Por vehículo' },
    { value: 'PER_PERSON', label: 'Por persona' },
  ],
  fromToAirport: [
    { value: 'FROM_AIRPORT', label: 'Del aeropuerto' },
    { value: 'TO_AIRPORT', label: 'Al aeropuerto' },
  ],
  services: [],
  polygon: [],
  selectedLatLong: { lat: -33.4436021, lng: -70.6606066 },
};

const errorInitialState = {};

const AddOldService = ({match,history}) =>  {

  const dispatch = useDispatch();
  const [values, setValues] = useState(initialState);
  const [modal, setModal] = useState(modalInitialState);
  const [locationAreas, setLocationAreas] = useState([]);
  const [services, setServices] = useState([]);
  const [locations, setLocations] = useState([]);
  const [itemList, setItemList] = useState([]);
  const [itemToEdit, setItemToEdit] = useState([]);
  const [loader, setLoader] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState('');
  const [errors, setErrors] = useState(errorInitialState);

  const id = match.params ? Number(match.params.id) : null;

  useEffect(() => {
    dispatch(apiCall({
      url: ApiUrls.getLocationList(),
      callback: res => { onLocationApiResponse(res)}
    }))
  }, [dispatch])



  const getLocationAreas = (val) => {
    dispatch(apiCall({
      url: ApiUrls.getLocationAreas(),
      callback: res => { 
        if (res.data && res.data.success && res.data.data) {
          setLocationAreas(
            res.data.data
              .sort((x, y) => {
                return y.id - x.id;
              })
              .filter(area => {
                return area.location_id === val.value;
              })
              .map(val => ({ value: val.id, label: val.area_name, polygon: val.polygon })),
          )
         }
      }
    }))
  }

 

  useEffect(() => {
    dispatch(apiCall({
      url: ApiUrls.getServices(),
      callback: res => { 
        if (res.data && res.data.success && res.data.data) {
          setServices(
            res.data.data
              .sort((x, y) => {
                return y.id - x.id;
              })
              .map(val => ({ value: val.id, label: val.name })),
          )
         }
      }
    }))
  }, [dispatch])

  //Location API Response
const  onLocationApiResponse = result => {
    if (result.data && result.data.success && result.data.data) {
      setLocations(
        result.data.data
          .sort((x, y) => {
            return y.id - x.id;
          })
          .map(val => ({
            value: val.id,
            label: val.name,
            type: val.type,
          })),
      )
    }
  };

 

  useEffect(() => {
    dispatch(apiCall({
      url: ApiUrls.addServicePricing(),
      callback: res => { 
        if (res.data && res.data.success && res.data.data) {
            // this.setState({ itemList: res.data.data });
            setItemList(res.data.data)
          }
          if (id)
            getParticularItem(id, res.data.data);
        
      }
    }))
  }, [dispatch,id])

 const  getParticularItem = (id, items) => {
    items.forEach(element => {
      if (element.id == id) {
        setItemToEdit(element);
        setValues({
          selectedServicePriceName: element.service_price_name,
          selectedPickupLocation: [{ value: element.location_id, label: element.location_name }],
          selectedPickupLocationArea: [
            { value: element.location_area_id, label: element.location_area_name },
          ],
          selectedService: [{ value: element.service_name, label: element.service_name }],
          selectedPriceType: [{ value: element.price_type, label: element.price_type }],
          selectedTransferType: [{ value: element.origin_type, label: element.origin_type }],
          selectedPrice: element.price,
        })
      }
    });
  };

  

 const onChange = () => {
    console.log('Value is changing');
  };

 const onPriceBlur = text => {
    console.log('Value is blur', text);
  };

  const validate = () => {

    let error = 0;
    setErrors(initialState);

    if (!values.selectedPickupLocation) {
      setErrors({ ...errors, selectedCountryError: 'Seleccione la Localización de embarque' });
      error++;
      return false;
    }
    if (!values.selectedPickupLocationArea) {
      setErrors({ ...errors, selectedPickupLocationAreaError: 'Seleccione la Área de localización de embarque' });
      error++;
      return false;
    } else if (!values.selectedTransferType) {
      setErrors({ ...errors, selectedTransferTypeError: 'Seleccione el tipo de servicio' });
      error++;
      return false;
    } else if (!values.selectedService) {
      setErrors({ ...errors, selectedServiceError: 'Por favor ingrese al servicio' });
      error++;
      return false;
    } else if (!values.selectedPriceType) {
      setErrors({ ...errors, selectedPriceTypeError: 'Ingrese el precio' });
      error++;
      return false;
    } else if (!values.selectedPrice) {
      setErrors({ ...errors, selectedPriceError: 'Ingrese el precio' });
      error++;
      return false;
    } 
    if (error > 0) {
      return false;
    } else {
      return true;
    }
  };

  const createOrUpdate = () => {
    if (!validate()) {
      // alert('Select all required fields');
      return;
    }
    setLoader(true)

    let data, updateData;
    id
      ? (updateData = {
          id: Number(itemToEdit.id),
          client_code: itemToEdit.client_code,
          location_id: itemToEdit.location_id,
          location_area_id: itemToEdit.location_area_id,
          location_name: itemToEdit.location_name,
          location_area_name: itemToEdit.location_area_name,
          origin_type: itemToEdit.origin_type,
          service_id: Number(itemToEdit.service_id),
          service_name: itemToEdit.service_name,
          price_type: itemToEdit.price_type,
          price: Number(values.selectedPrice),
          service_price_name: itemToEdit.service_price_name,
          status: 'ACTIVE',
          created_by: itemToEdit.created_by,
          last_updated_on: itemToEdit.last_updated_on,
          last_updated_by: 0,
          created_user: itemToEdit.created_user,
          created_utc: Number(itemToEdit.created_utc),
          created_date_time_stg: itemToEdit.created_date_time_stg,
          last_updated_by: itemToEdit.last_updated_by,
          created_user: '',
          created_utc: 0,
        })
      : (data = {
          // id: this.props.match.params.id ? +this.props.match.params.id : undefined,

          location_id: values.selectedPickupLocation.value,
          location_name: values.selectedPickupLocation.label,
          location_area_id: values.selectedPickupLocationArea.value,
          location_area_name: values.selectedPickupLocationArea.label,
          service_id: values.selectedService.value,
          service_name: values.selectedService.label,
          price_type: values.selectedPriceType.value,
          origin_type: values.selectedTransferType.value,
          price: Number(values.selectedPrice),
          // remarks: this.state.selectedRemarks,
        });
  

    dispatch(
      apiCall({
        url: ApiUrls.addOldServicePrice(),
        method: id ? 'PUT' : 'POST',
        data: id ? updateData : data,
        callback: res => onAddServicePricingResponse(res),
      }),
    );
  };

  const onAddServicePricingResponse = res => {
    if (res.data && res.data.success && res.data.data) {
      setLoader(false)
      setModal({
        show: true,
        success: true,
        redirect: true,
        bodyText: 'Éxito' || (id ? 'Éxito' : 'Éxito'),
      });
    } else if (!res.data.success && res.data.message) {
      setModal({ show: true, success: false, bodyText: res.data.message });
    } else {
      setModal({ show: true, success: false, bodyText: 'Fail' });
    }
  };

 

  // setPloygonOnMap = selectedArea => {
  //   let myElementPolygon,
  //     newPolygon = [];

  //   this.state.rawLocationAreas &&
  //     this.state.rawLocationAreas.forEach(element => {
  //       if (selectedArea.value === element.id) {
  //         myElementPolygon = JSON.parse(element.polygon).features[0].geometry.coordinates[0];
  //       }
  //     });
  //   if (myElementPolygon) {
  //     myElementPolygon.forEach(element => {
  //       newPolygon.push({ lat: element[0], lng: element[1] });
  //     });

  //     console.log('New polygon is', newPolygon);

  //     this.setState({
  //       polygon: newPolygon,
  //       selectedLatLong: (newPolygon || []).length ? newPolygon[0] : this.state.selectedLatLong,
  //     });
  //   }
  // };

  const onPriceChange = (value, type) => {
    switch (type) {
      case 'price':
        setValues({...values, selectedPrice:value})
        break;
    }
  };

  const goToAdminHome = () => {
    setModal({
      show: true,
      success: false,
      redirect: true,
      bodyText: " ",
    });
    history.push('/admin-home');
  };

 const  onLocationChange = (val, type) => {

    switch (type) {
      case 'pickup_location':
        setValues({...values, selectedPickupLocation:val})
        getLocationAreas(val);
        break;
      case 'pickup_location_area':
        setValues({...values, selectedPickupLocationArea:val})
        break;
      case 'service':
        setValues({...values, selectedService:val})
        break;
      case 'price_type':
        setValues({...values, selectedPriceType:val})
        break;
      case 'transfer_type':
        setValues({...values, selectedTransferType:val})
        break;
    }

   
  };

    return (
      <div className="home-add-service">
        <div className="service-pricing-main">
          <div className="location-selection-container">
            <div>
              <span className="bold-text">{id ? "Actualizar nuevo servicio" : "Agregar servicio nuevo"}</span>
            </div>
            <div className="location-selection-body">
              <div style={{ marginTop: 10 }}>
                <DropdownComponent
                  onChange={val => onLocationChange(val, 'pickup_location')}
                  placeholder={'Locaciones'}
                  options={locations}
                  value={values.selectedPickupLocation}
                  disabled={id ? true : false}
                  error={errors.selectedPickupLocationError ? true : false}
                  errorMessage={
                    errors.selectedPickupLocationError
                      ? errors.selectedPickupLocationError
                      : ''
                  }
                />
              </div>

              <div style={{ marginTop: 20 }}>
                <DropdownComponent
                  onChange={val => onLocationChange(val, 'pickup_location_area')}
                  placeholder={'Areas de las locaciones seleccionadas'}
                  options={locationAreas}
                  // options={this.state.locations}
                  value={values.selectedPickupLocationArea}
                  disabled={id ? true : false}
                  error={errors.selectedPickupLocationAreaError ? true : false}
                  errorMessage={
                    errors.selectedPickupLocationAreaError
                      ? errors.selectedPickupLocationAreaError
                      : ''
                  }
                />
              </div>

              <div style={{ marginTop: 20 }}>
                <DropdownComponent
                  onChange={val => onLocationChange(val, 'transfer_type')}
                  placeholder={'Tipo de transfer'}
                  options={initialState.fromToAirport}
                  value={values.selectedTransferType}
                  disabled={id ? true : false}
                  error={errors.selectedTransferTypeError ? true : false}
                  errorMessage={
                    errors.selectedTransferTypeError
                      ? errors.selectedTransferTypeError
                      : ''
                  }
                />
              </div>

              <div style={{ marginTop: 20 }}>
                <DropdownComponent
                  onChange={val => onLocationChange(val, 'service')}
                  placeholder={'Servicio'}
                  options={services}
                  value={values.selectedService}
                  disabled={id ? true : false}
                  error={errors.selectedServiceError ? true : false}
                  errorMessage={
                    errors.selectedServiceError ? errors.selectedServiceError : ''
                  }
                />
              </div>
              <div style={{ marginTop: 20 }}>
                <DropdownComponent
                  onChange={val => onLocationChange(val, 'price_type')}
                  placeholder={'Tipo de precio'}
                  options={initialState.priceTypes}
                  value={values.selectedPriceType}
                  disabled={id ? true : false}
                  error={errors.selectedPriceTypeError ? true : false}
                  errorMessage={
                    errors.selectedPriceTypeError
                      ? errors.selectedPriceTypeError
                      : ''
                  }
                />
              </div>

              <div className="common-kupos-input-box" style={{ marginTop: 20 }}>
                <KuposInput
                  type="text"
                  className="kupos-border"
                  placeholder={'Precio'}
                  value={values.selectedPrice}
                  error={errors.selectedPriceError ? true : false}
                  errorMessage={
                    errors.selectedPriceError ? errors.selectedPriceError : ''
                  }
                  onChange={val => onPriceChange(val, 'price')}
                  onBlur={text => onPriceBlur(text)}
                />
              </div>

              <div className="button-container" style={{ marginTop: 20 }}>
                <KuposButton onClick={createOrUpdate}>
                  <span className="font11">
                    {id
                      ? 'Actualizar precios del servicio'
                      : 'Crear precios de servicios'}
                  </span>
                </KuposButton>
              </div>
            </div>
          </div>

          <div className="service-pricing-map-section">
            <MapWithAMarker
              key={initialState.selectedLatLong.lat}
              googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${AppData.MAP_API_KEY}&v=3.exp&libraries=geometry,drawing,places`}
              loadingElement={<div style={{ height: `100%` }} />}
              containerElement={<div style={{ height: `450px` }} />}
              mapElement={<div style={{ height: `100%`, borderRadius: 8 }} />}
              selectedLatLong={values.selectedLatLong}
            >
              {/* <Polygon
                ref={ref => {
                  this.polygonRef = ref;
                }}
                path={this.state.polygon}
              /> */}
            </MapWithAMarker>
          </div>
        </div>

        <KuposErrorSuccessModal
        success={modal.success}
        showModal={modal.show}
        onButtonClick={() => {
          setModal(modalInitialState);
          if (modal.redirect) {
            history.goBack();
          }
        }}
        bodyText={modal.bodyText}
      />

      {loader ? (
        <div className="loader-container">
          <Loader visible={loader} type="Bars" color="#637290" height={80} width={80} />
        </div>
      ) : null}
      </div>
    );
  
}

const MapWithAMarker = withScriptjs(
  withGoogleMap(props => (
    <GoogleMap
      defaultZoom={12}
      defaultCenter={props.selectedLatLong}
      defaultOptions={{
        scrollwheel: false,
      }}
      // defaultCenter={{ lat: -33.4436021, lng: -70.6606066 }}
      onClick={props.onMapClick}
    >
      {props.children}
    </GoogleMap>
  )),
);

/* istanbul ignore next */
function mapStateToProps(state) {
  return {
    home: state.home,
  };
}

/* istanbul ignore next */
function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...actions }, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AddOldService);
