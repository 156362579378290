import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actions from './redux/actions';
// import * as serviceActions from '../pb-service-list/redux/actions';
import * as homeActions from '../home/redux/actions';
// import * as paymentActions from '../pb-payment-details/redux/actions';

import { withTranslation, Trans } from 'react-i18next';
import i18next from 'i18next';
import { LoginModal } from './LoginModal';
import CommonService from '../../services/commonService';
import DateService from '../../services/dateService';
import ReactDOM from 'react-dom';
import { CancellationModal } from './CancellationModal';

class LanguageSwitch extends Component {
  constructor(props){
    super(props);
  }

  changeLanguage = (lang) => {
    i18next.changeLanguage(lang);
    localStorage.setItem('selectedLanguage',lang);
    if(window.location.pathname.indexOf(`/${lang}/`) <= -1){
      let path  = window.location.pathname
      if(lang === 'en'){
        path = path.replace('/es/',`/${lang}/`)
        .replace('/pasajes-bus','/bus-tickets')
        .replace('/pasajes-tren','/train-tickets')
        .replace('/transfer-aeropuerto','/airport-transfer')
        .replace('/carga-bip','/bip-recharge')
        .replace('/viajes-especiales','/private-transport')
        if(window.location.pathname.length < 4){
          path = path.replace('/es',`/${lang}`)
        }
        this.props.history.push(path)
      }else{
        path = path.replace('/en/',`/${lang}/`)
        .replace('/bus-tickets', '/pasajes-bus')
        .replace('/train-tickets', '/pasajes-tren')
        .replace('/airport-transfer', '/transfer-aeropuerto')
        .replace('/bip-recharge', '/carga-bip')
        .replace('/private-transport', '/viajes-especiales')
        if(window.location.pathname.length < 4){
          path = path.replace('/en',`/${lang}`)
        }
        this.props.history.push(path)
      }
    }
  }

  render(){
    return(
      <div className={"language-changer-switch font10 " + (this.props.logoType == 1 ? '' : 'border') }>
        <div onClick={() => this.changeLanguage('es')} className={"language-item " + (i18next.language == 'es' ? 'active' : '')}>ES</div>
        <div onClick={() => this.changeLanguage('en')} className={"language-item " + (i18next.language == 'en' ? 'active' : '')}>EN</div>
      </div>
    )
  }
}

export class TopStrip extends Component {
         static propTypes = {
           actions: PropTypes.object,
           classes: PropTypes.string,
           logoType: PropTypes.number,
           t: PropTypes.func,
           i18n: PropTypes.object,
           onLogoClicked: PropTypes.func,
           data: PropTypes.object,
         };
         constructor(props) {
           super(props);
           this.state = {
             showLoginModal: false,
             showMyAccountDropDown: false,
             showMyTicketsDropDown: false,
             showCancellationModal: false,
             dropMenus: [
               {
                 icon: '/svgs/my-account/my-account-white.svg',
                 name: 'PROFILE.MY_ACCOUNT',
                 path: '',
               },
               {
                 icon: '/svgs/my-account/my-trips-white.svg',
                 name: 'PROFILE.MY_TRIPS',
                 path: '/trips',
               },
               {
                 icon: '/svgs/my-account/my-movements-white.svg',
                 name: 'PROFILE.MY_MOVEMENTS',
                 path: '/transactions',
               },
               {
                 icon: '/svgs/my-account/promotions-white.svg',
                 name: 'PROFILE.PROMOTIONS',
                 path: '/promotions',
               },
               {
                 icon: '/svgs/my-account/my-groups-white.svg',
                 name: 'PROFILE.MY_GROUPS',
                 path: '/groups',
               },
               {
                 icon: '/svgs/my-account/logout-white.svg',
                 name: 'PROFILE.LOGOUT',
                 isLogout: true,
               },
             ],
           };
         }

         componentDidMount() {
           document.addEventListener('click', this.handleClickOutside, true);
         }
         componentWillUnmount() {
           document.removeEventListener('click', this.handleClickOutside, true);
         }

         handleClickOutside = event => {
           const domNode = ReactDOM.findDOMNode(this);

           if (!domNode || !domNode.contains(event.target)) {
             this.setState({ showMyAccountDropDown: false, showMyTicketsDropDown: false });
           }
         };

         gotoHome = () => {
           this.props.onLogoClicked();
           window.scrollTo(0, 0);
           if (CommonService.clearSearchFunction) {
             CommonService.clearSearchFunction();
           }
           this.props.actions.setOnwardPbDate(DateService.getTodayString());
           this.props.actions.setReturnPbDate(null);
           this.props.actions.pbSetSourceCity(null);
           this.props.actions.pbSetDestinationCity(null);
           this.props.actions.setServiceListOnward(null);
           this.props.actions.setServiceListReturn(null);
           if (this.props.history) this.props.history.push('/');
         };

         onMyAccountClick = () => {
           if (CommonService.isLoggedIn(this.props.common.loginData)) {
             this.props.history.push('/my-account');
            //  this.setState({
            //    showMyAccountDropDown: !this.state.showMyAccountDropDown,
            //    showMyTicketsDropDown:false});
           } else {
             this.props.actions.openCloseLoginModal(true);
             this.setState({showLoginModal:true})
           }
         };

         onMyTicketsClick = () => {
          //  if (CommonService.isLoggedIn(this.props.common.loginData)) {
          //    this.props.history.push('/my-account/trips');
          //  }else{
               this.setState({
               showMyTicketsDropDown: !this.state.showMyTicketsDropDown,
               showMyAccountDropDown :false });
            //  }
             
          //  } else {
          //    this.props.actions.openCloseLoginModal(true);
          //    // this.setState({showLoginModal:true})
          //  }
         };

         goToMyAccount = path => {
           this.props.history.push('/my-account' + path);
         };

         menuItemClick = item => {
           if (item.name != 'PROFILE.LOGOUT') {
             this.goToMyAccount(item.path);
           } else {
             CommonService.logout();
             this.props.history.push('/');
           }
           this.setState({ showMyAccountDropDown: false });
         };

         renderMyAccountDropdown = t => {
           return (
             <div className="my-account-dropdown">
               {this.state.dropMenus.map((val, key) => (
                 <div
                   onClick={() => this.menuItemClick(val)}
                   className={
                     'my-account-dropitem flex-row ' +
                     (val.name == 'PROFILE.LOGOUT' ? 'logout' : '')
                   }
                   key={key}
                 >
                   <span className="menu-icon">
                     <img src={val.icon} />
                   </span>
                   <span className="menu-name">{t(val.name)}</span>
                 </div>
               ))}
             </div>
           );
         };

         handleCancelationDisplay = () =>{
           this.setState({ showCancellationModal: false});
         }

         renderMyTicketsDropdown = () => {
           return (
             <div className="my-tickets-dropdown">
               <div className="font10 ">
                 <div
                   onClick={() => this.setState({ showCancellationModal: true })}
                   className={'my-tickets-dropitem display-flex margin-bottom-5 '}
                 >
                   <span className="top-strip-cancel-ticket-icon">
                     <img src="/images/cancel_ticket_white.svg" />
                   </span>
                   <span className="menu-name font10">
                     {this.props.t('CANCEL_TICKET.CANCEL_TICKET')}
                   </span>
                 </div>

                 <div
                   className={'my-tickets-dropitem flex-row display-flex'}
                   onClick={() =>{ this.props.history.push('/pages/contact')
                   this.setState({ showMyTicketsDropDown: false });}
                  }
                 >
                   <span className="top-strip-cancel-ticket-icon">
                     <img src="/images/contact_us_white.svg" />
                   </span>
                   <span className="menu-name font10">{this.props.t('HOME.CONTACT_US')}</span>
                 </div>
               </div>
             </div>
           );
         };

         render() {
           const { t } = this.props;
           return (
             <div className={`nav-section home-nav-section common-top-strip ${this.props.classes}`}>
               <div className="header-details">
                 <div className="content-container">
                   <div className="nav-container">
                     <div
                       style={{ flex: 1 }}
                       onClick={() => this.setState({ showMyAccountDropDown: false, showMyTicketsDropDown: false })}
                     >
                       <a href={`/${i18next.language}/${i18next.language === 'en' ? 'bus-tickets' : 'pasajes-bus'}`}>
                         {this.props.logoType == 1 ? (
                           <img
                             src="/svgs/kupos-logo.svg"
                             alt="DIS"
                             className="logo"
                             id="main-logo"
                             alt={this.props.t('TRANSLATIONS.MAIN_LOGO')}
                           />
                         ) : (
                           <img
                             src="/svgs/kupos-logo2.svg"
                             alt="DIS"
                             className="logo2"
                             id="main-logo"
                               alt={this.props.t('TRANSLATIONS.KUPOS_LOGO2')}
                           />
                         )}
                       </a>
                     </div>

                     <ul>
                       <li className="cancel-ticket-top-strip font9">
                         <a onClick={this.onMyTicketsClick}>
                           <div className="cancel-ticket-top-strip-inner">
                             <img src="/images/my-trips.svg" alt={this.props.t('TRANSLATIONS.MY_TRIPS')} />
                             <span className="menu-name">{this.props.t('HOME.MY_JOURNEYS')}</span>
                           </div>
                         </a>
                         {this.state.showMyTicketsDropDown ? this.renderMyTicketsDropdown() : null}
                       </li>
                       <li>
                         <div className="my-account-top font10">
                           <a onClick={this.onMyAccountClick}>
                             {CommonService.isLoggedIn(this.props.common.loginData) &&
                             this.props.common.loginData.image_link ? (
                               <div className="dp-image-top">
                                 <img src={this.props.common.loginData.image_link} alt="DIS" />
                               </div>
                             ) : (
                               <div
                                 className={
                                   'menu-profile-icon-stripe circular-icon-stripe profile-circular-icon '
                                 }
                               >
                                <img src="/images/user_red_dot.svg" alt={this.props.t('TRANSLATIONS.USER_DOT')} />
                               </div>
                             )}
                             {/* {CommonService.isLoggedIn(this.props.common.loginData)
                        ? CommonService.capitalize(this.props.common.loginData.name)
                        : t('HOME.MY_ACCOUNT')} */}
                           </a>
                           {this.state.showMyAccountDropDown
                             ? this.renderMyAccountDropdown(t)
                             : null}
                         </div>
                       </li>
                       <li onClick={() => this.setState({ showMyAccountDropDown: false })}>
                         <LanguageSwitch logoType={this.props.logoType} history={this.props.history} />
                       </li>
                     </ul>
                   </div>
                 </div>
                 {this.props.children}
               </div>
               {this.props.newSearch}
               {this.props.metaData ? (
                 <LoginModal
                   showModal={this.props.common.showLoginModal}
                   onHide={() => this.props.actions.openCloseLoginModal(false)}
                   common={this.props.common}
                   pbPaymentDetails={this.props.pbPaymentDetails}
                   metaData={this.props.metaData}
                   actions={this.props.actions}
                   t={this.props.t}
                   onLoginSuccess={() => this.props.actions.openCloseLoginModal(false)}
                   onSignupSuccess={() => this.props.actions.openCloseLoginModal(false)}
                   history={this.props.history}
                 />
               ) : null}

               {this.props.metaData ? (
                 <CancellationModal
                   showModal={this.state.showCancellationModal}
                   onHide={() => this.handleCancelationDisplay()}
                   common={this.props.common}
                   metaData={this.props.metaData}
                   actions={this.props.actions}
                   history={this.props.history}
                   t={this.props.t}
                 />
               ) : null}
             </div>
           );
         }
       }

/* istanbul ignore next */
function mapStateToProps(state) {
  return {
    common: state.common,
    pbPaymentDetails: state.pbPaymentDetails
  };
}

/* istanbul ignore next */
function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...actions, ...homeActions }, dispatch)
    // actions: bindActionCreators({ ...actions, ...serviceActions, ...homeActions, ...paymentActions }, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('common')(TopStrip));