import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actions from './redux/actions';
import DateService from '../../services/dateService';
import CommonService from '../../services/commonService';

export class StripDateFilter extends Component {
  static propTypes = {
    common: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      datesToShow: [],
    };

    this.calculateDatesToShow();
  }

  calculateDatesToShow = () => {
    let currentUTC = DateService.getCurrentTimeUTC();

    for (let i = 0; i < 6; i++) {
      let d = CommonService.convertUTCDateToLocalDate(currentUTC);
      this.state.datesToShow.push(d);
      currentUTC = currentUTC + 60 * 60 * 24;
    }
  };

  calculateNextAndPreviousDate = (date, type) => {
    // console.log('utc of last day----', date, type);

    let c = DateService.changeDateFormat(date, 'dd-mm-yyyy', 'yyyy-mm-dd');
    // console.log('utc converted date is-----',c,type)

    let lastDayUTC = CommonService.getTimeInSeconds(c + 'T' + '00:00');

    lastDayUTC = type == 'NEXT_DATE' ? lastDayUTC + 60 * 60 * 24 : lastDayUTC - 60 * 60 * 24;
    // console.log('utc number is AFTER----',lastDayUTC,type)

    let dateToAdd = CommonService.convertUTCDateToLocalDate(lastDayUTC);
    return dateToAdd;
  };

  onNextDay = () => {
    let data = this.state.datesToShow;
    let lastElement = data[data.length - 1];

    let date = this.calculateNextAndPreviousDate(lastElement, 'NEXT_DATE');
    console.log('utc date to add is----', date);
    data.shift();
    data.push(date);
    this.setState({ datesToShow: data });
    // console.log('utc array after pushing next elements', this.state.datesToShow);
  };

  onPreviousDay = () => {
    let data = this.state.datesToShow;
    let firstElement = data[0];
    let currentUTC = DateService.getCurrentTimeUTC();
    let firstDayDate = DateService.changeDateFormat(firstElement, 'dd-mm-yyyy', 'yyyy-mm-dd');
    let firstDayUTC = CommonService.getTimeInSeconds(firstDayDate + 'T' + '00:00');

    console.log('current and first day utc is ', currentUTC, firstDayUTC);
    if (currentUTC < firstDayUTC) {
      let date = this.calculateNextAndPreviousDate(firstElement, 'PREVIOUS_DATE');
      data.pop();
      data.unshift(date);
      this.setState({ datesToShow: data });
    } else {
      return;
    }

    // console.log('utc array after pushing previous elements', this.state.datesToShow);
  };

  render() {
    // console.log('new data arty object is', this.props);
    return (
      <div className="common-strip-date-filter">
        <div className="date-strip font10">
          <div className="date-strip-icon" onClick={this.onPreviousDay}>
            <img src="/images/icons/left.png" />
          </div>
          {this.state.datesToShow &&
            this.state.datesToShow.map(data => {
              // console.log('new array to display', this.state.datesToShow.slice(-6));
              return (
                <span
                  className={
                    this.props.selectedDate == data
                      ? 'strip-date-item  selected-date-in-strip'
                      : 'strip-date-item '
                  }
                  // style={this.props.selectedDate == data ? {backgroundColor:"#333"} :{}}
                  onClick={() => this.props.onStripDateClick(data)}
                >
                  {data}
                </span>
              );
            })}

          <div className="date-strip-icon" onClick={this.onNextDay}>
            <img src="/images/icons/right.png" />
          </div>
        </div>
      </div>
    );
  }
}

/* istanbul ignore next */
function mapStateToProps(state) {
  return {
    common: state.common,
  };
}

/* istanbul ignore next */
function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...actions }, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(StripDateFilter);
