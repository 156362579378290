import React, { Component } from 'react';
import PropTypes from 'prop-types';
import SvgHome from './SvgHome';

export default class KuposCounter extends Component {
  static propTypes = {
    value:PropTypes.number,
    step:PropTypes.number,
    error:PropTypes.bool,
    onChange:PropTypes.func
  };

  render() {
    return (
      <div className={"common-kupos-counter "  + (this.props.error ? ' error' : '')}>
        <div className={"coumter-inner flex-row " + (!this.props.noBorder ? ' kupos-border' : '')}>
          <div className="pointer" onClick={() => (this.props.onChange ? this.props.onChange(+this.props.value - 1) : null)} ><SvgHome name="minus" /></div> 
          {/* <img className="pointer" src="/images/icons/minus-round.png" onClick={() => (this.props.onChange ? this.props.onChange(+this.props.value - 1) : null)}/> */}
          <span className="counter-value center-text flex1">{this.props.value}</span>
          {/* <img className="pointer" src="/images/icons/plus-round.png" onClick={() => (this.props.onChange ? this.props.onChange(+this.props.value + 1) : null)} /> */}
          <div className="pointer" onClick={() => (this.props.onChange ? this.props.onChange(+this.props.value + 1) : null)} ><SvgHome name="plus" /></div> 
        </div>
        {this.props.error ? <span className="error font9">{this.props.errorMessage}</span> : null}
      </div>
    );
  }
}
