import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actions from './redux/actions';
import KuposInput from './KuposInput';
import SvgHome from './SvgHome';
import KuposDatePicker from './KuposDatePicker';
import KuposCheckbox from './KuposCheckbox';
import DateService from '../../services/dateService';
import KuposModal from './KuposModal';
import CommonService from '../../services/commonService';
import KuposErrorSuccessModal from './KuposErrorSuccessModal';
// import ApiUrls from '../../services/apiUrls';
import KuposModalWithButtons from './KuposModalWithButtons';
// import TermsAndConditions from '../pages/TermsAndConditions';

export class CancellationModal extends Component {
  static propTypes = {
    common: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired,
    t: PropTypes.func,
  };

  constructor(props) {
    super(props);

    this.state = {
      capsuleMenuAcktiveTab: 1,
      cancelPrintTicketCheck: false,
      tncError: false,
      selectedTab : 1
    };
  }

  onCancelPrintTicketChange = (text, inp) => {
    if (inp == 'pnr') {
      this.setState({
        cancelPrintTicketPNRError: null,
        cancelPrintTicketPNR: text,
      });
    } else if (inp == 'email') {
      this.setState({
        cancelPrintTicketEmailError: null,
        cancelPrintTicketEmail: text,
      });
    }
  };

  onCancelPrintTicketBlur = (text, inp) => {
    if (!text) {
      if (inp == 'email') {
        this.setState({
          cancelPrintTicketEmailError: {
            message: 'VALIDATIONS.VALID_EMAIL',
          },
        });
      } else if (inp == 'pnr') {
        this.setState({
          cancelPrintTicketPNRError: {
            message: 'VALIDATIONS.VALID_RESERVATION',
          },
        });
      }
    } else if (inp == 'email') {
      if (!CommonService.isEMailValid(text)) {
        this.setState({
          cancelPrintTicketEmailError: {
            message: 'VALIDATIONS.VALID_EMAIL',
          },
        });
      }
    }
  };

  onCancelPrintTicketSubmitPressed = () => {
    let errorCount = 0;
    if (!this.state.cancelPrintTicketPNR) {
      this.setState({
        cancelPrintTicketPNRError: {
          message: 'VALIDATIONS.VALID_RESERVATION',
        },
      });
      errorCount++;
    }
    if (!this.state.cancelPrintTicketEmail) {
      this.setState({
        cancelPrintTicketEmailError: {
          message: 'VALIDATIONS.VALID_EMAIL',
        },
      });
      errorCount++;
    } else if (!CommonService.isEMailValid(this.state.cancelPrintTicketEmail)) {
      this.setState({
        cancelPrintTicketEmailError: {
          message: 'VALIDATIONS.VALID_EMAIL',
        },
      });
      errorCount++;
    }

    if (!this.state.cancelPrintTicketDate && this.state.capsuleMenuAcktiveTab === 1) {
      this.setState({
        cancelPrintTicketDateError: {
          message: 'VALIDATIONS.VALID_DATE',
        },
      });
      errorCount++;
    }

    if (!this.state.cancelPrintTicketCheck) {
      this.setState({
        tncError:true
      });
      errorCount++;
    }
    
    if (errorCount > 0) {
      return;
    }
    let path = 'travel_date=' + this.state.cancelPrintTicketDate;
    if(this.state.selectedTab == 1){
      path += '&contact_info=' + this.state.cancelPrintTicketEmail;
    }else{
      path += '&email=' + this.state.cancelPrintTicketEmail;
    }
    path += '&is_mobile_app=' + true;

    if (this.state.capsuleMenuAcktiveTab == 1) {
      path += '&do_cancel=' + true;
    }

    this.onCancelPrintTicketSubmit(path, this.state.cancelPrintTicketPNR);
  };

  // onCancelPrintTicketSubmit = (path, pnr) => {
  //   this.props.actions
  //     .loadTicketDetails({ url: this.state.selectedTab == 1 ? ApiUrls.ticketDetails(pnr, path) : ApiUrls.ticketDetailsAT(pnr, path) })
  //     .then(res => {
  //       // this.props.actions.setTicketDetails(res);
  //       if ((res.body && res.body.pnr_number) || (res.result && res.result.pnr_number)) {
  //         if (this.state.capsuleMenuAcktiveTab == 0) {
  //           window.open(res.body.print_pdf_url, '_blank');
  //         } else {if (
  //           ((res.body && res.body.pnr_number) &&
  //             (res.body.status.toLowerCase() == "cancelado" ||
  //               res.body.status.toLowerCase() == "cancelled")) ||
  //           (res.result &&
  //             res.result.pnr_number &&
  //             (res.result.status.toLowerCase() == "cancelado" ||
  //               res.result.status.toLowerCase() == "cancelled"))
  //           ){
  //             this.setState({ showSuccessModal: true, modalSuccess:false, modalBodyText: this.props.t("HOME.TICKET_CANCELLED") });
  //           }else{
  //             if(this.state.selectedTab == 1) 
  //               this.props.history.push('/modify-ticket/cancel')
  //             else
  //               this.props.history.push('/at-modify-ticket/cancel');
  //             this.onHide()
  //           }
  //         }
  //       } else {
  //         CommonService.removeLocal('ticketInfo');
  //         this.setState({ showSuccessModal: true, modalSuccess:false, modalBodyText: res.body ? res.body.message : res.error });
  //       }
  //     });
  // };


  onHide = () => {
    this.setState({
      cancelPrintTicketPNR:null,
      cancelPrintTicketPNRError: null,
      cancelPrintTicketEmail:null,
      cancelPrintTicketEmailError: null,
      cancelPrintTicketDate: null,
      cancelPrintTicketDateError: null,
      tncError: null,
      selectedTab: 1
    });
    this.props.onHide()
  }

  onTabChange = (i) => {
    this.setState({ selectedTab: i })
  }

  renderTabs = t => {
    return (
      <div className="recharge-tabs-container">
        <div className="map-container-selector font10">
          <div
            className={'selector-item ' + (this.state.selectedTab == 1 ? 'active' : '')}
            onClick={() => this.onTabChange(1)}
          >
            {t("HOME.PB_NAME")}
          </div>
          <div
            className={'selector-item ' + (this.state.selectedTab == 2 ? 'active' : '')}
            onClick={() => this.onTabChange(2)}
          >
            {t("HOME.AT_NAME")}
          </div>
        </div>
      </div>
    );
  };


  renderCancellationCard = () => {
    return (
      <div className="cancel-container">
        <div className={'kupos-card print-cancel-section-modal cancel-modal'}>
          <div className="bold-text font16 flex-center print-section-cancel-modal-title">
            {this.props.t('CANCEL_TICKET.CANCEL_TICKET')}
          </div>
          <div className="tabs-container-cancel-modal">{this.renderTabs(this.props.t)}</div>

          <div className={'print-cancel-home-inputs font10 cancel'}>
            <div className="display-flex cancel-body-row-1-content">
              <KuposInput
                placeholder={this.props.t('CANCEL_TICKET.RESERVATION_NUMBER_PLACEHOLDER')}
                type="text"
                className="kupos-border"
                value={this.state.cancelPrintTicketPNR}
                onChange={text => this.onCancelPrintTicketChange(text, 'pnr')}
                onBlur={text => this.onCancelPrintTicketBlur(text, 'pnr')}
                error={this.state.cancelPrintTicketPNRError ? true : false}
                errorMessage={
                  this.state.cancelPrintTicketPNRError
                    ? this.props.t(this.state.cancelPrintTicketPNRError.message)
                    : null
                }
              />
              <KuposDatePicker
                placeholder={this.props.t('CANCEL_TICKET.TRAVEL_DATE')}
                // placeholder={this.props.t('HOME.SELECT_DATE')}
                minDate={DateService.getTodayString()}
                selectedDate={this.state.cancelPrintTicketDate}
                onDateChange={data =>
                  this.setState({
                    cancelPrintTicketDate: data,
                    cancelPrintTicketDateError: null,
                  })
                }
                error={this.state.cancelPrintTicketDateError ? true : false}
                errorMessage={
                  this.state.cancelPrintTicketDateError
                    ? this.props.t(this.state.cancelPrintTicketDateError.message)
                    : null
                }
                style={{ paddingRight: 0 }}
              />
            </div>

            <div className="cancel-body-row-1-content">
              <KuposInput
                placeholder={this.props.t('CANCEL_TICKET.EMAIL_ID')}
                type="email"
                className="kupos-border"
                value={this.state.cancelPrintTicketEmail}
                onChange={text => this.onCancelPrintTicketChange(text, 'email')}
                onBlur={text => this.onCancelPrintTicketBlur(text, 'email')}
                error={this.state.cancelPrintTicketEmailError ? true : false}
                errorMessage={
                  this.state.cancelPrintTicketEmailError
                    ? this.props.t(this.state.cancelPrintTicketEmailError.message)
                    : null
                }
                style={{ paddingRight: 0 }}
              />
            </div>

            <a
              onClick={() =>
                this.setState({ showReserveNumberOrTNCModel: true, findPnrSelected: true })
              }
              className="primary-text less-bold-text font10 primary-text"
            >
              {this.props.t('PRINT_SCREEN.WHERE_TO_FIND_PNR')}
            </a>

            <div className="font10 cancel-body-checkbox-container">
              <div className="accept-tnc font10">
                <KuposCheckbox
                  checked={this.state.cancelPrintTicketCheck}
                  onChange={() =>
                    this.setState({
                      tncError: false,
                      cancelPrintTicketCheck: !this.state.cancelPrintTicketCheck,
                    })
                  }
                />
                <a
                  onClick={() =>
                    this.setState({ showReserveNumberOrTNCModel: true, findPnrSelected: false })
                  }
                >
                  {this.props.t('PASSENGER_DETAILS.I_ACCEPT_TNC')}
                </a>
              </div>
              {this.state.tncError ? (
                <div className="tnc-error secondary-text font10 center-text">
                  {this.props.t('PASSENGER_DETAILS.PLEASE_ACCEPT_TNC')}
                </div>
              ) : null}
            </div>

            <div style={{ marginTop: 20 }}>
              <button
                className="kupos-button print-cancel-button-modal font11"
                onClick={this.onCancelPrintTicketSubmitPressed}
              >
                {this.props.common.loadTicketDetailsPending ? (
                  <div className="loader-cricle"></div>
                ) : (
                  <span>{this.props.t('CANCEL_TICKET.CANCEL')}</span>
                )}
              </button>
            </div>
          </div>
        </div>
        {/* ) : null} */}
      </div>
    );
  };

  render() {
    return (
      <div className="common-login-modal">
        <KuposModal
          showModal={this.props.showModal}
          onHide={this.onHide}
          size="md"
          ariaLabel="login-modal"
        >
          <div className="login-signup-block">
            <div className="close-button pointer" onClick={this.onHide}>
              <SvgHome name="close" />
            </div>
            {this.renderCancellationCard()}
          </div>
        </KuposModal>

        <KuposErrorSuccessModal
          showModal={this.state.showSuccessModal}
          success={this.state.modalSuccess}
          onButtonClick={() =>
            this.setState({
              showSuccessModal: false,
              modalBodyText: '',
            })
          }
          bodyText={this.state.modalBodyText}
        />
        <KuposModal
          ariaLabel="tnc-pnr-modal-outer"
          size={this.state.findPnrSelected ? "lg" : 'xl'}
          onHide={() => this.setState({showReserveNumberOrTNCModel:false,findPnrSelected:false})}
          showModal={this.state.showReserveNumberOrTNCModel}
        >
          {/* <div className="tnc-pnr-modal">
          {this.state.findPnrSelected ? <img src="/images/find-reservation-no.png" /> : <TermsAndConditions /> }
            <div className="close-button pointer" onClick={() => this.setState({showReserveNumberOrTNCModel:false,findPnrSelected:false})}>
              <SvgHome name="close" />
            </div>
          </div> */}
        </KuposModal>
{/* 
        <KuposModalWithButtons size="md" showButton1={true} showModal={this.state.showErrorsModal} onHide={() => this.setState({showErrorsModal:false})} >
        <div className="font11 center-text">{this.props.t("HOME.TICKET_CANCELLED")}</div>
        </KuposModalWithButtons> */}
      </div>
    );
  }
}

/* istanbul ignore next */
function mapStateToProps(state) {
  return {
    common: state.common,
  };
}

/* istanbul ignore next */
function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...actions }, dispatch)
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CancellationModal);
