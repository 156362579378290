import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actions from './redux/actions';
import { KuposButton, KuposInput, MaterialInputField } from '../common';
import CommonService from '../../services/commonService';
import AppData from '../../services/appData';
import { Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import DateService from '../../services/dateService';
import ApiUrls from '../../services/apiUrls';

export class ShiftSummaryReport extends Component {
  static propTypes = {
    home: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      columns: [
        { id: 'item', label: 'Item', minWidth: 100 },
        { id: 'value', label: 'Value', minWidth: 200 },
      ],
    };
  }

  componentDidMount() {
    ///api/v1/agent/counterClosureSummary/:counterId/:counterTxnId

    let counterDetails = CommonService.getLocal('counter');
    console.log('Counter details------', counterDetails);

    if (!counterDetails) {
      this.setState({
        noCounter: false,
        showSnackbar: true,
        alertMessage: "Usted no tiene caja abierta",
      });
      return;
    }

    this.props.actions
      .apiCall({
        url:
          AppData.BASE_URL +
          `agent/counterClosureSummary/${counterDetails.counter_id}/${counterDetails.counter_txn_id}`,
      })
      .then(
        res => {
          this.onSummaryReportFetch(res);
        },
        err => {
          console.log('Result is', err);
        },
      );
  }

  onSummaryReportFetch = res => {
    if (res.data && res.data.success && res.data.data) {
      console.log('res.data.data.counter_txn_summary', res.data.data);
      // res.data.data.counter_tickets_summary && res.data.data.counter_tickets_summary;
      //   this.setState({
      //     shiftCloseReport: res.data.data,
      //   });

      let dataRows = [];

      let counterCustomer = res.data.data.counter_txn_summary.counter_customer_txn_summary;
      let counterUser = res.data.data.counter_txn_summary.counter_user_txn_summary;
      let bankTransferTickets = res.data.data.counter_tickets_summary.bank_transfer_ticket_details;
      let cashTickets = res.data.data.counter_tickets_summary.cash_ticket_details;
      let creditCardTickets = res.data.data.counter_tickets_summary.credit_card_ticket_details;
      let debitCardTickets = res.data.data.counter_tickets_summary.debit_card_ticket_details;
      let cancelledTickets = res.data.data.counter_tickets_summary.cancel_ticket_details;

      
      CommonService.storeCounterIdWhenLoginFromOtherMachine(counterCustomer);

      dataRows.push({
        label: 'ID transacción de caja',
        value: counterCustomer.counter_txn_id,
      });
      dataRows.push({
        label: 'Usuario',
        value: counterCustomer.user_name,
      });
      dataRows.push({
        label: 'Turno',
        value: counterCustomer.shift_name,
      });
      dataRows.push({
        label: 'Sucursal',
        value: counterCustomer.branch_name,
      });
      dataRows.push({
        label: 'Caja',
        value: counterCustomer.counter_name,
      });
      dataRows.push({
        label: 'ID caja',
        value: counterCustomer.counter_id,
      });
      dataRows.push({
        label: 'Hora de inicio',
        value: DateService.ampm(
          counterCustomer.ctr_open_date_stg + ' ' + counterCustomer.ctr_open_time_stg,
        ),
        bold: true,
      });
      dataRows.push({
        label: 'hora de finalización',
        value:
          DateService.getTodayString() +
          `, ${DateService.ampm(
            DateService.getCurrentHour() + ':' + DateService.getCurrentMinute(),
          )}`,
        bold: true,
      });
      //   dataRows.push({
      //     label: 'End Time',
      //     value: counterCustomer.ctr_open_date_stg + ' ' + counterCustomer.ctr_open_time_stg,
      //   });

      dataRows.push({
        label: ' ',
        value: '',
        empty: true,
        fullRow: true,
        bold: true,
      });
      dataRows.push({
        label: '(1) Balance inicial',
        value: CommonService.currency(counterCustomer.opening_balance),
        bold: true,
      });
      dataRows.push({
        label: '(2) Boletos ( A-B )',
        value: CommonService.currency(
          counterCustomer.total_booking_amount - counterCustomer.total_cancellation_amount,
        ),
        bold: true,
      });
      dataRows.push({
        label: '(A) Vendido',
        value: `${counterCustomer.total_booking_count} 
        (${CommonService.currency(counterCustomer.total_booking_amount)})`,
      });
      dataRows.push({
        label: '(B) Cancelaciones',
        value: `${counterCustomer.total_cancellation_count} 
        (${CommonService.currency(counterCustomer.total_cancellation_amount)})`,
      });
      //Transactions
      dataRows.push({
        label: '(3) Transacciones del usuario  (H-G)',
        value: CommonService.currency(counterUser.total_income - counterUser.total_expense),
        bold: true,
      });
      dataRows.push({
        label: 'Gastos(G)',
        value: CommonService.currency(counterUser.total_expense),
        bold: true,
      });

      //expenses list
      counterUser.expense_item_list &&
        counterUser.expense_item_list.map(expItem => {
          dataRows.push({
            label: expItem.txn_name,
            value: expItem.payment_type,
            value2: `${CommonService.currency(expItem.amount)}`,
          });
        });

      dataRows.push({
        label: 'Ingresos(H)',
        value: CommonService.currency(counterUser.total_income),
        bold: true,
      });

      //income list
      counterUser.income_item_list &&
        counterUser.income_item_list.map(incItem => {
          dataRows.push({
            label: incItem.txn_name,
            value: incItem.payment_type,
            value2: `${CommonService.currency(incItem.amount)}`,
          });
        });

      dataRows.push({
        label: 'Encomienda',
        value: CommonService.currency(counterUser.total_income - counterUser.total_expense),
      });
      //Payment Types
      //   dataRows.push({
      //     label: 'Payment Types',
      //     value: '',
      //   });

      dataRows.push({
        label: 'Tipos de pagos ',
        value: '',
        empty: true,
        fullRow: true,
        bold: true,
      });
      dataRows.push({
        label: 'Efectivo',
        value: CommonService.currency(counterCustomer.cash_booking_amount),
      });
      dataRows.push({
        label: 'Tarjeta de débito',
        value: CommonService.currency(counterCustomer.debit_booking_amount),
      });
      dataRows.push({
        label: 'Tarjeta de crédito',
        value: CommonService.currency(counterCustomer.credit_booking_amount),
      });
      dataRows.push({
        label: 'Transferencia bancaria',
        value: CommonService.currency(counterCustomer.banktransfer_booking_amount),
      });
      dataRows.push({
        label: 'Dólares',
        value: CommonService.currency(counterCustomer.dollar_booking_amount),
      });
      dataRows.push({
        label: 'Monto total  ( 1 + 2 + 3 )',
        value: CommonService.currency(
          counterCustomer.opening_balance +
            counterCustomer.total_booking_amount -
            counterCustomer.total_cancellation_amount +
            counterUser.total_income -
            counterUser.total_expense,
        ),
        bold: true,
      });
      dataRows.push({
        label: 'Total a depositar (Efectivo)',
        value: CommonService.currency(
          counterCustomer.opening_balance +
            counterCustomer.cash_booking_amount +
            counterUser.total_income_cash -
            counterUser.total_expense_cash,
        ),
        bold: true,
      });

      dataRows.push({
        label: 'Impreso por',
        value: counterCustomer.user_name,
        bold: true,
      });

      dataRows.push({
        label: 'Impreso en',
        value:
          DateService.getTodayString() +
          `, ${DateService.ampm(
            DateService.getCurrentHour() + ':' + DateService.getCurrentMinute(),
          )}`,
        bold: true,
      });

      //tickets entry

      //CASH ENTRY TICKETS
      cashTickets.ticket_list &&
        dataRows.push({
          label: 'Efectivo Boletos',
          value: '',
          empty: true,
          fullRow: true,
          bold: true,
        });

      cashTickets.ticket_list &&
        dataRows.push({
          label: 'Pnr No.',
          value: 'Fecha',
          value2: 'Monto',
          bold: true,
        });

      cashTickets.ticket_list &&
        cashTickets.ticket_list.map(bankTicket => {
          dataRows.push({
            label: bankTicket.pnr_data,
            value: bankTicket.date_time_data,
            value2: `${CommonService.currency(bankTicket.Amount)}`,
          });
        });

      //BANK ENTRY TICKETS
      bankTransferTickets.ticket_list &&
        dataRows.push({
          label: 'Transferencia bancaria Boletos',
          value: '',
          empty: true,
          fullRow: true,
          bold: true,
        });

      bankTransferTickets.ticket_list &&
        dataRows.push({
          label: 'Pnr No.',
          value: 'Fecha',
          value2: 'Monto',
          bold: true,
        });

      bankTransferTickets.ticket_list &&
        bankTransferTickets.ticket_list.map(bankTicket => {
          dataRows.push({
            label: bankTicket.pnr_data,
            value: bankTicket.date_time_data,
            value2: `${CommonService.currency(bankTicket.Amount)}`,
          });
        });

      //BEDIT CARD TICKETS
      debitCardTickets.ticket_list &&
        dataRows.push({
          label: 'Tarjeta de débito Boletos',
          value: '',
          empty: true,
          fullRow: true,
          bold: true,
        });

      debitCardTickets.ticket_list &&
        dataRows.push({
          label: 'Pnr No.',
          value: 'Fecha',
          value2: 'Monto',
          bold: true,
        });

      debitCardTickets.ticket_list &&
        debitCardTickets.ticket_list.map(bankTicket => {
          dataRows.push({
            label: bankTicket.pnr_data,
            value: bankTicket.date_time_data,
            value2: `${CommonService.currency(bankTicket.Amount)}`,
          });
        });

      //CREDIT CARD TICKETS
      creditCardTickets.ticket_list &&
        dataRows.push({
          label: 'Tarjeta de crédito Boletos',
          value: '',
          empty: true,
          fullRow: true,
          bold: true,
        });

      creditCardTickets.ticket_list &&
        dataRows.push({
          label: 'Pnr No.',
          value: 'Fecha',
          value2: 'Monto',
          bold: true,
        });

      creditCardTickets.ticket_list &&
        creditCardTickets.ticket_list.map(bankTicket => {
          dataRows.push({
            label: bankTicket.pnr_data,
            value: bankTicket.date_time_data,
            value2: `${CommonService.currency(bankTicket.Amount)}`,
          });
        });

      //CANCELLED TICKETS

      cancelledTickets.ticket_list &&
        dataRows.push({
          label: 'Tarjeta de Cancelación',
          value: '',
          empty: true,
          fullRow: true,
          bold: true,
        });

      cancelledTickets.ticket_list &&
        dataRows.push({
          label: 'Pnr No.',
          value: 'Fecha',
          value2: 'Monto',
          bold: true,
        });

      cancelledTickets.ticket_list &&
        cancelledTickets.ticket_list.map(bankTicket => {
          dataRows.push({
            label:
              bankTicket.pnr_data.split(' ')[0] +
              ' ' +
              bankTicket.pnr_data.split(' ')[1] +
              ' Cancelación',
            value: bankTicket.date_time_data,
            value2: `${CommonService.currency(bankTicket.Amount)}`,
          });
        });

      this.setState({ shiftCloseReport: res.data.data, dataRows: dataRows });
    }
  };

  onChange = val => {
    this.setState({ comment: val });
  };

  onBlur = () => {};

  closeCounter = () => {
    if (!this.state.comment) {
      this.setState({
        showSnackbar: true,
        alertMessage: 'Por favor ingrese comentario para el cierre de caja',
      });

      return;
    }
    let counterDetails = CommonService.getLocal('counter');
    let counterDetailsFromOtherMachineLogin = CommonService.getLocal('counterLoginFromOtherMachine');
    let currentUserFull = CommonService.getLocal('currentUserFull');
    let currentTime = DateService.getCurrentTimeUTC();

    let data = {
      client_code: currentUserFull.authentication_details.user_details.client_code,
      login_info_ref_id: currentUserFull.login_info_ref_id,
      counter_txn_id: counterDetails.counter_txn_id,
      // branch_id: counterDetails.branch_id,
      branch_id: counterDetailsFromOtherMachineLogin.branch_id,
      counter_id: counterDetails.counter_id,
      shift_id: counterDetails.shift_id,
      closing_remark: this.state.comment,
      ctr_close_date_stg: DateService.getTodayString('yyyy-mm-dd'), // "YYYY-MM-DD
      ctr_close_time_stg: `${DateService.getCurrentHour()}:${DateService.getCurrentMinute()}`, // "HH:MM"
      ctr_close_date_time: currentTime, // Unix Datetime.
    };
    // console.log('+++data to fly-----', data);

    // return;

    this.props.actions
      .apiCall({
        url: AppData.BASE_URL + `counter/close`,
        method: 'POST',
        data: data,
      })
      .then(
        res => {
          this.onCounterCloseResponse(res);
        },
        err => {
          console.log('Result is', err);
        },
      );

    //
  };

  onCounterCloseResponse = res => {
    console.log('Counter xlose response is----', res);

    if (res && res.data && res.data.success && res.data.data) {
      CommonService.printContent(document.getElementById('shift-summary-report-print').innerHTML);

      setTimeout(() => {
        this.props.history.push('/');
        // localStorage.removeItem('counter');
        this.logoutUser();
      }, 1000);
    }
  };

  logoutUser = () => {
    let currentUser = CommonService.getLocal('currentUser');
    let currentUserFull = CommonService.getLocal('currentUserFull');
    let counter = CommonService.getLocal('counter');
    let currentTime = DateService.getCurrentTimeUTC();

    console.log('Current user----', currentUser);
    console.log('counter is---', counter);
    let data = {
      login_id: currentUser.user_details.email, // Id used for login ( email)
      login_info_ref_id: currentUserFull.login_info_ref_id, // Will get this in login API response ( login_info_ref_id )
      counter_txn_id: counter != undefined ? counter.counter_txn_id : 0, // For Counter Users, client will get this while counter_open, Other Users send 0
      logout_unix_time: currentTime,
      logout_date_stg: DateService.getTodayString('yyyy-mm-dd'),
      logout_time_stg: `${DateService.getCurrentHour()}:${DateService.getCurrentMinute()}`,
    };

    console.log('Data to fly ----', data);

    this.props.actions.apiCall({ url: ApiUrls.logout(), data: data, method: 'POST' }).then(
      res => {
        this.onLogoutResponse(res);
      },
      err => {
        this.onLogoutResponse(err);
      },
    );
  };

  onLogoutResponse = result => {
    console.log('Result from logout is----', result);

    if (result && result.data && result.data.success) {
      CommonService.logout();
      window.location.href = '/';
    }
  };

  render() {
    console.log('shiftCloseReport---------', this.state.shiftCloseReport);
    return (
      <div className="home-shift-summary-report">
        <div className="bold-text" style={{ marginBottom: 20 }}>
          Resumen cierre de caja
        </div>
        <div style={{ minWidth: `50vw` }}>
          <TableContainer
            className="table-height-30vh"
            style={{ minHeight: `60vh` }}
            id="shift-summary-report-print"
          >
            <Table stickyHeader size="small" aria-label="a dense table">
              {/* <TableHead>
                <TableRow>
                  {this.state.columns.map(column => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead> */}
              <TableBody style={{ borderWidth: 1, borderColor: '#ccc' }}>
                {this.state.dataRows &&
                  this.state.dataRows.map(row => {
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={row.code}
                        // style={{ borderWidth: 1, borderColor: '#ccc' }}
                        style={
                          row.bold
                            ? {
                                marginTop: 10,
                                borderWidth: 1,
                                borderColor: '#ccc',
                                borderStyle: 'solid',
                              }
                            : { borderWidth: 1, borderColor: '#ccc', borderStyle: 'solid' }
                        }
                      >
                        {/* {this.state.columns.map(column => {
                          const value = row[column.id];
                          return (
                            <TableCell key={column.id} align={column.align}>
                              {column.format && typeof value === 'number'
                                ? column.format(value)
                                : value}
                            </TableCell>
                          );
                        })} */}

                        {row.fullRow ? (
                          <TableCell
                            // className="table-border"
                            style={
                              row.bold
                                ? {
                                    fontWeight: 'bold',
                                    marginTop: 10,
                                    display: 'flex',
                                    justifyContent: 'center',
                                  }
                                : row.empty
                                ? { paddingTop: 60 }
                                : {}
                            }
                          >
                            {row.label}
                          </TableCell>
                        ) : (
                          <TableCell
                            className={'table-border one-third-cell'}
                            style={
                              row.bold
                                ? { fontWeight: 'bold', marginTop: 10 }
                                : row.empty
                                ? { paddingTop: 60 }
                                : {}
                            }
                          >
                            {row.label}
                          </TableCell>
                        )}
                        {!row.fullRow && (
                          <TableCell
                            className={'table-border'}
                            style={
                              row.bold
                                ? { fontWeight: 'bold' }
                                : row.empty
                                ? { paddingTop: 60 }
                                : {}
                            }
                          >
                            <span className="two-items">
                              <span style={{ minWidth: '10vw' }}>{row.value}</span>

                              <span style={{ minWidth: '10vw' }}>
                                {row.value2 && (
                                  <TableCell
                                    className="border-less-cell"
                                    style={
                                      row.bold
                                        ? { fontWeight: 'bold' }
                                        : row.empty
                                        ? { paddingTop: 60 }
                                        : {}
                                    }
                                  >
                                    {row.value2}
                                  </TableCell>
                                )}
                              </span>
                            </span>
                          </TableCell>
                        )}
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
        {!this.state.noCounter ? (
          <div className="common-kupos-input-box document-search" style={{ marginTop: 10 }}>
            <MaterialInputField
              label={'Comentario'}
              value={this.state.comment}
              multiline={true}
              rows={3}
              onChange={text => this.onChange(text)}
              onBlur={() => this.onBlur()}
              error={this.props.commentError}
              errorMessage={this.props.commentError ? this.props.commentError.message : ''}
            />
          </div>
        ) : null}

        {!this.state.noCounter ? (
          <div style={{ marginTop: 20 }}>
            <KuposButton onClick={() => this.closeCounter()}>
              <span className="font11">Cerrar caja</span>
            </KuposButton>
          </div>
        ) : null}

        <Snackbar
          open={this.state.showSnackbar}
          autoHideDuration={5000}
          anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
          onClose={this.closeSnackbar}
          //   message={this.state.alertMessage}
        >
          <Alert onClose={() => this.setState({ showSnackbar: false })} severity="error">
            {this.state.alertMessage}
          </Alert>
        </Snackbar>
      </div>
    );
  }
}

// class Example extends React.Component {

//   render() {
//     return (
//       <div>
//         <ReactToPrint
//           trigger={() => <a href="#">Print this out!</a>}
//           content={() => this.componentRef}
//           pageStyle={pageStyle}
//         />
//         <ShiftSummaryReport ref={el => (this.componentRef = el)} />
//       </div>
//     );
//   }
// }

/* istanbul ignore next */
function mapStateToProps(state) {
  return {
    home: state.home,
  };
}

/* istanbul ignore next */
function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...actions }, dispatch),
  };
}

// export default connect(mapStateToProps, mapDispatchToProps)(Example);
export default connect(mapStateToProps, mapDispatchToProps)(ShiftSummaryReport);
