import React, { useState, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { apiCall } from './redux/apiCall';
import ApiUrls from '../../services/apiUrls';
import {
  KuposErrorSuccessModal,
  KuposListLoader,
  KuposModalWithButtons,
  AddButton, ActionButtons
} from '../common';
import { Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';

const modalInitialState = {
  show: false,
  success: false,
  bodyText: null,
};

const AllPos = ({ history }) => {
  const dispatch = useDispatch();
  const onResponse = useRef(() => { });
  const [modal, setModal] = useState(modalInitialState);
  const [posList, setPostList] = useState([]);
  const [fetch, setFetch] = useState(false);
  const [spinner, setSpinner] = useState(true);
  const [selectedViewItem, setSelectedViewItem] = useState(null);
  const [snackbar, setSnackbar] = useState({});


  useEffect(() => {
    dispatch(
      apiCall({
        url: ApiUrls.addPos(),
        callback: res => onResponse.current(res),
      }),
    );
  }, [dispatch, fetch]);

  onResponse.current = res => {
    setSpinner(false);
    if (res && res.data && res.data.data) {
      setPostList(res.data.data);
    } else if (res && res.data && !res.data.success) {
      setSnackbar({
        showSnackbar: true,
        persist: true,
        alertMessage: res.data.message ? res.data.message : 'Failure'
      });
    } else {
      setSnackbar({
        showSnackbar: true,
        persist: true,
        alertMessage: 'Check Your Internet Connection'
      });
    }
  };

  const renderItem = (val, i) => {
    // console.log('value is', val);
    return (
      <div key={i} className="flex item-row">
        <div className="sm:w-full md:w-1/6 text-center">{val.id}</div>
        <div className="sm:w-full md:w-2/6 text-center capitalize-text">{val.name}</div>
        <div className="sm:w-full md:w-2/6 text-center capitalize-text">{val.branch_name}</div>
        <div className="sm:w-full md:w-2/6 text-center capitalize-text">
          {val.status.toLocaleLowerCase()}
        </div>
        <div className="sm:w-full md:w-3/6 text-center capitalize-text">{val.description}</div>


        <ActionButtons
          val={val}
          view={setSelectedViewItem}
          url={('/pos/' + val.id + '/edit')}
          handleDelete={() =>
            setModal({
              showDeleteModal: true,
              selectedItemName: val.name,
              selectedItemId: val.id,
            })
          }
        />
      </div>
    );
  };

  const renderViewModal = () => {
    return (
      <KuposModalWithButtons
        size="lg"
        showButton1={true}
        showModal={selectedViewItem ? true : false}
        onHide={() => setSelectedViewItem(null)}
      >
        {selectedViewItem && selectedViewItem.id ? (
          <div className="view-item-container">
            <h3 className="text-center font-bold">POS</h3>
            <div className="flex view-item-row">
              <div className="sm:w-full md:w-2/6 label font-bold">Nombre:</div>
              <div className="sm:w-full md:w-full">{selectedViewItem.name}</div>
            </div>
            <div className="flex view-item-row">
              <div className="sm:w-full md:w-2/6 label font-bold">Sucursal:</div>
              <div className="sm:w-full md:w-full">{selectedViewItem.branch_name}</div>
            </div>
            <div className="flex view-item-row">
              <div className="sm:w-full md:w-2/6 label font-bold">Estatus:</div>
              <div className="sm:w-full md:w-full">{selectedViewItem.status}</div>
            </div>
            <div className="flex view-item-row">
              <div className="sm:w-full md:w-2/6 label font-bold">Descripción:</div>
              <div className="sm:w-full md:w-full">{selectedViewItem.description}</div>
            </div>
          </div>
        ) : null}
      </KuposModalWithButtons>
    );
  };

  const renderDeleteModal = () => {
    return (
      <KuposModalWithButtons
        size="md"
        buttonText1="No"
        buttonText2="Yes"
        onButtonClick1={() => setModal(modalInitialState)}
        onButtonClick2={deleteItem}
        showButton1={true}
        showButton2={true}
        showModal={modal.showDeleteModal}
        onHide={() => setModal(modalInitialState)}
      >
        <div className="create-staff">
          <h4 className="text-center">¿Quieres eliminar "{modal.selectedItemName}"?</h4>
        </div>
      </KuposModalWithButtons>
    );
  };

  const deleteItem = () => {
    dispatch(
      apiCall({
        url: ApiUrls.addPos(modal.selectedItemId),
        method: 'DELETE',
        callback: res => onDeleteResponse(res),
      }),
    );
  };

  const onDeleteResponse = res => {
    if (res.data && res.data.success) {
      setModal({ show: true, success: true, bodyText: 'Eliminar éxito' });
      setFetch(!fetch);
    } else {
      setModal({ show: true, success: true, bodyText: res.data.message });
    }
  };

  return (
    <div className="home-all-pos">
      <hr className="pb-3" />
      <AddButton
        title='POS'
        url='/pos/create'
        addTitle='Agregar POS'
      />

      <div className="flex table-heading">
        <div className="sm:w-full md:w-1/6 text-center">ID</div>
        <div className="sm:w-full md:w-2/6 text-center">Nombre</div>
        <div className="sm:w-full md:w-2/6 text-center">Sucursal</div>
        <div className="sm:w-full md:w-2/6 text-center">Estatus</div>
        <div className="sm:w-full md:w-3/6 text-center">Descripción</div>
        <div className="sm:w-full md:w-3/6 text-center">Acciones</div>
      </div>

      {spinner ? (
        <KuposListLoader />
      ) : (posList || []).length > 0 ? (
        posList.sort((a, b) => b.id - a.id).map((val, i) => renderItem(val, i))
      ) : null}

      {renderViewModal()}
      {renderDeleteModal()}

      <KuposErrorSuccessModal
        showModal={modal.show}
        success={modal.success}
        smallIcon={true}
        bodyText={modal.bodyText}
        onButtonClick={() => {
          setModal(modalInitialState);
        }}
      />
      <Snackbar
        open={snackbar.showSnackbar}
        autoHideDuration={snackbar.persist ? 10000000000 : 5000}
        anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
        onClose={() => setSnackbar({ showSnackbar: false })}
      >
        <Alert onClose={() => setSnackbar({ showSnackbar: false })} severity="error">
          <span>{snackbar.alertMessage}</span>
        </Alert>
      </Snackbar>
    </div>
  );
};

export default AllPos;
