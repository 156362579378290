import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actions from './redux/actions';
import CommonService from '../../services/commonService';
import DateService from '../../services/dateService';
import KuposDatePicker from '../common/KuposDatePicker';
import KuposTimePicker from '../common/KuposTimePicker';
import {
  // AtInput,
  // SvgHome,
  // AtCounter,
  // AtTimePicker,
  KuposInput,
  KuposButton,
  KuposRadio,
  KuposSteps,
  DropdownComponent,
  KuposErrorSuccessModal,
  KuposHybridInput,
} from '../common';
import AppData from '../../services/appData';
import PrintTicket from './printTicket';
import { StripDateFilter } from '../common/StripDateFilter';
import DataNotFound from '../common/DataNotFound';
import ApiUrls from '../../services/apiUrls';
import { BookingPaymentModel } from './BookingPaymentModel';
import { Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { KuposDateTimePicker } from '../common/KuposDateTimePicker';
import { KuposAtCounter } from '../common/KuposAtCounter';
import Loader from 'react-loader-spinner';

export class DefaultPage extends Component {
  static propTypes = {
    // airportTransfer: PropTypes.object.isRequired,
    home: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.selectServiceOptions = [
      { value: 'FROM_AIRPORT', label: 'Hacia aeropuerto' },
      { value: 'TO_AIRPORT', label: 'Desde aeropuerto' },
    ];

    this.state = {
      apiResponseForPrinting: { data: [], pnr_no: '' },
      transferServiceError: true,
      selectedKupos: 1,
      selectedAdultKupos: 1,
      selectedKidKupos: 0,
      selectedTime: '00:00',
      selectedDate: DateService.getTodayString(),
      services: [],
      stepsMenu: [
        { label: 'Efectivo', active: true, value: 'CASH' },
        { label: 'Tarjeta Débito', active: false, value: 'DEBIT_CARD' },
        { label: 'Tarjeta Crédito', active: false, value: 'CREDIT_CARD' },
        { label: 'Trans bancaria', active: false, value: 'BANK_TRANSFER' },
        { label: 'Factura de Crédito', active: false, value: 'CREDIT_INVOICE' },
      ],
      docType: 'RUT',
      selectedService: null,
      noResults: 'Input the required fields and search for the available services',
      searchScreen: true,
      showLoader: false,
      showOnSelect: false,
      discountAmount: 0,
      isReservedTicket: false,
      metaData: [],
      selectedReserveTime: '00:00',
      additionalFares: [],
      additionalFareSelected: null,
      additionalFareComment: null,
      additionalFareAmount: 0,
      user:"guest",
    };
    this.getMasterData();
  }

  componentDidMount() {
    let counterDetails = CommonService.getLocal('counter');

    if (counterDetails === undefined) {
      this.setState({
        showSnackbar: true,
        persist: true,
        alertMessage:
          'No hay ninguna caja asignada, por favor primero elija una caja para continuar',
      });
      return;
    }
    this.getExchangeList();
    this.getAdditionalFareList();
    this.setState({ selectedPaymentType: this.state.stepsMenu[0] });
    this.getAtTerminalList();
  }

  // "api/v1/master_data/SEAT/TYPE"

  getMasterData = () => {
    this.props.actions.apiCall({ url: ApiUrls.getMasterData() }).then(
      res => {
        if (res && res.data && res.data.data) {
          this.setState({ metaData: res.data.data });
        }
      },
      err => {
        console.log('+++ meta data error is', err);
      },
    );
  };

  getAtTerminalList = () => {
    this.props.actions.apiCall({ url: ApiUrls.getAtTerminalList() }).then(
      res => {
        console.log('+++response from atTerminals api is', res);
        if (res.data && res.data.success && res.data.data) {
          let atTerminals = [];
          res.data.data.map(atTerminal => {
            atTerminals.push({ value: atTerminal.airport_loc_id, label: atTerminal.airport_name });
          });

          this.setState({ selectedAtTerminals: atTerminals });
        }
      },
      err => {
        console.log('Result from all location areas is', err);
      },
    );
  };

  getAdditionalFareList = () => {
    this.props.actions.apiCall({ url: ApiUrls.getAddtionalFareList() }).then(
      res => {
        console.log('additional fare lsit------', res);
        if (res.data && res.data.success) {
          const data = res.data.data.map(val => {
            return { value: val.value, label: val.value };
          });
          this.setState({ additionalFares: data });
        }
      },
      err => {
        console.log('Result is', err);
      },
    );
  };

  getExchangeList = () => {
    this.props.actions.apiCall({ url: ApiUrls.addCurrencyExchange() }).then(
      res => {
        // console.log('Exchnage list------', res.data.data);
        if (res.data && res.data.success) {
          let exchanges = [];
          exchanges.push({
            value: 1,
            label: 'CLP',
            rate: 1,
            from: 'CLP',
            to: 'CLP',
          });
          res.data.data.map(val => {
            exchanges.push({
              value: val.id,
              label: val.exchange_name.split('_TO_')[1],
              rate: val.exchange_rate,
              from: val.from_currency_code,
              to: val.to_currency_code,
            });
          });
          this.setState({
            exchangeListData: res.data.data,
            exchanges,
            selectedCurrency: {
              value: 1,
              label: 'CLP',
              rate: 1,
              from: 'CLP',
              to: 'CLP',
            },
          });
        }
      },
      err => {
        console.log('Result is', err);
      },
    );
  };

  identity_valueChange = () => {};

  onBlur = (value, type) => {
    if (!value) {
      if (type === 'pnr') {
        this.setState({ pnrError: { message: 'Enter pnr' } });
      }
    }
  };

  getVehicleImage = type => {
    console.log('Service type-----'.type);
    switch (type) {
      case 'MINI SHARED':
        return <img src="/images/cars/van_shared.png" />;
        break;
      case 'MINI EXCLUSIVE':
        return <img src="/images/cars/taxi_exclusive.png" />;
        break;
    }
  };

  getTotalPrice = (serviceType, priceType, price, serviceSeats) => {
    return priceType === 'PER_VEHICLE' ? price : price * this.state.choosenKupos;
  };

  getBookingPrice = (serviceType, priceType, price, serviceSeats) => {
    // console.log('Price Type is', priceType);
    // return priceType === 'PER_VEHICLE' ? price * serviceSeats : price;
    return priceType === 'PER_VEHICLE' ? price : price;
  };

  calculateCurrencyAmount = () => {
    return (
      Number(
        this.state.selectedService.price_type === 'PER_VEHICLE'
          ? this.state.selectedService.price
          : // ? this.state.selectedService.price * this.state.selectedService.seat_capacity
            this.state.selectedService.price * this.state.choosenKupos,
      ) *
      Number(
        (this.state.selectedCurrency &&
          this.state.selectedCurrency.rate &&
          this.state.selectedCurrency.rate) ||
          1,
      )
    );
  };

  selectSeat = item => {
    this.setState({ selectedService: item, selectedServiceId: item.id });
  };

  renderService = (service, key) => {
    // console.log('Service is-----------------', service);
    return (
      <div className="service-row font10">
        <div className="service-icon-container">
          {/* {this.getVehicleImage(service.service_type)} */}

          {service.service_type === 'Pav Van Compartida' ? (
            <img src="/images/cars/van_shared.png" />
          ) : (
            <img src="/images/cars/taxi_exclusive.png" />
          )}
        </div>
        <div>
          <div className="bold-text">
            {service.service_type && CommonService.capitalize(service.service_type)}
          </div>
          <div className="second-line">
            Pasajeros: <span>1 - {service.seat_capacity}</span>
          </div>
        </div>

        <div>
          <div>{service.price_type === 'PER_VEHICLE' ? 'PRECIO' : 'PRECIO POR CUPO'} </div>
          <div className="bold-text second-line">
            CPL${' '}
            <span>
              {CommonService.currency(
                this.getBookingPrice(
                  service.service_type,
                  service.price_type,
                  service.price,
                  service.seat_capacity,
                ),
              )}
            </span>
          </div>
        </div>

        <div>
          <div>CUPOS</div>
          <div className="bold-text second-line">
            <span>{this.state.choosenKupos}</span> Pasajeros
          </div>
        </div>

        <div>
          <div>PRECIO TOTAL</div>
          <div className="bold-text second-line">
            CLP${' '}
            <span>
              {CommonService.currency(
                this.getTotalPrice(
                  service.service_type,
                  service.price_type,
                  service.price,
                  service.seat_capacity,
                ),
              )}
            </span>
          </div>
        </div>

        <div className="search-button-container">
          <KuposButton
            className={
              this.state.selectedService && this.state.selectedServiceId === service.id
                ? 'search-button-selected'
                : 'search-button'
            }
            style={
              this.state.selectedService && this.state.selectedServiceId === service.id
                ? { backgroundColor: '#637290' }
                : { backgroundColor: '#ccc' }
            }
            onClick={() => this.selectSeat(service)}
          >
            <span className="upper-case-text">comprar</span>
          </KuposButton>
        </div>
      </div>
    );
  };

  validatePassengerDetails = () => {
    console.log('+++state in validate passanger details', this.state);
    let errors = 0;

    // if (this.state.docType === 'RUT') {
    //   if (!CommonService.isRutValid(this.state.identity_value)) {
    //     this.setState({ identity_valueError: { message: 'Ingrese un número de RUT válido' } });
    //     errors++;
    //     console.log('1');
    //   } 
    //   else if (!this.state.identity_value) {
    //     this.setState({
    //       identity_valueError: { message: 'Ingrese un número de documento válido' },
    //     });
    //     errors++;
    //     console.log('2');
    //   }
    // }

    // if (!this.state.name) {
    //   this.setState({ nameError: { message: 'Ingrese un nombre' } });
    //   errors++;
    //   console.log('3');
    // }

    // if (!this.state.phone || (this.state.phone && this.state.phone.length > 12)) {
    //   this.setState({ phoneError: { message: 'Ingrese un número de teléfono válido' } });
    //   errors++;
    //   console.log('4');
    // }

    // if (!this.state.email || !(this.state.email && CommonService.isEMailValid(this.state.email))) {
    //   this.setState({ emailError: { message: 'Ingrese un correo electrónico válido' } });
    //   errors++;
    //   console.log('5');
    // }

    // if (!this.state.user_boading_address) {
    //   this.setState({
    //     user_boading_addressError: { message: 'Ingrese un correo dirección válido' },
    //   });
    //   errors++;
    //   console.log('6');
    // }

    if (errors > 0) {
      return false;
    } else {
      return true;
    }
  };

  validatePassengerDetailsForReserve = () => {
    this.setState({ isReservedTicket: true });
    let errors = 0;

    // if (this.state.docType === 'RUT') {
    //   if (!CommonService.isRutValid(this.state.identity_value)) {
    //     this.setState({ identity_valueError: { message: 'Ingrese un número de RUT válido' } });
    //     errors++;
    //     console.log('1');
    //   } 
    //   else if (!this.state.identity_value) {
    //     this.setState({
    //       identity_valueError: { message: 'Ingrese un número de documento válido' },
    //     });
    //     errors++;
    //     console.log('2');
    //   }
    // }

    // if (!this.state.name) {
    //   this.setState({ nameError: { message: 'Ingrese un nombre' } });
    //   errors++;
    //   console.log('3');
    // }

    // if (!this.state.phone || (this.state.phone && this.state.phone.length > 12)) {
    //   this.setState({ phoneError: { message: 'Ingrese un número de teléfono válido' } });
    //   errors++;
    //   console.log('4');
    // }

    // if (!this.state.email || !(this.state.email && CommonService.isEMailValid(this.state.email))) {
    //   this.setState({ emailError: { message: 'Ingrese un correo electrónico válido' } });
    //   errors++;
    //   console.log('5');
    // }

    if (this.state.additionalFareSelected && !this.state.additionalFareComment) {
      this.setState({ additionalFareCommentError: 'Required' });
      errors++;
      console.log('5');
    }

    if (!this.state.reserveDate) {
      this.setState({
        reserveDateError: { message: 'Reserve date  is required' },
      });
      errors++;
      console.log('7');
    }

    if (!this.state.reserveTime) {
      this.setState({
        reserveTimeError: { message: 'Reserve time is required' },
      });
      errors++;
      console.log('8');
    }
    // if (!this.state.user_boading_address) {
    //   this.setState({
    //     user_boading_addressError: { message: 'Ingrese un correo dirección válido' },
    //   });
    //   errors++;
    //   console.log('6');
    // }

    if (errors > 0) {
      return false;
    } else {
      return true;
    }
  };

  bookTicket = () => {
    this.setState({ showPaymentModal: false });
    if (!this.validatePassengerDetails()) {
      // alert('Fill all details');
      return;
    }

    // console.log('All good', this.state.selectedService);

    // return;

    let counterDetails = CommonService.getLocal('counter');

    // if (counterDetails === undefined) {
    //   this.setState({
    //     showSnackbar: true,
    //     alertMessage: 'No counter assigned please choose a counter first',
    //   });
    //   return;
    // }

    if (
      !this.state.selectedService ||
      (this.state.selectedService &&
        this.state.selectedService[0] &&
        !this.state.selectedService[0].selected)
    ) {
      this.setState({
        showSnackbar: true,
        alertMessage:
          'Estimado usuario, usted no ha seleccionado ningún servicio, por favor elija un servicio para continuar',
      });
      return;
    }

    let currentTime = DateService.getCurrentTimeUTC();

    let total_amount = this.getTotalPrice(
      this.state.selectedService.service_type,
      this.state.selectedService.price_type,
      this.state.selectedService.price,
      this.state.selectedService.seat_capacity,
      this.state.choosenKupos,
    );

    let price = this.getBookingPrice(
      this.state.selectedService.service_type,
      this.state.selectedService.price_type,
      this.state.selectedService.price,
      this.state.selectedService.seat_capacity,
    );

    let currencyAmount = this.calculateCurrencyAmount();

  

    let data = {
      correlation_id: `${this.state.selectedService.client_code.substr(
        0,
        3,
      )}_${this.state.user.substr(0, 3)}_${currentTime}`,
      user_id: CommonService.currentUser.user_id,
      user_name: CommonService.currentUser.user_name,
      branch_id: counterDetails.branch_id,
      counter_id: counterDetails.counter_id,
      counter_txn_id: counterDetails.counter_txn_id, // Returned from CounterOpen
      shift_id: counterDetails.shift_id,
      customer_name: this.state.name,
      customer_email: this.state.email,
      customer_phone: this.state.phone,
      customer_address: this.state.user_boading_address,
      pickup_loc_id: this.state.selectedService.pickup_location_id,
      pickup_latitude:
        this.state.origin.location && this.state.origin.location.lat
          ? this.state.origin.location.lat
          : this.state.origin.value,
      pickup_longitude:
        this.state.origin.location && this.state.origin.location.lon
          ? this.state.origin.location.lon
          : this.state.origin.value,
      pickup_address: this.state.origin.address,
      drop_loc_id: this.state.selectedService.drop_location_id,
      drop_latitude:
        this.state.destination.location && this.state.destination.location.lat
          ? this.state.destination.location.lat
          : this.state.destination.value,
      drop_longitude:
        this.state.destination.location && this.state.destination.location.lon
          ? this.state.destination.location.lon
          : this.state.destination.value,
      drop_address: this.state.destination.address,
      is_round_trip: 0, // 1 : If Yes, 0 : If No.
      booking_date_time_stg: `${DateService.getTodayString(
        'yyyy-mm-dd',
      )} ${DateService.getCurrentHour()}:${DateService.getCurrentMinute()} `, // YYYY-MM-DD HH:MM
      // booking_date_time: CommonService.getTimeInSeconds(
      //   DateService.getTodayString('yyyy-mm-dd') +
      //     'T' +
      //     DateService.getCurrentHour() +
      //     ':' +
      //     DateService.getCurrentMinute(),
      // ), 
      booking_date_time: DateService.getCurrentTimeUTCNew(),
      onward_trip_date_stg: DateService.changeDateFormat(
        this.state.selectedDate,
        'dd-mm-yyyy',
        'yyyy-mm-dd',
      ), // Date of the trip, YYYY-MM-DD
      onward_trip_time_stg: this.state.selectedTime, // Time of the trip, HH:MM
      onward_trip_ref_date_time: CommonService.getTimeInSecondsNew(
        DateService.changeDateFormat(this.state.selectedDate, 'dd-mm-yyyy', 'yyyy-mm-dd') +
          'T' +
          this.state.selectedTime,
      ), // UTC time in Unix Milli Seconds.
      no_of_seats: this.state.selectedKupos,
      price_type: this.state.selectedService.price_type,
      price: price,
      identity_type: this.state.docType,
      identity_value: this.state.identity_value,
      onward_service_pricing_id: this.state.selectedService.id,
      payment_type: this.state.selectedPaymentType.value, // CASH / CREDIT_CARD / DEBIT_CARD / BANK_TRANSFER / CREDIT_INVOICE
      currency: (this.state.selectedCurrency && this.state.selectedCurrency.to) || 'CLP',
      payment_ref_no: this.state.card_number || '',
      total_amount: total_amount,
      currency_amount: currencyAmount || 0,
      discount_code: this.state.discountCode ? this.state.discountCode : ' ',
      discount_amount: Number(this.state.discountAmount),
      additional_fare_type: this.state.additionalFareSelected
        ? this.state.additionalFareSelected.value
        : '',
      additional_fare: this.state.additionalFareAmount
        ? Number(this.state.additionalFareAmount)
        : 0,
      additional_fare_remark: this.state.additionalFareComment
        ? this.state.additionalFareComment
        : '',
      seat_meta_data: this.state.metaData,
      service_type: this.state.selectedService.service_type,
      confirm_deadline_stg:
        this.state.reserveDate && this.state.reserveTime
          ? DateService.changeDateFormat(this.state.reserveDate, 'dd-mm-yyyy', 'yyyy-mm-dd') +
            ' ' +
            this.state.reserveTime
          : '', // YYYY-MM-DD HH:MM
      confirm_deadline_utc:
        this.state.reserveDate && this.state.reserveTime
          ? CommonService.getTimeInSecondsNew(
              DateService.changeDateFormat(this.state.reserveDate, 'dd-mm-yyyy', 'yyyy-mm-dd') +
                'T' +
                this.state.reserveTime,
            )
          : 0, // UTC time in Unix Milli Seconds.
    };

    console.log('+++Data to fly-----', data);

    // return;

    this.props.actions
      .apiCall({
        url: AppData.BASE_URL_V2 + 'agent/booking',
        data: data,
        method: 'POST',
      })
      .then(
        res => {
          this.onBookingResponse(res);
          console.log('Data from api------', res);
          let newState = { ...this.state };
          var printData = { ...this.state.apiResponseForPrinting };
          printData.data = data;
          printData.pnr_no = res.data.data.onward_pnr_id;
          // printData.pnr_no = res.data.data.pnr_id;

          printData.fare = res.data.data.fare;
          printData.discount = res.data.data.discount;
          printData.totalAmount = res.data.data.totalAmount;
          printData.totalAmount = res.data.data.bookedBy;
          printData.totalAmount = res.data.data.printedBy;
          printData.totalAmount = res.data.data.printedBy;

          printData.origin = this.state.atOrignText && this.state.atOrignText;
          printData.destination = this.state.atDestinationText && this.state.atDestinationText;
          printData.date = newState.selectedDate;
          printData.time = newState.selectedTime;

          console.log('+++newState data is---', newState);
          console.log("+++print",printData);
          this.setState({ apiResponseForPrinting: printData });
        },
        err => {
          this.onBookingResponse(err);
        },
      );
  };

  onBookingResponse = result => {
    console.log('Result from final booking--------', result);
    if (result.data && result.data.success) {
      this.setState({
        successModalStatus: true,
        successModalMessage: result.message,
        showSuccessModal: true,
      });
      this.restoreState();
      // this.reloadPage();
    } else {
      this.setState({
        successModalStatus: false,
        successModalMessage: result.message,
        showSuccessModal: true,
      });
    }
  };

  restoreState = () => {
    this.setState({
      transferServiceError: true,
      selectedKupos: 1,
      selectedTime: '00:00',
      services: [],
      stepsMenu: [
        { label: 'Efectivo', active: true },
        { label: 'Tarjeta Débito', active: false },
        { label: 'Tarjeta Crédito', active: false },
        { label: 'Monto de transferencias bancarias', active: false },
        { label: 'Factura de Crédito', active: false },
      ],
      docType: 'RUT',
      selectedService: null,
      selectedServiceDirection: null,
      name: '',
      phone: '',
      email: '',
      origin: null,
      identity_value: '',
    });

    this.forceUpdate();
  };

  onTransferChange = (value, type) => {
    switch (type) {
      case 'selectedServiceDirection':
        this.setState({ selectedServiceDirection: value });
        break;
      case 'origin':
        this.setState({ origin: value, transferSourceCityError: null });
        break;
      case 'destination':
        this.setState({ destination: value, transferDestinationCityError: null });
        break;
      case 'date':
        this.setState({ selectedDate: value, showOnSelect: true });
        break;
      case 'returnDate':
        this.setState({ returnDate: value });
        break;
      case 'returnTime':
        this.setState({ returnTime: value });
        break;
      case 'time':
        this.setState({ selectedTime: value });
        break;
      case 'kupos':
        this.setState({ selectedKupos: value });
        break;
      case 'currency':
        this.setState({ selectedCurrency: value });
        break;
      case 'kupos_adult':
        this.setState({
          selectedAdultKupos: value < 1 ? 1 : value,
          selectedKupos: value < 1 ? 1 : value + this.state.selectedKidKupos,
        });
        break;
      case 'kupos_kid':
        this.setState({
          selectedKidKupos: value < 1 ? 0 : value,
          selectedKupos: this.state.selectedAdultKupos + value,
        });
        break;
      case 'kupos_infant':
        this.setState({ selectedInfantKupos: value < 1 ? 0 : value });
        break;
      case 'discount':
        this.setState({ discountAmount: value, discountCode: 'coupen' });
      case 'reserveDate':
        this.setState({ selectedReserveDate: value });
        break;
      case 'reserveTime':
        this.setState({ selectedReserveDate: value });
        break;
    }
  };

  validate = () => {
    let errors = 0;
    // if (!this.state.selectedServiceDirection) {
    //   errors++;
    //   // return false;
    // } else
    if (!this.state.origin) {
      this.setState({ transferSourceCityError: { message: 'Seleccione la origen' } });
      errors++;
    }
    if (!this.state.destination) {
      this.setState({ transferDestinationCityError: { message: 'Seleccione la destino' } });
      // return false;
      errors++;
    }
    if (!this.state.selectedTime) {
      this.setState({ selectedDateTimeError: { message: 'select both time and date' } });
      // return false;
      errors++;
    }
    if (!this.state.selectedDate) {
      this.setState({ selectedDateTimeError: { message: 'select both time and date' } });
      // return false;
      errors++;
    }

    if (errors > 0) {
      return false;
    } else {
      return true;
    }
  };

  searchTransfer = () => {
    if (!this.validate()) {
      console.log('Validation failed ');
      return;
    }

    // this.getAdditionalFareList();

    this.setState({
      choosenKupos: this.state.selectedKupos,
      selectedService: [],
      selectedServiceId: null,
    });

    // console.log('Data to pass in api is', this.state.origin, this.state.destination);
    // return;

    // let currentUTC = DateService.getCurrentTimeUTC();
    let currentUTC = DateService.getCurrentTimeUTCNew();

    let seats = Number(this.state.selectedAdultKupos) + Number(this.state.selectedKidKupos);
    this.setState({ showLoader: true });

    let trfrType = 0;
    if (this.state.origin.value) {
      trfrType = 1;
    } else if (this.state.destination.value) {
      trfrType = 2;
    }

    let originLat =
      this.state.origin.location && this.state.origin.location.lat
        ? this.state.origin.location.lat
        : this.state.origin.value;
    let originLon =
      this.state.origin.location && this.state.origin.location.lon
        ? this.state.origin.location.lon
        : this.state.origin.value;

    let destLat =
      this.state.destination.location && this.state.destination.location.lat
        ? this.state.destination.location.lat
        : this.state.destination.value;
    let destLon =
      this.state.destination.location && this.state.destination.location.lon
        ? this.state.destination.location.lon
        : this.state.destination.value;

    let originLocId = this.state.origin.value ? this.state.origin.value : 0;
    let destLocId = this.state.destination.value ? this.state.destination.value : 0;
    this.props.actions
      .apiCall({
        url:
          AppData.BASE_URL_V3 +
          `service_inventory/${originLat}/${originLon}/${originLocId}/${DateService.getTimeInSeconds(
            DateService.changeDateFormat(this.state.selectedDate, 'dd-mm-yyyy', 'yyyy-mm-dd') +
              'T' +
              this.state.selectedTime,
          )}/${destLat}/${destLon}/${destLocId}/0/${seats}/${currentUTC}/${trfrType}`,

        // AppData.BASE_URL_V2 +
        // `service_inventory/${this.state.origin.location.lat}/${
        //   this.state.origin.location.lon
        // }/${DateService.getTimeInSeconds(
        //   DateService.changeDateFormat(this.state.selectedDate, 'dd-mm-yyyy', 'yyyy-mm-dd') +
        //     'T' +
        //     this.state.selectedTime,
        // )}/${this.state.destination.location.lat}/${
        //   this.state.destination.location.lon
        // }/0/${seats}/${currentUTC}`,
      })
      .then(
        res => {
          this.onServicePriceAPIResponse(res);
        },
        err => {
          this.onServicePriceAPIResponse(err);
        },
      );
  };

  onServicePriceAPIResponse = result => {
    let noOfServices = [];

    console.log('Result from search api is-----', result);

    // return;

    if (
      result &&
      result.data &&
      result.data.success &&
      result.data.data &&
      (result.data.data.onward_service_list || result.data.data.return_service_list)
    ) {
      // this.setState({ services: result.data.data });

      this.setState({
        onwardServices: result.data.data.onward_service_list,
        returnServices: result.data.data.return_service_list,
      });

      result.data.data &&
        result.data.data.onward_service_list &&
        Object.keys(result.data.data.onward_service_list).forEach(element => {
          noOfServices.push({
            selected: false,
          });
          console.log('Elemet to push', element);
        });

      // this.setState({
      //   onwardServices: result.data.data.onward_service_list,
      //   returnServices: result.data.data.onward_service_list,
      // });

      this.setState({
        selectedService: noOfServices,
      });

      console.log('No of services', noOfServices);
    } else {
      this.setState({
        selectedService: null,
        showSnackbar: true,
        alertMessage:
          '¡Lo sentimos!\n\n Este servicio no está disponible en la fecha que seleccionada. Por favor intenta con otra fecha.',
        noResults:
          '¡Lo sentimos!Este servicio no está disponible en la fecha que seleccionada. Por favor intenta con otra fecha',
      });
    }
    this.setState({ showLoader: false });
  };

  onStepClick = menuItem => {
    console.log('Menu item ', menuItem);
    this.setState({ selectedPaymentType: this.state.stepsMenu[menuItem - 1] });

    console.log('PAYMENT TYPE IS ', this.state.stepsMenu[menuItem - 1]);
    // Highlight the selected Menu Item

    //  let elementsIndex = this.state.stepsMenu.findIndex(
    //    element => element.key == menuItem - 1,
    //  );

    let elementsIndex = menuItem - 1;

    console.log('Element index is', elementsIndex);

    let newMenus = [...this.state.stepsMenu];

    newMenus.forEach((item, key) => {
      newMenus[key] = {
        ...newMenus[key],
        active: false,
      };
    });

    // console.log('New Menu is ', newMenus);

    newMenus[elementsIndex] = {
      ...newMenus[elementsIndex],
      active: !newMenus[elementsIndex].active,
    };

    this.setState({ stepsMenu: newMenus });
    this.forceUpdate();
  };

  // when inputs change -----------------------------
  onBookingDetailsChange = (value, type) => {
    console.log(type, value);

    if (type === 'identity_value') {
      this.setState({
        identity_value: this.state.docType === 'RUT' ? CommonService.formatRut(value) : value,
        identity_valueError: null,
      });
    } else if (type === 'phone') {
      this.setState({ phone: CommonService.onlyNumbers(value), phoneError: null });
    } else {
      this.setState({
        [type]: value,
        [type + 'Error']: null,
      });
    }
  };

  onBookingDetailsBlur = (value, type) => {
    switch (type) {
      case 'identity_value':
        if (!CommonService.isRutValid(value)) {
          this.setState({ identity_valueError: { message: 'Ingrese un RUT' } });
        }
        // alert(this.state.identity_value);

        // make api call to get passenger information and autofill the fields
        this.props.actions
          .apiCall({
            url:
              AppData.BASE_URL_V1 +
              `agent/customerInfo/${this.state.docType}/${this.state.identity_value}`,
          })
          .then(
            res => {
              this.onPassengerInformationResponse(res);
            },
            err => {
              this.onPassengerInformationResponse(err);
            },
          );

        break;
      case 'phone':
        if (value.length > 12) {
          this.setState({ phoneError: { message: 'Ingrese un número de teléfono válido' } });
        }
        break;

      case 'email':
        if (!CommonService.isEMailValid(value)) {
          this.setState({ emailError: { message: 'Ingrese un correo electrónico válido' } });
        }
        break;
    }
  };

  onPassengerInformationResponse = res => {
    console.log({ res });
    if (res && res.data && res.data.success) {
      this.setState({
        identity_value: res.data.data.identity_value,
        name: res.data.data.name,
        phone: res.data.data.phone,
        email: res.data.data.email,
        docType: res.data.data.identity_type,
        user_boading_address: res.data.data.address,
        phoneError: '',
        emailError: '',
      });
    }

    if (res && res.data && !res.data.success) {
      this.setState({
        name: '',
        phone: '',
        email: '',
        user_boading_address: '',
      });
    }
  };

  getTicketDetails = () => {
    if (!this.state.pnrEntered) {
      this.setState({
        pnrEnteredError: { message: 'Ingrese un PNR' },
        showSnackbar: true,
        alertMessage: 'Ingrese un PNR',
      });
      return;
    }
    this.setState({ searchScreen: false });
    // let data = {
    //   name: 'raqib',
    //   class: 'jksdgjfh',
    // };
    // this.props.actions.setDataDummy({ data: data });

    this.props.actions
      .apiCall({
        url: AppData.BASE_URL + `ticketDetails/${this.state.pnrEntered}`,
      })
      .then(
        res => {
          this.onTicketDetailsFetch(res);
        },
        err => {
          this.onTicketDetailsFetch(err);
        },
      );

    // console.log('data is', data);

    // this.props.history.push('/details-page');
  };

  onTicketDetailsFetch = res => {
    if (res && res.data && res.data.success && res.data.data) {
      this.props.actions.setSearchedTicketDetails(res.data.data);
      this.props.history.push('/details-page');
    } else {
      this.setState({
        showSnackbar: true,
        alertMessage: 'PNR inválido, por favor verifique el PNR e intente nuevamente',
        pnrEnteredError: { message: 'PNR inválido' },
      });
    }
    console.log('onTicketDetailsFetch--------', res);
  };

  onPnrChange = value => {
    this.setState({ pnrEntered: value, pnrEnteredError: null });
  };

  closeSnackbar = () => {
    this.setState({ showSnackbar: false });
  };

  setReserveFlags = () => {
    if (!this.validatePassengerDetailsForReserve()) {
      // alert('Fill all details in reserve');
      return;
    }
    console.log('+++ set reserve flags', this.state);
    this.decideBookingFlow();
  };

  decideBookingFlow = () => {
    let method;
    this.state.stepsMenu.forEach(element => {
      if (element.active) {
        method = element;
      }
    });

    console.log('Method selected -------', method);

    if (method.value === 'CASH') {
      this.bookTicket();
    } else {
      this.setState({ showPaymentModal: true });
    }
  };

  reloadPage = () => {
    window.location.reload();
  };

  onStripDateClick = date => {
    console.log('Date clicked is------$$$$$$', date);
    this.setState({ selectedDate: date });

    setTimeout(() => {
      this.searchTransfer();
    }, 1000);
  };

  onATInputBlur = (val, type) => {
    // return;
    setTimeout(() => {
      if (type === 'origin') {
        this.setState({
          showOriginTerminalSuggestions: false,
          // showDestinationTerminalSuggestions: false,
        });
      } else {
        this.setState({
          showDestinationTerminalSuggestions: false,
          // showOriginTerminalSuggestions: false,
        });
      }
    }, 300);
  };

  onATInputFocus = (type, event) => {
    if (type === 'origin')
      this.setState({
        showOriginTerminalSuggestions: true,
        originType: this.state.setPickupTerminal ? 'text' : 'places',
      });
    else if (type === 'destination')
      this.setState({
        showDestinationTerminalSuggestions: true,
        destinationType: this.state.setDestinationTerminal ? 'text' : 'places',
        // destinationReadonly: this.state.setPickupTerminal ? false : true,
      });
  };

  renderATTerminals = (val, key, type) => {
    console.log('+++ value , key and type is', val, key, type);
    return (
      <div onClick={() => this.onSelect(val, type)}>
        <a
          // className={}
          key={key}
          className="at-terminal-item"
        >
          {/* <img src="/images/icons/home/airport-icon.png" /> */}
          {val.label}
        </a>
      </div>
    );
  };

  onSelect = (val, type) => {
    if (type == 'origin') {
      this.setState({
        showOriginTerminalSuggestions: false,
        setPickupTerminal: val,
        atOrignText: val.label,
        originType: 'text',
        origin: val,
        airportChoosen: true,
      });

      // this.props.onTransferChange(val, 'origin');
    } else {
      this.setState({
        showDestinationTerminalSuggestions: false,
        setDestinationTerminal: val,
        atDestinationText: val.label,
        destinationType: 'text',
        destination: val,
        airportChoosen: true,
      });
      // this.props.onTransferChange(val, 'destination');
    }
  };

  eraseSelectedInput = type => {
    let val = '';
    switch (type) {
      case 'origin':
        this.setState({
          atOrignText: val,
          setPickupTerminal: null,
          airportChoosen: false,
          airportChoosen: this.state.setDestinationTerminal ? true : false,
          originType: 'places',
        });
        // this.props.onTransferChange(val, 'origin');
        break;
      case 'destination':
        this.setState({
          atDestinationText: val,
          airportChoosen: false,
          airportChoosen: this.state.setPickupTerminal ? true : false,
          destinationType: 'places',
          // destinationReadonly: false,
          setDestinationTerminal: null,
        });
        // this.props.onTransferChange(val, 'destination');
        break;
    }
  };

  render() {
    console.log('+++props in render--------', this.props);

    console.log('+++State ____________ ', this.state);
    // console.log('this.state.selectedService------', this.state.selectedService);
    // console.log('Counter details-------', CommonService.getLocal('counter'));
    // console.log('selectedCurrency-------', this.state.selectedCurrency);
    return (
      <div className="home-default-page">
        <hr className="pb-3" />
        <div className="search-customer-section">
          <div className="vendor-pasaje" style={{ marginTop: 2 }} onClick={() => this.reloadPage()}>
            <span className="vendor-icon">
              <img src="/images/my-trips.png" />
            </span>
            <span className="vendor-text font12">VENDER PASAJE</span>
          </div>

          <div className="common-kupos-input-box document-search" style={{ marginLeft: 10 }}>
            <KuposInput
              type="email"
              className="kupos-border"
              placeholder={'RUT / Nº reserva / Correo'}
              //  value={}
              error={this.state.pnrEnteredError ? true : false}
              errorMessage={this.state.pnrEnteredError ? this.state.pnrEnteredError.message : ''}
              onChange={val => this.onPnrChange(val)}
              onBlur={text => this.onBlur(text, 'pnr')}
            />
          </div>

          <div className="doc-search-button font12">
            <KuposButton onClick={this.getTicketDetails} className="doc-search-button-inner">
              <span className="vendor-icon">
                <img src="/images/ticket-search.png" />
              </span>
            </KuposButton>
          </div>
        </div>

        <hr className="mb-0 mt-3" />
        <div className="search-box-container mt-3">
          <div className="home-selection-input-boxes" key={this.props.selectedSearchApp}>
            {/* <div className="home-selection-input-box font10 select-box">
              <span className="label font11">{'Origen'}</span>
              <DropdownComponent
                onChange={val => this.onTransferChange(val, 'selectedServiceDirection')}
                placeholder={'Select Direction'}
                options={this.selectServiceOptions}
                value={this.state.selectedServiceDirection}
                error={this.state.selectedServiceDirectionError ? true : false}
                errorMessage={this.state.selectedServiceDirectionError}
              />
            </div> */}

            <div className="home-selection-input-box font10" style={{ position: 'relative' }}>
              <span className="label font11">{'Origen'}</span>

              {/* <KuposInput
                type="places"
                value={this.state.atOriginText}
                onTextChange={val => this.setState({ atOriginText: val })}
                onChange={val => this.onTransferChange(val, 'origin')}
                placeholder={'Origen'}
                icon={'/svgs/origin.svg'}
                error={this.state.transferSourceCityError ? true : false}
                errorMessage={
                  this.state.transferSourceCityError
                    ? this.state.transferSourceCityError.message
                    : ''
                }
                height={40}
              /> */}

              <KuposHybridInput
                type={this.state.originType}
                value={this.state.atOrignText}
                onTextChange={val =>
                  this.setState({
                    showOriginTerminalSuggestions: val ? false : true,
                    atOrignText: val,
                  })
                }
                onBlur={val => this.onATInputBlur(val, 'origin')}
                onChange={val => this.onTransferChange(val, 'origin')}
                placeholder={'Origen'}
                icon={'/svgs/origin.svg'}
                error={this.state.transferSourceCityError ? true : false}
                errorMessage={
                  this.state.transferSourceCityError
                    ? this.state.transferSourceCityError.message
                    : ''
                }
                height={40}
                fontSize="font11"
                erasable={true}
                onFocus={event => this.onATInputFocus('origin', event)}
                eraseSelectedInput={() => this.eraseSelectedInput('origin')}
                // ref={'atOriginRef'}
              />
              {this.state.selectedAtTerminals &&
                this.state.showOriginTerminalSuggestions &&
                !this.state.airportChoosen && (
                  <div className="at-termninal-suggestions auto-suggestions font10 scroll-style">
                    {this.state.selectedAtTerminals &&
                      this.state.selectedAtTerminals
                        .sort((a, b) => b.label.localeCompare(a.label))
                        .map((val, key) => {
                          return this.renderATTerminals(val, key, 'origin');
                        })}
                  </div>
                )}
            </div>

            <div className="home-selection-input-box font10" style={{ position: 'relative' }}>
              <span className="label font11">{'Destino'}</span>

              {/* <KuposInput
                type="places"
                value={this.state.atDestnationText}
                onTextChange={val => this.setState({ atDestnationText: val })}
                onChange={val => this.onTransferChange(val, 'destination')}
                placeholder={'Destino'}
                icon={'/svgs/deestination.svg'}
                error={this.state.transferDestinationCityError ? true : false}
                errorMessage={
                  this.state.transferDestinationCityError
                    ? this.state.transferDestinationCityError.message
                    : ''
                }
                height={40}
              /> */}

              <KuposHybridInput
                type={this.state.destinationType}
                value={this.state.atDestinationText}
                onTextChange={val =>
                  this.setState({
                    showDestinationTerminalSuggestions: val ? false : true,
                    // showDestinationTerminalSuggestions: this.state.destinationReadonly ? true : false,
                    atDestinationText: val,
                    // atDestinationText: this.state.destinationReadonly ? null : val,
                    // destinationType: this.state.setDestinationTerminal ? 'text' : 'places',
                  })
                }
                onBlur={val => this.onATInputBlur(val, 'destination')}
                onChange={val => this.onTransferChange(val, 'destination')}
                placeholder={'Destino'}
                icon={'/svgs/deestination.svg'}
                error={this.state.transferDestinationCityError ? true : false}
                errorMessage={
                  this.state.transferDestinationCityError
                    ? this.state.transferDestinationCityError.message
                    : ''
                }
                height={40}
                fontSize="font11"
                erasable={true}
                onFocus={event => this.onATInputFocus('destination', event)}
                eraseSelectedInput={() => this.eraseSelectedInput('destination')}
              />

              {this.state.origin &&
                this.state.showDestinationTerminalSuggestions &&
                !this.state.airportChoosen && (
                  <div className="at-termninal-suggestions auto-suggestions font10 scroll-style">
                    {this.state.selectedAtTerminals &&
                      this.state.selectedAtTerminals
                        .sort((a, b) => b.label.localeCompare(a.label))
                        .map((val, key) => {
                          return this.renderATTerminals(val, key, 'destination');
                        })}
                  </div>
                )}
            </div>

            <div className="home-selection-input-box font10 datebox">
              <span className="label font11">{'Ida'}</span>
              <KuposDateTimePicker
                selectedDate={
                  this.state.selectedDate ? this.state.selectedDate : DateService.getTodayString()
                }
                selectedTime={this.state.selectedTime}
                onDateChange={val => this.onTransferChange(val, 'date')}
                onTimeChange={val => this.onTransferChange(val, 'time')}
                minDate={DateService.getTodayString()}
                error={this.state.selectedDateError || this.state.selectedTimeError ? true : false}
                errorMessage={this.state.selectedDateTimeError}
                placeholder={DateService.getTodayString()}
                // showOnSelect={this.state.showOnSelect}
                showOnSelect={this.state.showOnSelect}
              />
            </div>

            <div className="home-selection-input-box font10 datebox">
              <span className="label font11">{'Vuelta(Opcional)'}</span>
              <KuposDateTimePicker
                selectedDate={DateService.getTodayString()}
                selectedTime={'00:00'}
                onDateChange={val => this.onTransferChange(val, 'returnDate')}
                onTimeChange={val => this.onTransferChange(val, 'returnTime')}
                minDate={DateService.getTodayString()}
                optional={true}
                disabled={true}
                // error={this.state.selectedDateError || this.state.selectedTimeError ? true : false}
                // errorMessage={this.state.selectedDateTimeError}
                // optional ={true}
              />
            </div>

            <div className="home-selection-input-box font10 counter">
              <span className="label font11">{'Cupos'}</span>
              <div
                className="show-count"
                onClick={() => this.setState({ showAtCounter: !this.state.showAtCounter })}
              >
                <div>
                  <img
                    className="at-count-user-icon"
                    src="/images/kupos-AirportTransfer-Icon-Userkupos.svg"
                  />
                </div>
                <div>
                  <span>
                    {this.state.selectedKupos}
                    &nbsp;Pasajero
                  </span>
                </div>
                <div>
                  <img
                    className="at-count-down-icon"
                    src="/images/kupos-AirportTransfer-Icon-Arrow.svg"
                  />
                </div>
              </div>
              {this.state.showAtCounter ? (
                <div
                  className="at-counter-popoup"
                  onMouseLeave={() => this.setState({ showAtCounter: false })}
                >
                  <KuposAtCounter
                    selectedKupos={
                      this.state.selectedAdultKupos ? this.state.selectedAdultKupos : 1
                    }
                    onTransferChange={val => this.onTransferChange(val, 'kupos_adult')}
                    kuposType={'kupos_adult'}
                    borderBottom
                  />

                  <KuposAtCounter
                    selectedKupos={this.state.selectedKidKupos ? this.state.selectedKidKupos : 0}
                    onTransferChange={val => this.onTransferChange(val, 'kupos_kid')}
                    kuposType={'kupos_kid'}
                    borderBottom
                  />

                  <KuposAtCounter
                    selectedKupos={
                      this.state.selectedInfantKupos ? this.state.selectedInfantKupos : 0
                    }
                    onTransferChange={val => this.onTransferChange(val, 'kupos_infant')}
                    kuposType={'kupos_infant'}
                  />
                </div>
              ) : null}
            </div>

            <div className="home-selection-input-box font12">
              <button
                className="kupos-button home-selection-input-button "
                onClick={this.searchTransfer}
              >
                <img
                  className="icon-size"
                  src="/images/icons/home/icon_search-white.png"
                  alt={'TRANSLATIONS.ICON_SEARCH_WHITE'}
                />{' '}
                {/* <span>{'HOME.SEARCH'}</span> */}
              </button>
            </div>
          </div>
        </div>

        {this.state.selectedService && this.state.searchScreen ? (
          <div className="services-and-details">
            <div className="available-services">
              <StripDateFilter
                onStripDateClick={val => this.onStripDateClick(val)}
                selectedDate={this.state.selectedDate}
              />

              <div className="service-container">
                {this.state.onwardServices &&
                  this.state.onwardServices.map((val, key) => this.renderService(val, key))}
              </div>

              <div className="details-of-transaction font10">
                <div className="main-details">
                  <div>Detalles de la transacción</div>
                  <div className="payment-type">
                    <span>Tipo de pago</span>
                    <KuposSteps
                      menuButtons={this.state.stepsMenu}
                      //  t={this.props.t}
                      onStepClick={val => this.onStepClick(val)}
                    />
                  </div>

                  <div
                    className="aggrement-discount"
                    style={{ marginTop: 50, marginBottom: 10, alignItems: 'end' }}
                  >
                    <div className="home-selection-input-box font10 select-box">
                      <div>
                        <span className="label font11">{'Descuento por convenio'}</span>
                      </div>

                      <DropdownComponent
                        placeholder={'Descuento por convenio'}
                        disabled={true}
                        options={this.state.selectedDiscounts}
                        value={this.state.selectedDiscount}
                        onChange={val => this.onTransferChange(val, 'corporate_agency')}
                        error={this.state.selectedCurrencyError ? true : false}
                        errorMessage={
                          this.state.selectedCurrencyError
                            ? this.state.selectedCurrencyError.message
                            : ''
                        }
                      />
                    </div>

                    <div className="home-selection-input-box font10 select-box">
                      <div>
                        <span className="font11">{'Descuento monto'}</span>
                      </div>
                      <KuposInput
                        className="kupos-border"
                        type="text"
                        placeholder="Descuento monto"
                        //  value={this.props.kuposGoData.name}
                        onChange={val => this.onTransferChange(val, 'discount')}
                        onBlur={text => {}}
                        error={this.state.kuposGoNameError ? true : false}
                        errorMessage={this.state.kuposGoNameError}
                        style={{ height: 40 }}
                        inputOuterStyle={{ height: 40 }}
                      />
                    </div>

                    <div className="home-selection-input-box font10 select-box">
                      <div>
                        <span className="label font11">{'Moneda'}</span>
                      </div>

                      <DropdownComponent
                        onChange={val => this.onTransferChange(val, 'currency')}
                        placeholder={'Moneda'}
                        options={this.state.exchanges}
                        value={this.state.selectedCurrency}
                        error={this.state.selectedCurrencyError ? true : false}
                        errorMessage={
                          this.state.selectedCurrencyError
                            ? this.state.selectedCurrencyError.message
                            : ''
                        }
                      />
                    </div>
                  </div>

                  {/* ADDITIONAL FARE UI */}
                  <div
                    className="aggrement-discount"
                    style={{ marginTop: 20, marginBottom: 10, alignItems: 'flex-start' }}
                  >
                    <div className="home-selection-input-box font10 select-box">
                      <DropdownComponent
                        placeholder={'Tarifa adicional'}
                        // disabled={true}
                        options={this.state.additionalFares}
                        value={this.state.additionalFareSelected}
                        onChange={val => this.setState({ additionalFareSelected: val })}
                        // error={this.state.selectedCurrencyError ? true : false}
                        // errorMessage={
                        //   this.state.selectedCurrencyError
                        //     ? this.state.selectedCurrencyError.message
                        //     : ''
                        // }
                      />
                    </div>

                    <div className="home-selection-input-box font10 select-box">
                      <KuposInput
                        className="kupos-border"
                        type="number"
                        placeholder="Monto"
                        value={this.state.additionalFareAmount}
                        onChange={val => {
                          if (val < 0) {
                            this.setState({ additionalFareAmount: 0 });
                          } else {
                            this.setState({ additionalFareAmount: val });
                          }
                        }}
                        onBlur={text => {}}
                        // error={this.state.kuposGoNameError ? true : false}
                        // errorMessage={this.state.kuposGoNameError}
                        style={{ height: 40 }}
                        inputOuterStyle={{ height: 40 }}
                        optional={true}
                      />
                    </div>

                    <div className="home-selection-input-box font10 select-box">
                      <textarea
                        className="kupos-border"
                        type="text"
                        placeholder="Comentario"
                        value={this.state.additionalFareComment}
                        onChange={val => {
                          if (this.state.additionalFareComment) {
                            this.setState({
                              additionalFareComment: val.target.value,
                              additionalFareCommentError: '',
                            });
                          }
                          this.setState({ additionalFareComment: val.target.value });
                        }}
                        onBlur={text => {}}
                        error={this.state.additionalFareCommentError ? true : false}
                        errorMessage={this.state.additionalFareCommentError}
                        rows={5}
                        cols={15}
                        style={
                          this.state.additionalFareCommentError
                            ? { border: 'red 1px solid', borderRadius: 10, padding: 6 }
                            : { borderRadius: 10, padding: 6 }
                        }
                        // inputOuterStyle={{ height: 40 }}
                      />
                      {this.state.additionalFareCommentError && (
                        <span style={{ color: 'red' }}>
                          {this.state.additionalFareCommentError}
                        </span>
                      )}
                    </div>
                  </div>
                </div>

                <div className="discount-details">
                  
                  <div className="two-items-in-line">
                    <span>Monto total</span>
                    <span>
                      CLP
                      {CommonService.currency(
                        this.state.selectedService &&
                          this.state.selectedService.price_type === 'PER_VEHICLE'
                          ? this.state.selectedService.price
                          : this.state.selectedService.price * this.state.choosenKupos,
                      )}
                    </span>
                  </div>

                  <div className="two-items-in-line">
                    <span>Descuento total</span>
                    <span>{CommonService.currency(this.state.discountAmount || 0)}</span>
                  </div>

                  <div className="two-items-in-line">
                    <span>Tarifa adicional</span>
                    <span>
                      $ {this.state.additionalFareAmount ? this.state.additionalFareAmount : 0}
                    </span>
                  </div>
                  <div className="two-items-in-line">
                    <span>Monto neto</span>
                    <span>
                      CLP
                      {CommonService.currency(
                        this.state.selectedService &&
                          this.state.selectedService.price_type === 'PER_VEHICLE'
                          ? this.state.selectedService.price +
                              Number(this.state.additionalFareAmount) -
                              this.state.discountAmount
                          : this.state.selectedService.price * this.state.choosenKupos -
                              this.state.discountAmount +
                              Number(this.state.additionalFareAmount),
                      )}
                    </span>
                  </div>

                  <div className="two-items-in-line">
                    <span>
                      {this.state.selectedCurrency
                        ? `Monto neto ${this.state.selectedCurrency.to}`
                        : 'Choose currency'}
                    </span>
                    <span>
                      {this.state.selectedCurrency && this.state.selectedCurrency.to}
                      {CommonService.currency(
                        this.state.selectedService &&
                          this.state.selectedCurrency &&
                          Number(
                            this.state.selectedService.price_type === 'PER_VEHICLE'
                              ? this.state.selectedService.price +
                                  Number(this.state.additionalFareAmount) -
                                  this.state.discountAmount
                              : this.state.selectedService.price * this.state.choosenKupos -
                                  this.state.discountAmount +
                                  Number(this.state.additionalFareAmount),
                          ) *
                            Number(
                              (this.state.selectedCurrency &&
                                this.state.selectedCurrency.rate &&
                                this.state.selectedCurrency.rate) ||
                                1,
                            ),
                      )}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="details-sidebar">
              <div className="input-containers-bulk">
                <div className="doc-selection font11">
                  <KuposRadio
                    onChange={() => this.setState({ docType: 'RUT' })}
                    checked={this.state.docType === 'RUT'}
                    label="RUT"
                  />
                  <KuposRadio
                    onChange={() => this.setState({ docType: 'OTHER' })}
                    checked={this.state.docType === 'OTHER'}
                    label="Otro"
                  />
                </div>
                <div className="name-input">
                  <KuposInput
                    className="kupos-border"
                    type="text"
                    //  placeholder="Nombre"
                    value={this.state.identity_value}
                    onChange={val => this.onBookingDetailsChange(val, 'identity_value')}
                    onBlur={val => {
                      this.onBookingDetailsBlur(val, 'identity_value');
                    }}
                    error={
                      this.state.identity_valueError && this.state.docType === 'RUT' ? true : false
                    }
                    errorMessage={
                      this.state.identity_valueError ? this.state.identity_valueError.message : null
                    }
                    // style={{ height: 40 }}
                    inputOuterStyle={{ height: 40 }}
                  />
                </div>

                <div className="name-input">
                  <div>
                    <span className="font11">Nombre completo</span>
                    {/* <span className="font11">{'HOME.NAME'}</span> */}
                  </div>
                  <KuposInput
                    className="kupos-border"
                    type="text"
                    placeholder="Nombre completo"
                    value={this.state.name}
                    onChange={val => this.onBookingDetailsChange(val, 'name')}
                    onBlur={text => {}}
                    // error={this.state.nameError ? true : false}
                    // errorMessage={this.state.nameError ? this.state.nameError.message : ''}
                    // style={{ height: 40 }}
                    inputOuterStyle={{ height: 40 }}
                  />
                </div>

                <div className="name-input">
                  <div>
                    <span className="font11">Teléfono</span>
                  </div>
                  <KuposInput
                    className="kupos-border"
                    type="text"
                    placeholder="9XXXXXXXX"
                    value={this.state.phone}
                    onChange={val => this.onBookingDetailsChange(val, 'phone')}
                    onBlur={val => this.onBookingDetailsBlur(val, 'phone')}
                    error={this.state.phoneError ? true : false}
                    errorMessage={this.state.phoneError ? this.state.phoneError.message : null}
                    // style={{ height: 40 }}
                    inputOuterStyle={{ height: 40 }}
                  />
                </div>

                <div className="name-input">
                  <div>
                    <span className="font11">Correo electrónico</span>
                  </div>
                  <KuposInput
                    className="kupos-border"
                    type="text"
                    placeholder="Ingresa su correo electrónico"
                    value={this.state.email}
                    onChange={val => this.onBookingDetailsChange(val, 'email')}
                    onBlur={val => this.onBookingDetailsBlur(val, 'email')}
                    error={this.state.emailError ? true : false}
                    errorMessage={this.state.emailError ? this.state.emailError.message : null}
                    // style={{ height: 40 }}
                    inputOuterStyle={{ height: 40 }}
                  />
                </div>

                <div className="name-input">
                  <div>
                    <span className="font11">Dirección</span>
                  </div>
                  <KuposInput
                    className="kupos-border"
                    type="text"
                    placeholder="Dirección"
                    value={this.state.user_boading_address}
                    onChange={val => this.onBookingDetailsChange(val, 'user_boading_address')}
                    onBlur={text => {}}
                    // error={this.state.user_boading_addressError ? true : false}
                    // errorMessage={
                    //   this.state.user_boading_addressError
                    //     ? this.state.user_boading_addressError.message
                    //     : ''
                    // }
                    // style={{ height: 40 }}
                    inputOuterStyle={{ height: 40 }}
                  />
                </div>

                <div className="home-selection-input-box font10 datebox">
                  <span className="label font11">{'Reserva'}</span>
                  <KuposDateTimePicker
                    selectedDate={
                      this.state.reserveDate ? this.state.reserveDate : DateService.getTodayString()
                    }
                    selectedTime={this.state.reserveTime ? this.state.reserveTime : '00:00'}
                    onDateChange={val => this.onBookingDetailsChange(val, 'reserveDate')}
                    onTimeChange={val => this.onBookingDetailsChange(val, 'reserveTime')}
                    minDate={DateService.getTodayString()}
                    error={
                      this.state.reserveDateError || this.state.reserveTimeError ? true : false
                    }
                    errorMessage={this.state.reserveDateError ? this.state.reserveDateError.message : ''}
                    // placeholder={DateService.getTodayString()}
                    showOnSelect={this.state.showOnSelect}
                  />
                </div>

                <div className="button-container">
                  {/* <KuposButton onClick={this.bookTicket}> */}
                  {/* <KuposButton onClick={() => this.setState({ showPaymentModal: true })}> */}
                  <div style={{ marginRight: 10 }}>
                    <KuposButton onClick={this.setReserveFlags}>
                      <span>RESERVE VENTA</span>
                    </KuposButton>
                  </div>

                  <div>
                    <KuposButton onClick={() => this.decideBookingFlow()}>
                      <span>CONFIRMAR VENTA</span>
                    </KuposButton>
                  </div>
                </div>
              </div>

              {/* <div style={{ visibility: 'hidden' }}>
                <PrintTicket printData={this.state.apiResponseForPrinting}></PrintTicket>
              </div> */}
            </div>
          </div>
        ) : (
          <DataNotFound
            icon="/images/our_mission.svg"
            title={'Sin resultados'}
            subTitle={'Buscar nuestros servicios '}
            infoText={
              'Este servicio no está disponible en la fecha que seleccionada. Por favor intenta con otra fecha.'
            }
            onInfoClick={() => this.setState({ showAddBIPCard: true })}
          />
        )}

        <div style={{ visibility: 'hidden' }}>
          <PrintTicket printData={this.state.apiResponseForPrinting}></PrintTicket>
        </div>

        <KuposErrorSuccessModal
          success={this.state.successModalStatus}
          showModal={this.state.showSuccessModal}
          onButtonClick={() => {
            this.state.successModalStatus &&
              setTimeout(() => {
                CommonService.printCancelTicket(document.getElementById('print-ticket').innerHTML);
                // window.print();

                this.setState(
                  {
                    showSuccessModal: false,
                    successModalStatus: false,
                    successModalMessage: false,
                  },
                  // () => {
                  //   window.print();
                  // },
                );
              }, 1000);

            this.state.successModalStatus &&
              setTimeout(() => {
                this.reloadPage();
              }, 3000);
          }}
          bodyText={this.state.successModalMessage}
        />

        {this.state.showPaymentModal && (
          <BookingPaymentModel
            showModal={this.state.showPaymentModal}
            onHide={() => this.setState({ showPaymentModal: false })}
            onInputChange={val => this.onBookingDetailsChange(val, 'card_number')}
            button1Press={() => this.bookTicket()}
            selectedPaymentMethod={this.state.stepsMenu}
          />
        )}

        <Snackbar
          open={this.state.showSnackbar}
          autoHideDuration={this.state.persist ? 10000000000 : 5000}
          anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
          onClose={this.closeSnackbar}
        >
          <Alert onClose={this.closeSnackbar} severity="error">
            {this.state.alertMessage}
          </Alert>
        </Snackbar>

        {this.state.showLoader ? (
          <div className="loader-container">
            <Loader
              visible={this.state.showLoader}
              type="Bars"
              color="#637290"
              height={80}
              width={80}
            />
          </div>
        ) : null}
      </div>
    );
  }
}

/* istanbul ignore next */
function mapStateToProps(state) {
  return {
    home: state.home,
  };
}

/* istanbul ignore next */
function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...actions }, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(DefaultPage);
