import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { KuposButton, KuposErrorSuccessModal, KuposInput, KuposListLoader } from '../common';
import { DropdownComponent } from '../common/DropdownComponent';
import ApiUrls from '../../services/apiUrls';
import DateService from '../../services/dateService';
import CommonService from '../../services/commonService';
import { apiCall } from './redux/apiCall';
import { Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import Loader from 'react-loader-spinner';


const modalInitialState = {
  show: false,
  success: false,
  bodyText: null,
};

const initialState = {
  fromCurrency: '',
  toCurrency: '',
  exchangeRate: '',
};

const AddExchangeRate = ({ history, match }) => {
  const dispatch = useDispatch();
  const [values, setValues] = useState(initialState);
  const [modalValues, setModalValues] = useState(modalInitialState);
  const [loader, setLoader] = useState(true);
  const [currenciesListFrom, setCurrenciesListFrom] = useState([]);
  const [currenciesListTo, setCurrenciesListTo] = useState([]);
  const [snackbar, setSnackbar] = useState({});
  const [showForm, setShowForm] = useState(true)
  const [count, setCount] = useState('')

  // console.log({ currenciesListFrom }, { currenciesListTo }, { values }, { count })

  const id = match.params ? Number(match.params.id) : null;

  useEffect(() => {
    getCurrencyList();
    id && getExchangeList();
    // eslint-disable-next-line
  }, [id])


  // redirecting back if data not found using timer
  useEffect(() => {
    let interval
    if (count) {
      interval = setInterval(() => {
        setCount((currentCount) => --currentCount)
      }, 1000);
    }
    // Redirect once the count is === 1
    count === 1 && history.push('/exchange-rates');
    // CleanUp
    return () => clearInterval(interval)
  }, [count, history])


  const getExchangeList = () => {
    dispatch(apiCall({ url: ApiUrls.addCurrencyExchange(), callback: res => onExchangeListResponse(res) }))
  }

  const onExchangeListResponse = (res) => {
    setLoader(false)

    if (res && res.data && res.data.success) {
      let selectedExchangeItem = res.data.data.find(
        exchangeItem => exchangeItem.id == id,
      );

      //set values to readonly inputs
      if (selectedExchangeItem) {
        console.log('+++ echange amount', selectedExchangeItem)
        setValues({
          ...values,
          selectedExchangeItem,
          exchangeRate: selectedExchangeItem.exchange_rate.toString(),
          fromCurrency: {
            value: selectedExchangeItem.from_currency_id,
            label: selectedExchangeItem.from_currency_code,
          },
          toCurrency: {
            value: selectedExchangeItem.to_currency_id,
            label: selectedExchangeItem.to_currency_code,
          },
        })
      } else {
        // Show Snack Bar Msge
        setSnackbar({
          showSnackbar: true,
          persist: true,
          alertMessage: 'Record not found'
        });
        setShowForm(false)
        setCount(5)
      }
    }
    else {
      // if there is no REsponse 
      setSnackbar({
        showSnackbar: true,
        persist: true,
        alertMessage: 'Check Your Internet Connection'
      });
      setShowForm(false)
    }
  }

  const getCurrencyList = () => {
    dispatch(apiCall({ url: ApiUrls.getCurrencies(), callback: res => onCurrencyListResponse(res) }))
  };

  const onCurrencyListResponse = (res) => {
    setLoader(false)
    if (res && res.data && res.data.success) {
      let currencies = [];
      res.data.data.map(val => {
        currencies.push({ value: val.id, label: val.code });
      });
      setCurrenciesListFrom(currencies)
      setCurrenciesListTo(currencies)
    }
  }


  const onChange = (val, type) => {
    switch (type) {
      case 'fromCurrency':
        setValues({ ...values, [type]: val, [type + 'Error']: null, toCurrency: null })
        setCurrenciesListTo(currenciesListFrom.filter(it => it.value != val.value))
        break;
      case 'toCurrency':
        setValues({ ...values, [type]: val, [type + 'Error']: null })
        break;
      case 'exchangeRate':
        setValues({ ...values, [type]: val, [type + 'Error']: null })
        break;
    }
  };

  const validate = () => {
    if (!values.fromCurrency) {
      setValues({ ...values, fromCurrencyError: 'Convertir de' });
      return false;
    }
    if (!values.toCurrency) {
      setValues({ ...values, toCurrencyError: 'Convertir a ' });
      return false;
    }
    if (!values.exchangeRate) {
      setValues({ ...values, exchangeRateError: 'Ingrese un Tasa de conversión' });
      return false;
    }
    return true;

  };

  const createOrUpdate = () => {
    if (!validate()) {
      return;
    }
    let data, method = 'POST';
    if (id) {
      method = 'PUT';
      data = {
        id: values.selectedExchangeItem.id,
        client_code: values.selectedExchangeItem.client_code,
        user_id: values.selectedExchangeItem.user_id,
        user_name: values.selectedExchangeItem.user_name,
        exchange_name: values.selectedExchangeItem.exchange_name,
        status: values.selectedExchangeItem.status,
        from_currency_id: values.selectedExchangeItem.from_currency_id,
        from_currency_code: values.selectedExchangeItem.from_currency_code,
        to_currency_id: values.selectedExchangeItem.to_currency_id,
        to_currency_code: values.selectedExchangeItem.to_currency_code,
        exchange_rate: Number(values.exchangeRate),
        creation_date_stg: values.selectedExchangeItem.creation_date_stg,
        creation_time_stg: values.selectedExchangeItem.creation_time_stg,
        updation_user_id: CommonService.currentUser.user_id,
        updation_user_name: `${CommonService.currentUser.user_details.first_name} ${CommonService.currentUser.user_details.last_name}`,
        updation_date_stg: DateService.getTodayString('yyyy-mm-dd'),
        updation_time_stg: `${DateService.getCurrentHour()}:${DateService.getCurrentMinute()}`,
        deletion_user_id: 0,
        deletion_user_name: '',
        deletion_date_stg: '',
        deletion_time_stg: '',
      }
    } else {
      data = {
        from_currency_id: values.fromCurrency.value,
        from_currency_code: values.fromCurrency.label,
        to_currency_id: values.toCurrency.value,
        to_currency_code: values.toCurrency.label,
        exchange_rate: Number(values.exchangeRate),
        creation_date_stg: DateService.getTodayString('yyyy-mm-dd'),
        creation_time_stg: `${DateService.getCurrentHour()}:${DateService.getCurrentMinute()}`,
      };
    }
    console.log('Data to fly is----', { data });

    dispatch(
      apiCall({ method, url: ApiUrls.addCurrencyExchange(), data, callback: res => onCurrencyAddResponse(res) })
    )
  };

  const onCurrencyAddResponse = res => {
    console.log('Currency res is-----', res);
    if (res && res.data && res.data.success && res.data.data) {
      setModalValues({
        show: true,
        success: true,
        bodyText: res.data.message,
      });
    }
  };

  const hideModal = () => {
    setModalValues({ ...modalValues, show: false, bodyText: null });
    if (modalValues.success) {
      history.goBack();
    }
  };

  const onBlur = () => { };


  if (loader) {
    return (
      <div className="loader-container">
        <Loader visible={loader} type="Bars" color="#637290" height={80} width={80} />
      </div>
    )
  }

  return showForm ?
    <div className="home-add-location">
      <div className="home-selection-input-box font10 select-box">
        <DropdownComponent
          onChange={val => onChange(val, 'fromCurrency')}
          placeholder={'Convertir de'}
          disabled={id ? true : false}
          options={currenciesListFrom}
          value={values.fromCurrency}
          error={values.fromCurrencyError ? true : false}
          errorMessage={
            values.fromCurrencyError ? values.fromCurrencyError : null
          }
        />
      </div>

      <div className="home-selection-input-box font10 select-box" style={{ marginTop: 15 }}>
        <DropdownComponent
          onChange={val => onChange(val, 'toCurrency')}
          placeholder={'Convertir a '}
          disabled={id ? true : false}
          options={currenciesListTo}
          value={values.toCurrency}
          error={values.toCurrencyError ? true : false}
          errorMessage={values.toCurrencyError ? values.toCurrencyError : null}
        />
      </div>

      <div className="common-kupos-input-box" style={{ marginTop: 15 }}>
        <KuposInput
          type="text"
          className="kupos-border"
          placeholder={'Tasa de conversión'}
          value={values.exchangeRate}
          error={values.exchangeRateError ? true : false}
          errorMessage={values.exchangeRateError ? values.exchangeRateError : ''}
          onChange={text => onChange(text, 'exchangeRate')}
          onBlur={text => onBlur(text, 'rate')}
        />
      </div>

      <div className="button-container" style={{ marginTop: 20 }}>
        <KuposButton onClick={createOrUpdate}>
          <span className="font11">
            {id ? 'Actualizar Tasa de conversión' : 'Crear Tasa de conversión'}
          </span>
        </KuposButton>
      </div>
      <KuposErrorSuccessModal
        showModal={modalValues.show}
        success={modalValues.success}
        smallIcon={true}
        bodyText={modalValues.bodyText}
        onButtonClick={() => hideModal()}
      />
    </div> :
    <Snackbar
      open={snackbar.showSnackbar}
      autoHideDuration={snackbar.persist ? 10000000000 : 5000}
      anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
      onClose={() => setSnackbar({ showSnackbar: false })}
    >
      <Alert onClose={() => setSnackbar({ showSnackbar: false })} severity="error">
        <span>{snackbar.alertMessage} </span>
        {count
          &&
          `we are redirecting you back in ${count} seconds`
        }
      </Alert>
    </Snackbar>

};

export default AddExchangeRate;
