import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actions from './redux/actions';
import KuposModal from './KuposModal';
import SvgHome from './SvgHome';
import KuposButton from './KuposButton';
import KuposInput from './KuposInput';
import CommonService from '../../services/commonService';

export class EditCard extends Component {
  static propTypes = {
    common: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired,
    t:PropTypes.func,
    userCards: PropTypes.object.isRequired,
    selectedItemForEdit: PropTypes.number.isRequired,
  };

  constructor(props){
    super(props);
    this.state = {
      name: null,
      cardNumber: null
    };
  }

  getName = (val, key) => {
    return this.props.selectedItemForEdit === key ? val.name : '';
  }

  getExpiry = (val, key) => {
    return this.props.selectedItemForEdit === key ? val.MMAA : '';
  }

  getCardNumber = (val, key) => {
    return this.props.selectedItemForEdit === key? val.cardNumber: ''
  }

  render() {
    console.log('Edit Item', this.props.selectedItemForEdit);
    return (
      <div className="common-edit-bip-card">
        <KuposModal
          showModal={this.props.showModal}
          onHide={this.props.onHide}
          size="md"
          ariaLabel="login-modal"
        >
          <div className="login-signup-block delete-bip-card">
            <div className="close-button pointer" onClick={this.props.onHide}>
              <SvgHome name="close" />
            </div>

            <div>
              <span className="bold-text font12 bip-pop-up-title-edit">
                {this.props.t('PROFILE.MODIFY_CARD_TITLE')}
              </span>

              <div className="add-bip-card-input-container add-my-card-body">
                <div className="add-bip-card-input-container-inner">
                  <div className="bip-input-add-container">
                    <div className="recarge-input-label font10 bold-text">
                      {this.props.t('PROFILE.CARD_NUM_ADD_CARDS')}
                    </div>

                    <KuposInput
                      placeholder={'XXXXXXXXXXXX'}
                      className="kupos-border font10 bip-recharge-input add-bip-input"
                      value={CommonService.removeCommas(
                        this.props.userCards
                          ? this.props.userCards.map((val, key) => this.getCardNumber(val, key))
                          : null,
                      )}
                      // value={this.state.rechargeAmount}
                      // onBlur={() => {}}
                      // onChange={val => this.setState({ rechargeAmount: val })}
                    />
                  </div>

                  <div className="two-inputs-row display-flex">
                    <div className="left-input">
                      <div className="recarge-input-label font10 bold-text">
                        {this.props.t('PROFILE.EXPIRATION')}
                      </div>

                      <KuposInput
                        placeholder="MM/AA"
                        className="kupos-border font10 bip-recharge-input add-bip-input"
                        style={{ width: '94%' }}
                        value={CommonService.removeCommas(
                          this.props.userCards
                            ? this.props.userCards.map((val, key) => this.getExpiry(val, key))
                            : null,
                        )}
                        // value={this.state.rechargeAmount}
                        // onBlur={() => {}}
                        // onChange={val => this.setState({ rechargeAmount: val })}
                      />
                    </div>

                    <div className="right-input">
                      <div className="recarge-input-label font10 bold-text">
                        {this.props.t('PROFILE.CVV_CODE')}
                      </div>

                      <KuposInput
                        placeholder="XXX"
                        className="kupos-border font10 bip-recharge-input add-bip-input"
                        style={{ width: '94%' }}
                        // value={this.state.rechargeAmount}
                        // onBlur={() => {}}
                        // onChange={val => this.setState({ rechargeAmount: val })}
                      />
                    </div>
                  </div>

                  <div className="bip-input-add-container">
                    <div className="recarge-input-label font10 bold-text">
                      {this.props.t('PROFILE.NAME_ADD_CARD')}
                    </div>

                    <KuposInput
                      placeholder="Alicia Sanchez"
                      className="kupos-border font10 bip-recharge-input add-bip-input"
                      value={CommonService.removeCommas(
                        this.props.userCards
                          ? this.props.userCards.map((val, key) => this.getName(val, key))
                          : null,
                      )}
                      // value={this.state.rechargeAmount}
                      // onBlur={() => {}}
                      // onChange={val => this.setState({ rechargeAmount: val })}
                    />
                  </div>
                </div>
              </div>

              <div className=" edit-bip-button bip-popup-button-container-edit">
                <KuposButton
                  // onClick={this.onBookButtonPress}
                  className="bip-popup-button1 font12 bip-edit-button"
                >
                  {this.props.t('PROFILE.SAVE_CHANGES_BUTTON')}
                </KuposButton>
              </div>
            </div>
          </div>
        </KuposModal>
      </div>
    );
  }
}

/* istanbul ignore next */
function mapStateToProps(state) {
  return {
    common: state.common,
  };
}

/* istanbul ignore next */
function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...actions }, dispatch)
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditCard);
