import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actions from './redux/actions';
import PassengerDetailsBlock from './PassengerDetailsBlock';
import { KuposButton, KuposErrorSuccessModal } from '../common';
import AppData from '../../services/appData';
import DateService from '../../services/dateService';
import CommonService from '../../services/commonService';

export class CancellationDetailsPage extends Component {
  static propTypes = {
    home: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      ticketDetails: this.props.home && this.props.home.cancelTicketDetails,
      cancelTicketDetails: '',
    };
  }

  componentDidMount() {
    // console.log('Props in cancellation detail screen are---', this.props);
  }

  onExclusiveCheck = () => {
    this.setState({ exclusiveChecked: !this.state.exclusiveChecked });
  };

  getSelectedSeats() {
    const checkCopy = { ...this.state.selectedSeats };
    let count = 0;
    for (let key in checkCopy) {
      if (checkCopy[key]) count++;
    }
    return count;
  }

  onSeatChecked = seatNo => {
    const checkCopy = { ...this.state.selectedSeats };
    if (checkCopy[seatNo]) checkCopy[seatNo] = false;
    else checkCopy[seatNo] = true;

    let allSelected = true;
    for (let key in checkCopy) {
      if (!checkCopy[key]) {
        allSelected = false;
        break;
      }
    }
    this.setState({ selectedSeats: checkCopy, allSelected: allSelected });
  };

  selectAll = () => {
    const checkCopy = { ...this.state.selectedSeats };
    for (let key in checkCopy) {
      checkCopy[key] = !this.state.allSelected;
    }
    this.setState({
      selectedSeats: checkCopy,
      allSelected: !this.state.allSelected,
      selectedAllKey: this.state.selectedAllKey + 1,
    });
  };

  cancelTicket = () => {
    this.setState({ loading: true });

    let currentTime = DateService.getCurrentTimeUTC();

    let counterDetails = CommonService.getLocal('counter');

    if (!counterDetails) {
      alert('Counter not assigned');

      return;
    }

    let data = {
      correlation_id: `${this.state.ticketDetails.customer_name.substr(0, 3)}_${currentTime}`,
      counter_txn_id: counterDetails.counter_txn_id,
      pnr_id: this.state.ticketDetails.pnr_id,
      cancellation_reason: 'ssome reason',
      cancellation_time: currentTime,
      cancellation_date_stg: DateService.getTodayString('yyyy-mm-dd'),
      cancellation_time_stg: `${DateService.getCurrentHour()}:${DateService.getCurrentMinute()}`,
    };

    this.props.actions
      .apiCall({ url: AppData.BASE_URL + 'agent/cancel', data: data, method: 'POST' })
      .then(
        res => {
          this.onTicketCancel(res);
        },
        err => {
          this.onTicketCancel(err);
        },
      );
  };

  onTicketCancel = res => {
    this.setState({ loading: true });
    console.log('On ticket cancel response-----', res);

    if (res && res.data && res.data.success && res.data.data) {

      this.setState({
        showSuccessModal: true,
        successModalStatus: true,
        successModalMessage: `${res.data.data.pnr_id} Cancelación exitosa`,
        cancelTicketDetails:res.data.data,
      });
      // this.props.history.push('/sales-page');
    } else {
      this.setState({
        showSuccessModal: true,
        successModalStatus: false,
        successModalMessage: `There was some issue in cancellation, please try again!`,
      });
    }
  };

  render() {
    console.log('Ticket details are---', this.state.ticketDetails);
    let clientInfo = CommonService.getLocal('clientInfo');
    return (
      <div className="home-cancellation-details-page">
        <div>
          <PassengerDetailsBlock
            ticketDetails={this.state.ticketDetails}
            selectedSeats={this.state.selectedSeats}
            onSeatCheck={this.onSeatChecked}
            selectAll={this.selectAll}
            onExclusiveCheck={this.onExclusiveCheck}
            exclusiveChecked={this.state.exclusiveChecked}
            allSelected={this.state.allSelected}
            tncChecked={this.state.tncChecked}
            onTncChange={() => this.setState({ tncChecked: !this.state.tncChecked })}
          />
        </div>
        {/* <div className="bank-deposit-input-box font12">
          <button className="kupos-button" onClick={this.onSubmit}>
            <span>PROCESAR CANCELACIÓN</span>
          </button>
        </div> */}

        <div className="button-container" style={{ marginTop: 20 }}>
          <KuposButton onClick={this.cancelTicket} loading={this.state.loading} height={44}>
            {/* <KuposButton
            onClick={() =>
              CommonService.printCancelTicket(
                document.getElementById('cancellation-print').innerHTML,
              )
            }
            loading={this.state.loading}
            height={44}
          > */}
            <span className="font11">PROCESAR CANCELACIÓN</span>
          </KuposButton>
        </div>

        <KuposErrorSuccessModal
          success={this.state.successModalStatus}
          showModal={this.state.showSuccessModal}
          onButtonClick={() => {
            this.setState({
              showSuccessModal: false,
              successModalStatus: false,
              successModalMessage: false,
            });

            this.state.successModalStatus &&
              CommonService.printCancelTicket(
                document.getElementById('cancellation-print').innerHTML,
              );
            this.state.showSuccessModal && this.props.history.push('/sales-page');
          }}
          bodyText={this.state.successModalMessage}
        />

        <div id="cancellation-print" style={{ visibility: 'hidden' }}>
          <img
             src={(clientInfo && clientInfo.logo_path) ||
              '/images/delfos_print_white.png'
            }
            style={{ marginBottom: '20px', width: '200px' }}
            className="logo-img"
          />

          <div>
            <span>Detalles del pasejero y de la reserva</span>
          </div>
          <table style={{ borderColor: '#000' }}>
            <tbody>
              <tr>
                <td>PNR</td>
                <td>{this.state.ticketDetails.pnr_id}</td>
              </tr>

              <tr>
                <td>Nombre</td>
                <td className="bold-text">{this.state.ticketDetails.customer_name}</td>
              </tr>

              <tr>
                <td>{this.state.ticketDetails.identity_type || 'RUT'}</td>
                <td>{this.state.ticketDetails.identity_value}</td>
              </tr>

              <tr>
                <td className="bold-text">Tipo de servicio</td>
                <td>{this.state.ticketDetails.service_type}</td>
              </tr>

              <tr>
                <td>Fecha de servicio</td>
                <td className="bold-text">{this.state.ticketDetails.trip_ref_date}</td>
              </tr>

              <tr>
                <td>Hora</td>
                <td className="bold-text">{this.state.ticketDetails.trip_ref_time}</td>
              </tr>

              <tr>
                <td className="bold-text">Detalles de cancelación</td>
                <td></td>
              </tr>

              <tr>
                <td>Cargos de cancelación</td>
                {/* <td>{CommonService.currency(this.state.ticketDetails.penalty)}</td> */}
                <td>{CommonService.currency(this.state.cancelTicketDetails.cancellation_charge)}</td>
              </tr>
              <tr>
                <td>Tarifa pagada</td>
                <td>{CommonService.currency(this.state.ticketDetails.booking_amount)}</td>
              </tr>
             
              <tr>
                <td>Monto total de reembolso</td>
                {/* <td>{CommonService.currency(this.state.ticketDetails.refund_amount)}</td> */}
                <td>{CommonService.currency(this.state.cancelTicketDetails.refund_amount)}</td>
              </tr>
              <tr>
                <td>Fecha de cancelación</td>
                <td>{`${DateService.getTodayString()} - ${DateService.getCurrentHour()} : ${DateService.getCurrentMinute()}`}</td>
              </tr>
            </tbody>
          </table>

          <div className="bottom-line">
            <span>Impreso por: </span>
            <span className="bold-text">{CommonService.currentUser.user_name}</span>{' '}
            <span style={{ marginLeft: 20 }}>On: </span>
            <span className="bold-text">{`${DateService.getTodayString()} - ${DateService.ampm(
              DateService.getCurrentHour() + ':' + DateService.getCurrentMinute(),
            )}`}</span>
          </div>
        </div>
      </div>
    );
  }
}

/* istanbul ignore next */
function mapStateToProps(state) {
  return {
    home: state.home,
  };
}

/* istanbul ignore next */
function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...actions }, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CancellationDetailsPage);
