import React, { Component } from 'react';
import PropTypes from 'prop-types';
// import Select from 'react-select';
import CommonService from '../../services/commonService';

export default class KuposSelectBox extends Component {
  static propTypes = {
    selectedOption: PropTypes.object,
    options: PropTypes.array,
    onChange: PropTypes.func,
    onInputChange: PropTypes.func,
    placeholder: PropTypes.string,
    icon: PropTypes.string,
    iconRight: PropTypes.string,
    hideArrow: PropTypes.bool,
    height: PropTypes.number,
    noPaddingLeft: PropTypes.bool,
    smallArrow: PropTypes.bool,
    error: PropTypes.bool,
    errorMessage: PropTypes.string,
    readonly: PropTypes.bool,
    showAll: PropTypes.bool,
    appType: PropTypes.string,
    selectType: PropTypes.string,
    t: PropTypes.func,
    fontSize: PropTypes.string,
  };

  constructor(props) {
    super(props);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.state = {
      cursor: 0,
      options: this.props.options,
      showSuggestions: false,
    };
  }
  componentDidMount() {
    if (this.props.selectType && this.props.options && !this.optionsFull)
      this.optionsFull = this.props.options;
    this.setState({
      options: this.props.options,
    });
  }
  componentWillUpdate() {
    if (this.props.selectType && this.props.options && !this.optionsFull)
      this.optionsFull = this.props.options;
    if (this.optionsFull && !this.optionsLoaded) {
      this.setState({
        options: this.optionsFull.slice(0, 100),
      });
      this.optionsLoaded = true;
    }
    if (
      typeof this.textValue === 'undefined' &&
      this.props.selectedOption &&
      this.props.selectedOption.label
    ) {
      this.textValue = this.props.selectedOption.label;
      this.setState({ textValue: this.props.selectedOption.label });
    }
  }

  scrollIfNotInView = el => {
    if (el) {
      if (CommonService.isElementInView(el)) {
        console.log('In the viewport!');
      } else {
        el.scrollIntoView(false);
      }
    }
  };

  clearValue = () => {
    setTimeout(() => {
      this.setState({ textValue: undefined });
      this.onSelect(null);
    }, 300);
  };
  handleKeyDown(e) {
    const { cursor, options } = this.state;
    // arrow up/down button should select next/previous list element
    if (e.keyCode === 38 && cursor > 0) {
      this.setState(prevState => ({
        cursor: prevState.cursor - 1,
      }));
    } else if (e.keyCode === 40 && cursor < options.length) {
      this.setState(prevState => ({
        cursor: prevState.cursor + 1,
      }));
    } else if (e.keyCode === 13 && cursor > 0) {
      this.onSelect(this.state.options[cursor - 1]);
    }
    setTimeout(() => {
      var c = document.querySelector('.auto-suggestions .cursor');
      if (c) {
        // var topPos = c.offsetTop;
        // document.querySelector('.auto-suggestions').scrollTop = topPos;
      }
    }, 300);
  }

  gotoActive = () => {
    var act = document.querySelector('.auto-suggestions .active');
    if (act) {
      var topPos = act.offsetTop;
      document.querySelector('.auto-suggestions').scrollTop = topPos - 10;
    }
  };

  inputChange = ev => {
    let val = ev.target.value;
    let ops =
      val && !this.props.showAll
        ? this.props.options.filter(it => {
            return (
              CommonService.getStringWithoutAccent(it.label.toLowerCase()).indexOf(
                CommonService.getStringWithoutAccent(val.toLowerCase()),
              ) >= 0
            );
          })
        : this.props.options;
    this.setState({
      options: ops,
      textValue: val,
    });
    if (this.el) {
      setTimeout(() => {
        this.scrollIfNotInView(this.el);
      }, 250);
    }

    if (this.props.selectType) {
      let selectOptions = this.optionsFull.filter(it => {
        return it.label.toLowerCase().indexOf(val.toLowerCase()) >= 0;
      });
      // this.selectOptions = CommonService.copyObject(selectOptions.slice(0,100))
      this.setState({
        options:
          selectOptions && selectOptions.length
            ? selectOptions.slice(0, 100)
            : this.optionsFull.slice(0, 100),
      });
    }
    if (this.props.onInputChange) this.props.onInputChange(val);
  };

  onSelect = val => {
    this.setState({
      textValue: val ? val.label : undefined,
      // textValue: val ? this.props.t(val.label) : undefined,
      showSuggestions: false,
      cursor: 0,
    });
    this.props.onChange(val);
  };

  onFocus = (toggle = false, hideSuggestions = false) => {
    if (hideSuggestions) {
      return;
    }
    if (toggle === true) {
      this.setState({ showSuggestions: !this.state.showSuggestions });
    } else {
      this.setState({ showSuggestions: true });
    }
    setTimeout(() => {
      // var b = window.document.querySelector('.is-focused');
      if (this.el) {
        // var as = window.document.querySelector('.auto-suggestions');
        // // if(b && as)
        // // if(document.documentElement.clientHeight - b.getBoundingClientRect().bottom < as.clientHeight){
        //   // if(b.getBoundingClientRect().top > as.clientHeight || (b.getBoundingClientRect().top > document.documentElement.clientHeight - b.getBoundingClientRect().bottom)){
        //   //   as.className = 'auto-suggestions up font10'
        //   // }else{
        //     as.className = 'auto-suggestions font10'
        //   // }
        // // }
        setTimeout(() => {
          this.scrollIfNotInView(this.el);
        }, 250);
        this.gotoActive();
      }
    }, 300);
  };

  onBlur = () => {
    setTimeout(() => {
      this.setState({ showSuggestions: false, cursor: 0 });
    }, 250);
  };

  render() {
    return (
      <div className="common-kupos-select-box-outer">
        <div
          className={
            'common-kupos-select-box kupos-border flex-row ' +
            (!this.props.hideArrow ? 'drop-arrow ' : '') +
            (this.props.noPaddingLeft ? 'no-padding-left ' : '') +
            (this.props.error ? 'is-error ' : '') +
            (this.state.showSuggestions ? 'is-focused ' : '')
          }
          style={{ height: (this.props.height || 70) + 'px' }}
        >
          {this.props.icon ? (
            <img
              // alt={this.props.t('TRANSLATIONS.ICON_SOURCE')}
              className="icon-size icon-left "
              src={this.props.icon}
            />
          ) : null}
          {/* <Select
            value={this.props.selectedOption}
            onChange={this.props.onChange}
            onInputChange={this.props.onInputChange}
            options={this.props.options}
            placeholder={this.props.placeholder}
          /> */}
          {this.props.readonly ? (
            <input
              ref={ref => (this.input = ref)}
              readOnly
              type="text"
              className={this.props.fontSize ? this.props.fontSize : 'font11'}
              onKeyDown={this.handleKeyDown}
              placeholder={this.props.placeholder}
              onFocus={() => this.onFocus(true, true)}
              onBlur={this.onBlur}
              onChange={this.inputChange}
              value={this.state.textValue}
              // value={this.props.t(this.state.textValue)}
            />
          ) : (
            <input
              ref={ref => (this.input = ref)}
              type="text"
              className={this.props.fontSize ? this.props.fontSize : 'font11'}
              onKeyDown={this.handleKeyDown}
              placeholder={this.props.placeholder}
              onFocus={this.onFocus}
              onBlur={this.onBlur}
              onChange={this.inputChange}
              value={this.state.textValue}
              // value={this.props.t(this.state.textValue)}
            />
          )}
          {this.props.iconRight ? (
            <img
              // alt={this.props.t('PROFILE.LOG_IN_HEADING')}
              className="icon-size icon-right "
              src={this.props.iconRight}
            />
          ) : null}
          {!this.props.hideArrow ? (
            <img
              className={
                'icon-arrow pointer' +
                (this.props.smallArrow ? 'small-arrow ' : '') +
                (this.state.showSuggestions ? 'up-side-down ' : '')
              }
              src="/images/icons/icon-arrow.png"
              // alt={this.props.t('PROFILE.LOG_IN_HEADING')}
              onClick={() => (this.props.readonly ? this.onFocus(true, true) : this.onFocus)}
            />
          ) : null}
        </div>
        {this.props.error ? <span className="error font9">{this.props.errorMessage}</span> : null}
        {this.props.options &&
        this.state.showSuggestions &&
        this.state.options &&
        this.state.options.length ? (
          <div
            ref={el => {
              this.el = el;
            }}
            className={
              'auto-suggestions font10 scroll-style ' + (this.props.error ? 'error-shown' : '')
            }
          >
            {this.state.options &&
              this.state.options.map((val, key) => {
                return (
                  <a
                    className={
                      (this.props.selectedOption && val.value == this.props.selectedOption.value
                        ? 'active '
                        : '') + (this.state.cursor == key + 1 ? 'cursor' : '')
                    }
                    key={key}
                    onClick={() => this.onSelect(val)}
                  >
                    {val.label}
                    {/* {this.props.t(val.label)} */}
                  </a>
                );
              })}
          </div>
        ) : null}
      </div>
    );
  }
}
