// @flow

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actions from './redux/actions';
import Select from 'react-select';
import { KuposButton, KuposErrorSuccessModal, KuposInput } from '../common';
import ApiUrls from '../../services/apiUrls';
import { withScriptjs, withGoogleMap, GoogleMap, Marker, Polygon } from 'react-google-maps';
import AppData from '../../services/appData';
import { DropdownComponent } from '../common/DropdownComponent';
import DateService from '../../services/dateService';

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    fontSize: 14,
    // borderBottom: '1px dotted pink',
    // color: state.isSelected ? 'red' : 'blue',
    // padding: 10,
  }),
  // control: () => ({
  //   // none of react-select's styles are passed to <Control />
  //   // width: 200,
  //   // maxHeight:40,
  //   // borderRadius:10,
  // }),
  // singleValue: (provided, state) => {
  //   const opacity = state.isDisabled ? 0.5 : 1;
  //   const transition = 'opacity 300ms';
  //   return { ...provided, opacity, transition };
  // },
};

export class AddService extends Component {
  constructor(props) {
    super(props);

    this.state = {
      priceError: null,
      locations: [],
      locationAreas: [],
      priceTypes: [
        { value: 'PER_VEHICLE', label: 'Por vehículo' },
        { value: 'PER_PERSON', label: 'Por persona' },
        { value: 'PER_KM', label: 'Por km' },
      ],
      fromToAirport: [
        { value: 'FROM_AIRPORT', label: 'Del aeropuerto' },
        { value: 'TO_AIRPORT', label: 'Al aeropuerto' },
      ],
      services: [],
      polygon: [],
      selectedLatLong: { lat: -33.4436021, lng: -70.6606066 },
      serviceToEdit: null,
      // selectedLocation: null,
      // selectedLocationArea: null,
      // selectedService: null,
      // selectedPriceType: null,
      // selectedDirection: null,
    };

    // Get services
    this.getServiceList();

    // Get Location areas
    // this.getLocationAreas();

    this.getList();
  }

  componentDidMount() {
    console.log('Props in add service', this.props);

    //Get Locations
    // this.props.actions.apiCall({ url: ApiUrls.getLocations() }).then(
    this.props.actions.apiCall({ url: ApiUrls.getLocationList() }).then(
      res => {
        this.onLocationApiResponse(res);
      },
      err => {
        this.onLocationApiResponse(err);
      },
    );
  }

  getLocationAreas = val => {
    console.log('+++ on pickup location select---------', val);
    this.props.actions.apiCall({ url: ApiUrls.getLocationAreas() }).then(
      res => {
        console.log('+++Result from all location areas api----&&&&&&', res);
        if (res.data && res.data.success && res.data.data) {
          this.setState({
            locationAreas: res.data.data
              .sort((x, y) => {
                return y.id - x.id;
              })
              .filter(area => {
                return area.location_id === val.value;
              })
              .map(val => ({ value: val.id, label: val.area_name, polygon: val.polygon })),
          });
        }
      },
      err => {
        console.log('Result from all location areas is', err);
      },
    );
  };

  getServiceList = () => {
    this.props.actions.apiCall({ url: ApiUrls.getServices() }).then(
      res => {
        console.log('+++Result from all Vehicles api', res);
        if (res.data && res.data.success && res.data.data) {
          this.setState({
            services: res.data.data
              .sort((x, y) => {
                return y.id - x.id;
              })
              .map(val => ({ value: val.id, label: val.name })),
          });
        }
      },
      err => {
        console.log('Result from all location areas is', err);
      },
    );
  };

  //Location API Response
  onLocationApiResponse = result => {
    console.log('+++data from location list------------', result);
    if (result.data && result.data.success && result.data.data) {
      this.setState({
        locations: result.data.data
          .sort((x, y) => {
            return y.id - x.id;
          })
          .map(val => ({
            value: val.id,
            label: val.name,
            type: val.type,
            address: val.address,
          })),
      });
    }
  };

  getList = () => {
    this.props.actions.apiCall({ url: ApiUrls.addServicePricing() }).then(
      res => {
        console.log('+++Result from service prices areas is----------------', res);
        if (res.data && res.data.success) {
          this.setState({ itemList: res.data.data });
        }
        if (this.props.match.params.id)
          this.getParticularItem(this.props.match.params.id, res.data.data);
      },
      err => {
        console.log('Result from all location areas is', err);
      },
    );
  };

  getParticularItem = (id, items) => {
    console.log('+++Locations and id is', id, items);

    items.forEach(element => {
      if (element.id == id) {
        console.log("+++ element is",element)
        this.setState({
          itemToEdit: element,
          selectedServicePriceName: element.service_price_name,
          selectedPickupLocation: { value: element.pickup_location_id, label:element.pickup_location_name },
          selectedPickupLocationArea:{ value: element.pickup_location_area_id, label:element.pickup_location_area_name } ,
          selectedDropLocation:{ value: element.drop_location_id, label:element.drop_location_name } ,
          selectedDropLocationArea: { value: element.drop_location_area_id, label:element.drop_location_area_name },
          selectedService: { value: element.service_price_type, label:element.service_price_type },
          selectedPriceType: { value:element.price_type, label:element.price_type },
          selectedPrice: element.price,
          selectedPickupAddressName: element.pickup_address,
          selectedDropAddressName: element.drop_address,
        });
      }
    });
  };

  // getService = (id) => {
  //   this.props.actions.apiCall({ url: ApiUrls.addServicePricing(id) }).then(
  //     res => {
  //       console.log('+++services are ',res)
  //       if (res.data && res.data.success && res.data.data) {
  //         this.setState({
  //           serviceToEdit: res.data.data,
  //           selectedLocation: {
  //             value: res.data.data.location_id,
  //             label: res.data.data.location_name,
  //           },
  //           selectedLocationArea: {
  //             value: res.data.data.location_area_id,
  //             label: res.data.data.location_area_name,
  //           },
  //           selectedService: res.data.data.service
  //             ? { value: res.data.data.service.id, label: res.data.data.service.name }
  //             : { value: res.data.data.service_id, label: res.data.data.service_name },
  //           selectedPriceType: this.state.priceTypes.find(
  //             p => p.value === res.data.data.price_type,
  //           ),
  //           selectedDirection: this.state.fromToAirport.find(
  //             p => p.value === res.data.data.origin_type,
  //           ),
  //           selectedPrice: res.data.data.price,
  //         });
  //         this.onLocationChange({
  //           value: res.data.data.location_id,
  //           label: res.data.data.location_name,
  //         });
  //       }
  //     },
  //     err => {},
  //   );
  // };

  static propTypes = {
    home: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired,
  };

  onChange = () => {
    console.log('Value is changing');
  };

  onPriceBlur = text => {
    console.log('Value is blur', text);
  };

  validate = () => {
    if (!this.state.selectedServicePriceName) {
      this.setState({
        selectedServicePriceNameError: { message: 'Ingrese un Nombre del servicio' },
      });
      return false;
    } else if (!this.state.selectedPickupLocation) {
      this.setState({
        selectedPickupLocationError: { message: 'Seleccione la Localización de embarque ' },
      });
      return false;
    }
    else if (!this.state.selectedPickupLocationArea) {
      this.setState({
        selectedPickupLocationAreaError: {
          message: 'Seleccione la Área de localización de embarque ',
        },
      });
      return false;
    } else if (!this.state.selectedPickupAddressName) {
      this.setState({
        selectedPickupAddressNameError: { message: 'pickup address  required ' },
      });
      return false;
    }  else if (!this.state.selectedDropLocation) {
      this.setState({
        selectedDropLocationError: { message: 'Seleccione la Localización de la bajada' },
      });
      return false;
    } else if (!this.state.selectedDropLocationArea) {
      this.setState({
        selectedDropLocationAreaError: {
          message: 'Seleccione la Área de la localización de la bajada',
        },
      });
      return false;
    } else if (!this.state.selectedDropAddressName) {
      this.setState({
        selectedDropAddressNameError: { message: 'pickup address  required ' },
      });
      return false;
    } else if (!this.state.selectedService) {
      this.setState({ selectedServiceError: { message: 'Por favor ingrese al servicio' } });
      return false;
    } else if (!this.state.selectedPriceType) {
      this.setState({ selectedPriceTypeError: { message: 'Ingrese el precio' } });
      return false;
    } else if (!this.state.selectedPrice) {
      this.setState({ selectedPriceError: { message: 'Ingrese el precio' } });
      return false;
    } else {
      return true;
    }
  };

  createOrUpdate = () => {
    console.log(this.state);
    if (!this.validate()) {
      // alert('Select all required fields');
      return;
    }

    let data, updateData;
    this.props.match.params.id
      ? (updateData = {
          id: Number(this.state.itemToEdit.id),
          client_code: this.state.itemToEdit.client_code,
          location_id: 0,
          location_area_id: 0,
          location_name: '',
          location_area_name: '',
          origin_type: '',
          pickup_address: this.state.selectedPickupAddressName,
          drop_address: this.state.selectedDropAddressName,
          service_id: Number(this.state.itemToEdit.service_id),
          service_name: this.state.itemToEdit.service_name,
          pickup_location_id: Number(this.state.itemToEdit.pickup_location_id),
          pickup_location_name: this.state.itemToEdit.pickup_location_name,
          pickup_location_area_id: Number(this.state.itemToEdit.pickup_location_area_id),
          pickup_location_area_name: this.state.itemToEdit.pickup_location_area_name,
          drop_location_id: Number(this.state.itemToEdit.drop_location_id),
          drop_location_name: this.state.itemToEdit.drop_location_name,
          drop_location_area_id: Number(this.state.itemToEdit.drop_location_area_id),
          drop_location_area_name: this.state.itemToEdit.drop_location_area_name,
          price_type: this.state.itemToEdit.price_type,
          price: Number(this.state.selectedPrice),
          service_price_name: this.state.itemToEdit.service_price_name,
          status: 'ACTIVE',
          created_on: this.state.itemToEdit.created_on,
          created_by: 0,
          last_updated_on: this.state.itemToEdit.last_updated_on,
          last_updated_by: 0,
          created_user: this.state.itemToEdit.created_user,
          created_utc: Number(this.state.itemToEdit.created_utc),
          created_date_time_stg: this.state.itemToEdit.created_date_time_stg,
          last_updated_user: '',
          last_updated_utc: DateService.getCurrentTimeUTC(),
          last_updated_date_time_stg: DateService.getCurrentStgDate(),
          deleted_user: '',
          deleted_utc: 0,
          deleted_date_time_stg: '',
        })
      : (data = {
          pickup_address: this.state.selectedPickupAddressName,
          drop_address: this.state.selectedDropAddressName,
          pickup_location_id: this.state.selectedPickupLocation.value,
          pickup_location_name: this.state.selectedPickupLocation.label,
          pickup_location_area_id: this.state.selectedPickupLocationArea.value,
          pickup_location_type: this.state.selectedPickupLocation.type,
          pickup_location_area_name: this.state.selectedPickupLocationArea.label,
          drop_location_id: this.state.selectedDropLocation.value,
          drop_location_name: this.state.selectedDropLocation.label,
          drop_location_type: this.state.selectedDropLocation.type,
          drop_location_area_id: this.state.selectedDropLocationArea.value,
          drop_location_area_name: this.state.selectedDropLocationArea.label,
          service_id: this.state.selectedService.value,
          service_name: this.state.selectedService.label,
          price_type: this.state.selectedPriceType.value,
          price: Number(this.state.selectedPrice),
          service_price_name: this.state.selectedServicePriceName,
          created_utc: DateService.getCurrentTimeUTC(),
          created_date_time_stg: DateService.getCurrentStgDate(),
        });

    this.props.match.params.id
      ? this.props.actions
          .apiCall({ method: 'PUT', url: ApiUrls.addServicePrice(), data: updateData })
          .then(
            res => {
              this.onUpdateServicePriceResponse(res);
            },
            err => {
              this.onUpdateServicePriceResponse(err);
            },
          )
      : this.props.actions
          .apiCall({ method: 'POST', url: ApiUrls.addServicePrice(), data: data })
          .then(
            res => {
              this.onAddServicePricingResponse(res);
            },
            err => {
              this.onAddServicePricingResponse(err);
            },
          );
  };

  onAddServicePricingResponse = result => {
    console.log('Result from add Service Pricing is', result.data);

    if (result.data && result.data.success && result.data.http_code == 201) {
      this.setState({
        showSuccessModal: true,
        successModalStatus: true,
        successModalMessage: 'Éxito',
      });
    }
  };

  onUpdateServicePriceResponse = result => {
    if (result.data && result.data.success && result.data.http_code == 200) {
      this.setState({
        showSuccessModal: true,
        successModalStatus: true,
        successModalMessage: 'Éxito',
      });
    }
  };

  setPloygonOnMap = selectedArea => {
    console.log('++++polygon is', selectedArea);
    let myElementPolygon,
      newPolygon = [];
    console.log('Selected Area is ', selectedArea);

    this.state.rawLocationAreas &&
      this.state.rawLocationAreas.forEach(element => {
        if (selectedArea.value === element.id) {
          myElementPolygon = JSON.parse(element.polygon).features[0].geometry.coordinates[0];
        }
      });

    console.log('+++My element polygon is', myElementPolygon);

    if (myElementPolygon) {
      myElementPolygon.forEach(element => {
        console.log('Polygon Entry in for each', element);

        // element.forEach(innerElement => {
        //   newPolygon.push({ lat: innerElement[0], lng: innerElement[1] });
        // })

        newPolygon.push({ lat: element[0], lng: element[1] });
      });

      console.log('New polygon is', newPolygon);

      this.setState({
        polygon: newPolygon,
        selectedLatLong: (newPolygon || []).length ? newPolygon[0] : this.state.selectedLatLong,
      });

      //  console.log("New Ploygon is", newPolygon);

      //  console.log(
      //    'My element polygon is',
      //    myElementPolygon,
      //    //  JSON.parse(myElementPolygon).features[0].geometry.coordinates[0],
      //  );
    }
  };

  onPriceChange = (value, type) => {
    // console.log('+++Selected Price is', value);
    switch (type) {
      case 'service_price_name':
        this.setState({ selectedServicePriceName: value, selectedServicePriceNameError: null });
        break;
      case 'pickup_address':
        this.setState({ selectedPickupAddressName: value, selectedPickupAddressNameError: null });
        break;
      case 'drop_address':
        this.setState({ selectedDropAddressName: value, selectedDropAddressNameError: null });
        break;
      case 'price':
        this.setState({ selectedPrice: value, selectedPriceError: null });
        break;
    }
  };

  goToAdminHome = () => {
    this.setState({ showSuccessModal: false });
    this.props.history.push('/admin-home');
  };

  onLocationChange = (val, type) => {
    console.log('+++Location is----- ', val, type);

    switch (type) {
      case 'pickup_location':
        this.setState({ selectedPickupLocation: val, selectedPickupLocationError: null });
        this.getLocationAreas(val);
        const isPickupAirport = val.type.includes('Airport');
        if (isPickupAirport) {
          this.setState({ selectedPickupAddressName: val.address });
        }
        break;
      case 'drop_location':
        this.setState({ selectedDropLocation: val, selectedDropLocationError: null });
        this.getLocationAreas(val);
        const isDropAirport = val.type.includes('Airport');
        if (isDropAirport) {
          this.setState({ selectedDropAddressName: val.address });
        }
        break;
      case 'pickup_location_area':
        this.setState({ selectedPickupLocationArea: val, selectedPickupLocationAreaError: null });
        break;
      case 'pickup_location_area':
        this.setState({ selectedPickupLocationArea: val, selectedPickupLocationAreaError: null });
        // this.setPloygonOnMap(this.state.selectedPickupLocationArea.polygon);
        break;
      case 'drop_location_area':
        this.setState({ selectedDropLocationArea: val, selectedDropLocationAreaError: null });
        // this.setPloygonOnMap(this.state.selectedPickupLocationArea.polygon);
        break;
      case 'service':
        this.setState({ selectedService: val, selectedServiceError: null });
        break;
      case 'price_type':
        this.setState({ selectedPriceType: val, selectedPriceTypeError: null });
        break;
    }

    //Get Location Areas
    //   this.props.actions
    //     .apiCall({ url: `${ApiUrls.getLocationAreasFromLocation()}/${val.value}` })
    //     .then(
    //       res => {
    //         this.onGetLocationAreasFromLocationResponse(res);
    //       },
    //       err => {
    //         this.onGetLocationAreasFromLocationResponse(err);
    //       },
    //     );
    // };

    //Location Area API Response
    // onGetLocationAreasFromLocationResponse = result => {
    //   let data = [];
    //   if (result.data && result.data.success && result.data.data) {
    //     this.setState({ rawLocationAreas: result.data.data });
    //     result.data.data.map(val => {
    //       data.push({ value: val.id, label: val.area_name });
    //       //  data.push({ id: val.location_id, label: val.area_name });
    //     });
    //     if (this.props.match.params.id)
    //       setTimeout(() => {
    //         this.setPloygonOnMap(this.state.selectedLocationArea);
    //       }, 300);
    //   }
    //   this.setState({ locationAreas: data });
  };

  render() {
    // console.log('Raw Location Areas', this.state.rawLocationAreas);
    console.log('+++state in service pricing is', this.state);
    return (
      <div className="home-add-service">
        <div className="service-pricing-main">
          <div className="location-selection-container">
            <div>
              <span className="bold-text">Especifique los detalles del precio del servicio</span>
            </div>
            <div className="location-selection-body">
              <div className="common-kupos-input-box" style={{ marginTop: 10 }}>
                <KuposInput
                  type="text"
                  className="kupos-border"
                  label={'Nombre del servicio'}
                  placeholder={'Nombre del servicio'}
                  value={this.state.selectedServicePriceName}
                  disabled={this.props.match.params.id ? true : false}
                  error={this.state.selectedServicePriceNameError ? true : false}
                  errorMessage={
                    this.state.selectedServicePriceNameError
                      ? this.state.selectedServicePriceNameError.message
                      : ''
                  }
                  onChange={val => this.onPriceChange(val, 'service_price_name')}
                  onBlur={text => this.onPriceBlur(text)}
                />
              </div>

              <div style={{ marginTop: 10 }}>
                <DropdownComponent
                  onChange={val => this.onLocationChange(val, 'pickup_location')}
                  label={'Localización de embarque '}
                  placeholder={'Localización de embarque '}
                  options={this.state.locations}
                  value={this.state.selectedPickupLocation}
                  disabled={this.props.match.params.id ? true : false}
                  error={this.state.selectedPickupLocationError ? true : false}
                  errorMessage={
                    this.state.selectedPickupLocationError
                      ? this.state.selectedPickupLocationError.message
                      : ''
                  }
                />
              </div>

              <div style={{ marginTop: 30 }}>
                <DropdownComponent
                  onChange={val => this.onLocationChange(val, 'pickup_location_area')}
                  label={'Área de localización de embarque '}
                  placeholder={'Área de localización de embarque '}
                  options={this.state.locationAreas}
                  // options={this.state.locations}
                  value={this.state.selectedPickupLocationArea}
                  disabled={this.props.match.params.id ? true : false}
                  error={this.state.selectedPickupLocationAreaError ? true : false}
                  errorMessage={
                    this.state.selectedPickupLocationAreaError
                      ? this.state.selectedPickupLocationAreaError.message
                      : ''
                  }
                />
              </div>

              <div className="common-kupos-input-box" style={{ marginTop: 30 }}>
                <KuposInput
                  type="text"
                  className="kupos-border"
                  label={'Dirección de embarque'}
                  placeholder={'Dirección de embarque'}
                  value={this.state.selectedPickupAddressName}
                  // disabled={this.props.match.params.id ? true : false}
                  error={this.state.selectedPickupAddressNameError ? true : false}
                  errorMessage={
                    this.state.selectedPickupAddressNameError
                      ? this.state.selectedPickupAddressNameError.message
                      : ''
                  }
                  onChange={val => this.onPriceChange(val, 'pickup_address')}
                  onBlur={text => this.onPriceBlur(text)}
                />
              </div>

              <div style={{ marginTop: 10 }}>
                <DropdownComponent
                  onChange={val => this.onLocationChange(val, 'drop_location')}
                  label={'Localización de la bajada'}
                  placeholder={'Localización de la bajada'}
                  options={this.state.locations}
                  value={this.state.selectedDropLocation}
                  disabled={this.props.match.params.id ? true : false}
                  error={this.state.selectedDropLocationError ? true : false}
                  errorMessage={
                    this.state.selectedDropLocationError
                      ? this.state.selectedDropLocationError.message
                      : ''
                  }
                />
              </div>

              <div style={{ marginTop: 30 }}>
                <DropdownComponent
                  onChange={val => this.onLocationChange(val, 'drop_location_area')}
                  label={'Área de la localización de la bajada '}
                  placeholder={'Área de la localización de la bajada '}
                  options={this.state.locationAreas}
                  // options={this.state.locations}
                  value={this.state.selectedDropLocationArea}
                  disabled={this.props.match.params.id ? true : false}
                  error={this.state.selectedDropLocationAreaError ? true : false}
                  errorMessage={
                    this.state.selectedDropLocationAreaError
                      ? this.state.selectedDropLocationAreaError.message
                      : ''
                  }
                />
              </div>

              <div className="common-kupos-input-box" style={{ marginTop: 30 }}>
                <KuposInput
                  type="text"
                  className="kupos-border"
                  label={'Dirección de bajada'}
                  placeholder={'Dirección de bajada'}
                  value={this.state.selectedDropAddressName}
                  // disabled={this.props.match.params.id ? true : false}
                  error={this.state.selectedDropAddressNameError ? true : false}
                  errorMessage={
                    this.state.selectedDropAddressNameError
                      ? this.state.selectedDropAddressNameError.message
                      : ''
                  }
                  onChange={val => this.onPriceChange(val, 'drop_address')}
                  onBlur={text => this.onPriceBlur(text)}
                />
              </div>

              <div style={{ marginTop: 10 }}>
                <DropdownComponent
                  onChange={val => this.onLocationChange(val, 'service')}
                  label={'Servicio'}
                  placeholder={'Servicio'}
                  options={this.state.services}
                  value={this.state.selectedService}
                  disabled={this.props.match.params.id ? true : false}
                  error={this.state.selectedServiceError ? true : false}
                  errorMessage={
                    this.state.selectedServiceError ? this.state.selectedServiceError.message : ''
                  }
                />
              </div>
              <div style={{ marginTop: 30 }}>
                <DropdownComponent
                  onChange={val => this.onLocationChange(val, 'price_type')}
                  label={'Tipo de precio'}
                  placeholder={'Tipo de precio'}
                  options={this.state.priceTypes}
                  value={this.state.selectedPriceType}
                  disabled={this.props.match.params.id ? true : false}
                  error={this.state.selectedPriceTypeError ? true : false}
                  errorMessage={
                    this.state.selectedPriceTypeError
                      ? this.state.selectedPriceTypeError.message
                      : ''
                  }
                />
              </div>

              <div className="common-kupos-input-box" style={{ marginTop: 30 }}>
                <KuposInput
                  type="text"
                  className="kupos-border"
                  label={'Precio'}
                  placeholder={'Precio'}
                  value={this.state.selectedPrice}
                  error={this.state.selectedPriceError ? true : false}
                  errorMessage={
                    this.state.selectedPriceError ? this.state.selectedPriceError.message : ''
                  }
                  onChange={val => this.onPriceChange(val, 'price')}
                  onBlur={text => this.onPriceBlur(text)}
                />
              </div>

              {this.state.selectedPriceType && this.state.selectedPriceType.value === 'PER_KM' ? (
                <div>
                  <div className="common-kupos-input-box" style={{ marginTop: 10 }}>
                    <KuposInput
                      type="text"
                      className="kupos-border"
                      placeholder={'Fixed Price'}
                      value={this.state.selectedFixedPrice}
                      error={this.state.selectedFixedPriceError ? true : false}
                      errorMessage={
                        this.state.selectedFixedPriceError
                          ? this.state.selectedFixedPriceError.message
                          : ''
                      }
                      onChange={val => this.onPriceChange(val, 'fixed_price')}
                      onBlur={text => this.onPriceBlur(text)}
                    />

                    <KuposInput
                      type="text"
                      className="kupos-border"
                      placeholder={'Max distance'}
                      value={this.state.selectedMaximumDistance}
                      error={this.state.selectedMaximumDistanceError ? true : false}
                      errorMessage={
                        this.state.selectedMaximumDistanceError
                          ? this.state.selectedMaximumDistanceError.message
                          : ''
                      }
                      onChange={val => this.onPriceChange(val, 'maximum_distance')}
                      onBlur={text => this.onPriceBlur(text)}
                    />

                    <KuposInput
                      type="text"
                      className="kupos-border"
                      placeholder={'From KM'}
                      value={this.state.fromKM}
                      error={this.state.fromKMError ? true : false}
                      errorMessage={this.state.fromKMError ? this.state.fromKMError.message : ''}
                      onChange={val => this.onPriceChange(val, 'from_km')}
                      onBlur={text => this.onPriceBlur(text)}
                    />

                    <KuposInput
                      type="text"
                      className="kupos-border"
                      placeholder={'To KM'}
                      value={this.state.toKM}
                      error={this.state.toKMError ? true : false}
                      errorMessage={this.state.toKMError ? this.state.toKMError.message : ''}
                      onChange={val => this.onPriceChange(val, 'to_km')}
                      onBlur={text => this.onPriceBlur(text)}
                    />

                    <KuposInput
                      type="text"
                      className="kupos-border"
                      placeholder={'Per KM'}
                      value={this.state.perKM}
                      error={this.state.perKMError ? true : false}
                      errorMessage={this.state.perKMError ? this.state.perKMError.message : ''}
                      onChange={val => this.onPriceChange(val, 'per_km')}
                      onBlur={text => this.onPriceBlur(text)}
                    />
                  </div>
                </div>
              ) : null}

              <div className="button-container" style={{ marginTop: 20 }}>
                <KuposButton onClick={this.createOrUpdate}>
                  <span className="font11">
                    {this.props.match.params.id
                      ? 'Actualizar precios del servicio'
                      : 'Crear precios de servicios'}
                  </span>
                </KuposButton>
              </div>
            </div>
          </div>

          <div className="service-pricing-map-section">
            <MapWithAMarker
              key={this.state.selectedLatLong.lat}
              googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${AppData.MAP_API_KEY}&v=3.exp&libraries=geometry,drawing,places`}
              loadingElement={<div style={{ height: `100%` }} />}
              containerElement={<div style={{ height: `450px` }} />}
              mapElement={<div style={{ height: `100%`, borderRadius: 8 }} />}
              //  onMapClick={this.onMapClicked}
              //  onMarkerDrag={this.onMarkerDrag}
              //  onPolygonMouseOut={this.onPolygonMouseOut}
              selectedLatLong={this.state.selectedLatLong}
            >
              <Polygon
                ref={ref => {
                  this.polygonRef = ref;
                }}
                //  onDragEnd={() => console.log(this.polygonRef.getPaths())}
                //  onMouseUp={() => this.onPolygonMouseOut(this.polygonRef)}
                //  editable={true}
                path={this.state.polygon}
              />
            </MapWithAMarker>
          </div>
        </div>

        <KuposErrorSuccessModal
          success={this.state.successModalStatus}
          showModal={this.state.showSuccessModal}
          onButtonClick={() => {
            this.setState({
              showSuccessModal: false,
              successModalStatus: false,
              successModalMessage: false,
            });
            if (this.state.successModalStatus) this.props.history.goBack();
          }}
          bodyText={this.state.successModalMessage}
        />
      </div>
    );
  }
}

const MapWithAMarker = withScriptjs(
  withGoogleMap(props => (
    <GoogleMap
      defaultZoom={12}
      defaultCenter={props.selectedLatLong}
      defaultOptions={{
        scrollwheel: false,
      }}
      // defaultCenter={{ lat: -33.4436021, lng: -70.6606066 }}
      onClick={props.onMapClick}
    >
      {props.children}
    </GoogleMap>
  )),
);

/* istanbul ignore next */
function mapStateToProps(state) {
  return {
    home: state.home,
  };
}

/* istanbul ignore next */
function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...actions }, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AddService);
