import './Aside.scss'
import React from 'react';
import {
    ProSidebar,
    Menu,
    MenuItem,
    SubMenu,
    SidebarHeader,
    SidebarFooter,
    SidebarContent,
} from 'react-pro-sidebar';
import sidebarBg from '../../images/Asidebg.jpg';
import { useHistory } from 'react-router';
import CommonService from '../../services/commonService';

const Aside = ({ image, collapsed, rtl, toggled, handleToggleSidebar }) => {
    const history = useHistory()


    return CommonService.authToken ? (
        <ProSidebar
            image={image ? sidebarBg : false}
            // rtl={rtl}
            collapsed={collapsed}
            toggled={toggled}
            // breakPoint="md"
            onToggle={handleToggleSidebar}
        >
            <SidebarHeader >
                <Menu iconShape="round" >
                    <MenuItem
                        onClick={handleToggleSidebar}
                        icon={<span className="fas fa-bars"></span>} />
                </Menu>
            </SidebarHeader>

            <SidebarContent>
                {CommonService.authToken ?
                    <Menu iconShape="round" onClick={() => history.push('/')}>
                        <MenuItem
                            icon={<span className="fas fa-user"></span>}
                        >
                            Principal
                        </MenuItem>
                    </Menu> : null
                }

                {CommonService.isRole('Super Admin') || CommonService.isRole('Admin') ? <SuperAdmin history={history} /> : null}
                {CommonService.isRole('Super Admin') || CommonService.isRole('Sales') ? <Sales history={history} /> : null}
                {CommonService.isRole('Super Admin') || CommonService.isRole('Operator') ? <Operator history={history} /> : null}
                {CommonService.isRole('Platform Admin') ? <PlatformAdmin history={history} /> : null}
                {CommonService.isRole('Super Admin') ? <Admin history={history} /> : null}

            </SidebarContent>

            <SidebarFooter style={{ textAlign: 'center' }}>
                {/*                 
                <div
                    className="sidebar-btn-wrapper"
                    style={{
                        padding: '20px 24px',
                    }}

                >

                    <FaGithub />
                </div> */}
            </SidebarFooter>
        </ProSidebar>
    ) : null
};

export default Aside;


const PlatformAdmin = ({ history }) => {
    return <Menu iconShape="round" >
        <SubMenu
            iconShape='round'
            // prefix={<span className="fas fa-user"></span>}
            title={<span>Plataforma de <br /> administración</span>}
            icon={<span className="fas fa-user"></span>}
        >
            <MenuItem onClick={() => history.push('/clients')}>
                Manejo de clientes
            </MenuItem>
            <MenuItem onClick={() => history.push('/location-areas')}>
                Manejo de áreas
            </MenuItem>
            <MenuItem onClick={() => history.push('/platform-location')}>
                Manejo de locaciones
            </MenuItem>
        </SubMenu>
    </Menu>
}




const SuperAdmin = ({ history }) => {

    return <Menu iconShape="round">
        <SubMenu
            iconShape="round"
            // prefix={<span className="fas fa-user"></span>}
            title={'Administración'}
            icon={<span className="fas fa-bell"></span>}
        >

            <MenuItem
                iconShape="round"
                prefix={<span className="fas fa-ban"></span>}
                onClick={() => history.push('/blocks-unblocks')}
            >
                Bloqueo/desbloqueo
            </MenuItem>
            <MenuItem
                prefix={<span className="fas fa-users"></span>}
                onClick={() => history.push('/users')}>
                Manejo de asuarios
            </MenuItem>
            <MenuItem
                prefix={<span className="fas fa-adjust"></span>}
                onClick={() => history.push('/cancellation-policies')}>
                Politicas de cancelación
            </MenuItem>
        </SubMenu>
        <SubMenu
            iconShape="round"
            icon={<span className="fas fa-book"></span>}
            // suffix={rutas ? <span className="fas fa-angle-down" /> : <span className="fas fa-angle-right" />}
            title={`Manejar rutas`}>
            <MenuItem onClick={() => history.push('/vehicles')}>Manejo de servicios</MenuItem>
            <MenuItem onClick={() => history.push('/services')}>Manejo de precios de <br /> servicios</MenuItem>
            <MenuItem onClick={() => history.push('/locations')}>Manejo de locaciones</MenuItem>
            <MenuItem onClick={() => history.push('/location-areas')}>Manejo de áreas</MenuItem>
            <MenuItem onClick={() => history.push('/c-vehicles')}>Manejo de vehículo</MenuItem>
            <MenuItem onClick={() => history.push('/old-services')}>Manejo de precios de  <br />servicios (viejos)</MenuItem>
            <MenuItem onClick={() => history.push('/drivers')}>Gestión de conductores</MenuItem>
        </SubMenu>

        <SubMenu
            iconShape="round"
            // suffix={caja ? <span className="fas fa-angle-down" /> : <span className="fas fa-angle-right" />}
            icon={<span className="fas fa-chair"></span>}
            title={`Manejar caja`}>
            <MenuItem onClick={() => history.push('/countries')}>Manejo de países</MenuItem>
            <MenuItem onClick={() => history.push('/states')}>Manejo de regiones</MenuItem>
            <MenuItem onClick={() => history.push('/cities')}>Manejo de ciudades</MenuItem>
            <MenuItem onClick={() => history.push('/branches')}>Manejo de sucursales</MenuItem>
            <MenuItem onClick={() => history.push('/counters')}>Manejo de cajas</MenuItem>
            <MenuItem onClick={() => history.push('/pos')}>Manejo de POS</MenuItem>
            <MenuItem onClick={() => history.push('/shifts')}>Manejo de turnos</MenuItem>
            <MenuItem onClick={() => history.push('/exchange-rates')}>Manejo de tasa de conversión</MenuItem>
            <MenuItem onClick={() => history.push('/income-expense')}>Lista de gastos e ingresos</MenuItem>
        </SubMenu>
    </Menu>
}


const Sales = ({ history }) => {
    return <Menu iconShape="round">
        <SubMenu
            iconShape="round"
            // prefix={<span className="fas fa-user"></span>}
            title={'Gestión de ventas'}
            icon={<span className="fas fa-headset"></span>}
        >

            <MenuItem
                iconShape="round"
                prefix={<span className="fas fa-ticket-alt"></span>}
                onClick={() => history.push('/sales-page')}
            >
                Ventas
            </MenuItem>
            <MenuItem
                prefix={<span className="fas fa-chart-line"></span>}
                onClick={() => history.push('/counter-dashboard')}>
                Transacciones de caja
            </MenuItem>
            <MenuItem
                prefix={<span className="fas fa-hand-holding-usd"></span>}
                onClick={() => history.push('/income-expense-txn')}>
                Transacciones del usuario
            </MenuItem>

        </SubMenu>
    </Menu>
}

const Operator = ({ history }) => {

    return <Menu iconShape="round">
        <MenuItem
            iconShape="round"
            icon={<span className="fas fa-bell"></span>}
            onClick={() => history.push('/operations')}
        >
            Operaciones
        </MenuItem>

        <MenuItem
            iconShape="round"
            icon={<span className="fas fa-download"></span>}
            onClick={() => history.push('/reports')}
        >
            Reportes
        </MenuItem>
    </Menu>
}



const Admin = ({ history }) => {

    return <Menu iconShape="round">
        <MenuItem
            iconShape="round"
            icon={<span className="fas fa-heart"></span>}
        // onClick={() => history.push('/operations')}
        >
            Favouritos
        </MenuItem>

        <MenuItem
            iconShape="round"
            icon={<span className="fas fa-cog"></span>}
        // onClick={() => history.push('/reports')}
        >
            Ajustes
        </MenuItem>
    </Menu>
}







// old SideBar navigation used in App.js 

// {
//     CommonService.authToken ? (
//         // {this.props.home && this.props.home.loginData ? (
//         <div
//             className={
//                 'bg-gray-900 shadow-lg h-16 fixed bottom-0 md:relative md:h-screen z-10 w-full md:w-50 main-side-menu' +
//                 (this.state.minimiseMenu ? ' minimize-menu' : '')
//             }
//         >
//             <div
//                 className={
//                     'md:w-50 md:relative md:left-0 md:top-0 content-center md:content-start text-left justify-between' +
//                     (this.state.minimiseMenu ? ' minimize-menu' : '')
//                 }
//             >
//                 <div className="ham-menu">
//                     <a
//                         href="#"
//                         onClick={() => this.setState({ minimiseMenu: !this.state.minimiseMenu })}
//                     >
//                         <i className="fas fa-bars pr-3 md:pr-3"></i>
//                     </a>
//                 </div>
//                 <ul className="list-reset md:flex-col py-0 md:py-3 px-1 md:px-2 text-left md:text-left main-menu-ul">
//                     {/* <ul className="list-reset flex flex-row md:flex-col py-0 md:py-3 px-1 md:px-2 text-center md:text-left"> */}
//                     <li className="mr-3 flex-1">
//                         <div
//                             // href="#"
//                             onClick={() => this.props.history.push('/')}
//                             className="block py-1 md:py-3 pl-1 align-middle no-underline primary-color pointer"
//                         >
//                             <span className="menu-icon">
//                                 {' '}
//                                 <i className="fas fa-house-user pr-3 md:pr-3"></i>
//                             </span>
//                             <span className="pb-1 md:pb-0 text-xs md:text-base block md:inline-block font11 menu-label">
//                                 Principal
//                             </span>
//                         </div>
//                     </li>

//                     {/* {CommonService.isRole('Platform Admin') ? (
//             <li className="mr-3 flex-1" onClick={() => this.props.history.push('/clients')}>
//               <div
//                 onClick={() => this.props.history.push('/')}
//                 className="block py-1 md:py-3 pl-1 align-middle no-underline primary-color pointer"
//               >
//                 <span className="menu-icon">
//                   <i className="fa fa-users pr-3 md:pr-3"></i>
//                 </span>
//                 <span className="pb-1 md:pb-0 text-xs md:text-base block md:inline-block font11 menu-label">
//                   Clients
//                 </span>
//               </div>
//             </li>
//           ) : null} */}



//                     {CommonService.isRole('Super Admin') || CommonService.isRole('Admin') ? (
//                         <li className="mr-3 flex-1">
//                             <div
//                                 // href="#"
//                                 onClick={() => this.openSubMenu('administration')}
//                                 className="block py-1 md:py-3 pl-1 align-middle no-underline primary-color pointer"
//                             >
//                                 <span className="menu-icon">
//                                     <i className="far fa-bell pr-3 md:pr-3"></i>
//                                 </span>
//                                 <span className="pb-1 md:pb-0 text-xs md:text-base block md:inline-block font11 menu-label">
//                                     Administración
//                                 </span>
//                             </div>

//                             {this.state.openSubMenu === 'administration' ? (
//                                 <ul className="primary-color font10 sub-menu">
//                                     <li onClick={() => this.props.history.push('/blocks-unblocks')} className={pathName == "/blocks-unblocks" ? "active-link" : null}>
//                                         <span className="menu-icon">
//                                             <i className="fa fa-ban pr-1 md:pr-3"></i>
//                                         </span>
//                                         Bloqueo/desbloqueo
//                                     </li>
//                                     <li onClick={() => this.props.history.push('/users')} className={pathName == "/users" ? "active-link" : null}>
//                                         <span className="menu-icon">
//                                             <i className="fas fa-users pr-1 md:pr-3"></i>
//                                         </span>
//                                         Manejo de asuarios
//                                     </li>
//                                     <li onClick={() => this.props.history.push('/cancellation-policies')} className={pathName == "/cancellation-policies" ? "active-link" : null}>
//                                         <span className="menu-icon">
//                                             <i className="fa fa-adjust pr-1 md:pr-3"></i>
//                                         </span>
//                                         Politicas de cancelación
//                                     </li>
//                                     <li>
//                                         <div
//                                             onClick={() =>
//                                                 this.setState({
//                                                     showRouteMangement: !this.state.showRouteMangement,
//                                                 })
//                                             }
//                                         >
//                                             <span className="menu-icon">
//                                                 <i className="fa fa-book pr-1 md:pr-3"></i>
//                                             </span>
//                                             <span className="pb-1 md:pb-0 text-xs md:text-base block md:inline-block font10 menu-label">
//                                                 Manejar rutas
//                                             </span>
//                                         </div>

//                                         {this.state.showRouteMangement ? (
//                                             <ul style={{ marginLeft: 25 }}>
//                                                 <li onClick={() => this.props.history.push('/vehicles')} className={pathName == "/vehicles" ? "active-link" : null}>
//                                                     Manejo de servicios
//                                                 </li>
//                                                 <li onClick={() => this.props.history.push('/services')} className={pathName == "/services" ? "active-link" : null}>
//                                                     Manejo de precios de <br /> servicios
//                                                 </li>
//                                                 <li onClick={() => this.props.history.push('/locations')} className={pathName == "/locations" ? "active-link" : null}>
//                                                     Manejo de locaciones
//                                                 </li>
//                                                 <li onClick={() => this.props.history.push('/location-areas')} className={pathName == "/location-areas" ? "active-link" : null}>
//                                                     Manejo de áreas
//                                                 </li>
//                                                 <li onClick={() => this.props.history.push('/c-vehicles')} className={pathName == "/c-vehicles" ? "active-link" : null}>
//                                                     Manejo de vehículo
//                                                 </li>
//                                                 <li onClick={() => this.props.history.push('/old-services')} className={pathName == "/old-services" ? "active-link" : null}>
//                                                     Manejo de precios de servicios (viejos)
//                                                 </li>
//                                                 <li onClick={() => this.props.history.push('/drivers')} className={pathName == "/drivers" ? "active-link" : null}>
//                                                     Gestión de conductores
//                                                 </li>
//                                                 {/* <li onClick={() => this.props.history.push('/cancellation-policies')} className={pathName == "/drivers" ? "active-link" : null}>
//                           Cancellation Policies
//                         </li> */}
//                                             </ul>
//                                         ) : null}

//                                         <div
//                                             onClick={() =>
//                                                 this.setState({
//                                                     showCounterMangement: !this.state.showCounterMangement,
//                                                 })
//                                             }
//                                         >
//                                             <span className="menu-icon">
//                                                 <i className="fas fa-chair pr-1 md:pr-3"></i>
//                                             </span>
//                                             <span className="pb-1 md:pb-0 text-xs md:text-base block md:inline-block font10 menu-label">
//                                                 Manejar caja
//                                             </span>
//                                         </div>
//                                         {this.state.showCounterMangement ? (
//                                             <ul style={{ marginLeft: 25 }}>
//                                                 <li onClick={() => this.props.history.push('/countries')} className={pathName == "/countries" ? "active-link" : null}>
//                                                     Manejo de países
//                                                 </li>
//                                                 <li onClick={() => this.props.history.push('/states')} className={pathName == "/states" ? "active-link" : null}>
//                                                     Manejo de regiones
//                                                 </li>
//                                                 <li onClick={() => this.props.history.push('/cities')} className={pathName == "/cities" ? "active-link" : null}>
//                                                     Manejo de ciudades
//                                                 </li>
//                                                 <li onClick={() => this.props.history.push('/branches')} className={pathName == "/branches" ? "active-link" : null}>
//                                                     Manejo de sucursales
//                                                 </li>
//                                                 <li onClick={() => this.props.history.push('/counters')} className={pathName == "/counters" ? "active-link" : null}>
//                                                     Manejo de cajas
//                                                 </li>
//                                                 <li onClick={() => this.props.history.push('/pos')} className={pathName == "/pos" ? "active-link" : null}>
//                                                     Manejo de POS
//                                                 </li>
//                                                 <li onClick={() => this.props.history.push('/shifts')} className={pathName == "/shifts" ? "active-link" : null}>
//                                                     Manejo de turnos
//                                                 </li>
//                                                 <li onClick={() => this.props.history.push('/exchange-rates')} className={pathName == "/exchange-rates" ? "active-link" : null}>
//                                                     Manejo de tasa de conversión
//                                                 </li>
//                                                 <li onClick={() => this.props.history.push('/income-expense')} className={pathName == "/income-expense" ? "active-link" : null}>
//                                                     Lista de gastos e ingresos
//                                                 </li>
//                                             </ul>
//                                         ) : null}

//                                         {/* <div
//                       onClick={() =>
//                         this.setState({
//                           showVehicleMangement: !this.state.showVehicleMangement,
//                         })
//                       }
//                     >
//                       <span className="menu-icon">
//                         <i className="fas fa-bus pr-1 md:pr-3"></i>
//                       </span>
//                       <span className="pb-1 md:pb-0 text-xs md:text-base block md:inline-block font10 menu-label">
//                         Vehicle Management
//                       </span>
//                     </div>
//                     {this.state.showVehicleMangement ? (
//                       <ul>
//                         <li onClick={() => this.props.history.push('/vehicles')}>
//                           Manejo de servicios
//                         </li>
//                         <li onClick={() => this.props.history.push('/drivers')}>
//                           Gestión de conductores
//                         </li>
//                       </ul>
//                     ) : null} */}
//                                     </li>
//                                 </ul>
//                             ) : null}
//                         </li>
//                     ) : null}

//                     {CommonService.isRole('Super Admin') || CommonService.isRole('Sales') ? (
//                         <li>
//                             <div
//                                 onClick={() =>
//                                     this.setState({
//                                         showSalesMangement: !this.state.showSalesMangement,
//                                     })
//                                 }
//                                 className="block py-1 md:py-3 pl-1 align-middle no-underline primary-color pointer"
//                             >
//                                 <span className="menu-icon">
//                                     <i className="fas fa-headset pr-3 md:pr-3"></i>
//                                 </span>
//                                 <span className="pb-1 md:pb-0 text-xs md:text-base block md:inline-block font11 menu-label">
//                                     {/* Sales Management */}
//                                     Gestión de ventas
//                                 </span>
//                             </div>
//                             {this.state.showSalesMangement ? (
//                                 <ul>
//                                     <li
//                                         className="ml-3 flex-1"
//                                         onClick={() => this.props.history.push('/sales-page')}

//                                     >
//                                         <div
//                                             onClick={() => this.props.history.push('/')}
//                                             className="block py-1 md:py-3 pl-1 align-middle no-underline primary-color pointer"
//                                         >
//                                             <span className="menu-icon">
//                                                 <i className="fas fa-ticket-alt pr-1 md:pr-3"></i>
//                                             </span>
//                                             <span className="pb-1 md:pb-0 text-xs md:text-base block md:inline-block font11 menu-label" className={pathName == "/sales-page" ? "active-link" : null}>
//                                                 Ventas
//                                             </span>
//                                         </div>
//                                     </li>

//                                     {CommonService.isRole('Super Admin') || CommonService.isRole('Sales') ? (
//                                         <li
//                                             className="ml-3 flex-1"
//                                             onClick={() => this.props.history.push('/counter-dashboard')}

//                                         >
//                                             <div
//                                                 onClick={() => this.props.history.push('/')}
//                                                 className="block py-1 md:py-3 pl-1 align-middle no-underline primary-color pointer"
//                                             >
//                                                 <span className="menu-icon">
//                                                     <i className="fas fa-chart-line pr-1 md:pr-3"></i>
//                                                 </span>
//                                                 <span className="pb-1 md:pb-0 text-xs md:text-base block md:inline-block font10 menu-label" className={pathName == "/counter-dashboard" ? "active-link" : null}>
//                                                     Transacciones de caja
//                                                 </span>
//                                             </div>
//                                         </li>
//                                     ) : null}

//                                     {CommonService.isRole('Super Admin') || CommonService.isRole('Sales') ? (
//                                         <li
//                                             className="ml-3 flex-1"
//                                             onClick={() => this.props.history.push('/income-expense-txn')}

//                                         >
//                                             <div
//                                                 onClick={() => this.props.history.push('/')}
//                                                 className="block py-1 md:py-3 pl-1 align-middle no-underline primary-color pointer"
//                                             >
//                                                 <span className="menu-icon">
//                                                     <i className="fas fa-hand-holding-usd pr-1 md:pr-3"></i>
//                                                 </span>
//                                                 <span className="pb-1 md:pb-0 text-xs md:text-base block md:inline-block font10 menu-label" className={pathName == "/income-expense-txn" ? "active-link" : null}>
//                                                     Transacciones del usuario
//                                                 </span>
//                                             </div>
//                                         </li>
//                                     ) : null}
//                                 </ul>
//                             ) : null}
//                         </li>
//                     ) : null}

//                     {CommonService.isRole('Super Admin') || CommonService.isRole('Operator') ? (
//                         <li className="mr-3 flex-1" >
//                             <div
//                                 // href="#"
//                                 onClick={() => this.props.history.push('/operations')}

//                                 className="block py-1 md:py-3 pl-1 align-middle no-underline primary-color pointerr"
//                             >
//                                 <span className="menu-icon">
//                                     <i className="far fa-bell pr-3 md:pr-3"></i>
//                                 </span>
//                                 {!this.state.minimiseMenu ? (
//                                     <span className="pb-1 md:pb-0 text-xs md:text-base block md:inline-block font11 menu-label" className={pathName == "/operations" ? "active-link" : null}>
//                                         Operaciones
//                                     </span>
//                                 ) : null}
//                             </div>
//                         </li>
//                     ) : null}

//                     {CommonService.isRole('Super Admin') || CommonService.isRole('Operator') ? (
//                         <li className="mr-3 flex-1" >
//                             <div
//                                 // href="#"
//                                 onClick={() => this.props.history.push('/reports')}
//                                 className="block py-1 md:py-3 pl-1 align-middle no-underline primary-color pointerr"
//                             >
//                                 <span className="menu-icon">
//                                     <i className="fas fa-download pr-3 md:pr-3"></i>
//                                 </span>
//                                 {!this.state.minimiseMenu ? (
//                                     <span className="pb-1 md:pb-0 text-xs md:text-base block md:inline-block font11 menu-label" className={pathName == "/reports" ? "active-link" : null}>
//                                         Reportes
//                                     </span>
//                                 ) : null}
//                             </div>
//                         </li>
//                     ) : null}

//                     {CommonService.isRole('Super Admin') ? (
//                         <li className="mr-3 flex-1">
//                             <div
//                                 // href="#"
//                                 className="block py-1 md:py-3 pl-1 align-middle no-underline primary-color pointer"
//                             >
//                                 <span className="menu-icon">
//                                     <i className="far fa-heart pr-3 md:pr-3"></i>
//                                 </span>
//                                 <span className="pb-1 md:pb-0 text-xs md:text-base block md:inline-block font11 menu-label">
//                                     Favouritos
//                                 </span>
//                             </div>
//                         </li>
//                     ) : null}
//                     {CommonService.isRole('Super Admin') ? (
//                         <li className="mr-3 flex-1">
//                             <div
//                                 // href="#"
//                                 className="block py-1 md:py-3 pl-1 align-middle no-underline primary-color pointer"
//                             >
//                                 <span className="menu-icon">
//                                     <i className="fas fa-cog pr-3 md:pr-3"></i>
//                                 </span>
//                                 <span className="pb-1 md:pb-0 text-xs md:text-base block md:inline-block font11 menu-label">
//                                     Ajustes
//                                 </span>
//                             </div>
//                         </li>
//                     ) : null}
//                 </ul>
//             </div>
//         </div>
//     ) : null
// }