import React, { Component } from 'react';
import KuposModal from './KuposModal';
import SvgHome from './SvgHome';
import PropTypes from 'prop-types';

export default class TicketConfirmModal extends Component {
  static propTypes = {
    image: PropTypes.object.isRequired,
    t: PropTypes.func,
  };

  render() {
    return (
      <div className="common-bip-info-modal">
        <KuposModal
          showModal={this.props.showModal}
          onHide={this.props.onHide}
          size="md"
          ariaLabel="login-modal"
        >
          <div className="bip-info-modal-container">
            <div className="close-button pointer" onClick={this.props.onHide}>
              <SvgHome name="close" />
            </div>
            <div className="kupos-pool-popup-image-back">
              <img src={this.props.image} />
            </div>
          </div>
        </KuposModal>
      </div>
    );
  }
}